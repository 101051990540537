import React from 'react';
import styled from 'styled-components';
import Api, { Actions, Endpoints } from '../../../../../../_Shared/Api';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DatePicker from 'react-datepicker';

const TasksContent = (props: any) => {
    const [task, setTask]: any = React.useState(null);
    const [title, setTitle]: any = React.useState('');
    const [startDate, setStartDate]: any = React.useState(new Date());
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    const fetch = async () => {
        const deal = await Api(Endpoints['Hubspot'], Actions['GetAssociationsDealsToTask'], props.external_token, {}, [
            ['dealId', props.data.deal_id],
        ]);
        const engagements = deal.results.map(async (d: any) => {
            const engagement = await Api(Endpoints['Hubspot'], Actions['GetEngagements'], props.external_token, {}, [
                ['associationId', d.id],
            ]);
            return engagement;
        });

        Promise.all(engagements).then((arr: any) => {
            const sorted = arr.sort(function (x: any, y: any) {
                return x.engagement.timestamp - y.engagement.timestamp;
            });
            //console.log(sorted);
            setTask(sorted.reverse());
        });
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const editorChange = (value: any) => {
        setEditorState(value);
    };

    const titleChange = (event: any) => {
        setTitle(event.target.value);
    };

    const handleDateChange = (date: any) => {
        setStartDate(date)
    };

    const saveTask = async () => {
        const rawContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const ownerId = props.data.account.user_id;
        const data = {
            engagement: {
                active: true,
                // ownerId: ownerId,
                type: 'TASK',
                timestamp: moment(startDate).valueOf(),
            },
            associations: {
                contactIds: [],
                companyIds: [],
                dealIds: [props.data.deal_id],
                ownerIds: [],
                ticketIds: [],
            },
            attachments: [],
            metadata: {
                body: rawContentState,
                subject: title,
                status: 'NOT_STARTED',
            },
        };

        await Api(Endpoints['Hubspot'], Actions['CreateEngagement'], props.external_token, { body: data });
        setEditorState(EditorState.createEmpty());
        setTitle('');
        fetch();
    };

    return (
        <>
            <ParentTaskContainer>
                <TaskContainer>
                    <Input placeholder='Title' onChange={titleChange} value={title} />
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat='Pp'
                    />
                    <CustomEditor
                        editorState={editorState}
                        toolbarClassName='toolbarClassName'
                        wrapperClassName='wrapperClassName'
                        editorClassName='editorClassName'
                        onEditorStateChange={editorChange}
                    />
                    <Button className='button default' onClick={saveTask}>
                        Save
                    </Button>
                </TaskContainer>
            </ParentTaskContainer>
            {task === null ? (
                <>Preparing task for you...</>
            ) : task.length === 0 ? (
                <>There is nothing here</>
            ) : (
                task.map((n: any, i: number) => {
                    const { engagement, metadata } = n;
                    return (
                        <ParentTaskContainer key={i}>
                            <TaskContainer>
                                <TaskHeader>
                                    <TaskTitle>
                                        <div className='title'>Task</div> {metadata.subject}
                                    </TaskTitle>
                                    <TaskDate>{moment(engagement.timestamp).format('lll')}</TaskDate>
                                </TaskHeader>
                                <Divider />
                                <TaskContent>
                                    <div>
                                        <div className='label'>Due Date</div>
                                        <div className="value">
                                            In {moment(engagement.timestamp).diff(new Date().getTime(), 'days') + 1}{' '}
                                            business days
                                        </div>
                                    </div>
                                    <div>
                                        <div className='label'>Reminder</div>
                                        <div className="value">
                                            {metadata.reminders[0]
                                                ? `${
                                                      Math.abs(
                                                          moment(metadata.reminders[0]).diff(
                                                              engagement.timestamp,
                                                              'minutes'
                                                          )
                                                      ) / 60
                                                  } hours before due. (${moment(metadata.reminders[0]).format('lll')})`
                                                : 'At task due time'}
                                        </div>
                                    </div>
                                </TaskContent>
                                <Divider />
                                <div dangerouslySetInnerHTML={{ __html: metadata.body }} />
                            </TaskContainer>
                        </ParentTaskContainer>
                    );
                })
            )}
        </>
    );
};

const Input = styled.input`
    border: none;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
`;

const Button = styled.button`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    min-width: 80px;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 10px !important;
    border: none;
    color: white;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.newlight};
    }
`;
const CustomEditor = styled(Editor)``;

const TaskContent = styled.div`
    display: flex;
    div {
        width: 100%;
    }
    .label {
        font-size: 0.7rem;
        color: #81818d;
    }
    .value {
        color: #0f8d96;
    }
`;

const Divider = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-top: 10px;
`;

const TaskDate = styled.div`
    /* width: 100%; */
    margin-left: auto;
    font-size: 12px;
    color: #81818d;
`;

const TaskTitle = styled.div`
    /* width: 100%; */
    .title {
        font-weight: bolder;
        margin-bottom: 10px;
    }
`;

const TaskHeader = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const ParentTaskContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
`;

const TaskContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #f2f4f6;
    border: 1px solid #f2f4f6;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    input {
        border: none;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
    }
`;

export default TasksContent;
