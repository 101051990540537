import React from 'react'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import MainClass from './MainClass'

const MainFunctional = (props: any) => {
  const meetingContext = useMeetingContext()
  return <MainClass meetingContext={meetingContext} {...props} />
}

export default MainFunctional
