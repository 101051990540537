import React from 'react'
import styled from 'styled-components'
import LinkOtherAccount from './linkotheraccount.png'

interface Props {
	refresh: Function,
	cancel: Function
}

function LinkOtherAccountModal(props: Props) {
	const startLogin = (key: string) => {
		props.refresh('slack')
		window.open(
			(process.env.REACT_APP_API_URL || 'http://localhost:8000') +
			`/oauth/auth/${key}`
		)
	}
	return (
		<Container className="topbar rounded inner">
			<Contents>
				<img src={LinkOtherAccount} alt="Instructions to link other workspace" />
			</Contents>
			<Options>
				<Button className="button primary" onClick={() => startLogin('slack')}>Link other workspace</Button>
				<UnderlinedText onClick={() => props.cancel()}>
					<span>Cancel</span>
				</UnderlinedText>
			</Options>
		</Container>
	)

}

const UnderlinedText = styled.p`
    cursor: pointer;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 16px;

    span {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 2px solid;
    }
`

const Container = styled.div`
	padding: 12px;
	max-width: 90%;
    margin: 0 auto;
`

const Contents = styled.div`
	padding: 20px;

    img {
        width: 100%;
    }
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
    text-align: center;
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	width: 190px;
    margin: 0 auto 10px;
    font-weight: 400;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`


export default LinkOtherAccountModal
