import React, { ReactNode } from 'react'
import styled from 'styled-components'

import {
  Modal,
} from '@material-ui/core'

function StyledModal({ visible, title = <></>, onClose = () => {}, children = <></> }: {
    visible: boolean,
    title?: ReactNode
    onClose?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
    children: ReactNode
}) {
  return (
    <CustomModal
      open={visible}
      onClose={onClose}
    >
      <>
        {title}
        {children}
      </>
    </CustomModal>
  )
}

const CustomModal = styled(Modal)`
`

export default StyledModal