import React from 'react'
// import AudioLevelIndicator from './AudioLevelIndicator';
import {LocalAudioTrack} from 'twilio-video'
import {
  PREVIOUS_AUDIO_INPUT_KEY,
  SELECTED_AUDIO_INPUT_KEY,
} from '../../../../../../calling/_constants'
import Dropdown from '../../../../../../components/Dropdown'
import useDevices from '../../hooks/useDevices'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack'
import useLocalStorage from '../../hooks/useLocalStorage'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { FiMic as MicIcon } from "@react-icons/all-files/fi/FiMic";
import { CallingProviderName } from '../../../../../../contexts/types'
import { DailyCall } from '@daily-co/daily-js'

export default function AudioInputList({ onboarding }: { onboarding?: boolean }) {
  const {audioInputDevices} = useDevices()
  const {localTracks, room, callProvider} = useMeetingContext()
  const [audioInputId, setAudioInputId] = useLocalStorage(
    SELECTED_AUDIO_INPUT_KEY,
    'default',
  )

  const localAudioTrack = localTracks.find(
    (track) => track.kind === 'audio',
  ) as LocalAudioTrack
  // const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
  // const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  const isTwilio = callProvider === CallingProviderName.Twilio ||
    callProvider === CallingProviderName.TwilioPro || 
    callProvider === CallingProviderName.TwilioStandard


  const audio_options: any = audioInputDevices.map(device => ({
    name: device.label,
    value: device.deviceId
  }))

  async function replaceTrack(newDeviceId: string) {
    setAudioInputId(newDeviceId)
    if(isTwilio || onboarding) {
      localAudioTrack?.restart({deviceId: {exact: newDeviceId}})
    } else {
      await (room as DailyCall)?.setInputDevicesAsync({
        audioDeviceId: newDeviceId,
      });
    }
    
  }

  return (
    <div style={{marginBottom: 10}}>
      <div className="label">Microphone</div>
      
      {audioInputDevices.length >= 1 ? (
        // <Dropdown
        //   name="audioinput"
        //   value={localAudioInputDeviceId || ""}
        //   items={audioInputDevices.map((device) => ({
        //     name: device.label,
        //     value: device.deviceId
        //   })) as any}
        //   onChange={(item) => replaceTrack(item.value as string)}
        // />
        <div className="select-device-wrapper">
          <Dropdown
            name="video-devices"
            items={audio_options}
            disabled={audioInputDevices.length === 0}
            value={audioInputId || ''}
            // inputWidth={100%}
            onChange={(item, name) => {
              replaceTrack(item.value as string)
            }}
            dropdownIcon={<MicIcon />}
          />
        </div>
        // <select
        //   className="select-custom border-lightimp topbar hover-container"
        //   onChange={(e) => replaceTrack(e.target.value as string)}
        //   value={audioInputId || ''}
        // >
        //   {audioInputDevices.map((device) => (
        //     <option value={device.deviceId} key={device.deviceId}>
        //       {device.label}
        //     </option>
        //   ))}
        // </select>
      ) : (
        <div>
          <Dropdown
            name="video-devices"
            items={[{
              name: 'No Local Audio',
              value: '',
              disabled: false
            }]}
            disabled={true}
            value={''}
            // inputWidth={100%}
            onChange={(item, name) => {
              replaceTrack(item.value as string)
            }}
            dropdownIcon={<MicIcon />}
          />
          {/* <p style={{ marginBottom: '10px', textAlign: 'center' }}>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</p> */}
        </div>
      )}
    </div>
  )
}
