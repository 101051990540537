import React from "react";
import Reflux from "reflux";
import styled from "styled-components";

// Components
import Modal from "../../components/Modalv2";

interface Props {
  closeModal: Function
  deleteItems: Function
}

class ModalRemove extends Reflux.Component {

  constructor(props: Props) {
    super(props);
  }

  delete() {
    const { deleteItems, closeModal } = this.props
    deleteItems()
    closeModal()
  }

  render() {
    return (
      <Modal {...this.props}>
        <Container>
            <div className="name">Are you sure you want to remove this?</div>
            <div className="actions-wrapper">
                <div className="button default" onClick={() => this.props.closeModal()}>Cancel</div>
                <div className="button danger" onClick={() => {this.delete()}}>Remove</div>
            </div>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  padding: 15px;

  & > div.name {
    padding: 8px;
  }

  & div.actions-wrapper {
    justify-content: center;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > div {
      cursor: pointer;
      min-width: 68px;
      padding: 8px 20px;
    }

    > div:first-child {
      margin-right: 5px;
    }
  }
`;

export default ModalRemove;
