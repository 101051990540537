import React from "react";
import styled from "styled-components"

// Components
import Directory from "./Directory";
import Item from "./Item";
import Folders from "./Folders";
import ItemActions from "./ItemActions";
import ContextMenu from "./ContextMenu";
import EmptyFileContents from "../_Shared/EmptyFileContents"

import LoopApi from '../../helpers/LoopApi'

// Types
import { IWidgetProps, AllWidgets } from "../types";
import {
  IFile,
  IFilesMap,
  IFilesWidgetState,
  IFolder,
  IFoldersMap,
  KeyboardKeys,
  IFileIdToParentUuidMap,
} from "./types";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { BiRename as RenameIcon } from "@react-icons/all-files/bi/BiRename";
import { FiMove as MoveIcon } from '@react-icons/all-files/fi/FiMove'
import { FiTrash as RemoveIcon } from '@react-icons/all-files/fi/FiTrash'
import { FiDownload as DownloadIcon } from '@react-icons/all-files/fi/FiDownload'
import { ImFilePdf as PDFIcon } from '@react-icons/all-files/im/ImFilePdf'
import { FaSpinner as SpinnerIcon } from '@react-icons/all-files/fa/FaSpinner'
import { BiChalkboard } from '@react-icons/all-files/bi/BiChalkboard'
import { MainStore, WidgetActions } from "../../stores/MainStore";
import Reflux from "reflux";
import { VariableCallingStore } from "../../stores/VariableCallingStore";
import { CallingInstanceState } from "../../calling/types";
import Sagas from "../../helpers/Sagas";
import { BiSlideshow as Present } from 'react-icons/bi'

interface Props {
  files: IFile[];
  currentFolder: IFolder | null;
  UpdateSelf: Function;
  updateState: (
    fn: (prevState: IFilesWidgetState) => IFilesWidgetState,
    cb?: Function
  ) => void;
  filesWidgetState: IFilesWidgetState;
  childFiles: IFileIdToParentUuidMap;
  deleteItems: Function;
  isQuickAccess?: boolean;
  userId: string
  isTravelling: boolean
  isPresenterOnline: boolean
  onPresent: Function
  changeIndexState: Function
  setLastView: (s: string) => void
  users: any
}

interface State {
  converting: boolean
  call_starter: any
  meetingLocked: boolean
  locallyHiddenSync: any
  meetingName: string
  widgets: any
  status: string
  localCurrentWidget: any
}

export default class FileList extends Reflux.Component<typeof VariableCallingStore | typeof MainStore, Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      converting: false,
      call_starter: '',
      meetingLocked: false,
      locallyHiddenSync: {},
      meetingName: '',
      widgets: [],
      status: '',
      localCurrentWidget: {}
    }

    this.stores = [VariableCallingStore, MainStore]
    this.storeKeys = [
      'status',
      'call_starter',
      'meetingLocked',
      'locallyHiddenSync',
      'meetingName',
      'widgets',
      'localCurrentWidget'
    ]
  }

  convertFile = async (uri: string, filename: string) => {
    try {
      if (this.state.converting) {
        return
      }

      this.setState({ converting: true })

      await LoopApi(null, 'ConvertOffice', {
        uri,
        filename,
      })
      this.setState({ converting: false })
    } catch (e) {
      this.setState({ converting: false })
    }
  }

  openWithWB(file: IFile) {
    const wbData = { url: file.uri, fileType: file.filetype }

    localStorage.setItem('wbFile', JSON.stringify(wbData))

    if ((CallingInstanceState.Connected === this.state.status && !this.state.meetingLocked) || CallingInstanceState.Connected !== this.state.status) {
      console.log('WB in local state');


      //if WB is hidden
      if ((this.state.locallyHiddenSync?.[this.state.meetingName] || []).includes('whiteboard')) {
        console.log('WB hidden');
        WidgetActions.HideSync('whiteboard')
        this.openFile(wbData)
      } else {
        console.log('WB is not hidden');

        //if WB is not added
        // Sagas.addWidget('whiteboard')
        //if WB is not hidden
        console.log(this.state.widgets.whiteboard);

        if (this.state.widgets.whiteboard === undefined)
          Sagas.addWidget('whiteboard')

        setTimeout(() => {
          const localCurrentWidget = {
            ...this.state.localCurrentWidget,
            [this.state.meetingName]: 'whiteboard'
          }
          localStorage.setItem("currentWidget", JSON.stringify(localCurrentWidget))
          WidgetActions.SetLocalCurrentWidget({ localCurrentWidget })
          this.openFile(wbData)

        }, 10);

      }
    } else {
      console.log('WB in meeting');
      //if meeting is locked
      if (this.state.call_starter === this.props.userId) {
        console.log('WB open by host');
        //if WB is not added
        if (this.state.widgets.whiteboard === undefined)
          Sagas.addWidget('whiteboard')
        //if WB is not hidden
        setTimeout(() => {
          WidgetActions.SetCurrentWidget({ currentWidget: 'whiteboard' })
          this.openFile(wbData)
        }, 10);
      }
    }


  }

  openFile(wbData: any) {
    console.log('openFile Called');

    setTimeout(() => {
      const event = new CustomEvent(`OPEN_WITH_WB`, { detail: { wbData } })
      window.dispatchEvent(event)
    }, 1000);

  }

  render() {
    const { files, currentFolder, childFiles, filesWidgetState, updateState, UpdateSelf, deleteItems, isQuickAccess = false, isTravelling } =
      this.props;
    const { keyboardKeys, selectedItems, rightClickedItem } = filesWidgetState;

    const currentFolderId = currentFolder ? currentFolder.uuid : null;

    let isPartOfSelected = false

    if (rightClickedItem && "_id" in rightClickedItem) {
      // console.log({
      //   selectedIds: selectedItems.map(item => (item as IFile)._id).filter(item => !!item),
      //   rightclickedId: rightClickedItem._id
      // })
      isPartOfSelected = selectedItems.map(item => (item as IFile)._id).includes(rightClickedItem._id)
    }

    let filesToRender = [...files]
    if (!isQuickAccess) {
      filesToRender = filesToRender.filter((file) => {
        if (currentFolderId) {
          // child folder
          return childFiles[file._id] === currentFolderId;
        } else {
          // root folder
          return !childFiles[file._id];
        }
      })
    }

    if (filesToRender.length === 0) {
      return <EmptyFileContents folder={false}>No files found</EmptyFileContents>
    }

    const sortedFiles: JSX.Element[] = filesToRender
      .sort((file1, file2) => {
        return file1.filename.toLowerCase().localeCompare(file2.filename.toLowerCase());
      })
      .map((file) => {
        const browserable = ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'txt', 'jfif', 'webp', 'mp4', 'mov', 'wmv', 'flv', 'avi', 'webm', 'mkv', 'webp']
        const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']
        const wbReady = ['pdf', 'png', 'svg', 'jpg', 'jpeg']



        let match = file.filename.match(/\.(\w+)$/)
        let viewHidden = true, showWB = true
        // let viewHidden = true
        if (!match || !match[1]) {
          viewHidden = false
          return <></>
        } else {
          viewHidden = browserable.includes(match[1].toLowerCase()) || office.includes(match[1].toLowerCase())
        }

        if (!match || !match[1]) {
          showWB = false
        } else {


          // if (CallingInstanceState.Connected !== this.state.status || (CallingInstanceState.Connected !== this.state.status && !this.state.meetingLocked) || this.state.call_starter === this.props.userId) {
          showWB = wbReady.includes(match[1].toLowerCase())
          // } else {
          //   showWB = false
          // }

        }



        const libre_regex = /\.(xls|doc|ppt)x?$/i
        const can_conv = libre_regex.test(file.filename)

        return <ContextMenu
          isEnabled={!isTravelling}
          key={file._id}
          actions={[
            {
              label: "View File",
              icon: <FiEye />,
              show: viewHidden,
              menuItemProps: {
                data: file,
                onClick: (e, data: IFolder) => {
                  console.log("Opening", data);
                  updateState(prevState => ({ ...prevState, currentFileId: file._id }))
                },
              }
            },

            {
              label: "Download File",
              icon: <DownloadIcon />,
              show: true,
              menuItemProps: {
                data: file,
                onClick: () => {
                  const link = document.createElement('a')
                  link.href = `${file.uri}/download`
                  link.download = file.filename

                  if (CallingInstanceState.Connected === this.state.status)
                    link.target = '_blank'

                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                },
              },
            },
            // {
            //   label: "Convert to PDF",
            //   icon: this.state.converting ? <Spinner /> : <PDFIcon />,
            //   show: can_conv,
            //   menuItemProps: {
            //     data: file,
            //     disabled: this.state.converting,
            //     onClick: (e, data: IFolder) => {
            //       console.log("Converting", data);
            //       this.convertFile(file.uri, file.filename)
            //     },
            //   },
            // },
            {
              label: "Open with Whiteboard",
              icon: <BiChalkboard />,
              show: selectedItems.length > 1 ? false : showWB,
              menuItemProps: {
                data: file,
                onClick: (e, data: IFile) => {
                  this.openWithWB(data);
                },
              },
            },
            {
              label: "Rename File",
              icon: <RenameIcon />,
              show: true,
              menuItemProps: {
                data: file,
                onClick: (e, data: IFolder) => {
                  console.log("RENAMING", data);
                  updateState((prevState) => ({
                    ...prevState,
                    modals: {
                      ...prevState.modals,
                      renameFile: true,
                    },
                  }));
                },
              },
            },
            {
              label: isPartOfSelected
                ? selectedItems.length > 1
                  ? `Move ${selectedItems.length} item(s)`
                  : "Move"
                : "Move",
              icon: <MoveIcon />,
              show: true,
              menuItemProps: {
                data: file,
                disabled: isQuickAccess,
                onClick: (e, data: IFolder) => {
                  console.log("MOVING TO", data, { rightClickedItem });
                  updateState((prevState) => ({
                    ...prevState,
                    modals: {
                      ...prevState.modals,
                      fileList: true,
                    },
                  }));
                },
              },
            },
            {
              label: isPartOfSelected
                ? selectedItems.length > 1
                  ? `Remove ${selectedItems.length} item(s)`
                  : "Remove"
                : "Remove",
              icon: <RemoveIcon />,
              show: true,
              menuItemProps: {
                data: file,
                onClick: (e, data: IFolder) => {
                  console.log("REMOVING", data);
                  // deleteItems()
                  updateState(prevState => ({
                    ...prevState,
                    modals: {
                      ...prevState.modals,
                      delete: true
                    }
                  }))
                },
              },
            },
            ...!this.props.isPresenterOnline ? [{
              label: "Present",
              icon: <Present />,
              show: true,
              menuItemProps: {
                data: file,
                onClick: () => {
                  this.props.changeIndexState({ currentFileId: file._id }, this.props.onPresent)
                },
              },
            }] : [],
          ]}
          contextMenuProps={{
            id: `file-${file._id}-context-menu`,
            onShow: () => {
              updateState((prevState) => {
                return {
                  ...prevState,
                  isFromRightClick: true,
                  rightClickedItem: file,
                  currentFile: file
                };
              });
            },
          }}
        >
          <Item
            isGridView={filesWidgetState.viewType === "grid"}
            item={file}
            key={file._id}
            onClick={() => {
              const { metaKey, ctrlKey } = keyboardKeys;

              let isPartOfSelected = (selectedItems || [])
                .map((item) => (item as IFile)._id)
                .includes(file._id);

              updateState((prevState: any) => {
                const opsys = (navigator.userAgentData || navigator.userAgent) as any

                let os = opsys.platform || (opsys.includes('Mac') ? 'macOS' : 'unknown')  //window.navigator!.userAgentData!.platform || 'unknown';
                return {
                  ...prevState,
                  selectedItems: (os === 'macOS' ? metaKey : ctrlKey)
                    ? !isPartOfSelected
                      ? [...prevState.selectedItems, file]
                      : prevState.selectedItems.filter((item: any) => {
                        if ("_id" in item) {
                          // if file, return true
                          return item._id !== file._id;
                        } else {
                          // folder
                          return item;
                        }
                      })
                    : [file],
                };
              })
            }}
            onDoubleClick={() => {
              updateState(prevState => ({ ...prevState, currentFileId: file._id }), () => this.props.setLastView('file'))
            }}
            selected={(selectedItems || []).map((item: IFile) => item._id).includes(file._id)}
            users={this.props.users}
          />
        </ContextMenu>
      });

    if (filesWidgetState.viewType === "grid") {
      return <Grid>{sortedFiles}</Grid>
    } else {
      return (
        <List>
          <ColumnHeader>
            <div></div>
            <div>Name</div>
            <div>Owner</div>
            <div>Date Uploaded</div>
          </ColumnHeader>
          {sortedFiles}
        </List>
      )
    }
  }
}

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 0px;
`

const Spinner = styled(SpinnerIcon)`
	animation: icon-spin 2s infinite linear;

	@keyframes icon-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(359deg);
		}
	}
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`


const ColumnHeader = styled.div`
	display: grid;
	grid-template-columns: 50px 3fr 1fr 1fr;
	padding: 8px 16px;
	min-height: 48px;
  font-size: 13px;
  font-weight: 400;

	> div {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`