import React, { Component } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ReactTooltip from "react-tooltip"

import { buildImageProxy } from '../../helpers/ApiCaller'
import CustomTip from '../../helpers/CustomTip'

interface Props {
	image: IImage
	imgClicked: () => void
	lazy: boolean
	isLoading?: boolean
	toggleModal: (a: boolean, b: string) => void
	travelling: boolean | undefined
}

interface IImage {
	url: string
	image_hash: string
}

export default class ImageGalleryItem extends Component<Props> {
	removeItem = (evt: any) => {
		evt.stopPropagation()
		ReactTooltip.hide()
		this.props.toggleModal(true, this.props.image.url)
	}

	render() {
		const { isLoading, travelling } = this.props

		let ren = (
			<ImageThumb
				className="rounded inner"
				onClick={() => !travelling && this.props.imgClicked()}
				src_url={isLoading ? '' : buildImageProxy(
					this.props.image.url,
					this.props.image.image_hash
				)}
				travelling={travelling}
			>
				{isLoading && <span style={{ color: 'white' }}>Loading</span>}
				{!travelling && <RemoveImageButton
					data-tip
					data-for="remove-button"
					onClick={this.removeItem}
				>
					&#10005;
				</RemoveImageButton>}

				{!travelling && <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Remove Image' dataFor='remove-button' />}

			</ImageThumb>
		)
		if (this.props.lazy) {
			ren = (
				<LazyLoad overflow once height={100}>
					{ren}
				</LazyLoad>
			)
		}
		return ren
	}
}

const RemoveImageButton = styled.button`
	width: 25px;
	height: 25px;
	background: rgba(0,0,0,0.2);
	outline: none;
	border: none;
	position: absolute;
	top: 4px;
	right: 4px;
	color: white;
	cursor: pointer;
	font-weight: bolder;
	font-size: 16px;
	display: none;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
`

const ImageThumb = styled.div<{ src_url: string, travelling: boolean | undefined }>`
	width: 140px;
	height: 140px;
	min-height: 140px;
	background-image: url(${props => props.src_url});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 10px;
	background-color: white;
	cursor: ${(props) => props.travelling ? 'default' : 'pointer'};
	position: relative;

	&:hover {
		opacity: 0.85;

		${RemoveImageButton} {
			display: flex;
		}
	}

	@media (max-width: 768px) {
		margin-bottom: 0px;
		margin-right: 15px;
		min-width: 140px;
	}

	@media (max-width: 480px) {
		min-width: 100px;
		min-height: 100px;
		height: 100px;
		width: 100px;
	}
`
