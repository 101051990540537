import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Quill, { DeltaStatic, Sources } from 'quill';
import { Scrollbars } from 'react-custom-scrollbars';

import Toolbar from './Toolbar';
import useNotesContext from '../useNotesContext';
import { EditorWrapper } from '../styledcomponents';
import useToastify from '../../../helpers/useToastify';

import 'quill/dist/quill.snow.css';
import { MSWORD_MATCHERS } from './format';
import LoadingComponent from '../../_Shared/LoadingComponent';
const BackgroundClass = Quill.import('attributors/class/background');
const ColorClass = Quill.import('attributors/class/color');
Quill.register(BackgroundClass, false);
Quill.register(ColorClass, false);
const Link = Quill.import('formats/link');
Link.sanitize = function(url: any) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url;
}


const { GlobalState } = require('reflux');

function Editor() {
    const { activeNote, roomDoc, sidebarNotes, userId, setActiveNote, connection, setInitialized } = useNotesContext()
    let lastUpdateTimeout: any = null

    useEffect(() => {
        if(!!!activeNote) return

        const sidebarNotesKey = Object.keys(sidebarNotes)
        const isExisting = sidebarNotesKey.includes(activeNote)
        if(!isExisting) {
            useToastify({
                message: 'The note you are currently viewing was removed.',        
                progressClassName: GlobalState.theming.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD',
            })
            
            setActiveNote(sidebarNotesKey?.length > 0 ? sidebarNotesKey?.[0] : null)
        }
    }, [activeNote, sidebarNotes])

    useEffect(() => {
        if(!!!activeNote) return 
        //@ts-ignore
        const notesDoc = connection.get('sharednotes', activeNote)
        try {
            if (!notesDoc) return
            // Mark: Room subscription to sharedb (notes)
            notesDoc.subscribe((error: any) => {
                if (error) return console.error("Error subscribing to notes", error)

                // If doc.type is undefined, the document has not been created, so let's create it
                // if (!notesDoc.type) {
                //     console.error("Error getting notes")
                // } else {
                    const options = {
                        theme: 'snow',
                        bounds: '#editor',
                        modules: {
                            clipboard: { matchers: MSWORD_MATCHERS, matchVisual: false },
                            history: {
                                delay: 1000,
                                maxStack: 100,
                                userOnly: true
                            },
                            toolbar: {
                                container: "#custom-notes-toolbar",
                                handlers: {
                                    'undo': () => {
                                        //@ts-ignore
                                        quill.history.undo()
                                    },
                                    'redo': () => {
                                        //@ts-ignore
                                        quill.history.redo()
                                    }
                                }
                            }
                        },
                        placeholder: 'Start taking notes!',
                    };

                    let quill = new Quill('#editor', options);
                    quill.setContents(notesDoc.data);
                    setInitialized(true);

                    /**
                     * On Text change publishing to our server
                     * so that it can be broadcasted to all other clients
                     */
                    quill.on('text-change', function (delta: DeltaStatic, oldDelta: DeltaStatic, source: Sources) {
                        if (source !== 'user') return;
                        notesDoc.submitOp(delta, { source: quill });
                        if(roomDoc && quill) {
                            //@ts-ignore
                            const notesSidebarDoc = connection.get("sharednotessidebar", activeNote);
                            if(!notesSidebarDoc) return 

                            const content = quill.getText(0, 70)?.trim()
                            const date_updated = moment().format()
                            notesSidebarDoc.submitOp([{
                                p: ['content'],
                                oi: content
                            },{
                                p: ['date_updated'],
                                oi: date_updated
                            }])

                            clearTimeout(lastUpdateTimeout) 
                            lastUpdateTimeout = setTimeout(() => {
                                notesSidebarDoc.submitOp([{
                                    p: ['updated_by'],
                                    oi: userId || null
                                }])
                            }, 1500)   
                        }

                    });

                    
                    /** listening to changes in the document
                     * that is coming from our server
                     */
                    notesDoc.on('op', function (op: any, source: any) {
                        if (source === quill) return;
                        if(!quill) return 
                        quill.updateContents(op);
                        if(roomDoc) {
                            //@ts-ignore
                            const notesSidebarDoc = connection.get("sharednotessidebar", activeNote);
                            if(!notesSidebarDoc) return 

                            const content = quill.getText(0, 70)?.trim()
                            const date_updated = moment().format()
                            notesSidebarDoc.submitOp([{
                                p: ['content'],
                                oi: content
                            }, {
                                p: ['date_updated'],
                                oi: date_updated
                            }])

                        }
                    });
                // }

            });
        } catch (error) {
            console.error("ERROR in Notes Editor: ", error)
        }

        return () => {
            notesDoc.unsubscribe((error: any) => {
                // if (error) return console.error(error)
            })
        }
    }, [activeNote, userId])

    return (
        <React.Fragment>
            <Toolbar key={`notes-toolbar-${activeNote}`}/>
            <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: 'calc(100% - 110px)' }}
            >
                <EditorWrapper id='editor' />
            </Scrollbars>
        </React.Fragment>
    )
}

export default Editor