import React from 'react';

const SSOComponent = (props) => {
    React.useEffect(() => {
        const lastMeeting = localStorage.getItem('lastMeeting');
        const token = props.match.params.token;
        if (token) {
            setToken(token);
            if(lastMeeting) {
                window.location.href = `/${lastMeeting}`;
            } else {
                window.location.href = "/create-or-join"
            }
        }
        else {
            //console.log('REDIRECTING TO login', lastMeeting);
        }
    }, []);

    const setToken = async (token) => {
            localStorage.setItem('token', token)
    }

    return <>Signing in...</>;
};

export default SSOComponent;
