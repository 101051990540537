import React from 'react'

import Header from '../Header'
import Form from './Form'
import { Container } from './styled'

function Login() {
  return (
    <Container>
      <Header />
      <Form />
    </Container>
  )
}

export default Login