import styled from 'styled-components'
import FlipMove from 'react-flip-move'

import {WidgetContainer as WidgetContainerOld} from '../WidgetItem'

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  /* @media (max-width: 970px) {
		height: 172px;
	} */

  @media (max-width: 970px), (max-width: 900px) and (orientation: landscape) {
    ${({collapse}) =>
      collapse
        ? 'height: 44px; min-height: 44px;'
        : 'height: 150px; min-height: 150px;'}
  }
`

export const CollapseWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;

  .sync-title {
    font-weight: 500;
    flex: 1;
    font-size: 16px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px !important;
  }

  @media (max-width: 970px) {
    display: none;
  }
`

export const LeftUserUI = styled.div`
  position: absolute;
  bottom: 50px;
  height: auto !important;
  padding: 15px 10px !important;
  right: 10px;
  display: flex;
  align-items: center;
  max-width: 300px;
  z-index: 2;
  box-shadow: ${(props) => props.theme.shadows.newlight};

  .image-wrapper {
    width: 30px;
    height: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .joined-message {
    display: flex;
    font-weight: 400;
    font-size: 13px;

    .name {
      font-weight: 500;
      margin: 0 5px;
    }
  }
`

export const JoinedUserUI = styled.div`
  position: absolute;
  top: 10px;
  height: auto !important;
  padding: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  max-width: 300px;
  z-index: 2;
  box-shadow: ${(props) => props.theme.shadows.newlight};

  .image-wrapper {
    width: 30px;
    height: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .joined-message {
    display: flex;
    font-weight: 400;
    font-size: 13px;

    .name {
      font-weight: 500;
      margin: 0 5px;
    }
  }
`

export const ToggleList = styled.div`
  position: absolute;
  bottom: 50px;
  left: ${(props) => (props.expanded ? '95px' : '5px')};
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  cursor: pointer;
  transition: 0.3s left ease-in-out;

  .button {
    padding: 7px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${(props) => props.theme.shadows.newlight};
  }

  .sync-title {
    margin: 0px 5px;
    font-weight: 500;
    font-size: 16px;
  }

  @media (max-width: 970px) {
    display: none;
  }
`

export const UserAvatarWidget = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ongoing {
    position: absolute;
    bottom: 5px;
    color: white;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
`

export const WidgetContainer = styled(WidgetContainerOld)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
`

export const CurrentWidgetWrapper = styled.div`
  width: 100%;
  display: flex;
  min-width: 100%;
  /* flex: 1;	 */

  @media (max-width: 970px) {
    /* height: 70%; */
    flex: 1;
    flex-direction: column;
    height: ${(props) => (props.keynote ? 'calc(100% - 172px)' : '100%')};
  }
`

export const CurrentWidget = styled(FlipMove).attrs({
  appearAnimation: 'fade',
  leaveAnimation: 'fade',
  enterAnimation: 'fade',
})`
  ${(props) =>
    props.isInCall
      ? `
		width: calc(100% - ${
      props.keynote && props.expanded && !props.callView
        ? '420px'
        : props.callView && props.expanded
        ? '200px'
        : props.callView && !props.expanded
        ? '0px'
        : '220px'
    });
		max-width: calc(100% - ${
      props.keynote && props.expanded && !props.callView
        ? '420px'
        : props.callView && props.expanded
        ? '200px'
        : props.callView && !props.expanded
        ? '0px'
        : '220px'
    });
		min-width: calc(100% - ${
      props.keynote && props.expanded && !props.callView
        ? '420px'
        : props.callView && props.expanded
        ? '200px'
        : props.callView && !props.expanded
        ? '0px'
        : '220px'
    });`
      : !!props.currentWidget
      ? `
			width: ${props.keynote && props.expanded ? 'calc(100% - 200px)' : '100%'};`
      : 'width: 100%;'}
  display: flex;
  align-items: center;
  justify-content: center;
  > div:not(.helperButtons) {
    height: Calc(100% - 50px);
    max-width: Calc(100% - 50px);
    margin: 0 auto;
  }

  > div.floatingWrapper {
    margin: 0;
    height: 40px;
  }

  @media (max-width: 970px) {
    /* width: ${(props) => (props.isInCall ? 'calc(100% - 215px)' : '100%')};
		max-width: ${(props) => (props.isInCall ? 'calc(100% - 215px)' : '100%')};
		min-width: ${(props) => (props.isInCall ? 'calc(100% - 215px)' : '100%')}; */
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: ${(props) =>
      !props.isInCall
        ? '100%'
        : props.currentWidget && !props.isInCall
        ? '100%'
        : props.isInCall && !props.currentWidget
        ? '100%'
        : 'calc(100% - 160px)'};
    max-height: ${(props) =>
      !props.isInCall
        ? '100%'
        : props.currentWidget && !props.isInCall
        ? '100%'
        : props.isInCall && !props.currentWidget
        ? '100%'
        : 'calc(100% - 160px)'};
    min-height: ${(props) =>
      !props.isInCall
        ? '100%'
        : props.currentWidget && !props.isInCall
        ? '100%'
        : props.isInCall && !props.currentWidget
        ? '100%'
        : 'calc(100% - 160px)'};

    .call-tile-wrapper {
      padding: 10px 7px;
    }
  }
`

export const SyncsTileContainer = styled.div`
  /* width: 80px; */
  height: 80px;
  margin: 8px;
  cursor: pointer;
  position: relative;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .helperButtons > * {
      opacity: 1;
    }
  }

  @media (max-width: 970px) {
    min-width: 80px;
    width: 80px;
    max-width: 80px;
  }
`

export const SideItemContainer = styled.div`
  cursor: pointer;
  margin: 20px 30px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;

  .widgetTitleBar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-left: 128px; */
    z-index: 2;
    pointer-events: none;

    img {
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      text-align: center;
    }
  }

  &:hover {
    &:before {
      opacity: 0.12;
    }
    .helperButtons > * {
      opacity: 1;
    }
  }
  > *:first-child {
    max-height: 100%;

    zoom: 0.6;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    margin: 0 !important;

    > * {
      overflow: hidden;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.01);
      opacity: 0.01;
    }
  }

  span {
    position: absolute;
    right: 25px;
    bottom: 25px;
    width: 20px;
    height: 20px;
    background: red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.callingSlide
      ? `
		position: sticky;
		top: -5px;
		z-index: 3;
		margin: 0;
		padding: 15px 30px;
	`
      : ''}

  @media (max-width: 970px) {
    ${(props) =>
      props.callingSlide
        ? `
			min-width: 140px !important;
			margin: 0 !important;
			padding: 0 10px;
			top: 0;
			left: 20px;
			border-radius: 0px !important;

			& + div {
				margin-left: ${props.isInCall ? '0px' : '20px'};
			}
		`
        : ''}
  }
`

export const List = styled(FlipMove)`
  width: 30%;
  max-width: 110px;
  min-width: 110px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  padding-bottom: 15px;
  height: 100%;
  /* padding-left: 10px; */
  transition: 0.3s display ease-in-out;

  ${(props) => (!props.expanded ? 'display: none;' : '')}

  /* Moves scrollbar to left */
	/* direction: rtl; */
	* {
    direction: ltr;
  }

  /* @media (min-width: 970px) {
		::-webkit-scrollbar {
			width: 6px;
		}

		::-webkit-scrollbar-track {
			background: transparent;
		}

		::-webkit-scrollbar-thumb {
			border-radius: 3px;
			background: rgba(0, 0, 0, 0.2);
		}
	} */
  @media (max-width: 970px) {
    position: relative;
    width: 100%;
    height: 172px;
    max-width: 100%;
    flex-direction: row;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    align-items: center;
    padding: 5px 10px;
    /* padding-left: 0px; */
    ${(props) => (props.isInCall ? 'padding-left: 0px;' : '')}
    direction: ltr;

    & > * {
      width: 120px;
      margin: 0 6px;
      min-width: 120px;
      height: max-content;
      z-index: 1;
    }

    & > *:nth-child(2) {
      margin-left: 155px;
      ${(props) => !props.withAdder && `margin-left: 0px;`}
    }

    & > .ongoing-call-tile {
      ${(props) => (props.isInCall ? 'left: 0px; z-index: 2;' : '')}
    }
  }

  @media (max-width: 970px), (max-width: 900px) and (orientation: landscape) {
    ${({collapse}) => (collapse ? 'display: none; *{display: none} ' : null)}

    & > *:nth-child(2) {
      margin-left: 0px;
    }

    > .container.rounded {
      height: 105px;
      width: 105px;
      min-width: 105px;
    }
  }
`

export const Container = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: row;
  overflow: hidden; //hide overlapping views when in setup mode

  @media (max-width: 970px) {
    flex-direction: column-reverse;
    /* padding-top: 142px; */

    .notes-contentwrapper {
      max-height: ${(props) =>
        !!!props.mobileCollapse ? `calc(100vh - 272px)` : ``};
    }
  }

  #main-grid.meeting-main-with-sidebar + #floating-widget {
    right: 174px;
  }

  #main-grid.meeting-main-with-sidebar ~ .floating-sync {
    right: 174px;
  }

  #main-grid.meeting-main-with-sidebar ~ .floating-container {
    right: 225px;
  }

  @media (max-width: 767px) {
    #left-wrapper {
      display: none;
    }

    #main-grid.meeting-full-main {
      grid-template-areas:
        'main main'
        'footer footer'
        'aside aside';
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 70px 0;
    }

    #main-grid.meeting-main-with-sidebar {
      grid-template-rows: 1fr 130px 70px;
      grid-template-columns: 1fr 25px;
      grid-template-areas:
        'main'
        'aside'
        'footer';
    }

    #main-grid.meeting-main-with-sidebar > aside {
      margin: auto 0;
    }

    #floating-widget,
    .floating-sync {
      bottom: 35px;
      right: 35px;
    }

    .meeting-duration {
      position: fixed;
      top: 90px;
      left: 20px;
    }

    .floating-container {
      bottom: 35px;
      right: 85px;
    }

    #main-grid .meeting-controls button {
      margin: 0 4px;
    }

    #main-grid.meeting-main-with-sidebar .participants-wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      justify-items: center;
    }

    #main-grid.meeting-main-with-sidebar + #floating-widget {
      right: 35px;
      /* bottom: 163px; */
    }

    #main-grid.meeting-main-with-sidebar ~ .floating-sync {
      right: 35px;
    }

    #main-grid.meeting-main-with-sidebar ~ .floating-container {
      right: 85px;
    }
  }

  @media (max-width: 320px) {
    #main-grid {
      padding: 15px;
    }

    #floating-widget,
    .floating-sync {
      bottom: 24px;
      right: 20px;
    }
  }

  @media (max-width: 420px) {
    .floating-container {
      min-width: 280px;
      max-width: 280px;
    }
  }
`

export const GhostContainer = styled.div`
  position: absolute;
  flex: 1;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: initial;
  max-width: initial;
  margin-top: 0;
  top: 0;
  padding: 20px;
`

export const Collapse = styled.div`
  transition: all 0.3s ease;
  height: 27px;
  width: 27px;
  cursor: pointer;
  margin-left: auto;
  ${({collapse}) =>
    collapse
      ? `bottom: 10px; padding: 5px !important;`
      : `bottom: 155px; transform: rotate(180deg); padding: 5px 5px 5px 6px !important;`}
`

export const MobileCollapseWrapper = styled.div`
  display: none;

  @media (max-width: 970px), (max-width: 900px) and (orientation: landscape) {
    display: flex;
    flex-direction: row;
    padding: 9px 15px 0 15px;
    align-items: center;

    .sync-title {
      font-weight: 500;
      font-size: 16px;
    }

    .add {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 5px !important;
      margin-right: 35px;
      margin-left: 10px;
    }
  }
`

export const MainDivGrid = styled.div`
  @media (max-width: 970px) and (min-width: 768px) {
    max-height: ${({mobileCollapse}) => mobileCollapse ? `calc(100% - 45px)` : `calc(100% - 150px)`};
  }

  @media (max-width: 767px) {
    max-height: 100%;
  }
`