import React, { Component } from "react";
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";

// Components
import VideoTrack from "./VideoTrack";
import CallItemControlsTwilio from "./CallItemControlsTwilio";
import { AspectRatioEnforcer, Background, MutedWrapper, IconButtonMute, XLine } from "./LocalParticipantStyles";
import TrackWaveMeter from '../../../Meeting/Sidebar/TrackWaveMeter'

import { VariableCallingActions } from "../../../../stores/VariableCallingStore";
import { initializeTranscription } from "../../../../calling/_utils";

import VolumeIcon from "/assets/icons/micOn.svg";

interface State {
  tracks: any[];
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  transcriptionTrack: any;
  selectedVideoDeviceId: any;
  selectedAudioDeviceId: any;
}

interface Props {
  participant: any;
  isLocalParticipant?: boolean;
  user: any;
  room: any;
  canSharescreen?: boolean;
  isSharingScreen?: boolean;
  localVideoTrack?: any;
  localAudioTrack?: any;

}

class LocalParticipantUI extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const videoTracks = [...this.props.participant.videoTracks.values()]
    const audioTracks = [...this.props.participant.audioTracks.values()]
    
    this.state = {
      tracks: [],
      isAudioEnabled: audioTracks[0] ? audioTracks[0].isTrackEnabled : false,
      isVideoEnabled: videoTracks[0] ? videoTracks[0].isTrackEnabled : false,
      transcriptionTrack: null,
      selectedVideoDeviceId: null,
      selectedAudioDeviceId: null,
    }
  }

  async componentDidMount() {
    // EVENT HANDLER
    if (window) {
      // //console.log('EVENT LISTENER CHAT')
      window.addEventListener('AUDIO_DEVICE_CHANGE', this.handleAudioDeviceChanged)
      window.addEventListener('VIDEO_DEVICE_CHANGE', this.handleVideoDeviceChanged)
    }
    
    navigator.mediaDevices.ondevicechange = this.mkDeviceChangeHandler();

    // default devices
    let selectedVideoDeviceId = localStorage.getItem('video_input_device_id')
    let selectedAudioDeviceId = localStorage.getItem('audio_input_device_id')
    this.setState({ selectedVideoDeviceId, selectedAudioDeviceId })
  }

  componentWillUnmount() {
    window.removeEventListener('AUDIO_DEVICE_CHANGE', this.handleAudioDeviceChanged)
    window.removeEventListener('VIDEO_DEVICE_CHANGE', this.handleVideoDeviceChanged)
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return JSON.stringify(nextProps.participant) !== JSON.stringify(this.props.participant) || 
      JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user) || 
      this.props.isLocalParticipant !== nextProps.isLocalParticipant ||
      this.props.canSharescreen !== nextProps.canSharescreen || 
      this.props.isSharingScreen !== nextProps.isSharingScreen || 
      JSON.stringify(this.state.tracks) !== JSON.stringify(nextState.tracks) || 
      this.state.isAudioEnabled !== nextState.isAudioEnabled || 
      this.state.isVideoEnabled !== nextState.isVideoEnabled || JSON.stringify(nextProps.localVideoTrack) !== JSON.stringify(this.props.localVideoTrack) || JSON.stringify(nextProps.localAudioTrack) !== JSON.stringify(this.props.localAudioTrack)
  }

  mkDeviceChangeHandler = () => async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter((device) => device.kind === "audioinput" && device.label !== '' && device.deviceId !== 'default');
    if (!audioDevices[0]) return;

    const event = new CustomEvent(`AUDIO_DEVICE_CHANGE`, { detail: { audio_input_device_id: audioDevices?.[0]?.deviceId } })
    localStorage.audio_input_device_id = audioDevices?.[0]?.deviceId
    window.dispatchEvent(event)
  };

  /*
    Video
  */
  handleVideoDeviceChanged = (e: any) => {
    const { detail } = e
    //console.log('handleVideoDeviceChanged:', detail.video_input_device_id)
    this.updateVideoDevice(detail.video_input_device_id)
  }

  getVideoTrackOptions = (selectedVideoDeviceId?: string) => {
    let deviceId = selectedVideoDeviceId || localStorage.getItem("video_input_device_id") || 'default'
    this.setState({ selectedVideoDeviceId: deviceId })

    const options = {
      deviceId: { ideal: deviceId },
    }

    //console.log('getVideoTrackOptions', options)

    return options;
  };

  toggleVideo = async () => {
    /*
      Local Video Track exists?
          - YES -> enable localVideoTrack
            Is selectedVideoDeviceChanged ?
              - YES -> stop video track, 
          - NO -> create new LocalVideoTrack, then publish
    */
  
    if (!this.state.isVideoEnabled) {
      // Enable
      if (this.props.localVideoTrack) {
        // If device is changed
        if (localStorage.getItem("video_input_device_id") !== this.state.selectedVideoDeviceId) {
          // Unpublish Current track...
          this.unpublishVideoTrack()

          // Publish New Video track using the new video device id
          const deviceId = localStorage.getItem("video_input_device_id") || undefined
          this.createAndPublishVideoTrack(deviceId);
        } else {
          // Disable BUT do not stop
          //console.log('Toggling Video ON, enabling localVideoTrack')
          this.props.localVideoTrack.enable();
        }
      } else {
        this.createAndPublishVideoTrack();
      }
    } else {
      // Disable BUT do not stop
      if (this.props.localVideoTrack) {
        this.props.localVideoTrack.disable();
      }
    }

    this.setState({ isVideoEnabled: !this.state.isVideoEnabled })
  }

  updateVideoDevice = async (selectedVideoDeviceId: string) => {
    localStorage.setItem("video_input_device_id", selectedVideoDeviceId);
    //console.log('Updating Video Device', selectedVideoDeviceId)

    // Do not publish yet when toggled OFF
    if (!this.state.isVideoEnabled) {
      return
    }

    this.publishVideo(selectedVideoDeviceId)
  }

  unpublishVideoTrack = async () => {
    if (this.props.localVideoTrack) {
      this.props.room.localParticipant.unpublishTrack(this.props.localVideoTrack)
      this.props.localVideoTrack.stop()
      VariableCallingActions.StopLocalVideoTrack()
      VariableCallingActions.SetLocalVideoTrack(null)
    }
  }

  createAndPublishVideoTrack = async (selectedVideoDeviceId?: string) => {
    // Create Local Video Track
    const videoTrack = await createLocalVideoTrack(this.getVideoTrackOptions(selectedVideoDeviceId));

    // Set to Video Track to Store
    VariableCallingActions.SetLocalVideoTrack(videoTrack);

    // Publish to Twilio
    this.props.room.localParticipant.publishTrack(videoTrack)
  }

  publishVideo = async (selectedVideoDeviceId: string) => {
    // Unpublish Current track...
    this.unpublishVideoTrack()

    // Publish
    this.createAndPublishVideoTrack(selectedVideoDeviceId)
  }

  /*
    AUDIO
  */
  handleAudioDeviceChanged = (e: any) => {
    const { detail } = e
    //console.log('handleAudioDeviceChanged:', detail.audio_input_device_id)
    this.updateAudioDevice(detail.audio_input_device_id)
  }

  getAudioTrackOptions = (selectedAudioDeviceId?: string) => {
    let deviceId = selectedAudioDeviceId || localStorage.getItem("audio_input_device_id") || 'default'
    this.setState({ selectedAudioDeviceId: deviceId })

    const options = {
      deviceId: { ideal: deviceId },
    }

    //console.log('getAudioTrackOptions', options)

    return options;
  };

  toggleAudio = async () => {
    if (!this.state.isAudioEnabled) {
      // Enable

      if (this.props.localAudioTrack) {
        // If device is changed
        if (localStorage.getItem("audio_input_device_id") !== this.state.selectedAudioDeviceId) {
          // Unpublish Current track...
          this.unpublishAudioTrack()

          // Publish New Audio track using the new audio device id
          const deviceId = localStorage.getItem("audio_input_device_id") || undefined
          const audioTrack = await this.createAndPublishAudioTrack(deviceId);

           // Setup Transcription
          const transcriptionTrack = initializeTranscription(audioTrack)
          VariableCallingActions.SetTranscriptionTrack(transcriptionTrack)
        } else {
          // Disable BUT do not stop
          //console.log('**twil', 'Toggling Audio ON, enabling localAudioTrack')
          this.props.localAudioTrack.enable();

          // Setup Transcription
         const transcriptionTrack = initializeTranscription(this.props.localAudioTrack)
         VariableCallingActions.SetTranscriptionTrack(transcriptionTrack)
        }
      } else {
        //console.log('**twil', 'Joined without Audio... creating and publishing new audio track');
        await this.createAndPublishAudioTrack();
      }


     
    } else {
      // Disable BUT do not stop
      if (this.props.localAudioTrack) {
        this.props.localAudioTrack.disable();
      }

      VariableCallingActions.StopTranscriptionTrack()
    }

    this.setState({ isAudioEnabled: !this.state.isAudioEnabled })
  }

  updateAudioDevice = async (selectedAudioDeviceId: string) => {
    localStorage.setItem("audio_input_device_id", selectedAudioDeviceId);
    //console.log('Updating Audio Device', selectedAudioDeviceId)

    // Do not publish yet when toggled OFF
    if (!this.state.isAudioEnabled) {
      return
    }

    this.publishAudio(selectedAudioDeviceId)
  }

  unpublishAudioTrack = async () => {
    if (this.props.localAudioTrack) {
      this.props.room.localParticipant.unpublishTrack(this.props.localAudioTrack)
      this.props.localAudioTrack.stop()
      VariableCallingActions.StopLocalAudioTrack()
      VariableCallingActions.SetLocalAudioTrack(null)
      VariableCallingActions.StopTranscriptionTrack()
    }
  }

  createAndPublishAudioTrack = async (selectedAudioDeviceId?: string) => {
    // Create Local Audio Track
    const audioTrack = await createLocalAudioTrack(this.getAudioTrackOptions(selectedAudioDeviceId));

    // Set to Audio Track to Store
    VariableCallingActions.SetLocalAudioTrack(audioTrack);

    // Publish to Twilio
    this.props.room.localParticipant.publishTrack(audioTrack);

    // Setup Transcription
    const transcriptionTrack = initializeTranscription(audioTrack)
    VariableCallingActions.SetTranscriptionTrack(transcriptionTrack)

    return audioTrack
  }

  publishAudio = async (selectedAudioDeviceId: string) => {
    // Unpublish Current track...
    this.unpublishAudioTrack()

    // Publish
    this.createAndPublishAudioTrack(selectedAudioDeviceId)
  }

  /*
    handle screenshare
  */
  handleScreenSharingEnabling = () => {
    VariableCallingActions.Screenshare(true)
  }

  render() {
    
    const { isVideoEnabled, isAudioEnabled } = this.state;
    const { isLocalParticipant, user, participant, canSharescreen, isSharingScreen, localVideoTrack, localAudioTrack } = this.props;
    
    return (
        <AspectRatioEnforcer>
          { !isVideoEnabled && <Background
            className="container content"
            avatar_url={(user || {}).avatar_url || ""}
            style={{ zIndex: 1 }}
          /> }

          {
            localVideoTrack && <VideoTrack isLocalParticipant={true} track={localVideoTrack} />
          }

          {
            isAudioEnabled && <MutedWrapper className={isLocalParticipant ? `muted` : ''}>
              <TrackWaveMeter track={localAudioTrack} />
            </MutedWrapper>
          }

          <CallItemControlsTwilio
            audioEnabled={isAudioEnabled}
            videoEnabled={isVideoEnabled}
            onVideoClick={() => {
              this.toggleVideo();
            }}
            onAudioClick={() => {
              this.toggleAudio();
            }}
            startScreenshare={() => {
              this.handleScreenSharingEnabling()
            }}
            canSharescreen={canSharescreen}
            isSharingScreen={isSharingScreen}
            participant={participant}
          />
          
          {
            !isAudioEnabled && <MutedWrapper>
              <IconButtonMute className="button" muted={true}>
                <img src={VolumeIcon} width={11} />
                <XLine />
              </IconButtonMute>
            </MutedWrapper>
          }

        </AspectRatioEnforcer>
    );
  }
}
export default LocalParticipantUI;
