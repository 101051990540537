import React, { memo } from 'react';
import styled from 'styled-components';
import { FiCreditCard as CreditCardIcon } from 'react-icons/fi';
import moment from 'moment';

import Modal from '../../../components/Modalv2';
import Table from '../../../components/Table';

import LoopApi from '../../../helpers/LoopApi';
import { ModalActions } from '../../../stores/ModalStore';
// import Receipt from '../../../assets/icons/receipt.svg';
import Visa from '../../../assets/icons/visa.svg';
import Mastercard from '../../../assets/icons/mastercard.svg';

const { GlobalState } = require('reflux');

// const basic = 'price_1Jtq04DRSSBybS25JXStmhRn';
// const pro = 'price_1KEuKJDRSSBybS25Pe15F7YS';
const free = ['price_1KHeZjDRSSBybS25lLX5fgGB', 'price_1KHeZZDRSSBybS254fyI62eK']

interface Props {
    closeModal: () => void
}

interface IBillings {
    plan: null | string
    subscription: null | ISubscription
    invoices?: IInvoices
    product: IProducts
    pm: IPM | null
}

interface ISubscription {
    // billing_cycle_anchor: 1654266445
    current_period_end: number
    current_period_start: number
    // id: "sub_1L6bTFDRSSBybS25PU5YA302"
    plan: { interval: string }
    // amount: 155.88
    // id: "price_1KfP35DRSSBybS25jD1RkX6H"
    // interval: "yearly"
}

interface IInvoices {
    data: any[]
}

interface IPM {
    card: { brand: string, last4: string }
}

interface IPrice {
    // active: true
    // billing_scheme: "per_unit"
    // created: 1642123651
    // currency: "usd"
    id: string
    // livemode: false
    // lookup_key: null
    // metadata: {type: "recurring"}
    // nickname: null
    // object: "price"
    // product: "prod_Kujg3Y02x8e5AW"
    // recurring: {aggregate_usage: null, interval: "year", interval_count: 1, trial_period_days: null,…}
    // tax_behavior: "unspecified"
    // tiers_mode: null
    // transform_quantity: null
    // type: "recurring"
    // unit_amount: 0
    // unit_amount_decimal: "0"
}

interface IProducts {
    prices: IPrice[]
    name: string
    description: string
}

const Billings = memo((props: Props) => {
    const [billings, setBillings] = React.useState<IBillings | null>(null);
    const [products, setProducts] = React.useState<IProducts[] | null>(null)
    const [freePlan, setFreePlan] = React.useState<IProducts | null>(null);

    React.useEffect(() => {
        getFullBillings();
        getProducts()
    }, []);

    const getFullBillings = async () => {
        const { _id, subscription } = GlobalState.auth.jwt.data;
        const sub = await LoopApi(null, 'StripeGetFullBillings', { id: _id, subscription });
        setBillings(sub);
        if(!sub.subscription) {
            const freePlanInfo = await LoopApi(null, 'StripeGetPlanInfo');
            setFreePlan(freePlanInfo)
        }
    };

    const cancelSubscription = async () => {
        const data = await LoopApi(null, 'StripeCancelSubscription');
        localStorage.setItem('token', data.token);
        props.closeModal();
        window.location.reload();
    };

    const getProducts = async () => {
        const products = await LoopApi(null, 'StripeGetAllProducts')
        setProducts(products)
    }

    if (!billings) return <React.Fragment></React.Fragment>;
    
    const currentProduct = (billings.subscription && billings.product ? billings.product : freePlan) as IProducts 
    return (
        <Modal closeModal={props.closeModal} dismissible={true}>
            <Container className='topbar-modal'>
                <Header><CreditCardIcon size={20}/> Billing</Header>
                <Content>
                    <Left>
                        <Label>Current Plan</Label>
                        <WrapperWithBg className='rounded inner container'>
                            <PlanName>{currentProduct?.name}</PlanName>
                            <FeatureList>
                                <div className='label'>Features</div>
                                <ul>
                                    {
                                        currentProduct?.description?.split(',')?.map((desc) => {
                                            return <li>{desc}</li>
                                        })
                                    }
                                </ul>
                            </FeatureList>
                            <Buttons>
                                {
                                    billings.subscription ? 
                                    <div className='button default' onClick={() => ModalActions.SetModalWithParams('CancelSubscription', { billings })}>
                                        Cancel Subscription
                                    </div> : null
                                }
                                <div className="button primary" onClick={() => ModalActions.SetModal('Subscription')}>
                                    {billings?.subscription ? 'Switch Plan' : 'Upgrade'}
                                </div>
                            </Buttons>
                        </WrapperWithBg>
                    </Left>
                    <Right>
                        <Label>Billing Period</Label>
                        <WrapperWithBg className='rounded inner container'>
                            {
                                !billings?.subscription ?
                                <div className='upgrade-label'>Upgrade your plan</div> : 
                                <BillingWrapper>
                                    <BillingLabel>{billings.subscription?.plan?.interval}</BillingLabel>
                                    <BillingPeriod>
                                        {moment.unix(billings.subscription.current_period_start).format('DD MMM YYYY')} to{' '}
                                        {moment.unix(billings.subscription.current_period_end).format('DD MMM YYYY')}
                                    </BillingPeriod>
                                    <BillingNextPayment>
                                        Next payment on{' '}{moment.unix(billings?.subscription?.current_period_end).format('DD MMM YYYY')}
                                    </BillingNextPayment>
                                </BillingWrapper>
                            }
                        </WrapperWithBg>

                        <Label style={{ marginTop: '15px' }}>Payment Details</Label>
                        <WrapperWithBg className={`rounded inner container ${billings.subscription ? 'payment' : ''}`}>
                            {
                                !billings?.subscription ?
                                <div className='upgrade-label'>Upgrade your plan</div> : 
                                <PaymentWrapper>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <div>
                                            <img src={billings?.pm?.card?.brand?.toLowerCase() === 'visa' ? Visa : Mastercard} alt={billings?.pm?.card?.brand?.toLowerCase() === 'visa' ? 'Visa' : 'Mastercard'} />
                                        </div>
                                        <PaymentCard>
                                            {
                                            [...Array(3).keys()].map(() => {
                                                return <div>&#8226;&#8226;&#8226;&#8226;</div>
                                            })
                                            }
                                            { billings?.pm?.card?.last4 }
                                        </PaymentCard>
                                    </div>
                                    <div className='button default' onClick={() => ModalActions.SetModalWithParams('ChangePaymentMethod', { billings })}>Change</div>
                                </PaymentWrapper>
                            }
                        </WrapperWithBg>
                    </Right>
                </Content>
                {
                    (billings?.invoices?.data || [])?.length > 0 ?
                    <Footer>
                        <PlanName>Payment History</PlanName>
                        <Table
                            key={`${(billings?.invoices?.data || []).length}-invoices`}
                            columns={[{
                                Header: 'Date of Payment',
                                accessor: '',
                                Cell: ({ original }) => {
                                    return <div style={{ textAlign: 'center' }}>{
                                        original?.status_transitions?.paid_at ? 
                                        moment.unix(original?.status_transitions?.paid_at).format('MMM DD, YYYY HH:mm:ss') :
                                        'Pending payment'
                                    }</div>
                                }
                            },
                            // {
                            //     Header: 'Plan',
                            //     accessor: '',
                            //     Cell: ({ original }) => {
                            //         return  <div style={{ textAlign: 'center' }}>{original?.plan || ''}</div>
                            //     }
                            // },
                            {
                                Header: 'Payment Amount',
                                accessor: '',
                                Cell: ({ original }) => {
                                    return  <div style={{ textAlign: 'center' }}>{original?.currency === 'usd' ? '$' : ''}{((original?.amount_paid/100) || 0)?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                }
                            },{
                                Header: 'eReceipt',
                                accessor: '',
                                width: 120,
                                Cell: ({original}) => {
                                    return <div style={{ textAlign: 'center' }} className="color-textimp">
                                        {
                                            original.hosted_invoice_url ?
                                            <a
                                                href={original.hosted_invoice_url}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <Receipt />
                                                {/* <img src={Receipt} alt="Receipt" /> */}
                                            </a> : ''

                                        }
                                    </div>
                                }
                            }
                            ]}
                            data={billings?.invoices?.data || []}
                            defaultPageSize={(billings?.invoices?.data || []).length === 0 ? 5 : (billings?.invoices?.data || []).length}
                            showPagination={false}
                            sortable={false}
                            noPointer
                            noBorder
                            withMinHeight={false}
                            withMaxHeight={false}
                        />
                    </Footer> 
                    : null
                }
            </Container>
        </Modal>
    );
});

export default Billings;

const Container = styled.div`
    padding: 30px;
    min-width: 80vw;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media (min-width: 1369px) {
        min-width: 60vw;
        max-width: 60vw;
    }


    @media (max-width: 1123px) {
        min-width: 90vw;
        max-width: 90vw;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;

    svg {
        margin-right: 5px;
    }
`

const Content = styled.div`
    display: flex;
    width: 100%;
    padding: 25px;
`

const Left = styled.div`
    flex: 1;
    padding-right: 10px;
`
const Right = styled.div`
    flex: 1;
    padding-left: 10px;
`
const Label = styled.div`
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 10px;
`

const WrapperWithBg = styled.div`
    padding: 20px;

    .upgrade-label {
        text-align: center;
        opacity: 0.5;
        font-weight: 500;
    }

    @media (max-width: 1123px) {
        &.payment {
            padding: 10px 10px 15px;
        }
    }
`

const PlanName = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
`

const FeatureList = styled.div`
    .label {
        font-weight: 500;
        margin-bottom: 5px;
    }

    ul {
        padding-left: 20px;
        list-style: disc;

        li {
            line-height: 16px;
        }
    }
`

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 25px 25px;
`

const Buttons = styled.div`
    padding: 20px 0 0;
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    .button {
        padding: 10px 12px;
        text-align: center;
        cursor: pointer;
        flex: 1;
        margin: 0 4px;
    }

    @media (max-width: 1123px) {
        flex-direction: column;

        > .button:not(:first-child) {
            margin-top: 5px;
        }
    }
`

const BillingWrapper = styled.div``

const BillingLabel = styled.div`
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
`

const BillingPeriod = styled.div`
    line-height: 18px;
`

const BillingNextPayment = styled.div`
    line-height: 18px;
`

const PaymentWrapper = styled.div`
    display: flex;
    align-items: center;

    .button {
        padding: 10px 12px;
        text-align: center;
        cursor: pointer;
    }

    img  {
        width: 50px;
    }

    @media (max-width: 876px) {
        flex-direction: column;
    }
`

const PaymentCard = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    flex: 1;
    margin-left: 15px;

    > div {
        margin-right: 10px;
        font-size: 24px;
        line-height: 16px;
        display: flex;
        align-items: center;
    }
`

const Receipt = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-fill">
            <path d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H9C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V9H14V11C14 11.7956 13.6839 12.5587 13.1213 13.1213C12.5587 13.6839 11.7956 14 11 14H3C2.20435 14 1.44129 13.6839 0.87868 13.1213C0.316071 12.5587 0 11.7956 0 11V2ZM11 10V13C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11V10H11ZM10 13V2C10 1.73478 9.89464 1.48043 9.70711 1.29289C9.51957 1.10536 9.26522 1 9 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13H10ZM3 3.5C3 3.36739 3.05268 3.24021 3.14645 3.14645C3.24021 3.05268 3.36739 3 3.5 3H7.5C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5C8 3.63261 7.94732 3.75979 7.85355 3.85355C7.75979 3.94732 7.63261 4 7.5 4H3.5C3.36739 4 3.24021 3.94732 3.14645 3.85355C3.05268 3.75979 3 3.63261 3 3.5ZM3 6.5C3 6.36739 3.05268 6.24021 3.14645 6.14645C3.24021 6.05268 3.36739 6 3.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H3.5C3.36739 7 3.24021 6.94732 3.14645 6.85355C3.05268 6.75979 3 6.63261 3 6.5ZM3 9.5C3 9.36739 3.05268 9.24021 3.14645 9.14645C3.24021 9.05268 3.36739 9 3.5 9H5.5C5.63261 9 5.75979 9.05268 5.85355 9.14645C5.94732 9.24021 6 9.36739 6 9.5C6 9.63261 5.94732 9.75979 5.85355 9.85355C5.75979 9.94732 5.63261 10 5.5 10H3.5C3.36739 10 3.24021 9.94732 3.14645 9.85355C3.05268 9.75979 3 9.63261 3 9.5Z" />
        </svg>
    )
}