import moment from 'moment'
import React, { MouseEvent } from 'react'
import { IoCloseCircle as CloseIcon } from '@react-icons/all-files/io5/IoCloseCircle'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'

import { NotesItemWrapper } from './styledcomponents'

interface Props {
    title: string, 
    pureText: string, 
    dateUpdated: string,
    dateCreated: string,
    isPrivate?: boolean, 
    id: string, 
    isActive: boolean
    changeActiveNote: (id: string) => void
    deleteNote: (id: string, e: MouseEvent<SVGElement>) => void
}

function NotesItem(props: Props) {
    const { title, pureText, dateUpdated, dateCreated, isPrivate, id, isActive, changeActiveNote, deleteNote } = props

    const checkDate = (curDate: any) => {
        return curDate ? moment(curDate).calendar({
            sameDay: '[Today]',
        }) : ''
    }
    
    return (
        <NotesItemWrapper className={isActive ? 'topbar' : ''} onClick={() => changeActiveNote(id)}>
            <div className="preview-header">
                <div className="preview-title">{title || 'New Note'}</div>
                <div className="preview-date">
                    {
                        checkDate(dateUpdated || dateCreated) === 'Today' ?
                            moment(dateUpdated || dateCreated).fromNow() :
                            dateUpdated ?
                                moment(dateUpdated).format("MMMM DD, YYYY") :
                                moment(dateCreated).format("MMMM DD, YYYY")
                    }
                </div>
            </div>
            <div className="preview-content">{pureText || 'No additional content'}</div>
            <CloseIcon className="close-icon" onClick={(e: MouseEvent<SVGElement>) => deleteNote(id, e)} />
            {isPrivate ? <div className="private-flag"><LockedIcon size={9} /></div> : ''}
        </NotesItemWrapper>
    )
}

function areEqual(prevProps: Props, nextProps: Props) {
    return prevProps.isActive === nextProps.isActive &&
    prevProps.title === nextProps.title &&
    prevProps.pureText === nextProps.pureText &&
    prevProps.isPrivate === nextProps.isPrivate &&
    prevProps.dateUpdated === nextProps.dateUpdated
}


export default React.memo(NotesItem, areEqual)
