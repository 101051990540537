import React, { useMemo } from 'react'
import clsx from 'clsx'
import Participant from './Participant'
import useMainParticipant from '../../hooks/useMainParticipant'
import useParticipants from '../../hooks/useParticipants'
import useVideoContext from '../../hooks/useVideoContext'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'
import useView, {MeetingLayouts, MeetingViews} from '../../hooks/useView'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { Room, Participant as P } from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'

interface Props {
  currentWidgetName: string | null
  layout: MeetingLayouts
}

export default function ParticipantList(props: Props) {
  const {users} = useVideoContext()
  const {room} = useMeetingContext()
  const participants = useParticipants()
  const localParticipant = (room as Room)!.localParticipant || (room as DailyCall)?.participants()?.local
  const screenShareParticipant = useScreenShareParticipant()
  const mainParticipant = useMainParticipant()
  // const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const {layout, currentWidgetName} = props

  // if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  const getUser = (id: string) => users.find((u) => u.id === id)
  //@ts-ignore
  const currentUser = getUser(((localParticipant as P)?.identity || localParticipant?.user_name || '')?.split('::')?.[1])

  // return useMemo(() => (
  return  <React.Fragment>
      <Participant
        participant={localParticipant}
        isLocalParticipant={true}
        user={currentUser}
      />
      {participants.map((participant: any) => {
        const isOnGoingAndSidebarView =
          !currentWidgetName && layout === MeetingLayouts.Sidebar
        const isMainParticipant = (
          mainParticipant?.identity || mainParticipant?.user_name || mainParticipant?.user_id) === (participant?.identity || participant?.user_name || participant?.user_id)
        const someoneIsScreensharing = !!screenShareParticipant

        const hideParticipant =
          isOnGoingAndSidebarView && !someoneIsScreensharing
            ? isMainParticipant
            : false

        const user = getUser((participant?.identity || participant?.user_name)?.split('::')?.[1])
        return (
          <Participant
            key={(participant?.sid) || `${participant?.session_id}::${participant?.user_id}` }
            participant={participant}
            isSelected={false}
            // onClick={() => {}}
            hideParticipant={hideParticipant}
            user={user}
            filterScreenshare={isOnGoingAndSidebarView && someoneIsScreensharing}
          />
        )
      })}
    </React.Fragment>
  // ), [participants, mainParticipant])
}
