import React, { Component } from 'react'
import styled from 'styled-components'
import LoopApi from '../../helpers/LoopApi'
import FileDrop from "react-file-drop";

interface Props {
	SetUrl: (s: string) => void
  addLink: (s: string) => void
  travelling: boolean
}

interface State {
	url: string
	showForm: boolean
  isUploading: boolean
}

export default class SetUrl extends Component<Props, State> {
  fileInputRef: any;

  constructor(props: Props) {
    super(props);
    this.state = { url: "", showForm: false, isUploading: false };

    // binds
    this.SetUrl = this.SetUrl.bind(this);
    this.submit = this.submit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.uploadPdf = this.uploadPdf.bind(this);

    // refs
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ url: "" });
  }

  SetUrl(e: any) {
    this.setState({ url: e.target.value });
  }

  async submit(e: any) {
    e.preventDefault();

    if (!this.state.url) {
      return;
    }
    
    var validUrl = require('valid-url');
    const valid = await validUrl.isUri(this.state.url); 
    if (!valid) return;

    this.props.addLink(this.state.url);
    this.props.SetUrl(this.state.url);
  }

  async handleUpload(e: any) {
    this.uploadPdf(e.target.files);
  }

  async uploadPdf(targetFiles: any) {
    try {
      this.setState({ isUploading: true });
      const file = targetFiles[0];
      //console.log({targetFiles, file})
      if (file.type !== 'application/pdf') {
        return
      }

      const data = new FormData();
      data.append("file", file);
			data.append('targetWidget', 'pdfpresenter')
      let response = await LoopApi(null, "UploadFile", {}, undefined, data);

      this.props.SetUrl(response.uri);
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isUploading: false });
    }
  }

  triggerInputClick = () => {
    this.fileInputRef.click();
  };

  render() {
    return (
      <Container>
        <Wrapper
          onDrop={(e: any) => {
            if(!this.props.travelling) {
              //console.log("pdf presenter", e);
              this.uploadPdf(e);
            }
          }}
        >
          <div className="show-while-dragging">Drag Files Here</div>
          <div className="show-while-dragging-over">Drop Files</div>
          <InnerWrapper>
            <Text>Drop your files here</Text>
            <Text>or</Text>
            <Button
              className="button default"
              type="button"
              onClick={this.triggerInputClick}
              disabled={this.state.isUploading || this.props.travelling}
            >
              {this.state.isUploading ? "loading" : "Upload Files"}
            </Button>
            {/* <Text>or</Text>
            <ButtonLink onClick={() => this.setState({ showForm: true })}>
              <Text>Upload by URL</Text>
            </ButtonLink> */}

            {this.state.showForm && !this.props.travelling ? (
              <Form onSubmit={this.submit}>
                <Input
                  autoFocus
                  type="text"
                  value={this.state.url}
                  placeholder="Add link here then press ENTER"
                  onChange={this.SetUrl}
                  className="button topbar border-light"
                />
              </Form>
            ) : null}
          </InnerWrapper>
        </Wrapper>

        <input
          type="file"
          hidden
          onChange={this.handleUpload}
          ref={(ref) => (this.fileInputRef = ref)}
          accept="application/pdf"
        />
      </Container>
    );
  }
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 16px;
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled(FileDrop as any)`
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 10px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

  .file-drop-target {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		overflow: hidden;
		position: relative;
    width: 100%;
	}

	.show-while-dragging,
  .show-while-dragging-over {
    display: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 32px;
    color: white;
    font-weight: 500;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }
`

const Text = styled.p`
	margin-top: 8px;
	margin-bottom: 3px;

	& > a {
		color: #2d81ff;
		text-decoration: none;
	}
`

const Button = styled.button`
	border: none;
	color: white;
	height: 40px;
	width: 140px;
	outline: 0;
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		/* background-color: rgba(62, 131, 247, 0.8); */
		opacity: 0.5;
	}
`

const ButtonLink = styled.button`
	cursor: pointer;
	background: none;
	border: none;
	color: inherit;
	text-decoration: none;

	p {
		padding-bottom: 3px;
		margin-bottom: 0;
		border-bottom: 2px solid;
	}
`

const Form = styled.form`
	margin-top: 16px;
`

const Input = styled.input`
	text-align: center;
	width: 256px;
	border: 1px solid;
	/* border-radius: 4px !important; */
	padding: 8px;
`
