import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'

import { MEMBERS } from '../dummy-events'

const NUMBER_OF_HOURS = 24 // for 24 hours
const TIMEBLOCKS_IN_HOUR = 2 // every 30 minutes

function DayView() {

  const getHeaderBlocks = () => {
    const headerHours = []
    for (let i = 0; i < (NUMBER_OF_HOURS / TIMEBLOCKS_IN_HOUR); i++) {
      headerHours.push({ time: i*2 })
    }
    return headerHours
  }

  const getTimeFormat = (__time: number) => {
    const date = new Date()
    date.setHours(__time)
    date.setMinutes(0)

    return (moment(date).format('h') !== '12') ? moment(date).format('h:mm') : moment(date).format('h:mmA')
  }

  const getEventPosition = (__event: any) => {
    // Get the position of event schedule in the timeline
    // and getting the width of the schedule
    // NOTE: the width for 30 minutes block is 2.05%
    const BLOCK_WIDTH = 2.05
    const EVENT_DURATION = 2 // hours
    const NUM_HALF_OF_HOUR = 2 // 2 * 30 minutes in 1 hour

    const eventTime = moment(__event.time).format('H:mm')
    const splitTime = eventTime.split(":")

    const time_in_Number = parseInt(splitTime[0]) + (splitTime[1] === '30' ? .5 : 0)
    const number_of_block = (time_in_Number / .5)

    return {
      time: moment(__event.time).format('h:mmA'),
      position: (BLOCK_WIDTH * (number_of_block)) + '%',
      width: ((BLOCK_WIDTH * NUM_HALF_OF_HOUR) * EVENT_DURATION) + '%'
    }
  }

  return (
    <WeeklyWrapper>
      <ContentRow>
        <HeaderItem className="date-header">
            May 19
            <div className="shor-day">Thu</div>
        </HeaderItem>

        <HeaderItem className="time-header">
        {
          getHeaderBlocks().map((__hour, key) =>
            <HeaderTimeBlock key={key}>{getTimeFormat(__hour.time)}</HeaderTimeBlock>
          )
        }
        </HeaderItem>
      </ContentRow>


      {
        MEMBERS.map((__member, __index) =>
          <ContentRow key={__index}>
            <MemberItem>
              <MemberAvatar>ID</MemberAvatar>
              <MemberName>{__member.name}</MemberName>
            </MemberItem>

            <MemberEventContainer>
            {
              getHeaderBlocks().map((__block, key) => <MemberTimeBlock key={key} />)
            }

            {
              __member.events[5].map((__event, key) => {
                const eventDetails = getEventPosition(__event)

                // overlayInnerStyle={{ borderRadius: '30px', fontSize: '11px', padding: '8px 10px' }}
                return (
                  <Tooltip key={key} placement="top" title={`${eventDetails.time} - ${__event.topic}`}>
                    <EventTimeBlock className={`${__event.type.toLocaleLowerCase()}`} style={{ left: eventDetails.position, width: eventDetails.width }} />
                  </Tooltip>
                  )
              })
            }
            </MemberEventContainer>
          </ContentRow>
        )
      }

    </WeeklyWrapper>
    )
}

const WeeklyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 15px;
`

const ContentRow = styled.div`
  display: flex;
  flext-direction: row;
`

const HeaderItem = styled.div`
  box-sizing: border-box;
  height: 32px;

  &:first-child {
    width: 10%;
    padding: 6px 5px;
    font-size: 12px;
    position: relative;

    > .shor-day {
      position: absolute;
      top: 6px;
      right: 3px;
      font-weight: 500;
    }
  }

  &.date-header {
  }

  &.time-header {
    width: 90%;
    display: flex;
    flex-direction: row;
  }
`

const HeaderTimeBlock = styled.div`
  width: 8.2%;
  height: 30px;
  border-left: 1px solid #eee;
  font-size: 11px;
  padding: 7px 0px 7px 10px;

  &:nth-child(1) {
    border-left: 1px solid #eee;
  }

  &:nth-child(3n+3) {
  }
`


const MemberItem = styled.div`
  background: #F2F2F2;
  border-radius: 5px;
  width: 10%;
  margin: 2px 0px;

  padding: 10px 0px 6px 5px;

  > div {
    vertical-align: middle;
  }
`
const MemberAvatar = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;

  background: #363B45;
  border-radius: 18px;
  text-align: center;
  color: white;
  padding-top: 4px;
`
const MemberName = styled.div`
  display: inline-block;
  width: 85px;
  font-size: 11px;
  margin-left: 2px;
`

const MemberEventContainer = styled.div`
  width: 90%;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;

  display: flex;
  flex-direction: row;

  position: relative;
`

const EventTimeBlock = styled.div`
  position: absolute;
  top: 15px;
  height: 15px;

  background: linear-gradient(90deg, #0659A9 0%, #008BFF 100%);
  border: 2px solid #FFFFFF;
  border-radius: 5px;

  &.holiday {
    background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
    border: 3px solid #FFFFFF;
    border-radius: 5px;
  }
`

const MemberTimeBlock = styled.div`
  width: 8.2%;
  height: 50px;
  font-size: 11px;
  padding: 7px 0px 7px 10px;
  border-right: 1px solid #eee;

  &:nth-child(12) {
    border-right: none;
  }
`

export default DayView
