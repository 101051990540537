import React, { Component } from 'react'
import styled from 'styled-components'
import { IoMdCheckboxOutline as CheckedIcon } from '@react-icons/all-files/io/IoMdCheckboxOutline'
import { IoMdSquareOutline as UncheckedIcon } from '@react-icons/all-files/io/IoMdSquareOutline'
import { TiAttachmentOutline } from 'react-icons/ti'
import { FiPaperclip } from '@react-icons/all-files/fi/FiPaperclip'
import { BiTrashAlt as DeleteIcon } from '@react-icons/all-files/bi/BiTrashAlt'
import { FiEdit2 as EditIcon } from '@react-icons/all-files/fi/FiEdit2'

import { Task } from './types'
import CustomTip from '../../helpers/CustomTip'

type Props = {
    tasks: Task
    check: () => void
    edit: Function
    delete: () => void
    travelling: boolean
}

type state = {
    showMoreDescription: boolean
}

export class TaskItem extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            showMoreDescription: false
        }
    }

    toggleShowMoreDescription = () => {
        this.setState(prevState => ({
            ...prevState,
            showMoreDescription: !prevState.showMoreDescription
        }))
    }

    cropText = (text: string) => {
        return text?.length > 100
            ? text.split('').slice(0, 100).join('') + '...'
            : text
    }

    render() {
        const { tasks } = this.props
        const firstThree = [...tasks.assignees]
        if (firstThree.length > 3) {
            firstThree.length = 3
        }
        //console.log("firstThree: ", firstThree)
        return (
            <MainCont className={`hover-container pid-${tasks.id}`}>
                {!this.props.travelling && <Done onClick={() => this.props.check()}>
                    {tasks.done ? (
                        <CheckedIcon size={24} />
                    ) : (
                        <UncheckedIcon size={24} />
                    )}
                </Done>}
                <Info>
                    <NameSpan>{tasks.name}</NameSpan>
                    <RemarksSpan>
                        <div dangerouslySetInnerHTML={{
                            __html: this.state.showMoreDescription ? tasks.notes.replace('_self', '_blank') : this.cropText(tasks.notes.replace('_self', '_blank')),
                        }} />
                        {tasks.notes?.length > 100
                            ? (
                                <ToggleDesc onClick={this.toggleShowMoreDescription} className="color-primary">
                                    View {this.state.showMoreDescription ? "Less" : "More"}
                                </ToggleDesc>
                            )
                            : null}
                    </RemarksSpan>
                    {tasks.attachments?.length > 0 ? <RemarksSpan><FiPaperclip /> {tasks.attachments?.length} attachment{tasks.attachments?.length > 1 ? 's' : ''}</RemarksSpan> : ""}
                </Info>

                <Assignee>
                    <Assignees>
                        {firstThree.length === 3 &&
                            tasks.assignees.length > 3 ? (
                            <ImageWrapper
                                className="container"
                                title={'Other assignees'}
                                color="rgba(0, 0,0,0.4)"
                            >
                                +{tasks.assignees.length - 3}
                            </ImageWrapper>
                        ) : (
                            ''
                        )}
                        {[...firstThree].reverse().map((assignee: any, i: number) => (
                            <>
                                <ImageWrapper data-tip data-for={assignee.id} key={i}>
                                    {assignee?.avatar_url ? (
                                        <img
                                            src={assignee?.avatar_url}
                                            alt={assignee?.username}
                                        />
                                    ) : (
                                        <span className="assignee-name">
                                            {assignee?.initials}
                                        </span>
                                    )}
                                </ImageWrapper>
                                <CustomTip top={-20} left={-10} class='top-right' place='left' tip={assignee?.name || ''} dataFor={assignee?.id || ''} />
                            </>
                        ))}
                    </Assignees>
                </Assignee>
                {!this.props.travelling && <Options>
                    <Edit onClick={() => this.props.edit()} data-tip data-for='tedit'>
                        <EditIcon />
                    </Edit>
                    <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Edit Task' dataFor='tedit' />
                    <Delete onClick={() => this.props.delete()} data-tip data-for='tdelete'>
                        <DeleteIcon />
                    </Delete>
                    <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Remove Task' dataFor='tdelete' />
                </Options>}
            </MainCont>
        )
    }
}

const MainCont = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 0 5px;
`

const Done = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const Info = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1;

    > span {
        word-break: break-all;
    }
`
const NameSpan = styled.span`
    font-size: 14px;
    font-weight: 400;
`
const RemarksSpan = styled.span`
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
    min-height: 12px;
`
const Assignee = styled.div`
    margin-left: auto;
    margin-right: 1rem;
`
const Options = styled.div`
    margin-left: auto;
    width: fit-content;
    display: flex;
    font-size: 15px;
    padding-right: 5px;
`

const Attach = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`
const Edit = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`
const Delete = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`

const ImageWrapper = styled.div<{ color?: string; bordered?: boolean }>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textRegular};

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        color: white;
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > ${ImageWrapper} {
        transition: 0.3s transform ease-out;
        &:hover {
            z-index: 2;
            border: 1px solid white;
            transform: scale(1.1);
        }
    }
`

const ToggleDesc = styled.span`
    cursor: pointer;
    font-weight: 500;
`

export default TaskItem
