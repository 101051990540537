import atob from 'atob'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'

import LoopApi from '../../helpers/LoopApi'
import FinishedSignup from './FinishedSignup'
import Recaptcha from '../../components/Recaptcha'

import { ErrorMessage, FormGroup, Input, InputLabel, LoginForm, MainFormWrapper, OtherInfo, RecaptchaWrapper, SubmitButton, Title } from './styled'
import { useHistory, useLocation, useParams } from 'react-router'

function Form() {
  let recaptchaInstance: any = React.createRef()
  const history = useHistory()
  const { search } = useLocation();
  const { meetingName } = useParams() as any

  const [focused, setFocused] = useState({
      displayName: true,
      email: true,
      password: true,
      confirmPassword: true,
  })
  const [values, setValues] = useState({
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
      organization_name: '',
      organization_id: '',
      user_id: ''
  })
  const [visibility, setVisibility] = useState({
      password: false,
      confirmPassword: false
  })
  const [recaptcha, setRecaptcha] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [signingUp, setSigningUp] = useState(false)
  const [finished, setFinished] = useState(false)
  const [isJoinOrg, setIsJoinOrg] = useState(false)

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setErrorMessage('')

    setValues({
        ...values,
        [e.target.name]: value
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!_isInputGood) return

    if(values.user_id && values.email && values.organization_name && values.organization_id && isJoinOrg) {
        return handleOrgSignup()
    }

    handleSignup()
  }

  const handleSignup = async() => {
    setSigningUp(true)
    setErrorMessage('')

    try {
        const resp = await LoopApi(null, 'Signup', {
            username: values.displayName,
            email: values.email,
            captcha_response: recaptcha,
            account: {
                password: values.password,
            },
        })
        
        if (resp.error) {
            if(resp.msg.toString().includes('verify')) {
                setFinished(true)
            } else {
                setSigningUp(false)
                setErrorMessage((typeof resp.msg === 'string' ? resp.msg : null) ?? resp.msg.message)
            }
        }
        recaptchaInstance?.reset()
        if(
            typeof window !== 'undefined' &&
            //@ts-ignore
            typeof window.grecaptcha !== 'undefined' &&
            //@ts-ignore
            typeof window.grecaptcha.reset === 'function'
        ) {
            //@ts-ignore
            window.grecaptcha.reset()
        }
    } catch (err) {
        try {
            if(
                typeof err === 'string' &&
                typeof window !== 'undefined' &&
                //@ts-ignore
                typeof window.grecaptcha !== 'undefined' &&
                //@ts-ignore
                typeof window.grecaptcha.reset === 'function'
            ) {
                //@ts-ignore
                window.grecaptcha.reset()
            }
            setErrorMessage(typeof err === 'string' ? err : '')
        } catch (error) {
        }
        
        setSigningUp(false)
    }
  }

  const handleOrgSignup = async() => {
    setSigningUp(true)
    setErrorMessage('')

    try {
        const resp = await LoopApi(null, 'SignupInvite', {
            username: values.displayName,
            captcha_response: recaptcha,
            password: values.password,
            new_password: values.confirmPassword,
            organization_id: values.organization_id,
            email: values.email,
        })

        if (resp.error) {
            if(resp.msg.toString().includes('verify')) {
                setFinished(true)
            } else {
                setSigningUp(false)
                setErrorMessage((typeof resp.msg === 'string' ? resp.msg : null) ?? resp.msg.message)
            }
        } else {
            if (resp.token && resp.token) {
                signinSuccess(resp.token)
            }
        }
        recaptchaInstance?.reset()
        if(
            typeof window !== 'undefined' &&
            //@ts-ignore
            typeof window.grecaptcha !== 'undefined' &&
            //@ts-ignore
            typeof window.grecaptcha.reset === 'function'
        ) {
            //@ts-ignore
            window.grecaptcha.reset()
        }
    } catch (err) {
        try {
            if(
                typeof err === 'string' &&
                typeof window !== 'undefined' &&
                //@ts-ignore
                typeof window.grecaptcha !== 'undefined' &&
                //@ts-ignore
                typeof window.grecaptcha.reset === 'function'
            ) {
                //@ts-ignore
                window.grecaptcha.reset()
            }
            setErrorMessage(typeof err === 'string' ? err : '')
        } catch (error) {}
        
        setSigningUp(false)
    }
}


  const passwordValidation = () => {
    const { password, confirmPassword } = values;
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if(password === '') return
    if (password.length < 8) return 'Password must at least 8 characters.';
    if (password.length > 16) return 'Password must at maximum of 16 characters.';
    if (!/[a-z]/.test(password)) return 'At least one lowercase character.';
    if (!/[A-Z]/.test(password)) return 'At least one uppercase character.';
    if (!/\d/.test(password)) return 'At least one numeric character.';
    if (!format.test(password)) return 'At least one special character.';
    if (password !== confirmPassword ) return 'Please make your password match.';
  }

  const _isInputGood = () => {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return !values.displayName ||
    !values.password ||
    !values.confirmPassword ||
    !values.email ||
    values.password !== values.confirmPassword ||
    !recaptcha || 
    values.password.length < 8 ||
    values.password.length > 16 ||
    !/[a-z]/.test(values.password) || 
    !/[A-Z]/.test(values.password) || 
    !/\d/.test(values.password) ||
    !format.test(values.password)
  }

  const signinSuccess = async (token: string) => {
    localStorage.setItem('token', token)

    if (meetingName) {
        return history.push(`/${meetingName}`)
    }

    const meetingResp = await LoopApi(null, 'GetMeetings')
    if (!meetingResp.meetings || meetingResp.meetings.length === 0) {
        return history.push(`/create-or-join`)
    } else if (localStorage.lastMeeting && meetingResp.meetings.find((m: any) => m.name === localStorage.lastMeeting)) {
        return history.push(`/${localStorage.lastMeeting}`)
    }

    history.push(`/create-or-join`)
  }

  useEffect(() => {
    const token = search?.split("?")?.[1] || ''
    const atobToken = atob(token)
    const decodedToken = decodeURIComponent(atobToken)
    const concatenatedToken = `?${decodedToken}`

    const user_id = new URLSearchParams(concatenatedToken).get("user_id") || '';
    const email = new URLSearchParams(concatenatedToken).get("email") || ''
    const organization_name = new URLSearchParams(concatenatedToken).get("organization_name") || ''
    const organization_id = new URLSearchParams(concatenatedToken).get("organization_id") || ''

    const isJoin = !!(user_id && email && organization_name && organization_id)

    setValues({
        ...values,
        email,
        organization_name,
        organization_id,
        user_id
    })
    setIsJoinOrg(isJoin)
  }, [search])

  if(finished) {
    return <FinishedSignup displayName={values.displayName} email={values.email} />
  }
  
  return (
    <MainFormWrapper>
        <LoginForm onSubmit={handleSubmit}>
            <Title>{isJoinOrg ? `Join ${values.organization_name || 'Organization'}` : 'Create Personal Account'}</Title>
            <FormGroup className={`form-group ${focused.displayName || values.displayName ? 'focused' : ''}`}>
                <InputLabel htmlFor="signupDisplayName">Name</InputLabel>
                <Input 
                    id="signupDisplayName" 
                    type="text" 
                    name={'displayName'}
                    value={values.displayName}
                    onChange={handleValueChange}
                    placeholder="Name"
                    // onFocus={() => setFocused({ ...focused, displayName: true })} 
                    // onBlur={() => setFocused({ ...focused, displayName: false })}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup className={`form-group ${focused.email || values.email ? 'focused' : ''}`}>
                <InputLabel htmlFor="signupEmail">Email Address</InputLabel>
                <Input 
                    id="signupEmail" 
                    type="email" 
                    name={'email'}
                    disabled={isJoinOrg}
                    value={values.email}
                    onChange={handleValueChange}
                    placeholder="Email Address"
                    // onFocus={() => setFocused({ ...focused, email: true })} 
                    // onBlur={() => setFocused({ ...focused, email: false })}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup className={`form-group ${focused.password || values.password ? 'focused' : ''}`}>
                <InputLabel htmlFor="loginPassword">Password</InputLabel>
                <Input 
                    className='form-password'
                    id="loginPassword" 
                    type={visibility.password ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleValueChange}
                    placeholder="Password"
                    // onFocus={() => setFocused({ ...focused, password: true })} 
                    // onBlur={() => setFocused({ ...focused, password: false })}
                    autoComplete="off"
                />
                <span className="password-view" onClick={() => setVisibility({ ...visibility, password: !visibility.password })}>{visibility.password ? <EyeIcon /> : <EyeSlashIcon />}</span>
            </FormGroup>
            <FormGroup className={`form-group ${focused.confirmPassword || values.confirmPassword ? 'focused' : ''}`}>
                <InputLabel htmlFor="loginConfirmPassword">Confirm Password</InputLabel>
                <Input 
                    className='form-password'
                    id="loginConfirmPassword" 
                    type={visibility.confirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleValueChange}
                    placeholder="Confirm Password"
                    // onFocus={() => setFocused({ ...focused, confirmPassword: true })} 
                    // onBlur={() => setFocused({ ...focused, confirmPassword: false })}
                    autoComplete="off"
                />
                <span className="password-view" onClick={() => setVisibility({ ...visibility, confirmPassword: !visibility.confirmPassword })}>{visibility.confirmPassword ? <EyeIcon /> : <EyeSlashIcon />}</span>
            </FormGroup>
            {
                passwordValidation() && 
                <OtherInfo>
                    <ErrorMessage>{passwordValidation()}</ErrorMessage>
                </OtherInfo>
            }
            <RecaptchaWrapper>
                <Recaptcha
                    verifyCallback={(ev: any) => setRecaptcha(ev)}
                    ref={e => (recaptchaInstance = e)}
                    innerRef={(e: any) => recaptchaInstance = e}
                    sitekey={process.env.RECAPTCHA_KEY || '6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'}
                />
            </RecaptchaWrapper>
            {
                errorMessage &&
                <OtherInfo>
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                </OtherInfo>
            }

            <SubmitButton
                className="button primary"
                disabled={signingUp || _isInputGood()}
                value={"Sign Up"}
                type="submit"
            />
        </LoginForm>
        {/* <div className='register-org-wrapper'>
            or
            <br/>
            <a href="https://admin.grapl.it/organization/signup">Register Organization</a>
        </div> */}
    </MainFormWrapper>
  )
}

export default Form