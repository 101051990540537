import React, { Component } from 'react'
import styled from 'styled-components'
import play from './play.svg'
import RecordingViewer from './RecordingViewer'

export default class RecordingBreakdown extends Component {
	constructor(props) {
		super(props)
		this.state = {
			active_recording: null,
			active_recording_actions: null,
		}
	}

	setActiveRecording(active_recording) {
		this.setState({ active_recording })
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.day_breakdown.length === 1) {
			this.setState({ active_recording: nextProps.day_breakdown[0] })
		}
	}

	render() {
		const recordings = this.props.day_breakdown.map(rec => {
			let minutes = (rec.duration || 0) / 60e3
			const hours = Math.floor(minutes - minutes % 60)
			minutes = Math.ceil(minutes % 60)
			const timestring = (hours > 0 ? `${hours}h ` : '') + `${minutes}m`

			const user_mappings = rec.user_ids.map((u, ii) => {
				const user = this.props.users[u]
				if (!user) {
					return null
				}

				return (
					<div key={u}>
						{user.name}
						{rec.user_ids.length - 1 > ii && `, `}
					</div>
				)
			})
			return (
				<EntryContainer
					key={rec.created_at}
					onClick={() => this.setActiveRecording(rec)}
					selected={
						this.state.active_recording &&
						this.state.active_recording.created_at === rec.created_at
					}
				>
                    <Title>Recordings</Title>
                    <Style1><img src={play} alt="play" width="25px"/> {rec.uri.split('/done/')[1]}</Style1>
                    <Style2>Duration: {timestring}</Style2>
                    <Style2><button className="button primary" onClick={() => window.open(rec.uri, '_blank')}>download</button></Style2> 
					{/* <div>
						{new Date(rec.created_at).toLocaleString('en-US', {
							month: 'short',
							day: 'numeric',
							hour: 'numeric',
							minute: 'numeric',
						})}
					</div>
					<UserCol>{user_mappings}</UserCol>
					<Timestamp>{timestring}</Timestamp><button className="button primary" onClick={() => window.open(rec.uri, '_blank')}>download</button> */}
				</EntryContainer>
			)
		})
		return (
			<div>
				<Card>{recordings}</Card>
				{this.state.active_recording && (
					<RecordingViewer
						users={this.props.users}
						recording={this.state.active_recording}
						recording_actions={this.state.active_recording_actions}
					/>
				)}
			</div>
		)
	}
}

const Timestamp = styled.div`
	margin-left: auto;
    margin-right: 5px;
`

const EntryContainer = styled.div`
	display: flex; 
    justify-content: space-between;
	padding: 12px;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	${props => props.selected && `border-left: 6px solid blue;`};
    button {
        margin-left: auto;
        background: #363B45;
        border-radius: 10px;
        height: 40px;
        width: 114px;
        color: white;
        border: none;
        text-transform: capitalize;
    }
    div {
        width:30%;
    }
`

const UserCol = styled.div`
	max-width: 75%;
	margin: 0 6px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-weight: 500;
	> * {
		margin-right: 4px;
	}
`

const Card = styled.div`
	//margin: 12px 12px 24px 12px;
	/* box-shadow: ${props => props.theme.shadows.heavy};
	background-color: white;
	border-radius: 2px;
	overflow-y: hidden; */

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    display: flex;
    align-items: center;

    color: #00265C;
`

const Style1 = styled.div` 
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    justify-content: space-evenly;
    /* or 200% */

    display: flex;
    align-items: center;

    color: #0659A9;
`

const Style2 = styled.div` 
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    /* or 200% */

    display: flex;
    align-items: center;

    color: #363B45;
`