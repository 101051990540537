import htmlToDraft from 'html-to-draftjs';
import React, { memo, useEffect, useState } from 'react'
import { ContentState, convertToRaw, EditorState, getDefaultKeyBinding, Modifier, RichUtils } from 'draft-js';
import { Editor, SyntheticEvent, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';

import { EditorWrapper } from '../styledcomponents'
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useWindowUnloadEffect } from '../../../helpers/useWindowUnloadEffect';

const { GlobalState } = require('reflux')

interface Props {
    pureText: string
    content: string
    updating: null | string
    activeNote: null | string
    isPrivate?: boolean
    updateContent: (content: string, pureText: string, isPrivate?: boolean, fromBlur?: boolean, fromUnmount?: boolean) => void
    updateUpdating: () => void
}

function NotesEditor(props: Props) {
    const { updateContent, isPrivate } = props
    const [themingStore] = useState(GlobalState.theming)
    const [authStore] = useState(GlobalState.auth)
    const [activeNote, setActiveNote] = useState<string | null>(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const updateEditorState = () => {
        const blocksFromHtml = htmlToDraft(props?.content || '')
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
        let editorStateS = EditorState.createWithContent(contentState)
        
        setEditorState(editorStateS)
        setActiveNote(props?.activeNote || null)
    }

    useEffect(() => {
        updateEditorState()
    }, [])

    useEffect(() => {
        if(props.updating !== authStore?.jwt?.data?._id && authStore?.jwt?.data?._id && props.updating) {
            updateEditorState()
        }

        if(props.activeNote !== activeNote) {
            updateEditorState()
        }

        // if(JSON.stringify(props.activeData?.content) !== JSON.stringify(activeData?.content) && props.updating === authStore?.jwt?.data?._id && props.updatedFrom === 'floating') {
        //     updateEditorState()
        // }

        // if(props.speechOn || props.transcribing === authStore?.jwt?.data?._id) {
        //     updateEditorState()
        // }

        () => {
            if(props.updating === authStore?.jwt?.data?._id) {
                props.updateUpdating()
                // debugger
            }
        }
        
    }, [props.content, props.activeNote])

    useWindowUnloadEffect(() => {
        //console.log("***notes: updating notes before unloading")
        // if(!!!isPrivate) return

        const pureText = editorState.getCurrentContent().getPlainText()
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        updateContent(content, pureText, isPrivate, false, true)

        setEditorState(editorState);
    }, true)

    let className = 'CustomRichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' Custom-hidePlaceholder';
        }
    }

    const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
        if (e.keyCode === 8 && !contentState.hasText() && contentState.getBlockMap().first().getType() !== 'unstyled') {
            //console.log("e: ", e.keyCode, "   editorstate: ", contentState.getBlockMap().first().getType() !== 'unstyled' )
            const editorstate = getResetEditorState(editorState)
            setEditorState(editorstate)
        }
        return getDefaultKeyBinding(e);
    }

    const removeSelectedBlocksStyle = (editorstate: any)  => {
        const newContentState = RichUtils.tryToRemoveBlockStyle(editorstate);
        if (newContentState) {
            return EditorState.push(editorstate, newContentState, 'change-block-type');
        }
        return editorstate;
    }
    

    const getResetEditorState = (editorstate: any) => {
        const blocks = editorstate
            .getCurrentContent()
            .getBlockMap()
            .toList();
        const updatedSelection = editorstate.getSelection().merge({
            anchorKey: blocks.first().get('key'),
            anchorOffset: 0,
            focusKey: blocks.last().get('key'),
            focusOffset: blocks.last().getLength(),
        });
        const newContentState = Modifier.removeRange(
            editorstate.getCurrentContent(),
            updatedSelection,
            'forward'
        );
    
        const newState = EditorState.push(editorstate, newContentState, 'remove-range');
        return removeSelectedBlocksStyle(newState)
    }

    const onEditorStateChange = (editorStateP: any) => {
        const pureText = editorStateP.getCurrentContent().getPlainText()
        const content = draftToHtml(convertToRaw(editorStateP.getCurrentContent()))
        const prevContent = props.content

        if(prevContent !== content) {
            updateContent(content, pureText, isPrivate || false )
        }

        setEditorState(editorStateP);
    };

    const onBlur = (e: SyntheticEvent, editorStateP: any) => {
        if(!!!isPrivate) return

        const pureText = editorStateP.getCurrentContent().getPlainText()
        const content = draftToHtml(convertToRaw(editorStateP.getCurrentContent()))

        updateContent(content, pureText, isPrivate, true)

        setEditorState(editorStateP);
    }

    if(!activeNote) {
        return <div>No notes</div>
    }

    return (
        <EditorWrapper
            className={className}
            data-text-editor="name" 
            bgColor={themingStore.color_scheme === 'Light' ? '#FFFFFF' : '#272732'}
            color={themingStore.color_scheme === 'Light' ? '#F89809' : '#008BFF'} 
            defColor={themingStore.color_scheme === 'Light' ? '#363B45' : '#FFFFFF'}
        >
            <Editor 
                // ref={(el: any) => editornew = el}
                editorState={editorState}
                placeholder="Start taking notes!"
                onEditorStateChange={(editorState: EditorState) => onEditorStateChange(editorState)} 
                wrapperClassName="notes-editor-wrapper"
                toolbarClassName="notes-toolbar"
                toolbar={toolbar}
                handlePastedText={() => false}
                {...{keyBindingFn: myKeyBindingFn, onBlur: onBlur as any}}
            />
        </EditorWrapper>
    )
}

const toolbar = {
    options: ['inline', 'blockType', 'list', 'link', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: { className: "notes-bold" },
        italic: { className: "notes-italic" },
        underline: { className: "notes-underline" },
        strikethrough: { className: "notes-strikethrough" },
    },
    blockType: {
        inDropdown: false,
        options: ['Blockquote', 'Code'],
        className: "notes-blocktype",
    },
    list: {
        options: ['unordered', 'ordered'],
        unordered: { className: "notes-unordered" },
        ordered: { className: "notes-ordered" },
    },
    link: {
        options: ['link', 'unlink'],
        link: { className: "notes-link" },
        unlink: { className: "notes-unlink" },
    },
    history: {
        options: ['undo', 'redo'],
        undo: { className: "notes-undo" },
        redo: { className: "notes-redo" },
    },
}

function areEqual(prevProps: Props, nextProps: Props) {
    return prevProps.pureText === nextProps.pureText && 
    prevProps.content === nextProps.content
}

export default memo(NotesEditor, areEqual)
