import React, { useMemo, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';

import useNotesContext from '../useNotesContext'

import Items from './Items';
import Modal from '../../_Shared/Modal'
import PrivatePublicModal from '../modals/PrivatePublicModal'
import { Button, NoExistingNotesWrapper, NotePreview, SidebarWrapper } from '../styledcomponents'

function Sidebar() {
    const { addNewNotes, sidebarNotes, activeNote, collapsedSidebar } = useNotesContext()
    const [showCreateModal, setShowCreateModal] = useState(false)

    return useMemo(() => {
        const sidebarNotesKey: any = Object.keys(sidebarNotes)

        return <React.Fragment>
            <Modal show={showCreateModal} overlayClicked={() => setShowCreateModal(false)}>
                <PrivatePublicModal submit={addNewNotes} callback={() => setShowCreateModal(false)} />
            </Modal>
            <SidebarWrapper className="rounded container content" collapsed={collapsedSidebar}>
                {
                    !!!collapsedSidebar &&
                    <NoExistingNotesWrapper className="addnew">
                        <Button className="button primary" onClick={() => setShowCreateModal(true)}>New Note</Button>
                    </NoExistingNotesWrapper>
                }
                {
                    (sidebarNotesKey || [])?.length ?
                        <Scrollbars
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            style={{ height: 'calc(100% - 40px)' }}
                        >
                            {
                                (sidebarNotesKey || []).map((notesKey: any, idx: number) => {
                                    return <Items
                                        key={`notes-item-${notesKey}`}
                                        id={notesKey || ''}
                                        isActive={notesKey === activeNote}
                                    />
                                })
                            }
                        </Scrollbars> : <NotePreview className="no-note list">
                            No notes
                        </NotePreview>
                }
            </SidebarWrapper>
        </React.Fragment>
    }, [JSON.stringify(sidebarNotes), showCreateModal, activeNote, collapsedSidebar])
}

export default Sidebar