import styled from "styled-components";

export const AvatarBg = styled.div<{ avatar_url: string }>`
	background-image: url('${(props) => props.avatar_url}');
	background-size: cover;
	background-position: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
    z-index: 2;
`

export const AspectRatioEnforcer = styled.div`
	/* padding-top: 100%; */
	height: inherit;
    width: inherit;
	background-color: black;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
`

export const Video = styled.div`
	height: 100%;
    width: 100%;

    video {
        height: 100%;
        width: 100%;
        border-radius: 6px;
        transform: scaleX(-1);
        object-fit: cover;
    }

    video.screenvideo {
        object-fit: contain;
        transform: initial;
    }

`

export const MutedWrapper = styled.div<{isCurrentUser?: boolean}>`
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 3;
`

export const IconButtonMute = styled.div<{ muted?: boolean }>`
    color: white;
    padding: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    border-radius: 2px;
    font-size: 14px;
    transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
    opacity: 0;
    position: relative;
    ${(props) => (props.muted ? 'opacity: 1!important;' : '')} 
    cursor: pointer;
`

export const XLine = styled.div<{big?: boolean}>`
    position: absolute;
    height: 1px;
    width: ${props => props.big ? '20px' :'16px'};
    background-color: #fff;
    transform: rotate(135deg);
    pointer-events: none;
`

export const Name = styled.div<{isCurrentUser?: boolean}>`
    position: absolute;
    top: 7px;
    left: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    text-shadow: 0px 0px 2px rgba(0,0,0,0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    .host-indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        perspective: 1000px;
        overflow: hidden;
        -webkit-animation: filckering 2s infinite;
        animation: filckering 2s infinite;
        display: inline-block;
        -webkit-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
        font-size: 13px;

        svg {
            width: 18px;
        }
    }

    .tile-username {

        > .ellipsis-tile-username {
            max-width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 5px;
        }
    }

    @keyframes filckering {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }

`

export const IconButton = styled.div<{ muted?: boolean, active?: boolean }>`
	/* color: ${props => props.active ? '#008bff !important' : 'white'}; */
	padding: 16px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 4px;
	border-radius: 2px;
	font-size: 20px;
	transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
	/* box-shadow: ${(props) => props.theme.shadows.light}; */
	opacity: 0;
	position: relative;
	/* ${(props) => (props.muted ? 'opacity: 1!important;' : '')}  */
	cursor: pointer;
`

export const CallControlsContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 25px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
    z-index: 3;
`

export const Item = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 5px 4px;
	font-weight: 400;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	& > img, & > svg {
		margin-right: 8px;
	}

	&:hover {
		/* background-color: rgba(0, 200, 0, 0.075); */
		cursor: pointer;
	}
`
