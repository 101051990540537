import React from 'react'
import styled from 'styled-components'
import {FaCrown as HostIcon} from '@react-icons/all-files/fa/FaCrown'
import {FiMicOff as MicOff} from '@react-icons/all-files/fi/FiMicOff'
import {FiMic as MicOn} from '@react-icons/all-files/fi/FiMic'
import CustomTip from '../../../../../../helpers/CustomTip'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { CallingProviderName } from '../../../../../../contexts/types'

interface Props {
  transferHostAccess: () => void
  transferHostAccessDisabled: boolean
  muteParticipant: () => void
  muteParticipantDisabled: boolean
}

function ParticipantOverlay(props: Props) {
  const {
    transferHostAccess,
    transferHostAccessDisabled,
    muteParticipantDisabled,
    muteParticipant,
  } = props

  const { callProvider } = useMeetingContext()

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  return (
    <CallControlsContainer className="call-control-container">
      <IconButtonMore
        // title="Make Host"
        className={`button default-opaque call-control ${
          transferHostAccessDisabled ? 'disabled' : ''
        }`}
        onClick={() => {
          if (!transferHostAccessDisabled) {
            transferHostAccess()
          }
        }}
      >
        <HostIcon data-tip data-for="makeH" />
        <CustomTip
          top={-40}
          left={-25}
          class="top-right"
          place="left"
          tip="Make Host"
          dataFor="makeH"
        />
      </IconButtonMore>
      {
        isTwilio && <React.Fragment>
          <CustomTip
            top={-40}
            left={-25}
            class="top-right"
            place="left"
            tip={
              !!!muteParticipantDisabled
                ? 'Mute Participant'
                : "You can't unmute others"
            }
            dataFor="cantMute"
          />

          <IconButtonMore
            data-tip
            data-for="cantMute"
            className={`button default-opaque call-control ${
              muteParticipantDisabled ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!muteParticipantDisabled) {
                muteParticipant()
              }
            }}
          >
            {!!!muteParticipantDisabled ? (
              <MicOn data-tip data-for="muteP" />
            ) : (
              <MicOff data-tip data-for="cantMuteP" />
            )}
            {/* <CustomTip top={-40} left={-25} class='top-right' place='left' tip='Mute Participant' dataFor='muteP' />
            <CustomTip top={-40} left={-25} class='top-right' place='left' tip="You can't unmute others" dataFor='cantMuteP' /> */}
          </IconButtonMore>
        </React.Fragment>
      }
    </CallControlsContainer>
  )
}

export default ParticipantOverlay

export const IconButtonMore = styled.div<{muted?: boolean; active?: boolean}>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  font-size: 16px;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  opacity: 0;
  position: relative;
  cursor: pointer;
`

const CallControlsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 3;
`
