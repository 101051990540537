import React, { Component } from 'react'
import styled from 'styled-components'
import { MdRadioButtonChecked as RadioChecked } from '@react-icons/all-files/md/MdRadioButtonChecked'
import { MdRadioButtonUnchecked as RadioUnchecked } from '@react-icons/all-files/md/MdRadioButtonUnchecked'
import { Button } from '../styledcomponents'


interface Props {
    submit: ({ isPrivate }: { isPrivate: boolean }) => void
    callback?: () => void
}

interface State {
    selectedState: boolean
}

export default class PrivatePublicModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props) 

        this.state = {
            selectedState: false
        }

        this.handleSelectedChange = this.handleSelectedChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSelectedChange(selectedState: boolean, e: any) {
        e.stopPropagation()
        this.setState({ selectedState })
    }

    handleSubmit(e: any) {
        e.stopPropagation()
        this.props.submit({ isPrivate: this.state.selectedState })
        if(this.props.callback) {
            this.props.callback()
        }
    }

    render() {
        const { selectedState } = this.state
        return (
            <Container className="topbar rounded inner" onClick={(e) => e.stopPropagation()}>
                <Title>Select note privacy</Title>
                <Options className="container content">
                    <input type="radio" id="public" name="switch-one" checked={!!!selectedState} onClick={(e) => this.handleSelectedChange(false, e)}/>
                    <label className="container content" htmlFor="public">Public</label>
                    <input type="radio" id="private" name="switch-one" checked={selectedState} onClick={(e) => this.handleSelectedChange(true, e)} />
                    <label className="container content" htmlFor="private">Private</label>
                </Options>
                {!!!selectedState ? <div className="error">NOTE: A public note cannot be changed into a private note</div> : ''}
                <Button className="button primary" onClick={this.handleSubmit}>
                    Create note
                </Button>
            </Container>
        )
    }
}

const Container = styled.div`
    min-width: 400px;
    max-width: 400px;
    padding: 36px;
    .error {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
    }
`

const Title = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: 500;
`

const Options = styled.div`
    margin: 15px 0;
	overflow: hidden;
	display: flex;
    border-radius: 999px;

    input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
    }

    label {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        padding: 12px 16px;
        transition: all 0.1s ease-in-out;
        border-radius: 999px;
    }

    label:hover {
        cursor: pointer;
    }

    input:checked + label {
        background: #363B45 !important;
        color: white;
        box-shadow: none;
    }
`