import React from 'react';
import WidgetHeader from '../_Shared/Header';
import styled from 'styled-components';
import ArrowIcon from './arrow.svg';
import Select from '../_Shared/Select';
import { FiPlus as AddIcon } from '@react-icons/all-files/fi/FiPlus'

const Header = (props: any) => {
  const { UpdateSelf } = props.actions;
  const { screen } = props.data; 
  const screenHandler = screen.toLowerCase().includes('compan')
    ? 'companies'
    : screen.toLowerCase().includes('contact')
    ? 'contacts'
    : screen.toLowerCase().includes('deal')
    ? 'deals'
    : screen.toLowerCase().includes('task')
    ? 'tasks'
    : screen;

    
    // list all screens that hide create button
    const viewOnlyScreens = ['analytAllWebPages', 'analytDailyAllLandingPages', 'analytAllBlogPosts', 'contact', 'deal', 'company', 'analytAllMetrics', 'analytDailyTraffic', 'analytWeeklyVisitorSessions', 'analytTrafficBySource', 'analytDailyVisitByTwitter', 'analytAllVisitByGeo', 'analytAllVisitAllCampaigns'];
    // list all screens that show back button
    const viewOnlyScreensBack = ['contact', 'deal', 'company', 'createDeal', 'createContact', 'createCompany', 'task', 'createTask'];
    
    
  return (
    <CustomWidgetHeader>
      <WidgetHeader
        size={[105, 21]}
        icon={require('./icon.svg')}
        divider=''
        onClick={() => UpdateSelf({ screen: screenHandler })}
        name={
          <NameHeader>
            <CSelect
              hoverEffect={false}
              bgClass="topbar"
              selectInputClassName="active-select border-lightimp rounded inner"
              fitContent
              name='basic'
              placeholder={'Basic'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                {
                  value: 'contacts',
                  label: 'Contacts',
                },
                {
                  value: 'companies',
                  label: 'Company',
                },
                {
                  value: 'deals',
                  label: 'Deals',
                },
                // {
                //   value: 'tasks',
                //   label: 'Tasks',
                // },
              ]}
            />
            {/* <CSelect
              bgClass="topbar"
              selectInputClassName="active-select border-lightimp"
              fitContent
              name='analytics'
              placeholder={'Reports'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                {
                  value: 'analytAllWebPages',
                  label: 'All Hubspot Web Pages',
                },
                {
                  value: 'analytDailyAllLandingPages',
                  label: 'Daily All Landing Pages',
                },
                {
                  value: 'analytAllBlogPosts',
                  label: 'All Blog Posts',
                },
                {
                  value: 'analytAllMetrics',
                  label: 'All Metrics',
                },
                {
                  value: 'analytDailyTraffic',
                  label: 'Daily Traffic',
                },
                {
                  value: 'analytWeeklyVisitorSessions',
                  label: 'Weekly Visitor Session',
                },
                {
                  value: 'analytTrafficBySource',
                  label: 'Traffic by Source',
                },
                {
                  value: 'analytAllVisitByGeo',
                  label: 'All Visit by Geographic',
                },
                {
                  value: 'analytAllVisitAllCampaigns',
                  label: 'All Visits all Campaigns',
                },
              ]}
              /> */}
            {/* <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='tasks'
              placeholder={'Marketing Tasks'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                // {
                //   value: 'tasks',
                //   label: 'Tasks',
                // },
              ]}
            />
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='cms'
              placeholder={'CMS'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
                {
                  value: 'blogs',
                  label: 'Blogs',
                },
                {
                  value: 'domains',
                  label: 'Domains',
                },
                {
                  value: 'files',
                  label: 'Files',
                }
              ]}
            />
            <CSelect
              selectInputClassName="active-select border-primary"
              fitContent
              name='crm'
              placeholder={'CRM'}
              defaultValue={screenHandler || ''}
              onChange={(e: any) => UpdateSelf({ screen: e.target.value })}
              optionsList={[
              ]}
            /> */}
          </NameHeader>
        }
        end={
          <>
              <Button
              className='button default'
              onClick={() => UpdateSelf({ screen: screenHandler })}
              hidden={!viewOnlyScreensBack.includes(screen) ? true : false}
              >
              Back
              </Button>
              
              <Button
                className='button default desktop'
                onClick={() => props.useRef.current.click()}
                hidden={viewOnlyScreens.includes(screen) ? true : false}
              >
                Create
              </Button>
              <Button
                className='button default mobile'
                onClick={() => props.useRef.current.click()}
                hidden={viewOnlyScreens.includes(screen) ? true : false}
              >
                <AddIcon />
              </Button>
          </>
          }
      />
    </CustomWidgetHeader>
  );
};

export default Header;

const CSelect = styled(Select)``;

const CustomWidgetHeader = styled.div`
  align-items: center;
  align-content: center;

  .mobile {
      display: none;
  }

  @media (max-width: 555px) {
    .mobile {
      display: block;

      &.button {
        min-width: auto;
      }
    }

    .desktop {
      display: none;
    }
  }
`;

const NameHeader = styled.div`
  display: flex;
  align-items: center;
  
  div {
    margin-right: 10px;
  }
  

  .active-select {
    border: 1px solid;
    min-height: 40px;
    cursor: pointer;

    .selected-text {
      cursor: pointer;
    }

    &:hover {
      border-color: var(--primary-ui-color) !important;
    }
  }
`;


const Button = styled.button`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;
    border-radius: 10px !important;
    border: none;
    color: white;


    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.newlight};
    }
`;