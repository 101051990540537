import React, { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { WidgetActions } from "../../stores/MainStore";
const { GlobalState } = require('reflux')

export interface IClickupContext {
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    loadingData: boolean, 
    setLoadingData: React.Dispatch<React.SetStateAction<boolean>>,

    external_token: any,
    travelling: any
    
    isAuthenticated: boolean
    authFailed: () => void,

    started: boolean,
    setStarted: React.Dispatch<React.SetStateAction<boolean>>,
    currentView: string | null,
    clickupData: any,
    setClickupData: React.Dispatch<React.SetStateAction<any>>
}

interface ClickupProviderProps {
    children: ReactNode,
    userId: any
    users: any
    external_token: any
    TryRefreshToken: any
    travelling: any
    lastView: any
}

const initialData = {
    currentTeam: null, 
    currentSpace: null, 
    currentFolder: null, 
    currentList: null, 
    currentTask: null,
}

export const ClickupContext = createContext<IClickupContext>(null!)
export function ClickupContextProvider({
    children,
    external_token,
    TryRefreshToken,
    travelling,
    lastView
}: ClickupProviderProps) {
    let last_auth_attempt: any = null
    const [started, setStarted] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [currentView, setCurrentView] = useState<string | null>(null)
    const [clickupData, setClickupData] = useState<any>(initialData)

    useEffect(() => {
        setStarted(!!lastView?.view)
        setCurrentView(lastView?.view || null)
    }, [lastView?.view])

    useEffect(() => {
        setClickupData(lastView || {})
    }, [JSON.stringify(lastView?.currentTeam)])

    useEffect(() => {
        if(external_token) { setIsAuthenticated(true) }
    }, [external_token])

    useEffect(() => {
        if ((!external_token && !isAuthenticated)) {
            console.log({ external_token, isAuthenticated })
            setClickupData(initialData)
            WidgetActions.SetLastView({
                sync: 'clickup',
                data: {
                    view: null,
                    ...initialData
                }
            })
        }
    }, [external_token, isAuthenticated])


    const authFailed = () => {
        setIsAuthenticated(true)

        if (external_token && last_auth_attempt < Date.now() - 1000) {
            if (last_auth_attempt === -1) last_auth_attempt = 0 
            else last_auth_attempt = Date.now()
            
            TryRefreshToken('clickup' as any)
        }
    }

    return <ClickupContext.Provider value={{
        loading,
        setLoading,
        loadingData, 
        setLoadingData,

        authFailed,
        external_token,
        isAuthenticated,
        travelling,

        started,
        setStarted,
        currentView,
        clickupData,
        setClickupData,
    }}>
        {children}
    </ClickupContext.Provider>
}

