import styled from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";
import { createElement } from "react";

import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { GoListOrdered as OrderedIcon } from '@react-icons/all-files/go/GoListOrdered'
import { GoListUnordered as UnorderedIcon } from '@react-icons/all-files/go/GoListUnordered'
import { BiLink as LinkIcon } from '@react-icons/all-files/bi/BiLink'
import { BiUnlink as UnlinkIcon } from '@react-icons/all-files/bi/BiUnlink'
import { MdUndo as UndoIcon } from '@react-icons/all-files/md/MdUndo'
import { MdRedo as RedoIcon } from '@react-icons/all-files/md/MdRedo'

export const NotesWrapper = styled.div<{ travelling?: boolean, collapsedSidebar?: boolean }>`
    pointer-events: ${props => props.travelling ? 'none' : ''};
    display: grid;
    grid-template-rows: 54px auto;
    grid-template-areas: 'header' 'content';
    overflow: hidden;
    height: 100%;
    min-height: 500px;
    max-height: 500px;

    @media (max-width: 970px) {
        /* grid-template-columns: 0px auto; */
    }

    @media (max-height: 740px) {
        min-height: 330px;
        max-height: 330px;
    }

    @media (max-width: 480px) {
        min-height: 330px;
        max-height: 330px;
    }
    
`

export const SidebarWrapper = styled.div<{ collapsed?: boolean }>`
    grid-area: content;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    position: relative;
    transition: 0.3s display ease-in-out;
`

export const NotesContentWrapper = styled.div<{ collapsed?: boolean, isInCall?: boolean }>`
    grid-area: content;
    margin-bottom: 15px;
    font-weight: ${props => props.theme.textRegular};
    padding: 10px;
    max-height: ${props => props.isInCall ? 'calc(100vh - 220px)' : 'calc(100vh - 150px)'};
    position: relative;

    textarea {
        width: 100%;
    }

    .textarea {
        padding-left: 0px;
    }

    .notes-date {
        padding: 10px;
        padding-left: 0px;
        font-weight: 600;
    }

    .CustomRichEditor-editor {
        height: calc(100% - 80px);
        overflow-y: auto;
    }

    @media (max-width: 970px) {
        margin-left: 15px;
    }    
`

export const NotesItemWrapper = styled.div`
    padding: 15px 30px 10px 30px;
    cursor: pointer;
    position: relative;

    .preview-header {
        font-weight: ${props => props.theme.textBold};
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        .preview-title {
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 5px;
        }

        .preview-date {
            font-size: 11px;
            line-height: 13px;
            opacity: 0.5;
            margin-top: 5px;
            font-weight: ${props => props.theme.textRegular};
        }

        .preview-options {
            opacity: 1;
        }
    }

    .preview-content {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: 5px;
        flex: 1;
        margin-right: 5px;
        font-weight: 300;
        line-height: 1.1rem;
    }


    .close-icon {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        font-size: 16px;
        transition: 0.3s display ease-in-out;
        z-index: 2;
    }

    .private-flag {
        position: absolute;
        left: 15px;
        top: 20px;
    }

    &:hover {
        .close-icon {
            display: block;
        }
    }

`

const reactSvgComponentToMarkupString = (Component: any, props: any) =>
`data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props))
)}`;

export const NotesHeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;

    .notes-updatedby {
        padding: 0 10px 10px 0;
        font-size: 12px;
        font-style: italic;
        opacity: 0.5;
    }

    .note-header-right {
        justify-self: flex-end;
    }
`

export const Button = styled.div<{ disabled?: boolean }>`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }

    ${props => props.disabled ? 'opacity: 0.5; cursor: default;' : ''}
`

export const NoExistingNotesWrapper = styled.div`
    padding: 20px 30px 20px 30px;

    &.addnew {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }
`

export const NotePreview = styled.div<{ selectedRangeBounds?: any, color?: string }>`
    padding: 20px 30px 20px 30px;
    cursor: pointer;
    position: relative;

    &.whole-div {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        font-weight: ${props => props.theme.textBold};
    }

    &.no-note {
        flex-direction: column;
        font-weight: ${props => props.theme.textBold};
        text-align: center;
        height: 100%;
        align-content: center;
        display: flex;
        justify-content: center;

        .lighter {
            opacity: 0.4;
            margin-bottom: 20px;
        }
    }

`


export const NoteToolbar = styled.div`
	border: none !important;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
`

export const NoteToolbarBlock = styled.span`
    display: flex;
    margin-right: 0px !important;
	> * {
        margin: 2px;
	}
`

export const ToolbarButton = styled.button<{color?: string}>`
    border: none;
    background: none;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.ql-active {
        color: ${props => props.color || '#F89809'};
    }
`

export const EditorWrapper = styled.div`
    border: none !important;
    height: calc(100% - 25px);

    font-size: 14px;

    .ql-editor {   
        li:not(.ql-direction-rtl) {
            padding-left: 0.5em;
        }
        
        &.ql-blank::before {
            opacity: 0.5;
        }

        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-left: 2em;
        }

        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-left: 3.5em;
        }

        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-left: 5em;
        }

        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-left: 6.5em;
        }
        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-left: 8em;
        }
        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-left: 9.5em;
        }
        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-left: 11em;
        }

        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-left: 12.5em;
        }

        ul > li {
            &::before {
                font-size: 16px;
            }
        }
    }

    .ql-formats {
        margin-right: 0px;
    }
`

export const TitleWrapper = styled.div`
    width: 100%;
    .ql-editor {
        font-size: 16px;
        padding: 0px 12px;
    }
`

export const TitleEditorWrapper = styled.div`
    border: none !important;
    height: 25px;

    .ql-editor {
        &::before {
            left: 0;
            opacity: 0.5;
            font-style: normal;
        }
    }
`

export const MenuWrapper = styled.div<{collapsed?: boolean}>`
    position: absolute;
    font-size: 24px;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 4;

    @media (max-width: 970px) {
        left: ${props => props.collapsed ? '20px' : '295px'};
    }
`

export const Overlay = styled.div<{ collapsed: boolean }>`
    display: none;

    @media (max-width: 970px) {
        ${props => !props.collapsed ? 'display: block; position: absolute; left: 0; top:0; bottom: 0; right: 0; z-index: 4;' : ''};
	}
`

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    
    .icon-label {
        font-size: 12px;
    }
`


export const HeaderWrapper = styled.div`
    grid-area: header;
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

export const HeaderLeft = styled.div`
    flex: 1;
    
`

export const HeaderRight = styled.div`
    > * {
        margin-left: 10px;
        cursor: pointer;
    }

    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }
`