import styled from "styled-components"
import React, {Fragment} from 'react'
import { IoLockOpen as LockOpenIcon } from '@react-icons/all-files/io5/IoLockOpen'
import { IoLockClosed as LockClosedIcon } from '@react-icons/all-files/io5/IoLockClosed'

function StartMeeting ({ username, avatar_url, color, action = 'started' }: any) {
    return  <Fragment>
        <HostPermission>
            <div className="icon color-primary bg-primary-opaque">
                <img src={avatar_url || `https://ui-avatars.com/api/?name=${username}&format=svg&background=${color || 'random'}&color=fefefe`} alt={username} />
            </div>
            <HostPermissionContent>
                <HostPermissionMessage>
                    <div>
                        <span className="name color-primary">{username}&nbsp;</span> has {action} the meeting.
                    </div>
                </HostPermissionMessage>
            </HostPermissionContent>
        </HostPermission>
    </Fragment>
}

export default StartMeeting;

export const HostPermissionContent = styled.div`
	display: flex;    
	justify-content: center;
	flex-direction: column;
    flex: 1;
`

export const HostPermissionMessage = styled.div`
	height: 30px;
    display: flex;
    align-items: center;
`

export const HostPermission = styled.div`
	display: flex;
	font-weight: 400;

	.icon {
		width: 40px;
		height: 40px;
		padding: 5px;
		border-radius: 30px;
		margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 30px;
        }
	}

`