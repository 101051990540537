import moment from 'moment'
import React, { memo } from 'react'

import { NotesHeaderWrapper } from './styledcomponents'

interface Props {
    dateCreated: any
    dateUpdated: any
    owner: IOwner
    modifiedBy: IOwner
}

interface IOwner {
    name: string
    username: string
}

function NotesHeader(props: Props) {
    const { dateUpdated, dateCreated, owner, modifiedBy } = props
    return (
        <NotesHeaderWrapper>
            <div className="note-header-left">
                <div className="notes-date">
                    { 
                        dateUpdated || dateCreated ? 
                        moment(dateUpdated || dateCreated).format("MMMM DD, YYYY") : 
                        moment().format('MMMM DD, YYYY')
                    } at&nbsp;
                    {
                        dateUpdated || dateCreated ? 
                        moment(dateUpdated || dateCreated).format("hh:mm A") : 
                        moment().format("hh:mm A")
                    }
                </div>
                <div className="notes-updatedby">
                    Created by { owner?.name || owner?.username }<br/>
                    {(modifiedBy?.name || owner?.name) && `Last updated by ${ modifiedBy?.username || modifiedBy?.name || owner?.username || owner?.name}`}
                </div>
            </div>
        </NotesHeaderWrapper>
    )
}


{/* <div className="note-header">
    <div className="note-header-left">
        <div className="notes-date">
            {moment(activeData.date_updated || activeData.date_created).format("MMMM DD, YYYY")} at {moment(activeData.date_updated || activeData.date_created).format("hh:mm A")}
        </div>
        <div className="notes-updatedby">
            Created by {owner?.name}<br/>
            {modifiedBy?.name && `Last updated by ${modifiedBy?.name}`}
        </div>
    </div>
    <div className="note-options">
        {
            this.state.status !== CallingInstanceState.Connected ? 
            <React.Fragment>
                {window.speechSynthesis && <VoiceOutIcon className={`${this.state.narrateOn ? 'narrating' : ''} voice-icon`} onClick={this.handleReadOutLoud} />}
                <MicrophoneAnimation onClick={this.state.speechOn ? this.endSpeechRecognition : this.startSpeechRecognition} speechOn={this.state.speechOn} color={color} />
            </React.Fragment> : ''
        }
        <Dropdown items={DropdownContent} addTop={10}>
            <MoreOptionIcon />
        </Dropdown>
    </div>
</div> */}

function areEqual(prevProps: Props, nextProps: Props) {
    return prevProps.dateUpdated === nextProps.dateUpdated && 
    prevProps.dateCreated === nextProps.dateCreated && 
    JSON.stringify(prevProps.owner) === JSON.stringify(nextProps.owner) && 
    JSON.stringify(prevProps.modifiedBy) === JSON.stringify(nextProps.modifiedBy)
}


export default memo(NotesHeader, areEqual)
