import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import Folder from './Folder'
import File from './File'
import { IWidgetProps, AllWidgets, GDriveFolderStackItem, GFile } from '../types'
import Login from '../_Shared/Login'
import { WidgetContainer } from '../_Shared/Elements'
import Api, { Actions, Endpoints } from '../_Shared/Api'

import Header from './Header'

import { Editables, ExportsMenus } from './Constants'
import SyncModal from '../_Shared/SyncModal'
import LoopApi from '../../helpers/LoopApi'
import { shuffleArray } from '../../helpers'
import LoadingComponent from '../_Shared/LoadingComponent'
import { VariableCallingStore } from '../../stores/VariableCallingStore'
import { CallingInstanceState } from '../../calling/types'
import { ThemingStore } from '../../stores/ThemingStore'
import { MainStore, WidgetActions } from '../../stores/MainStore'
import { appendScript, removeScript } from '../../helpers'
import useToastify from '../../../src/helpers/useToastify'
import SuccessErrorToast from '../../../src/components/Notifications/SuccessErrorToast'

import moment from 'moment'
const { GlobalState } = require('reflux')


type Props = IWidgetProps<AllWidgets.GoogleDrive>

interface State {
	isAuthenticated: boolean
	pointerId: string
	pointerFileFormat: string
	public_permission_id: string
	files: any[]
	folders: any[]
	folderStack: GDriveFolderStackItem[]
	searchFilter: string | null
	loading: boolean
	file: GFile | null
	public_permission: string
	modal: any
	menu: string
	isUploading: boolean
	exportMenus: {name: string, mclick: Function}[]
	viewType: string
	suggestedItems: any[]
	nextPageToken: string
	isLeftVisible: boolean
	status: string
	color_scheme: string
	toSaveLink: any
	isSaveLinkFromFolder: boolean
	widgets: {
		files: {
			externals: {
				googleDrive: null | {}
			}
		}
	}
	presentation: any
	lastView: {
		googledrive: null | {
			view: string
			state: any
			folderStack: any
		}
	}
}

const syncedInfo = [
	'Read access to public files you choose',
	'Write access to public files you choose',
]

export default class GoogleDrive extends Reflux.Component<typeof VariableCallingStore | typeof ThemingStore | typeof MainStore, Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			isAuthenticated: true,
			pointerId: '',
			pointerFileFormat: 'folder',
			folderStack: [{
				name: 'Drive',
				folderId: ''
			}],
			files: [],
			folders: [],
			public_permission_id: 'anyoneWithLink',
			searchFilter: '',
			loading: false,
			file: null,
			public_permission: '',
			modal: {
				show: false,
				type: ''
			},
			menu: 'mydrive',
			isUploading: false,
			exportMenus: [],
			viewType: 'list',
			suggestedItems: [],
			nextPageToken: '',
			isLeftVisible: true,
			status: '',
			color_scheme: GlobalState.theming.color_scheme,
			toSaveLink: null,
			isSaveLinkFromFolder: false,
			widgets: {
				files: {
					externals: {
						googleDrive: null
					}
				}
			},
			presentation: {
				googledrive: null
			},
			lastView: {
				googledrive: null
			}
		}
		this.stores = [VariableCallingStore, ThemingStore, MainStore]
		this.storeKeys = [
			'status',
			'color_scheme',
			'widgets',
			'presentation',
			'lastView'
		]
	}

	last_auth_attempt = 0

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.external_token !== nextProps.external_token) {
			this.setState({ isAuthenticated: true })
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.external_token !== prevProps.external_token)
			this.restoreLastView()

		if(
			(
				this.props.data.file !== prevProps.data.file &&
				this.props.data.file === null &&
				this.state.presentation?.googledrive === 'viewing'
			) ||
			(
				this.props.data.userId &&
				!!this.props.users?.find(user => user.id === this.props.data.userId && user.status === 'offline') &&
				this.state.presentation?.googledrive === 'viewing'
			)
		) {
			WidgetActions.SetPresentation({
				sync: 'googledrive',
				val: null
			})
		}
	}

	componentDidMount() {
		appendScript('https://accounts.google.com/gsi/client')

		if (this.props.external_token) {
			this.restoreLastView()
		}

		// setTimeout(() => {
		// 	if(
		// 		this.props.data.file &&
		// 		this.props.data.userId === this.props.userId &&
		// 		this.state.presentation?.googledrive !== 'presenting'
		// 	) {
		// 		this.stopPresent()
		// 	}
		// }, 200)

	}

	componentWillUnmount() {
		removeScript('https://accounts.google.com/gsi/client')
		// if(
		// 	this.props.data.file &&
		// 	this.props.data.userId === this.props.userId &&
		// 	this.checkStatusPresenter()
		// ) {
		// 	window.removeEventListener('beforeunload', this.beforeunload);
		// 	window.removeEventListener('unload', this.unload)
		// }
	}

	//allways check authentication
	checkAuth(resp: any) {
		let err = null
		if (resp.error) {
			err = true
			switch (resp.error.code) {
				case 401:
				case 403:
					this.setState({
						isAuthenticated: false
					})
					if (
						this.props.external_token &&
						resp.error.message !== 'Insufficient Permission: Request had insufficient authentication scopes.' &&
						this.last_auth_attempt < Date.now() - 5000
					) {
						if (this.last_auth_attempt === -1) {
							this.last_auth_attempt = 0
						} else {
							this.last_auth_attempt = Date.now()
						}
						this.props.actions.TryRefreshToken('googledrive')
					}
					this.toggleLoading(false)
					break
				case 500:
					if(resp.error.message)
					console.log(`Error 500 - ${resp.error.message}`)
					break
				default:
					break
			}
		}
		else this.setState({ isAuthenticated: true })
		return err
	}

	//start of api calls
	async Files({folderId, nextPage}: {folderId?: string, nextPage?: boolean}) {
		let folder = 'root'
		if (folderId)
		folder = folderId

		const nextPageCheck = nextPage && !!this.state.nextPageToken && this.state.pointerId === folder
		nextPageCheck ? '' : this.toggleLoading(true)

		let q = ''
		let filesQuery = ''
		let foldersQuery = ''
		let squery = ''
		let setSuggested = false
		let setFilesOnly = false
		let Qpromises = []
		let suggestedItems = []

		switch(this.state.menu) {
			case 'mydrive':
				q = ` and '${folder}' in parents&fields=*&orderBy=name`
				squery = !folderId ?
					squery = `'root' in parents` : ''
			break
			case 'sharedwithme':
				if(folderId) {
					q = ` and '${folderId}' in parents&fields=*&orderBy=name`
				} else {
					q = ` and sharedWithMe=true&fields=*&orderBy=name`
					squery = `sharedWithMe=true`
				}
			break
			case 'recent':
				q = `&fields=*`
				setFilesOnly = true
			break
			case 'starred':
				q = folderId ?
				` and '${folderId}' in parents&fields=*&orderBy=name` :
				` and starred=true&fields=*&orderBy=name`
			break
		}

		if(q) {
			filesQuery = `?q=mimeType!='application/vnd.google-apps.folder' and trashed=false${q}`

			if(nextPageCheck)
			filesQuery = `${filesQuery}&pageToken=${this.state.nextPageToken}`

			Qpromises.push(Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
				urlAppend: filesQuery
			}))

			if(!setFilesOnly) {
				foldersQuery = `?q=mimeType='application/vnd.google-apps.folder' and trashed=false${q}`
				if(nextPageCheck)
				filesQuery = `${foldersQuery}&pageToken=${this.state.nextPageToken}`

				Qpromises.push(Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
					urlAppend: foldersQuery
				}))
			}

			if(squery && !nextPageCheck) {
				squery =
				`?q=${squery} and mimeType != 'application/vnd.google-apps.folder' and trashed=false&fields=*&orderBy=viewedByMeTime desc,modifiedTime desc&pageSize=20`
				Qpromises.push(Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
					urlAppend: squery
				}))
			}
		}

		const [files, folders, suggested] = await Promise.all(Qpromises)

		const err = this.checkAuth(files)

		if(err)
		return

		if(suggested) {
			const shuffled = shuffleArray(suggested.files.slice())
			suggestedItems = shuffled.slice(0, 4)
		}


		let dataToSet = {}
		if(nextPageCheck) {
			const filesUniq = Array.from(new Set([...this.state.files, ...(files ? files.files : [])]))
			dataToSet = {
				files: filesUniq,
				nextPageToken: files.nextPageToken || ''
			}
			this.setState(dataToSet)
		} else {
			dataToSet = {
				files: files ? files.files : [],
				folders: folders ? folders.files : [],
				pointerFileFormat: 'folder',
				pointerId: folder,
				public_permission_id: 'anyoneWithLink',
				file: null,
				public_permission: '',
				suggestedItems,
				nextPageToken: files.nextPageToken || ''
			}
			this.setState(dataToSet)
			this.toggleLoading(false)
		}

		WidgetActions.SetLastView({
			sync: 'googledrive',
			data: {
				folderStack: this.state.lastView.googledrive?.folderStack ? this.state.lastView.googledrive?.folderStack : this.state.folderStack,
				view: 'folder',
				state: { folderId, nextPage }
			}
		})

		return files
	}

	//get single file. based on file ID
	async File({fileId}: {fileId: string}) {
		this.toggleLoading(true)

		const date = new Date();
		const dateFormatted = date.toISOString();
		const [file, permResp] = await Promise.all([
			Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, { urlAppend: `/${fileId}?fields=*` }),
			Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
				urlAppend: `/${fileId}/permissions`,
			}),
			Api(Endpoints['GoogleDrive'], Actions[`PATCHFiles`], this.props.external_token, {
				urlAppend: `/${fileId}`,
				body: {
					viewedByMeTime: dateFormatted
				},
			})
		])

		const error = this.checkAuth(file)

		if(error)
		return

		const pub_entry =
			permResp.permissions &&
			permResp.permissions.length > 0 &&
			permResp.permissions.find((p: any) => p.type === 'anyone')
		const public_permission = pub_entry && pub_entry.role

		let mtype

		if(file && file.fullFileExtension) {
			mtype = (Object.keys(Editables.extension) as Array<keyof typeof Editables.extension>).find(editable => {
				return Editables.extension[editable].includes(file.fullFileExtension)
			})
		} else {
			mtype = (Object.keys(Editables.mimetype) as Array<keyof typeof Editables.mimetype>).find(editable => {
				return Editables.mimetype[editable].includes(file.mimeType)
			})
		}

		const { id, kind, mimeType, name, ownedByMe, iconLink, webViewLink, permissions } = file
		const filteredFile = { id, kind, mimeType, name, mtype, ownedByMe, iconLink, webViewLink, permissions }

		let dataToSet = {
			pointerFileFormat: 'file',
			pointerId: fileId,
			public_permission_id: pub_entry && pub_entry.id,
			public_permission: public_permission || '',
			file: filteredFile
		}
		this.setState(dataToSet)

		WidgetActions.SetLastView({
			sync: 'googledrive',
			data: {
				view: 'file',
				state: { fileId },
				folderStack: this.state.folderStack
			}
		})

		this.toggleLoading(false)
		return file;
	}

	async setPermission(permType: string, isPresenting: boolean, file?: GFile) {

		let currentPerms = isPresenting ? this.props.data.public_permission : this.state.public_permission
		const fi = { id: '' }
		let id = (isPresenting ? this.props.data.file : this.state.file || fi).id

		if(file) {
			const pub_entry =
			file.permissions &&
			file.permissions.length > 0 &&
			file.permissions.find((p: any) => p.type === 'anyone')
			currentPerms = pub_entry && pub_entry.role
			id = file.id
		}

		let crudType = ''
		let urlAppend = `/${id}/permissions`
		let body = undefined
		if (currentPerms && permType) {
			crudType = 'PATCH'
			urlAppend += `/${this.state.public_permission_id}`
			body = {
				role: permType,
			}
		} else if (!currentPerms && permType) {
			crudType = 'POST'
			body = {
				role: permType,
				type: 'anyone',
			}
		} else if (currentPerms && !permType) {
			crudType = 'DELETE'
			urlAppend += `/${this.state.public_permission_id}`
		} else {
			return // somethin happened
		}

		const resp = await Api(Endpoints['GoogleDrive'], Actions[`${crudType}Files` as Actions], this.props.external_token, {
			urlAppend,
			body,
		})

		if(!resp.error) {
			if(file) {
				const folderId = this.state.folderStack[this.state.folderStack.length - 1].folderId
				this.toggleLoading(true)
				if(folderId)
				this.Files({ folderId })
				else {
					this.Files({})
				}
				this.setState({ isSaveLinkFromFolder: false })
			} else {
				if(isPresenting) {
					this.setState({ public_permission_id: 'anyoneWithLink', public_permission: permType})
					this.props.actions.UpdateSelf({ public_permission: permType })
				} else {
					this.setState({ public_permission_id: 'anyoneWithLink', public_permission: permType})
				}
			}
		}

		return resp;
	}

	//copy and paste a file
	async Copy(fileId: string) {
		let distinationFolders = [this.state.pointerId]

		const resp = await Api(Endpoints['GoogleDrive'], Actions['Copy'], this.props.external_token, {
			urlAppend: `/${fileId}/copy`,
			body: { parents: distinationFolders },
		})

		this.checkAuth(resp)
	}

	Upload = async(targetfiles: any) => {
		const files = Array.from(targetfiles)

		if(!files.length)
		return

		const generateResUpload = async(file: any) => {
			let folder = ''
			if(this.state.menu === 'mydrive' &&
			this.state.pointerFileFormat == 'folder' &&
			this.state.pointerId) {
				folder = this.state.pointerId
			}

			const metadata = {
				name: file.name,
				...(file.type ? {mimeType: file.type} : null),
				...(folder ? {parents: [folder]} : null)
			}

			const formData = new FormData();
			formData.append('metadata', new Blob([JSON.stringify(metadata)], {
				type: 'application/json'
			  }));
			formData.append('file', file);

			let resp = await Api(Endpoints['GoogleDriveUpload'], Actions['POSTUpload'], this.props.external_token, {
				urlAppend: '?uploadType=multipart',
				noHeader: true,
				headerSettings: {
					contentType: false,
				},
				body: formData
			})
			return resp
		}

		const uploadPromises = files.map(f => generateResUpload(f))

		return await Promise.all(uploadPromises).catch(e => console.log(e)).finally(() => {
			this.setState({isUploading: false})
			if(this.state.menu === 'mydrive' &&
			this.state.pointerFileFormat == 'folder' &&
			this.state.pointerId) {
				if(this.state.pointerId)
				this.Files({folderId: this.state.pointerId})
				else
				this.Files({})
			}
		})
	}

	Download = async(file: any) => {

		const genLink = (url: string) => {
			const link = document.createElement('a')
            link.href = url

			if(CallingInstanceState.Connected === this.state.status)
			link.target = '_blank'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
		}

		if(file.webContentLink && !file.mimeType.includes('vnd.google-apps')) {
			const resp = await LoopApi(null, 'DownloadUrlHash',
			{type: 'download', filename: file.name, token: this.props.external_token},
			[['url', `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`]])
			genLink(resp.url)
		} else if (file.exportLinks) {
			const menus = Object.entries(ExportsMenus).reduce<any[]>((acc, [k, v]) => {
				if(file.exportLinks[k]) {
					const obj = {name: v, mclick: () => {
						genLink(file.exportLinks[k])
						this.setState({modal: {show: false, type: ''}, exportMenus: []})
					}}
					acc.push(obj)
				}
				return acc
			}, [])
			this.setState({modal: {show: true, type: 'GDExportFile'}, exportMenus: menus})
		}
	}

	//end of api calls

	//update folder stack for navigation
	updateFolderStack(action: number, params?: GDriveFolderStackItem) {
		let fstack = this.state.folderStack

		switch (action) {
			case 1:
				fstack.push(params!)
				break

			case -1:
				fstack.splice(-1, 1)
				break

			case 2:
				const ind = fstack.findIndex(x => x.folderId === params?.folderId)
				if(fstack.length > 1 && fstack.length - 1 !== ind && fstack.length - 1 > ind) {
					fstack = fstack.slice(0, ind + 1)
				}
			default:
				break
		}

		let dataToSet = { folderStack: fstack }
		this.setState({...dataToSet, searchFilter: ''})
		WidgetActions.SetLastView({
			sync: 'googledrive',
			data: {
				...this.state.lastView.googledrive,
				...dataToSet
			}
		})

		// WidgetActions.SetLastView({
		// 	sync: 'googledrive',
		// 	data: {
		// 		view: this.state.lastView.googledrive?.view ? this.state.lastView.googledrive.view : this.state.pointerFileFormat,
		// 		state: {
		// 			...(!!this.state.lastView.googledrive?.state ? this.state.lastView.googledrive.state : {}),
		// 			...dataToSet
		// 		}
		// 	}
		// })
	}

	//perform back for navigation
	back() {
		this.setState({public_permission: '', file: null})
		const folderId = this.state.folderStack[this.state.folderStack.length - 1].folderId
		this.Files({folderId})
	}

	searchFile = (entry: string) => {
		this.state.files.length > 0 && this.setState({searchFilter: entry});
	}

	toggleLoading = (state: boolean) => {
		this.setState({loading: state})
	}

	checkStatusPresenter = () => {
		const presenter = this.props.data.userId &&
			this.props.users?.find(user => user.id === this.props.data.userId && user.status === 'online')
		return !!presenter
	}

	onPresent = (isAlreadyPublic?: boolean) => {
		this.props.actions.UpdateSelf({
			public_permission: this.state.public_permission,
			file: this.state.file,
			userId: this.props.userId,
			isAlreadyPublic: isAlreadyPublic ? true: false
		})
		this.changePresentationState('presenting')
	}

	stopPresent = async () => {
		if(this.checkStatusPresenter() && this.props.data.file) {
			// const id = this.props.data.file.id
			// const externalgd: any = this.state.widgets.files?.externals?.googleDrive
			// if(
			// 	!this.props.data.isAlreadyPublic &&
			// 	externalgd &&
			// 	!externalgd[id]
			// )
			// await this.setPermission('', true)

			this.props.actions.UpdateSelf({
				public_permission: '',
				file: null,
				userId: null,
				isAlreadyPublic: false
			})

			this.changePresentationState(null)
		}
	}

	startViewing = () => {
		this.changePresentationState('viewing')
	}

	stopViewing = () => {
		this.changePresentationState(null)
	}

	// beforeunload = (event: BeforeUnloadEvent) => {
	// 	event.preventDefault()
	// 	event.returnValue = ''
	// }

	// unload = () => {
	// 	if(this.props.data.file && this.props.data.userId === this.props.userId) {
	// 		this.stopPresent()
	// 	}
	// }

	setModalType = () => {
		let actionModal = () => {}
		let closeModal = () => this.setState({modal: {show: false, type: ''}})
		let typeModal = this.state.modal.type
		let exportMenus = this.state.exportMenus.length ? this.state.exportMenus : null

		switch(this.state.modal.type) {
			case 'GDSetToPublic':
				actionModal = async () => {await this.setPermission('reader', false); this.onPresent()}
				closeModal = () => this.setState({modal: {show: false, type: ''}},
					() => !!this.state.toSaveLink && this.changeIndexState({
						modal: {
							show: true,
							type: 'FSGDduplicateLink'
						}
				}))
			break
			case 'GDStopPresentation':
				actionModal = () => {this.stopPresent()}
			break
			case 'GDStopViewingPresentation':
				actionModal = () => {this.stopViewing()}
			break
			case 'GDExportFile':
				closeModal = () => this.setState({modal: {show: false, type: ''}, exportMenus: []})
			break
			case 'FSGDduplicateLink':
				actionModal = () => this.saveLink(this.state.toSaveLink)
				closeModal = () => this.setState({modal: {show: false, type: ''}, toSaveLink: null, isSaveLinkFromFolder: false})
			break
			default:
				typeModal = ''
		}

		return !!typeModal ? <SyncModal
			actionModal={actionModal}
			closeModal={closeModal}
			type={typeModal}
			menus={exportMenus}
		/> : null
	}

	changeIndexState = (state: any, callback?: Function) => {
		this.setState(state, () => callback ? callback() : {})
  	}

	changePresentationState = (val: any) => {
		WidgetActions.SetPresentation({
			sync: 'googledrive',
			val
		})
	}

	// setLastViewedByMe = async(fileId: string) => {
	// 	let respPatch
	// 	if(this.props.external_token && this.state.isAuthenticated) {
	// 		const resp = await Api(Endpoints['GoogleDrive'], Actions['Files'], this.props.external_token, {
	// 			urlAppend: `/${fileId}`,
	// 		})

	// 		if(!!!resp.error) {
	// 			const date = new Date();
	// 			const dateFormatted = date.toISOString();
	// 			respPatch = await Api(Endpoints['GoogleDrive'], Actions[`PATCHFiles`], this.props.external_token, {
	// 				urlAppend: `/${fileId}`,
	// 				body: {
	// 					viewedByMeTime: dateFormatted
	// 				},
	// 			})
	// 		}
	// 	}
	// 	return respPatch
	// }

	handleNextPage = () => {
		if(this.state.nextPageToken) {
			this.Files({folderId: this.state.pointerId, nextPage: true})
		}
	}

	saveLink = (file: any, isFromFolder?: boolean) => {
		const {id, name, mimeType, iconLink, webViewLink} = file

		let mtype

		if(file && file.fullFileExtension) {
			mtype = (Object.keys(Editables.extension) as Array<keyof typeof Editables.extension>).find(editable => {
				return Editables.extension[editable].includes(file.fullFileExtension)
			})
		} else {
			mtype = (Object.keys(Editables.mimetype) as Array<keyof typeof Editables.mimetype>).find(editable => {
				return Editables.mimetype[editable].includes(file.mimeType)
			})
		}

		const updatedFile = {
			id,
			name,
			mimeType,
			iconLink,
			webViewLink,
			mtype,
			savedTime: moment(new Date()).format(),
			linkDescription: '',
			linkTitle: file.name
		}

		let toUpdate = {
			name: 'files',
			externals: {
				googleDrive: {
					...(!!this.state.widgets.files?.externals?.googleDrive ?
						this.state.widgets.files?.externals?.googleDrive : {}),
					[id]: updatedFile
				}
			}
		}

		if(!!this.state.toSaveLink) {
			this.setState({toSaveLink: null})
		}

		WidgetActions.CreateOrUpdateWidget(
			toUpdate,
			false
		)

		useToastify({
			message: () => SuccessErrorToast({ message: 'Successfully saved link.' }),
			position: "top-right",
			autoClose: 1500,
			closeButton: false,
			hideProgressBar: true,
			className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
			bodyClassName: "grow-font-size",
		})

		// set perm to public
		if(isFromFolder || this.state.isSaveLinkFromFolder) {
			const pub_entry =
			file.permissions &&
			file.permissions.length > 0 &&
			file.permissions.find((p: any) => p.type === 'anyone')
			const publicperm = pub_entry && pub_entry.role

			if(!publicperm) {
				this.setPermission('reader', false, file)
			}
		}
	}

	restoreLastView = () => {
		if(this.state.lastView?.googledrive?.view) {
			const { view, state, folderStack } = this.state.lastView.googledrive
			this.setState({folderStack})
			switch(view) {
				case 'folder':
					this.Files({folderId: state.folderId, nextPage: state.nextPage})
					break
				case 'file':
					this.File({fileId: state.fileId})
					break
				default:
					break
			}
		} else {
			this.setState({folderStack: [{
				name: 'Drive',
				folderId: ''
			}]})
			this.Files({})
		}
	}

	render() {
		// console.log('this.state :>> ', this.state);
		// console.log('this.props :>> ', this.props);
		const { searchFilter, files, folders } = this.state;
		const filteredFiles = searchFilter && files.filter(file => file.name.toLowerCase().includes(searchFilter.toLowerCase())) || files;
		const filteredFolders = searchFilter && folders.filter(folder => folder.name.toLowerCase().includes(searchFilter.toLowerCase())) || folders;

		//Presentation
		const isPresenterOnline = !!this.props.data.file && this.checkStatusPresenter()
		const isPresenter = isPresenterOnline && this.props.data.userId === this.props.userId
		const viewPresenting = isPresenterOnline && !isPresenter && this.state.presentation?.googledrive === 'viewing'
		const unauthenticatedViewer = isPresenterOnline && (!this.props.external_token || !this.state.isAuthenticated)
		const isPresentingOrViewing = viewPresenting || isPresenter || unauthenticatedViewer

		const GDExternals = this.state.widgets.files?.externals?.googleDrive

		if ((!this.props.external_token || !this.state.isAuthenticated) && !unauthenticatedViewer)
			return (
				<Login
					name="Google Drive"
					logo={require('./icon.svg')}
					loginKey="google"
					syncedInfo={syncedInfo}
				/>
			)
		else
			return (
				this.state.loading ?
				<Loading><LoadingComponent /></Loading> :
				<MainWidgetContainer>
					<Header
						getFiles={async(folderId: string) => this.Files({folderId})}
						public_permission={isPresentingOrViewing ? this.props.data.public_permission : this.state.public_permission}
						folderStack={this.state.folderStack}
						updateFolderStack={(action: number, params: GDriveFolderStackItem) =>
							this.updateFolderStack(action, params)
						}
						setPermission={(val: string, val2: boolean) => this.setPermission(val, val2)}
						searchFile={(val: string) => this.searchFile(val)}
						toggleLoading={(val: boolean) => this.toggleLoading(val)}
						file={isPresentingOrViewing ? this.props.data.file : this.state.file}
						onPresent={this.onPresent}
						isPresenterOnline={isPresenterOnline}
						isPresenter={isPresenter}
						viewPresenting={viewPresenting}
						unauthenticatedViewer={unauthenticatedViewer}
						changeIndexState={this.changeIndexState}
						hasPresentingFile={!!this.props.data.file}
						back={() => this.back()}
						isPresentingOrViewing={isPresentingOrViewing}
						colorScheme={this.state.color_scheme}
						saveLink={this.saveLink}
						GDExternals={GDExternals}
						startViewing={this.startViewing}
					/>
					<CustomWidgetContainer>
						{this.state.file || isPresentingOrViewing ? (
							<File
								bot_mode={this.props.bot_mode || null}
								perms={isPresentingOrViewing ? this.props.data.public_permission : this.state.public_permission}
								file={isPresentingOrViewing ? this.props.data.file : this.state.file}
								CreateOrUpdateWidget={this.props.actions.CreateOrUpdateWidget}
								// setLastViewedByMe={this.setLastViewedByMe}
								isOwner={!!this.state.file}
							/>
						) : (
							<Folder
								pointerFileFormat={this.state.pointerFileFormat}
								files={filteredFiles}
								folders={filteredFolders}
								getFiles={(folderId: string) => this.Files({folderId})}
								getFile={(fileId: string) => this.File({fileId})}
								folderStack={this.state.folderStack}
								updateFolderStack={(action: number, params: GDriveFolderStackItem) =>
									this.updateFolderStack(action, params)
								}
								loading={this.state.loading}
								menu={this.state.menu}
								changeIndexState={this.changeIndexState}
								upload={this.Upload}
								isUploading={this.state.isUploading}
								onPresent={this.onPresent}
								Download={this.Download}
								viewType={this.state.viewType}
								suggestedItems={this.state.suggestedItems}
								handleNextPage={this.handleNextPage}
								nexPageLoading={!!this.state.nextPageToken}
								isLeftVisible={this.state.isLeftVisible}
								colorScheme={this.state.color_scheme}
								saveLink={this.saveLink}
								GDExternals={GDExternals}
								isPresenterOnline={isPresenterOnline}
							/>
						)}
					</CustomWidgetContainer>
					{this.state.modal.show && this.setModalType()}
				</MainWidgetContainer>
			)
	}

}

const CustomWidgetContainer = styled(WidgetContainer)`

`
const MainWidgetContainer = styled(WidgetContainer)`
	flex-direction: column;
`

const Loading = styled.div`
	width: 100%;
	height: 100%;
	z-index: 2;
`
