import React, {Fragment, useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import { BiChevronRight } from 'react-icons/bi'
import { MdCancelPresentation, MdOutlineStopScreenShare } from 'react-icons/md'
import { SiSlideshare } from 'react-icons/si'
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai'
import { BsChevronDown } from 'react-icons/bs'
import { GDriveFolderStackItem, GFile } from '../types'
import WidgetHeader from '../_Shared/Header'
import DropdownWrapper from '../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import ShareMenu from './ShareMenu'

interface Props {
    folderStack: GDriveFolderStackItem[]
    public_permission: null | string
    file: GFile | null
    updateFolderStack: (f: number, s: GDriveFolderStackItem) => void
    getFiles: (s: string) => Promise<any>
    setPermission: (s: string, s2: boolean) => Promise<any>
    searchFile: (s: string) => void
    toggleLoading: (s: boolean) => void
    onPresent: Function
    changeIndexState: Function
    isPresenter: boolean
    viewPresenting: boolean
    isPresenterOnline: boolean
    hasPresentingFile: boolean
    unauthenticatedViewer: boolean
    back: () => void
	isPresentingOrViewing: boolean
    colorScheme: string
    saveLink: Function
    GDExternals: any
    startViewing: Function
}

const Header = (props: Props) => {

    const {folderStack,
        public_permission,
        file,
        setPermission,
        updateFolderStack,
        getFiles,
        searchFile,
        toggleLoading,
        onPresent,
        isPresenter,
        viewPresenting,
        changeIndexState,
        isPresenterOnline,
        hasPresentingFile,
        unauthenticatedViewer,
        isPresentingOrViewing,
        back,
        saveLink,
        GDExternals,
        startViewing } = props

    const [fStack, setFStack] = useState<GDriveFolderStackItem[]>([])


    useEffect(() => {
        let folders: GDriveFolderStackItem[];
        const fs = (limit: number) => folderStack.length > limit ? folderStack.slice(folderStack.length - limit) : folderStack
        folders = fs(2)
        folders && setFStack(folders);
    }, [props])


    const changeFolder = async (param: GDriveFolderStackItem) => {
        toggleLoading(true)
		updateFolderStack(2, param)
		await getFiles(param.folderId)
        toggleLoading(false)
    }

    const searchFileHandler = (val: string) => {
        searchFile(val)
    }

    let crumbs = null

    if(isPresenter || viewPresenting || unauthenticatedViewer) {
        crumbs = (
            <Fragment>
                <BreadCrumb
                    type="button"
                    className="breadcrumb-button filename"
                >
                    {file?.name}
                </BreadCrumb>
            </Fragment>
        )
    } else {
        crumbs = fStack.map((folder, index, arr) => (
            <Fragment key={index}>
                <BreadCrumb
                    type="button"
                    key={folder.folderId}
                    onClick={() => changeFolder(folder)}
                    className="breadcrumb-button"
                >
                    {folder.name}
                </BreadCrumb>
                {index === arr.length - 1 ? null: <BiChevronRight />}
            </Fragment>
        ))

        if(file) {
            crumbs.push(
                <Fragment>
                    <BreadCrumb
                        type="button"
                        className="breadcrumb-button filename"
                    >
                        {file?.name}
                    </BreadCrumb>
                </Fragment>
            )
        }
    }

    const setGridtoThree = (!!!file && !(!viewPresenting && !isPresenter && isPresenterOnline)) || (!!file && viewPresenting)
    return (
        <HeaderContainer className="container">
            <HeaderWrapper className="container" setGridtoThree={setGridtoThree}>
                <LeftControls>
                <WidgetHeader
                    icon={require("./icon.svg")}
                    size={['35px', '35px']}
                    divider={''}
                />
                <BreadCrumbContainer>{crumbs}</BreadCrumbContainer>
                </LeftControls>
                <RightControls
                    isLight={props.colorScheme === 'Light'}
                >
                {file && (
                    <Fragment>
                        {
                            (!hasPresentingFile || !isPresenterOnline) &&
                            <Dropdown
                                width="200px"
                                DropdownItems={ShareMenu}
                                noright
                                title="Share"
                                publicPermission={public_permission}
                                setPermission={setPermission}
                                onPresent={onPresent}
                                changeIndexState={changeIndexState}
                                hasMtype={!!file.mtype}
                                file={file}
                                GDExternals={GDExternals}
                                saveLink={() => saveLink(file)}
                            >
                                <Share>
                                    <div>Share</div>
                                    <div><BsChevronDown size={15} /></div>
                                </Share>
                            </Dropdown>
                        }

                        {isPresenter &&
                            <Present className="button default" onClick={() =>
                                changeIndexState({
                                    modal: {
                                        show: true,
                                        type: 'GDStopPresentation'
                                    }
                                })}
                            >
                                <MdOutlineStopScreenShare size={22}/>
                                <span>Stop Presentation</span>
                            </Present>
                        }

                        {!viewPresenting && !isPresenter && !unauthenticatedViewer && isPresenterOnline &&
                            <Present className="button primary" onClick={() => startViewing()}>
                                <SiSlideshare size={22}/>
                                <span>View Presentation</span>
                            </Present>
                        }

                        {viewPresenting && !unauthenticatedViewer &&
                            <React.Fragment>
                                <Present className="button default" onClick={() =>
                                    changeIndexState({
                                        modal: {
                                            show: true,
                                            type: 'GDStopViewingPresentation'
                                        }
                                    })}
                                >
                                    <MdCancelPresentation size={22}/>
                                    <span>Stop Viewing</span>
                                </Present>
                            </React.Fragment>
                        }

                        {!isPresentingOrViewing &&
                            <div className="button default" style={{cursor: 'pointer', marginLeft: '10px'}}
                                onClick={() => back()}
                            >
                                Back
                            </div>
                        }
                    </Fragment>
                )}
                {!file && (
                    <React.Fragment>
                        {!viewPresenting && !isPresenter && isPresenterOnline &&
                            <Present className="button primary" onClick={() => startViewing()}>
                                <SiSlideshare size={22}/>
                                <span>View Presentation</span>
                            </Present>
                        }
                        <SearchContainer
                            className="border-light topbar"
                        >
                            <label htmlFor="search_file">
                                <SearchIcon />
                            </label>
                            <SearchInput
                                id="search_file"
                                type="search"
                                placeholder="Search file"
                                className="dropbox-search-input"
                                onChange={(e) => searchFileHandler(e.target.value)}
                            />
                        </SearchContainer>
                    </React.Fragment>
                )}
                </RightControls>
            </HeaderWrapper>
        </HeaderContainer>
    )
}



const HeaderContainer = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	padding: 16px;
    background-color: #f2f4f6;
    z-index: 1;
    border-radius: 15px;
`

const BreadCrumbContainer = styled.div`
	font-size: 16px;
	display: flex;
	align-items: center;
    flex-wrap: wrap;
`

const BreadCrumb = styled.button`
	background: none;
	border: none;
	cursor: pointer;
    padding: 4px 4px;
    font-size: 10px;
	border-radius: 24px;
    max-width: 124px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.5s;

	&:hover:not(.filename) {
		background-color: rgba(0, 0, 0, 0.3);
	}

	&:last-child:not(:first-child) {
		color: #fff;
		background-color: #33333d;
        margin-left: 5px;
	}

    &.filename {
        cursor: default;
        text-overflow: unset;
        max-width: unset;
        color: #fff;
		background-color: #33333d;
    }

    @media (min-width: 410px){
        padding: 4px 12px;
        font-size: 16px;
    }
`

const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	border: 1px solid;
	border-radius: 32px;
    margin-left: 20px;
    margin-top: 20px;

    @media (min-width: 640px){
        margin-top: 0;
    }
`

const SearchInput = styled.input`
	background: none;
	border: none;
	padding-left: 8px;
`

const HeaderWrapper = styled.div<{setGridtoThree: boolean}>`
    @media (min-width: 640px){
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content:space-between;
    }
`

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
`

const Present = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding: 8px 12px !important;

    span {
        margin-left: 5px;
        white-space: nowrap;
    }
`

const RightControls = styled.div<{isLight: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px){
        flex-direction: row;
        align-items: center;
    }

    ${Present} {
        ${({isLight}) => isLight ? 'background: transparent;' : 'background: #414052;'}
    }
`

const LeftControls = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px){
        flex-direction: row;
        align-items: center;
    }
`

const Share = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > div:nth-child(1) {
        margin-right: 20px;
    }
`

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
    margin-right: 25px;
	font-size: 14px;
	position: relative;

	${props => props.noRightMargin ? 'margin-right: 0px;' : ''}
`

export default Header;