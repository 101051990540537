import React from 'react';
import CallsContent from './calls';
import EmailsContent from './emails';
import MeetingsContent from './meetings';
import NotesContent from './notes';
import TasksContent from './tasks';

const ViewContent = (props: any) => {
    const { tab } = props;

    return (
        <>
            {tab === 'notes' ? (
                <NotesContent {...props} />
            ) : tab === 'emails' ? (
                <EmailsContent {...props} />
            ) : tab === 'calls' ? (
                <CallsContent {...props} />
            ) : tab === 'task' ? (
                <TasksContent {...props} />
            ) : tab === 'meetings' ? (
                <MeetingsContent {...props} />
            ) : null}
        </>
    );
};

export default ViewContent;
