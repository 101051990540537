
import React, { createElement, ReactElement, useState, useRef, useEffect} from 'react'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html';
import styled from 'styled-components';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import { getVisibleSelectionRect, convertToRaw, convertFromHTML, EditorState, ContentState, Modifier, getDefaultKeyBinding, RichUtils } from 'draft-js'
import { renderToStaticMarkup } from "react-dom/server";
import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { GoListOrdered as OrderedIcon } from '@react-icons/all-files/go/GoListOrdered'
import { GoListUnordered as UnorderedIcon } from '@react-icons/all-files/go/GoListUnordered'
import { BiLink as LinkIcon } from '@react-icons/all-files/bi/BiLink'
import { BiUnlink as UnlinkIcon } from '@react-icons/all-files/bi/BiUnlink'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const { GlobalState } = require('reflux')

import urlRegex from 'url-regex'

interface Props {
    activeData: ActiveData
    handleContentUpdate: (id: string, content: string, pureText: string) => void
    updateSelf: (obj: any) => void
    updating: any
    speechOn?: boolean
    transcribing?: any
    addLink: (s: string) => void
    updatedFrom: string
}

interface ActiveData {
    id: string
    content: any
    editorState: any
    updated_by: string
}
interface RangeBounds {
    top: number
    left: number
    bottom: number
    right: number
    width: number
    height: number
}

interface ClientBound {
    left?: number
    top?: number
    width?: number
    right?: number
}

function NotesEditor(props: Props): ReactElement {
    let editor = useRef<any>(null)
    const editorRef = useRef<any>(null);

    const [activeData, setActiveData] = useState<ActiveData | null>(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [themingStore] = useState(GlobalState.theming)
    const [authStore] = useState(GlobalState.auth)
    const [selectedRangeBounds, setSelectedRangeBounds ] = useState<RangeBounds | null>(null)
    const [clientRangeBounds, setClientRangeBounds] = useState<ClientBound | null>(null)
    
    const onEditorStateChange = (editorStateP: any) => {
        const selectionState = editorStateP.getSelection()
        const anchorKey = selectionState.getAnchorKey()
        const currentContent = editorStateP.getCurrentContent()
        const currentContentBlock = currentContent.getBlockForKey(anchorKey)
        const start = selectionState.getStartOffset()
        const end = selectionState.getEndOffset()
        const selectedText = currentContentBlock.getText().slice(start, end)
        
        if(selectedText) {
            const rangeBounds = getVisibleSelectionRect(window)
            let selected = null
            if (rangeBounds) selected = rangeBounds
            setSelectedRangeBounds(selected) 

            EditorState.acceptSelection(editorState, selectionState)
            
            const { width, left, top, right  } = editor.current?.getBoundingClientRect()
            setClientRangeBounds({ width, left, top, right })
            
        } else {
            setSelectedRangeBounds(null)
        }


        const pureText = editorStateP.getCurrentContent().getPlainText()
        const content = draftToHtml(convertToRaw(editorStateP.getCurrentContent()))
        const prevContent = props.activeData?.content

        if(prevContent !== content) {
            props.handleContentUpdate(props.activeData?.id,  content, pureText)
        }

        setEditorState(editorStateP);
        props.updateSelf({ updating: authStore?.jwt?.data._id, transcribing: null })
        // focusEditor();
    };

    const updateEditorState = () => {
        const blocksFromHtml = htmlToDraft(props.activeData?.content)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
        let editorStateS = EditorState.createWithContent(contentState)
        
        setEditorState(editorStateS)
        setActiveData(props.activeData)
    }

    const handlePaste = (e: any) => {
        if (editorRef && editorRef.current.state.editorFocused && e.clipboardData.files[0] === undefined) {
            const textData = e.clipboardData.getData('Text')
            const urls = textData && textData.match(urlRegex()) || []
			urls && urls.length > 0 && props.addLink(urls)
        }
    }

    useEffect(() => {
        updateEditorState()
        document.addEventListener('paste', handlePaste);
        return () => document.removeEventListener('paste', handlePaste);
    }, [])

    useEffect(() => {
        if(props.updating !== authStore?.jwt?.data?._id) {
            updateEditorState()
        }

        if(props.activeData?.id !== activeData?.id) {
            updateEditorState()
        }

        if(JSON.stringify(props.activeData?.content) !== JSON.stringify(activeData?.content) && props.updating === authStore?.jwt?.data?._id && props.updatedFrom === 'main') {
            updateEditorState()
        }

        if(props.speechOn || props.transcribing === authStore?.jwt?.data?._id) {
            updateEditorState()
        }

        () => {
            props.updateSelf({ updating: null, transcribing: null})
        }
        
    }, [props.activeData, props.speechOn, props.updatedFrom])

    let className = 'CustomRichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' Custom-hidePlaceholder';
        }
    }

    const myKeyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
        if (e.keyCode === 8 && !contentState.hasText() && contentState.getBlockMap().first().getType() !== 'unstyled') {
            //console.log("e: ", e.keyCode, "   editorstate: ", contentState.getBlockMap().first().getType() !== 'unstyled' )
            const editorstate = getResetEditorState(editorState)
            setEditorState(editorstate)
            // props.updateSelf({ updating: authStore?.jwt?.data._id, transcribing: null })
        }
        return getDefaultKeyBinding(e);
    }

    const removeSelectedBlocksStyle = (editorstate: any)  => {
        const newContentState = RichUtils.tryToRemoveBlockStyle(editorstate);
        if (newContentState) {
            return EditorState.push(editorstate, newContentState, 'change-block-type');
        }
        return editorstate;
    }
    
    const getResetEditorState = (editorstate: any) => {
        const blocks = editorstate
            .getCurrentContent()
            .getBlockMap()
            .toList();
        const updatedSelection = editorstate.getSelection().merge({
            anchorKey: blocks.first().get('key'),
            anchorOffset: 0,
            focusKey: blocks.last().get('key'),
            focusOffset: blocks.last().getLength(),
        });
        const newContentState = Modifier.removeRange(
            editorstate.getCurrentContent(),
            updatedSelection,
            'forward'
        );
    
        const newState = EditorState.push(editorstate, newContentState, 'remove-range');
        return removeSelectedBlocksStyle(newState)
    }
    
    
    return (
        <EditorWrapper 
            ref={editor}
            data-text-editor="name" 
            bgColor={themingStore.color_scheme === 'Light' ? '#FFFFFF' : '#181820'}
            color={themingStore.color_scheme === 'Light' ? '#F89809' : '#008BFF'} 
            defColor={themingStore.color_scheme === 'Light' ? '#363B45' : '#FFFFFF'}
            clientRangeBounds={clientRangeBounds}
            selectedRangeBounds={selectedRangeBounds}
            className={className}
        >
            <Editor 
                ref={editorRef}
                editorState={editorState}
                placeholder="Start taking notes!"
                onEditorStateChange={(editorState: EditorState) => onEditorStateChange(editorState)} 
                wrapperClassName="notes-editor-wrapper"
                toolbarClassName="notes-toolbar"
                toolbar={toolbar}
                handlePastedText={() => false} 
                {...{keyBindingFn: myKeyBindingFn}}
            />
        </EditorWrapper>
    )
}

const toolbar = {
    options: ['inline', 'blockType', 'list', 'link'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: { className: "notes-bold" },
        italic: { className: "notes-italic" },
        underline: { className: "notes-underline" },
        strikethrough: { className: "notes-strikethrough" },
    },
    blockType: {
        inDropdown: false,
        options: ['Blockquote', 'Code'],
        className: "notes-blocktype",
    },
    list: {
        options: ['unordered', 'ordered'],
        unordered: { className: "notes-unordered" },
        ordered: { className: "notes-ordered" },
    },
    link: {
        options: ['link', 'unlink'],
        link: { className: "notes-link" },
        unlink: { className: "notes-unlink" },
    },
}

const reactSvgComponentToMarkupString = (Component: any, props: any) =>
`data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props))
)}`;


const EditorWrapper = styled.div<{defColor: string, color: string, bgColor: string, selectedRangeBounds?: any, clientRangeBounds?: ClientBound | null}>`
    height: 100%;

    &.Custom-hidePlaceholder {
        .public-DraftEditorPlaceholder-root {
            display: none;
        }
    }

    .notes-editor-wrapper {
        padding: 10px;
        font-weight: 400;
        color: inherit;

        .rdw-link-modal {
            left: -135px;
            background: ${props => props.bgColor};
            box-shadow: ${props => props.theme.shadows.neumorphiclight};
            border: none;
        }
    }

    .public-DraftEditorPlaceholder-inner {
        font-style: italic;
        font-weight: 400;
        color: ${props => props.defColor};
    }

    .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
        margin-left: 3.5em;
    }
    .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
        margin-left: 5em;
    }
    .public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
        margin-left: 6.5em;
    }
    .public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
        margin-left: 8em;
    }
    .public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
        margin-left: 9.5em;
    }

    .notes-toolbar {
        border-radius: 5px;
        z-index: 2;
        font-size: 14px;
        padding: 10px 5px;
        margin-bottom: 0;

        .rdw-inline-wrapper, .rdw-inline-wrapper, .rdw-list-wrapper, .rdw-link-wrapper {
            margin-bottom: 0;
        }

        .notes-italic, .notes-bold, .notes-underline, .notes-strikethrough, .notes-ordered, .notes-unordered, .notes-link, .notes-unlink {
            img { display: none; }
        }

        .rdw-option-wrapper {
            border: none;
            background: transparent;

            &:hover {
                box-shadow: none;
            }
        }

        .rdw-option-active {
            box-shadow: none;
        }

        .notes-bold {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(BoldIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(BoldIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-italic {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(ItalicIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(ItalicIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-underline {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnderlineIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UnderlineIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-strikethrough {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(StrikeIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(StrikeIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-unordered {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnorderedIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UnorderedIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-ordered {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(OrderedIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(OrderedIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-link {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(LinkIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(LinkIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-unlink {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnlinkIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UnlinkIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-blocktype {
            font-size: 0;

            div:first-child {
                &::before {
                    content: ${({ defColor }) =>
                    `url(${reactSvgComponentToMarkupString(QuoteIcon, {
                        color: defColor
                    })})`};
                }

                &.rdw-option-active {
                    &::before {
                        content: ${({ color }) =>
                        `url(${reactSvgComponentToMarkupString(QuoteIcon, {
                            color: color
                        })})`};
                    }
                }
            }

            div:nth-child(2) {
                &::before {
                    content: ${({ defColor }) =>
                    `url(${reactSvgComponentToMarkupString(CodeIcon, {
                        color: defColor
                    })})`};
                }

                &.rdw-option-active {
                    &::before {
                        content: ${({ color }) =>
                        `url(${reactSvgComponentToMarkupString(CodeIcon, {
                            color: color
                        })})`};
                    }
                }
            }
        }

    }

    @media (max-width: 480px) {
        .notes-editor-wrapper {
            .rdw-link-modal {
                left: 10px;
                width: 280px;
                height: 180px;
                overflow: auto;
            }
        }
    }
`

export default NotesEditor