import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { AiOutlineStar } from '@react-icons/all-files/ai/AiOutlineStar'
import { AiFillStar } from '@react-icons/all-files/ai/AiFillStar'
import LoopApi from '../../helpers/LoopApi'

import { MainActions, WidgetActions } from '../../stores/MainStore'

const formatNum = (timestamp: any) => new moment(timestamp).format('h:mm:ssa')

interface Props {
  rec: IRec
  getTranscriptions: () => void
  updateSelf: (data: any) => void
  forceUpdate?: any
  highlight: any
  removeHighlight: any
  live?: boolean
}

interface IRec {
  user: any
  text: string
  timestamp: Date
  stared?: boolean
  owner: any
  id?: string
  t_id?: string
}

const TranscriptionItem = (props: Props) => {
  const [star, setStar] = React.useState(null) as any

  const highlight = () => {
    props.highlight(props)
  }

  const removeHighlight = () => {
    props.removeHighlight(props)
  }

  React.useEffect(() => {}, [star])

  return (
    <Container>
      <Name owner={props.rec.owner}>
        [{props.live ? 'Live' : moment(props.rec.timestamp).format('hh:mm:ss')}]{' '}
        {props.rec.owner ? 'You' : props.rec.user?.name}
      </Name>
      <TextEntry
        className={props.rec.stared || star ? 't-highlighted' : ''}
        stared={props.rec.stared || star}
      >
        {props.rec.text}
      </TextEntry>
      {/* <Timestamp>{formatNum(props.rec.timestamp)}</Timestamp> */}
      {!props.live ? (
        <StarDiv>
          {props.rec.stared || star ? (
            <StarCont>
              <AiFillStar
                onClick={() => {
                  setStar(null)
                  removeHighlight()
                }}
                size="22px"
              />
            </StarCont>
          ) : (
            <AiOutlineStar
              onClick={() => {
                setStar(true)
                highlight()
              }}
              size="22px"
            />
          )}
        </StarDiv>
      ) : null}
    </Container>
  )
}

export default TranscriptionItem

const Timestamp = styled.div`
  font-variant: tabular-nums;
  font-family: monospace;
  opacity: 0.6;
`

const Name = styled.div<{ owner: boolean }>`
  /* align-self: flex-start; */
  width: 150px;
  color: ${(props) => (props.owner ? '#FF8200' : '#0659A9')};
  line-break: anywhere;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  padding: 0 30px;
  overflow: hidden;
  margin: 10px 0;

  > * {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`

const TextEntry = styled.div<{ stared?: boolean }>`
  align-self: center;
  padding: 0 10px 0 0;
  flex: 1;
  background-color: ${(props) => (props.stared ? '#ffee00' : '')};
`
const StarCont = styled.div`
  color: #ffee00;
`
const StarDiv = styled.div`
  cursor: pointer;
  margin-left: 5px;
`
