import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FiChevronLeft as BackIcon } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'

import DropdownWrapper from '../../../../../../loop-widgets/_Shared/DropdownWrapper'
import { HeaderLeft, HeaderRight, HeaderWrapper } from '../styledcomponents'
import MoreOptions from '../Content/MoreOptions'
import useNotesContext from '../useNotesContext'
import DeleteNotes from '../modals/DeleteNotes'
import Modal from '../../../../../../loop-widgets/_Shared/Modal'
import Title from '../Content/Title'

interface INotesData { 
    id: string
    title: string
    content: string
    created_by: string
    date_created: string
    date_updated: string
    updated_by: string
    isPrivate: boolean
}

function HeaderContent() {
  const { sidebarNotes, activeNote, users, userId, deleteNote, connection, setActiveNote, updateActiveSync } = useNotesContext()
  const [ openDelete, setOpenDelete ] = useState<boolean>(false)
  const [notesData, setNotesData] = useState<INotesData>({
      id: '',
      title: '',
      content: '',
      created_by: '',
      date_created: '',
      date_updated: '',
      updated_by: '',
      isPrivate: false
  })

  useEffect(() => {
      //@ts-ignore
      const note =  connection.get("sharednotessidebar", activeNote);
      function unsubscribeNotesDoc() {
          note.unsubscribe()
      }
      
      note.subscribe(update)
      note.on('op', update)
      function update(err: Error) {
          // if(err) //console.log("Error in notes header: ", err)
          setNotesData({ ...(note?.data || {}) })
      }
      return () => {
          unsubscribeNotesDoc()
      }
  }, [activeNote, userId])


  const { created_by, isPrivate } = notesData
  return (
    <React.Fragment>
        <Modal show={openDelete} overlayClicked={() => setOpenDelete(false)}>
            <DeleteNotes
                handleClose={() => setOpenDelete(false)}
                handleSubmit={(e: any) => { 
                    deleteNote({ id: activeNote, e })
                    setOpenDelete(false)
                    setActiveNote(null)
                }}
            />
        </Modal>
        <HeaderWrapper className="border-light">
            <HeaderLeft style={{ display: 'flex', alignItems: 'center' }}>
                <BackIcon className="btn" size={20} onClick={() => setActiveNote(null)}/>
                <Title />
                {notesData.isPrivate ? <span><LockedIcon size={10} title="Private note" /></span> : ''} 
            </HeaderLeft>
            <HeaderRight style={{ display: 'flex' }}>
                <Dropdown items={MoreOptions} componentProps={{ setOpenDelete, created_by, userId, isPrivate }}>
                    <MoreOptionsIcon size={20} />
                </Dropdown>
                <CloseIcon size={20} onClick={() => updateActiveSync('')} />
            </HeaderRight>
        </HeaderWrapper>
    </React.Fragment>
  )
}

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	position: relative;
    .dropdown-content {
        right: -10px;
    }
`

export default HeaderContent