import React, { Component } from 'react'
import styled from 'styled-components'

import SearchLinkInput from './SearchLinkInput'
import LinkInput from './LinkInput'
import Links from './Links'
import { IWidgetProps, AllWidgets } from '../types'
import SyncModal from '../_Shared/SyncModal'
import LinkEdit from './LinkEdit'
import { Button } from '../../components/Elements'
import LinkAdd from './LinkAdd'

type Props = IWidgetProps<AllWidgets.Links>

interface State {
	delModal: boolean
	selectedLink: string | null
	dupModal: boolean
	duplicateLink: any
	filter: string
	editModal: boolean
	editItem: any | null
	invalidModal: boolean
	addModal: boolean
}

interface ILink {
	url: string
}

// From https://github.com/remarkablemark/youtube-video-id but I've augmented
const youtube_regex = /(youtube\.com\/watch\?.*v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
const getYouTubeVideoId = (try_string: string) => {
	const match = try_string.match(youtube_regex)
	if (match && match.length > 1) {
		return match[2]
	}
	return try_string
}

const soundcloud_regex = /(soundcloud\.com\/)([a-zA-Z0-9_/-]+)/
const getSoundCLoudTrack = (url: string) => {
	const match = url.match(soundcloud_regex)
	if (match && match.length > 1) {
		return match[2]
	}
	return url
}

export default class LinksWidget extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			delModal: false,
			selectedLink: null,
			dupModal: false,
			invalidModal: false,
			duplicateLink: {},
			filter: "",
			editModal: false,
			addModal: false,
			editItem: null
		}
		this.onClickLink = this.onClickLink.bind(this)
		this.onSearch = this.onSearch.bind(this);
	}

	onClickLink(link: ILink, isRaw: boolean = false) {
		if (isRaw) {
			window.open(link?.url, '_blank')?.focus()
		} else if (
			link?.url?.startsWith('https://www.youtube.com') ||
			link?.url?.startsWith('https://youtu.be')
		) {
			const videoId = getYouTubeVideoId(link?.url)
			this.props.actions.CreateOrUpdateWidget(
				{ name: 'youtube', videoId },
				true
			)
			this.props.actions.ScrollIntoView('youtube')
		} else if (link?.url?.startsWith('https://www.twitch.tv')) {
			this.props.actions.CreateOrUpdateWidget(
				{
					name: 'twitch',
					channel: link?.url,
				},
				true
			)
		} else if (link?.url?.startsWith('https://soundcloud.com')) {
			const soundcloudId = getSoundCLoudTrack(link.url)
			this.props.actions.CreateOrUpdateWidget(
				{
					name: 'soundcloud',
					track: soundcloudId,
				},
				true
			)
		} else {
			window.open(link?.url, '_blank')?.focus()
		}
	}

	toggleDeleteModal = (link: string | null) => {
		this.setState({
			delModal: link ? true : false,
			selectedLink: link
		})
	}

	onSearch(keyword: string) {
		this.setState({ filter: keyword });
	}

	toggleDuplicateModal = ({val, link} : {val: boolean, link?: any}) => {
		this.setState({dupModal: val})

		if(!!link) {
			this.setState({duplicateLink: link})
		}
	}

	toggleInvalidModal = ({val}: {val: boolean}) => {
		this.setState({invalidModal: val})
	}

	handleCallback = ({action, data}: {action: any, data: any}) => {
		if(action === 'duplicate') {
			this.toggleDuplicateModal(data)
		} else if(action === 'invalid') {
			this.toggleInvalidModal({val: true})
		}
	}

	showDuplicate = () => {
		try {
			let elem = Array.from(document.getElementsByClassName(`link-${this.state.duplicateLink.timestamp}`) as
				  HTMLCollectionOf<HTMLElement>)[0]

			elem.scrollIntoView({
				  block: 'center',
				  behavior: 'smooth'
			})
			elem.classList.add('selected-link');

			setTimeout(() => {
				  elem.classList.remove('selected-link');
				  this.setState({duplicateLink: {}})
			}, 3000);

		} catch (e) {
				console.log('error', e)
		}
	}

	toggleEditModal = (editItem: any, editModal: boolean) => {
		this.setState({
			editModal,
			editItem
		})
	}

	saveEdit = ({title, description, url}: {title: string, description: string, url: string}) => {
		const toUpdate = this.transformLinks().reduce((acc: any, link: any) => {
			if(link.url === url) {
				acc = {
					[`${link.timestamp}`]: {
						...link,
						title,
						description
					}
				}
			}
			return acc
		}, {})
		this.props.actions.UpdateSelf({
			links: {...this.props.data.links, ...toUpdate}
		})
		this.toggleEditModal(null, false)
	}

	transformLinks = () => {
		let links = this.props.data.links
		links = !links ? [] : !Array.isArray(links) ? Object.values(links) : links
		return links.length ? links.sort((a: any, b: any) => a.timestamp - b.timestamp) : links
	}

	addLink = ({ url, is_valid }: { url: any, is_valid?: boolean }) => {

		if (url.length === 0 || !is_valid) {
			return
		}

		this.props.actions.AddLink(url, this.handleCallback, 'links')
	}

	render() {
		const { travelling } = this.props;
		let links = this.transformLinks();
		if (this.state.filter && this.state.filter.length != 0) {
			links = links.filter((link: any) => {
				const filter = this.state.filter.toLowerCase()
				const { url, description, title } = link
				if(url.toLowerCase().includes(filter))
				return true
				else if(description && description.toLowerCase().includes(filter))
				return true
				else if(title && title.toLowerCase().includes(filter))
				return true
				else
				return false
			});
		}

		return (
			<Container className="" travelling={travelling}>
				<InnerWrapper className="topbar rounded inner">
					<Header>
						<Ico>
                            <img src={require("./icon.svg")} />
							<span>Links</span>
                        </Ico>
						<SearchLinkInput travelling={travelling} onSearch={(keyword: string)=> { this.onSearch(keyword); }} openAdd={() => this.setState({ addModal: true })} />
					</Header>
					<Links
						links={links}
						linkClicked={this.onClickLink}
						openDeleteModal={(link: string) => this.toggleDeleteModal(link)}
						travelling={travelling}
						toggleEditModal={this.toggleEditModal}
					/>
					{/* {!travelling && <LinkInput addLink={this.props.actions.AddLink} handleCallback={this.handleCallback} />} */}
					{this.state.delModal &&
						<SyncModal
							actionModal={() => this.props.actions.RemoveLinkSync(this.state.selectedLink)}
							closeModal={() => this.toggleDeleteModal(null)}
							type="removeLink"
						/>
					}
					{this.state.dupModal &&
						<SyncModal
							actionModal={() => this.showDuplicate()}
							closeModal={() => this.toggleDuplicateModal({val: false})}
							type="duplicateLink"
						/>
					}
					{this.state.invalidModal &&
						<SyncModal
							actionModal={() => {}}
							closeModal={() => this.toggleInvalidModal({val: false})}
							type="invalidLink"
						/>
					}
					{this.state.editModal &&
						<LinkEdit
							actionModal={(edit) => this.saveEdit(edit)}
							closeModal={() => this.toggleEditModal(null, false)}
							editItem={this.state.editItem}
						/>
					}
					{this.state.addModal &&
						<LinkAdd
							actionModal={this.addLink}
							closeModal={() => this.setState({ addModal: false })}
						/>
					}
				</InnerWrapper>
			</Container>
		)
	}
}

const Container = styled.div<{travelling: boolean | undefined}>`
	padding: 15px;
	display: ${({travelling}) => travelling ? 'block' : 'flex'};
	/* position: absolute;
	left: 0;
	top: 0; */
	/* height: calc(100% - 30px);
	width: calc(100% - 30px); */
	/* overflow: hidden; */
	/* margin: 15px; */
	/* padding-bottom: 60px; */
`

const InnerWrapper = styled.div`
	flex: 1;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5px 5px 0px 15px;
`

const Ico = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	img {
		height: 26px;
	}

	span {
		margin-left: 10px;
		font-size: 20px;
	}
`