import React, { createRef, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Mention, MentionsInput } from 'react-mentions'
import { withMemoizedContext, } from 'react-memoized-context';

import { MdAttachFile } from '@react-icons/all-files/md/MdAttachFile'
import { AiOutlineSend } from '@react-icons/all-files/ai/AiOutlineSend'
import { AiOutlineFile } from '@react-icons/all-files/ai/AiOutlineFile'
import { AiOutlineCloseCircle } from '@react-icons/all-files/ai/AiOutlineCloseCircle'
import { RiAddLine } from '@react-icons/all-files/ri/RiAddLine'
import { FaUsers } from '@react-icons/all-files/fa/FaUsers'

import ChatAvatar from './ChatAvatar'
import { IContext } from './types'
import { ChatContext } from './ChatContext';
import { formatBytes } from './chatHelper';

const { GlobalState } = require('reflux')

interface Props {
    context: IContext
}

function ChatFooter({ context }: Props) {


    const [attachments, setAttachments]: any = useState([])
    const [message, setMessage] = useState('')

    const fileInput: any = createRef();

    const handleOnChange = async (e: any) => {
        let files = Array.from(e.target.files)
   

        if (files.length === 0) {
            return
        }

        const getSrc = (file: any) => {
            return new Promise((resolve) => {
                if (file.type.indexOf("image") !== 0) {
                    resolve('')
                }
                var reader = new FileReader();
                reader.onload = event => {
                    if (event.target) {
                        resolve(event.target.result)
                    } else {
                        resolve('')
                    }
                };
                reader.readAsDataURL(file)
            })
        }

        const promises = files.map(file => getSrc(file))
        const sources = await Promise.all(promises)

        files = files.map((file, index) => {
            return {
                file,
                src: sources[index]
            }
        })

        setAttachments((prevState: any) => [...prevState, ...files]);

    }

    const removeAttachment = (index: number) => {
        let newArr = attachments

        console.log(newArr);
        newArr.splice(index, 1)

        setAttachments([...newArr])
    }

    const extractMesssage = () => {

        let mentions: any = []
        let text = message.trim();
        setTimeout(() => {
            setMessage('')
        }, 1);
        const patmentns = /(\@\[[^\]]+\]\([^\)]+\))/g
        const mentns = text.match(patmentns)
        if (mentns && mentns.length) {
            mentns.map(mentn => {
                const patmentn = /\[([^\]]+)\]\(([^\)]+)\)$/g
                const mentnMatches = [...mentn.matchAll(patmentn)][0]
                if (mentnMatches.length > 0) {
                    const name = mentnMatches[1]
                    const userid = mentnMatches[2]
                    const matched = modifiedUsers.find(u => userid === u.id)
                    if (!!matched) {
                        mentions.push(userid)
                        text = text.replace(mentn, `@${name}`)
                    }
                }
            })
        }

        context.sendMessage(text, mentions, attachments)
        setAttachments([])
    }


    //Insert 'All' in chat mention
    var modifiedUsers = [{ id: 'All', display: 'All' }]

    context.users.map((u: any) => {
        const name = u.first_name + u.last_name
        u.display = name ? name : u.name
        modifiedUsers.push(u)
    })


    return useMemo(() => (
        <Footer >
            {!!attachments?.length &&
                <Attachments flex={attachments?.length > 2}>
                    <AttachBinder>
                        {attachments.map((f: any, key: number) =>
                            <Attachment key={key} className='container'>
                                <div className='delete' onClick={() => removeAttachment(key)}>
                                    <AiOutlineCloseCircle size={22} />
                                </div>
                                {f.src ?
                                    <img
                                        src={f.src}
                                        alt={f.file.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    :
                                    <>
                                        <FileIconWrapper>

                                            <FIleType>
                                                {f.file.name.substr(f.file.name.lastIndexOf('.') + 1)}
                                            </FIleType>
                                            <AiOutlineFile size={32} />
                                        </FileIconWrapper>
                                        <FileName>{f.file.name}</FileName>
                                        <FileSize> {formatBytes(f.file.size)}</FileSize>
                                    </>
                                }

                            </Attachment>)}
                        {/* {Add Button} */}
                        <Attachment className='add container' onClick={() => fileInput.current.click()}>
                            <RiAddLine size={25} />
                        </Attachment>
                    </AttachBinder>
                </Attachments>
            }
            <input
                type="file"
                ref={fileInput}
                hidden
                multiple
                onChange={handleOnChange}
            />
            <InputButtonBinder>
                <MentionsInput
                    className="chat-widget-mentions-input topbar"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={style}
                    placeholder={"Mention people using '@'"}
                    a11ySuggestionsListLabel={"Suggested mentions"}
                    onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            extractMesssage()
                        }
                    }}
                >
                    <Mention

                        // markup="@[__display__](name)"
                        trigger="@"
                        data={(modifiedUsers || []).filter((u) => u.id !== context.myId)}
                        renderSuggestion={(suggestion: any, search, highlightedDisplay) => {

                            return <div className="user">
                                {suggestion.display == 'All' ?
                                    <div style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <FaUsers size={22} />
                                    </div>
                                    :
                                    <ChatAvatar id={suggestion.id} small />}
                                <span className='user-name'>
                                    {suggestion.display}
                                </span>
                            </div>
                        }
                        }
                        // onAdd={onAdd}
                        displayTransform={(_, display) => `@${display}`}
                        style={{ overflow: 'auto' }}
                    />
                </MentionsInput>

                {/* {BUTTONS} */}
                <ButtonCont onClick={extractMesssage} className='topbar'>
                    <AiOutlineSend style={{ opacity: `${(message !== '' || !!attachments?.length) ? '1' : '.5'}` }} size={20} />
                    {/* <AiOutlineSend size={20} /> */}
                </ButtonCont>

                <ButtonCont className='topbar' onClick={() => fileInput.current.click()}>
                    < MdAttachFile size={20} />
                </ButtonCont>
            </InputButtonBinder>

        </Footer>
    ), [context.myId, context.users, attachments, message])
}

const ComponentContexted = withMemoizedContext(ChatContext)(ChatFooter, ['myId', 'users', 'sendMessage']);

export default ComponentContexted


const Footer = styled.div`
    margin-top: 2px;
    flex-direction: column;
    display: flex;
`

const Attachments = styled.div<{ flex: boolean }>`
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: ${({ flex }) => flex ? 'row-reverse' : 'row'};
`

const AttachBinder = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 10px 0 10px;

    .add{
        justify-content: center;
        align-items: center;
    }
`

const Attachment = styled.div`
    min-height: 70px;
    min-width: 100px;
    max-height: 70px;
    max-width: 70px;
    display: flex;
    margin: 0 10px 2px 10px;
    overflow: hidden;
    flex-direction: column;
    padding: 5px;
    cursor: pointer;
    user-select: none;
    position: relative;

    :hover{
        .delete{
            opacity: 1;
        }
    }
    .delete{
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
`


let style = {
    borderRadius: 10,
    maxWidth: 'calc(100% - 70px)',
    width: '100%',
    input: {
        overflow: 'auto',
        borderRadius: 10,
        padding: 8,
        maxHeight: 100,
        border: 'solid 1px lightgray',
        color: 'inherit'
    },
    highlighter: {
        boxSizing: 'border-box',
        overflow: 'hidden',
        maxHeight: 100,
        minHeight: 35,
    },
}

const FileName = styled.span`
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
`
const FileSize = styled.span`
    font-size: 10px;
`

const FIleType = styled.span`
    position: absolute;
    font-size: 10px;
    margin-top: 5px;
`

const FileIconWrapper = styled.div`
    justify-content: center;
    align-items: center;

    display: flex;
   
`

const InputButtonBinder = styled.div`
    height: fit-content;
    padding: 10px;
    display: flex;
    align-items: center;
    max-height: 120px;

    .chat-widget-mentions-input__suggestions {
        bottom: 32px !important;
        top: unset !important;
        border-radius: 10px;
        min-width: 150px !important;
        box-shadow: ${props => props.theme.shadows.newheavy};
        > ul {
            border: 1px solid lightgray;
            border-radius: 10px;

            > li {
                padding: 2px;
                padding: 6px 10px;
                &:not(:last-child) {
               
                    margin-bottom: 2px;
                }

                &:last-child{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:first-child{
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }
            }
        }
    }
    
    .chat-widget-mentions-input__suggestions__list {
        color: inherit;
        font-size: 16px;
        padding: 5px 15px;
        max-height: 200px;
        overflow-y: auto;
    }

    .user{
        display: flex;
        align-items: center;
        max-width: 300px;
        min-width: 200px;
    }

    .user-name{
        margin-left: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
    }
`


const ButtonCont = styled.div`
    width: 40px;
    height: 35px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center ;
    margin-left: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
    cursor: pointer;
`
