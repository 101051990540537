import React, { Fragment } from 'react'
import styled from 'styled-components'
import Modal from "../../components/Modalv2";

interface Props {
	closeModal: () => void
  actionModal: (a?: any) => void
  type: any
  menus?: any
}

type MtypesType = {
  [key: string]: any
}

const Mtypes: MtypesType = {
  removeLink: {
    header: 'Remove Link',
    message: 'Are you sure you want to remove this link?',
    closeBtn: 'Cancel',
    closeClass: 'button default',
    actionBtn: 'Remove',
    actionClass: 'button danger'
  },
  duplicateLink: {
    header: 'Duplicate Link',
    message: 'This link already exists',
    actionBtn: 'Ok',
    actionClass: 'button default'
  },
  invalidLink: {
    header: 'Invalid Link',
    message: 'There is a problem parsing this link',
    actionBtn: 'Ok',
    actionClass: 'button default'
  },
  GDSetToPublic: {
    header: 'Present File',
    message: (<Fragment>For all users to view the presentation, <br /> the file has to be public</Fragment>),
    actionBtn: 'Make Public',
    actionClass: 'button default',
    closeBtn: 'Cancel',
    closeClass: 'button default'
  },
  GDStopPresentation: {
    header: 'Stop Presentation',
    message: 'Are you sure you want to stop presenting?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button danger'
  },
  GDStopViewingPresentation: {
    header: 'Stop Viewing',
    message: 'Are you sure you want to stop viewing the presentation?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button danger'
  },
  GDExportFile: {
    header: 'Export File',
    message: 'Choose from the following file types',
    closeBtn: 'Close',
    closeClass: 'button default',
  },
  FSGDdeleteLink: {
    header: 'Remove Link',
    message: 'Are you sure you want to remove this link?',
    closeBtn: 'Cancel',
    closeClass: 'button default',
    actionBtn: 'Remove',
    actionClass: 'button danger'
  },
  FSGDduplicateLink: {
    header: 'Duplicate Link',
    message: 'This link already exists. Do you want to overwrite?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button default'
  },
  DBStopPresentation: {
    header: 'Stop Presentation',
    message: 'Are you sure you want to stop presenting?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button danger'
  },
  DBStopViewingPresentation: {
    header: 'Stop Viewing',
    message: 'Are you sure you want to stop viewing the presentation?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button danger'
  },
  FilesStopPresentation: {
    header: 'Stop Presentation',
    message: 'Are you sure you want to stop presenting?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button danger'
  },
  FilesStopViewingPresentation: {
    header: 'Stop Viewing',
    message: 'Are you sure you want to stop viewing the presentation?',
    closeBtn: 'No',
    closeClass: 'button default',
    actionBtn: 'Yes',
    actionClass: 'button danger'
  },
}

const ClickMenu = (menus: any) => {
  return (
    <Menus>
      {menus.map((m: any) => {
        return <MenuItem onClick={() => m.mclick()}>
          {m.name}
        </MenuItem>
      })}
    </Menus>
  )
}

const SyncModal = (props: Props) => {
    const { closeModal, actionModal, type, menus } = props;
    return Mtypes[type] && (
        <Modal {...props}>
            <Container className="topbar rounded inner">
              <div className="m-wrapper">
                  <Header>
                    {Mtypes[type].header}
                  </Header>
                  <Message>
                    {Mtypes[type].message}
                  </Message>
              </div>
              {menus ? ClickMenu(menus) : null}
              <ButtonCont>
                  { Mtypes[type].closeBtn ? <CloseBtn
                    className={Mtypes[type].closeClass}
                    onClick={() => closeModal()}
                  >
                    {Mtypes[type].closeBtn}
                  </CloseBtn> : null}
                  { Mtypes[type].actionBtn ? <ActionBtn
                    className={Mtypes[type].actionClass}
                    onClick={() => {
                      actionModal()
                      closeModal()
                    }}
                  >
                    {Mtypes[type].actionBtn}
                  </ActionBtn> : null}
              </ButtonCont>
            </Container>
        </Modal>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  .m-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 16px;
  padding: 0 12px;
`

const Message = styled.span`
  font-weight: 400;
  font-size: 13px;
  padding: 0 12px;
  padding-top: 10px;
`

const ButtonCont = styled.div`
  display: flex;
  width: 70%;
  padding: 0 12px;
`
const ActionBtn = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 14px;
  margin: 4px;
  white-space: nowrap;
`
const CloseBtn = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 4px;
  white-space: nowrap;
`

const Menus = styled.div`
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
`

const MenuItem = styled.div`
  padding: 15px 30px;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #f1f1f1;
  }
`



export default SyncModal;