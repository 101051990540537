import React, { FormEvent } from 'react'
import { useHistory } from 'react-router'

import { LoginForm, LogoIcon, LogoWrapper, SubmitButton, SubTitle, Title } from './styled'
import EmailSentIcon from './emailsent.svg'

interface Props {
    email?: string,
    displayName?: string
}

function FinishedSignup(props: Props) {
    const history = useHistory()

    const onFinishedSignup = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        history.push('/login')
    }

  
  return (
    <LoginForm onSubmit={onFinishedSignup} style={{ minHeight: '200px' }}>
        <LogoWrapper>
            <LogoIcon src={EmailSentIcon} alt={'Email'} />
        </LogoWrapper>
        <Title>
            Thanks for signing up, {props.displayName}!
        </Title>
        <SubTitle>A verification email has been sent to <strong>{props.email}</strong>. Please check your email to verify the account</SubTitle>
        <SubmitButton
            className="button primary"
            value="Ok"
            type="submit"
            style={{ marginBottom: 0 }}
        />
    </LoginForm>
  )
}

export default FinishedSignup