import React, { Dispatch, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ISelectedData } from '../types'
import LeftDisplay from './LeftDisplay'
import RightDisplay from './RightDisplay'
import { fetchAllUsers } from '../../../helpers/TimeTravel'

interface Props {
    selectedData: ISelectedData
    setSelectedData: Dispatch<any>
}

function DataDisplay(props: Props) {
    const { setSelectedData, selectedData } = props
    const [ sItem, setSItem ] = useState({})
    const [ users, setUsers ] = useState([])

    useEffect(() => {
        return () => {
            setSelectedData({ 
                transcriptions: [],
                recordings: []
            })
        }
    }, [])

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = async () => {
		try {
			const all_users = await fetchAllUsers()
			const usrs: any = {}
			for (let i = 0, len = all_users.length; i < len; i++) {
				const u = all_users[i]
				usrs[u._id] = u
			}
			setUsers(usrs)
		} catch (e) {
			console.error(e)
		}
	}


    return (
        <DisplayWrapper>
            <LeftDisplay selectedData={selectedData} setSItem={setSItem} />
            <RightDisplay sItem={sItem} users={users} setSItem={setSItem} />
        </DisplayWrapper>
    )
}

const DisplayWrapper = styled.div`
    display: flex;
    flex: 1;
    padding-top: 15px;
    max-width: calc(100vw - 30px);
`

export default DataDisplay
