import React, { useMemo } from 'react'
import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'

import { MenuWrapper, NotePreview, NotesContentWrapper } from '../styledcomponents'
import useNotesContext from '../useNotesContext'
import Editor from './Editor'
import Header from './Header'
import Title from './Title'
import HeaderContent from '../Header/HeaderContent'
import LoadingComponent from '../../../../Loader'

function Content() {
    const { activeNote, sidebarNotes, collapsedSidebar, initialized, initializedTitle } = useNotesContext()
    const sidebarKeys = Object.keys(sidebarNotes) || []
    const isEmpty = sidebarKeys.length === 0

    return useMemo(() => {
        if(isEmpty) {
            return <NotesContentWrapper className="topbar rounded inner" collapsed={collapsedSidebar}>
                <NotePreview className="whole-div no-note">
                    <div className="lighter">Your notes is empty.</div>
                </NotePreview>
            </NotesContentWrapper>
        }
        if(!activeNote) {
            return <React.Fragment></React.Fragment>
        }
        return <React.Fragment>
            {!!!initialized && !!!initializedTitle && <LoadingComponent info={'Preparing notes content'} className="topbar" />}
            <HeaderContent />
            <NotesContentWrapper className="topbar rounded inner notes-contentwrapper" collapsed={collapsedSidebar}>
                <Editor />
            </NotesContentWrapper>
        </React.Fragment>
    }, [activeNote, isEmpty, collapsedSidebar, initialized, initializedTitle])
}

export default Content