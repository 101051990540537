import { DailyCall, DailyEventObjectParticipant, DailyParticipant } from '@daily-co/daily-js'
import {useEffect, useState} from 'react'
import {RemoteParticipant, Room} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import { sortDate } from '../../../../../helpers'
import useDominantSpeaker from './useDominantSpeaker'
import useVideoContext from './useVideoContext'
import LoopApi from '../../../../../helpers/LoopApi'
import { VariableCallingActions } from '../../../../../stores/VariableCallingStore'

const { GlobalState } = require('reflux')

export default function useParticipants() {
  const {room, callProvider} = useMeetingContext()
  const {host} = useVideoContext()
  const dominantSpeaker = useDominantSpeaker()
  const [participants, setParticipants] = useState<any>(
    callProvider === CallingProviderName.DailyCo ? Object.values((room as DailyCall).participants() || {})?.filter((t: DailyParticipant) => !!!t.local) :
    Array.from((room as Room)?.participants?.values() ?? []),
  )

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.

  // EDIT (Jason): [Dec. 8, 2021] - Do not move the dominant speaker on top, so the whole useEffect was commented out
  // useEffect(() => {
  //   if (dominantSpeaker) {
  //     setParticipants(prevParticipants => [
  //       dominantSpeaker,
  //       ...prevParticipants.filter(participant => participant !== dominantSpeaker),
  //     ]);
  //   }
  // }, [dominantSpeaker]);


  useEffect(() => {
    if (room) {
      const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
  
      const isDaily = callProvider === CallingProviderName.DailyCo
      
      const participantConnected = (participant?: RemoteParticipant | DailyEventObjectParticipant) => {
        if(isTwilio) {
          setParticipants((prevParticipants: any) => [
            ...prevParticipants,
            participant,
          ])
        } else if(isDaily) {
          if((participant as DailyEventObjectParticipant).participant?.local) return
          setParticipants((prevParticipants: any) => [
            ...prevParticipants,
            (participant as DailyEventObjectParticipant).participant,
          ])
          VariableCallingActions.NotifyJoinMeeting({ isDaily: true, id: (participant as DailyEventObjectParticipant)?.participant?.user_name?.split("::")?.[1] })
        }
      }
      const participantDisconnected = async(participant?: RemoteParticipant | DailyEventObjectParticipant) => {
        if(isTwilio) {
          setParticipants((prevParticipants: any) =>
            prevParticipants.filter((p: any) => p !== participant),
          )
        } else if(isDaily) {
          setParticipants((prevParticipants: any) =>
            prevParticipants.filter((p: DailyParticipant) => (p?.user_name || p?.user_id) !== ((participant as DailyEventObjectParticipant)?.participant?.user_name || (participant as DailyEventObjectParticipant)?.participant?.user_id)),
          )
          VariableCallingActions.NotifyLeftMeeting({ isDaily: true, id: (participant as DailyEventObjectParticipant)?.participant?.user_name?.split("::")?.[1] })
          const remainingParticipant = Object.values((room as DailyCall).participants() || {}).filter((p: DailyParticipant) => (p?.user_name || p?.user_id) !== ((participant as DailyEventObjectParticipant)?.participant?.user_name || (participant as DailyEventObjectParticipant)?.participant?.user_id))
          if((participant as DailyEventObjectParticipant)?.participant?.user_name?.split("::")?.[1] === host) {
            const sortedParticipants = remainingParticipant.slice().sort((a: any, b: any) => sortDate(a.joined_at, b.joined_at))
            const host = sortedParticipants.map((p: any) => (p?.local ? GlobalState.auth.jwt.data._id : p?.user_name?.split("::")?.[1]))?.[0]
          
            await LoopApi(null, 'UpdateRecordingHost', { 
              session_id: GlobalState.calling?.session_id || '',  
              host
            }, [])

            VariableCallingActions.SetHost({ host })
          }
        }
      }

      if(isTwilio) {
        (room as Room).on('participantConnected', participantConnected);
        (room as Room).on('participantDisconnected', participantDisconnected);
      } else if(isDaily) {
        (room as DailyCall).on('participant-joined', participantConnected);
        (room as DailyCall).on('participant-left', participantDisconnected);
        
      }
      return () => {
        if(isTwilio) {
          (room as Room).off('participantConnected', participantConnected);
          (room as Room).off('participantDisconnected', participantDisconnected);
        } else if(isDaily) {
          (room as DailyCall).off('participant-joined', participantConnected);
          (room as DailyCall).off('participant-left', participantDisconnected);
        }
      }
    }
  }, [room, callProvider, host])

  return participants
}
