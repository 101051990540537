import React, { FormEvent } from 'react'
import { FiArrowLeft as BackIcon } from '@react-icons/all-files/fi/FiArrowLeft'

import { BackButton, Content, LoginForm, SubmitButton, Title } from './styled'
import LoopApi from '../../helpers/LoopApi'
import useToastify from '../../helpers/useToastify'
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast'
import { useHistory } from 'react-router'

interface Props {
    onOk: () => void
    username?: string
    // email: string
}

function ArchivedAccount({ username, onOk }: Props) {
    let history = useHistory()

    const onBack = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        // history.push('/login')
        onOk()
    }

  
  return (
    <LoginForm onSubmit={onBack}>
        <Title>
            Account is archived
        </Title>
        <Content>Hi, <span className='emphasis'>{username || 'User'}</span>! It seems that your account has been <span style={{fontWeight: 600}}>inactive / archived</span>. Please contact your organization admin or support team. Thank you!</Content>
        <SubmitButton
            className="button primary"
            // disabled={this.state.isMagicLink ? this.state.isSendingMagicLink : this.state.loggingIn}
            value="OK"
            type="submit"
        />
    </LoginForm>
  )
}

export default ArchivedAccount