import React, { useEffect, useMemo, useState } from 'react'

import VideoTrack from './VideoTrack'
import AudioTrack from './AudioTrack'
import CallControls from './CallControls'
import ParticipantInfo from './ParticipantInfo'
import LoopApi from '../../../../../helpers/LoopApi'
import OtherParticipantsControl from './OtherParticipantsControl'
import { IFakeMediasoupOuterTrack } from '../../../Sidebar/UsersInCall/types'

import { AvatarBg } from './styles'
import { ParticipantsTileWrapper } from '../styles'


interface Props {
    participant?: {
		avatar_url?: string
		username?: string
		name: string
		id: string
	}
	videoTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	audioTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	preview: boolean
    currentUser?: boolean
    onVideoToggle?: () => void
    onAudioToggle?: () => void
    isAudioEnabled?: boolean
    isCameraEnabled?: boolean
    isHost?: boolean
    youAreHost?: boolean
    meetingName?: string
    screenshareTile?: boolean
    onClick?: () => void
}

function ParticipantTile(props: Props) {
    const { participant, videoTrack, audioTrack, currentUser, preview, isAudioEnabled, isCameraEnabled, youAreHost, isHost, meetingName, screenshareTile, onClick = () => {} } = props
    const callControls = useMemo(() => <CallControls audioTrack={audioTrack} videoTrack={videoTrack} participant={participant} />, [audioTrack, videoTrack, participant])

    const transferHostAccess = async () => {
		try {
			await LoopApi(null, 'TransferHost', { participant }, [
				['name', meetingName],
			])
		} catch (e) {
			console.error(e)
		}
	}

    return (
        <ParticipantsTileWrapper className={`callParticipants container rounded inner ${screenshareTile ? 'screenshareTile' : ''}`} onClick={onClick}>
            {!isCameraEnabled && !screenshareTile && <AvatarBg avatar_url={participant?.avatar_url || ''} />}
            {!screenshareTile && <ParticipantInfo key={participant?.id} audioTrack={audioTrack}  currentUser={currentUser || false} preview={preview} participant={participant} isAudioEnabled={isAudioEnabled} />}
            <VideoTrack key={`${videoTrack?.track?.id}-videotrack`} track={videoTrack} screenshareTile={screenshareTile} />
            {!!!currentUser && !screenshareTile && <AudioTrack key={`${audioTrack?.track?.id}-audiotrack`} track={audioTrack} />}
            {
                currentUser ? 
                callControls :
                <OtherParticipantsControl 
                    transferHostAccess={transferHostAccess}
                    showTransferAccess={!isHost && youAreHost}
                />
            }
        </ParticipantsTileWrapper>
    )
}

export default ParticipantTile
