import React, { useEffect, useMemo } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import Quill, { DeltaStatic, Sources } from 'quill';
import Scrollbars from 'react-custom-scrollbars';

import { TitleEditorWrapper, TitleWrapper } from '../styledcomponents'
import useNotesContext from '../useNotesContext'
import moment from 'moment';

const BackgroundClass = Quill.import('attributors/class/background');
const ColorClass = Quill.import('attributors/class/color');
Quill.register(BackgroundClass, false);
Quill.register(ColorClass, false);

function Title() {
    const { activeNote, roomDoc, connection, userId, setInitializedTitle } = useNotesContext()
    let lastUpdateTimeout: any = null
    useEffect(() => {
        if (!!!activeNote) return
        //@ts-ignore
        const notesTitleDoc = connection.get('sharednotestitle', activeNote)
        try {
            if (!notesTitleDoc) return
            // Mark: Room subscription to sharedb (notes)
            notesTitleDoc.subscribe((error: any) => {
                if (error) return console.error("Error subscribing to notes title", error)

                // If doc.type is undefined, the document has not been created, so let's create it
                if (!notesTitleDoc.type) {
                    console.error("Error getting notes")
                } else {
                    const options = {
                        theme: 'snow',
                        modules: {
                            toolbar: null
                        },
                        placeholder: 'New note',
                    };

                    let quill = new Quill('#title-mini-editor', options);
                    quill.setContents(notesTitleDoc.data);
                    setInitializedTitle(true);

                    /**
                     * On Text change publishing to our server
                     * so that it can be broadcasted to all other clients
                     */
                    quill.on('text-change', function (delta: DeltaStatic, oldDelta: DeltaStatic, source: Sources) {
                        if (source !== 'user') return;
                        notesTitleDoc.submitOp(delta, { source: quill });
                        if (roomDoc && quill) {
                          
                            //@ts-ignore
                            const notesSidebarDoc = connection.get("sharednotessidebar", activeNote);
                            if(!notesSidebarDoc) return 

                            const title = quill.getText(0, 70)?.trim()
                            const date_updated = moment().format()
                            notesSidebarDoc.submitOp([{
                                p: ['title'],
                                oi: title
                            }, {
                                p: ['date_updated'],
                                oi: date_updated
                            }])

                            clearTimeout(lastUpdateTimeout) 
                            lastUpdateTimeout = setTimeout(() => {
                                notesSidebarDoc.submitOp([{
                                    p: ['updated_by'],
                                    oi: userId || null
                                }])
                            }, 1500)  
                        }
                    });
                    /** listening to changes in the document
                     * that is coming from our server
                     */
                    notesTitleDoc.on('op', function (op: any, source: any) {
                        if (source === quill) return;
                        if (!quill) return
                        quill.updateContents(op);
                        if (roomDoc) {
                            //@ts-ignore
                            const notesSidebarDoc = connection.get("sharednotessidebar", activeNote);
                            if(!notesSidebarDoc) return 

                            const title = quill.getText(0, 70)?.trim()
                            const date_updated = moment().format()
                            notesSidebarDoc.submitOp([{
                                p: ['title'],
                                oi: title
                            }, {
                                p: ['date_updated'],
                                oi: date_updated
                            }])
                        }
                    });
                }

            });
        } catch (error) {
            console.error("ERROR in Notes Title Editor: ", error)
        }

        return () => {
            notesTitleDoc.unsubscribe((error: any) => {
                if (error) return console.error(error)
            })
        }
    }, [activeNote, userId])

    return <TitleWrapper>
        <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ height: '25px' }}
        >
            <TitleEditorWrapper id='title-mini-editor' />
        </Scrollbars>
    </TitleWrapper>
}

export default Title