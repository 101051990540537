import { createActions, Store } from 'reflux'
import jwtDecode from 'jwt-decode'
import ThemeStyles from '../ThemeStyles'
import Color from 'color'
import { MainActions } from './MainStore'
import { AuthActions } from './AuthStore'
import LoopApi from '../helpers/LoopApi'

const GlobalState = require('reflux')

export var ThemingActions = createActions([
	'Resize',
	'SetScrolling',
	'SetColor',
	'SetWatchMode',
])

export const defaultStyles = {
	'.rounded': `border-radius: 15px`,
	'.rounded.inner': `border-radius: 10px`,
	'.button.danger': `background-color: #FF3C3C; color: white;`,
	'.button.danger-opaque': `background-color: #FF3C3C90; color: white;`,
	'.button': `border-radius: 999px; padding: 12px`,
	'.button.round': `
		display: flex;
		width: 50px;
		height: 50px;
		padding: initial;
	`,
	'.button.disabled': `
		opacity: 0.5;
		cursor: default;
	`,
	'.error': `color: #FF3C3C`,
	'.error-imp': `color: #FF3C3C !important;`,
	'.success': `color: #00bf9c`,
	'.select': `
		border: none;
		padding: 5px 10px;
		appearance: none;
		outline: none;
		border-radius: 5px;

		&::-ms-expand {
			display: none;
		}
	`,
	'.textarea': `
		padding: 10px;
		background: none;
		border: 1px solid transparent;
		border-radius: 5px;
		resize: none;
	`,
	'.custom-tooltip': `
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

		&::after {
			display: none;
		}
	`,
	'.top-left': `
		border-radius: 0 15px 15px 15px;
	`,
	'.top-right': `
		border-radius: 15px 0 15px 15px;
	`,
	'.bottom-left': `
		border-radius: 15px 15px 15px 0px;
	`,
	'.bottom-right': `
		border-radius: 15px 15px 0 15px;
	`,
	'.draft-toolbar': `
		background-color: transparent;
		border: none;
		border-bottom: solid 1px
	`,
	'.draft-wrapper': `
		border: solid 1px;
		border-radius: 20px;
	`,
	'.draft-editor': `
		padding: 8px;
		margin: 1px;
		border-radius: 0px 0px 15px 15px;
	`,
	'.rdw-link-modal': `
		background-color: transparent;
		border: 1px solid;
	`,
	'.rdw-link-modal-label': `
		font-size: 13px !important;
	`,
	'.rdw-link-modal-btn': `
		border-radius: 15px
	`,
	'.ReactTable.-noBorder' : `border: none;`,
	'.ReactTable.-noBorder .rt-thead': 'background: none;',
	'.ReactTable.-noBorder .rt-thead .rt-th, .ReactTable.-noBorder .rt-thead .rt-td, .ReactTable.-noBorder .rt-tbody .rt-td': 'border-right: none;',
	'.ReactTable.-noBorder .rt-tbody .rt-tr-group': 'border-bottom: none;',
	'.sub-button-y': `background: #00265C`,
	'.sub-button-m': `background: #FF9900`,
	'.sub-color-y': 'color: #00265C',
	'.sub-color-m': 'color: #FF9900',
}

export const defaultColors = {
	Default: {
		textColor: '#363B45',
		background: '#FFFFFF',
		secondary: '#4A4F58',
		container: '#363B45',
		containerText: '#FFFFFF'
	},
	Dark: {
		background: '#21212B',
		secondary: '#414052',
		textColor: '#FFFFFF',
		container: '#181820',
		containerText: '#FFFFFF'
	},
	Light: {
		secondary: '#222222',
		background: '#FFFFFF',
		textColor: '#222222',
		container: '#F6F6F6',
		containerText: '#363B45'
	}
} as any

export const colorSchemeMapper = {
	Default: {
		'.topbar': `
			background-color: white;
			color: #363B45;
		`,
		'.svg-stroke': `path { stroke: #363B45 }`,
		'.svg-fill': `path { fill: #363B45 }`,
		'.background-button': 'svg path {color: #363B45}',
		'.button': `color: #363B45;`,
		'.border.focus': `border-color: #363B45`,
		'.button.primary': `color: white; background: linear-gradient(90deg, #213465 0%, #008BFF 100%);`,
		'.button.default': `color: white; background: #363B45;`,
		'.button.secondary': `color: white; background-color: #F89809`,
		'.button.tertiary': `color: white; background-color: rgba(48, 50, 179, 0.63);`,
		'.button.overfocus': `color: white; background: #414052;`,
		'.container': `background-color: #F2F4F6; color: #363B45;`,
		'.container.focus': `background-color: #363B45;`,
		'.container.main': `background-color: #ffffff`,
		'.container.content': `background-color: #E9ECEF;`,
		'.container.focus.text': `color: #FFFFFF;`,
		'.rounded': `border-radius: 15px`,
		'.helperbutton-close': `color: #FF3C3C`,
		'.helperbutton-open': `color: #363B45`,
		'.hover-container': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #F2F4F6;
			}
		`,
		'.hover-container-inverse': `
			color: #FFFFFF;
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #21212B;
			}
		`,
		'.remove-hover-container': `
			&:hover, &:focus, &:active {
				background: none !important;
				cursor: default;
			}
		`,
		'.hover-topbar': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #FFFFFF;
			}
		`,
		'.hover-default': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #363B45;
			}
		`,
		'.breadcrumb-button': `
			&:hover {
				color: #fff;
			}
		`,
		'.folder-item': `
			background-color: rgba(0,0,0,0.1);

			&:hover {
				background-color: rgba(0,0,0,0.3);
			}
		`,
		'.link': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.link-option': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.ace-bg': `
			background-color: rgb(255,255,255);
			color: #000;
		`,
		'.input-desc': `
			background-color: rgb(255, 255, 255);
			color: #000;
		`,
		'.widget-selected': `
			background-color: #363b45;
			color: #fff;
		`,
		'.widget': `
			&:hover {
				border: 1px solid orange;
			}
		`
	},
	Dark: {
		'.task-notes-unlink': `
			filter: invert(1);
		`,
		'.task-notes-link': `
			filter: invert(1);
		`,
		'.rdw-option-wrapper': `
			border: none;
			background-color: transparent;
		`,
		'.rdw-link-modal': `
			background-color: #21212B;
			border-color: #414052!important;
			box-shadow: 1px 1px 5px #5e5e5e;
		`,
		'.content': `--primary-ui-color: #008BFF;`,
		'.topbar': `
			background-color: #272732;
			color: white;
		`,
		'.topbar-modal, .rt-noData': `background-color: #21212B;  color: #FFFFFF`,
		'.react-contexify__item': `
			font-weight: 400;
			&:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
			&:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
				background: #008BFF;
			}
		`,
		'.react-contexify': `background: #181820;`,
		'.react-contexify__item__content': `color: #fff`,
		'.toastProgress.Toastify__progress-bar--default': `background: #008BFF;`,
		'.bg-textColor': `background: #fff`,
		'.bg-gradient': `background: linear-gradient(90deg, #213465 0%, #008BFF 100%);`,
		'.bg-primary, .chat-widget-mentions-input__suggestions__item.chat-widget-mentions-input__suggestions__item--focused': `background: #008BFF`,
		'.svg-stroke': `path { stroke: white }`,
		'.svg-fill': `path { fill: white }`,
		'.svg-fill-primary': `path { fill: #008BFF; }`,
		'.color-primary': `color: #008BFF;`,
		'.color-primaryhover': `&:hover {color: #008BFF;}`,
		'.color-primaryimp, .ql-active': `color: #008BFF !important;`,
		'.color-textimp': `color: #fff !important;`,
		'.button': `color: white;`,
		'.border.focus': `border-color: #181820`,
		'.border-topbar': `border-color: #272732`,
		'.border-topbarimp': `border-color: #272732 !important`,
		'.border-light, [type="radio"]:not(:checked) + label:before': `border-color: #414052`,
		'.border-lightslack': `
			border-color: #414052;

			&:hover, &:focus {
				border-color: #363544;
			}
		`,
		'.border-lightimp': `border-color: #414052 !important`,
		'.border-lightinvereseimp': `border-color: #E9ECEF !important`,
		'.border-default': `border-color: #fff;`,
		'.border-primary, [type="radio"]:checked + label:before': `border-color: #008BFF !important;`,
		'.border-loader-primary': `border-top-color: #008BFF33 !important;`,
		'.border-table': `border-color: rgba(255,255,255,0.05);`,
		'.border-tableimp': `border-color: rgba(255,255,255,0.05) !important;`,
		'.header-border': `border-color: transparent`,
		'.button.primary': `background: #008BFF;`,
		'[type="radio"]:checked + label:after,[type="radio"]:not(:checked) + label:after': 'background: #008BFF;',
		'.button.secondary': `color: white; background-color: #F89809`,
		'.button.tertiary': `color: white; background-color: rgba(48, 50, 179, 0.63);`,
		'.button.default': `background: #414052;`,
		'.button.default-opaque': `background: #41405290;`,
		'.button.overfocus': `background: #414052;`,
		'.container, .rc-tooltip-inner': `background-color: #21212B;  color: #FFFFFF`,
		'.container.content': `background-color: #21212B`,
		'.container.contentimp, .chat-widget-mentions-input__suggestions': `background-color: #21212B !important;`,
		'.container.main': `background-color: #181820`,
		'.container.focus': `background-color: #181820`,
		'.container.focus.text': `color: #FFFFFF`,
		'.helperbutton-close': `color: #414052`,
		'.helperbutton-open': `color: #414052`,
		'.item-selected': `
			color: #FFFFFF;
			background-color: #21212B;
		`,
		'.hover-container': `
			color: #FFFFFF;
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #21212B;
			}
		`,
		'.hover-container-inverse': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #F6F6F6;
			}
		`,
		'.remove-hover-container': `
			&:hover, &:focus, &:active {
				background: none !important;
				cursor: default;
			}
		`,
		'.hover-topbar': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #272732;
			}
		`,
		'.hover-default': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #414052;
			}
		`,
		'.breadcrumb-button': `
			color: #fff;
		`,
		'.dropbox-search-input': `
			color: #fff;
		`,
		'.folder-item': `
			background-color: #33333d;

			&:hover {
				background-color: #2b2b35;
			}
		`,
		'.link': `
			background-color: transparent;
			color: rgb(255, 255, 255);
		`,
		'.link-option': `
			background-color: transparent;
			color: #000;
		`,
		'.ace-bg': `
			background-color: rgba(24, 24, 32, 0.61);
			color: #fff;
		`,
		'.input-desc': `
			background-color: rgba(24, 24, 32, 0.61);
			color: #fff;
		`,
		// '.slack-message-container': `background-color: #414052; color: #fff;`,
		'.widget-selected': `
			background-color: #fff;
			color: #000;
		`,
		'.widget': `
			&:hover {
				border: 1px solid blue;
			}
		`,
		'.edge-left': `
			background-image: linear-gradient(to right , #272732, 50%, transparent);
		`,
		'.edge-right': `
			background-image: linear-gradient(to left , #272732, 50%, transparent);
		`,
		'.box-shadow-heavy': `box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);`,
		'.react-contextmenu': 'background: #181820; color: #fff;',
		'.react-contextmenu-item': `color: #fff;`
		,
		'.wb-tool': `
			&:hover{
				background-color: #393939;
			}
		`,
		'.wb-tool-selected': `
			background-color: #141414;
			&:hover{
				background-color: #393939;
			}
		`,
		'.text-primary': `
			color: #008BFF;
		`,
		'.text-secondary': `
			color: #fff;
		`,
		'.files-back': `
			background: #008BFF;
			color: #fff;
			&:hover {
				opacity: 1
			}
		`,
		'.ReactTable': `border: 1px solid rgba(255,255,255, 0.05);`,
		'.ReactTable .rt-tbody .rt-td': 'border-right: 1px solid rgba(255,255,255,0.05)',
		'.ReactTable .rt-tbody .rt-tr-group': 'border-bottom: 1px solid rgba(255,255,255,0.05)',
		'.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover': `background: #21212B;`,
		'.ReactTable .rt-thead': `background: #414052;`,
		'.bg-primary-opaque-0-2': `background: rgba(0, 139, 255, 0.2);`,
		'.t-highlighted': `color: #000;`,
		'.notif-highlight': `background-color: #21212B;`,
		'.todo-dropmenu-svg': 'fill: #fff',
		'.slack-text': 'color: #fff !important',
		'.text-invert': 'color: #000',
		'.switch .slider': 'box-shadow: 0 0 0 2px #414052, 0 0 4px #414052;',
		'.switch input:checked + .slider:before': 'background-color: #008BFF;',
		'.switch input:checked + .slider': 'box-shadow: 0 0 0 2px #008BFF, 0 0 8px #008BFF;',
		'.ReactTable.-noBorder .rt-thead': 'color: #fff;',
        '.pointer': 'cursor: pointer',
		'.chat-other-message': `
		background: linear-gradient(90deg,#213465 0%,#008BFF 100%) !important;
			a {
				color: #FFF !important;
			}
		`,
		'.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected' : `
			color: #008BFF;
		`,
		'.ql-editor.ql-blank::before': `color: #fff;`,
		".react-datepicker-wrapper.datepicker input::placeholder": `
			color: #fff;
			opacity: 0.3;
		`		
	},
	Light: {
		'.rdw-link-modal': `
			background-color: white;
			border-color: #E9ECEF;
			box-shadow: 1px 1px 5px #bfbdbd;
		`,
		'.content': `--primary-ui-color: #FF9900;`,
		'.topbar': `
			background-color: white;
			color: #363B45;
		`,
		'.topbar-modal, .rt-noData': `
			background-color: white;
			color: #363B45;
		`,
		'.topbar-secondary': `
			background-color: #E5E5E5;
			color: #000;
		`,
		'.react-contexify__item': `
			font-weight: 400;
			&:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
			&:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
				background: #FF9900;
			}
		`,
		'.react-contexify': `background: #F2F4F6;`,
		'.toastProgress.Toastify__progress-bar--default': `background: #FF9900;`,
		'.bg-textColor': `background: #363B45`,
		'.bg-gradient': `background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);`,
		'.bg-primary-opaque': `background: rgba(255,153,0,0.5)`,
		'.bg-primary, .chat-widget-mentions-input__suggestions__item.chat-widget-mentions-input__suggestions__item--focused': `background: #FF9900`,
		'.color-primary': `color: #FF9900;`,
		'[type="radio"]:checked + label:after,[type="radio"]:not(:checked) + label:after': 'background: #FF9900;',
		'.color-primaryhover': `&:hover {color: #FF9900;}`,
		'.color-primaryimp, .ql-active': `color: #FF9900 !important;`,
		'.color-textimp': `color: #363B45 !important;`,
		'.svg-stroke': `path { stroke: #363B45 }`,
		'.svg-fill': `path { fill: #363B45 }`,
		'.svg-fill-primary': `path { fill: #FF9900; }`,
		'.button': `color: #363B45;`,
		'.border.focus': `border-color: #F2F4F6`,
		'.border-topbar': `border-color: #ffffff`,
		'.border-topbarimp': `border-color: #ffffff !important`,
		'.border-light, [type="radio"]:not(:checked) + label:before': `border-color: #E9ECEF`,
		'.border-lightslack': `
			border-color: #E9ECEF;

			&:hover, &:focus {
				border-color: #cbd3da;
			}
		`,
		'.border-lightimp': `border-color: #E9ECEF !important`,
		'.border-lightinvereseimp': `border-color: #414052 !important`,
		'.border-default': `border-color: #363B45;`,
		'.border-primary, [type="radio"]:checked + label:before': `border-color: #FF9900 !important;`,
		'.border-loader-primary': `border-top-color: #FF990033 !important;`,
		'.border-table': `border-color: rgba(0,0,0,0.02);`,
		'.border-tableimp': `border-color: rgba(0,0,0,0.02) !important;`,
		'.header-border': `border-color: #E9ECEF`,
		'.button.primary': `background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); color: white;`,
		'.button.default': `color: white; background-color: #363B45`,
		'.button.default-opaque': `color: white; background: rgba(54,59,69, 0.7);`,
		'.button.overfocus': `color: white; background: #363B45;`,
		'.button.secondary': `color: white; background-color: #F89809`,
		'.button.tertiary': `color: white; background-color: rgba(48, 50, 179, 0.63);`,
		'.button.quaternary': `color: white; background-color: #94989e;`,
		'.container, .rc-tooltip-inner': `background-color: #F2F4F6; color: #363B45;`,
		'.container.focus': `background-color: #F2F4F6`,
		'.container.content': `background-color: #F2F4F6`,
		'.container.contentimp': `background-color: #F2F4F6 !important;`,
		'.container.main': `background-color: #ffffff`,
		'.container.focus.text': `color: #363B45`,
		'.helperbutton-close': `color: #FF3C3C`,
		'.helperbutton-open': `color: #222222`,
		'.item-selected': `
			color: #363B45;
			background-color: #F2F4F6;
		`,
		'.hover-container': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #F2F4F6;
			}
		`,
		'.hover-container-inverse': `
			color: #FFFFFF;
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #21212B;
			}
		`,
		'.remove-hover-container': `
			&:hover, &:focus, &:active {
				background: none !important;
				cursor: default;
			}
		`,
		'.hover-topbar': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #363B45;
				background-color: #FFFFFF;
			}
		`,
		'.hover-default': `
			transition: background-color 0.3s ease-out;
			&:hover, &:focus, &:active {
				color: #FFFFFF;
				background-color: #363B45;
			}
		`,
		'.breadcrumb-button': `
			&:hover {
				color: #fff;
			}
		`,
		'.folder-item': `
			background-color: rgba(0,0,0,0.1);

			&:hover {
				background-color: rgba(0,0,0,0.3);
			}
		`,
		'.link': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.link-option': `
			color: rgb(20, 20, 20);
			background-color: transparent;
		`,
		'.ace-bg': `
			background-color: rgb(255,255,255);
			color: #000;
		`,
		'.input-desc': `
			background-color: rgb(255, 255, 255);
			color: #000;
		`,
		'.widget-selected': `
			background-color: #363b45;
			color: #fff;
		`,
		'.widget': `
			&:hover {
				border: 1px solid orange;
			}
		`,
		'.edge-left': `
			background-image: linear-gradient(to right , white, 50%, transparent);
		`,
		'.edge-right': `
			background-image: linear-gradient(to left , white, 50%, transparent);
		`,
		'.box-shadow-heavy': `box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);`,
		'.react-contextmenu': 'background: #fff; color: #363B45;',
		'.react-contextmenu-item': `color: #363B45;`
		,
		'.wb-tool': `
			&:hover{
				background-color: white;
			}
		`,
		'.wb-tool-selected': `
			background-color: #eae9e9;
			&:hover{
				background-color: white;
			}
		`,
		'.text-primary': `
			color: #FF8200;
		`,
		'.text-secondary': `
			color: #000;
		`,
		'.files-back': `
			background-color: #808080;
			color: #fff;
			&:hover {
				opacity: 1
			}
		`,
		'.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover': `background: #F2F4F6;`,
		'.ReactTable .rt-thead': `background: #363B45;`,
		'.editorClassName': `background-color: #fff;`,
		'.bg-primary-opaque-0-2': `background: rgba(255, 130, 0, 0.2);`,
		'.notif-highlight': `background-color: #F2F4F6;`,
		'.todo-dropmenu-svg': 'fill: #363B45',
		'.slack-text': 'color: #363B45 !important',
		'.switch input:checked + .slider:before': 'background-color: #FF8200;',
		'.switch input:checked + .slider': 'box-shadow: 0 0 0 2px #FF8200, 0 0 8px #FF8200;',
		'.ReactTable.-noBorder .rt-thead': 'color: #363B45;',
        '.pointer': 'cursor: pointer',
		'.chat-other-message': `
			background-color: #ddd !important;
			a {
				color: #0000EE !important;
			}
		`,
		'.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected' : `
			color: #FF9900;
		`,
		'.ql-editor.ql-blank::before': `color: #363B45;`,

		".react-datepicker-wrapper.datepicker input::placeholder": `
			color: #fff;
			opacity: 0.3;
		`,
		".hover-container:hover .react-datepicker-wrapper.datepicker input::placeholder, .hover-container:hover .react-datepicker-wrapper.datepicker input": `
			color: #363B45;
			opacity: 0.7;
		`
	},
}

export class ThemingStore extends Store {
	constructor() {
		super()
		this.state = {
			mobile: false,
			rowCount: 1,
			scrolling: false,
			watchMode: false,
			color: Color('#2D81FF'),
			themes: [],
			meeting_name: '',
			color_scheme: 'Light'
		}
		this.listenables = [
			ThemingActions,
			{
				DbMeeting: MainActions.DbMeeting,
				SetTheme: MainActions.SetTheme,
				SetJwt: AuthActions.SetJwt
			},
		]
	}

	onSetJwt(passedJwt: any = null) {
		let jwt = passedJwt || localStorage.getItem('token')
		if (!jwt) {
			return
		}
		const decoded = jwtDecode(jwt)
		const themes = decoded?.data?.themes || []
		const color_scheme = themes?.[window.location.pathname.replace("/", "")] || 'Light'

		this.setState({ color_scheme, themes })
	}

	onSetWatchMode(watchMode: string) {
		this.setState({ watchMode })
	}

	onDbMeeting(passedMeeting: any) {
		const themes = this.state.themes || []
		const color_scheme = themes?.[passedMeeting?.name]  || 'Light'

		this.setState({
			meeting_name: passedMeeting?.name || '',
			color_scheme: color_scheme || 'Light',
			widget_display_method: passedMeeting?.settings?.widget_display_method,
		})
	}

	onSetTheme(color_scheme: string) {
		const themes = this.state.themes || []
		const updatedThemes = {
			...themes,
			[window.location.pathname.replace("/", "")]: color_scheme
		}
		
		this.setState({
			color_scheme,
			themes: updatedThemes
		})

		LoopApi(null, 'UpdateTheme', { color_scheme })
          .then((res) => {
			  localStorage.token = res.token
			  AuthActions.SetJwt(res.token)
          })
          .catch((error) => {
            console.log({ error })
          });
	}

	onSetColor(passedColorString: string) { }

	onSetScrolling(scrolling: boolean) {
		if (scrolling === this.state.scrolling) {
			return
		}
		this.setState({
			scrolling,
		})
	}

	onResize() {
		const widgetContWidth =
			window.innerWidth - ThemeStyles.sidebarSize - ThemeStyles.scrollerWidth
		const rowCount = Math.trunc(widgetContWidth / ThemeStyles.blockSize) || 1
		const mobile =
			window.innerWidth - ThemeStyles.sidebarSize < ThemeStyles.blockSize

		if (mobile !== this.state.mobile || rowCount !== this.state.rowCount) {
			this.setState({ mobile, rowCount })
		}
	}
}

; (ThemingStore as any).id = 'theming'
