import React, { useMemo } from 'react'
import useTrack from '../../hooks/useTrack'
import AudioTrack from './AudioTrack'
import VideoTrack from './VideoTrack'

import {IVideoTrack} from '../../VideoProvider/types'
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video'
import useVideoContext from '../../hooks/useVideoContext'
import { DailyParticipant } from '@daily-co/daily-js'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { CallingProviderName } from '../../../../../../contexts/types'
import { initializeAwsTranscriber } from '../../../../../../helpers/aws-transcriber'
import { v4 } from 'uuid'

import { WidgetActions } from '../../../../../../stores/MainStore'

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication
  participant: Participant | DailyParticipant
  isLocalParticipant?: boolean
  videoOnly?: boolean
  videoPriority?: Track.Priority | null
}

export default function Publication({
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
  participant,
}: PublicationProps) {
  if(!publication) return null
  const track = useTrack(publication)
  const { callProvider, screenshareId, meeting } = useMeetingContext()

  const isDaily = callProvider === CallingProviderName.DailyCo
  const {sessionData} = useVideoContext()
  const shouldTranscribe = meeting.settings.transcribe
  
  if (!track) return null
  let isScreenshareTrack = (track?.name || (track as MediaStreamTrack)?.label ||  '')?.includes('screen')
  if(isDaily && screenshareId && track.kind === 'video' ) {
    isScreenshareTrack = track?.id === screenshareId
  }
  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={!(track?.name || (track as MediaStreamTrack)?.label ||  '')?.includes('screen') && isLocalParticipant}
          isFlipped={
            !!!isScreenshareTrack &&
            sessionData.cameraFlipStatus[(participant as Participant)?.identity || (participant as DailyParticipant)?.user_name]
          }
        />
      )
    case 'audio':
    //   initializeAwsTranscriber(WidgetActions.TranscriptionResult, v4, shouldTranscribe, track)
      return videoOnly || (isDaily && isLocalParticipant) ? null : <AudioTrack track={track as IAudioTrack} />
    default:
      return null
  }
}
