const audioUtils = require('./audioUtils') // for encoding audio data as PCM
const crypto = require('crypto') // tot sign our pre-signed URL
const v4 = require('./aws-signature-v4') // to generate our pre-signed URL
const marshaller = require('@aws-sdk/eventstream-marshaller') // for converting binary event stream messages to and from JSON
const util_utf8_node = require('@aws-sdk/util-utf8-node') // utilities for encoding and decoding UTF8
const mic = require('microphone-stream').default // collect microphone input as a stream of raw bytes
const { GlobalState } = require('reflux')

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8,
)

// export const AWSTranscriber = (props) => {
let languageCode = 'en-US'
let region = 'us-east-1'
let sampleRate = 44100
let inputSampleRate
let transcription = ''
let socket
let micStream
let socketError = false
let transcribeException = false

// a client can be shared by different commands.

function wireSocketEvents(broadcast, generateId, shouldTranscribe, track) {
  // handle inbound messages from Amazon Transcribe
  socket.onmessage = function (message) {
    //convert the binary event stream message to JSON
    let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data))
    let messageBody = JSON.parse(
      String.fromCharCode.apply(String, messageWrapper.body),
    )
    if (messageWrapper.headers[':message-type'].value === 'event') {
      handleEventStreamMessage(messageBody, broadcast, generateId, shouldTranscribe, track)
    } else {
      transcribeException = true
      console.log(messageBody.Message)
      // toggle stop
    }
  }

  socket.onerror = function () {
    socketError = true
    console.log('WebSocket connection error. Try again.')
    // toggle stop
  }

  socket.onclose = function (closeEvent) {
    micStream?.stop()

    // the close event immediately follows the error event; only handle one.
    if (!socketError && !transcribeException) {
      if (closeEvent.code != 1000) {
        console.log(
          '</i><strong>Streaming Exception</strong><br>' + closeEvent.reason,
        )
      }
      // toggle stop
    }
  }
}
let handleEventStreamMessage = function (messageJson, broadcast, generateId, shouldTranscribe, track) {
  let results = messageJson.Transcript.Results

  if (results.length > 0) {
    if (results[0].Alternatives.length > 0) {
      let transcript = results[0].Alternatives[0].Transcript

      // fix encoding for accented characters
      transcript = decodeURIComponent(escape(transcript))

      // update the textarea with the latest result
      // $('#transcript').val(transcription + transcript + "\n");

      const transcribeEvent = new CustomEvent('transcribe', { detail: { transcript, speaker: GlobalState.auth.jwt.data?.username }})
      document.dispatchEvent(transcribeEvent)
      // if this transcript segment is final, add it to the overall transcription
      if (!results[0].IsPartial) {
        //scroll the textarea down
        // $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

        transcription += transcript + '\n'

        const raw_transcription = {
            text: transcript,
            timestamp: Date.now(),
            t_id: generateId()
        }
        
        if ((typeof track?.isEnabled === 'boolean' && !track?.isEnabled) || (typeof track?.enabled === 'boolean' && !track?.enabled) || track?.muted) return
        
        if(!shouldTranscribe) return
        
        broadcast(raw_transcription)

        const transcribeEvent = new CustomEvent('transcribe', { detail: { transcript: '...', speaker: GlobalState.auth.jwt.data?.username }})
        document.dispatchEvent(transcribeEvent)

        // console.log(transcription)
        // console.log(transcript)
      }
    }
  }
}

export const closeAwsTranscriber = function () {
  console.log('aws transcriber trigger to close.')
  try {
      micStream?.stop()
      micStream = null;
      if (socket.readyState === socket.OPEN) {
        console.log('CLOSING AWS TRANSCRIBER ...')
        
        micStream.stop()
        micStream = null;
    
        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])))
        let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage)
        socket.send(emptyBuffer)
      }
      
  } catch (error) {
    console.log(error.message)
  } 
}

function convertAudioToBinaryMessage(audioChunk) {
  let raw = mic.toRaw(audioChunk)

  if (raw == null) return

  // downsample and convert the raw audio bytes to PCM
  let downsampledBuffer = audioUtils.downsampleBuffer(
    raw,
    inputSampleRate,
    sampleRate,
  )
  let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer)

  // add the right JSON headers and structure to the message
  let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer))

  //convert the JSON object + headers into a binary event stream message
  let binary = eventStreamMarshaller.marshall(audioEventMessage)

  return binary
}

function getAudioEventMessage(buffer) {
  // wrap the audio data in a JSON envelope
  return {
    headers: {
      ':message-type': {
        type: 'string',
        value: 'event',
      },
      ':event-type': {
        type: 'string',
        value: 'AudioEvent',
      },
    },
    body: buffer,
  }
}

function createPresignedUrl() {
  let endpoint = 'transcribestreaming.' + region + '.amazonaws.com:8443'

  // get a preauthenticated URL that we can use to establish our WebSocket
  return v4.createPresignedURL(
    'GET',
    endpoint,
    '/stream-transcription-websocket',
    'transcribe',
    crypto.createHash('sha256').update('', 'utf8').digest('hex'),
    {
      key: 'AKIAYYCW2ZATQSJVLVV6',
      secret: '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',
      // 'sessionToken': "",
      protocol: 'wss',
      expires: 15,
      region: region,
      query:
        'language-code=' +
        languageCode +
        '&media-encoding=pcm&sample-rate=' +
        sampleRate,
    },
  )
}

export const streamAudioToWebSocket = function (userMediaStream, broadcast, generateId, shouldTranscribe, track) {
  micStream?.stop()
  micStream = null;
  //let's get the mic input from the browser, via the microphone-stream module
  micStream = new mic()
  // setMicStream(new mic())

  micStream.on('format', function (data) {
    inputSampleRate = data.sampleRate
  })

  micStream.setStream(userMediaStream)

  // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
  // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
  let url = createPresignedUrl()
 
  //open up our WebSocket connection
  socket = new WebSocket(url)
  socket.binaryType = 'arraybuffer'

  let sampleRate = 0

  // when we get audio data from the mic, send it to the WebSocket if possible
  socket.onopen = function () {
    micStream?.on('data', function (rawAudioChunk) {
      // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
      let binary = convertAudioToBinaryMessage(rawAudioChunk)

      if (socket.readyState === socket.OPEN) socket.send(binary)
    })
  }

  // handle messages, errors, and close events
  wireSocketEvents(broadcast, generateId, shouldTranscribe, track)
}

export const initializeAwsTranscriber = (broadcast, generateId, shouldTranscribe, track) => {
    const { transcribe } = GlobalState.main.db_meeting.settings;
    if(transcribe) {
        console.log('Initializing aws-transcriber.')
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log('getUserMedia supported.')
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              audio: true,
              video: false,
            },
          )
    
          // Success callback
          .then((mediaStream) => streamAudioToWebSocket(mediaStream, broadcast, generateId, shouldTranscribe, track))
    
          // Error callback
          .catch(function (err) {
            console.log('The following getUserMedia error occurred: ' + err)
            // toggle stop
          })
      } else {
        console.log('getUserMedia not supported on your browser!')
      }
  }
}
// }
