import React from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { IWidgetConfig } from './types';

import { IFileIdToParentUuidMap, IFoldersMap, IUserLastMessageMap, IFilesMap } from './Files/types'
import { IFolder, IFolderless, ISpace, ITask, ITeam } from './Clickup/types';
import { Todos } from './TodoList/types';

const Loading = () => <LoadingContainer>Loading...</LoadingContainer>;
const LoadingContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export enum AllWidgets {
      Asana = 'Asana',
      Chat = 'Chat',
      Clickup = 'Clickup',
      // CryptoTicker = 'CryptoTicker',
      // CustomWidget = 'CustomWidget',
      Dropbox = 'Dropbox',
      Files = 'Files',
      Gists = 'Gists',
      Github = 'Github',
      GoogleDrive = 'GoogleDrive',
      Images = 'Images',
      Links = 'Links',
      Notes = 'Notes',
      Note = 'Note',
      SharedbNotes = 'SharedbNotes',
      PdfPresenter = 'PdfPresenter',
      Scheduler = 'Scheduler',
      Hubspot = 'Hubspot',
      // Screenshare = 'Screenshare',
      // SketchPad = 'SketchPad',
      Slack = 'Slack',
      // SoundCloud = 'SoundCloud',
      // Standup = 'Standup',
      TicTacToe = 'TicTacToe',
      Todo = 'Todo',
      Transcription = 'Transcription',
      Twitch = 'Twitch',
      Youtube = 'Youtube',
      WhiteBoard = 'WhiteBoard',
      // CryptoGraph = 'CryptoGraph',
}

function _asWidgetT<T extends { data: {} }>(
      obj: { [name in AllWidgets]: IWidgetConfig<T> }
) {
      return obj;
}

export const _widgetConfigs = {
      Asana: {
            key: 'asana',
            loader: loadable(() => import('./Asana')),
            displayName: 'Asana',
            icon: require('./Asana/icon.svg'),
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        project: null as null | string | undefined,
                        sections: [],
                        project_users: [],
                        tasks: [],
                        task: null as null | object | undefined,
                        attachment: {},
                  },
            },
      },
      Clickup: {
            key: 'clickup',
            loader: loadable(() => import('./Clickup')),
            displayName: 'Clickup',
            icon: require('./Clickup/icon.svg'),
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  users: true,
            },
            defaultProps: {
                  data: {
                        currentScreen: '',
                        teamList: [] as any[],
                        spaceList: [] as any[],
                        folderList: [] as any[],
                        folderless: [] as any[],
                        taskList: [] as any[],
                        memberList: [] as any[],
                        team: null as ITeam | null | undefined,
                        space: null as ISpace | null | undefined,
                        folder: null as IFolder | null | undefined,
                        list: null as IFolderless | null | undefined,
                        task: null as ITask | null | undefined,
                        hostId: null as null | string,
                        askingToBeTheHost: null as null | string,
                        loading: false as boolean,
                        dataLoading: false as boolean
                  },
            },
      },
      Chat: {
            key: 'chat',
            loader: loadable(() => import('./Chat')),
            botModeUnavailable: true,
            displayName: 'Chat',
            dataUsed: {
                  users: true,
                  transcription: false,
                  user: true,
            },
            icon: require('./Chat/icon.svg'),
            defaultProps: {
                  data: {
                        chats: {} as object,
                        userId: null as string | null,
                        typing: {} as any,
                        lastSeenMessageMap: {} as IUserLastMessageMap,
                        userList: [] as string[],

                        unreadMessages: 0 as number
                  },
            },
      },
      /******** CRYPTO TICKER & CUSTOM WIDGET HIDDEN: Will be removed if it is not really needed anymore  ********/
      // CryptoTicker: {
      // 	key: 'cryptoticker',
      // 	loader: Loadable({
      // 		loader: () => import(`./CryptoTicker` as any),
      // 		loading: Loading,
      // 	}),
      // 	dataUsed: {
      // 		transcription: false
      // 	},
      // 	displayName: 'Crypto Ticker',
      // 	icon: require('./CryptoTicker/icon.svg'),
      // 	defaultProps: {
      // 		data: {
      // 			tickers: [],
      // 			quantities: {},
      // 			useBtc: true,
      // 			changePeriod: '24h',
      // 		},
      // 	},
      // },
      // CustomWidget: {
      // 	key: 'custom',
      // 	loader: Loadable({
      // 		loader: () => import(`./CustomWidget` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'Custom Widget',
      // 	icon: require('./CustomWidget/icon.svg'),
      // 	allowsDuplicates: true,
      // 	dataUsed: {
      // 		transcription: false
      // 	},
      // 	defaultProps: {
      // 		data: {
      // 			_url: '' as string
      // 		}
      // 	},
      // 	requiredSetup: [
      // 		{
      // 			key: '_displayName',
      // 			userFriendlyKey: 'Name',
      // 		},
      // 		{
      // 			key: '_url',
      // 			userFriendlyKey: 'Widget URL',
      // 		},
      // 	],
      // },
      Dropbox: {
            key: 'dropbox',
            loader: loadable(() => import('./Dropbox')),
            dataUsed: {
                  transcription: false,
                  users: true
            },
            displayName: 'Dropbox',
            icon: require('./Dropbox/icon.svg'),
            oauthType: 'private',
            defaultProps: {
                  data: {
                        link: null,
                        filename: null,
                        file: null as any,
                        userId: null as null | string,
                        src: "" as string,
                  },
            },
      },
      Files: {
            key: 'files',
            loader: loadable(() => import('./Files')),
            displayName: 'Files',
            icon: require('./Files/icon.svg'),
            dataUsed: {
                  files: true,
                  transcription: false,
                  users: true,
                  userId: true
            },
            defaultProps: {
                  data: {
                        currentFileId: null as null | string,
                        currentFolderId: null as null | string,
                        folders: {} as IFoldersMap,
                        childFiles: {} as IFileIdToParentUuidMap,
                        externals: null as null | {},
                        files: {} as IFilesMap,
                        file: null as null | string,
                        userId: null as null | string,
                  },
            },
      },
      Gists: {
            key: 'gists',
            loader: loadable(() => import('./Gists')),
            displayName: 'Github Gists',
            externalTokenKey: 'github',
            icon: require('./Gists/icon.svg'),
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        currentGist: null as any,
                        newGistName: '',
                        gistText: ``,
                        syntax: 'javascript',
                        theme: 'monokai',
                  },
            },
      },
      Github: {
            key: 'github',
            loader: loadable(() => import('./Github')),
            displayName: 'Github',
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  otherWidgets: ['gists'], // Data of other widgets that should be passed. Don't list THIS widget here, that data is passed either way
                  otherWidgetData: {},
            },
            icon: require('./Github/icon.svg'),
            defaultProps: {
                  data: {
                        syntax: 'javascript',
                        currentRepo: null as null | object,
                        currentPath: ``,
                        currentFile: null as null | object,
                        files: [],
                        content: '',
                  },
            },
      },
      GoogleDrive: {
            key: 'googledrive',
            loader: loadable(() => import('./GoogleDrive')),
            displayName: 'Google Drive',
            icon: require('./GoogleDrive/icon.svg'),
            oauthType: 'private',
            oauthKey: 'google',
            dataUsed: {
                  transcription: false,
                  users: true
            },
            defaultProps: {
                  data: {
                        file: null as any,
                        public_permission: null as null | string,
                        userId: null as null | string,
                        isAlreadyPublic: false
                  },
            },
      },
      Images: {
            key: 'images',
            loader: loadable(() => import('./Images')),
            displayName: 'Images',
            icon: require('./Images/icon.svg'),
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        images: {} as any | null,
                        current_image: null as any,
                  },
            },
      },
      Links: {
            key: 'links',
            loader: loadable(() => import('./Links')),
            displayName: 'Links',
            dataUsed: {
                  transcription: false,
            },
            icon: require('./Links/icon.svg'),
            defaultProps: {
                  data: {
                        links: {} as any | null,
                  },
            },
      },
      Notes: {
            key: 'notes',
            loader: loadable(() => import('./Notesv2')),
            displayName: 'Notes',
            dataUsed: {
                  transcription: false,
                  userId: true,
                  users: true
            },
            icon: require('./Notesv2/icon.svg'),
            defaultProps: {
                  data: {
                        notes: [] as any[],
                  },
            },
      },
      Note: {
            key: 'note',
            loader: loadable(() => import('./Notesv2')),
            displayName: 'Notes v2',
            dataUsed: {
                  transcription: false,
                  userId: true,
                  users: true
            },
            icon: require('./Notesv2/icon.svg'),
            defaultProps: {
                  data: {
                        noteslist: {} as any,
                  },
            },
      },
      SharedbNotes: {
            key: 'sharedbnotes',
            loader: loadable(() => import('./SharedbNotes')),
            displayName: 'Notes 2.0',
            dataUsed: {
                  transcription: false,
                  userId: true,
                  users: true
            },
            icon: require('./SharedbNotes/icon.svg'),
            defaultProps: {
                  data: {}
            },
      },
      PdfPresenter: {
            key: 'pdfpresenter',
            loader: loadable(() => import('./PdfPresenter')),
            displayName: 'PDF Presenter',
            icon: require('./PdfPresenter/icon.svg'),
            defaultProps: {
                  data: {
                        page: 1 as number | null,
                        url: null as null | any,
                        scrollPerc: 0,
                        scale: 1.0
                  },
            },
            dataUsed: {
                  transcription: false,
            },
      },
      Scheduler: {
            key: 'scheduler',
            loader: loadable(() => import('./Scheduler')),
            hidden: true,
            defaultProps: { data: {} },
            displayName: 'Scheduler',
            icon: require('./Scheduler/icon.svg'),
            dataUsed: {
                  user: true,
                  users: true,
                  meetingConfig: true,
                  transcription: false,
            },
      },
      // Screenshare: {
      //       key: 'screenshare',
      //       loader: Loadable({
      //             loader: () => import(`./Screenshare` as any),
      //             loading: Loading,
      //       } as any),
      //       // botModeUnavailable: true,
      //       dataUsed: {
      //             transcription: false,
      //       },
      //       displayName: 'Screenshare',
      //       icon: require('./Screenshare/icon.svg'),
      //       defaultProps: {
      //             data: {},
      //       },
      // },
      // SketchPad: {
      // 	key: 'sketchpad',
      // 	loader: Loadable({
      // 		loader: () => import(`./SketchPad` as any),
      // 		loading: Loading,
      // 	}),
      // 	defaultProps: {data: {}},
      // 	displayName: 'White Board',
      // 	icon: require('./SketchPad/icon.svg'),
      // 	dataUsed: {
      // 		user: true,
      // 		transcription: false,
      // 		users: true,
      // 	},
      // },
      Slack: {
            key: 'slack',
            loader: loadable(() => import('./Slack')),
            displayName: 'Slack',
            token_key: 'slack',
            icon: require('./Slack/icon.svg'),
            defaultProps: {
                  data: {
                        dms: [],
                        messages: [],
                        channelList: [],
                        membersList: [],
                        selected: null as any,
                        hostId: null as null | string,
                        hostRequest: null as null | any
                  },
            },
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  users: true,
                  user: true,
            },
      },
      /******** SOUNDCLOUD HIDDEN: Will be removed if it is not really needed anymore  ********/
      // SoundCloud: {
      // 	key: 'soundcloud',
      // 	loader: Loadable({
      // 		loader: () => import(`./SoundCloud` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'SoundCloud',
      // 	icon: require('./SoundCloud/icon.svg'),
      // 	dataUsed: {
      // 		transcription: false
      // 	},
      // 	defaultProps: {
      // 		data: {
      // 			startedPlaying: null,
      // 			track: null,
      // 		},
      // 	},
      // },
      // Standup: {
      //       key: 'standup',
      //       loader: Loadable({
      //             loader: () => import(`./Standup` as any),
      //             loading: Loading,
      //       }),
      //       displayName: 'Standup',
      //       token_key: 'standup',
      //       icon: require('./Standup/icon.svg'),
      //       dataUsed: {
      //             user: true,
      //             users: true,
      //             transcription: false,
      //       },
      //       defaultProps: {
      //             data: {
      //                   standupLeaderMemberId: null as null | string | undefined,
      //                   previousStandups: [] as any[],
      //                   currentScreen: 'home' as string,
      //                   currentStandupAttendees: [] as any[],
      //                   postedToSlack: false as boolean,
      //             },
      //       },
      // },
      TicTacToe: {
            key: 'tictactoe',
            loader: loadable(() => import('./TicTacToe')),
            displayName: 'Tic Tac Toe',
            icon: require('./TicTacToe/icon.svg'),
            defaultProps: {
                  data: {
                        playerX: null as null | string | undefined,
                        currentTurn: null as null | string | undefined,
                        board: null as null | any[] | undefined,
                  },
            },
            dataUsed: {
                  transcription: false,
            },
      },

      Todo: {
            key: 'todo',
            loader: loadable(() => import('./TodoList')),
            dataUsed: {
                  files: true,
                  transcription: false,
                  users: true,
                  userId: true,
            },

            defaultProps: {
                  data: {
                        todo: null as null | Todos,
                  },
            },

            displayName: 'Tasks',
            icon: require('./TodoList/icon.svg'),
      },

      Transcription: {
            key: 'transcription',
            loader: loadable(() => import('./Transcription')),
            botModeUnavailable: true,
            displayName: 'Transcription',
            defaultProps: {
                  data: {
                        transcriptions: [] as any,
                        saved: [] as any,
                        searched: null as any,
                        view: null as null | [] as any,
                        stashed_view: null as null | [] as any,
                        getTranscriptions: null as any,
                        isHighlightsShown: false
                  }
            },
            dataUsed: {
                  users: [],
                  user: {},
                  transcription: true,
            },
            icon: require('./Transcription/icon.svg'),
      },
      Twitch: {
            key: 'twitch',
            loader: loadable(() => import('./Twitch')),
            dataUsed: {
                  transcription: false,
            },
            defaultProps: {
                  data: {
                        channel: '' as string,
                        playing: false as boolean,
                        skippedTime: 0,
                        getCurrentTime: false,
                        changedChannel: false
                  },
            },
            displayName: 'Twitch',
            icon: require('./Twitch/icon.svg'),
      },
      Youtube: {
            key: 'youtube',
            loader: loadable(() => import('./Youtube')),
            dataUsed: {
                  transcription: false,
            },
            displayName: 'YouTube',
            icon: require('./Youtube/icon.svg'),
            defaultProps: {
                  data: {
                        videoId: null as null | string | undefined,
                        groupPlay: false,
                        startedPlaying: Date.now() / 1000,
                        startTimestamp: 0,
                  },
            },
      },
      /******** CRYPTOGRAPH HIDDEN: Will be removed if it is not really needed anymore  ********/
      // CryptoGraph: {
      // 	key: 'cryptograph',
      // 	loader: Loadable({
      // 		loader: () => import(`./CryptoGraph` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'CryptoGraph',
      // 	icon: require('./CryptoGraph/icon.svg'),
      // 	defaultProps: { data: {} },
      // 	dataUsed: {
      // 		user: true,
      // 		users: true,
      // 		meetingConfig: true,
      // 		transcription: false
      // 	},
      // },

      WhiteBoard: {
            key: 'whiteboard',
            loader: loadable(() => import('./WhiteBoard')),

            dataUsed: {
                  users: [],
                  user: {},
                  files: true,
                  transcription: false,
            },

            defaultProps: {
                  data: {
                        logs: [] as object[] | any,
                        activeUsers: [] as object | any,
                        // frameSelected: 0 as number | any,
                        fileName: '' as string,
                        selectedBoard: 0 as number,
                        whiteboards: [] as object[] | any
                  },
            },
            displayName: 'Whiteboard',
            icon: require('./WhiteBoard/icon.svg'),
      },
      /******** CRYPTOGRAPH HIDDEN: Will be removed if it is not really needed anymore  ********/
      // CryptoGraph: {
      // 	key: 'cryptograph',
      // 	loader: Loadable({
      // 		loader: () => import(`./CryptoGraph` as any),
      // 		loading: Loading,
      // 	}),
      // 	displayName: 'CryptoGraph',
      // 	icon: require('./CryptoGraph/icon.svg'),
      // 	defaultProps: { data: {} },
      // 	dataUsed: {
      // 		user: true,
      // 		users: true,
      // 		meetingConfig: true,
      // 		transcription: false
      // 	},
      // },
      Hubspot: {
            key: 'hubspot',
            loader: loadable(() => import('./Hubspot')),
            displayName: 'Hubspot',
            token_key: 'hubspot',
            oauthType: 'private',
            dataUsed: {
                  transcription: false,
                  otherWidgets: [], // Data of other widgets that should be passed. Don't list THIS widget here, that data is passed either way
                  otherWidgetData: {},
                  color_scheme: true
            },
            icon: require('./Hubspot/icon.svg'),
            defaultProps: {
                  data: {
                        screen: 'contacts', // default screen
                        tasks: [],
                        task: {},
                        companies: [],
                        company: {},
                        company_id: '',
                        contacts: [],
                        contact: {},
                        contact_id: '',
                        deals: [],
                        deal: {},
                        deal_id: '',
                        reports: [],
                        analytics: {},
                        account: {},
                        stages: {}
                  },
            },
      },
} as any;

// THIS WILL GO RED if things are wrong
_asWidgetT(_widgetConfigs);

const _createLoadable = (name: keyof typeof _widgetConfigs) => ({
      render: _widgetConfigs[name].loader,
      widgetConfig: _widgetConfigs[name],
});

const Widgets = Object.keys(_widgetConfigs).reduce(
      (obj: any, item: AllWidgets) => {
            obj[_widgetConfigs[item].key] = _createLoadable(item);
            return obj;
      },
      {}
);

export default { ...Widgets };
