import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash'
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal'

import Api, { Endpoints, Actions } from '../_Shared/Api'
import Loader from '../_Shared/Loader'
import WidgetHeader from '../_Shared/WidgetHeader'
import { WidgetContainer, WidgetContentItem } from '../_Shared/Elements'
import Table from '../_Shared/Table'
import Dropdown from '../_Shared/DropdownWrapper' 

interface Props {
    external_token: string | null | undefined
    UpdateSelf(obj: object): void
    authFailed(): void
    project: null | string | undefined
    tasks: object[]
    sections: object[]
}

interface ITask {
    gid?: string | number
    title?: string
    description?: string
    name?: string
}

interface ITasks {
    gid?: string | number | undefined
    name?: string
}

interface State {
    loading: boolean
}

export default class TaskList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = { loading: false }

        this.ListTasks = this.ListTasks.bind(this)
        this.ListSections = this.ListSections.bind(this)
        this.startNewTask = this.startNewTask.bind(this)
        this.selectTask = this.selectTask.bind(this)
        this.deleteTask = this.deleteTask.bind(this)
        this.backOutOfWorkspace = this.backOutOfWorkspace.bind(this)
    }

    componentDidMount() {
        this.ListTasks()
        this.ListSections()
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.external_token !== this.props.external_token ||
            prevProps.project !== this.props.project ||
            prevProps.tasks.length !== this.props.tasks.length ||
            this.props.tasks.find((t, ii) => prevProps.tasks[ii] !== t)
        ) {
            this.ListTasks()
        }
    }

    async ListTasks() {
        if (!this.props.external_token) {
            return
        }
        this.setState({ loading: true })
        try {
            const resp = await Api(Endpoints['Asana'], Actions['ListTasks'], this.props.external_token, {
                urlAppend: `?project=${this.props.project}&opt_fields=name,due_on,completed,memberships.section.name,assignee.name,assignee.photo`,
            })
            if (!resp.data) {
                this.setState({ loading: false })
                return this.props.authFailed()
            }
            this.props.UpdateSelf({
                tasks: resp.data.map((d: any) => ({
                    ...d,
                    status: d.memberships && d.memberships.length && d.memberships[0]?.section?.name || '',
                    status_details: d.memberships && d.memberships.length && d.memberships[0]?.section || {}
                }))
            })
            this.setState({ loading: false })

        } catch (e) {
            this.setState({ loading: false })
            return this.props.authFailed()
        }
    }

    async ListSections() {
        if (!this.props.external_token || !this.props.project) {
            return
        }
        this.setState({ loading: true })
        try {
            const resp = await Api(Endpoints['Asana'], Actions['ListSections'], this.props.external_token, {}, [["project_gid", this.props.project]])
            if (!resp.data) {
                this.setState({ loading: false })
                return this.props.authFailed()
            }
            this.props.UpdateSelf({
                sections: resp.data
            })
            this.setState({ loading: false })

        } catch (e) {
            this.setState({ loading: false })
            return this.props.authFailed()
        }
    }

    startNewTask() {
        this.props.UpdateSelf({ task: { title: '', description: '', gid: null } })
    }

    selectTask(task: object) {
        this.props.UpdateSelf({ task })
    }

    async deleteTask(gid: string | number | undefined, e: any) {
        // debugger
        e.stopPropagation()

        if (!this.props.external_token) {
            return
        }

        this.setState({ loading: true })
        const resp = await Api(Endpoints['Asana'], Actions['DeleteTask'], this.props.external_token, {
            urlAppend: `/${gid}`,
        })

        this.setState({ loading: false })
        if (resp.errors) return //console.log('error')
        let newTasks = this.props.tasks.filter((t: any) => t.gid !== gid)

        this.props.UpdateSelf({ tasks: newTasks })
        this.forceUpdate() // forceUpdate, i don't know why but if I delete multiple tasks consecutively, it does not update 
    }

    backOutOfWorkspace() {
        this.props.UpdateSelf({
            tasks: [],
            task: null,
            project: null,
            workspace: null,
            workspace_name: null,
        })
    }

    render() {
        return (
            <WidgetContainer padding="30px">
                <WidgetHeader
                    name={
                        <Header>
                            <Ico>
                                <img src={require("./icon.svg")} />
                            </Ico>
                            Task list
                        </Header>
                    }
                    subname="You can create, edit, and remove the task here"
                    end={
                        <React.Fragment>
                            <Button className="button" onClick={() => this.backOutOfWorkspace()}>Back</Button>
                            <Button className="button default" onClick={() => this.startNewTask()}>Create</Button>
                        </React.Fragment>
                    }
                />
                <Table
                    headers={[
                        { label: 'Task name', value: 'name', width: '60%' },
                        { label: 'Status', value: 'status', width: '10%' },
                        {
                            label: 'Assignee', value: '', row: (row: any) => {
                                return row?.assignee?.name || row?.assignee?.photo?.image_60x60 ? <ImageWrapper title={row?.assignee?.name || ''}><img src={row?.assignee?.photo?.image_60x60 || `https://ui-avatars.com/api/?name=${row?.assignee?.name}&format=svg`} alt={row?.assignee?.name} /></ImageWrapper> : ''
                            }, width: '15%'
                        },
                        {
                            label: 'Due date', value: 'due_on', width: '10%', row: (row: any) => {
                                const finalOutput = row?.due_on ? moment(row?.due_on).calendar({
                                    sameDay: '[Today]',
                                    nextDay: '[Tomorrow]',
                                    nextWeek: 'dddd',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'MMM DD',
                                    sameElse: 'MMM DD'
                                }) : ''

                                if (finalOutput === 'Today' || finalOutput === 'Tomorrow') return <span className="success">{finalOutput}</span>
                                if ((finalOutput === 'Yesterday' && !!!row?.completed) || (row?.due_on && moment().isAfter(row?.due_on) && !!!row?.completed)) return <span className="error">{finalOutput}</span>
                                return finalOutput
                            },
                        },
                        {
                            label: '', value: '', row: (row: any) => {
                                const DropdownContent = (props: any) => {
                                    return (
                                        <React.Fragment>
                                            <WidgetContentItem padding={10} onClick={(e: any) => {
                                                this.deleteTask(row.gid, e)
                                                props.onClose()
                                            }}><Trash width={16} className="error" />Remove task</WidgetContentItem>
                                        </React.Fragment>
                                    )
                                }
                                return <Dropdown items={DropdownContent}><IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} /></Dropdown>
                            }, width: '5%'
                        },
                    ]}
                    data={this.props.tasks}
                    onClick={(row: any) => this.selectTask(row)}
                />
            </WidgetContainer>
        )
    }
}

const ImageWrapper = styled.div`
    width: 25px;
    height: 25px;
    object-fit: cover;

    img {
        width: 100%;
        border-radius: 50%;
    }
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 35px;
        border: none;
        align-self: center;
        margin: 0 14px;
    }
`