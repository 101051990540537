export const SELECTED_VIDEO_INPUT_KEY = 'video_input_device_id'
export const SELECTED_AUDIO_INPUT_KEY = 'audio_input_device_id'
export const SELECTED_AUDIO_OUTPUT_KEY = 'audio_output_device_id'
export const PREVIOUS_VIDEO_INPUT_KEY = 'previous_video_input_device_id'
export const PREVIOUS_AUDIO_INPUT_KEY = 'previous_audio_input_device_id'
export const PREVIOUS_AUDIO_OUTPUT_KEY = 'previous_audio_output_device_id'

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 640,
  height: 480,
  frameRate: 24,
}

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY =
  'TwilioVideoApp-selectedBackgroundSettings'
