import React, {useState} from 'react'
import Scrollbars from 'react-custom-scrollbars'
import AudioInputList from '../../../../../Widgets/KeynoteWidgets/Main/components/AudioInputList'
import AudioOutputList from '../../../../../Widgets/KeynoteWidgets/Main/components/AudioOutputList'
import VideoInputList from '../../../../../Widgets/KeynoteWidgets/Main/components/VideoInputList'
import BackgroundThumbnail from '../BackgroundThumbnail'
import {FiSlash as NoneIcon} from '@react-icons/all-files/fi/FiSlash'
import BlurIcon from '../../../../../../../assets/icons/blurbg.svg'

// Styled Components
import {
  SelectDevicesToggleWrapper,
  SelectDevicesWrapper,
} from './styledComponents'
import {backgroundConfig} from '../../../VideoProvider/useBackgroundSettings'
import DeviceList from './DeviceList'

const SelectDevices = () => {
  const [visible, setVisible] = useState(true)

  const imageNames = backgroundConfig.imageNames
  const images = backgroundConfig.images

  return (
    <React.Fragment>
      <SelectDevicesToggleWrapper>
        <span
          className="color-primary"
          onClick={() => {
            // setVisible(!visible)
          }}
        >
          Audio / Video Settings
        </span>
      </SelectDevicesToggleWrapper>
      <SelectDevicesWrapper>
        {visible && (
          <div>
            {/* @ts-ignore */}
            <DeviceList key={`${JSON.stringify(window?.deviceInfo)}-${Math.random()}`} />
            {/* <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              style={{height: 'calc(80vh - 320px)'}}
            >
              <div>
                <div className="default-options">
                  <BackgroundThumbnail
                    icon={<NoneIcon />}
                    thumbnail={'none'}
                    name={'None'}
                    label={'None'}
                  />
                  <BackgroundThumbnail
                    icon={<img src={BlurIcon} />}
                    thumbnail={'blur'}
                    name={'Blur'}
                    label={'Blur'}
                  />
                </div>

                <div className="thumb-list">
                  {images.map((image, index) => (
                    <BackgroundThumbnail
                      thumbnail={'image'}
                      name={imageNames[index]}
                      index={index}
                      imagePath={image}
                      key={image}
                    />
                  ))}
                </div>
              </div>
            </Scrollbars> */}
          </div>
        )}
      </SelectDevicesWrapper>
    </React.Fragment>
  )
}

export default SelectDevices
