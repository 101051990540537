import { useContext } from 'react';
import { ClickupContext } from './ClickupContext';

export default function useClickupContext() {
  const context = useContext(ClickupContext);
  if (!context) {
    throw new Error('useClickupContext must be used within a ClickupContextProvider');
  }
  return context;
}
