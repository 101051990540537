import React, {useState} from 'react'
import {LocalVideoTrack} from 'twilio-video'
import useDevices from '../../hooks/useDevices'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack'
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../../../../calling/_constants'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import useLocalStorage from '../../hooks/useLocalStorage'
import { CallingProviderName } from '../../../../../../contexts/types'
import { DailyCall } from '@daily-co/daily-js'
import Dropdown from '../../../../../../components/Dropdown'
import { FiCamera as CameraIcon } from "@react-icons/all-files/fi/FiCamera";

export default function VideoInputList({ onboarding }: { onboarding?: boolean }) {
  const {videoInputDevices} = useDevices()
  const {localTracks, callProvider, room} = useMeetingContext()
  const [localVideoDeviceId, setLocalVideoDeviceId] = useLocalStorage(
    SELECTED_VIDEO_INPUT_KEY,
    'default',
  )

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  const isDaily = callProvider === CallingProviderName.DailyCo

  const localVideoTrack = localTracks.find(
    (track) => track.kind === 'video',
  ) as LocalVideoTrack | undefined
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack, !!onboarding)
  //@ts-ignore
  window.testtrack = mediaStreamTrack
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] =
    useState(localVideoDeviceId)
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings()?.deviceId || storedLocalVideoDeviceId
      

  async function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId)
    setLocalVideoDeviceId(newDeviceId)

    if(isTwilio || onboarding ) {
      localVideoTrack?.restart({
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        deviceId: {exact: newDeviceId},
      })
    } else if(isDaily) {
      await (room as DailyCall).setInputDevicesAsync({
        videoDeviceId: newDeviceId
      })
    }
  }

  const video_options: any = videoInputDevices.map(device => ({
    name: device.label,
    value: device.deviceId
  }))

  // TODO: Add Local Video Preview on top of Video Device List
  return (
    <div>
      {videoInputDevices.length >= 1 ? (
        <div>
          <div className="label">Video</div>
          {/* <select
            className="select-custom border-lightimp topbar hover-container"
            onChange={(e) => replaceTrack(e.target.value as string)}
            value={localVideoInputDeviceId || ''}
          >
            {videoInputDevices.map((device) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select> */}

          <div className="select-device-wrapper">
            <Dropdown
              name="video-devices"
              items={video_options}
              disabled={videoInputDevices.length === 0}
              value={storedLocalVideoDeviceId || 'default'}
              // inputWidth={100%}
              onChange={(item, name) => {
                replaceTrack(item.value as string)
              }}
              dropdownIcon={<CameraIcon />}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="label">Video</div>
          <div className="select-device-wrapper">
            <Dropdown
              name="video-devices"
              items={[
                {
                  name: 'No Local Video',
                  value: '',
                  disabled: false
                }
              ]}
              disabled={true}
              value={''}
              // inputWidth={100%}
              onChange={(item, name) => {
                replaceTrack(item.value as string)
              }}
              dropdownIcon={<CameraIcon />}
            />
          </div>
          {/* <p style={{ marginBottom: '10px', textAlign: 'center' }}>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</p> */}
        </div>
      )}
    </div>
  )
}
