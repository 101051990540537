import { DailyCall } from '@daily-co/daily-js'
import {useState, useCallback, useRef} from 'react'
import {LogLevels, Track, Room} from 'twilio-video'
import SuccessErrorToast from '../../../../../components/Notifications/SuccessErrorToast'
import { CallingProviderName } from '../../../../../contexts/types'
import useToastify from '../../../../../helpers/useToastify'
import { ModalActions } from '../../../../../stores/ModalStore'
import {ErrorCallback} from './types'

const { GlobalState } = require('reflux')

interface MediaStreamTrackPublishOptions {
  name?: string
  priority: Track.Priority
  logLevel: LogLevels
}

export default function useScreenShareToggle(
  room: Room | DailyCall | null,
  onError: ErrorCallback,
  callProvider: CallingProviderName
) {
  const [isSharing, setIsSharing] = useState(false)
  const stopScreenShareRef = useRef<() => void>(null!)

  const shareScreen = useCallback(() => {
    const isTwilio = callProvider === CallingProviderName.Twilio ||
      callProvider === CallingProviderName.TwilioPro || 
      callProvider === CallingProviderName.TwilioStandard
    if(!room) return 
    if(isTwilio) {
      navigator.mediaDevices
        .getDisplayMedia({
          audio: false,
          video: {
            frameRate: 10,
            height: 1080,
            width: 1920,
          },
        })
        .then((stream) => {
  
          // All video tracks are published with 'low' priority. This works because the video
          // track that is displayed in the 'MainParticipant' component will have it's priority
          // set to 'high' via track.setPriority()
          const track = stream.getTracks()[0];
          //@ts-ignore
          room!.localParticipant
            .publishTrack(track, {
              name: 'screen', // Tracks can be named to easily find them later
              priority: 'high', // Priority is set to high by the subscriber when the video track is rendered
            } as MediaStreamTrackPublishOptions)
            .then((trackPublication: any) => {
              stopScreenShareRef.current = () => {
                //@ts-ignore
                room!.localParticipant.unpublishTrack(track)
                // TODO: remove this if the SDK is updated to emit this event
                //@ts-ignore
                room!.localParticipant.emit('trackUnpublished', trackPublication)
                track.stop()
                setIsSharing(false)
              }
              // @ts-ignore
              window.stopScreenShareRef = stopScreenShareRef.current
  
              track.onended = stopScreenShareRef.current
              setIsSharing(true)
            })
            .catch(onError)


        })
        .catch((error) => {
          // Don't display an error if the user closes the screen share dialog
          if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
            onError(error)
          }

          if(error?.message === 'Permission denied by system' && error?.name === 'NotAllowedError') {
            ModalActions.SetModal('UnableToShareScreen')
          }
        })
    } else if(callProvider === CallingProviderName.DailyCo) {
      (room as DailyCall).startScreenShare()
      setIsSharing(true)
      stopScreenShareRef.current = () => {
        (room as DailyCall).stopScreenShare()
        setIsSharing(false)
      }
      // @ts-ignore
      window.stopScreenShareRef = stopScreenShareRef.current
    }
  }, [room, onError, callProvider])

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current()
    }
  }, [isSharing, shareScreen, room])

  return [isSharing, toggleScreenShare] as const
}
