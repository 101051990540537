export const getDevices = (deviceKind?: string) => {
  return new Promise<any[]>((resolve, reject) => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          if (deviceKind) {
            const filteredDevices = devices.filter((device) => device.kind === deviceKind);
            resolve(filteredDevices);
          } else {
            resolve(devices);
          }
        })
        .catch((err) => {
          //console.log("getDevices err:", err);
          reject({ error: "The media devices could not be checked" });
        });
    } else {
      //console.log("getDevices err");
      reject({ error: "The media devices could not be checked" });
    }
  });
};
