import {useCallback, useEffect, useState} from 'react'
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
} from 'twilio-video'

import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from '../calling/_constants'
import {isPermissionDenied, getDeviceInfo} from '../calling/_helpers'
import useLocalStorage from '../screens/Meeting2.0/Widgets/KeynoteWidgets/hooks/useLocalStorage'

var userAgent = navigator?.userAgent?.toLowerCase()

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>()
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false)
  const [localAudioInputDeviceId, setAudioInput] = useLocalStorage(
    SELECTED_AUDIO_INPUT_KEY,
    'default',
  )
  const [localVideoInputDeviceId, setVideoInput] = useLocalStorage(
    SELECTED_VIDEO_INPUT_KEY,
    'default',
  )

  useEffect(() => {
    const handleDeviceChange = async () => {
      if (audioTrack) {
        if (!audioTrack.isStopped) audioTrack.stop()
      }
  
      const replaceTrack = (newDeviceId: string) => {
        setAudioInput(newDeviceId)
        audioTrack?.restart({deviceId: {exact: newDeviceId}})
      }

      navigator.mediaDevices.enumerateDevices().then(devices => {
        const audioInputs = devices.filter(device => device.kind === 'audioinput');
        // return Video.createLocalAudioTrack({ deviceId: {exact: audioInputs?.[1]?.deviceId || 'default'} });
        // if (newAudioTrack) {
          
          // if (audioTrack) {
          //   if (!audioTrack.isStopped) audioTrack.stop()
          //   // setAudioTrack(undefined)
          // }
          if (audioTrack) {
            audioTrack.disable()
          }

          let newAudioInput = audioInputs?.[1]?.deviceId || 'default'

          if(userAgent.match(/safari/i)) {
            newAudioInput = audioInputs?.[0]?.deviceId || 'default'
          }
  
          // setAudioTrack(newAudioTrack)
          replaceTrack(newAudioInput)
        // }
      })
      // .then((newAudioTrack) => {
        
      // })

    }

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange,
      )
    }
  }, [audioTrack])

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {}

    if (deviceId) {
      options.deviceId = {exact: deviceId}
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack)
      return newTrack
    })
  }, [])

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY,
    )

    const {videoInputDevices} = await getDeviceInfo()

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    )

    const options: CreateLocalTrackOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && {
        deviceId: {exact: selectedVideoDeviceId!},
      }),
    }

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      setVideoTrack(newTrack)
      return newTrack
    })
  }, [])

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      if (!audioTrack.isStopped) audioTrack.stop()
      setAudioTrack(undefined)
    }
  }, [audioTrack])

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      if (!videoTrack.isStopped) videoTrack.stop()
      setVideoTrack(undefined)
    }
  }, [videoTrack])

  const stopLocalTracks = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop()
    }

    if (videoTrack) {
      videoTrack.stop()
    }
  }, [audioTrack, videoTrack])

  const getAudioAndVideoTracks = useCallback(async () => {
    const {
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    } = await getDeviceInfo()

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve()
    if (isAcquiringLocalTracks || audioTrack || videoTrack)
      return Promise.resolve()

    setIsAcquiringLocalTracks(true)

    const selectedAudioDeviceId = localAudioInputDeviceId
    const selectedVideoDeviceId = localVideoInputDeviceId

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
    )
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    )

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    // @ts-ignore
    const isCameraPermissionDenied = await isPermissionDenied('camera')
    // @ts-ignore
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied
    const shouldAcquireAudio =
      hasAudioInputDevices && !isMicrophonePermissionDenied

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: {exact: selectedVideoDeviceId!},
        }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice
          ? {deviceId: {exact: selectedAudioDeviceId!}}
          : hasAudioInputDevices),
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find(
          (track) => track.kind === 'video',
        ) as LocalVideoTrack
        const newAudioTrack = tracks.find(
          (track) => track.kind === 'audio',
        ) as LocalAudioTrack
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack)
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          // window.localStorage.setItem(
          //   SELECTED_VIDEO_INPUT_KEY,
          //   newVideoTrack?.mediaStreamTrack?.getSettings()?.deviceId ?? '',
          // )
          setVideoInput(newVideoTrack?.mediaStreamTrack?.getSettings()?.deviceId ?? 'default')
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack)
          setAudioInput(newAudioTrack?.mediaStreamTrack?.getSettings()?.deviceId ?? 'default')
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          throw new Error('NotAllowedError')
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied')
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied')
        }
      })
      .catch(err => console.error('Error: ',err)) 
      .finally(() => setIsAcquiringLocalTracks(false))
  }, [audioTrack, videoTrack, isAcquiringLocalTracks])

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined,
  ) as (LocalAudioTrack | LocalVideoTrack)[]

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    stopLocalTracks
  }
}
