import {useEffect, useState} from 'react'
import {LocalTrack, LocalTrackPublication, RemoteTrack, RemoteTrackPublication, TrackPublication} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

export default function useTrack(
  publication: LocalTrackPublication | RemoteTrackPublication | undefined,
) {
  const { callProvider } = useMeetingContext()
  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
  const isDaily = callProvider === CallingProviderName.DailyCo

  const [track, setTrack] = useState<any>(isTwilio ? publication?.track : isDaily ? publication : null)


  useEffect(() => {
    if(isTwilio) {
      // Reset the track when the 'publication' variable changes.
      setTrack(publication && publication.track)

      function addTrack(remoteTrack: RemoteTrack) {
        setTrack(remoteTrack)
        remoteTrack.on('switchedOff', () => {
          //You may update your UI accordingly
          // You can also determine whether a particular RemoteTrack is switched off.
          // assert.equal(remoteTrack.isSwitchedOff, true);
          console.log(`The RemoteTrack ${remoteTrack.name} was switched off`);
        });
      
        remoteTrack.on('switchedOn', () => {
          //You may update your UI accordingly
          // You can also determine whether a particular RemoteTrack is switched off.
          // assert.equal(remoteTrack.isSwitchedOff, false);
          console.log(`The RemoteTrack ${remoteTrack.name} was switched on`);
        });
      }
  
      if (publication) {
        const removeTrack = () => setTrack(null)
  
        publication.on('subscribed', addTrack)
        publication.on('unsubscribed', removeTrack)
        return () => {
          publication.off('subscribed', addTrack)
          publication.off('unsubscribed', removeTrack)
        }
      }
    } else if(callProvider === CallingProviderName.DailyCo) {
      setTrack(publication || null)
    }
  }, [publication])

  return track
}
