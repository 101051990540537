import React from 'react'
import styled from 'styled-components'
import Modal from "../../components/Modalv2";

interface Props {
  toggleModal: (a: boolean) => void
  deleteItem: () => void
}

const DeleteImage = (props: Props) => {
  const { toggleModal, deleteItem } = props;
  return (
    <Modal {...props} {...{ closeModal: () => toggleModal(false) }}>
      <Container className="topbar rounded inner">
        <div className="warning-wrapper">
          <WarningHeader>
            Remove Image
          </WarningHeader>
          <WarningMessage>
            Are you sure you want to remove this image?
          </WarningMessage>
        </div>
        <ButtonCont>
          <CancelBtn
            className="button default"
            onClick={() => toggleModal(false)}
          >
            Cancel
          </CancelBtn>
          <DeleteBtn
            className="button danger"
            onClick={() => {
              deleteItem()
              toggleModal(false)
            }}
          >
            Remove
          </DeleteBtn>
        </ButtonCont>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;

  .warning-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const WarningHeader = styled.div`
    font-weight: 500;
    font-size: 16px;
`

const WarningMessage = styled.span`
    font-weight: 400;
    font-size: 13px;
    padding-top: 10px;
`

const ButtonCont = styled.div` 
    display: flex;
    width: 70%;
`
const DeleteBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 14px;
    margin: 4px;
`
const CancelBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 4px;
`


export default DeleteImage;