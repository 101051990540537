import React, { useEffect, useRef, useState } from 'react'

import { AspectRatioEnforcer, Video } from './styles'
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from '../../../Sidebar/UsersInCall/types';

const { GlobalState } = require('reflux')

interface Props {
	track?: MediaStreamTrack | IFakeMediasoupOuterTrack
}

declare global {
    interface Window {
        betaStartCustomTrack: any
        betaStopCustomTrack: any
    }
}

function AudioTrack(props: Props) {
    const trackRef = useRef<HTMLVideoElement>(null);
    const [ betaStream, setBetaStream ] = useState<any>(null)
    const { track } = props

    useEffect(() => {
        // if(trackRef.current?.srcObject) return 
        if (trackRef?.current) {

            let stream = new MediaStream()
            if(GlobalState?.main?.db_meeting?.settings?.calling_provider === 'DAILYCO' && track) {
                //console.log("USE BETA START CUSTOM TRACK")
                track && normalizedCallHelpers.addTrackToStream(stream, track)
                startBetaTrack()
            } else {
                track && normalizedCallHelpers.addTrackToStream(stream, track)
            }


            trackRef.current.srcObject = stream;
        }

        return () => {
            stopBetaTrack()
        }
    }, [track]);

    const startBetaTrack = async() => {
        const audio = await window?.betaStartCustomTrack({ track, mode: 'music' });
        setBetaStream(audio)
    }

    const stopBetaTrack = async() => {
        await window.betaStopCustomTrack({ mediaTag: betaStream });
    }

    return <audio {...{ ref: trackRef } as any} autoPlay/>
}

function areEqual(prevProps: Props, nextProps: Props) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
}

export default React.memo(AudioTrack, areEqual)
