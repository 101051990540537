import React, { Component } from 'react'
import DownloadNow from '../_Shared/DownloadNow'
import FileView from './FileView'
import { IFilesWidgetState } from './types'

interface Props {
	file?: IFileP
	UpdateSelf: (obj: any) => void
	updateState: (
	  fn: (prevState: IFilesWidgetState) => IFilesWidgetState,
	  cb?: Function
	) => void;
    parent: any
  changeCurrentFile: any
}

interface IFileP {
	filename?: string
	uri?: string
}

export default class File extends Component<Props> {
	render() {
		if (!this.props.file) {
			return null
		}
		return (
			<FileView changeCurrentFile={this.props.changeCurrentFile} filename={this.props.file.filename} uri={this.props.file.uri} UpdateSelf={this.props.UpdateSelf} updateState={this.props.updateState} parent={this.props.parent} />
		)
	}
}
