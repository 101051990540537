import { getDailyConnectionOptions } from "./daily";
import { getConnectionOptions } from "./twilio";
import { CallingProviderName } from "./types";

export const getProviderConnectOptions = (meetingProvider: CallingProviderName) => {
    let connectionOptions = {}

    switch(meetingProvider) {
        case CallingProviderName.Twilio:
            connectionOptions = getConnectionOptions(CallingProviderName.Twilio)
            break;
        
        case CallingProviderName.TwilioPro:
            connectionOptions = getConnectionOptions(CallingProviderName.TwilioPro)
            break;
                    
        case CallingProviderName.TwilioStandard:
            connectionOptions =getConnectionOptions(CallingProviderName.TwilioStandard)
            break;
            
        case CallingProviderName.DailyCo:
            connectionOptions = getDailyConnectionOptions()
            break;

        default:
            break;
    }

    return connectionOptions
}