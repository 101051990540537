import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import FlipMove from 'react-flip-move'
import { MdMoreVert as DotsIcon } from '@react-icons/all-files/md/MdMoreVert'
import ReactToolTip from "react-tooltip"
import {Img} from 'react-image'
import VisibilitySensor from 'react-visibility-sensor'
import Loader from '../../../components/Loader'
import TopBar from '../Sidebar/TopBar'
import KeynoteUserItem from './KeynoteUserItem'
import { MainStore } from '/stores/MainStore'
import { AuthStore } from '/stores/AuthStore'
import UserAdder from './UserAdder'
import MeetingTitle from './MeetingTitle'
import Dropdowns from './Dropdowns'
import BasicTopbarControls from '../NewCall/BasicTopbarControls'
import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import Loadable from 'react-loadable'
import logoText from '/assets/logo.svg'
import logowhite from '/assets/logowhite.svg'
import logoonly from '/assets/Icon-SVG.svg'

import { NotificationStore } from '../../../stores/NotificationStore'
import { ThemingStore } from '../../../stores/ThemingStore'
import CustomTip from '../../../helpers/CustomTip'
import { VariableCallingStore } from '../../../stores/VariableCallingStore'
import Grapl from '../../../assets/Icon-SVG.svg'
import BasicTopbarControlsFunctional from '../NewCall/BasicTopbarControlsFunctional'

const IconSvg = <img src={Grapl} alt="Grapl"/>

const topCompMappings = {
	OCMD: './titles/OcmdTitle',
	BASE: './titles/BaseTitle',
}

let compName = process.env.REACT_APP_CLIENT_NAME
if (!compName || !topCompMappings[compName]) {
	compName = 'BASE'
}
const ClientSpecificTitle = Loadable({
	loader: () => import(topCompMappings[compName]),
	loading: () => null,
})


const is_mobile = window.matchMedia('(max-width: 767px)')

export default class KeynoteSidebar extends Reflux.PureComponent {
	constructor(props) {
		super(props)

		this.stores = [MainStore, AuthStore, NotificationStore, ThemingStore, VariableCallingStore]
		this.storeKeys = ['db_meeting', 'users', 'meetingName', 'jwt', 'setupPhase', 'userBadges', 'color_scheme', 'connectedUserIds']
		this.mediaChange = this.mediaChange.bind(this)
		this.state = {
			is_mobile: is_mobile.matches,
			is_open: false
		}
	}

	mediaChange({ matches }) {
		this.setState({ is_mobile: matches })
	}

	componentDidMount() {
		is_mobile.addListener(this.mediaChange)
	}

	componentWillUnmount() {
		is_mobile.removeListener(this.mediaChange)
	}

	toggleOpen = () => {
		this.setState({ is_open: !this.state.is_open })
	}

	render() {
		if (!Reflux.GlobalState.auth.jwt) {
			return null
		}

		const fully_private =
			this.state.db_meeting.settings &&
			!this.state.db_meeting.settings.allow_knocking &&
			!this.state.db_meeting.settings.is_public

		const myUserId = Reflux.GlobalState.auth.jwt.data._id
		let userBreakdown = {
			myself: null,
			online: [],
		}
		this.state.users.forEach((u) => {
			if (!u.id || u.id === 'undefined') {
				console.info('User found without id:', u)
			} else if (u.id === myUserId) {
				userBreakdown.myself = u
			} else if (u.status === 'online') {
				userBreakdown.online.push(u)
			}
		})

		const users = (userBreakdown.online || []).filter((u, idx) => idx < 3).reverse().map((u) => {
			return <KeynoteUserItem user={u} key={u.id} />
		})
		let connectedUserIds = this.state.connectedUserIds
		if(this.state.connectedUserIds.length === undefined) {
			const connectedUserIdsKeys = (Object.keys(this.state.connectedUserIds) || []).filter((key) => !isNaN(parseInt(key)))
			connectedUserIds = connectedUserIdsKeys.map(s => this.state.connectedUserIds[s])
		}

		const UserDropdown = () => {
			return <React.Fragment>
				<Title>People</Title>
				<div style={{ maxHeight: '300px', overflowY: 'auto' }}>
					{
						(userBreakdown.online || []).map((user) => {
							return <UserWrapper>
								<UserImage>
									<VisibilitySensor>
										<Img
											key={user.avatar_url || `https://ui-avatars.com/api/?name=${user.username || user.name || ''}&format=svg&background=${user.color || 'random'}&color=fefefe` || 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg'}
											src={[user.avatar_url, `https://ui-avatars.com/api/?name=${user.username || user.name || ''}&format=svg&background=${user.color || 'random'}&color=fefefe`, 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg']}
											loader={<Loader circle containerHeight="30px" containerWidth="30px" />}
											alt={user.username || user.name}
											unloader={IconSvg} />
									</VisibilitySensor>
								</UserImage>
								<div className="info-wrapper">
									{user.name}
									<div className='color-primary' style={{ fontWeight: 500, fontSize: '12px', marginTop: '2px' }}>
										{connectedUserIds.includes(user.id) ? 'In Meeting' : ''}
									</div>
								</div>
							</UserWrapper>
						})
					}
				</div>
			</React.Fragment>
		}

		const show_extras = this.state.is_open || !this.state.is_mobile

		// Mark: Basic Topbar Controls
		return (
			<Container id='topbar-header-border' className="topbar header-border">
				<ClientSpecificTitle />
				<LogoContain className="border-light">
					<img src={this.state.is_mobile ? logoonly : this.state.color_scheme === 'Dark' ? logowhite : logoText} alt="Grapl" height="35px" />
				</LogoContain>
				<MeetingTitle />

				<div style={{ marginLeft: 'auto' }}>
					<BasicTopbarControlsFunctional />
				</div>
				<Controls isOpen={this.state.is_open}>
					{this.state.is_mobile &&
						<MobileMenu className="button default" onClick={() => this.toggleOpen()}>
							<DotsIcon />
							{
								!!this.state.userBadges && parseInt(this.state.userBadges.count) > 0 &&
								(<BadgeWrapper>
									<Badge>
										{this.state.userBadges.count}
									</Badge>
								</BadgeWrapper>) || null
							}
						</MobileMenu>
					}
					{(
						<div>
							{!this.state.is_mobile ?
								<React.Fragment>
									<UserContain key="keynotetopbarUserContain">
										<UsersWrapper>
											<div data-tip data-for="share-public-link" className={`share-public-link ${(userBreakdown.online || []).length === 0 ? 'solo-share' : ''}`}>{<UserAdder />}</div>
											{userBreakdown.online && userBreakdown.online.length > 3 ? <Dropdown DropdownItems={UserDropdown} right width="250px">
												<Plus className="button container content fixed border-topbar" data-tip data-for="user-count" >
													<span className="user-count">+{userBreakdown.online.length >= 100 ? 100 : userBreakdown.online.length - 3}</span>
												</Plus>
											</Dropdown> : ''}
											{users}
										</UsersWrapper>
									</UserContain>

									<CustomTip top={-40} left={-25} class='top-right' place='left' tip='Invite Users' dataFor='share-public-link' />
									<CustomTip top={-40} left={-25} class='top-right' place='left' tip={`+${userBreakdown.online.length >= 100 ? 100 : userBreakdown.online.length - 3}`} dataFor='user-count' />

								</React.Fragment> : <div className="useradd"><UserAdder /></div>}
						</div>
					)}
					{<Dropdowns isMobile={this.state.is_mobile} isOpen={this.state.is_open} userBadges={this.state.userBadges} key="keynotetopbarDropdowns" users={userBreakdown.online} meetingName={this.state.meetingName} />}
				</Controls>
				<TopBar
					key="keynotetopbarTopBar"
					meetingName={this.state.meetingName}
					myUser={
						userBreakdown.myself || {
							name: this.state.jwt.data && this.state.jwt.data.username,
							avatar_url: this.state.jwt.data && this.state.jwt.data.avatar_url,
							color: this.state.jwt.data && this.state.jwt.data.color,
						}
					}
					setup={this.state.setupPhase}
					history={this.props.history}
					hideExtras={true}
				/>
				{/* {this.state.is_mobile && (
					<Dots
						key="keynotetopbarDots"
						open={this.state.is_open}
						onClick={() => this.setState({ is_open: !this.state.is_open })}
					/>
				)} */}
				{/* {this.state.is_mobile && <Spacer key="keynotetopbarSpacer" />} */}
			</Container>
		)
	}
}

const LogoContain = styled.div`
	display: flex;
	align-items: center;
	margin-left: 45px;
	padding: 0 30px;
	border-right: 1px solid;
	border-left: 1px solid;
`

const UserWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	font-weight: 400;

	.info-wrapper {
		margin-left: 5px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
	}
`

const UserImage = styled.div`
	width: 30px;
	height: 30px;
	min-width: 30px;
	margin: 5px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
`

const Plus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px !important;
	border: 2px solid;

	&.fixed {
		width: 42px;
		height: 42px;
	}

	.user-count {
		font-weight: 400;
	}

	svg.fill-white {
		path {
			fill: white
		}
	}
`

const Title = styled.div`
	padding: 10px;
	font-weight: ${props => props.theme.textBold};
`

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px;
	font-size: 14px;

	&:hover {
		z-index: 1;
	}
`


const UsersWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	position: relative;

	& > div:not(:last-child) {
		margin-left: -18px;
	}

	.share-public-link {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: -12px !important;

		&:hover {
			z-index: 1;
		}
	}

	.solo-share {
		margin-left: 0px !important;
	}
`

const Spacer = styled.div`
	width: 16px;
	height: 8px;
`

const Dots = styled(DotsIcon).attrs({
	// color: 'white',
	size: 36,
})`
	cursor: pointer;
	position: absolute;
	top: auto;
	bottom: auto;
	right: 4px;
	transition: transform 0.3s ease-out;
	transform: rotate(${(props) => (props.open ? '90' : '0')}deg);
`

const UserContain = styled.div`
	display: flex;
	align-items: center;

	/* > div:not(:first-child) {
		margin-left: -22px;
	} */

	/* > div:first-child {
		z-index: 2;
	} */
`

const Container = styled(FlipMove)`
	background: ${({ theme }) => theme.color.string()};
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4); */
	border-bottom: 1px solid;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: auto;
	max-height: 72px;
	color: white;
	z-index: 100;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 20px;
	transition: max-height 0.3s ease-out;
	position: absolute !important;

	@media (max-width: 860px) {
		/* flex-direction: column-reverse;
		height: auto;
		${(props) => props.open && `max-height:Calc(100vh);`} > * {
			flex-direction: column;
			height: auto;
			margin: 4px auto;
		} */
	}
`

const Controls = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 767px) {
		flex-direction: column;
    	justify-items: flex-start;
		height: 38px;
		${({ isOpen }) => !!!isOpen && `overflow: hidden;`}
		/* overflow: hidden; */

		.useradd {
			margin-top: 5px;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s ease-out;
			transform: translateY(-41px);

			${({ isOpen }) => isOpen && `
				transform: none;
				visibility: visible;
				opacity: 1;
			`}
		}
	}
`

const MobileMenu = styled.div`
	margin: 0 5px;
	border-radius: 999px;
	// color: white;
	// background-color: #363B45;
	padding: 9px 8px !important;
	z-index: 2;
	> svg {
		padding: 0 2px;
		width: 17px;
		height: auto;
	}
`

const Badge = styled.div`
	position: relative;
    top: -26px;
    right: -16px;
	height: 18px;
	width: 18px;
	border-radius: 10px;
	line-height: 20px;
	background-color: #ff5555;
	color: #fff;
	text-align: center;
	display: block;
	font-size: 10px;
`

const BadgeWrapper = styled.div`
	position: absolute;
`