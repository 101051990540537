import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { IWidgetProps, AllWidgets } from '../types'

import bar from './Assets/bar.png'
import GroupItem from './GroupItem'
import Modal from '../_Shared/Modal'
import CreateGroupForm from './CreateGroupForm'
import SelectedGroup from './SelectedGroup'
import DeleteConfirmation from './DeleteConfirmation'
import InitialComponent from './InitialComponent'

import { NotificationStore } from '../../stores/NotificationStore'
import { Tasks, Todo, Todos } from './types'
import deepCopy from 'deep-copy'

type Props = IWidgetProps<AllWidgets.Todo>

type state = {
      closeModal: boolean
      showCreateGroup: boolean
      collapsed: boolean,
      showDeleteModal: boolean,
      showTodo: {
            todoId: string,
            taskId: string
      }
      taskIdToDelete: string
      selectedGroup: string
      groupIdtoEdit: string
}

export class index extends Reflux.Component<typeof NotificationStore, Props, state> {
      width: any
      constructor(props: Props) {
            super(props)

            this.state = {
                  closeModal: false,
                  showCreateGroup: false,
                  collapsed: false,
                  showDeleteModal: false,
                  showTodo: {
                        todoId: '',
                        taskId: ''
                  },
                  taskIdToDelete: '',
                  selectedGroup: '',
                  groupIdtoEdit: ''
            }

            this.width = null
            this.stores = [NotificationStore]
            this.storeKeys = [
                  'showTodo'
            ]
      }

      componentDidMount() {
            if (this.width && this.width <= 1100) {
                  this.setState({ collapsed: true })
            } else if (this.width > 1100) {
                  this.setState({ collapsed: false })
            }


            if (this.props.data.todo) {
                  const todos = this.patchArrayData(this.props.data.todo)
                  if (Object.keys(todos || []).length) {
                        const sessionGroup = sessionStorage.getItem('SelectedTodoGroup')
                        const setDefault = () => { this.setState({ selectedGroup: Object.keys(todos || [])[0] }) }

                        if (this.props.travelling)
                              setDefault()
                        else {
                              if (sessionGroup) {
                                    const { selectedGroup } = JSON.parse(sessionGroup)
                                    if (todos && todos[selectedGroup])
                                          this.setState({ selectedGroup })
                                    else
                                          setDefault()
                              } else {
                                    setDefault()
                              }
                        }
                  }
            }
      }

      componentDidUpdate(prevProps: Props, prevState: state) {
            if (!this.props.travelling && this.state.showTodo.todoId && prevState.showTodo.todoId !== this.state.showTodo.todoId) {
                  this.tryNotification()
            }

            if (!this.props.travelling && this.state.selectedGroup && prevState.selectedGroup !== this.state.selectedGroup) {
                  sessionStorage.setItem('SelectedTodoGroup', JSON.stringify({ selectedGroup: this.state.selectedGroup }));
            }
      }

      toggleCollapseSidebar = () => {
            this.setState((prevState: state) => ({ ...prevState, collapsed: !prevState.collapsed }))
      }

      notifCheck = () => {
            const { todoId, taskId } = this.state.showTodo

            if (todoId && this.props.data.todo && !this.props.data.todo[todoId])
                  return { missing: 'todo' };

            if (taskId && this.props.data.todo && !this.props.data.todo[todoId].tasks?.[taskId])
                  return { missing: 'task' };

            return { missing: 'none' };
      }

      tryNotification = () => {
            const nCheck = this.notifCheck()

            if (nCheck.missing !== 'todo') {
                  this.setState({ selectedGroup: this.state.showTodo.todoId })

                  if (nCheck.missing === 'none') {
                        setTimeout(() => {
                              try {
                                    let elem = Array.from(document.getElementsByClassName(`pid-${this.state.showTodo.taskId}`) as
                                          HTMLCollectionOf<HTMLElement>)[0]

                                    elem.scrollIntoView({
                                          block: 'center',
                                          behavior: 'smooth'
                                    })
                                    elem.classList.add('notif-highlight');

                                    setTimeout(function () {
                                          elem.classList.remove('notif-highlight');
                                    }, 3000);

                              } catch (e) {
                                    //console.log('error', e)
                              }
                        }, 300)
                  }
            }
      }

      changeIndexState = (state: any) => {
            this.setState(state)
      }

      patchArrayData = (tds: Todos | null) => {
            let todos = tds
            if (todos && Array.isArray(todos)) {
                  if (todos.length) {
                        todos = todos.reduce((acc: any, todo: any) => {
                              let tasks: any = todo.tasks
                              if (Array.isArray(tasks)) {
                                    if (tasks.length) {
                                          tasks = tasks.reduce((tacc: any, task: any) => {
                                                tacc = { ...tacc, [task.id]: task }
                                                return tacc
                                          }, {})
                                    } else {
                                          tasks = null
                                    }
                                    todo.tasks = tasks
                              }

                              acc = { ...acc, [todo.id]: todo }
                              return acc;
                        }, {})
                  } else {
                        todos = {}
                  }
            }

            return todos;
      }

      render() {
            let todos: null | Todos = deepCopy(this.props.data.todo)
            let completed = 0
            let pending = 0

            // patch old data of array todo to object
            todos = this.patchArrayData(todos)

            let todosLength = Object.keys(todos || []).length

            // travelling
            let travelSelectedGroup = ''
            let tSelectedGroupCheck = false
            if (this.props.travelling && todosLength) {
                  travelSelectedGroup = Object.keys(todos || [])[0]
                  tSelectedGroupCheck = !!todos && !!todos[this.state.selectedGroup]
            }

            if (todosLength) {
                  if (todos === null) return

                  let selectedGroup = !this.props.travelling ? this.state.selectedGroup :
                        tSelectedGroupCheck ? this.state.selectedGroup : travelSelectedGroup

                  let selected: Todo = todos[selectedGroup]
                  let tasks: Tasks | null = selected?.tasks

                  if (tasks && Object.keys(tasks).length) {
                        Object.values(tasks).map((m: any) => {
                              if (m.done) {
                                    completed += 1
                              } else {
                                    pending += 1
                              }
                        })
                  }

                  //THIS IS FOR OVERALL / ALL GROUP COMPLETED TASKS COUNT
                  // Object.values(todos || []).map(({ tasks }) => {
                  //       if (!tasks)
                  //             return

                  //       const tsks = Object.values(tasks)
                  //       if (!tsks.length)
                  //             return

                  //       tsks.map(task => {
                  //             if (task.done) completed += 1
                  //             else pending += 1
                  //       })
                  // })
            }

            return !todosLength ? (
                  <InitialCont>
                        <InitialComponent changeIndexState={this.changeIndexState} travelling={this.props.travelling} />
                        <Modal show={this.state.showCreateGroup}>
                              <CreateGroupForm
                                    todos={todos}
                                    groupIdtoEdit={this.state.groupIdtoEdit}
                                    changeIndexState={this.changeIndexState}
                                    actions={this.props.actions}
                              />
                        </Modal>
                  </InitialCont>
            ) : (
                  <MainCont>
                        {/* <Top>
                    <Icon src={icon} />
                    Todo List
                    <TopRight>
                        <InputCont className="container">
                            <FilterIcon>
                                <Filter />
                            </FilterIcon>
                            <FilterInput
                                className="container"
                                placeholder="Filter"
                            />
                        </InputCont>
                        <SVGCont>
                            <Hamburger />
                        </SVGCont>
                    </TopRight>
                </Top> */}

                        <Bottom>
                              <Overlay collapsed={this.state.collapsed} onClick={this.toggleCollapseSidebar} />
                              <Left
                                    collapsed={this.state.collapsed} className="container content rounded"
                              >
                                    <DashBoard>
                                          <Completed className="bg-primary rounded inner">
                                                <BarLeft>
                                                      <CountSpan>
                                                            {completed}
                                                      </CountSpan>
                                                      Completed
                                                </BarLeft>

                                                <BarRight>
                                                      <BarImg src={bar} />
                                                </BarRight>
                                          </Completed>

                                          <Remaining className="button default rounded inner">
                                                <BarLeft>
                                                      <CountSpan>
                                                            {pending}
                                                      </CountSpan>
                                                      To Do
                                                </BarLeft>

                                                <BarRight>
                                                      <BarImg src={bar} />
                                                </BarRight>
                                          </Remaining>
                                    </DashBoard>
                                    <GroupCont>
                                          {Object.entries(todos || []).map(([k, v]) => {
                                                return (
                                                      <GroupItem
                                                            todo={v}
                                                            key={k}
                                                            toggleCollapseSidebar={this.toggleCollapseSidebar}
                                                            changeIndexState={this.changeIndexState}
                                                            isSelected={!this.props.travelling ? this.state.selectedGroup === k :
                                                                  tSelectedGroupCheck ? this.state.selectedGroup === k : travelSelectedGroup === k}
                                                            groupKey={k}
                                                            travelling={this.props.travelling}
                                                      />
                                                )
                                          })}
                                    </GroupCont>
                                    {!this.props.travelling && <ButtonWrapper>
                                          <CreateButton
                                                className="button hover-topbar"
                                                onClick={() => {
                                                      this.setState({ showCreateGroup: true, groupIdtoEdit: '' })
                                                }}
                                          >
                                                <div>
                                                      CREATE GROUP
                                                </div>
                                          </CreateButton>
                                    </ButtonWrapper>}
                              </Left>

                              <SelectedGroup
                                    toggleCollapseSidebar={this.toggleCollapseSidebar}
                                    collapsed={this.state.collapsed}
                                    {...this.props}
                                    users={this.props.users}
                                    addLink={this.props.actions.AddLink}
                                    changeIndexState={this.changeIndexState}
                                    todos={todos}
                                    selectedGroup={!this.props.travelling ? this.state.selectedGroup :
                                          tSelectedGroupCheck ? this.state.selectedGroup : travelSelectedGroup}
                                    travelling={this.props.travelling}
                              />
                              <Modal show={this.state.showDeleteModal}>
                                    <DeleteConfirmation
                                          actions={this.props.actions}
                                          todos={todos}
                                          groupIdtoEdit={this.state.groupIdtoEdit}
                                          taskIdToDelete={this.state.taskIdToDelete}
                                          selectedGroup={!this.props.travelling ? this.state.selectedGroup :
                                                tSelectedGroupCheck ? this.state.selectedGroup : travelSelectedGroup}
                                          changeIndexState={this.changeIndexState}
                                    />
                              </Modal>
                              <Modal show={this.state.showCreateGroup}>
                                    <CreateGroupForm
                                          todos={todos}
                                          groupIdtoEdit={this.state.groupIdtoEdit}
                                          changeIndexState={this.changeIndexState}
                                          actions={this.props.actions}
                                    />
                              </Modal>
                        </Bottom>
                  </MainCont>
            )
      }
}

export const Overlay = styled.div<{ collapsed: boolean }>`
    display: none;

    @media (max-width: 1100px) {
        ${props => !props.collapsed ? 'display: block; position: absolute; left: 0; top:0; bottom: 0; right: 0; z-index: 2;' : ''};
	}
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: auto;
`

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const Bottom = styled.div`
    display: flex;
    height: 100%;
    /* height: 92%;  */ //remove comment once top components are finalized
`
const Left = styled.div<{ collapsed?: boolean }>`
      display: ${props => props.collapsed ? 'none' : 'flex'};
      flex: 1 1 0;
      /* height: calc(100vh - 122px); */
      padding: 5px;
      flex-direction: column;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      width: 0;


      @media (max-width: 1100px) {
            ${props => !props.collapsed ? 'position: absolute; left: 0; top:0; bottom: 0; z-index: 2; box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05); width: 100%' : ''};
      }
    
      @media (min-width: 1101px) {
            max-width: 335px;
      }
`

const GroupCont = styled.div`
      margin: 0 0 0 10px;
      padding-right: 6px;
    @media (min-width: 970px) {
        overflow: auto;
        max-height: 95%;
    }

    @media (min-width: 1101px) {
      ::-webkit-scrollbar {
          width: 3px;
          height: 3px;
      }
  
      ::-webkit-scrollbar-thumb {
          background: transparent;
      }
  
      /* Handle */
      &:hover::-webkit-scrollbar-thumb {
          background: darkgray;
      }
    }
`



const InitialCont = styled.div`
    display: flex;
    width: 100%;
`

const DashBoard = styled.div`
    display: flex;
    height: fit-content;
    color: white;
    margin: 10px 10px 0 10px;
`
const Completed = styled.div`
    display: flex;
    flex: 1;
    margin: 1rem 1rem 1rem 0;
`
const Remaining = styled.div`
    display: flex;
    flex: 1;
    margin: 1rem 0rem 1rem;
`
const BarLeft = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: left;
    text-align: left;
    padding-left: 0.5rem;
`
const BarRight = styled.div`
    padding: 5px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`
const CountSpan = styled.div`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
`
const BarImg = styled.img`
    object-fit: fill;
    height: 50px;
`

const CreateButton = styled.button`
    cursor: pointer;
    width: fit-content;
    border: none;
    font-weight: 500;
    background: none;
    margin-top: 10px;
    > div {
        padding-bottom: 1px;
        border-bottom: 2px solid;
    }
`

export default index
