import React, {createContext, useContext} from 'react'
import useActiveSinkId from '../screens/Meeting2.0/Widgets/KeynoteWidgets/hooks/useActiveSinkId'

export interface StateContextType {
  activeSinkId: string
  setActiveSinkId(sinkId: string): void
}

export const StateContext = createContext<StateContextType>(null!)

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [activeSinkId, setActiveSinkId] = useActiveSinkId()

  let contextValue = {
    activeSinkId,
    setActiveSinkId,
  } as StateContextType

  return (
    <StateContext.Provider value={{...contextValue}}>
      {props.children}
    </StateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider')
  }
  return context
}
