import Video, {ConnectOptions, LocalTrack, Room} from 'twilio-video'
import DailyIframe, { DailyCall, DailyCallOptions, DailyEventObjectRecordingStarted, DailyMeetingSession } from '@daily-co/daily-js';
import axios from 'axios'
import {VideoRoomMonitor} from '@twilio/video-room-monitor'
import {useCallback, useEffect, useRef, useState} from 'react'
import {isMobile, parseQuotedStrings} from '../../../../../calling/_helpers'
import {Callback} from '../VideoProvider/types'
import LoopApi from '../../../../../helpers/LoopApi'
import {CallingInstanceState} from '../../../../../calling/types'
import Sagas from '../../../../../helpers/Sagas'
import {VariableCallingActions} from '../../../../../stores/VariableCallingStore'
import { CallingProviderName, RoomType } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import { getConnectionOptions } from '../../../../../contexts/twilio'
import { createNewRecordings, createRoom, getDailyConnectionOptions, isProviderSupported } from '../../../../../contexts/daily'
import { closeAwsTranscriber, initializeAwsTranscriber } from '../../../../../helpers/aws-transcriber';
import { WidgetActions } from '../../../../../stores/MainStore'
import { v4 } from 'uuid'
import { Slide, toast } from 'react-toastify';
import useToastify from '../../../../../helpers/useToastify';
import RecordingNotif from '../../../../../components/RecordingNotif';
const {GlobalState} = require('reflux')


// @ts-ignore
window.TwilioVideo = Video

export default function useRoom(
  localTracks: LocalTrack[],
  onError: Callback,
  setCallStatus: React.Dispatch<React.SetStateAction<CallingInstanceState>>,
  options?: ConnectOptions | DailyCallOptions,
  callProvider?: CallingProviderName
) {
  const [room, setRoom] = useState<Room | DailyCall | null>(null)
  const [isConnecting, setIsConnecting] = useState(false)
  const optionsRef = useRef(options)

  useEffect(() => {
    optionsRef.current = options
  }, [options])

  const connect = useCallback(async ({ video, audio }: { video?: boolean, audio?: boolean }) => {
    
    try {
      setIsConnecting(true)
      setCallStatus(CallingInstanceState.Joining)
      VariableCallingActions.CallStatusChange(CallingInstanceState.Joining)

      // Mark: TWILIO CONNECTION
      if(callProvider === CallingProviderName.Twilio || callProvider === CallingProviderName.TwilioStandard || callProvider === CallingProviderName.TwilioPro) {
        // @ts-ignore
        const identity = `${GlobalState.main.db_meeting.name}::${GlobalState.auth.jwt.data._id}::${window.socket.id}`

        const response = await axios.get(
          `${
            process.env.REACT_APP_TWILIO_ACCESS_TOKEN_ENDPOINT ||
            'https://grapl-twilio-2351-dev.twil.io'
          }/token-service?identity=${identity}`,
        )

        const token = response?.data?.accessToken

        // create room from API
        const twilioRoom: any = await LoopApi(null, 'CreateTwilioRoom', {
          roomName: GlobalState.main.db_meeting.name,
          statusCallback:
            process.env.REACT_APP_TWILIO_WEBHOOK_URL ||
            'https://grapl-dev.ngrok.io/api/webhooks/twilio/callback',
        })

        const newRoom = await Video.connect(token, {
          ...optionsRef.current,
          tracks: localTracks,
          name: twilioRoom.uniqueName,
        })
        Sagas.Clients.Emit('main', 'message', {action: 'CallConnected'})

        setRoom(newRoom)
        VideoRoomMonitor.registerVideoRoom(newRoom)
        const disconnect = () => newRoom.disconnect()

        newRoom.setMaxListeners(15)

        newRoom.once('disconnected', () => {
          // Reset the room only after all other `disconnected` listeners have been called.
          setTimeout(() => {
            setRoom(null)
            setCallStatus(CallingInstanceState.Disconnected)
            VariableCallingActions.CallStatusChange(CallingInstanceState.Disconnected)
          })
          window.removeEventListener('unload', disconnect)

          if (isMobile) {
            window.removeEventListener('pagehide', disconnect)
          }

          Sagas.Clients.Emit('main', 'message', {action: 'CallDisconnected'})
        })

        // @ts-ignore
        window.twilioRoom = newRoom

        newRoom.localParticipant.videoTracks.forEach((publication) =>
          // All video tracks are published with 'low' priority because the video track
          // that is displayed in the 'MainParticipant' component will have it's priority
          // set to 'high' via track.setPriority()
          publication.setPriority('low'),
        )

        // Add a listener to disconnect from the room when a user closes their browser
        window.addEventListener('unload', disconnect)

        if (isMobile) {
          // Add a listener to disconnect from the room when a mobile user closes their browser
          window.addEventListener('pagehide', disconnect)
        }

        setIsConnecting(false)
        setCallStatus(CallingInstanceState.Connected)
        VariableCallingActions.CallStatusChange(CallingInstanceState.Connected)

        // get session
        const session = await LoopApi(null, 'GetRecordingBySessionId', {}, [
          ['sessionId', twilioRoom.sid],
        ])
        
        console.log({ session })
        
        Sagas.Clients.Emit('main', 'message', {action: 'CallConnected'})
        VariableCallingActions.SetLock({meetingLocked: session?.recording?.locked})
        if(session?.recording?.host) {
          VariableCallingActions.SetHost({ host: session?.recording?.host })
        }

        if(session?.recording?.isRecordingOngoing) {
          toast.dismiss('meetingRecordingNotif')
          useToastify({
            message: () => RecordingNotif(),
            position: "top-center",
            autoClose: false,
            closeButton: false,
            hideProgressBar: true,
            transition: Slide,
            toastId: 'meetingRecordingNotif',
            className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
            bodyClassName: "grow-font-size",
            progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
          })

          const event = new CustomEvent(`TOGGLE_RECORDING`, { detail: { onoff: session?.recording?.isRecordingOngoing } })
          window.dispatchEvent(event)
        }
        //////// NOTE: FOR DAILY ////////
      } else if(callProvider === CallingProviderName.DailyCo) {
        // check if the browser supports the Daily
        if(!isProviderSupported()) {
          setIsConnecting(false)
          setCallStatus(CallingInstanceState.JoiningFailed)
          VariableCallingActions.CallStatusChange(CallingInstanceState.JoiningFailed)
          const error = new Error('Browser not supported')
          error.message = 'Your browser does not support Daily. Please refresh the page to retry or use another browser.'
          return onError(error)
        }

        const dailyOptions: any = optionsRef.current

        let audioSource: MediaTrackConstraints | any = {
          deviceId: { ideal: parseQuotedStrings(localStorage.getItem('audio_input_device_id')) || 'default' },
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false
        }
        
        let videoSource: MediaTrackConstraints = { 
          deviceId: { ideal: parseQuotedStrings(localStorage.getItem('video_input_device_id')) || 'default' }
        }
        const url = await createRoom()
        const dailyRoom = DailyIframe.createCallObject({
          ...dailyOptions,
          audioSource: {
            ...audioSource,
            channels: 2,
          },
          videoSource,
          subscribeToTracksAutomatically: true,
          userName: `${GlobalState.main.meetingName}::${GlobalState.auth.jwt.data._id}`
        })
        
        
        
        if(dailyRoom && url) {
          await dailyRoom.join({ 
            url, 
            audioSource: parseQuotedStrings(localStorage.getItem('audio_input_device_id')) || true, 
            videoSource: parseQuotedStrings(localStorage.getItem('video_input_device_id')) || true,
            userName: `${GlobalState.main.meetingName}::${GlobalState.auth.jwt.data._id}`,
          });
          if(!!!video) {
            dailyRoom.setLocalVideo(false)
          }

          if(!!!audio) {
            dailyRoom.setLocalAudio(false)
          }

          const disconnect = () => dailyRoom.leave()
          dailyRoom.once('left-meeting', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => {
              setRoom(null)
              setCallStatus(CallingInstanceState.Disconnected)
              VariableCallingActions.CallStatusChange(CallingInstanceState.Disconnected)
            })
            window.removeEventListener('unload', disconnect)

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect)
            }

            Sagas.Clients.Emit('main', 'message', {action: 'CallDisconnected'})
          })

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('unload', disconnect)

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect)
          }

          await dailyRoom.setUserName(`${GlobalState.main.meetingName}::${GlobalState.auth.jwt.data._id}`)
          setIsConnecting(false)
          setCallStatus(CallingInstanceState.Connected)
          VariableCallingActions.CallStatusChange(CallingInstanceState.Connected)

          let { meetingSession } = await dailyRoom.getMeetingSession()
          const participants = dailyRoom.participants()
          const meetingDetails  = await LoopApi(null, 'GetDailyCoMeeting', {}, [['room_name', GlobalState.main?.meetingName]])
          await createNewRecordings(meetingSession?.id || '', participants)

          //@ts-ignore
          dailyRoom.sid = meetingSession?.id || ''
          setRoom(dailyRoom)

          //@ts-ignore
          window.dailyRoom = dailyRoom

          dailyRoom.on("recording-started", async (e: DailyEventObjectRecordingStarted | undefined) => {
            const session = await LoopApi(null, 'UpdateRecordingBySessionId', {
              compositionSid: e?.recordingId || '',
              isRecording: true
            }, [
              ['sessionId', meetingSession?.id || ''],
            ])

            const usr = GlobalState?.main?.users || []

            const getUser = (id: string) => usr.find((u: any) => u.id === id)
            const me = getUser(session.recordings.host)
            toast.dismiss('initializedRecording')
            Sagas.Clients.Emit('main', 'message', {
              action: 'ToggleRecording',
              currentUser: me,
              onoff: true,
              users: usr,
            })

          })
         
        }
      }

      Sagas.Clients.Emit('main', 'message', {action: 'CallConnected'})
      
      if((localTracks.find(({ kind }) => kind === 'audio') as any)?.isEnabled) {
          initializeAwsTranscriber(WidgetActions.TranscriptionResult, v4, GlobalState.main.db_meeting.settings.transcribe, localTracks.find(({ kind }) => kind === 'audio'))
      }
    } catch (err) {
      setIsConnecting(false)
      setCallStatus(CallingInstanceState.JoiningFailed)
      VariableCallingActions.CallStatusChange(CallingInstanceState.JoiningFailed)
      onError(err)
    }
  }, [localTracks, onError, callProvider])

  return {room, isConnecting, connect}
}
