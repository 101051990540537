import React, { Component } from 'react'
import styled from 'styled-components'

// import Sagas from '/helpers/Sagas'
import Modal from '/components/Modalv2'
import LoopApi from '/helpers/LoopApi'
// import { WidgetActions } from '/stores/MainStore'
import { GlobalState } from 'reflux'

export default class RemoveTranscription extends Component {
  constructor(props) {
    super(props)

    this.Cancel = this.Cancel.bind(this)
    this.Remove = this.Remove.bind(this)
  }

  Cancel() {
    this.props.closeModal()
  }

  async Remove() {
    const { getTranscriptions, view } = GlobalState.modal
    await LoopApi(null, 'RemoveTranscription', { view: view })
    await this.props.closeModal()
    getTranscriptions()
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        <Container>
          <Contents>
            <Title className="header">Remove Transcription</Title>
            <Details className="subheader">
              Are you sure you want to remove this transcription?
            </Details>
          </Contents>

          <Options>
            <Button className="button default" onClick={this.Cancel}>
              Cancel
            </Button>
            <Button className="button danger" onClick={this.Remove}>
              Remove
            </Button>
          </Options>
        </Container>
      </Modal>
    )
  }
}

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const Title = styled.div`
  font-weight: ${(props) => props.theme.textBold};
  font-size: ${(props) => props.theme.textLG};
`

const Details = styled.div`
  font-weight: ${(props) => props.theme.textRegular};
  font-size: ${(props) => props.theme.textMD};
  padding-top: 10px;

  &.small-padding {
    padding-top: 5px;
  }
`

const Options = styled.div`
  display: flex;
  width: 70%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 14px;
  margin: 4px;
`
