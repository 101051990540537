import React, {Fragment, useState} from 'react'
import styled from 'styled-components'
import GDrive from '../GoogleDrive/icon.svg'
import getIcon from '../_Shared/FileIcon'
import { FiEdit2 as EditIcon } from 'react-icons/fi'
import { BiTrashAlt as DeleteIcon } from 'react-icons/bi'
import moment from 'moment'
import EditSavedLink from './EditSavedLink'
import SyncModal from '../_Shared/SyncModal'
import EmptyFileContents from "../_Shared/EmptyFileContents"

interface Props {
  externals: any
  updateSelf: Function
  // setExternalFile: Function
  searchFilter: string
  back: Function
  isTravelling: boolean
}

const GoogleDrive = (props: Props) => {

  const [editModal, setEditModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)

  let googleDrive: any = null
  let hasLinks: boolean = false
  let gLinks: any[] = []

  if(!!props.externals && !!props.externals.googleDrive) {
    googleDrive = props.externals.googleDrive
    if(Object.values(googleDrive).length) {
      hasLinks = true
      gLinks = Object.values(googleDrive)

      if(props.searchFilter) {
        const filter = props.searchFilter
        gLinks = gLinks.filter(link => {
          return link.linkTitle.toLowerCase().includes(filter.toLowerCase()) ||
          link.linkDescription.toLowerCase().includes(filter.toLowerCase())
        })
      }
    }

  }

  const calcDuration = (duration: any) => {
    return duration.years() ? `${duration.years()}Y` :
    duration.months() ? `${duration.months()}M` :
    duration.weeks() ? `${duration.weeks()}W` :
    duration.days() ? `${duration.days()}D` :
    duration.hours() ? `${duration.hours()}h` :
    duration.minutes() ? `${duration.minutes()}m` :
    duration.seconds() ? `${duration.seconds()}s` :
    '< 1s'
  }

  const setItem = (item: any) => {
    const { id, name, linkTitle, linkDescription, mimeType, iconLink, webViewLink, savedTime, mtype } = item
    const FileIcon = getIcon(name)
    const IcoType = mimeType.includes('vnd.google-apps') && !!iconLink ?
                    <img src={iconLink.replace('/16/', '/32/')} /> :
                    <FileIcon size={32} />
    const now = moment(new Date()).format()
    const duration = moment.duration(moment(now).diff(moment(savedTime)))
    let link = ''
    switch (mtype) {
      case 'presentation':
          link = `https://docs.google.com/presentation/d/${id}`
          break
      case 'spreadsheet':
          link = `https://docs.google.com/spreadsheets/d/${id}`
          break
      case 'document':
          link = `https://docs.google.com/document/d/${id}`
          break
      default:
          link = `https://docs.google.com/file/d/${id}`
  }

    return (
      // <Item key={id} onClick={() => props.setExternalFile({id, mtype})}>
      <Item key={id} onClick={() => {window.open(link, '_blank')?.focus()}}>
        {IcoType}
        <div className="details">
          <div className="name">{linkTitle}</div>
          <div className="description">{linkDescription}</div>
          <div className="bottom">
            <span className="time">{calcDuration(duration)} - </span>
            <span className="url">{link}</span>
          </div>
        </div>
        {!props.isTravelling && <div className="options">
          <EditIcon
            className="fs-gd"
            onClick={(e) => {
              if (e.stopPropagation)
              e.stopPropagation()
              setEditModal(item)
            }}
          />
          <DeleteIcon
            className="fs-gd"
            onClick={(e) => {
              if (e.stopPropagation)
              e.stopPropagation()
              setDeleteModal(item.id)
            }}
          />
        </div>}
      </Item>
    )
  }

  const saveEdit = ({title, description, id}: {title: string, description: string, id: string}) => {
      const { [id]: str, ...others } = googleDrive
      const toUpdate = {
        ...others,
        [id]: {
          ...googleDrive[id],
          linkTitle: title,
          linkDescription: description
        }
      }

		props.updateSelf({externals: {
      ...props.externals,
      googleDrive: toUpdate
    }})
		setEditModal(null)
	}

  const deleteLink = () => {
    const id = deleteModal ? deleteModal : ''
    const { [id]: str, ...others } = googleDrive
    const toUpdate = {
      ...others
    }

    props.updateSelf({externals: {
      ...props.externals,
      googleDrive: toUpdate
    }})
    setDeleteModal(null)
  }

  return (
    <Container>
      {hasLinks ?
        <Fragment>
          <Header>
            <Brand>
              <Logo>
                <img src={GDrive} />
              </Logo>
              <span>Google Drive Links</span>
            </Brand>
            <Back
              className="button default"
              onClick={() => props.back()}
            >
              Back
            </Back>
          </Header>
          <Body>
            {gLinks.sort((a: any, b: any) => {
                return moment(b.savedTime).diff(moment(a.savedTime))
            }).map((item: any) => {
                return setItem(item)
            })}
          </Body>
        </Fragment> :
        <Fragment>
          <Header>
            <Brand>
              <Logo>
                <img src={GDrive} />
              </Logo>
              <span>Google Drive Links</span>
            </Brand>
            <Back
              className="button default"
              onClick={() => props.back()}
            >
              Back
            </Back>
          </Header>
          <EmptyFileContents>No links found</EmptyFileContents>
        </Fragment>

      }

      {editModal &&
        <EditSavedLink
          actionModal={(edit) => saveEdit(edit)}
          closeModal={() => setEditModal(null)}
          editItem={editModal}
        />
      }

      {deleteModal &&
        <SyncModal
          actionModal={() => deleteLink()}
          closeModal={() => setDeleteModal(null)}
          type="FSGDdeleteLink"
        />
      }
    </Container>
  )
}

const Container = styled.div`
  padding: 15px 30px;
  overflow-y: auto;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 26px;
  margin-right: 35px;

  span {
    font-size: 16px;
  }
`

const Logo = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 30px;
  background: #fff;
  margin-right: 15px;

  img {
      height: 20px;
  }
`

const Brand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Body = styled.div`

`

const Item = styled.div`
  margin: 30px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 3fr 1fr;

  .details {
		overflow: hidden;
		white-space: nowrap;

    > div {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .name {
      font-weight: 600;
      line-height: 17px;
    }

    .description {
      line-height: 17px;
    }

    .bottom {
      margin-top: 5px;
      line-height: 17px;
      .time {

      }
    }
  }

  .options {
    justify-self: end;

    .fs-gd {
      cursor: pointer;
      height: 17px;
      width: 17px;
      transition: all 200ms ease 0s;
      padding: 5px;
      :hover {
        transform: scale(1.2);
      }
    }
  }

`

const Back = styled.div`
  cursor: pointer;
  width: 58px;
  padding: 10px 13px !important;
`

export default GoogleDrive