import React, { Component, createRef, Fragment } from 'react'
import styled from 'styled-components'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import moment from 'moment'

import { SiGoogledrive as GdriveIcon } from 'react-icons/si'
import { FiUsers as UsersIcon } from 'react-icons/fi'
import { IoMdTime as Time } from 'react-icons/io'
import { AiOutlineStar as Star, AiOutlineDownload as DownloadIcon, AiOutlineLink as LinkIcon } from  'react-icons/ai'
import { HiViewList as ListIcon, HiViewGrid as GridIcon } from 'react-icons/hi'
import { BsEye as EyeIcon } from  'react-icons/bs'
import { BiSlideshow as Present } from  'react-icons/bi'
import { FaAngleLeft as CollapseIcon } from 'react-icons/fa'

import { GDriveFolderStackItem } from '../types'
import EmptyFileContents from '../_Shared/EmptyFileContents'
import LoadingComponent from '../_Shared/LoadingComponent'
import Item from './Item'

interface Props {
	updateFolderStack: (f: number, s: GDriveFolderStackItem) => void
	getFiles: (s?: string) => Promise<any>
	getFile: (s: string) => Promise<any>
	files: any[]
	folders: any[]
	pointerFileFormat: string
	folderStack: GDriveFolderStackItem[]
	loading: boolean
	menu: string
	changeIndexState: Function
	upload: Function
	isUploading: boolean
	onPresent: Function
	Download: Function
	viewType: string
	suggestedItems: any[]
	handleNextPage: Function
	nexPageLoading: boolean
	isLeftVisible: boolean
	colorScheme: string
	saveLink: Function
	GDExternals: any
	isPresenterOnline: boolean
}

const sideMenus = {
	mydrive: {
		name: 'My Drive',
		value: 'mydrive',
		icon: <GdriveIcon />
	},
	sharedwithme: {
		name: 'Shared with me',
		value: 'sharedwithme',
		icon: <UsersIcon />
	},
	recent: {
		name: 'Recent',
		value: 'recent',
		icon: <Time />
	},
	starred: {
		name: 'Starred',
		value: 'starred',
		icon: <Star />
	}
}

export default class Files extends Component<Props> {
	static defaultProps = {
		files: [],
	}

	state = {
		viewType: 'list',
		loading: false,
		prevY: 0
	}

	uploadRef = createRef<HTMLInputElement>()
	loadingRef = createRef<HTMLDivElement>()
	observer: IntersectionObserver

	componentDidMount() {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.99
		}

		this.observer = new IntersectionObserver(
			this.handleObserver,
			options,
		)
		if(this.loadingRef.current)
		this.observer.observe(this.loadingRef.current);
	}

	handleObserver = (entities: any, observer: any) => {
		const y = entities[0].boundingClientRect.y
		if (this.state.prevY > y && entities[0].isIntersecting) {
			this.props.handleNextPage()
		}
		this.setState({ prevY: y })
	}

	async openFolder(param: GDriveFolderStackItem) {
		const { updateFolderStack, getFiles } = this.props;
		updateFolderStack(1, param)
		await getFiles(param.folderId)
	}

	async openFile(param: GDriveFolderStackItem) {
		const { getFile } = this.props;
		return await getFile(param.folderId)
	}

	clickUploadRef = () => {
		this.uploadRef.current?.click()
	}

	handleUpload = (e: any) => {
		this.props.changeIndexState({isUploading: true})
		this.props.upload(e.target.files)
	}

	getSuggested = () => {
		let suggested = []
		if(
			(this.props.menu === 'mydrive' || this.props.menu === 'sharedwithme') &&
			this.props.folderStack.length === 1 &&
			this.props.suggestedItems &&
			this.props.suggestedItems.length
		) {
			const files = this.getFiles(this.props.suggestedItems.slice())
			suggested =  files.map((s: any) => {
				return {
					...s,
					val: React.cloneElement(s.val, { viewType: 'grid' })
				}
			})
		}
		return suggested
	}

	getFiles = (files: any) => {
		const {onPresent, changeIndexState} = this.props
		const f = files.map((file: any, index: number) => {
			const {id: folderId, name, ownedByMe} = file
				const menuEllipsis = [
					{
						name: 'view',
						text: 'View',
						icon: EyeIcon,
						onItemClick: async() => {
							this.openFile({folderId, name})
						}
					},
					...!this.props.isPresenterOnline ? [{
						name: 'present',
						text: 'Present',
						icon: Present,
						onItemClick: async() => {
							await this.openFile({folderId, name})
							const isPublic = !!file.permissions.find((p: any) => p.id === 'anyoneWithLink')
							if(isPublic || !ownedByMe)
							onPresent(true)
							else
							changeIndexState({
								modal: {
									show: true,
									type: 'GDSetToPublic'
								}
							})
						}
					}] : [],
					...file.webContentLink || file.exportLinks ? [{
						name: 'Download',
						text: 'Download',
						icon: DownloadIcon,
						onItemClick: () => this.props.Download(file)
					}] : [],
					{
						name: 'Save',
						text: 'Save Link',
						subtext: 'SAVE TO FILES TOOL',
						icon: LinkIcon,
						onItemClick: () => {
							if(!!this.props.GDExternals && !!this.props.GDExternals[file.id]) {
								changeIndexState({
									modal: {
										show: true,
										type: 'FSGDduplicateLink'
									},
									toSaveLink: file,
									isSaveLinkFromFolder: true
								})
							} else {
								this.props.saveLink(file, true)
							}
						}
					}
				]

				return {
					name: file.name,
					time: file.viewedByMe ? file.viewedByMeTime : file.modifiedByMeTime ? file.modifiedByMeTime : file.modifiedTime,
					val: <Item
							key={file.id}
							file={file}
							viewType={this.props.viewType}
							isLight={this.props.colorScheme === 'Light'}
							openFile={(param: GDriveFolderStackItem) => {
								if(!this.props.loading) {
									this.openFile(param)
								}
							}}
							options={menuEllipsis}
						/>
				}
		})
		return f
	}

	getFolders = (folders: any) => {
		const f = folders.map((folder: any) => {
					return {
						name: folder.name,
						val: <Item
								key={folder.id}
								file={folder}
								viewType={this.props.viewType}
								isLight={this.props.colorScheme === 'Light'}
								openFolder={(param: GDriveFolderStackItem) => {
									if(!this.props.loading) {
										this.openFolder(param)
									}
								}}
							/>
					}
		})
		return f
	}

	setRecent = (files: any) => {
		const timeDef = {
			today: {
				name: 'Today',
				val: []
			},
			week: {
				name: 'Earlier this week',
				val: []
			},
			month: {
				name: 'Earlier this month',
				val: []
			},
			year: {
				name: 'Earlier this year',
				val: []
			},
			older: {
				name: 'Older',
				val: []
			},
		}

		let recent = files.reduce((acc: any, file: any) => {
			if(moment(file.time).isSame(new Date(), 'day')) {
				acc.today.val.push(file)
			} else if (moment(file.time).isSame(new Date(), 'week')) {
				acc.week.val.push(file)
			} else if (moment(file.time).isSame(new Date(), 'month')) {
				acc.month.val.push(file)
			} else if (moment(file.time).isSame(new Date(), 'year')) {
				acc.year.val.push(file)
			} else {
				acc.older.val.push(file)
			}
			return acc
		}, timeDef)

		if(Object.values(recent).every((r: any) => !!!r.val.length))
		recent = null

		return recent
	}


	render() {
		const files = this.getFiles(this.props.files)
		const folders = this.getFolders(this.props.folders)
		const suggestedItems = this.getSuggested()

		let recent
		if(this.props.menu === 'recent')
		recent = this.setRecent(files)

		return (
				<Container>
					<SideBar isLeftVisible={this.props.isLeftVisible}>
						<input
							ref={this.uploadRef}
							type="file" hidden multiple id="files-to-upload"
							onChange={this.handleUpload}
							onClick={(event: any)=> {
								event.target.value = null
						   }}
						/>
						<UploadButton onClick={this.clickUploadRef}
							isUploading={this.props.isUploading}
							disabled={this.props.isUploading}
							isLight={this.props.colorScheme === 'Light'}
						>
							{this.props.isUploading ?
							<div className="overflow">
								<div className="circle-line">
									<div className="circle-red">&nbsp;</div>
									<div className="circle-blue">&nbsp;</div>
									<div className="circle-green">&nbsp;</div>
									<div className="circle-yellow">&nbsp;</div>
								</div>
							</div> :
							<div className="gtext">New</div>}
						</UploadButton>

						{Object.entries(sideMenus).map(([key, menu]) => {
							return (
								<SideMenuWrapper
									isSelectedMenu={this.props.menu === menu.value}
									isLight={this.props.colorScheme === 'Light'}
								>
									<SideMenus
										key={key}
										onClick={() => {
											this.props.changeIndexState({
												menu: menu.value,
												folderStack: [{
													name: 'Drive',
													folderId: ''
												}],
												pointerId: '',
												nextPageToken: '',
												isLeftVisible: false,
                        searchFilter: '',
											}, this.props.getFiles)
											this.setState({isLeftVisible: false})
										}}>
										{menu.icon}
										<span>{menu.name}</span>
									</SideMenus>
								</SideMenuWrapper>
							)
						})}
					</SideBar>
					<Content isLeftVisible={this.props.isLeftVisible}>
						<BackButton
								isLeftVisible={this.props.isLeftVisible}
								className="button primary"
								onClick={() => this.props.changeIndexState({ isLeftVisible: true })}
						>
								<CollapseIcon size={16} title="Collapse" />
						</BackButton>
						{
							!this.props.loading && suggestedItems.length ?
							<Fragment>
								<Heading>Suggested</Heading>
								<ItemWrapper viewType={'grid'} style={{marginBottom: '16px'}}>
									{suggestedItems.map((f: any) => f.val)}
								</ItemWrapper>
							</Fragment>
							 : null
						}
						<Wrapper style={{margin: '30px 0'}}>
							{this.props.menu !== 'recent' ? <Heading>Folders</Heading> : <Heading>Files</Heading>}
							<div>
								{
									this.props.viewType === 'grid' ? (
										<Fragment>
											<ListIcon
												data-tip
												data-for="list-icon"
												size={20}
												onClick={() => this.props.changeIndexState({ viewType: 'list' })}
											/>
										</Fragment>
									) : (
										<Fragment>
											<GridIcon
												data-tip
												data-for="grid-icon"
												size={20}
												onClick={() => this.props.changeIndexState({ viewType: 'grid' })}
											/>
										</Fragment>
									)
								}
							</div>
						</Wrapper>
						{
							this.props.viewType !== 'grid' &&
							<ColumnHeader>
								<div></div>
								<div>Name</div>
								<div>Owner</div>
								<div>Last Modified</div>
							</ColumnHeader>
						}
						{
							this.props.menu !== 'recent' ?
							<Fragment>
								{
									!this.props.loading && folders && folders.length > 0 ?
									<ItemWrapper viewType={this.props.viewType}>{folders.map((f: any) => f.val)}</ItemWrapper> :
									<EmptyFileContents>No Folders Found</EmptyFileContents>
								}

								<Wrapper style={{marginTop: '16px'}}>
								<Heading>Files</Heading>
								</Wrapper>

								{
									!this.props.loading && files && files.length > 0 ?
									<ItemWrapper viewType={this.props.viewType}>
										{files.map((f: any) => f.val)}
									</ItemWrapper> :
									<EmptyFileContents>No Files Found</EmptyFileContents>
								}
							</Fragment> :
							<Fragment>
								{
									!!recent && !this.props.loading ? Object.values(recent).map((r: any) => {
										if(r.val.length) {
											return (
												<Fragment>
													<Wrapper style={{marginTop: '16px'}}>
														<Heading>{r.name}</Heading>
													</Wrapper>
													<ItemWrapper viewType={this.props.viewType}>{r.val.map((f: any) => f.val)}</ItemWrapper>
												</Fragment>
											)
										}
									}) : <EmptyFileContents>No Files Found</EmptyFileContents>
								}
							</Fragment>
						}

						<LoadingNextPage ref={this.loadingRef}>
							<LoadingWrapper loading={this.props.nexPageLoading}>
								<LoadingComponent />
							</LoadingWrapper>
						</LoadingNextPage>
					</Content>
				</Container>
		)
	}
}

const ColumnHeader = styled.div`
	display: grid;
	grid-template-columns: 50px 3fr 1fr 1fr;
	padding: 8px 16px;
	min-height: 48px;

	> div {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: row;
`

const Content = styled.div<{isLeftVisible: boolean}>`
	padding: 26px;
	padding-top: 16px;
    flex-direction: column;
    flex: 1;

	${({isLeftVisible}) => isLeftVisible ? 'display: none;' : 'display: flex;'}

	@media (min-width: 1101px){
		display: flex;
	}
`

const Heading = styled.h1`
	font-weight: 100;
	margin-bottom: 16px;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	& > h1 {
		margin-bottom: 0;
	}

	& > div > svg {
		padding: 4px;
		margin-left: 4px;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background-color: none;
			@media (min-width: 769px) {
				background-color: rgba(0,0,0,0.3);
			};
		}
	}
`

const ItemWrapper = styled.div<{viewType: string}>`
	display: grid;
	${({viewType}) => viewType === 'grid' ?
		`grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 16px;
		padding: 0 16px;
		margin: 16px 0;

		@media (min-width: 456px){
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@media (min-width: 768px){
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@media (min-width: 1101px){
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@media (min-width: 1300px){
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}` : `grid-template-columns: 100%;`
	}

`

const SideBar = styled.div<{isLeftVisible: boolean}>`
	flex-direction: column;
	padding: 0px 0px 5px;

	${({isLeftVisible}) => isLeftVisible ?
	`min-width: 100%; display: flex;` :
	`min-width: 0%; display: none;`}

	@media (min-width: 1101px){
		display: flex;
		min-width: 335px;
		position: sticky;
		top: 73px;
		height: 400px;
	}
`

const SideMenuWrapper = styled.div<{isSelectedMenu: boolean, isLight: boolean}>`
	${({isSelectedMenu, isLight}) => isSelectedMenu ?
		`background: ${isLight ? '#fff' : '#272732'};`
		:
		`&:hover {
			background: ${isLight ? '#efefef' : '#1b1b25'};
		}
	`}

	@media (min-width: 1101px){
		border-top-right-radius: 21em 30em;
		border-bottom-right-radius: 20em 30em;
	}
`

const SideMenus = styled.div`
	padding: 5px;
	display: flex;
    align-items: center;
    margin-left: 50px;
	padding: 20px;
	cursor: pointer;

	svg {
		height: 20px;
		width: 20px;
		margin-right: 20px;
	}

`

const UploadButton = styled.button<{isUploading: boolean, isLight: boolean}>`
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    align-items: center;
    background-color: ${({isLight}) => isLight ? '#fff' : '#272732'};
    background-image: none;
    border: 1px
 	solid transparent;
    border-radius: 24px;
    color: ${({isLight}) => isLight ? '#3c4043' : '#fff'};
    display: inline-flex;
    font-family: 'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 48px;
    letter-spacing: .15px;
    line-height: 22px;
    margin: 0;
    min-width: 120px;
    padding: 0 24px0 0;
    text-transform: none;
    width: inherit;
	min-height: 32px;
	outline: none;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
    transition: color 200ms cubic-bezier(0.4,0,0.2,1),background 200ms cubic-bezier(0.4,0,0.2,1),box-shadow 400ms cubic-bezier(0.4,0,0.2,1);
    position: relative;
	margin-top: 30px;
	width: 10px;
	margin-left: 23px;
	margin-bottom: 51px;
	cursor: pointer;

	&:hover{
		box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
		background-color: ${({isLight}) => isLight ? '#f8f9fa' : '#12121f'};
		outline: none;
	}

	${({isUploading}) => !isUploading ? `
		&:before {
		border-radius: 50%;
		border-width: 0;
		content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path fill="%2334A853" d="M16 16v14h4V20z"/><path fill="%234285F4" d="M30 16H20l-4 4h14z"/><path fill="%23FBBC05" d="M6 16v4h10l4-4z"/><path fill="%23EA4335" d="M20 16V6h-4v14z"/><path fill="none" d="M0 0h36v36H0z"/></svg>');
		display: block;
		margin: 0 13px 0 9px;
		position: relative;
		height: 36px;
		-webkit-box-shadow: none;
		box-shadow: none;
		opacity: 1;
		}` :
		`cursor: default;
		pointer-events: none;`
	}


	.gtext {
		font-family: 'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
		font-weight: 500;
		font-size: 14px;
		color: ${({isLight}) => isLight ? '#3c4043' : '#fff'};
		letter-spacing: .15px;
		line-height: 22px;
		text-transform: none;
	}

	.overflow, .circle-line {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.circle-line {
		width: 110px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.circle-red,.circle-green,.circle-yellow,.circle-blue{
		position: relative;
		width: 8px;
		height: 8px;
		border-radius: 8px;
		margin: 5px;
	}

	.circle-red {
		background-color: #EA4335;
		animation: movingUp 0.3s infinite alternate;
	}

	.circle-blue {
		background-color: #4285f4;
		animation: movingUp 0.3s 0.1s infinite alternate;
	}

	.circle-green {
		background-color: #34A853;
		animation: movingUp 0.3s .2s infinite alternate;
	}

	.circle-yellow {
		background-color: #FBBC05;
		animation: movingUp 0.3s .3s infinite alternate;
	}

	@keyframes movingUp {
		from {
			top: -3px;
		}
		to {
			top: 0px;
		}
	}
`

const LoadingWrapper = styled.div<{loading?: boolean}>`
	position: relative;
	height: 100px;
	${({loading}) => loading === false ? 'display: none;' : loading === true ? 'display: block;' : ''}
`

const LoadingNextPage = styled.div`
	// height: 100px;
	// margin: 30px;
`

const BackButton = styled.div<{isLeftVisible: boolean}>`
    height: 27px;
    width: 27px;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 5px !important;
    display: ${({isLeftVisible}) => isLeftVisible ? 'none' : 'block'};

    @media (min-width: 1101px){
        display: none;
    }
`
