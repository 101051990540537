import React from 'react'
import Reflux from 'reflux'
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
} from 'twilio-video'
import {MediaPermissionsErrorType, requestMediaPermissions} from 'mic-check'

// Components
import SelectDevices from './SelectDevices'
import SpeakingDetector from './SpeakingDetector'
import ToggleAudioButton from './ToggleAudioButton'

// Others
import {VariableCallingStore} from '../../../../../../../stores/VariableCallingStore'
import {ModalActions} from '../../../../../../../stores/ModalStore'

// Helpers
import {getDevices} from './helpers'

// Styled Components
import {
  Button,
  StyledUsersIcon,
  XLine,
  Container,
  PreviewWrapper,
  VideoWrapper,
  Controls,
  PreviewCamMicButton,
} from './styledComponents'
import {MainActions, MainStore} from '../../../../../../../stores/MainStore'
import {
  getMediaDevices,
  parseQuotedStrings,
} from '../../../../../../../calling/_helpers'

import LoopApi from '../../../../../../../helpers/LoopApi'
import {IMeetingContext} from '../../../../../../../contexts/MeetingProvider'
import VideoTrack from '../../../../../Widgets/KeynoteWidgets/Main/components/VideoTrack'
import ToggleVideoButton from './ToggleVideoButton'
import { CallingProviderName } from '../../../../../../../contexts/types'
import { MutedIcon } from '../styledComponents'

var userAgent = navigator.userAgent.toLowerCase()
interface Props {
  onClose: (
    a: MediaTrackConstraints | boolean,
    c: unknown,
    b: MediaTrackConstraints | boolean,
  ) => void
  meetingContext: IMeetingContext
  isVideoEnabled?: boolean
  isAudioEnabled?: boolean
}

interface State {
  localAudioTrack: LocalAudioTrack | null
  localVideoTrack: LocalVideoTrack | null
  audioStream: MediaStream | null
  audioDevices: any[]
  videoDevices: any[]
  connectedUserIds: any
  micOn: boolean
  cameraOn: boolean
  devices: any
  permissionStatus: {
    hasAudioDevices: boolean
    hasVideoDevices: boolean
    permissionNeeded: boolean
    cameraBlocked: boolean
    microphoneBlocked: boolean
  }
  limits: any
  fetching: boolean
}

class Onboarding extends Reflux.Component<
  typeof MainStore | typeof VariableCallingStore,
  Props,
  State
> {
  constructor(props: Props) {
    super(props)

    this.stores = [MainStore, VariableCallingStore]
    this.storeKeys = ['status', 'connectedUserIds', 'devices', 'activeSinkId']

    this.state = {
      localAudioTrack: null,
      localVideoTrack: null,
      audioStream: null,
      audioDevices: [],
      videoDevices: [],
      devices: {
        videoDevices: [],
        audioDevices: {
          input: [],
          output: [],
        },
      },
      connectedUserIds: {},
      micOn: false,
      cameraOn: false,
      permissionStatus: {
        hasAudioDevices: false,
        hasVideoDevices: false,
        permissionNeeded: false,
        cameraBlocked: false,
        microphoneBlocked: false,
      },
      limits: {},
      fetching: false
    }
  }

  async init() {
    try {
      const requestedMediaPermission: any = await requestMediaPermissions()
      if(requestedMediaPermission) {
        //@ts-ignore
        window.getDevices()
      }
    } catch (err) {
      if (err.type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // user denied permission
        ModalActions.SetModal('PermissionNeeded')
      } else if (err.type === MediaPermissionsErrorType.UserPermissionDenied) {
        // browser doesn't have access to devices
        ModalActions.SetModal('DeviceConnection')
      } else if (
        err.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        return
      } else if (err === 'DOMException: Permission denied') {
        return ModalActions.SetModal('DeviceConnection')
      }
    }

    try {
      // Get List of Devices
      const videoDevices = await getDevices('videoinput')
      const audioDevices = await getDevices('audioinput')

      this.setState({audioDevices, videoDevices})

      // Permissions
      const deviceValid = this.checkPermissions()
      if (!!!deviceValid) return

      const hasAudioDevices = audioDevices.length > 0
      const hasVideoDevices = videoDevices.length > 0

      this.setState({micOn: hasAudioDevices, cameraOn: hasVideoDevices})
    } catch (err) {
      console.error('Onboarding2 componentDidMount err')
    }
  }

  async componentDidMount() {
    let browserName = ''
    if(userAgent.match(/edg/i)){
      browserName = "edge";
    } else if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "chrome";
    } else if(userAgent.match(/firefox|fxios/i)){
      browserName = "firefox";
    } else if(userAgent.match(/safari/i)){
      browserName = "safari";
    } else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else{
      browserName="No browser detection";
    }

    try {
      this.props.meetingContext.getAudioAndVideoTracks()
      this.init()
      if (browserName === 'safari' && !!!sessionStorage.getItem('safariPermissionAllowed')) {
        ModalActions.SetModal('PermissionNeeded')
      }
      this.getRoomOwnerLimits()
    } catch(err) {
      console.error("Error in onboarding: ", err)
    }
  }

  getRoomOwnerLimits = async () => {
    try {
    this.setState({ fetching: true })
      const limits = await LoopApi(null, 'StripeGetRoomOwnerLimits')
      this.setState({limits, fetching: false})
    } catch (err) {
      console.error('Error in getting room owner limits: ', err)
    }
  }

  componentWillUnmount() {
    // TODO: Stop Video Tracks On Close Dropdown
    if(this.props.meetingContext.callProvider === CallingProviderName.DailyCo) {
      this.props.meetingContext.removeLocalVideoTrack()
      this.props.meetingContext.removeLocalAudioTrack()
    }

    this.setState({
      audioStream: null,
      localAudioTrack: null,
      localVideoTrack: null,
    })
  }

  getPermissions = () => {
    const hasAudioDevices = this.state.audioDevices.length > 0
    const hasVideoDevices = this.state.videoDevices.length > 0

    // Permissions
    const microphoneBlocked = !!this.state.audioDevices.find(
      (device: any) => device.label === '',
    )
    const cameraBlocked = !!this.state.videoDevices.find(
      (device: any) => device.label === '',
    )
    const permissionNeeded = cameraBlocked && microphoneBlocked

    return {
      hasAudioDevices,
      hasVideoDevices,
      microphoneBlocked,
      cameraBlocked,
      permissionNeeded,
    }
  }

  checkPermissions = () => {
    const {
      hasAudioDevices,
      hasVideoDevices,
      permissionNeeded,
      cameraBlocked,
      microphoneBlocked,
    } = this.getPermissions()
    this.setState((prevState) => {
      const newState = {...prevState}
      newState.permissionStatus = {
        hasAudioDevices,
        hasVideoDevices,
        permissionNeeded,
        cameraBlocked,
        microphoneBlocked,
      }
      return newState
    })

    if (!hasAudioDevices && !hasVideoDevices) {
      // There are NO microphones and cameras
      if(!this.props.meetingContext.isAcquiringLocalTracks) {
        ModalActions.SetModalWithParams('DeviceFailed', { cb: () => {
          if(this.props.meetingContext.callProvider === CallingProviderName.DailyCo) {
            
            this.props.meetingContext.removeLocalVideoTrack()
            this.props.meetingContext.removeLocalAudioTrack()
    
            // this.setState({
            //   audioStream: null,
            //   localAudioTrack: null,
            //   localVideoTrack: null,
            // })
          } else {
            // setTimeout(() => {
              this.props.meetingContext.removeLocalVideoTrack()
              this.props.meetingContext.removeLocalAudioTrack()
            // }, 5000);
          }
        }})
      }
      return false
    } else if (permissionNeeded) {
      // There are detected devices but needs permission
      ModalActions.SetModal('PermissionNeeded')
      return false
    }

    return true
  }

  handleJoin = async () => {
    const {micOn, cameraOn} = this.state
    let video: MediaTrackConstraints | boolean = cameraOn
    let audio: MediaTrackConstraints | boolean = micOn

    let selectedAudioDeviceId = parseQuotedStrings(
      localStorage.getItem('audio_input_device_id'),
    )
    const selectedVideoDeviceId = parseQuotedStrings(
      localStorage.getItem('video_input_device_id'),
    )

    if (selectedAudioDeviceId && micOn) {
      audio = {
        deviceId: {ideal: selectedAudioDeviceId || 'default'},
      }
    }

    if (selectedVideoDeviceId && cameraOn) {
      video = {
        deviceId: {ideal: selectedVideoDeviceId || 'default'},
      }
    }

    // TODO: Stop Video Tracks On Close Dropdown

    // TODO: Apply LocalTracks from useLocalTracks to Onboarding
    this.props.meetingContext.connect({ video: this.props.isVideoEnabled, audio: this.props.isAudioEnabled })
    this.props.onClose(video, false, audio)
  }

  renderVideo = () => {
    const {cameraOn, micOn} = this.state
    const {hasAudioDevices, hasVideoDevices, microphoneBlocked, cameraBlocked} =
      this.getPermissions()

    const cameraAllowed = hasVideoDevices && !cameraBlocked && cameraOn
    const microphoneAllowed = hasAudioDevices && !microphoneBlocked && micOn

    if (!cameraAllowed && !microphoneAllowed) {
      return <div className="no-cam">Camera and microphone are off</div>
    } else if (!cameraAllowed && microphoneAllowed) {
      return <div className="no-cam">Camera off</div>
    } else {
      const videoTrack = this.props.meetingContext.localTracks.find(
        (track) => !track.name.includes('screen') && track.kind === 'video',
      ) as LocalVideoTrack
      return videoTrack ? <VideoTrack track={videoTrack} onboarding={true} isFlipped={true} /> : null
    }
  }

  check = () => {
    const {hasAudioDevices, microphoneBlocked}: any = this.checkDevices()
    return {
      hasAudioDevices,
      microphoneBlocked,
    }
  }

  checkDevices = async () => {
    const audioDevices = await getDevices('audioinput')
    const hasAudioDevices = audioDevices.length > 0

    // Permissions
    const microphoneBlocked = !!audioDevices.find(
      (device: any) => device.label === '',
    )
    const permissionNeeded = microphoneBlocked

    this.setState((prevState) => ({
      ...prevState,
      audioDevices,
      permissionStatus: {
        ...prevState.permissionStatus,
        microphoneBlocked,
        hasAudioDevices,
        permissionNeeded,
      },
    }))

    return {
      hasAudioDevices,
      microphoneBlocked,
    }
  }

  render() {
    const {permissionStatus} = this.state
    const {hasAudioDevices, permissionNeeded, microphoneBlocked} =
      permissionStatus
    let startButtonEnabled =
      hasAudioDevices && !microphoneBlocked && !permissionNeeded
    if (microphoneBlocked) {
      const {hasAudioDevices: had, microphoneBlocked: mb} = this.check()
      startButtonEnabled = had && !mb
    }
    if(!this.props.meetingContext.localTracks?.length) {
      startButtonEnabled = false
    }
    
    const audioTrack = this.props.meetingContext.localTracks.find(
      (track) => track.kind === 'audio',
    ) as LocalAudioTrack

    const willJoin =
      this.state.connectedUserIds?.length === undefined
        ? (Object.keys(this.state.connectedUserIds) || [])?.filter(
            (key: any) => !isNaN(parseInt(key)),
          )?.length
        : this.state.connectedUserIds?.length
    return (
      <Container>
        <PreviewWrapper>
          {audioTrack && audioTrack.isEnabled ? <SpeakingDetector audioTrack={audioTrack} /> : <MutedIcon />}
          <VideoWrapper className="rounded inner">
            {this.renderVideo()}
          </VideoWrapper>

          <Controls className="rounded inner">
            <div>
              <ToggleAudioButton />
              <ToggleVideoButton />
            </div>
          </Controls>
        </PreviewWrapper>

        <Button
          className={`start button primary`}
          onClick={
            this.state.limits.canStartMeeting
              ? this.handleJoin
              : !navigator.onLine ?  () => ModalActions.SetModal('NoInternet') : this.state.fetching ? () => console.log('Fetching...'): () => ModalActions.SetModal('LimitSession')
          }
          disabled={!startButtonEnabled}
        >
          <StyledUsersIcon size={18} />
          {willJoin
            ? 'Join Now'
            : this.state.limits.canStartMeeting
            ? 'Start Now'
            : !navigator.onLine ? 'Start Now' : this.state.fetching ? 'Fetching...' :  'Limit Reached'}
        </Button>

        <SelectDevices />
      </Container>
    )
  }
}

export default Onboarding
