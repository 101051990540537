import React from 'react'
import Reflux from 'reflux'
import Color from 'color'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt'
import styled from 'styled-components';

import "react-datepicker/dist/react-datepicker.css";
import { defaultColors, ThemingStore } from '../../stores/ThemingStore'

type DefProps = ReactDatePickerProps
interface Props extends DefProps {
    enableIcon?: boolean
    displayOnly?: boolean
    valueClassName?: string
    containerClassName?: string
    inverseHover?: boolean
    isDisabled?: boolean
}

interface State {
    color_scheme: string
    open: boolean
}

export default class RDatepicker extends Reflux.Component<typeof ThemingStore, Props, State> {
    constructor(props: Props) {
        super(props)

        this.store = ThemingStore
        this.storeKeys = ['color_scheme']
        this.state = {
            color_scheme: 'Light',
            open: false
        }

        this.handleOpen = this.handleOpen.bind(this)
    }

    handleOpen() {
        this.setState({ open: true })
    }

    render() {
        const { enableIcon, displayOnly, inverseHover = false, open, valueClassName, containerClassName = '', isDisabled = false, onClickOutside = () => { }, ...rest } = this.props
        return (
            <DatePickerContainer className={`hover-container${inverseHover ? '-inverse' : ''} ${enableIcon ? 'enabledIcon' : ''} ${displayOnly ? 'displayOnly' : ''} ${containerClassName}`} onClick={isDisabled ? () => { } : () => this.handleOpen()} isDisabled={isDisabled}>
                {
                    enableIcon ?
                        <UserWrapper>
                            <ImageWrapper className={this.props.selected ? '' : 'placeholder'} title={'Select a user'}><FaRegCalendarAlt /></ImageWrapper>
                        </UserWrapper> : ''
                }
                <DatePickerWrapper {...defaultColors[this.state.color_scheme]} color_scheme={this.state.color_scheme} className={`${valueClassName} hmmm`}>
                    <DatePicker open={open || this.state.open} onClickOutside={(e) => {
                        onClickOutside(e)
                        this.setState({ open: false })
                    }} {...rest} calendarContainer={CustomDatePicker} />
                </DatePickerWrapper>
            </DatePickerContainer>
        )
    }
}

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
`

const ImageWrapper = styled.div`
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px dashed;
    font-weight: 400;
    font-size: 12px;

    &.placeholder {
        color: #757575;
    }
`


const DatePickerContainer = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    width: max-content;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    z-index: 3 !important;

    &.enabledIcon {
        .react-datepicker-wrapper {
            min-width: 195px;
        }

        .react-datepicker-popper {
            left: -45px !important;
            top: 5px !important;
            z-index: 4 !important;
        }
    }

    &.displayOnly {
        cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
        .react-datepicker-wrapper {
            input {
                cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
            }
        }
    }
    &.task-datepicker {
        border: 1px solid;
    }

    @media (max-width: 480px) {
        &.enabledIcon {
            .react-datepicker-popper {
                left: -85px !important;
                z-index: 4;
            }
        }
    }
`

const CustomDatePicker = styled.div`
    border: none;
    box-shadow: ${props => props.theme.shadows.neumorphiclight};
    padding: 10px;

    .react-datepicker__navigation {
        top: 20px;

        &.react-datepicker__navigation--previous {
            left: 20px;
        }

        &.react-datepicker__navigation--next {
            right: 20px;
        }
    }

    .react-datepicker__header {
        border-bottom: none;
        background: transparent;
        padding-top: 10px;
        padding-bottom: 10px;

        .react-datepicker__current-month {
            font-weight: 500;
        }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        font-weight: 400;
    }

    .react-datepicker__day--outside-month {
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background: none;
        border: none;
        border-bottom: 2px solid #2D81FF;
        border-radius: 0px;
        color: #2D81FF !important;
        font-weight: 500;
    }

    .red  {
        background: red;
    }
`

const DatePickerWrapper = styled.div<{ container?: string, containerText?: string, color_scheme?: string }>`
    width: 100%;
    
    ${CustomDatePicker} {
        background: ${props => props.container};
        color: ${props => props.containerText};

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header,
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: ${props => props.containerText};
        }

        .react-datepicker__day--today:not(.react-datepicker__day--selected) {
            background: #2D81FF;
            color: #FFFFFF;
            border-radius: 4px;
        }

        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
            background: ${() => Color('#2D81FF').alpha(0.4).toString()};
        }
    }

    .react-datepicker-wrapper {
        min-width: 250px;

        input {
            width: calc(100% - 8px);
            width: -webkit-fill-available;
            border: none;
            background: none;
            font-weight: 300;

            color: ${props => props.color_scheme === 'Dark' ? '#FFF' : ''}
        }
    }

    &.error {
        .react-datepicker-wrapper {
            input {
                color: #FF3C3C;
            }
        }
    }

    &.success {
        .react-datepicker-wrapper {
            input {
                color: #00bf9c;
            }
        }
    }

`
