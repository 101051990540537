import React, {Component} from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

// Store
import {AuthStore} from '../../../../../stores/AuthStore'
import {MainActions, MainStore} from '../../../../../stores/MainStore'
import {
  IVariableCallingStoreState,
  VariableCallingStore,
} from '../../../../../stores/VariableCallingStore'

// Components
import TwilioCallingSlideNew from '../../../NewCall/TwilioCallingSlide/new'
import AllWidgets from '../../../../../loop-widgets'
import WidgetItem from '../../WidgetItem'
import {CurrentWidgetWrapper, GhostContainer, MainDivGrid} from '../StyledComponents'
import Premades from '../../Premades'
import CallUserUITwilio from '../../../Sidebar/UsersInCall/CallUserUITwilio'
import Room from './components/Room'

import './styles.css'

// types
import {IState} from './types'
import PresentationTileTwilio from '../../../NewCall/CallSlide/CallTiles/PresentationTileTwilio'
import {VideoProvider} from '../VideoProvider'
import MobileView from './components/MobileView'
import {IMeetingContext} from '../../../../../contexts/MeetingProvider'
import {CallingInstanceState, CallingProviderName} from '../../../../../calling/types'
import * as twilio from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'
import { closeAwsTranscriber } from '../../../../../helpers/aws-transcriber'
import { toast } from 'react-toastify'

interface Props {
  meetingContext: IMeetingContext

  // TODO: Update MainClass Props to use proper Types
  currentWidgetName: any
  widgets: any
  currentWidgetData: any
  widget_display_method: any
  isSettingUpWidget: any
  isInCall: any
  setActiveWidget: any
  mobileCollapse: boolean
}

class MainClass extends Reflux.Component<
  typeof MainStore | typeof VariableCallingStore | typeof AuthStore,
  Props,
  IState
> {
  state: IState

  constructor(props: Props) {
    super(props)
    this.stores = [VariableCallingStore, MainStore, AuthStore]
    this.storeKeys = [
      'participants',
      'users',
      'callObject',
      'current_presenter',
      'call_starter',
      'jwt',
      'meetingName',
      'participantTracks',
      'localScreenshareTrack',
      'remoteScreenshareTrack',
      'localVideoTrack',
      'localAudioTrack',
      'host',
      'startTime',
      'transcriptionTrack',
      'activeSinkId',
      'db_meeting',
    ]
  }

  componentDidMount() {
    const cdm = async () => {
      // SETUP EVENT HANDLER
      if (window) {
        window.addEventListener('LEAVE_MEETING', this.handleLeaveMeeting)
      }
    };

    cdm();
  }

  componentWillUnmount() {
    if(window) {
      window.removeEventListener('LEAVE_MEETING', this.handleLeaveMeeting)
    }
  }

  handleLeaveMeeting = () => {
    if(this.props.meetingContext.callProvider === CallingProviderName.DailyCo) {
      //   room?.stopRecording()
      (this.props.meetingContext.room as DailyCall)?.leave();
      (this.props.meetingContext.room as DailyCall)?.destroy();
      this.props.meetingContext.stopLocalTracks()
  } else {
      (this.props.meetingContext.room as any)?.disconnect()
  }
  closeAwsTranscriber();

  toast.dismiss('meetingRecordingNotif')
}

  renderWidgetSetup = () => {
    return (
      <GhostContainer>
        <Premades />
      </GhostContainer>
    )
  }

  getCurrentWidget = () => {
    const {
      currentWidgetName,
      widgets,
      currentWidgetData,
      widget_display_method,
    } = this.props
    return (
      currentWidgetName &&
      widgets[currentWidgetName] &&
      AllWidgets[currentWidgetName] && (
        // <CurrentWidgetWrapper {...{ keynote: widget_display_method === 'Keynote' }}>
        <WidgetItem {...currentWidgetData} />
        // </CurrentWidgetWrapper>
      )
    )
  }

  renderScreenshareTrack = () => {
    const {current_presenter, localScreenshareTrack, remoteScreenshareTrack} =
      this.state
    const {room} = this.props.meetingContext
    const localParticipantId = (room as twilio.Room)?.localParticipant?.identity || (room as DailyCall)?.participants()?.local?.user_name

    const user = this.state.users.find(
      (u: any) => u.id === localParticipantId?.split('::')[1],
    )

    if (
      !room ||
      !current_presenter ||
      (!localScreenshareTrack && !remoteScreenshareTrack)
    ) {
      return null
    }

    const isLocal = user.id === current_presenter

    return (
      <PresentationTileTwilio
        screenTrack={isLocal ? localScreenshareTrack : remoteScreenshareTrack}
      />
    )
  }

  renderNonMeeting = () => {
    const {isSettingUpWidget, currentWidgetName} = this.props

    if (isSettingUpWidget) {
      // Non Meeting -> Setting Up Widget
      return this.renderWidgetSetup()
    } else if (!isSettingUpWidget && currentWidgetName) {
      // Non Meeting -> Showing Current Widget
      return this.getCurrentWidget()
    } else {
      return this.renderMobileView()
    }
  }

  renderMainContent = () => {
    const {isSettingUpWidget, currentWidgetName, meetingContext} = this.props
    const {current_presenter} = this.state
    const isInCall =
      meetingContext.callStatus === CallingInstanceState.Connected

    const isScreenSharing =
      current_presenter &&
      (this.state.localScreenshareTrack || this.state.remoteScreenshareTrack)

    if (!isInCall) {
      if (isSettingUpWidget) {
        // Non Meeting -> Setting Up Widget
        return this.renderWidgetSetup()
      } else if (!isSettingUpWidget && currentWidgetName) {
        // Non Meeting -> Showing Current Widget
        return this.getCurrentWidget()
      } else {
        return null
      }
    } else {
      if (!currentWidgetName) {
        // Full Mode (showing all participants / ongoing call is clicked)
        return isScreenSharing ? this.renderScreenshareTrack() : null
      } else if (currentWidgetName) {
        return this.getCurrentWidget()
      } else {
        return null
      }
    }
  }

  renderMobileView = () => {
    return <MobileView />
  }

  // Twilio Related
  renderLocalParticipant = () => {
    if (!this.props.meetingContext.room) {
      return null
    }

    const { room } = this.props.meetingContext
    const localParticipantId = (room as twilio.Room)?.localParticipant?.identity || (room as DailyCall)?.participants()?.local?.user_name

    const user = this.state.users.find(
      (u: any) =>
        u.id ===
        localParticipantId?.split(
          '::',
        )[1],
    )

    const isHost = this.state.host === user.id

    return (
      <CallUserUITwilio
        participant={(room as twilio.Room)?.localParticipant || (room as DailyCall)?.participants()?.local}
        user={user}
        isLocalParticipant={true}
        localVideoTrack={this.state.localVideoTrack}
        localAudioTrack={this.state.localAudioTrack}
        isHost={isHost}
        room={this.props.meetingContext.room}
        canSharescreen={
          !this.state.localScreenshareTrack &&
          !this.state.remoteScreenshareTrack
        }
        isSharingScreen={!!this.state.localScreenshareTrack}
        transcriptionTrack={this.state.transcriptionTrack}
      />
    )
  }

  renderParticipants = () => {
    if (
      !this.props.meetingContext.room ||
      !Array.isArray(this.state.participantTracks)
    ) {
      return null
    }

    const tracksToRender = [...this.state.participantTracks]

    const renderFirstParticipants = (n: number) => {
      return tracksToRender.slice(0, n).map((participant: any) => {
        const user = this.state.users.find(
          (u: any) => u.id === participant.identity.split('::')[1],
        )
        const isHost = this.state.host === user?.id

        return (
          <CallUserUITwilio
            key={participant.identity}
            participant={participant}
            user={user}
            isLocalParticipant={false}
            isHost={isHost}
            room={this.props.meetingContext.room}
            canSharescreen={
              !this.state.localScreenshareTrack &&
              !this.state.remoteScreenshareTrack
            }
          />
        )
      })
    }

    if (tracksToRender.length > 8) {
      return (
        <React.Fragment>
          {renderFirstParticipants(7)}
          <div className="participant">
            <h1>{tracksToRender.length - 7} other(s)</h1>
          </div>
        </React.Fragment>
      )
    } else {
      return renderFirstParticipants(8)
    }
  }

  render = () => {
    const {users, host} = this.state
    const {room} = this.props.meetingContext
    const {currentWidgetName, setActiveWidget, currentWidgetData, widgets, mobileCollapse} =
      this.props

    const isInCall =
      this.props.meetingContext.callStatus === CallingInstanceState.Connected

    const couldRenderWidget =
      currentWidgetName &&
      widgets[currentWidgetName] &&
      AllWidgets[currentWidgetName]

    if (!isInCall) {
      return (
        <MainDivGrid id="main-grid" className={`container main non-meeting`} { ...{mobileCollapse} }>
          <main id="main-content">
            <div className="main-content-inner">{this.renderNonMeeting()}</div>
          </main>
          <aside id="participants-sidebar"></aside>
        </MainDivGrid>
      )
    } else {
      if (!room) return null
      return (
        <VideoProvider
          // room={room}
          // onError={() => {}}
          currentWidgetName={currentWidgetName}
          setActiveWidget={setActiveWidget}
          setActiveSinkId={MainActions.SetActiveSinkId}
          activeSinkId={this.state.activeSinkId}
          users={users}
          host={host}
          // meeting={this.state.db_meeting}
        >
          <Room
            currentWidgetData={currentWidgetData}
            couldRenderWidget={couldRenderWidget}
            mobileCollapse={mobileCollapse}
          />
        </VideoProvider>
      )
    }
  }

  oldRender() {
    const {currentWidgetName, setActiveWidget} = this.props
    const {current_presenter} = this.state

    const isInCall =
      this.props.meetingContext.callStatus === CallingInstanceState.Connected

    const isScreenSharing =
      current_presenter &&
      (this.state.localScreenshareTrack || this.state.remoteScreenshareTrack)

    let className = 'non-meeting'
    if (isInCall) {
      if (!currentWidgetName) {
        className = isScreenSharing ? 'meeting-sidebar' : 'meeting-full'
      } else {
        className = 'meeting-sidebar'
      }
    }

    const participantsCount = this.state.participantTracks.length + 1
    let participantColumns = 1
    let participantRows = 1
    let participantMultiplier = 3

    // columns
    if (participantsCount >= 2 && participantsCount <= 4) {
      participantColumns = 2
      participantMultiplier = 2
    }
    if (participantsCount >= 5) {
      participantColumns = 3
      participantMultiplier = 1
    }

    // rows
    if (participantsCount === 2) {
      participantRows = 1
    }
    if (participantsCount >= 3 && participantsCount <= 6) {
      participantRows = 2
    }
    if (participantsCount > 6) {
      participantRows = 3
    }

    return (
      <div id="main-grid" className={`topbar ${className}`}>
        <main id="main-content">
          <div className="main-content-inner">{this.renderMainContent()}</div>
        </main>
        <aside id="participants-sidebar">
          <div
            className={`participants-wrapper size-${participantMultiplier} cols-${participantColumns} rows-${participantRows}`}
          >
            {isInCall && (
              <React.Fragment>
                {this.renderLocalParticipant()}
                {className === 'meeting-sidebar' &&
                isScreenSharing &&
                currentWidgetName ? (
                  <div
                    className="participant"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setActiveWidget(null)
                    }}
                  >
                    {this.renderScreenshareTrack()}
                  </div>
                ) : null}
                {this.renderParticipants()}
              </React.Fragment>
            )}
          </div>
        </aside>
      </div>
    )
  }
}

export default MainClass
