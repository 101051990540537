import React from 'react'
import styled from 'styled-components'
import Reflux from 'reflux'
import Color from 'color'
import screenfull from 'screenfull'
import Scrollbars from 'react-custom-scrollbars'

import { MainStore } from '/stores/MainStore'
import Modal from '/components/Modalv2'
import Dropdown from '../../../components/Dropdown'
import Checkbox from '../../../components/Checkbox'
import { Button } from '/components/Elements'
import LoopApi from '/helpers/LoopApi'
import { ModalActions } from '../../../stores/ModalStore'
import { defaultColors } from '../../../stores/ThemingStore'
import { VariableCallingStore } from '../../../stores/VariableCallingStore'
import { FiAlertCircle as InfoIcon } from '@react-icons/all-files/fi/FiAlertCircle'
import { AuthStore } from '../../../stores/AuthStore'
import { GlobalState } from 'reflux'

const CALL_PROVIDERS = [
    {
        name: "HiFi 1 to 1",
        value: "DAILYCO"
    },
    {
        name: "Standard Group",
        value: "TWILIO::STANDARD"
    },
    // {
    //     name: "Pro Group",
    //     value: "TWILIO::PRO"
    // },
    // {
    //     name: "WebRTC",
    //     value: "WEBRTC",
    //     disabled: true
    // }
]

const COLOR_SCHEMES = [
    {
        name: "Dark",
        value: "Dark"
    },
    {
        name: "Light",
        value: "Light"
    }
]

const WIDGET_DISPLAY_METHODS = [
    {
        name: "Keynote",
        value: "Keynote"
    },
    // {
    //     name: "Tabs",
    //     value: "Tabs"
    // },
    // {
    //     name: "Fixed",
    //     value: "Fixed"
    // },
]

const CALL_TYPES = [
    {
        name: "Room",
        value: "Room"
    },
    {
        name: "Call",
        value: "Call"
    }
]


export default class ChangeSettings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [MainStore, VariableCallingStore, AuthStore]
        this.storeKeys = ['db_meeting', 'connectedUserIds', 'jwt', 'devices']
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeState = this.handleChangeState.bind(this)
        this.saveNow = this.saveNow.bind(this)
        this.toggleCommands = this.toggleCommands.bind(this)
        this.setPassword = this.setPassword.bind(this)
        this.state = {
            status: null,
            settings: {},
            show_commands: false,
            isFullscreen: window.matchMedia('(display-mode: fullscreen)').matches,
            videoDevices: [],
            audioDevices: [],
            devices: {
                videoDevices: [],
                audioDevices: {
                    input: [],
                    output: [],
                },
            },
            video_input_device_id: null,
            audio_input_device_id: null,
            changed: [],
            changepassword: false,
            password: '',
            uncheckPKAccepted: false
        }
    }

    toggleCommands() {
        this.setState({ show_commands: !this.state.show_commands })
    }

    componentDidMount() {
        if (!!GlobalState.modal.prevState) {
            this.setState(GlobalState.modal.prevState)
            ModalActions.SetParams({ prevState: null })
        } else {
            this.setState({
                ...this.state,
                dirty: false,
                settings: this.state.db_meeting.settings,
                original: this.state.db_meeting.settings,
                devices: {
                    audioDevices: {
                        input: this.state.devices.audioDevices.input.map(d => ({ name: d.label, value: d.deviceId })),
                        output: this.state.devices.audioDevices.output.map(d => ({ name: d.label, value: d.deviceId })),
                    },
                    videoDevices: this.state.devices.videoDevices.map(d => ({ name: d.label, value: d.deviceId }))
                }
            })
        }
    }

    handleSelectSource = (item, name) => {
        this.setState({
            status: 'dirty',
            [name]: item.value
        })
    }

    handleSelect = (item, name) => {
        this.setState({
            status: 'dirty',
            settings: {
                ...this.state.settings,
                [name]: item.value,
            },
        })
    }

    handleChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            status: 'dirty',
            settings: {
                ...this.state.settings,
                [name]: value,
            },
        })
    }

    handleChangeState(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            status: 'dirty',
            [name]: value,
        })
    }

    async saveNow(e = null) {
        const event = new CustomEvent('change-settings', { detail: { state: this.state } });
        document.dispatchEvent(event);
        if (e)
            e.preventDefault()

        if (this.state.status !== 'dirty') {
            return
        }

        if (
            !this.state.settings.is_public &&
            !this.state.settings.allow_knocking &&
            !this.state.uncheckPKAccepted
        ) {
            this.setState({ uncheckPKAccepted: true }, () => {
                ModalActions.SetModalWithParams('UncheckPublicAndKnocking', { prevState: this.state, cb: () => this.saveNow() })
            })
        } else {
            this.setState({ status: 'saving' })

            if (GlobalState.modal.prevState) {
                ModalActions.SetParams({ prevState: null, cb: null })
            }

            try {
                const res = await LoopApi(null, 'ChangeMeetingSettings', {
                    settings: this.state.settings,
                })
                if (!res.settings) {
                    console.error(res)
                    return this.setState({ status: 'dirty' })
                }

                if (this.state.audio_input_device_id !== localStorage.getItem('audio_input_device_id') && this.state.audio_input_device_id) {
                    const event = new CustomEvent(`AUDIO_DEVICE_CHANGE`, { detail: { audio_input_device_id: this.state.audio_input_device_id } })
                    localStorage.audio_input_device_id = this.state.audio_input_device_id
                    window.dispatchEvent(event)
                }

                if (this.state.audio_output_device_id !== localStorage.getItem('audio_output_device_id') && this.state.audio_output_device_id) {
                    const event = new CustomEvent(`AUDIO_OUTPUT_DEVICE_CHANGE`, { detail: { audio_output_device_id: this.state.audio_output_device_id } })
                    localStorage.audio_output_device_id = this.state.audio_output_device_id
                    window.dispatchEvent(event)

                    Array.from(document.getElementsByTagName('audio')).forEach(audio => {
                        audio.setSinkId(localStorage.audio_output_device_id)
                    })
                }

                if (this.state.video_input_device_id !== localStorage.getItem('video_input_device_id') && this.state.video_input_device_id) {
                    const event = new CustomEvent(`VIDEO_DEVICE_CHANGE`, { detail: { video_input_device_id: this.state.video_input_device_id } })
                    localStorage.video_input_device_id = this.state.video_input_device_id
                    window.dispatchEvent(event)
                }

                if (!!this.state.changepassword) {
                    await LoopApi(null, 'ChangeMeetingPassword', {
                        password: this.state.password,
                    })
                }


                const shouldToggle = (!this.state.isFullscreen && window.matchMedia('(display-mode: fullscreen)').matches) || (this.state.isFullscreen && !window.matchMedia('(display-mode: fullscreen)').matches)
                if (shouldToggle && screenfull.isEnabled) {
                    screenfull.toggle()
                }
                this.setState({ settings: res.settings, status: 'saved' })

                this.props.closeModal()
            } catch (e) {
                console.error(e)
                this.setState({ status: 'dirty' })
            }
        }
    }

    setPassword(event) {
        this.setState({ password: event.target.value || null })
    }

    render() {
        let button = <StyledButton disabled className="button primary" type="submit" value="Save" />
        if (this.state.status === 'dirty') {
            button = <StyledButton className="button primary" type="submit" value="Save" />
        } else if (this.state.status === 'saving') {
            button = <StyledButton disabled className="button primary" value="Saving..." />
        } else if (this.state.status === 'saved') {
            button = <StyledButton disabled className="button primary" value="Saved" />
        }

        const callActive = this.state.connectedUserIds.length === undefined ? (Object.keys(this.state.connectedUserIds) || []).filter((key) => !isNaN(parseInt(key))).length : this.state.connectedUserIds.length

        return (
            <Modal closeModal={this.props.closeModal} contentStyle={{ overflowY: 'hidden' }}>
                <Container className="topbar-modal">
                    <Title className="header">Room Settings</Title>
                    <Subtitle className="subheader">Change how your room looks and behaves</Subtitle>
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        autoHeight
                        autoHeightMax="calc(80vh - 200px)"
                    >
                        <Form onSubmit={this.saveNow}>
                            {
                                this.state.db_meeting.creator_user_id === this.state.jwt.data._id &&
                                <Field>
                                    <Label htmlFor="calling_provider">Sound Quality {callActive ? <span className="info-icon" title="In order to change the Sound Quality, please ask all Meeting participants (including Host) to leave the Meeting while the Host access the Room Setting and changes the Sound Quality for everyone. The other participants can rejoin once the Host restarts the meeting."><InfoIcon /></span> : ''}</Label>
                                    <Dropdown
                                        name="calling_provider"
                                        value={this.state.settings.calling_provider}
                                        items={CALL_PROVIDERS}
                                        inputWidth={100}
                                        onChange={this.handleSelect}
                                        disabled={callActive || this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                    />
                                </Field>
                            }
                            {/* {
                                this.state.db_meeting.creator_user_id === this.state.jwt.data._id &&
                                <Field>
                                    <Label htmlFor="widget_display_method">Sync Display Method</Label>
                                    <Dropdown
                                        name="widget_display_method"
                                        value={this.state.settings.widget_display_method}
                                        items={WIDGET_DISPLAY_METHODS}
                                        inputWidth={64}
                                        onChange={this.handleSelect}
                                        disabled={this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                    />
                                </Field>
                            } */}
                            {
                                this.state.db_meeting.creator_user_id === this.state.jwt.data._id &&
                                <Field>
                                    <Label htmlFor="is_public">Public Room</Label>
                                    <Checkbox
                                        name="is_public"
                                        checked={!!this.state.settings.is_public}
                                        onChange={this.handleChange}
                                        disabled={this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                    />
                                </Field>
                            }
                            {
                                this.state.db_meeting.creator_user_id === this.state.jwt.data._id &&
                                <Field>
                                    <Label htmlFor="transcribe">Transcribe Meeting</Label>
                                    <Checkbox
                                        name="transcribe"
                                        checked={!!this.state.settings.transcribe}
                                        onChange={this.handleChange}
                                        disabled={callActive || this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                    />
                                </Field>
                            }
                            {
                                this.state.db_meeting.creator_user_id === this.state.jwt.data._id &&
                                <Field>
                                    <Label htmlFor="record">Allow Recording</Label>
                                    <Checkbox
                                        name="record"
                                        checked={!!this.state.settings.record}
                                        onChange={this.handleChange}
                                        disabled={callActive || this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                    />
                                </Field>

                            }

                            {!this.state.settings.is_public && this.state.db_meeting.creator_user_id === this.state.jwt.data._id ? (
                                <Field>
                                    <Label htmlFor="allow_knocking">Allow Knocking</Label>
                                    <Checkbox
                                        name="allow_knocking"
                                        checked={!!this.state.settings.allow_knocking}
                                        onChange={this.handleChange}
                                        disabled={this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                    />
                                </Field>
                            ) : null}
                            {
                                !this.state.settings.ghost_mode && this.state.settings.is_public && this.state.db_meeting.creator_user_id === this.state.jwt.data._id && this.state.jwt.data.type !== 'guest' && (
                                    <React.Fragment>
                                        <FieldWrapper>
                                            <Checkbox
                                                name="changepassword"
                                                checked={!!this.state.changepassword}
                                                onChange={this.handleChangeState}
                                                disabled={this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}
                                            />
                                            {this.state.db_meeting.password ? 'Change Password?' : 'Create Password?'}
                                        </FieldWrapper>
                                        {
                                            this.state.changepassword &&
                                            <React.Fragment>
                                                <FormField
                                                    data-lpignore="true"
                                                    type="password"
                                                    autocomplete="off"
                                                    id="search-in-name-to-throw-off-pass-managers"
                                                    className="topbar border-light"
                                                    placeholder="Password"
                                                    value={this.state.password}
                                                    onChange={this.setPassword}
                                                />
                                                <div className="error">Note: Leave it blank to remove password</div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                )
                            }
                            {/* {!this.state.settings.ghost_mode && this.state.settings.is_public && this.state.db_meeting.creator_user_id === this.state.jwt.data._id && (
                                <LinkButton type="button" onClick={this.SetPassword} disabled={this.state.db_meeting.creator_user_id !== this.state.jwt.data._id}>
                                    {this.state.db_meeting.password ? 'Change Password' : 'Create Password'}
                                </LinkButton>
                            )} */}
                            {button}
                        </Form>
                    </Scrollbars>
                </Container>
            </Modal>
        )
    }
}


const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 20px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 352px;
	z-index: 3;
    overflow-y: initial;
    max-height: 80vh;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Subtitle = styled.div`
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: center;
`

const Form = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
    height: 100%;
    padding: 10px;
    .cancel-btn {
        text-decoration: underline;
        text-align: center;
        cursor: pointer;
        margin-top: 5px;
        padding: 10px;
        font-weight: 500;
        font-size: 13px;
    }

    .error {
        font-size: 11px;
        margin: 10px 5px;
        text-align: left;
    }
`

const FormField = styled.input`
  font-size: 16px;
  border: 1px solid;
  padding: 10px !important;
  border-radius: 999px;
`

const LinkButton = styled.p`
    background: none;
    border: none;
    text-decoration: underline;
    padding: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    cursor: pointer;

    ${props => props.disabled ? 'pointer-events: none; opacity: 0.5;' : ''}
`

const StyledButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 13px !important;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;
    text-align: center;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const Field = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
`

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 10px;

    label {
        margin-right: 5px;
    }
`

const Label = styled.label`
    display: flex;
    align-items: center;
    
    .info-icon {
        cursor: pointer;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
