import styled from "styled-components";
import FlipMove from 'react-flip-move'

export const FormFieldWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	border: 1px solid;
	padding: 8px;

	input {
		margin-bottom: 0;
		border: none;
		outline: none;
		padding-left: 10px;
		flex: 1;
		background: none;
	}
`

export const Container = styled(FlipMove)`
	position: relative;
	display: block;
	flex: 1;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 5px 0 30px;
	flex-direction: column;
	z-index: 1;
	overflow-y: auto;
	border-bottom: 1px solid;

	.no-workspace {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	&::-webkit-scrollbar-track {
		--webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: rgb(0, 0, 0, 0.2);
		padding-right: 12px;
		transform: translateX(-12px);
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.4);
	}
`

export const Header = styled.div`
    display: flex;
    align-items: center;

    .new-space {
        cursor: pointer;
        margin-right: 15px;
    }
    
    .label {
        flex: 1;
    }

	.new-space.loading {
		cursor: default;
		margin-bottom: 2px;
		margin-top: 2px;
        margin-right: 17px;
	}

	.loading {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}
`

export const WorkspaceItem = styled.div`
	align-items: center;
	&:not(:last-child) {
		margin-bottom: 10px;
	}

    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
		font-weight: 400;
		line-height: 16px;
		flex: 1;
        font-size: 12px;
    } 

	.more-option {
		margin-left: 10px;
		cursor: pointer;
	}
`

export const WorkspaceAvatar = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    min-width: 20px;
    margin-right: 10px;

    img {
        width: 100%;
        height: 100%;
		border-radius: 5px;
        object-fit: cover;
    }

	
`

export const DroppableWrapper = styled.div<{isDraggingOver?: boolean}>`
	position: relative;

	.delete-space {
		display: none;
		cursor: pointer;
		font-weight: 400;
		transition: all 0.3s ease-in-out;
	} 

	.delete-space-loader {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&:hover {
		.delete-space {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.no-room {
			display: none;
		}
	}

	.loading {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}

	${props => props.isDraggingOver && `
		.delete-space, .no-room, .no-room-in-general {
			display: none !important;
		}
	`}
`

export const SpaceName = styled.div`
    display: flex;
    align-items: center;
	margin: 15px 0 10px;

	.space-icon {
		font-size: 20px;
	}
    
    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
		font-weight: 400;
		margin: 0;
		line-height: 16px;
		display: flex;
		align-items: center;
		flex: 1;
		width: 100%;
		margin-right: 5px;

		input {
			flex: 1;
		}
    }

	&.general-room-wrapper {
		margin-top: 30px;
	}
`

export const SpaceLeft = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	
	.folder-action {
		cursor: pointer;
		margin-right: 5px;
	}

	input[type="text"] {
		background: none;
		outline: none;
		border: 1px solid;
		border-color: transparent;
		padding: 5px 7px;
		border-radius: 4px;
        font-size: 15px;
	}

	.room-title {
		font-weight: 500;
		font-size: 15px;
		margin-left: 10px;
	}

	.new-room-icon {
		cursor: pointer;
		margin-left: 2px;
	}
`
export const SpaceRight = styled.div`
	margin-right: 10px;
	.new-room-icon {
		cursor: pointer;
		margin-left: 2px;
	}

	.loading {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}
`

export const NoRooms = styled.div`
	text-align: center;
    opacity: 0.2;
    font-size: 12px;
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`


export const Meeting = styled.div<{small?: boolean, disabled?: boolean}>`
	font-weight: 400;
	font-size: 12px;
	cursor: pointer;
	padding: 0px 15px;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	position: relative;
	align-items: center;
	display: flex;
	margin-bottom: 5px;

	${props => props.small && `
		margin-bottom: 0px;
		padding: 0px 15px;
	`}

	${props => props.disabled && `
		opacity: 0.3;
		cursor: not-allowed;
	`}

	> p {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 10px;
	}

	&:hover {
		opacity: 0.5;
	}
`

export const IconWrapper = styled.div`
	width: 25px;
	height: 25px;
	object-fit: cover;
	border-radius: 50%;
	position: relative;

	img {
		width: 100%;
		border-radius: 50%;
	}
`

export const XLine = styled.div`
	position: absolute;
	height: 1px;
	width: 28px;
	border-radius: 30px;
	transform: rotate(135deg);
	pointer-events: none;
`

export const Bottom = styled.div`
	margin-top: 15px;
	${IconWrapper} {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	display: 'flex',
  
	// change background colour if dragging
	// background: isDragging ? "lightgreen" : "transparent",
  
	// styles we need to apply on draggables
	...draggableStyle
});

export const getListStyle = (isDraggingOver: boolean, theme: string, isCollapsed: boolean) => ({
	display: isCollapsed ? 'none' : 'block',
	background: !isDraggingOver ? 'transparent' : theme === 'Light' ? 'rgba(255, 130, 0, 0.2)' : 'rgba(0, 139, 255, 0.2)',
	padding: '1px 10px 1px 30px',
});

export const RoomTooltipWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`


export const OrganizationWrapper = styled.div`
	width: 160px;
	margin-bottom: 10px;

	&:not(.withBorder) {
		input.border-light.topbar.hover-container {
			border: none;
		}
	}

`