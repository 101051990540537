import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import moment from 'moment';

import SuccessErrorToast from '../components/Notifications/SuccessErrorToast';


import { sortDate } from '../helpers';
import Sagas from "../helpers/Sagas";
import LoopApi from '../helpers/LoopApi'
import useToastify from '../helpers/useToastify';
import { colorLog } from './_utils'
import { BaseProviderInstance } from "./types";
import { VariableCallingActions } from '../stores/VariableCallingStore';

const { GlobalState } = require('reflux')

export class DailyCoInstance extends BaseProviderInstance {
    private access_token: string

    _startTime: any
    room: any | null = null
    roomUrl: string | null = null
    in_connection_process = false
    callObject: DailyCall

    constructor(access_token: string, local_user_id: string) {
        super()
        this.access_token = access_token
        this.local_user_id = local_user_id

        this.room = null
        this.roomUrl = ''
    }

    get startTime() {
        return this._startTime;
    }

    private _log(message: string, payload?: any) {
        colorLog(message, 'DAILY', {
            payload,
            instance: {
                room: this.room,
                roomUrl: this.roomUrl,
            },
        })
    }

    private showToast({ message }: { message: string }) {
        useToastify({
            message: () => SuccessErrorToast({ message, type: 'error' }),
            position: "top-right",
            autoClose: 1500,
            closeButton: false,
            hideProgressBar: true,
            className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
            bodyClassName: "grow-font-size",
        })
    }

    async createCall(room_name: string) {
        this._log(' Creating room', { room_name })
        return await LoopApi(null, 'CreateDailyCoRoom', { room_name, properties: { meeting_join_hook: `${process.env.REACT_APP_BACKEND_URL || 'https://locahost:8000'}/api/webhooks/daily/callback` } })
          .then(({ room }) => {
            if(room?.error && room?.info && (room?.info || '')?.includes('already exists')) {
                this.roomUrl = `${process.env.REACT_APP_DAILYCO_URL || 'https://grapl.daily.co'}/${room_name}`
                return this.roomUrl
            } else if(room?.error) {
                throw new Error(room)
            }

            this.roomUrl = room?.url || ''
            return room?.url || ''
          })
          .catch((error) => {
            this._log('  Error creating room (Daily)', { error })
            this.roomUrl = null
          });
    }

    async startJoiningCall(url: string, constraints: MediaStreamConstraints): Promise<DailyCall> {
        const newCallObject = await DailyIframe.createCallObject();
        this.callObject = newCallObject
        this.roomUrl = url

        let audioSource: MediaTrackConstraints | boolean | any = {
            deviceId: { ideal: localStorage.getItem('audio_input_device_id') || 'default' },
            channels: 2,
            autoGainControl: false,
            echoCancellation: false,
            noiseSuppression: false
        }

        let videoSource: MediaTrackConstraints | boolean | any = { 
            deviceId: { ideal: localStorage.getItem('video_input_device_id') || 'default' }
        }
        
        this._log(' Starting/Joining Call', { url, constraints, audioSource, videoSource })
        await newCallObject.join({ url, audioSource, videoSource, userName: this.local_user_id });
        if(constraints?.video?.toString() === 'false') {
            newCallObject.setLocalVideo(false)
        }
        if(constraints?.audio?.toString() === 'false') {
            newCallObject.setLocalAudio(false)
        }

        return this.callObject
    }

    async getMeetingDetails(room_name: string) {
        try {
            const room = await LoopApi(null, 'GetDailyCoMeeting', {}, [['room_name', room_name]])
            return room
        }catch (err) {
            this._log(" Error getting the room details: ", err)
        }
    }

    async createNewRecordings(session_id: string, participants: any, meeting_name: string) {
        try {
            const objArray = [] as any[];
            Object.keys(participants).forEach(key => {
                if (key === 'local') {
                    return objArray.push({
                        key: key,
                        ...participants[key],
                        session_id: this.local_user_id || participants[key].session_id
                    })
                }

                return objArray.push({
                    key: key,
                    ...participants[key]
                })
            })
            const sortedP = objArray.slice().sort((a: any, b: any) => sortDate(a.joined_at, b.joined_at))
            const host = sortedP.map((p: any) => (p?.local ? this.local_user_id : p?.user_name))?.[0]

            this._log(' Getting meeting recording')
            const recording = await LoopApi(null, 'CreateRecording', {
                session_id,
                provider: 'DAILY',
                meeting_name,
                host
            }, []) 
            this._log(' Got meeting recording', { recording })

            VariableCallingActions.SetHost({ host })
            VariableCallingActions.SaveMeetingSession({ session_id })
            VariableCallingActions.SetLock({ meetingLocked: recording?.recordings?.data?.locked })
        }catch (err) {
            this._log("Error creating recording: ", err)
        }
    }


    public async connect(room_name: string, constraints: MediaStreamConstraints) {
        this._log(' Connecting to Daily', { room_name, constraints })
        // unsupported browser
        if(!DailyIframe.supportedBrowser().supported) {
            this._log(" Browser is not supported")
            this.showToast({ message: `Your browser does not support Daily. Please refresh the page to retry or use another browser.`})
            return
        }

        if(this.in_connection_process) return

        const dailyRoom = await this.createCall(room_name)
            .then((url) => this.startJoiningCall(url, constraints))
            .catch((err) => {
                this._log(` Unable to connect/join to Room: ${err?.message || err}`);
                this.teardown()
                return this.showToast({ message: `Unable to connect to Room: ${err?.message || err}. Please refresh the page.` })
            })

        
        if(dailyRoom) {
            this._log(' Getting meeting session')
            const { meetingSession } = await dailyRoom.getMeetingSession()
            this._log(' Got meeting session', { meetingSession })
            this._log(' Getting meeting participants')
            const participants = dailyRoom.participants()
            this._log(' Got meeting participants', { participants })
            this._log(' Getting meeting details')
            const meetingDetails  = await this.getMeetingDetails(room_name)
            this._log(' Got meeting details', { meetingDetails })
            this.createNewRecordings(meetingSession?.id || '', participants, room_name)
            const { data = [] } = meetingDetails
            const extracted = data.find((d: any) => d?.id === meetingSession?.id)
            this._startTime = extracted?.start_time || moment().unix()
        }
    }

    public async teardown() {
        try {
          this._log(' Destroying Room')
          await this.callObject?.leave()
          this.room = null;

          VariableCallingActions.SetRoom(null)
          VariableCallingActions.Leave()
    
          Sagas.Clients.Emit("main", "message", { action: "CallDisconnected" });
        } catch (e) {
          this._log(`Error during teardown: ${e.message}`);
        }
    }
}
