import React, { ReactElement, Fragment } from 'react'
import Reflux from 'reflux'
import Api, { Actions, Endpoints } from '../_Shared/Api'
import Login from '../_Shared/Login'
import Folder from './Folder'
import File from './File'
import DropboxHeader from './DropboxHeader'
import DownloadFileModal from "./DownloadFileModal"
import { IWidgetProps, AllWidgets, FolderStackItem, DropboxFile } from '../types'
import { WidgetContainer } from '../_Shared/Elements'
import Loader from '../_Shared/Loader'
import {makeSource, Frame} from "../_Shared/DownloadNow"

import { WidgetActions, MainStore } from '../../stores/MainStore'
import LoopApi from '../../helpers/LoopApi'

import styled from 'styled-components'

import { VariableCallingStore } from '../../stores/VariableCallingStore'
import { CallingInstanceState } from '../../calling/types'
import { ThemingStore } from '../../stores/ThemingStore'
import LoadingComponent from '../_Shared/LoadingComponent'

import MainFile from './MainFile'
import SyncModal from '../_Shared/SyncModal'


const { GlobalState } = require('reflux')

const syncedInfo = [
	'Read access to public files you choose',
	'Write access to public files you choose',
]

const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']

type Props = IWidgetProps<AllWidgets.Dropbox>

interface State {
	isAuthenticated: boolean
	pointerId: string
	folderStack: FolderStackItem[]
	files: any[],
	file: any,
	searchFilter: string
	viewFileSrc: string
	viewFileSrcOrig: string
	loading: boolean
	fileToDownload: DropboxFile | null,
	status: string
	color_scheme: string
	menu: string
	isLeftVisible: boolean
	isUploading: boolean
	modal: any
	toSaveLink: any
	hasSharedLink: boolean
	presentation: any
	lastView: {
		dropbox: null | {
			view: string
			state: any
			folderStack: any
		}
	}
}

export default class Dropbox extends Reflux.Component<typeof VariableCallingStore | typeof ThemingStore | typeof MainStore, Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			isAuthenticated: true,
			pointerId: '',
			folderStack: [{ path: "", name: "Dropbox" }],
			files: [],
			file: null,
			viewFileSrc: "",
			viewFileSrcOrig: "",
			searchFilter: "",
			loading: false,
			fileToDownload: null,
			status: '',
			color_scheme: GlobalState.theming.color_scheme,
			menu: 'allfiles',
			isLeftVisible: true,
			isUploading: false,
			hasSharedLink: false,
			presentation: {
				dropbox: null
			},
			lastView: {
				dropbox: null
			},
			modal: {
				show: false,
				type: ''
			},
			toSaveLink: null,
		}

		this.stores = [VariableCallingStore, ThemingStore, MainStore]
		this.storeKeys = [
			'status',
			'color_scheme',
			'presentation',
			'lastView'
		]
	}

	last_auth_attempt = 0

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.external_token !== nextProps.external_token) {
			this.setState({ isAuthenticated: true })
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.external_token !== prevProps.external_token)
		this.restoreLastView()

		if(
			(
				this.props.data.src !== prevProps.data.src &&
				this.props.data.file === null &&
				this.state.presentation?.dropbox === 'viewing'
			) ||
			(
				this.props.data.userId &&
				!!this.props.users?.find(user => user.id === this.props.data.userId && user.status === 'offline') &&
				this.state.presentation?.dropbox === 'viewing'
			)
		) {
			WidgetActions.SetPresentation({
				sync: 'dropbox',
				val: null
			})
		}
	}

	componentDidMount() {
		if (this.props.external_token) {
			this.restoreLastView()
		}

		setTimeout(() => {
			if(
				this.props.data.file &&
				this.props.data.userId === this.props.userId &&
				this.state.presentation?.dropbox !== 'presenting'
			) {
				this.stopPresent()
			}
		}, 200)
	}

	//allways check authentication
	checkAuth(resp: any) {
		let error = null
		if(resp.error) {
			if(resp.error['.tag'] === 'expired_access_token' ||
			resp.error['.tag'] === 'invalid_access_token') {
				error = true
				this.setState({
					isAuthenticated: false,
				})
				if (
					this.props.external_token &&
					this.last_auth_attempt < Date.now() - 5000
				) {
					if (this.last_auth_attempt === -1) {
						this.last_auth_attempt = 0
					} else {
						this.last_auth_attempt = Date.now()
					}
					this.props.actions.TryRefreshToken('dropbox')
				}
				this.setState({loading: false})
			}

			if (resp.error.code) {
				switch (resp.error.code) {
					case 401:
					case 403:
						this.setState({
							isAuthenticated: false,
						})
						error = true
						this.setState({loading: false})
						break
					default:
						break
				}
			}
		}
		else
		this.setState({ isAuthenticated: true })
		return error
	}

	//start of api calls
	getFiles = async ({folder_path} : {folder_path?: string}) => {
		let folder = ''

		this.setState({ loading: true })
		if (folder_path) folder = folder_path
		try {
			const resp = await Api(Endpoints['Dropbox'], Actions['Files'], this.props.external_token, {
				body: {
					path: folder,
				},
			})

			if(this.checkAuth(resp))
			return

			const state = {
				files: resp.entries,
				pointerId: folder,
				loading: false,
			}

			this.setState(state)

			WidgetActions.SetLastView({
				sync: 'dropbox',
				data: {
					folderStack: this.state.lastView.dropbox?.folderStack ? this.state.lastView.dropbox?.folderStack : this.state.folderStack,
					view: 'folder',
					state: { folder_path }
				}
			})

		} catch (e) {
			this.setState({ loading: false })
			console.warn(e)
		}
		this.props.actions.UpdateSelf({ link: null })
	}

	fetchFile = async ({file} : {file: DropboxFile}) => {
		let resp
		const hasSharedLink = await Api(Endpoints['Dropbox'], Actions['ListShared'], this.props.external_token, {
			body: {
				path: file.id,
			},
		})

		if(this.checkAuth(hasSharedLink))
		return

		if(hasSharedLink?.links?.length) {
			resp = { ...hasSharedLink.links[0], hasSharedLink: true }
		} else {
			resp = await Api(Endpoints['Dropbox'], Actions['TemporaryLink'], this.props.external_token, {
				body: {
					path: file.path_lower,
				},
			})
		}

		if(this.checkAuth(resp))
		return

		return resp
	}

	download = async(file: DropboxFile) => {
		let resp
		const args = { path: `${file.path_lower}` }
		const dropboxArgs = this.httpHeaderSafeJson(args)
		resp = await Api(Endpoints['DropboxContent'], Actions['Download'], this.props.external_token, {
			isBlob: true,
			headerSettings: {
				contentType: false,
				headerConfig : {
					'Dropbox-API-Arg': dropboxArgs
				}
			}
		})
		return resp
	}

	revokeLink = async(url?: string) => {
		const torevoke = url || this.state.viewFileSrcOrig

		if(torevoke) {
			await Api(Endpoints['Dropbox'], Actions['RevokeLink'], this.props.external_token, {
				body: {
					url: torevoke,
				},
			})

			if(!url)
			this.setState({viewFileSrcOrig: ''})
		}

		return
	}

	downloadFile = async (file: DropboxFile) => {
		// this.setState({ loading: true })
		const resp: any = await this.download(file)
		if(resp) {
			const binaryData = [];
			binaryData.push(resp);
			const downloadUrl = URL.createObjectURL(new Blob(binaryData))
			const link = document.createElement('a')
			link.setAttribute('href', downloadUrl)
			link.setAttribute('download', file.name)

			if(CallingInstanceState.Connected === this.state.status)
			link.setAttribute('target', '_blank')

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

		}
		// this.setState({ loading: false })
	}

	viewFile = async (file: DropboxFile) => {
		this.setState({ loading: true })
		const resp = await this.fetchFile({file})
		if (resp.url) {

			const url = this.isOfficeFile(file.name || '') ?
									resp.url
										.replace('?dl=0', '?dl=1') :
									resp.url
										.replace('dropbox.com/s/', 'dropbox.com/s/raw/')
										.replace('?dl=0', '')

			const viewFileSrc = makeSource(url || '', file.name || '')
			const fileInfo = {
				file,
				resp,
				source: viewFileSrc
			}

			let hasSharedLink = false
			if(resp.hasSharedLink) {
				hasSharedLink = true
			}

			this.setState({ loading: false, viewFileSrc, viewFileSrcOrig: resp.url, file: fileInfo, hasSharedLink })

			WidgetActions.SetLastView({
				sync: 'dropbox',
				data: {
					view: 'file',
					state: { file },
					folderStack: this.state.folderStack
				}
			})

		} else {
			this.setState({ loading: false })
		}

		return resp
	}

	isOfficeFile = (filename: string) => {
		let isOffice = false
		if(filename) {
			const match = filename.match(/\.(\w+)$/)
			if (match?.[1] && office.includes(match[1].toLocaleLowerCase())) {
				isOffice = true
			}
		}

		return isOffice
	}

	presentFile = async (file: DropboxFile) => {
		try {
			this.setState({ loading: true })
			const resp = await this.fetchFile({file})
			if (resp.url) {
				const uri = resp.url
					.replace('dropbox.com/s/', 'dropbox.com/s/raw/')
					.replace('?dl=0', '')

				let url = uri
				if (
					!uri.startsWith(
						process.env.REACT_APP_API_URL || 'http://localhost:8000'
					)
				) {
					const resp = await LoopApi(null, 'GetUrlHash', undefined, [
						['url', uri],
					])
					if (resp.error) {
						return console.error(resp)
					}
					url = resp.url
				}
				this.setState({ loading: false })
				WidgetActions.CreateOrUpdateWidget(
					{
						name: 'pdfpresenter',
						url: {url},
						scrollPerc: 0,
						page: 1,
					},
					true
				)
				LoopApi('main', 'SetCurrentWidget', { currentWidget: 'pdfpresenter', localPush: true })
			} else {
				this.setState({ loading: false })
			}
		} catch (e) {
			this.setState({ loading: false })
			console.error(e)
		}
	}

	setFileToDownload = (file: DropboxFile | null) => {
		this.setState({
			fileToDownload: file
		})
	}

	addFolderStackItem = (folderStackItem: FolderStackItem) => {
		const stack = [...this.state.folderStack, folderStackItem]
		this.setState(prevState => ({
			...prevState,
			searchFilter: "",
			folderStack: stack
		}))

		let dataToSet = { folderStack: stack }
		WidgetActions.SetLastView({
			sync: 'dropbox',
			data: {
				...this.state.lastView.dropbox,
				...dataToSet
			}
		})
	}

	removeItemsAfterFolderPath = (folderPath: string) => {
		const lastFolderPathIndex = this.state.folderStack.findIndex(folder => folder.path === folderPath)
		this.setState(prevState => ({
			...prevState,
			searchFilter: "",
			viewFileSrc: "",
			folderStack: prevState.folderStack.slice(0, lastFolderPathIndex + 1)
		}))
	}

	searchFile = (fileName: string) => {
		if (this.state.files.length > 0) {
			this.setState(prevState => ({
				...prevState,
				searchFilter: fileName
			}))
		}
	}

	onPresent = () => {
		this.props.actions.UpdateSelf({
			src: this.state.viewFileSrc,
			userId: this.props.userId,
			file: this.state.file
		})
		this.changePresentationState('presenting')
	}

	stopPresent = async () => {
		if(this.checkStatusPresenter() && this.props.data.file) {
			this.props.actions.UpdateSelf({
				file: null,
				userId: null,
				src: ''
			})
			this.changePresentationState(null)
		}
	}

	startViewing = () => {
		this.changePresentationState('viewing')
	}

	stopViewing = () => {
		this.changePresentationState(null)
	}

	checkStatusPresenter = () => {
		const presenter = this.props.data.userId &&
			this.props.users?.find(user => user.id === this.props.data.userId && user.status === 'online')
		return !!presenter
	}

	changePresentationState = (val: any) => {
		WidgetActions.SetPresentation({
			sync: 'dropbox',
			val
		})
	}

	back = () => {
		this.setState({ viewFileSrc: "", file: null, hasSharedLink: false })

		const folderStack = this.state.folderStack
		const lastPath = folderStack[folderStack.length - 1].path
		this.getFiles({folder_path: lastPath})
		if(!this.state.hasSharedLink) {
			this.revokeLink()
		}
	}

	changeFolder = (folderPath: string) => {
		if(this.state.file) {
			this.setState({ viewFileSrc: "", file: null, hasSharedLink: false })
		}

		this.removeItemsAfterFolderPath(folderPath)
		this.getFiles({folder_path: folderPath})
		if(!this.state.hasSharedLink) {
			this.revokeLink()
		}
	}

	changeIndexState = (state: any, callback?: Function) => {
		this.setState(state, () => callback ? callback() : {})
  }

	setModalType = () => {
		let actionModal = () => {}
		let closeModal = () => this.setState({modal: {show: false, type: ''}})
		let typeModal = this.state.modal.type

		switch(this.state.modal.type) {
			case 'DBStopPresentation':
				actionModal = () => {this.stopPresent()}
			break
			case 'DBStopViewingPresentation':
				actionModal = () => {this.stopViewing()}
			break
			default:
				typeModal = ''
		}

		return !!typeModal ? <SyncModal
			actionModal={actionModal}
			closeModal={closeModal}
			type={typeModal}
		/> : null
	}

	upload = async(targetfiles: any) => {
		this.setState({isUploading: true})
		const files = Array.from(targetfiles)

		if(!files.length)
		return

		const generateResUpload = async(file: any) => {
			const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024
			const filename = file.name

			if(file.size < UPLOAD_FILE_SIZE_LIMIT) {
				const args = { path: `${this.state.pointerId}/${filename}` }
				const dropboxArgs = this.httpHeaderSafeJson(args)
				let resp = await Api(Endpoints['DropboxContent'], Actions['Upload'], this.props.external_token, {
					headerSettings: {
						contentType: false,
						headerConfig : {
							'Dropbox-API-Arg': dropboxArgs,
							'Content-type': 'application/octet-stream'
						}
					},
					body: file
				})
				return resp
			} else { // File is bigger than 150 Mb
				const maxBlob = 150 * 1000 * 1000
				const workItems = [];
        let offset = 0;

        while (offset < file.size) {
          const chunkSize = Math.min(maxBlob, file.size - offset);
          workItems.push(file.slice(offset, offset + chunkSize));
          offset += chunkSize;
        }

				const task = workItems.reduce((acc, blob, idx, items) => {
          if (idx == 0) {
            // Starting multipart upload of file
            return acc.then(async() => {
							const args = { close: false }
							const dropboxArgs = this.httpHeaderSafeJson(args)
							const response = await Api(Endpoints['DropboxContent'], Actions['UploadSession'], this.props.external_token, {
								urlAppend: '/start',
								headerSettings: {
									contentType: false,
									headerConfig : {
										'Dropbox-API-Arg': dropboxArgs,
										'Content-type': 'application/octet-stream'
									}
								},
								body: blob
							})
							return response.session_id
            });
          } else if (idx < items.length - 1) {
            // Append part to the upload session
            return acc.then(async(sessionId: any) => {
							const cursor = { session_id: sessionId, offset: idx * maxBlob }
							const args = { cursor, close: false }
							const dropboxArgs = this.httpHeaderSafeJson(args)
							await Api(Endpoints['DropboxContent'], Actions['UploadSession'], this.props.external_token, {
								urlAppend: '/append_v2',
								headerSettings: {
									contentType: false,
									headerConfig : {
										'Dropbox-API-Arg': dropboxArgs,
										'Content-type': 'application/octet-stream'
									}
								},
								body: blob
							})
							return sessionId
            });
          } else {
            // Last chunk of data, close session
            return acc.then(async(sessionId: any) => {
              const cursor = { session_id: sessionId, offset: file.size - blob.size }
              const commit = { path: `${this.state.pointerId}/${filename}`, mode: 'add', autorename: true, mute: false }
							const args = { cursor, commit }
							const dropboxArgs = this.httpHeaderSafeJson(args)
							const response = await Api(Endpoints['DropboxContent'], Actions['UploadSession'], this.props.external_token, {
								urlAppend: '/finish',
								headerSettings: {
									contentType: false,
									headerConfig : {
										'Dropbox-API-Arg': dropboxArgs,
										'Content-type': 'application/octet-stream'
									}
								},
								body: blob
							})
							return response
            });
          }
        }, Promise.resolve())

				return task
			}
		}

		const uploadPromises = files.map(f => generateResUpload(f))

		try {
			const response = await Promise.all(uploadPromises)
		} catch (err) {
			console.log(err)
		} finally {
			this.setState({isUploading: false})
			this.getFiles({folder_path: this.state.pointerId})
		}
	}

	httpHeaderSafeJson = (args: any) => {
		return JSON.stringify(args).replace(/[\u007f-\uffff]/g, this.getSafeUnicode);
	}

	getSafeUnicode = (c: any) => {
		const unicode = `000${c.charCodeAt(0).toString(16)}`.slice(-4);
		return `\\u${unicode}`;
	}

	restoreLastView = () => {
		if(this.state.lastView?.dropbox?.view) {
			const { view, state, folderStack } = this.state.lastView.dropbox
			this.setState({ folderStack })
			switch(view) {
				case 'folder':
					this.getFiles({folder_path: state.folder_path})
					break
				case 'file':
					this.viewFile(state.file)
					break
				default:
					break
			}
		} else {
			this.setState({folderStack: [{ path: "", name: "Dropbox" }]})
			this.getFiles({})
		}
	}

	render() {
		//Presentation
		const isPresenterOnline = !!this.props.data.file && this.checkStatusPresenter()
		const isPresenter = isPresenterOnline && this.props.data.userId === this.props.userId
		const viewPresenting = isPresenterOnline && !isPresenter && this.state.presentation?.dropbox === 'viewing'
		const unauthenticatedViewer = isPresenterOnline && (!this.props.external_token || !this.state.isAuthenticated)
		const isPresentingOrViewing = viewPresenting || isPresenter || unauthenticatedViewer

		if ((!this.props.external_token || !this.state.isAuthenticated) && !unauthenticatedViewer) {
			return (
				<Login
					name="Dropbox"
					logo={require('./icon.svg')}
					loginKey="dropbox"
					syncedInfo={syncedInfo}
				/>
			)
		}

		const filteredFiles = this.state.files.filter(file => (
			file && file.name.toLowerCase().includes(this.state.searchFilter.toLowerCase())
		))

		let content: ReactElement =  (
			<Folder
				files={filteredFiles}
				getFiles={this.getFiles}
				folderStack={this.state.folderStack}
				addFolderStackItem={this.addFolderStackItem}
				downloadFile={this.downloadFile}
				viewFile={this.viewFile}
				presentFile={this.presentFile}
				setFileToDownload={this.setFileToDownload}
				colorScheme={this.state.color_scheme}
				menu={this.state.menu}
				changeIndexState={this.changeIndexState}
				isLeftVisible={this.state.isLeftVisible}
				upload={this.upload}
				onPresent={this.onPresent}
				isUploading={this.state.isUploading}
				isPresenterOnline={isPresenterOnline}
			/>
		)

		if (this.props.data.link) {
			content = <File link={this.props.data.link} name={this.props.data.filename} />
		}

		if (isPresentingOrViewing || this.state.viewFileSrc) {
			content = (
				<MainFile
					src={isPresentingOrViewing ? this.props.data.src : this.state.viewFileSrc}
					file={isPresentingOrViewing ? this.props.data.file : this.state.file}
				/>
			)
		}

		return (
			<WidgetContainer {...((this.state.file || this.props.data.file) ? { height: '100%' } : {})}>
				{this.state.loading ? <Loading><LoadingComponent /></Loading> :
					<Fragment>
					{this.state.fileToDownload && (
						<DownloadFileModal
							file={this.state.fileToDownload}
							downloadFile={this.downloadFile}
							setFileToDownload={this.setFileToDownload}
						/>
					)}

					<DropboxHeader
						folderStack={this.state.folderStack}
						searchFile={this.searchFile}
						searchFilter={this.state.searchFilter}
						back={this.back}
						startViewing={this.startViewing}
						onPresent={this.onPresent}
						colorScheme={this.state.color_scheme}
						file={isPresentingOrViewing ? this.props.data.file : this.state.file}
						isPresenterOnline={isPresenterOnline}
						isPresenter={isPresenter}
						viewPresenting={viewPresenting}
						unauthenticatedViewer={unauthenticatedViewer}
						changeIndexState={this.changeIndexState}
						hasPresentingFile={!!this.props.data.file}
						isPresentingOrViewing={isPresentingOrViewing}
						changeFolder={this.changeFolder}
					/>

					{content}
					{this.state.modal.show && this.setModalType()}
					</Fragment>
				}
			</WidgetContainer>
		)
	}
}

const Loading = styled.div`
	width: 100%;
	height: 100%;
	z-index: 2;
`
