import React, { FormEvent } from 'react'
import { useHistory } from 'react-router'

import { LoginForm, LogoIcon, LogoWrapper, SubmitButton, SubTitle, Title } from './styled'
import ForgotPasswordIcon from './forgotpassword.svg'

function ExpiredToken({ error, msg }: { error?: boolean, msg?: string }) {
    const history = useHistory()

    const onExpiredToken = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        history.push('/login')
    }

  
  return (
    <LoginForm onSubmit={onExpiredToken} style={{ minHeight: '200px' }}>
        <LogoWrapper>
            <LogoIcon src={ForgotPasswordIcon} alt={'Reset Password'} />
        </LogoWrapper>
        <Title>
            { error ? 'Uh! Oh!' : 'Awesome!' }
        </Title>
        <SubTitle>{ msg }</SubTitle>
        <SubmitButton
            className="button primary"
            value="Ok"
            type="submit"
            style={{ marginBottom: 0 }}
        />
    </LoginForm>
  )
}

export default ExpiredToken