import React, {useRef, useEffect} from 'react'
import {Track} from 'twilio-video'
import { CallingProviderName } from '../../../../../../contexts/types'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { normalizedCallHelpers } from '../../../../Sidebar/UsersInCall/types'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack'
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions'
import {IVideoTrack, TwilioTrackType} from '../../VideoProvider/types'

interface VideoTrackProps {
  track: IVideoTrack
  isLocal?: boolean
  priority?: Track.Priority | null
  isFlipped?: boolean
  onboarding?: boolean
}

export default function VideoTrack(props: VideoTrackProps) {
  const {
    track,
    isLocal,
    priority,
    isFlipped = true,
    onboarding
  } = props
  const ref = useRef<HTMLVideoElement>(null!)
  const mediaStreamTrack = useMediaStreamTrack(track)
  const dimensions = useVideoTrackDimensions(track, onboarding)
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0)
  const { callProvider } = useMeetingContext()
  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  useEffect(() => {
    const el = ref.current
    el.muted = true
    if(isTwilio || onboarding) {
      if ((track as TwilioTrackType).setPriority && priority) {
        (track as TwilioTrackType)?.setPriority?.(priority)
      }
      (track as TwilioTrackType).attach(el)
      return () => {
        (track as TwilioTrackType).detach(el)
  
        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null
  
        if ((track as TwilioTrackType).setPriority && priority) {
          // Passing `null` to setPriority will set the (track as TwilioTrackType)'s priority to that which it was published with.
          (track as TwilioTrackType).setPriority?.(null)
        }
      }
    } else if(callProvider === CallingProviderName.DailyCo) {
      if(!track) return
      if(el.srcObject) {
        const tracks = (el.srcObject as any).getTracks();

        tracks.forEach(function(track: any) {
          track.stop();
        });
        el.srcObject = null
      }
      const stream = new MediaStream()
      track && normalizedCallHelpers.addTrackToStream(stream, track as MediaStreamTrack)
      el.autoplay = true
      el.playsInline = true
      el.srcObject = stream
      return () => {
        el.srcObject= null
      }
    }
  }, [track, priority])

  // The local video track is mirrored if it is not facing the environment.
  // const isFrontFacing =
  //   mediaStreamTrack?.getSettings().facingMode !== 'environment'

  let rotation = isFlipped ? 180 : 0

  return (
    <video
      ref={ref}
      style={{
        width: '100%',
        height: '100%',
        transform: `rotateY(${rotation}deg)`,
        objectFit:
          isPortrait || ((track as TwilioTrackType)?.name || (track as MediaStreamTrack)?.label)?.includes('screen')
            ? ('contain' as const)
            : ('contain' as const),
      }}
      className="container contentimp"
    />
  )
}
