import React from 'react';
import Api, { Actions, Endpoints } from '../../../_Shared/Api';
import styled from 'styled-components';
import Sidebar from './sidebar';
import ViewBody from './body';

const ViewDeal = (props: any) => {
    const { UpdateSelf } = props.actions;

    const fetchDeal = async (id: string) => {
        const deal = await Api(Endpoints['Hubspot'], Actions['ViewDeal'], props.external_token, {}, [['dealId', id]]);
        UpdateSelf({ deal });
    };

    React.useEffect(() => {
        const { deal_id } = props.data;
        fetchDeal(deal_id);
    }, []);

    return (
        <ViewContainer>
            <div className="sidebar">
                <Sidebar {...props} />
            </div>
            <div className="body">
                <ViewBody {...props} />
            </div>
        </ViewContainer>
    );
};

export default ViewDeal;

const ViewContainer = styled.div`
    display: flex;
    flex-direction: row;
    .sidebar {
        margin-right: 5%;
    }
    .body {
        width: 100%;
    }
`;
