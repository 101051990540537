import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import { TiDelete } from '@react-icons/all-files/ti/TiDelete'
import { FaUser } from '@react-icons/all-files/fa/FaUser'
import { FiBell } from '@react-icons/all-files/fi/FiBell'
import { IoIosAddCircle } from '@react-icons/all-files/io/IoIosAddCircle'

import { WidgetContainer } from '../_Shared/Elements'
import WidgetHeader, { Subtitle, Title } from '../_Shared/WidgetHeader'
import FormField from '../_Shared/FormField'
import Dropdown from '../_Shared/DropdownWrapper'
import Select from '../_Shared/Select'
import RDatepicker from '../_Shared/Datepicker'

import LoopApi from '../../helpers/LoopApi' 
import ShowNotification from '../../helpers/Notification'
import { IWidgetProps, AllWidgets } from '../types'
import { LoadingComponentWrapper } from './'
import LoadingComponent from '../_Shared/LoadingComponent'
import useToastify from '../../helpers/useToastify'
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast'
import useClickupContext from './useClickupContext'
import { WidgetActions } from '../../stores/MainStore'

const { GlobalState } = require('reflux')

interface State {
    name: string
    description: string
    status: any
    due_date: any
    date_closed: any
    assignees: any
    removedAssignees: any[]
    addedAssignees: any[]
}

function Task() {
    const { authFailed, external_token, clickupData } = useClickupContext()

    const [formData, setFormData] = useState<State>({ name: '', addedAssignees: [], status: '', due_date: '', date_closed: null, description: '', assignees: [], removedAssignees: [] })
    const [members, setMembers] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)

    const getTask = async() => {
        if (!external_token) { return }
        setFetching(true)
        await LoopApi(null, 'GetClickupOneTask', {}, [['task_id', clickupData?.currentTask?.id]])
        .then((resp) => {
            setFetching(false)
            if (resp.error) {
                return authFailed()
            }

            setFormData({ ...resp, status: resp?.status?.status, addedAssignees: resp?.assignees })
        })
        .catch((err) => {
            setFetching(false)
            console.error(err)
            return authFailed()
        })
    }

    const getListMembers = async() => {
        if (!external_token) { return }

        setFetching(true)
        await LoopApi(null, 'GetClickupListMembers', {}, [['list_id', clickupData?.currentList?.id]])
        .then((resp) => {
            setFetching(false)
            if (resp.error) {
                return authFailed()
            }
            setMembers(resp.members)
        })
        .catch((err) => {
            setFetching(false)
            console.error(err)
            return authFailed()
        })
    }

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const addAssignee = (assignee: any, e: any) => {
        e.stopPropagation()
        const existing = (formData.addedAssignees || []).filter((a: any) => a.id === assignee.id)
        if (existing && existing.length > 0) return

        const assignees = [...formData.assignees, { ...assignee }]
        const addedAssignees = [...(formData.addedAssignees || []), { ...assignee }]
        setFormData({ ...formData, addedAssignees })
    }

    const removeAssignee = (assignee: any, e: any) => {
        e.stopPropagation()
        const addedAssignees = formData.addedAssignees.filter((a: any) => a.id !== assignee.id)
        const removedAssignee = formData.assignees.find((a: any) => a.id === assignee.id)
        const stateRemoved = (formData?.removedAssignees || []).filter((a: any) => a.id !== assignee.id)
        let removedAssignees = [...stateRemoved]
        if (removedAssignee) {
            removedAssignees = [...stateRemoved, removedAssignee]
        }
        setFormData({ ...formData, addedAssignees, removedAssignees })
    }

    const taskQueryConfig = () => {
        const add = (formData?.addedAssignees || []).map((assignee: any) => assignee.id)
        const rem = (formData?.removedAssignees || []).map((assignee: any) => assignee?.id)
        const assignees = { add, rem }
        return clickupData?.currentTask?.id
            ? {
                action: 'UpdateClickupTask',
                data: {
                    name: formData?.name,
                    description: formData?.description,
                    status: formData?.status,
                    due_date: formData?.due_date || null,
                    assignees: assignees
                },
                params: [['task_id', clickupData?.currentTask.id || '']],
                success: () => useToastify({
                    message: () => SuccessErrorToast({ message: 'Successfully updated task.' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                }) 
            }
            : {
                action: 'CreateClickupTask',
                data: {
                    name: formData.name,
                    description: formData.description,
                    status: formData.status,
                    due_date: formData.due_date || null,
                    assignees: add
                },
                params: [['list_id', clickupData?.currentList?.id || '']],
                success: () => useToastify({
                    message: () => SuccessErrorToast({ message: 'Successfully created task.' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                }) 
            }
    }

    const createOrUpdateTask = async() => {
        const { action, data, params, success } = taskQueryConfig()

        if (!formData.name) {
            useToastify({
                message: () => SuccessErrorToast({ message: 'Task name is required.', type: 'error' }),
                position: "top-right",
                autoClose: 1500,
                closeButton: false,
                hideProgressBar: true,
                className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                bodyClassName: "grow-font-size",
            }) 
            
            setLoading(false)
            return //console.log('Invalid Title')
        }
        await LoopApi(null, action, { ...data }, params)
            .then((resp) => {
                if (resp.error) {
                    return authFailed()
                }
                // debugger;

                // this.setState({ ...resp })
                success()
                handleBack()
            })
            .catch((err) => {
                console.error(err)
                return authFailed()
            })
        setLoading(false)
    }

    const handleBack = () => {
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                view: 'taskslist',
                currentTask: null,
            }
        })
    }

    useEffect(() => {
        if (clickupData?.currentTask?.id) {
            getTask()
        } else {
            const { members: m, ...rest }: any = clickupData?.currentTask
            setFormData({...formData, ...rest, status: rest?.status?.status, addedAssignees: rest?.assignees}) 
        }
    }, [clickupData?.currentTask?.id])

    useEffect(() => {
        if (clickupData?.currentList?.id) {
            //console.log("LIST: ", data)
            getListMembers()
        }
    }, [clickupData?.currentList?.id])

    const AddAssignee = () => {
        return (members || []).map((user: any) => {
            const bordered = (formData.addedAssignees || []).filter((a: any) => a.id === user.id).length > 0
            return <UserWrapper style={{ padding: '2px 10px' }} onClick={(e) => addAssignee(user, e)}>
                <ImageWrapper
                    title={user?.username || user?.email || ''}
                    color={user?.color || 'rgb(123, 104, 238)'}
                    style={{ marginRight: '5px' }}
                    bordered={bordered}
                >
                    {bordered ? <TiDelete className="remove-assignee bottom" onClick={(e: React.SyntheticEvent) => removeAssignee(user, e)} /> : ''}
                    {user.profilePicture ? <img src={user.profilePicture} alt={user.username || user.email} /> : <span className="assignee-name">{user.initials}</span>}
                </ImageWrapper>
                {user.username || user.email}
            </UserWrapper>
        })
    }

    const firstThree = [...(formData?.addedAssignees || [])]

    if (firstThree.length > 3) {
        firstThree.length = 3
    }


    let valueClassName = ''
    const finalOutput = formData.due_date ? moment(formData.due_date, 'x').calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'MMM DD',
        sameElse: 'MMM DD'
    }) : ''

    if (finalOutput === 'Today' || finalOutput === 'Tomorrow') valueClassName = 'success'
    if ((finalOutput === 'Yesterday' && !!!formData.date_closed) || (formData.due_date && moment(formData.due_date, 'x').isBefore(moment()) && !!!formData.date_closed)) valueClassName = 'error'

    return (
        <WidgetContainer padding="30px" height="100%">
            <WidgetHeader
                name={
                    <NameHeader>
                        <Ico>
                            <img src={require("./icon.svg")} />
                        </Ico>
                        <span className="desktop">Task</span>
                    </NameHeader>
                }
                subname="You can edit and update the task details here"
                subnameClassName="desktop"
                end={
                    <React.Fragment>
                        <BackButton className="button" onClick={(e) => {
                            WidgetActions.SetLastView({
                                sync: 'clickup',
                                data: {
                                    ...clickupData,
                                    view: 'taskslist',
                                    currentTask: null,
                                }
                            })
                        }}>Back</BackButton>
                        <Button disabled={loading} className={`button default`} onClick={() => { setLoading(true); createOrUpdateTask() }}>{clickupData?.currentTask?.id ? 'Update' : 'Create'}</Button>
                    </React.Fragment>
                }
            />
            <NameHeader className="mobile imp">
                <Title> {'Task'} </Title>
                <Subtitle className="subheader">{'You can edit and update the task details here'}</Subtitle>
            </NameHeader>
            {
                loading ? 
                <LoadingComponentWrapper className="rounded inner topbar" style={{ marginTop: '30px' }}>
                    <LoadingComponent />
                </LoadingComponentWrapper> :
                <TaskWrapper className="topbar rounded inner">
                    <TextareaAutosize
                        name="name"
                        value={formData.name}
                        className="textarea hover-container task-title"
                        placeholder="Task Name"
                        onResize={(e) => { }}
                        onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}
                    />
                    <FormField
                        label="Assignee"
                        field={
                            <div style={{ display: 'flex' }}>
                                {
                                    formData.addedAssignees && formData.addedAssignees.length > 0 ?
                                        <Assignees>
                                            <Dropdown items={AddAssignee} left addTop={15} width="250px" scrollable>
                                                <ImageWrapper className="placeholder" title={'Assign'} color="transparent">
                                                    <IoIosAddCircle className="add-assignee" />
                                                    <span className="add-assignee-logo"><FaUser /></span>
                                                </ImageWrapper>
                                            </Dropdown>
                                            {
                                                firstThree.length === 3 && formData.addedAssignees.length > 3 ? <ImageWrapper className="container" title={'Other assignees'} color="rgba(0, 0,0,0.4)">
                                                    +{formData.addedAssignees.length - 3}
                                                </ImageWrapper> : ''
                                            }
                                            {
                                                [...firstThree].reverse().map((assignee: any) => (
                                                    <ImageWrapper title={assignee?.username || ''} color={assignee?.color || 'rgb(123, 104, 238)'}>
                                                        <TiDelete className="remove-assignee" onClick={(e) => removeAssignee(assignee, e)} />
                                                        {assignee?.profilePicture ? <img src={assignee?.profilePicture} alt={assignee?.username} /> : <span className="assignee-name">{assignee?.initials}</span>}
                                                    </ImageWrapper>
                                                ))
                                            }
                                        </Assignees> : <Assignees>
                                            <Dropdown items={AddAssignee} left addTop={15} width="250px" scrollable>
                                                <ImageWrapper className="placeholder" title={'Assign'} color="transparent">
                                                    <IoIosAddCircle className="add-assignee" />
                                                    <span className="add-assignee-logo"><FaUser /></span>
                                                </ImageWrapper>
                                            </Dropdown>
                                        </Assignees>
                                }
                            </div>
                        }
                    />
                    <FormField
                        label="Status"
                        field={
                            <div>
                                <Select
                                    hoverEffect
                                    fitContent
                                    name="status"
                                    defaultValue={formData.status || ''}
                                    placeholder={"Select a status"}
                                    onChange={handleChange}
                                    optionsList={(clickupData?.currentList?.statuses || [{ status: 'to do' }]).map((status: any) => {
                                        return {
                                            value: status.status,
                                            label: (status?.status || '').toUpperCase()
                                        }
                                    })}
                                />
                            </div>
                        }
                    />
                    <FormField
                        label="Due date"
                        field={
                            <div>
                                <RDatepicker
                                    name="due_on"
                                    wrapperClassName="datepicker"
                                    placeholderText="No due date"
                                    displayOnly
                                    enableIcon
                                    onChangeRaw={(e: any) => e.preventDefault()}
                                    onSelect={(date) => setFormData({ ...formData, due_date: moment(date).valueOf() })}
                                    selected={formData.due_date ? moment(formData.due_date, 'x').toDate() : ''}
                                    onChange={() => { }}
                                    readOnly
                                    dateFormat="MMMM dd"
                                    valueClassName={valueClassName}
                                />
                            </div>
                        }
                    />
                    <FormField
                        label="Description"
                        field={
                            <TextareaAutosize
                                style={{ width: 'calc(100% - 12px)' }}
                                value={formData.description}
                                className="textarea over-container-focus hover-container focus"
                                placeholder="Add more detail to this task..."
                                rows={4}
                                onResize={(e) => { }}
                                onChange={(e: any) => setFormData({ ...formData, description: e.target.value })}
                            />
                        }
                    />
                </TaskWrapper>
            }
        </WidgetContainer>
    )
}

const NotificationWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0px !important;
    cursor: pointer;

    transform: rotate(-45deg);
    animation: shake 1.5s infinite ease-in-out;
    @keyframes shake {
        0% { transform:rotate(-0deg) scale(1) skew(1deg); }
        10% { transform:rotate(-15deg) scale(1) skew(1deg); }
        20% { transform:rotate(15deg) scale(1) skew(1deg); }
        30% { transform:rotate(-15deg) scale(1) skew(1deg); }
        40% { transform:rotate(15deg) scale(1) skew(1deg); }
        50% { transform:rotate(-0deg) scale(1) skew(1deg); }
        100% { transform:rotate(-0deg) scale(1) skew(1deg); }
    }
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;

    &.mobile {
        flex-direction: column;
        align-items: baseline;
        margin-top: 15px;
    }
`


const TaskWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px;
    margin-top: 30px;

    .task-title {
        font-size: ${props => props.theme.textLG};
    }

    > * {
        margin-top: 5px;
    }
`

const Button = styled.div<{disabled?: boolean}>`
    text-align: center;
    ${({disabled}) => disabled === false ? 'cursor: pointer;' : 'pointer-events: none;'} 
    font-weight: ${props => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const BackButton = styled.div<{disabled?: boolean}>`
    text-align: center;
    /* ${({disabled}) => disabled === false ? 'cursor: pointer;' : 'pointer-events: none;'}  */
    font-weight: ${props => props.theme.textBold};
    /* min-width: 100px; */
    margin: 0px 2px;
    border: none;
    width: fit-content;
    cursor: pointer;

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const ImageWrapper = styled.div<{ color?: string, bordered?: boolean}>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${props => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid ${props => props.color};
    ${props => props.bordered ? 'border: 2px solid #2D81FF;' : ''}
    font-weight: ${props => props.theme.textRegular};

    img {
        width: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        color: white;
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > ${ImageWrapper} {
        transition: 0.3s transform ease-out;
        &:not(.nohover):hover {
            z-index: 2;
            border: 1px solid white;
            transform: scale(1.1);
        }
    }
`

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 35px;
        border: none;
        align-self: center;
        margin: 0 14px;
    }
`

export default Task
