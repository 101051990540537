import React from 'react';
import Login from '../_Shared/Login';
import Api, { Actions, Endpoints } from '../_Shared/Api';
import Header from './header';
import { WidgetContainer, WidgetContent } from '../_Shared/Elements';
import WidgetHeader from '../_Shared/Header';
import { AllMetrics, TrafficBySource, DailyTraffic, WeeklyVisitorSession, AllVisitByGeo, AllVisitAllCampaign, AllWebPages, DailyAllLandingPages, AllBlogPosts } from './analytics';
import { CreateCompany, ListCompany, ViewCompany } from './company';
import { CreateContact, ViewContact, ListContacts } from './contacts';
import { ListReports } from './reports';
import { ListDeals, CreateDeal, ViewDeal } from './deals';
import styled from 'styled-components'; 
import { CreateTask, TaskList } from './tasks';

function Hubspot(props: any) {
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const { external_token } = props;
  const { UpdateSelf, TryRefreshToken } = props.actions;
  const { screen, contacts, companies, deals, reports } = props.data;

  const submitRef = React.useRef();

  React.useEffect(() => {
    //console.log('====== Hubspot props ======', props);
  }, [props]);

  React.useEffect(() => {
    if(external_token) initHubspot();
  }, [external_token]);

  React.useEffect(() => {
    if (contacts.status === 'error') return;
    if (companies.status === 'error') return;
    if (deals.status === 'error') return;
    // if (reports.status === 'error') return;
    setAuthenticated(true);
  }, [props.data]);

  const initHubspot = async () => {
    if (external_token) {
      getAccountDetails();
      getContacts();
      getCompanies();
      getDeals();
      getReports();
      //getAnalytics();
      getTasks();
    }
  };


  const getAccountDetails = async () => {
    const account = await Api(Endpoints['Hubspot'], Actions['GetAccountDetails'], props.external_token, {}, [['accessToken', props.external_token]]);
    UpdateSelf({ account });
  };


  const getContacts = async () => {
    const contacts = await Api(Endpoints['Hubspot'], Actions['GetContacts'], props.external_token);
    UpdateSelf({ contacts });
  };

  const getCompanies = async () => {
    const companies = await Api(Endpoints['Hubspot'], Actions['GetCompanies'], props.external_token);
    UpdateSelf({ companies });
  };

  const getDeals = async () => {
    const deals = await Api(Endpoints['Hubspot'], Actions['GetDeals'], props.external_token);
    const stages = await Api(Endpoints['Hubspot'], Actions['GetDealsPipeline'], props.external_token);
    
    const data = (deals.results || []).map((d: any) => {
        return (stages.results[0].stages || []).map((p: any) => {
            if (p.stageId === d.properties.dealstage) {
                return {...d, properties: { ...d.properties, label: p.label}}
            }
        }).filter(Boolean)[0];
        
    });
    
    UpdateSelf({ deals: data, stages: stages.results[0] });
  };

  const getReports = async () => {
    const reports = await Api(Endpoints['Hubspot'], Actions['GetReports'], props.external_token);
    UpdateSelf({ reports });
  };

  const getAnalytics = async () => {
    const allMetrics = await Api(Endpoints['Hubspot'], Actions['AnalytAllMetrics'], props.external_token);
    const dailyTraffic = await Api(Endpoints['Hubspot'], Actions['AnalytDailyTraffic'], props.external_token);
    const weeklyVisitorSessions = await Api(Endpoints['Hubspot'], Actions['AnalytWeeklyVisitorSessions'], props.external_token);
    const trafficBySource = await Api(Endpoints['Hubspot'], Actions['AnalytTrafficBySource'], props.external_token);
    const allVisitByGeo = await Api(Endpoints['Hubspot'], Actions['AnalytAllVisitByGeo'], props.external_token);
    const allVisitAllCampaigns = await Api(Endpoints['Hubspot'], Actions['AnalytAllVisitAllCampaigns'], props.external_token); 
    const allWebPages = await Api(Endpoints['Hubspot'], Actions['AnalytAllWebPages'], props.external_token); 
    const dailyAllLandingPages = await Api(Endpoints['Hubspot'], Actions['AnalytDailyAllLandingPages'], props.external_token); 
    const allBlogPosts = await Api(Endpoints['Hubspot'], Actions['AnalytAllBlogPosts'], props.external_token); 
    UpdateSelf({ analytics: { allMetrics, dailyTraffic, weeklyVisitorSessions, trafficBySource, allVisitByGeo, allVisitAllCampaigns, allWebPages, dailyAllLandingPages, allBlogPosts } });
  }

  const getTasks = async () => {
      const tasks = await Api(Endpoints['Hubspot'], Actions['GetCalendarTasks'], props.external_token);
      UpdateSelf({ tasks })
  }

  if ((!external_token || !isAuthenticated || contacts.status === 'error' || companies.status === 'error' || deals.status === 'error') && !props.travelling) return <Login name='Hubspot' logo={require('./icon.svg')} loginKey='hubspot' />;

  return (
    <>
      <WidgetContainer padding="15px">
        <Header {...props} useRef={submitRef} />
        {/* <SubHeader {...props} useRef={submitRef} /> */}
        <WidgetContent className="topbar rounded inner" style={{ margin: '15px 0 0', width: '100%', flex: 1,}} padding="20px">
          {/* <Title>
            {screen[0].toUpperCase()}
            {screen.slice(1, screen.length)}
          </Title> */}
          {screen === 'contacts' ? (
            <ListContacts {...props} getContacts={getContacts} useRef={submitRef} />
          ) : screen === 'createContact' ? (
            <CreateContact {...props} getContacts={getContacts} useRef={submitRef} />
          ) : screen === 'contact' ? (
            <ViewContact {...props} getContacts={getContacts} />
          ) : screen === 'companies' ? (
            <ListCompany {...props} getCompanies={getCompanies} useRef={submitRef} />
          ) : screen === 'company' ? (
            <ViewCompany {...props} getCompanies={getCompanies} />
          ) : screen === 'createCompany' ? (
            <CreateCompany {...props} getCompanies={getCompanies} useRef={submitRef} />
          ) : screen === 'deals' ? (
            <ListDeals {...props} getDeals={getDeals} useRef={submitRef} />
          ) : screen === 'createDeal' ? (
            <CreateDeal {...props} getDeals={getDeals} useRef={submitRef} />
          ) : screen === 'deal' ? (
            <ViewDeal {...props} getDeals={getDeals} />
          ) : screen === 'reports' ? (
            <ListReports {...props} getDeals={getDeals} />
          ) : screen === 'analytAllMetrics' ? (
            <AllMetrics {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytDailyTraffic' ? (
            <DailyTraffic {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytWeeklyVisitorSessions' ? (
            <WeeklyVisitorSession {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytTrafficBySource' ? (
            <TrafficBySource {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytAllVisitByGeo' ? (
            <AllVisitByGeo {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytAllVisitAllCampaigns' ? (
            <AllVisitAllCampaign {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytAllWebPages' ? (
            <AllWebPages {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytDailyAllLandingPages' ? (
            <DailyAllLandingPages {...props} getAnalytics={getAnalytics} />
          ) : screen === 'analytAllBlogPosts' ? (
            <AllBlogPosts {...props} getAnalytics={getAnalytics} />
          ) : screen === 'tasks' ? (
            <TaskList {...props} getTasks={getTasks} useRef={submitRef} />
          ) : screen === 'createTask' ? (
            <CreateTask {...props} getTasks={getTasks} useRef={submitRef} />
          ) : (
            ''
          )}
        </WidgetContent>
      </WidgetContainer>
      {/* 
      <WidgetContainer padding='30px'>
        <Header {...props} />
        {screen === 'contacts' ? <Contacts {...props} /> : screen === 'createContact' ? <CreateContact /> : ''}
      </WidgetContainer> */}
    </>
  );
}

export default Hubspot;

const Title = styled.h1`
  margin-left: 10px;
`;
