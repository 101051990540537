import React, { useEffect, useState } from 'react'
import styled from 'styled-components'


import StyledModal from './reusables/v1/StyledModal'
import StyledSelect from './reusables/v1/StyledSelect'
import StyledSelectTag from './reusables/v1/StyledSelectTag'
import {StyledButton} from './reusables/v1/StyledButton'

import {
  FiCalendar as CalendarIcon,
} from 'react-icons/fi'

// modal contents
import CreateSchedule from './modals/CreateSchedule'

import {
  Logo,
  PageName,
  CalendarWrapper,
  SideCalendar,
  MainCalendar,
  DatePickerWrapper,
  LinkCalendarWrapper,
  CustomCalendarIcon
} from './styles/Styles'
import { IPageDrawer, ICanvass, IEvent, IMember } from './props/Interfaces'
import { TModal,  } from './props/Types'

import WeekView from './views/ViewByWeek'
import DayView from './views/ViewByDay'
import Calendar from './Calendar'
import events, { MEMBERS } from './dummy-events'

import LogoLight from './assets/images/logolight.svg'
import GoogleCalendar from './assets/images/calendar/google-calendar-icon.svg'
import Outlook from './assets/images/calendar/outlook-icon.svg'

// V1
import {
  Drawer
} from '@material-ui/core'

function Canvass({ className = '', visible = false, onClose = () => {}, children = <></>, title = <></>, extra = <></>, keyboard = false }: IPageDrawer) {
  return (
    <Drawer
      className={className}
      anchor="top"
      variant="persistent"
      open={visible}
      onClose={onClose}
    >
      {title}
      {extra}

      {children}
    </Drawer>
  )
}

function CalendarCanvass({ openCalendar, handleClose }: ICanvass) {
  const [view, setView] = useState<string>('month')
  const [calendarDate, setCalendarDate] = useState<any>(new Date())
  const [dataRefresh, setDataRefresh] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [activeModal, setActiveModal] = useState<TModal | null>(null)
  const [schedules, setSchedules] = useState<IEvent[]>(events)
  const [members, setMembers] = useState<IMember[]>(MEMBERS)
  const [organization, setOrganization] = useState<string>('Organization Name')
  const [organizationList, setOrganizationList] = useState<any>([{ label: 'Organization Name', value: 'Organization Name' }])

  const viewOption = [
    {
      label: 'Month',
      value: 'month',
    },
    {
      label: 'Weekly',
      value: 'week',

    },
    {
      label: 'Daily',
      value: 'day'
    },
  ]
  const onViewChange = (value: any) => {
    setView(value.target.value)
  }

  const onOrganizationChange = (value: any) => {
    setOrganization(value.target.value)
  }

  const onChangeCalendar = (date: any): void => {
    setCalendarDate(new Date(date))
    refreshCalendar()
  }

  const refreshCalendar = () => {
    setDataRefresh(!dataRefresh)
  }

  const handleScheduleAction = (key: any): void => {
    switch(key) {
      case 'view-week': // get the selected date object
        setView('week')
        break

      case 'this-day': // get the selected date object
        setView('day')
        break

      default:
        setActiveModal(key)
        setVisible(true)
    }
  }

  const closeModals = (): void => {
    setActiveModal(null)
    setVisible(false)
  }

  const handleCreate = (values: IEvent) => {
    console.log('submitted:', values)

    const update_schedule = schedules
    const __values = {
              ...values,
              type: "Standard",
              text: 'Lorem ispum, dolor set amit',
              id: (update_schedule.length+1)
            }
    console.log('new values', __values)
    setSchedules(schedules => [...schedules, {...__values}])

    refreshCalendar()
    closeModals()
  }

  useEffect(() => {
    // console.log(schedules)
  }, [schedules])

  return (
    <Canvass visible={openCalendar}
      onClose={handleClose}
      keyboard={true}
      title={
        <Logo>
          <img className="logo" src={LogoLight} alt="Grapl" />
          <PageName>
            Calendar <CalendarIcon style={{ marginLeft: '8px' }} />
          </PageName>
        </Logo>
      }
      extra={
        <>
          <StyledButton isrounded='true' color="primary" onClick={() => handleScheduleAction('create-schedule')}>
            <CalendarIcon style={{ marginRight: '8px' }} /> Schedule meeting
          </StyledButton>
          <StyledSelect
            label="View"
            name="view"
            width="90px"
            value={view}
            options={viewOption}
            onChange={onViewChange} />
        </>
      }
    >
      {
        view === 'month' ?
          <CalendarWrapper>
            <SideCalendar>
              <DatePickerWrapper>
                <Calendar
                    month={calendarDate.getMonth()+1}
                    year={calendarDate.getFullYear()}
                    size="small"
                    onChangeCalendar={onChangeCalendar} />
              </DatePickerWrapper>

              {/* <LinkCalendarWrapper>
                <Text className="caption">Grapl with</Text>

                <StyledSelect
                  width="200px"
                  label="Organization Name"
                  name="organization"
                  value={organization}
                  options={organizationList}
                  onChange={onOrganizationChange}/>

                <StyledSelectTag name="members" options={members} />

                <Text className="content">Should be able to invite other user outside organization by adding email address, Grapl will send an invite</Text>

                <LinkCalendarButton isrounded='true'
                    onClick={() => handleScheduleAction('link-calendar')}
                  >
                  <CustomCalendarIcon style={{ marginRight: '8px' }} svgIcon={GoogleCalendar} /> Link Google Calendar
                </LinkCalendarButton>
                <LinkCalendarButton isrounded='true'
                    onClick={() => handleScheduleAction('link-calendar')}
                  >
                  <CustomCalendarIcon style={{ marginRight: '8px' }} svgIcon={Outlook} /> Link Outlook
                </LinkCalendarButton>
                <LinkCalendarButton isrounded='true'
                    onClick={() => handleScheduleAction('link-calendar')}
                  >
                  <CalendarIcon style={{ marginRight: '8px' }} /> Link your Calendar
                </LinkCalendarButton>

              </LinkCalendarWrapper> */}
            </SideCalendar>
            <MainCalendar>
              <Calendar
                month={calendarDate.getMonth()+1}
                year={calendarDate.getFullYear()}
                loadedEvents={schedules}
                dataRefresh={dataRefresh}
                createEvent={handleScheduleAction} />
            </MainCalendar>
          </CalendarWrapper> :
          view === 'week' ?
            <WeekView /> :
            view === 'day' ?
              <DayView /> : null
      }

      {
        (visible) &&
          <StyledModal visible={visible} onClose={closeModals}>
            {
              activeModal === 'create-schedule'
                ? <></> //<CreateSchedule handleCreate={handleCreate} />
                : activeModal === 'link-calendar'
                  ? 'Link Third-party Calendar'
                  : null
            }
          </StyledModal>
      }

    </Canvass>
  )
}


const LinkCalendarButton = styled(StyledButton)`
  margin-top: 10px;
  width: 250px;
  text-align: left;
  justify-content: left;
  font-size: 13px;
  font-weight: 500;

  > span {
    margin-left: 10px;
  }
`

const Text = styled.div`
  &.caption {
    font-size: 10px;
    text-transform: uppercase;
    color: #81818D;
  }

  &.content {
    font-size: 11px;
    color: #81818D;
    padding-left: 10px;
  }
`

const CalendarIcons = styled.span`
    height: 1em;
    width: 1em;
    stroke: currentcolor;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
`

export default CalendarCanvass
