import styled from "styled-components";

// Icons
import { FiUsers as UsersIcon } from "@react-icons/all-files/fi/FiUsers";

export const WaveWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
`;

export const Wave = styled.div<{ scale: number }>`
  height: ${(props) => props.scale}% !important;
`;

export const WaveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  --boxSize: 4px;
  --gutter: 2px;
  min-width: 30px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);

  .box {
    height: 20%;
    width: 4px;
    margin: 0 0.5px;
    /* background: linear-gradient(180deg, #213465 0%, #008BFF 100%); */
    background: #ff9900;
    border-radius: 30px;
    transition: 0.2s height ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  }
`;

export const Button = styled.button`
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const StyledUsersIcon = styled(UsersIcon)`
  margin-right: 5px;
`;

export const XLine = styled.div`
  position: absolute;
  height: 1px;
  width: 24px;
  background-color: #fff;
  transform: rotate(135deg);
  pointer-events: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;

  .start.button {
    display: flex;
    align-items: center;
    padding: 12px 30px;
    width: fit-content;
    margin: 10px auto;
  }
`;

export const PreviewWrapper = styled.div`
  position: relative;
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  margin: 0;
  overflow: hidden;
  background: #263238;
  display: flex;
  align-items: center;
  justify-content: center;

  .no-cam {
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 860px) {
    height: 260px;
  }

  video {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    transform: rotateY(180deg);

    @media (max-width: 860px) {
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
`;

export const VideoPreview = styled.video`
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  transform: rotateY(180deg);

  @media (max-width: 860px) {
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const Controls = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));

  > div {
    display: flex;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      cursor: pointer;

      padding: 15px !important;
    }
  }

  .controlbutton {
    box-shadow: none;
    border: none;

    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const SelectDevicesToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;

  span {
    cursor: pointer;
    font-weight: 500;
  }
`;

export const SelectDevicesWrapper = styled.div`
  padding-top: 12px;

  .select-device-wrapper {
    margin: 5px 0 10px;
  }

  label {
    font-weight: 500;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    label {
      margin-bottom: 4px;
    }
  }
`;

export const PreviewCamMicButton = styled.div<{disabled?: boolean}>`
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: 0.5;
    cursor: none !important;
  `}
`