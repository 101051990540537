import React from "react";
import Reflux from "reflux";
import styled from "styled-components";
import { v4 } from 'uuid'

// Components
import Modal from "../../components/Modalv2";
import moment from 'moment'

interface Props {
  folders: any,
  closeModal: Function
  meetingName: string
  UpdateSelf: Function
  currentFolderId: string | null
  checkDataFiles: Function
  userId: string
}

class ModalNewFolder extends Reflux.Component {
  inputRef: any

  constructor(props: Props) {
    super(props);

    // binds
    this.createFolder = this.createFolder.bind(this)

    // refs
    this.inputRef = React.createRef()

    this.state = {
      folderName: '',
      selectedFolder: '',
    };
  }

  componentDidMount() {
    this.inputRef.focus()
    this.inputRef.select()
  }

  createFolder(parentFolderUuid: string | null = null) {
    const { UpdateSelf, folders, closeModal, checkDataFiles, userId } = this.props

    const folderName = this.state.folderName.trim()

    if (folderName) {

      const key = v4()

      // Time Travel patch
      const filesObj = checkDataFiles()

      if (parentFolderUuid) {
        if (folders[parentFolderUuid]) {
          if (!folders[parentFolderUuid].folders.includes(key)) {
            const updatedFolders = {
              ...folders,
              [key]: {
                name: folderName,
                parentFolderUuid,
                files: [],
                folders: [],
                uuid: key,
                user_id: userId,
                created: moment(new Date()).format()
              },
              [parentFolderUuid]: {
                ...folders[parentFolderUuid],
                folders: [ ...folders[parentFolderUuid].folders, key ]
              }
            }
            UpdateSelf({
              folders: updatedFolders,
              ...(filesObj ? {files:filesObj} : {})  // Time Travel patch
            })
          }
        }
      } else {
        const updatedFolders = {
          ...folders,
          [key]: {
            name: folderName,
            parentFolderUuid,
            files: [],
            folders: [],
            uuid: key,
            user_id: userId,
            created: moment(new Date()).format()
          }
        }
        UpdateSelf({
          folders: updatedFolders,
          ...(filesObj ? {files:filesObj} : {}) // Time Travel patch
        })
      }

      this.setState({ folderName: '' })
      closeModal()
    }
  }

  render() {
    const { currentFolderId } = this.props

    return (
      <Modal {...this.props}>
        <Container>
          <div className="name">Enter folder name</div>
          <form onSubmit={e => {
            e.preventDefault()
            this.createFolder(currentFolderId)
          }}>
            <input
              className="hover-container border-lightimp"
              type="text"
              value={this.state.folderName}
              onChange={e => this.setState({ folderName: e.target.value })}
              ref={ref => this.inputRef = ref}
            />
            <div className="actions-wrapper">
              <div className="button default" onClick={() => this.props.closeModal()}>Cancel</div>
              <div className="button primary" onClick={(e) => {e.preventDefault(); this.createFolder(currentFolderId)}}>Create</div>
            </div>
          </form>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  padding: 15px;

  & > div.name {
    padding: 8px;
  }

  & > form > input {
    text-align: center;
    background: none;
    border-radius: 999px;
    border: 1px solid;
    padding: 10px;
    margin-top: 15px;
  }

  & div.actions-wrapper {
    justify-content: center;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > div {
      cursor: pointer;
      min-width: 68px;
      padding: 8px 20px;
    }

    > div:first-child {
      margin-right: 5px;
    }
  }
`;

export default ModalNewFolder;
