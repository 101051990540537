import React, { FormEvent } from 'react'
import { useHistory } from 'react-router'

import { LoginForm, LogoIcon, LogoWrapper, SubmitButton, SubTitle, Title } from './styled'
import EmailSentIcon from './emailsent.svg'

function SuccessfulEmail() {
    const history = useHistory()

    const onSuccessfulEmail = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        history.push('/login')
    }

  
  return (
    <LoginForm onSubmit={onSuccessfulEmail} style={{ minHeight: '200px' }}>
        <LogoWrapper>
            <LogoIcon src={EmailSentIcon} alt={'Email'} />
        </LogoWrapper>
        <Title>
            Check your email
        </Title>
        <SubTitle>We have sent a password recovery instruction to your email address</SubTitle>
        <SubmitButton
            className="button primary"
            value="Ok"
            type="submit"
            style={{ marginBottom: 0 }}
        />
    </LoginForm>
  )
}

export default SuccessfulEmail