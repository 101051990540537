import { DailyCall } from "@daily-co/daily-js"
import { Room } from "twilio-video"

export type ISettings = {
  calling_provider: CallingProviderName
  color_scheme: string
  widget_display_method: string
  call_type: string
  bot_mode: boolean
  is_public: boolean
  transcribe: boolean
  record: boolean
  allow_knocking: boolean
  expandedWidgets: any[]
  minimizedWidgets: any[]
  widgetOrder: any[]
  _id: string
}

export type IMeetingData = {
  _id: string
  ghost_mode: boolean
  user_ids: string[]
  guest_user_ids: string[]
  locked: boolean
  settings: ISettings
  creator_user_id: string
  subscribers: any[]
  bot_settings: any
  name: string
  name_alias: string
  created_at: string
  updated_at: string
  __v: any
  last_connection_date: string
  password: string | boolean
}

export enum CallingProviderName {
	Twilio = 'TWILIO',
	Zoom = 'ZOOM',
	WebRTC = 'WEBRTC',
	DailyCo = 'DAILYCO',
	TwilioStandard = 'TWILIO::STANDARD',
	TwilioPro = 'TWILIO::PRO',
}

export type ISessionData = {
  compositionAvailable: boolean
  compositionDuration: any
  compositionSize: any
  created_at: string
  duration: number
  hasScreenTrack: boolean
  history: any[]
  isRecording: boolean
  locked: boolean
  ongoing: boolean
  provider: CallingProviderName
  session_id: string
  start_time: number
  transcription: string
  updatedAt: string
  uri: string
  user_ids: string[]
  __v: any
  _id: string
}

export type RoomType = Partial<Room> & Partial<DailyCall>