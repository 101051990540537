import moment from 'moment';
import React, { useMemo } from 'react'
import styled from 'styled-components';
import { IChat, IContext, IUser } from '../types';
import MyChat from './MyChat';
import TheirChat from './TheirChat';
const { GlobalState } = require('reflux')

import { ChatContext } from '../ChatContext'
import { withMemoizedContext } from 'react-memoized-context';
import Info from './Info';

interface Props {
    context: IContext
}

function Chats({ context }: Props) {

    const myId = GlobalState.auth.jwt.data._id

    let photoArr: Array<any> = []

    var lastDate = '', postDate, prevSender = '', prevTime = 0



    const extractInfo = (id: String) => GlobalState.main.users.find((user: IUser) => user.id === id)

    console.log('chats rerender');


    return useMemo(() => {
        console.log('chats inside memo');

        const renderDate = (timeStamp: number) => {
            const ts = new Date(timeStamp);
            const date = ts.toDateString();
            const today = moment().toDate()
            const todayFormatted = moment(today, 'MMM-DD-YYYY')
            const chatDate = moment(timeStamp).format('MMM-DD-YYYY')

            if (lastDate !== date) {
                const difference = todayFormatted.diff(chatDate, 'days')

                lastDate = date
                if (difference === 0) {
                    return 'Today'
                } else if (difference === 1) {
                    return 'Yesterday'
                } else {
                    return `${date.slice(0, 3)}${','}${date.slice(3)}`
                }
            } else {
                return null
            }
        }

        return <>
            {
                context.chats?.sort((a: any, b: any) => a.timeStamp - b.timeStamp).map((c: IChat, idx: number) => {
                    const date = new Date(c.timeStamp).toDateString();
                    const prev = (prevSender === c.sender.id) && (moment(c.timeStamp).format('h:mm A') === moment(prevTime).format('h:mm A'))

                    prevSender = c.type === 'info' ? '' : c.sender.id
                    prevTime = c.timeStamp

                    let chatPhotos: Array<Object> = []
                    //Push images to photoArry for image viewing
                    if (c.type === 'photos') {
                        c.photos.map((url: string) => {
                            photoArr.push({ src: url, srcSet: [url] })
                            chatPhotos.push({ url, index: photoArr.length - 1 })
                        })
                    }

                    return (<>
                        {lastDate !== date  && <PostDate>{renderDate(c.timeStamp)}</PostDate>}
                        {c.type === 'info' ? <Info chat={c} /> : c.sender?.id === myId ?
                            <MyChat
                                key={c.timeStamp}
                                chat={{ ...c, photos: [...chatPhotos] }}
                                prev={prev}
                                openLightBox={(idx: number) => context.openLightBox(idx)}
                            />
                            :
                            <TheirChat
                                key={c.timeStamp}
                                chat={{ ...c, photos: [...chatPhotos], senderInfo: extractInfo(c.sender.id) }}
                                prev={prev}
                                openLightBox={(idx: number) => context.openLightBox(idx)}
                            />}
                    </>
                    )
                }
                )
            }

        </>
    }, [context.chats])
}

const ComponentContexted = withMemoizedContext(ChatContext)(Chats, ['chats', 'openLightBox']);


export default ComponentContexted

const PostDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: darkgray;
  margin: 10px 0;
  font-size: 10px;
`