import React from 'react'
import useParticipants from '../Widgets/KeynoteWidgets/hooks/useParticipants'

function JoinMeetingButton() {
    // const participants = useParticipants()
    return (
        <div>
            {/* { participants?.length } */}
        </div>
    )
}

export default JoinMeetingButton
