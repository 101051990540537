import { useCallback, useEffect, useState } from 'react';
import {SELECTED_AUDIO_OUTPUT_KEY} from '../../../../../calling/_constants';
import useDevices from './useDevices';
import useLocalStorage from './useLocalStorage';

export default function useActiveSinkId() {
  const { audioOutputDevices } = useDevices();
  const [activeSinkId, _setActiveSinkId] = useState('default');
  const [localSinkId, setLocalSinkId] = useLocalStorage(SELECTED_AUDIO_OUTPUT_KEY, '')

  const setActiveSinkId = useCallback((sinkId: string) => {
    setLocalSinkId(sinkId);
    _setActiveSinkId(sinkId);
  }, []);

  useEffect(() => {
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      device => localSinkId && device.deviceId === localSinkId
    );
    if (hasSelectedAudioOutputDevice) {
      _setActiveSinkId(localSinkId!);
    }
  }, [audioOutputDevices]);

  return [activeSinkId, setActiveSinkId] as const;
}
