import React from "react"

import IconPDF from "./file-icons/pdf.svg"
import IconTxt from "./file-icons/txt.svg"
import IconZip from "./file-icons/zip.svg"
import IconVideo from "./file-icons/video.svg"
import IconDocs from "./file-icons/docs.svg"
import IconCode from "./file-icons/code.svg"
import IconSpreadSheet from "./file-icons/excel.svg"
import IconMusic from "./file-icons/music.svg"
import IconImages from "./file-icons/images.svg"
import IconPowerpoint from "./file-icons/powerpoint.svg"
import IconOthers from "./file-icons/others.svg"

export const extvals = [
	['zip', 'tar', 'rar', 'tar.gz', '7z'],
	['doc', 'docx', 'dot'],
	['ods', 'xls', 'xlsx', 'numbers', 'csv', 'xlsm', 'xlt', 'xla', 'xlam'],
	['key', 'ppt', 'pptx'],
	[
		'js',
		'py',
		'c',
		'cpp',
		'go',
		'php',
		'html',
		'css',
		'htm',
		'cs',
		'swift',
		'vb',
		'h',
	],
	['jpg', 'jpeg', 'bmp', 'png', 'gif', 'ps', 'psd', 'svg', 'jfif', 'webp'],
	['avi', 'mov', 'mp4', 'flv', 'h264', 'mk4', 'm4v', 'mpg', 'mpeg'],
	['mp3', 'mp4', 'wav'],
	['odt', 'rtf', 'md', 'txt', 'notes'],
	['pdf'],
]

function Icon(src) {
	return props => <img src={src} height={props.size} width={props.size} />
}

export const IconFile = Icon(IconOthers)

export const extkeys = [
	Icon(IconZip),
	Icon(IconDocs),
	Icon(IconSpreadSheet),
	Icon(IconPowerpoint),
	Icon(IconCode),
	Icon(IconImages),
	Icon(IconVideo),
	Icon(IconMusic),
	Icon(IconTxt),
	Icon(IconPDF),
]

const extCategoryNames = [
	'Zip Files',
	'Documents',
	'Spreadsheets',
	'Powerpoint',
	'Code',
	'Images',
	'Videos',
	'Audio',
	'Text',
	'PDF Files'
]

const extColors = [
	'#6e6868',
	'#4970e2',
	'#38d47d',
	'#ef5014',
	'#ef1414',
	'#b784fd',
	'#d6ec17',
	'#f2983b',
	'#54d6ea',
	'#eb6d6d'
]

export function getExtCategoryName(fileName) {
	const extensionMatch = fileName.match(/\.(\w+)$/i)

	if (extensionMatch === null) {
		return 'Others'
	}

	const extension = extensionMatch[1]
	const extCategoryNameIndex = extvals.findIndex(ext => ext.includes(extension))

	return extCategoryNames[extCategoryNameIndex] || 'Others'
}

export function getIconByExtCategoryName(extCategoryName) {
	const iconIndex = extCategoryNames.findIndex(ecn => ecn === extCategoryName)
	return extkeys[iconIndex] || IconFile
}

export function getColorByFileName(fileName) {
	const extensionMatch = fileName.match(/\.(\w+)$/i)

	if (extensionMatch === null) {
		return 'rgba(0, 0, 0, 0.3)'
	}

	const extension = extensionMatch[1]
	const extColorIndex = extvals.findIndex(ext => ext.includes(extension))

	return extColors[extColorIndex] || 'rgba(0, 0, 0, 0.3)'
}

const getIcon = filename => {
	let ext = filename.match(/\.(\w+)$/i)
	if (!ext || !ext[1]) {
		return IconFile
	}
	ext = ext[1]
	return extkeys[extvals.findIndex(v => v.includes(ext))] || IconFile
}

export default getIcon
