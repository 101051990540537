import {LocalVideoTrack, Room} from 'twilio-video'
import {useState, useEffect, useCallback} from 'react'
import {SELECTED_BACKGROUND_SETTINGS_KEY} from '../../../../../calling/_constants'
import useLocalStorage from '../hooks/useLocalStorage'

import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
  isSupported,
} from '@twilio/video-processors'

import Grapl01 from '../../../../../assets/video-backgrounds/Grapl-BG-01-01.jpg'
import Grapl01Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-01.jpg'
import Grapl02 from '../../../../../assets/video-backgrounds/Grapl-BG-01-02.jpg'
import Grapl02Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-02.jpg'
import Grapl03 from '../../../../../assets/video-backgrounds/Grapl-BG-01-03.jpg'
import Grapl03Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-03.jpg'
import Grapl04 from '../../../../../assets/video-backgrounds/Grapl-BG-01-04.jpg'
import Grapl04Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-04.jpg'
import Grapl05 from '../../../../../assets/video-backgrounds/Grapl-BG-01-05.jpg'
import Grapl05Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-05.jpg'
import Grapl06 from '../../../../../assets/video-backgrounds/Grapl-BG-01-06.jpg'
import Grapl06Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-06.jpg'
import Grapl07 from '../../../../../assets/video-backgrounds/Grapl-BG-01-07.jpg'
import Grapl07Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-07.jpg'
import Grapl08 from '../../../../../assets/video-backgrounds/Grapl-BG-01-08.jpg'
import Grapl08Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-08.jpg'
import Grapl09 from '../../../../../assets/video-backgrounds/Grapl-BG-01-09.jpg'
import Grapl09Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-09.jpg'
import Grapl10 from '../../../../../assets/video-backgrounds/Grapl-BG-01-10.jpg'
import Grapl10Thumb from '../../../../../assets/video-backgrounds/thumbnail/Grapl-BG-01-10.jpg'
import {Thumbnail} from '../Main/components/BackgroundThumbnail'
import { RoomType } from '../../../../../contexts/types'
import { DailyCall } from '@daily-co/daily-js'

export interface BackgroundSettings {
  type: Thumbnail
  index?: number
}

const imageNames: string[] = [
  'Grapl 1',
  'Grapl 2',
  'Grapl 3',
  'Grapl 4',
  'Grapl 5',
  'Grapl 6',
  'Grapl 7',
]

const images = [
  Grapl01Thumb,
  Grapl02Thumb,
  Grapl03Thumb,
  Grapl04Thumb,
  Grapl05Thumb,
  Grapl06Thumb,
  Grapl07Thumb,
  Grapl08Thumb,
  Grapl09Thumb,
  Grapl10Thumb,
]

const rawImagePaths = [
  Grapl01,
  Grapl02,
  Grapl03,
  Grapl04,
  Grapl05,
  Grapl06,
  Grapl07,
  Grapl08,
  Grapl09,
  Grapl10,
]

let imageElements = new Map()

const getImage = (index: number): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index))
    }
    const img = new Image()
    img.onload = () => {
      imageElements.set(index, img)
      resolve(img)
    }
    img.onerror = reject
    img.src = rawImagePaths[index]
  })
}

export const backgroundConfig = {
  imageNames,
  images,
}

const virtualBackgroundAssets = `${process.env.REACT_APP_TWILIO_VIDEO_PROCESSOR_SERVER}/public/virtualbackground`
let blurProcessor: GaussianBlurBackgroundProcessor
let virtualBackgroundProcessor: VirtualBackgroundProcessor

export default function useBackgroundSettings(
  videoTrack: LocalVideoTrack | undefined,
  room?: Room | DailyCall | null,
) {
  const [localStorageSettings, setLocalStorageSettings] = useLocalStorage(
    SELECTED_BACKGROUND_SETTINGS_KEY,
    {type: 'none', index: 0} as BackgroundSettings,
  )
  const [backgroundSettings, setBackgroundSettings] =
    useState<BackgroundSettings>(localStorageSettings)

  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor && videoTrack.removeProcessor) {
      videoTrack.removeProcessor(videoTrack.processor)
    }
  }, [videoTrack])

  const addProcessor = useCallback((
      processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor,
    ) => {
      if (!videoTrack || videoTrack.processor === processor) {
        return
      }
      removeProcessor()
      //console.log({videoTrack})
      // if(videoTrack.addProcessor) {
      //   videoTrack.addProcessor(processor)
      // }
    },
    [videoTrack],
  )

  useEffect(() => {
    if (!isSupported) {
      return
    }
    // make sure localParticipant has joined room before applying video processors
    // this ensures that the video processors are not applied on the LocalVideoPreview
    const handleProcessorChange = async () => {
      //console.log('**twil, HANDLING processor change', virtualBackgroundAssets)
      if (!blurProcessor) {
        blurProcessor = new GaussianBlurBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          blurFilterRadius: 15,
        })
        await blurProcessor.loadModel()
      }
    //   if (!virtualBackgroundProcessor) {
    //     virtualBackgroundProcessor = new VirtualBackgroundProcessor({
    //       assetsPath: virtualBackgroundAssets,
    //       backgroundImage: await getImage(0),
    //       fitType: ImageFit.Cover,
    //       maskBlurRadius: 5,
    //     })
    //     await virtualBackgroundProcessor.loadModel()
    //   }
    //   // if (!room?.localParticipant) {
    //   //   return
    //   // }

      if (backgroundSettings.type === 'blur') {
        addProcessor(blurProcessor)
      } 
      // else if (
    //     backgroundSettings.type === 'image' &&
    //     typeof backgroundSettings.index === 'number'
    //   ) {
    //     //console.log('**twil', 'SETTING BACKGROUND IMAGE', backgroundSettings)
    //     virtualBackgroundProcessor.backgroundImage = await getImage(
    //       backgroundSettings.index,
    //     )
    //     addProcessor(virtualBackgroundProcessor)
    //   } else {
    //     removeProcessor()
    //   }
    }
    handleProcessorChange()
    setLocalStorageSettings(backgroundSettings)
  }, [backgroundSettings, room, addProcessor, removeProcessor])

  return [backgroundSettings, setBackgroundSettings] as const
}
