import React, { Component } from 'react';
import styled from 'styled-components';
import Color from 'color';
import moment from 'moment';
import { FaHashtag as IconHash } from '@react-icons/all-files/fa/FaHashtag'
import { FaLock as IconLock } from '@react-icons/all-files/fa/FaLock'

import MarkdownSlack from 'slack-markdown-it';

import { Avatar, UserItem } from '../Slack/SharedStyles';
import LoopApi from '../../helpers/LoopApi';
import Api, { Actions, Endpoints } from '../_Shared/Api';
import ChatInput from './ChatInput';

import lightMarkdown from './Parser/index';
import JoinChannel from './JoinChannel';
import getIcon from '../_Shared/FileIcon';
import Scrollbars from 'react-custom-scrollbars';
import { WidgetContentItemName } from '../_Shared/Elements';

interface Props {
  selected: any;
  token: string;
  handleUpdates: (obj: object) => void;
  listChannels: () => void;
  membersList: any[];
  channelList: any[];
  messages: any[];
  isHost: boolean | undefined;
  meetingName: string;
  isLeftVisible: boolean;
  refreshMessages: boolean
}

const md = require('markdown-it')({
  html: true,
  xhtmlOut: true,
});
md.disable('autolink');
md.use(MarkdownSlack);

export default class Message extends Component<Props> {
  messagesEnd: any;

  constructor(props: Props) {
    super(props);

		this.messagesEnd = React.createRef()

    this.listMessages = this.listMessages.bind(this);
    this.analyzeMessage = this.analyzeMessage.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.submitChat = this.submitChat.bind(this);
    this.joinChannel = this.joinChannel.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    this.scrollToBottom();

    if (
      (this.props.token && this.props.selected?.id !== prevProps.selected?.id) ||
      this.props.refreshMessages
      ) {
      this.listMessages();
    }
  }

  componentDidMount() {
    if (this.props.token && this.props.selected) {
      this.listMessages();
      this.props.listChannels();
    }
  }

  async listMessages() {
    const url = `?channel=${this.props.selected?.id}`;

    await Api(Endpoints['Slack'], Actions['ListMessages'], this.props.token, {
      urlAppend: url,
      noHeader: true,
      headerSettings: {
        contentType: false,
        headerConfig: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    })
      .then((response) => {
        let { messages = [] } = response;

        if (response.ok) {
          messages =
            messages.length > 0
              ? messages.reverse().reduce((result: any, currentValue: any) => {
                  let m = moment.unix(currentValue.ts).format('MMMM DD, YYYY') as string;
                  (result[m] = result[m] || []).push(currentValue);
                  return result;
                }, {})
              : [];

          this.props.handleUpdates({ messages, ...(this.props.refreshMessages ? {refreshMessages: false} : {}) });
          this.scrollToBottom();
        }
      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  analyzeMessage(text: any) {
    return lightMarkdown.toHtml(text, this.props.membersList, this.props.channelList);
  }

  scrollToBottom() {
    // this.messagesEnd && this.messagesEnd.scrollTo
    //   ? this.messagesEnd.scrollTo(0, this.messagesEnd.scrollHeight - this.messagesEnd.clientHeight)
    //   : (this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight - this.messagesEnd.clientHeight);
    // debugger
    if(this.messagesEnd?.current) {
      this.messagesEnd?.current?.scrollToBottom()
    } else {
      this.messagesEnd?.scrollToBottom()
    }
  }

  async submitChat(val: string) {
    if (val === '') {
      return;
    }

    this.props.handleUpdates({
      messages: {
        ...(this.props.messages || {}),
        [moment().format('MMMM DD, YYYY')]: [
          ...(this.props.messages[moment().format('MMMM DD, YYYY')] || []),
          {
            text: `${val} (sending...)`,
            ts: moment().format('X'),
            type: 'message',
            user: this.props.selected.id,
            sending: true,
          },
        ],
      },
    });

    await LoopApi(null, 'SendSlackMessage', {
      channel: this.props.selected.id,
      message: val,
      meeting_name: this.props.meetingName,
    })
      .then((response) => {
        if (response.ok) {
          this.listMessages();
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  async joinChannel() {
    const url = `?token=${this.props.token}&channel=${this.props.selected.id}`;
    await Api(Endpoints['Slack'], Actions['JoinChannel'], null, {
      urlAppend: url,
      headerSettings: {
        contentType: false,
      },
    })
      .then((response) => {
        if (response.ok) {
          this.props.listChannels();
          this.listMessages();
        }
      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  render() {
    const { isLeftVisible } = this.props;

    if (!!!this.props.selected) {
      return;
    }

    const messages = Object.keys(this.props.messages).map((ts: any, idx) => {
      return (
        <Container className="slack-message-container">
          <div className='timestamp-wrapper'>
            <div className='timestamp container content'>{ts}</div>
            <div className='timestamp-line container content'></div>
          </div>
          {this.props.messages[ts].map((m: any, idx1: number) => {
            const filteredUser = this.props.membersList.filter((u) => u.id === m.user) as any;
            const user = filteredUser && filteredUser.length ? filteredUser[0] : {};

            const prevMessage = this.props.messages[ts][idx1 - 1];
            let sameUser = false;
            let duration = 0;

            if (prevMessage) {
              const prevTime = moment.unix(prevMessage.ts);
              const currentTime = moment.unix(m.ts);
              duration = currentTime.diff(prevTime, 'minutes');
              sameUser = prevMessage && prevMessage.user === user.id;
            }

            return (
              <UserItem
                {...{ simple: true }}
                key={`${user.id}-${Math.random()}`}
                className={!sameUser || (sameUser && duration > 3) ? 'message-user' : 'no-user-label'}
              >
                {(!sameUser || (sameUser && duration > 3)) && !m.sending ? (
                  <Avatar src={user.avatar_url} />
                ) : (
                  <EmptyAvatar />
                )}
                <UserInfo>
                  {(!sameUser || (sameUser && duration > 3)) && !m.sending && (
                    <Name>
                      {user?.displayName || user.name} {moment.unix(m.ts).format('h:mm A')}
                    </Name>
                  )}
                  <MessageStr
                    subtype={!!m.subtype}
                    dangerouslySetInnerHTML={{
                      __html: md.render(this.analyzeMessage(m.text || '')),
                    }}
                    className={!(!sameUser || duration > 3) ? 'no-user-label' : m.sending ? 'sending' : ''}
                  />
                  {m.attachments && m.attachments.length > 0 && (
                    <React.Fragment>
                      {m.attachments.map((a: any) => {
                        return (
                          <SEOWrapper color={a.color}>
                            {a.service_icon || a.service_name ? (
                              <div className='service-info'>
                                {a.service_icon && <img src={a.service_icon} alt={a.service_name} />}
                                <div>{a.service_name}</div>
                              </div>
                            ) : (
                              ''
                            )}
                            <a href={a.title_link}>{a.title}</a>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: md.render(this.analyzeMessage(a.text || '')),
                              }}
                            ></div>
                          </SEOWrapper>
                        );
                      })}
                    </React.Fragment>
                  )}
                  {m.files && m.files.length > 0 && (
                    <FileWrapper>
                      {m.files.map((f: any) => {
                        const FileIcon = getIcon(f.url_private);

                        return (
                          <div className='file-preview'>
                            <a href={f.url_private} target='_blank'>
                              <FileIcon
                                size={44}
                                style={{
                                  alignSelf: 'center',
                                  marginBottom: 12,
                                }}
                              />
                            </a>
                            {/* <img className="someClass" src={f.thumb_800 || f.thumb_720 || f.thumb_480 || f.thumb_360 || f.thumb_160 || f.thumb_80 || f.thumb_64} /> */}
                          </div>
                        );
                      })}
                    </FileWrapper>
                  )}
                </UserInfo>
              </UserItem>
            );
          })}
        </Container>
      );
    });

    const { is_channel = false, is_im = false } = this.props.selected
    //console.log("SELECTED: ", this.props.selected)
    let details: any = {}

    if(is_channel) {
      details = this.props.selected
    } else if(is_im) {
      details = (this.props.membersList || [])?.filter(member => member?.id === this.props.selected?.user)?.[0] || {}
    }
    //console.log("DETAILS: ", details)
    return (
      <React.Fragment>
        <ChatContainer isLeftVisible={isLeftVisible} className="topbar rounded inner" >
          <Header className="border-light">
            {
              is_channel ? details?.is_private ? <IconLock /> : <IconHash />
              : is_im && details?.avatar_url ? <Avatar src={details?.avatar_url} style={{marginRight: '10px'}} /> 
              : ''
            }&nbsp; <WidgetContentItemName style={{ fontSize: '14px', fontWeight: 500 }}>{details?.displayName || details?.name}</WidgetContentItemName>
          </Header>
          <Scroller 
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            {...{ innerRef: (r: any) => (this.messagesEnd = r) } as any}
            {...({ ref: (r: any) => (this.messagesEnd = r) } as any)}z
          >{messages}</Scroller>
          <ChatInputContainer>
            {!this.props.selected.is_member && !this.props.selected.is_im ? (
              <JoinChannel joinChannel={this.joinChannel} isHost={true} selected={this.props.selected} />
            ) : (
              <ChatInput submitChat={this.submitChat} isHost={true} is_channel={is_channel} currentName={details?.displayName || details?.name}  />
            )}
          </ChatInputContainer>
        </ChatContainer>
      </React.Fragment>
    );
  }
}

const Header = styled.div`
  margin-right: 10px;
  display: flex;
  padding-bottom: 10px; 
  align-items: center; 
  width: 100%;
  border-bottom: 1px solid; 
  margin-bottom: 20px;
`

const FileWrapper = styled.div`
  .file-preview {

    img {
      width: 100%;
      border-radius: 3px;
      border: 1px solid #ccc;
    }

    .someClass {
      position: relative;
      width: 100%;
      height: 200px;
      background-color: #222222;
    }
  }
`;

const SEOWrapper = styled.div<{ color: string | number | undefined }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;

  &::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    left: 0;
    background-color: ${(props) =>
      props.color ? `#${props.color}` : Color(props.theme.colors.textPrimary).fade(0.5).string()};
    top: 0;
    border-radius: 10px;
  }

  .service-info {
    display: flex;

    img {
      height: 16px;
    }
  }
`;

const Container = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
  font-size: 12px;

  a {
    text-decoration: none;
    color: #3498db;
  }

  code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
  }

  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    white-space: pre-wrap;

    code {
      padding: 0;
      font-size: inherit;
      color: inherit;
      white-space: pre-wrap;
      background-color: transparent;
      border-radius: 0;
    }
  }

  blockquote {
    border-left: 5px solid #eee;
    padding-left: 5px;
  }

  .message-user {
    align-items: flex-start;
  }

  &.no-user-label,
  .no-user-label {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0;
  }

  .timestamp-wrapper {
    display: flex;
    position: relative;
    padding: 20px 0;
    justify-content: center;
    align-items: center;

    .timestamp {
      position: absolute;
      padding: 5px 20px;
      border-radius: 17px;
      font-weight: 400;
      /* background-color: #52525b;
      color: #fff; */
    }

    .timestamp-line {
      height: 0.5px;
      width: 90%;
      /* background-color: #363b45; */
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-wrap: anywhere;
  padding-left: 25px;
`;

const MessageStr = styled.div<{ subtype: boolean }>`
  flex: 1;
  margin-top: 5px;

  font-weight: ${(props) => props.theme.textRegular};
  ${(props: any) => props.subtype && `opacity: 0.5;`};

  &.sending {
    color: ${(props) => Color(props.theme.colors.textPrimary).fade(0.7).string()};
  }
`;

const Name = styled.div`
  flex: 1;
  font-weight: ${(props) => props.theme.textBold};
`;

const EmptyAvatar = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

const Scroller = styled(Scrollbars)`
  /* display: flex;
	flex-direction: column;
	flex: 1;*/
  /* overflow-y: auto; */
  align-self: stretch;
`;

const ChatContainer = styled.div<{isLeftVisible: boolean}>`
  display: ${({isLeftVisible}) => isLeftVisible ? 'none' : 'flex'};
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  margin-left: 0px;
  margin-top: 0px;
  flex-grow: 1;
  width: 55%;

  @media (min-width: 768px){
    display: flex;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
  }
`;

const ChatInputContainer = styled.div`
  align-self: stretch;
  margin-top: auto;
`;
