import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import LoopApi from '../../helpers/LoopApi'
import FolderList from './FolderList'
import { FiBell } from '@react-icons/all-files/fi/FiBell'
import { WidgetContainer, WidgetContent } from '../_Shared/Elements'
import { IWidgetProps, AllWidgets } from '../types'
import WidgetHeader, { Subtitle, Title } from '../_Shared/WidgetHeader'
import EmptyDropboxContents from '../_Shared/EmptyFileContents'
import { LoadingComponentWrapper } from './'
import LoadingComponent from '../_Shared/LoadingComponent'
import useClickupContext from './useClickupContext'
import { WidgetActions } from '../../stores/MainStore'

interface ISpace {
    name: string
    id: string
}

function SpaceList() {
    const { authFailed, external_token, clickupData} = useClickupContext()
    const [ loading, setLoading ] = useState(false)
    const [ dataLoading, setDataLoading ] = useState(false)
    const [folderList, setFolderList] = useState([])
    const [folderless, setFolderless] = useState([])
    const [spaceList, setSpaceList] = useState([])

     const listSpaces = async() => {
        if (!external_token) { return }
        setLoading(true)

        console.log({ clickupData })
        await LoopApi(null, 'GetClickupSpaces', {}, [['team_id', clickupData?.currentTeam?.id ]])
        .then((resp: any) => {
            if (resp.error) {
                return authFailed()
            }
            if (resp.spaces?.length > 0) {
                console.log("resp: ", resp.spaces)
                const curSpace = JSON.stringify(resp.spaces[0])
                setSpaceList(resp.spaces)
                handleSpaceClick(curSpace as any)
            } else {
                setFolderless([])
                setFolderList([])
                setLoading(false)
            }

        })
        .catch((err: any) => {
            console.error(err)

            setLoading(false)
            return authFailed()
        })
    }

    const handleSpaceClick = async (spaceS: ISpace | string) => {
        const space = JSON.parse(spaceS as any)
        setFolderless([])
        setFolderList([])
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                currentSpace: space
            }
        })

        setDataLoading(true)
        await listFolders(space.id)
        await listFolderless(space.id)
        setLoading(false)
        setDataLoading(false)
    }

    const listFolders = async(space_id: string) => {
        if (!external_token) return

        await LoopApi(null, 'GetClickupFolders', {}, [['space_id', space_id]])
            .then((resp) => {
                if (resp.error) {
                    return authFailed()
                }

                setFolderList(resp.folders || [])
            })
            .catch((err) => {
                console.error(err)

                setLoading(false)
                setDataLoading(false)
                return authFailed()
            })
    }

    const listFolderless = async(space_id: string) => {
        if (!external_token) { return }

        await LoopApi(null, 'GetClickupFolderlessList', {}, [['space_id', space_id]])
            .then((resp) => {
                if (resp.error) {
                    return authFailed()
                }
                setFolderless(resp.lists)
            })
            .catch((err) => {
                console.error(err)

                setLoading(false)
                setDataLoading(false)
                return authFailed()
            })
    }

    const handleBack = () => {
        setFolderless([])
        setFolderList([])
        setSpaceList([])

        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                view: 'teamlist',
                currentTeam: null,
                currentSpace: null,
            }
        })
    }

    useEffect(() => {
        listSpaces()
    }, [external_token])

    return (
        <WidgetContainer padding="30px" height="100%">
            <WidgetHeader
                name={
                    <NameHeader>
                        <Ico>
                            <img src={require("./icon.svg")} />
                        </Ico>
                        <span className="desktop">Spaces</span>
                    </NameHeader>
                }
                subname="You can view the ClickUp space here"
                subnameClassName="desktop"
                end={
                    <React.Fragment>
                        <Button className="button" onClick={() => handleBack()}>Back</Button>
                    </React.Fragment>
                }
            />
            <NameHeader className="mobile imp">
                <Title> Spaces </Title>
                <Subtitle className="subheader">{'You can view the ClickUp space here'}</Subtitle>
            </NameHeader>
            {
                loading ? 
                <LoadingComponentWrapper className="rounded inner topbar" style={{ marginTop: '30px' }}>
                    <LoadingComponent />
                </LoadingComponentWrapper> :
                <React.Fragment>
                    <SpaceWrapper>
                        {
                            spaceList?.length > 0 ? spaceList.map((space: ISpace) => {
                                return <Spaces 
                                    className={`${clickupData?.currentSpace?.id === space?.id ? 'border-primary' : 'border-light'} topbar button rounded inner`}
                                    onClick={(e: any) => {handleSpaceClick(JSON.stringify(space))}}
                                >{space.name}</Spaces>
                            }) : <EmptyDropboxContents fullView>No space</EmptyDropboxContents>
                        }
                    </SpaceWrapper>
                    <SpaceFolderWrapper className="topbar rounded inner" key={JSON.stringify(folderList)}>
                        {
                            dataLoading ? 
                            <LoadingComponentWrapper className="rounded inner topbar" style={{ height: '100%' }}>
                                <LoadingComponent />
                            </LoadingComponentWrapper>
                            :
                            (folderList?.length || folderless?.length) ?
                                <FolderList folderList={folderList} folderless={folderless}/> :
                            <EmptyDropboxContents folder fullView>No folder or list</EmptyDropboxContents>
                        }
                    </SpaceFolderWrapper>
                </React.Fragment>
            }
        </WidgetContainer>
    )
}

const SpaceFolderWrapper = styled.div`
    height: 100%;
    padding: 15px;
`

const NotificationWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0px !important;
    cursor: pointer;

    transform: rotate(-45deg);
    animation: shake 1.5s infinite ease-in-out;
    @keyframes shake {
        0% { transform:rotate(-0deg) scale(1) skew(1deg); }
        10% { transform:rotate(-15deg) scale(1) skew(1deg); }
        20% { transform:rotate(15deg) scale(1) skew(1deg); }
        30% { transform:rotate(-15deg) scale(1) skew(1deg); }
        40% { transform:rotate(15deg) scale(1) skew(1deg); }
        50% { transform:rotate(-0deg) scale(1) skew(1deg); }
        100% { transform:rotate(-0deg) scale(1) skew(1deg); }
    }
`

const Spaces = styled.div`
    margin-right: 15px;
    cursor: pointer;
    font-weight: 400;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;

    &.mobile {
        flex-direction: column;
        align-items: baseline;
        margin-top: 15px;
    }
`

const SelectWrapper = styled.div`
    max-width: 150px;
    position: relative;

    select {
        width: 100%;
        margin-left: 10px;
    }

    &::after {
        content: "▼";
        font-size: 8px;
        top: 8px;
        right: 0px;
        position: absolute;
        transform: scaleY(0.7);
    }
`

const SpaceWrapper = styled.div`
    display: flex;
    margin: 30px 0;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;

    @media (max-width: 550px) {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
    }
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 35px;
        border: none;
        align-self: center;
        margin: 0 14px;
    }
`

export default SpaceList
