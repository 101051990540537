import {useEffect, useState} from 'react'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

export default function useIsRecording() {
  const {room} = useMeetingContext()
  const [isRecording, setIsRecording] = useState(Boolean(room?.isRecording))

  useEffect(() => {
    if (room) {
      setIsRecording(room.isRecording)

      const handleRecordingStarted = () => setIsRecording(true)
      const handleRecordingStopped = () => setIsRecording(false)

      room.on('recordingStarted', handleRecordingStarted)
      room.on('recordingStopped', handleRecordingStopped)

      return () => {
        room.off('recordingStarted', handleRecordingStarted)
        room.off('recordingStopped', handleRecordingStopped)
      }
    }
  }, [room])

  return isRecording
}
