import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from '../../components/Modalv2'
import TextareaAutosize from 'react-autosize-textarea'

interface Props {
  closeModal: () => void
  actionModal: (a?: any) => void
  editItem: any
}

interface Errors {
  title?: string []
  description?: string []
}

const LinkEdit = (props: Props) => {
  const {closeModal, actionModal, editItem} = props
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState<Errors>({})

  useEffect(() => {
    setTitle(editItem.title)
    setDescription(editItem.description)
  }, [])

  const checkErrors = (e: any) => {
    const {name, value} = e.target

    switch(name) {
      case 'title':
        if(!!!value) {
          setErrors({
            ...errors,
            title: ['Title is required', ...errors.title ? errors.title : []]
          })
        } else {
          if(errors.title) {
            const {title, ...otherErrors} = errors
            setErrors({
              ...otherErrors
            })
          }
        }
      break
    }
  }

  const checkPrev = () => {
    return title === editItem.title && description === editItem.description
  }

  return (
    <Modal {...props}>
      <Container className="topbar rounded inner">
        <Title>Edit Link</Title>
        <Field>
          <Label>Title</Label>
          <Input
            className="textarea hover-container border-light"
            value={title || ''}
            name="title"
            placeholder="Link Title"
            onChange={(e: any) => {
              setTitle(e.target.value)
              checkErrors(e)
            }}
          />
          {errors?.title && errors.title.map((err) => {
            return <ErrorMessage>{err}</ErrorMessage>
          })}
        </Field>
        <Field>
          <Label>Description</Label>
          <TextArea
            rows={6}
            maxRows={6}
            placeholder="Link Description"
            className="textarea hover-container border-light"
            value={description || ''}
            name="description"
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </Field>
        <ButtonCont>
            <CloseBtn 
              className="button default"
              onClick={(e) => {
                e.preventDefault()
                closeModal()
              }}
            >
              Cancel
            </CloseBtn>
            <ActionBtn 
              className="button primary"
              onClick={(e) => {
                e.preventDefault()
                actionModal({title, description, url: editItem.url})
                closeModal()
              }}
              disabled={!!Object.values(errors).length || checkPrev()}
            > Save
            </ActionBtn>
        </ButtonCont>
      </Container>
    </Modal>
  )
}

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;
`

const ButtonCont = styled.div` 
  display: flex;
  width: 100%;
  padding: 0 12px;
  align-items: center;
  margin-top: 10px;
`
const ActionBtn = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 14px;
  margin: 4px;
  white-space: nowrap;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
const CloseBtn = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 4px;
  white-space: nowrap;
`

const Label = styled.div`
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
`

const Input = styled.input`
  outline: none;
  width: 15rem;
  font-size: 12px !important;
  border-radius: 99px !important;
`

const TextArea = styled(TextareaAutosize)`
  border-radius: 8px !important;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px !important;
`

const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`

const ErrorMessage = styled.p`
    color: red;
    margin: 8px 0 8px 8px;
    font-size: 0.8em;
`

export default LinkEdit;