import {useState, useEffect} from 'react'
import {AudioTrack, VideoTrack} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

/*
 * This hook allows components to reliably use the 'mediaStreamTrack' property of
 * an AudioTrack or a VideoTrack. Whenever 'localTrack.restart(...)' is called, it
 * will replace the mediaStreamTrack property of the localTrack, but the localTrack
 * object will stay the same. Therefore this hook is needed in order for components
 * to rerender in response to the mediaStreamTrack changing.
 */
export default function useMediaStreamTrack(track?: AudioTrack | VideoTrack | MediaStreamTrack, onboarding?: boolean) {
  const { callProvider } = useMeetingContext()
  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
        
  const [mediaStreamTrack, setMediaStreamTrack] = useState<MediaStreamTrack>(
    isTwilio || onboarding ? (track as AudioTrack | VideoTrack)?.mediaStreamTrack : ((track as AudioTrack | VideoTrack)?.mediaStreamTrack || track as MediaStreamTrack)
  )

  useEffect(() => {
    if(!track) return
    const trck: any = isTwilio || onboarding ? (track as AudioTrack | VideoTrack)?.mediaStreamTrack : ((track as AudioTrack | VideoTrack)?.mediaStreamTrack || track as MediaStreamTrack) ?? null
    setMediaStreamTrack(trck)

    if (trck && (isTwilio || onboarding)) {
      const handleStarted = () => {
        if(mediaStreamTrack?.kind === 'audio') {
          if(mediaStreamTrack && track && mediaStreamTrack?.id !== (track as AudioTrack)?.name) {
            mediaStreamTrack.stop()
          }
        }
        setMediaStreamTrack(trck)
      };
      (track as AudioTrack | VideoTrack).on('started', handleStarted);
      return () => {
        (track as AudioTrack | VideoTrack).off('started', handleStarted);
      }
    }
  }, [track, onboarding])

  return mediaStreamTrack
}
