import React from 'react'
import styled from 'styled-components'
import { FaCrown as HostIcon } from '@react-icons/all-files/fa/FaCrown'
import CustomTip from '../../../../helpers/CustomTip'

interface Props {
	transferHostAccess?: () => void
	showTransferAccess?: boolean
}

function MoreCallItemControlsTwilio(props: Props) {
	return (
		<Container className="call-more-controls-container">
			{
				props.showTransferAccess &&
				<IconButtonMore
					title="Make Host"
					className="button default-opaque call-control"
					onClick={() => {
						if (props.transferHostAccess) props.transferHostAccess()
					}}
				>
					<HostIcon data-tip data-for='makeH' />
					<CustomTip top={-40} left={-25} class='top-right' place='left' tip='Make Host' dataFor='makeH' />

				</IconButtonMore>
			}
		</Container>
	)
}

export default MoreCallItemControlsTwilio

const Container = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 25px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
    z-index: 1;
`

export const IconButtonMore = styled.div<{ muted?: boolean, active?: boolean }>`
	/* color: ${props => props.active ? '#008bff !important' : 'white'}; */
	// padding: 16px !important;
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 4px;
	border-radius: 2px;
	font-size: 20px;
	transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
	/* box-shadow: ${(props) => props.theme.shadows.light}; */
	opacity: 0;
	position: relative;
	/* ${(props) => (props.muted ? 'opacity: 1!important;' : '')}  */
	cursor: pointer;
`


const XLine = styled.div`
	position: absolute;
	height: 1px;
	width: 24px;
	background-color: #fff;
	transform: rotate(135deg);
	pointer-events: none;
`

// const Dropdown = styled(DropdownWrapper)<{screenSharing?: boolean}>`
// 	.dropdown-content {
// 		position: fixed;
// 		bottom: unset;
// 		transform: translate(-100px, -100px);
// 	}

// 	@media (max-width: 970px) {
// 		.dropdown-content {
// 			position: fixed;
// 			bottom: unset;
// 			transform: translate(-100px, -100px);
// 		}
// 	}

// `
