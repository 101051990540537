import React, { Component } from 'react'
import MeetingInfo from './MeetingInfo/'
import EventCalendar from './EventCalendar/'
import ScheduleList from './ScheduleList'
import ProposeMeeting from './ProposeMeeting'
import LoopApi from '/helpers/LoopApi'
import styled from 'styled-components'

export default class Scheduler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      proposeMeetingModal: false,
      viewMeetingInfo: false,
      eventCalendarView: false,
      activeMeetingId: -1,
      scheduleList: [],
      events: [],
      events_v: 0,
      schedule_v: 0,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.schedule)
      this.setState({
        scheduleList: nextProps.data.schedule,
        schedule_v: nextProps.data.schedule_v || this.state.schedule_v,
      })
    if (nextProps.data.events)
      this.setState({
        events: nextProps.data.events,
        events_v: nextProps.data.events_v || this.state.events_v,
      })
  }

  UNSAFE_componentWillMount() {
    //console.log(this.props)
    this.getAllScheduleOnLoad()
    this.getAllEventsOnLoad()
  }

  async getAllEventsOnLoad() {
    const resp =
      (await LoopApi(null, 'GetSchedulerData', {
        key: 'events',
        meeting_name: this.props.meetingName,
      })) || []
    this.setState({
      loading: false,
      events: resp.data,
      events_v: resp.events_v,
    })
  }

  async getAllScheduleOnLoad() {
    const resp =
      (await LoopApi(null, 'GetSchedulerData', {
        key: 'schedule',
        meeting_name: this.props.meetingName,
      })) || []
    this.setState({
      loading: false,
      scheduleList: resp.data,
      schedule_v: resp.schedule_v,
    })
  }

  setMainState(_state) {
    if (!_state) return false
    this.setState(_state)
  }

  getUserNameById(user) {
    const users = this.props.users
    const ind = this.findIndByKey(users, user, 'id')
    return ind > -1 ? users[ind].name : 'N/A'
  }

  findIndByKey(data, val, key) {
    if (!key) return data.findIndex(d => d === val)
    return data.findIndex(d => d[key] === val)
  }

  async notifyUserInSlack(msg, user) {
    const users = [...this.props.users]
    const ind = this.findIndByKey(users, user, 'id')
    const slack_id = users[ind].slack_id
    if (!slack_id || slack_id === '') return false

    LoopApi(null, 'NotifyUserInSlack', {
      slack_id: slack_id,
      meeting_name: this.props.meetingName,
      message: msg,
    })
  }

  render() {
    const schedList = (
      <div>
        <button onClick={() => this.setState({ eventCalendarView: true })}>
          Event Calendar
        </button>
        <button onClick={() => this.setState({ proposeMeetingModal: true })}>
          Propose Meeting
        </button>
        <ScheduleList
          data={this.state.scheduleList}
          setMainState={this.setMainState.bind(this)}
          schedule_v={this.state.schedule_v}
        />
      </div>
    )
    const proposeMeetingModal = (
      <ProposeMeeting
        setMainState={this.setMainState.bind(this)}
        user={this.props.userId}
        meeting_name={this.props.meetingName}
        UpdateSelf={this.props.actions.UpdateSelf}
      />
    )

    const meetingInfo = (
      <MeetingInfo
        data={this.state.scheduleList}
        setMainState={this.setMainState.bind(this)}
        activeMeetingId={this.state.activeMeetingId}
        users={this.props.users}
        user={this.props.userId}
        meeting_name={this.props.meetingName}
        UpdateSelf={this.props.actions.UpdateSelf}
        getUserNameById={this.getUserNameById.bind(this)}
        findIndByKey={this.findIndByKey.bind(this)}
        schedule_v={this.state.schedule_v}
        notifyUserInSlack={this.notifyUserInSlack.bind(this)}
      />
    )
    const eventCalendar = (
      <div>
        <EventCalendar
          findIndByKey={this.findIndByKey.bind(this)}
          events={this.state.events}
          setMainState={this.setMainState.bind(this)}
          getUserNameById={this.getUserNameById.bind(this)}
          UpdateSelf={this.props.actions.UpdateSelf}
          meeting_name={this.props.meetingName}
          users={this.props.users}
          events_v={this.state.events_v}
          notifyUserInSlack={this.notifyUserInSlack.bind(this)}
        />
      </div>
    )
    const meetingSchedulerView = (
      <div>
        {this.state.proposeMeetingModal && proposeMeetingModal}
        {!this.state.eventCalendarView && this.state.viewMeetingInfo ? (
          meetingInfo
        ) : (
          schedList
        )}
        <br />
      </div>
    )
    return (
      <Container>
        {this.state.eventCalendarView ? eventCalendar : meetingSchedulerView}
      </Container>
    )
  }
}

const Container = styled.div`overflow: auto;`
