import React from 'react'
import { CallingProviderName } from '../../../../../../contexts/types'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import CustomTip from '../../../../../../helpers/CustomTip'

import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'
import useStreamStates from '../../hooks/useStreamStates'
import useVideoContext from '../../hooks/useVideoContext'

export const SCREEN_SHARE_TEXT = 'Share Screen'
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen'
export const SHARE_IN_PROGRESS_TEXT =
  'Cannot share screen when another user is sharing'
export const SHARE_NOT_SUPPORTED_TEXT =
  'Screen sharing is not supported with this browser'

export default function ToggleScreenShareButton(props: {disabled?: boolean}) {
  const screenShareParticipant = useScreenShareParticipant()
  const {toggleScreenShare, isSharingScreen: tIsSharingScreen} = useVideoContext()
  const {isSharingScreen: dIsSharingScreen} = useStreamStates()
  const {callProvider} = useMeetingContext()
  const disableScreenShareButton = Boolean(screenShareParticipant)
  const isScreenShareSupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
  const isDaily = callProvider === CallingProviderName.DailyCo

  const isSharingScreen = isDaily ? dIsSharingScreen : tIsSharingScreen
  const isDisabled =
    (props.disabled || disableScreenShareButton || !isScreenShareSupported) &&
    !isSharingScreen

  let tooltipMessage = ''

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT
  }

  if (isSharingScreen) {
    tooltipMessage = STOP_SCREEN_SHARE_TEXT
  }

  return (
    <div data-tip data-for="sshare">
      <button
        className={`button ${
          isSharingScreen ? 'primary' : 'default'
        } controlbutton`}
        onClick={toggleScreenShare}
        disabled={isDisabled}
        style={{padding: '10px'}}
      >
        {/* { isSharingScreen ? STOP_SCREEN_SHARE_TEXT : SCREEN_SHARE_TEXT} */}
        <ScreenshareIcon />
      </button>
      <CustomTip
        top={-20}
        left={10}
        class="top-left"
        place="right"
        tip={
          isDisabled
            ? 'Someone is screen sharing'
            : isSharingScreen
            ? 'Stop screen sharing'
            : 'Screen share'
        }
        dataFor="sshare"
      />
    </div>
  )
}

const ScreenshareIcon = () => {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.3407 15.3363C31.3407 15.8629 31.131 16.3679 30.7578 16.7402C30.3846 17.1125 29.8785 17.3217 29.3507 17.3217H17.4109H13.4309V5.40924C13.4309 4.88267 13.6406 4.37768 14.0138 4.00534C14.387 3.633 14.8931 3.42383 15.4209 3.42383H29.3507C29.8785 3.42383 30.3846 3.633 30.7578 4.00534C31.131 4.37768 31.3407 4.88267 31.3407 5.40924V15.3363Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3907 23.2777C21.3907 23.8043 21.1811 24.3093 20.8079 24.6816C20.4347 25.0539 19.9285 25.2631 19.4008 25.2631H7.46091H3.48096V13.3506C3.48096 12.8241 3.69061 12.3191 4.06381 11.9467C4.437 11.5744 4.94316 11.3652 5.47093 11.3652H19.4008C19.9285 11.3652 20.4347 11.5744 20.8079 11.9467C21.1811 12.3191 21.3907 12.8241 21.3907 13.3506V23.2777Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
