import React from 'react'
import { FiEdit as NewIcon } from '@react-icons/all-files/fi/FiEdit'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import { CgArrowsExpandUpLeft as Expand } from '@react-icons/all-files/cg/CgArrowsExpandUpLeft'

import { HeaderLeft, HeaderRight, HeaderWrapper } from '../styledcomponents'
import { CallingInstanceState } from '../../../../../../calling/types'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { WidgetActions } from '../../../../../../stores/MainStore'
import CustomTip from '../../../../../../helpers/CustomTip'
import useNotesContext from '../useNotesContext'

function Header() {
  const { callStatus } = useMeetingContext()
  const { addNewNotes, updateActiveSync } = useNotesContext()

  return (
    <HeaderWrapper className="border-light">
        <HeaderLeft>Notes</HeaderLeft>
        <HeaderRight>
            <NewIcon size={20} onClick={() => addNewNotes({ isPrivate: true })} data-tip data-for='notes-new-mini' />

            <Expand className={callStatus === CallingInstanceState.Connected ? 'disabled' : ''} size={20} onClick={() => {
                if(callStatus === CallingInstanceState.Connected) return
                WidgetActions.ExpandFloatingWidgets({ currentWidget: 'sharedbnotes', localPush: true })
                updateActiveSync('')
            }
            } data-tip data-for='notes-expand-mini' />
            <CloseIcon size={20} onClick={() => updateActiveSync('')} />
        </HeaderRight>
        <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Expand' dataFor='notes-expand-mini' />
        <CustomTip top={-20} left={-10} class='top-right' place='left' tip='New Note' dataFor='notes-new-mini' />
    </HeaderWrapper>
  )
}

export default Header