import React, {useState, useEffect} from 'react'
import {VideoRoomMonitor} from '@twilio/video-room-monitor'
import {isMobile} from '../../../../../../calling/_helpers'
import {FiMoreHorizontal as SettingsIcon} from '@react-icons/all-files/fi/FiMoreHorizontal'
import {FiPhone as EndCallIcon} from '@react-icons/all-files/fi/FiPhone'
import {CgOptions as MoreSettingsIcon} from '@react-icons/all-files/cg/CgOptions'
import {BsLayoutWtf as LayoutIcon} from '@react-icons/all-files/bs/BsLayoutWtf'
import {FaCrown as CrownIcon} from '@react-icons/all-files/fa/FaCrown'
import {FiUsers as UsersIcon} from '@react-icons/all-files/fi/FiUsers'
// import BackgroundIcon from '../../../../../../assets/icons/background-icon.svg'
import {VscMirror} from '@react-icons/all-files/vsc/VscMirror'

import useRoomState from '../../hooks/useRoomState'
import useVideoContext from '../../hooks/useVideoContext'
import ToggleAudioButton from './ToggleAudioButton'
import ToggleScreenShareButton from './ToggleShareScreenButton'
import ToggleVideoButton from './ToggleVideoButton'
import {VariableCallingActions} from '../../../../../../stores/VariableCallingStore'
import DropdownWrapper from '../../../../../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'

import useView, {MeetingLayouts, MeetingViews} from '../../hooks/useView'
import styled from 'styled-components'
import useIsRecording from '../../hooks/useIsRecording'
import ToggleRecordingButton from './ToggleRecordingButton'
import CustomTip from '../../../../../../helpers/CustomTip'
import LoopApi from '../../../../../../helpers/LoopApi'
import useToastify from '../../../../../../helpers/useToastify'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { Room } from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'
import { CallingProviderName } from '../../../../../../contexts/types'
import { closeAwsTranscriber } from '../../../../../../helpers/aws-transcriber'
import { ModalActions } from '../../../../../../stores/ModalStore'
import useLocalTracks from '../../../../../../contexts/useLocalTracks'
import { toast } from 'react-toastify'

type Props = {
  openSettingsModal: Function
  openBackgroundModal: Function
  openLayoutModal: Function
  openMeetingParticipantsModal: Function
  layout: string
  setLayout: Function
  toggleFullScreen: Function
  isFullScreen: boolean
}

const is_mobile = window.matchMedia('(max-width: 767px)')

export default function MeetingControls(props: Props) {
  const {openSettingsModal, openBackgroundModal, openLayoutModal, openMeetingParticipantsModal} = props

  const {host, users, setCameraFlipped, sessionData, currentWidgetName} =
    useVideoContext()
  const { setView, setLayout } = useView()
  const {meeting, room, callProvider} = useMeetingContext()
  const { stopLocalTracks, removeLocalAudioTrack } = useLocalTracks()
  const localParticipant = (room as Room)!.localParticipant || (room as DailyCall)?.participants()?.local
  const getUser = (id: string) => users.find((u) => u.id === id)
  //@ts-ignore  
  const user = getUser(localParticipant?.identity?.split('::')?.[1] || ((localParticipant)?.user_name || '')?.split('::')?.[1])
  const roomState = useRoomState()
  const isReconnecting = roomState === 'reconnecting'
  const [isMobile, setIsMobile] = useState(is_mobile.matches)

  const [ disableAsk, setDisableAsk ] = useState(false)

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  useEffect(() => {
    is_mobile.addListener(mediaChange)
    return () => {
      is_mobile.removeListener(mediaChange)
    }
  }, [])

  const mediaChange = ({matches}: {matches: any}) => {
    setIsMobile(matches)
  }

  const askHostAccess = async () => {
    setDisableAsk(true)
    try {
      const resp = await LoopApi(null, 'AskHostAccess', {host_id: host}, [
        ['name', meeting.name],
      ])

      if (resp.status === 'failed') {
        useToastify({
          type: 'error',
          message: 'Denied access',
        })
      }

      setTimeout(() => {
        setDisableAsk(false)
      }, 10000)

      // this.setState({ status: resp.status })
      // this.knockTimeout = setTimeout(() => this.tryKnock(), 2000)
    } catch (e) {
      console.error(e)
      setDisableAsk(false)
      // this.setState({ status: 'failed' })
    }
  }

  const MoreOptions = (props: any) => {
    return (
      <React.Fragment>
        <Item
          className={`hover-container`}
          onClick={() => {
            openSettingsModal()
            props.onClose()
          }}
        >
          <div className="item-icon">
            <MoreSettingsIcon />
          </div>
          {'Settings'}
        </Item>
        <Item
          className={`hover-container`}
          onClick={() => {
            openLayoutModal()
            props.onClose()
          }}
        >
          <div className="item-icon">
            <LayoutIcon />
          </div>
          {'Layout'}
        </Item>
        {/* {!!!isMobile && (
          <Item
            id="video-bg-option"
            className={`hover-container svg-nopadding`}
            onClick={() => {
              openBackgroundModal()
              props.onClose()
            }}
          >
            <div className="item-icon">
              <BackgroundIcon />
            </div>
            {'Background'}
          </Item>
        )} */}
        {user?.id !== host && (
          <Item
            id="ask-host-access-option"
            className={`hover-container svg-nopadding`}
            onClick={() => {
              // openBackgroundModal()
              askHostAccess()
              props.onClose()
            }}
            disabled={disableAsk}
          >
            <div className="item-icon">
              <CrownIcon />
            </div>
            {'Ask host access'}
          </Item>
        )}
        {
          <Item
            id="camera-flip-option"
            className={`hover-container svg-nopadding`}
            onClick={async () => {
              try {
                // @ts-ignore
                const currentStatus = sessionData.cameraFlipStatus[localParticipant?.identity || localParticipant?.user_name || '']
                const updatedStatus = currentStatus === undefined ? false : !currentStatus
                setCameraFlipped(
                  updatedStatus,
                )
              } catch (err) {
                console.error('Mirrored Camera Error', err)
              }
            }}
          >
            <div className="item-icon">
              <CameraFlipIcon />
            </div>
            {sessionData.cameraFlipStatus[localParticipant?.identity || localParticipant?.user_name || ''] ? 'Non-mirrored Camera' : 'Mirrored Camera'}
          </Item>
        }
        {(!!!isMobile && isTwilio) && (
          <Item
            className={`hover-container svg-nopadding room-monitor`}
            onClick={() => {
              VideoRoomMonitor.toggleMonitor()
              props.onClose()
            }}
          >
            {'Room Monitor'}
          </Item>
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="meeting-controls">
      {/* <button
        className="button default controlbutton"
        onClick={async () => {
          try {
            setCameraFlipped(!sessionData.cameraFlipStatus[localParticipant.identity])
          } catch (err) {
            //console.log('Camera Flip Error', err)
          }
        }}
        disabled={false}
        data-tip
        data-for='flip'
      ><VscMirror /></button> */}
      {!!!isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip="Flip Camera"
          dataFor="flip"
        />
      )}

      <ToggleAudioButton disabled={isReconnecting} isMobile={isMobile} />
      <ToggleVideoButton disabled={isReconnecting} isMobile={isMobile} />
      {
        // isTwilio &&
        <ToggleRecordingButton
          disabled={user?.id !== host}
          h={host}
          isMobile={isMobile}
        />
      }
      {!isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
      {!!!isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip="Leave Meeting"
          dataFor="leave"
        />
      )}

      <Dropdown DropdownItems={MoreOptions} isTwilio={isTwilio} isWithAskHost={user?.id !== host} width={'224px'}>
        <button
          className="button default controlbutton"
          data-tip
          data-for="moption"
        >
          <SettingsIcon />
        </button>
      </Dropdown>
      {!!!isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip="More Option"
          dataFor="moption"
        />
      )}
      <button
        className="button default controlbutton"
        onClick={() => { openMeetingParticipantsModal() }}
        data-tip
        data-for="people"
      >
        <UsersIcon />
      </button>
      {!!!isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip="Meeting Participants"
          dataFor="people"
        />
      )}
      <button
        className="button danger controlbutton"
        onClick={() => ModalActions.SetModalWithParams('ConfirmLeaveMeeting', {
          cb: () => {
            if(callProvider === CallingProviderName.DailyCo) {
              // (room as DailyCall)?.stopRecording();
              (room as DailyCall)?.leave();
              (room as DailyCall)?.destroy();
              removeLocalAudioTrack();
              stopLocalTracks()
            } else {
              (room as Room)?.disconnect();
            }
            toast.dismiss('meetingRecordingNotif')
            closeAwsTranscriber();
          }
        })}
        data-tip
        data-for="leave"
      >
        <EndCallIcon />
      </button>

      {/* <button className="button default controlbutton" onClick={() => { toggleFullScreen() }}>

      </button> */}
      {/* <button onClick={() => openBackgroundModal()}>Background</button> */}
      {/* <button onClick={() => setLayout(MeetingLayouts.Default)} disabled={layout === MeetingLayouts.Default}>
        Default
      </button>
      {
        !currentWidgetName && <button onClick={() => setLayout(MeetingLayouts.Grid)} disabled={!!currentWidgetName || layout === MeetingLayouts.Grid}>
          Grid
        </button>
      }
      <button onClick={() => setLayout(MeetingLayouts.Floating)} disabled={layout === MeetingLayouts.Floating}>
        Floating
      </button> */}
    </div>
  )
}

export const Item = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 5px 4px;
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & > img,
  & > svg {
    margin-right: 8px;
  }

  & > svg {
    font-size: 20px;
    padding: 0 5px;
  }

  &.svg-nopadding {
    > svg {
      padding-left: 0px;
    }
  }

  &:hover {
    /* background-color: rgba(0, 200, 0, 0.075); */
    cursor: pointer;
  }

  .item-icon {
    min-width: 30px;

    > svg {
      width: 18px !important;
      height: auto;
    }
  }

  ${props => props.disabled && `
    opacity: 0.6;
    pointer-events: none;
  `}
`

const Dropdown = styled(DropdownWrapper)<{isTwilio?: boolean, isWithAskHost?: boolean}>`
  .dropdown-content {
    position: fixed;
    bottom: unset;
    padding: 15px;
    transform: translate(-70px, ${({isTwilio, isWithAskHost}) => isTwilio && isWithAskHost ? '-260px' : isTwilio || (!isTwilio && isWithAskHost) ? '-225px' : '-185px'});
    /* -225 Twilio, -185 daily */
    /* -295 Twilio with bg */
    /* -260 Twilio with host */
    /* daily: -225 with host */


    & > div {
      height: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    .dropdown-content {
      transform: translate(-70px, ${({isTwilio, isWithAskHost}) => isTwilio && isWithAskHost || (!isTwilio && isWithAskHost) ? '-225px' : '-185px'});
      /* daily: -185 */
      /* daily: -225 with host */
      /* Twilio: -220 with bg */
      /* Twilio: -225 with host */
    }
  }
`

const BackgroundIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-fill"
    >
      <path d="M9.3105 6L3 12.3105V10.1895L7.1895 6H9.3105Z" />
      <path d="M5.25002 24H7.50002V21.4395L4.95752 23.982C5.05352 23.994 5.15252 24 5.25002 24Z" />
      <path d="M22.8105 24H22.5V22.1895L27 17.6895V19.8105L22.8105 24Z" />
      <path d="M27.0003 14.5605L22.3533 19.2075C22.2065 18.7003 21.954 18.23 21.6123 17.8275L27.0003 12.4395V14.5605Z" />
      <path d="M18.75 18C19.0456 17.9996 19.3383 18.0575 19.6115 18.1705C19.8847 18.2834 20.1329 18.4491 20.3419 18.6581C20.5509 18.8671 20.7166 19.1153 20.8295 19.3885C20.9425 19.6617 21.0004 19.9544 21 20.25V24H19.5V20.25C19.5 20.0511 19.421 19.8603 19.2803 19.7197C19.1397 19.579 18.9489 19.5 18.75 19.5H11.25C11.0511 19.5 10.8603 19.579 10.7197 19.7197C10.579 19.8603 10.5 20.0511 10.5 20.25V24H9V20.25C9.0004 19.6534 9.23758 19.0813 9.65945 18.6595C10.0813 18.2376 10.6534 18.0004 11.25 18H18.75Z" />
      <path d="M14.5827 16.4773C13.928 16.403 13.3043 16.1581 12.7739 15.7672C12.2435 15.3763 11.825 14.8531 11.5602 14.2498C11.31 13.6763 11.2079 13.0491 11.2632 12.4258C11.3274 11.6829 11.6117 10.976 12.0797 10.3955C12.5477 9.81498 13.1781 9.38715 13.8905 9.16668C14.6028 8.94621 15.3647 8.94311 16.0788 9.15779C16.7929 9.37246 17.4268 9.79516 17.8995 10.3719C18.3722 10.9486 18.6622 11.6531 18.7325 12.3955C18.8028 13.1378 18.6502 13.8843 18.2942 14.5395C17.9381 15.1947 17.3949 15.7288 16.7338 16.0738C16.0726 16.4187 15.3237 16.5586 14.5827 16.4758V16.4773ZM14.9997 14.9998C15.5964 14.9998 16.1687 14.7628 16.5906 14.3408C17.0126 13.9188 17.2497 13.3466 17.2497 12.7498C17.2497 12.1531 17.0126 11.5808 16.5906 11.1588C16.1687 10.7369 15.5964 10.4998 14.9997 10.4998C14.4029 10.4998 13.8306 10.7369 13.4087 11.1588C12.9867 11.5808 12.7497 12.1531 12.7497 12.7498C12.7497 13.3466 12.9867 13.9188 13.4087 14.3408C13.8306 14.7628 14.4029 14.9998 14.9997 14.9998Z" />
      <path d="M3.18 22.6316L10.446 15.3641C10.1711 14.8863 9.97362 14.3678 9.861 13.8281L3 20.6891V21.7496C3 22.0631 3.0645 22.3601 3.18 22.6316Z" />
      <path d="M17.6146 8.196C17.1367 7.92106 16.6183 7.72361 16.0786 7.611L17.6896 6H19.8106L17.6146 8.196Z" />
      <path d="M19.9798 11.082C19.812 10.5808 19.5692 10.1079 19.2598 9.6795L22.9393 6H24.7498C24.8488 6 24.9448 6.006 25.0423 6.0195L19.9783 11.082H19.9798Z" />
      <path d="M18.6753 16.4996C19.3338 15.8546 19.8228 15.0371 20.0703 14.1191L26.8203 7.36914C26.9373 7.63914 27.0003 7.93614 27.0003 8.24964V9.31014L19.6923 16.6181C19.3923 16.5416 19.0758 16.4996 18.7503 16.4996H18.6753Z" />
      <path d="M3 17.5605L14.5605 6H12.4395L3 15.4395V17.5605Z" />
    </svg>
  )
}

const CameraFlipIcon = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-fill"
    >
      <path d="M2.83203 12.9009C2.83203 13.9355 3.24975 14.9277 3.99331 15.6593C4.73686 16.3909 5.74533 16.8019 6.79688 16.8019C7.84842 16.8019 8.85689 16.3909 9.60044 15.6593C10.344 14.9277 10.7617 13.9355 10.7617 12.9009C10.7617 11.8663 10.344 10.8741 9.60044 10.1426C8.85689 9.41099 7.84842 9 6.79688 9C5.74533 9 4.73686 9.41099 3.99331 10.1426C3.24975 10.8741 2.83203 11.8663 2.83203 12.9009ZM12.4609 23.4892H0.566406C0.416186 23.4892 0.272118 23.4305 0.165897 23.3259C0.0596749 23.2214 0 23.0797 0 22.9319V21.2601C0 20.5211 0.298374 19.8123 0.829483 19.2898C1.36059 18.7672 2.08093 18.4737 2.83203 18.4737H10.7617C11.5128 18.4737 12.2332 18.7672 12.7643 19.2898C13.2954 19.8123 13.5938 20.5211 13.5938 21.2601V22.9319C13.5938 23.0797 13.5341 23.2214 13.4279 23.3259C13.3216 23.4305 13.1776 23.4892 13.0273 23.4892H12.4609Z" />
      <path d="M17.832 9.90093C17.832 10.9355 18.2498 11.9277 18.9933 12.6593C19.7369 13.3909 20.7453 13.8019 21.7969 13.8019C22.8484 13.8019 23.8569 13.3909 24.6004 12.6593C25.344 11.9277 25.7617 10.9355 25.7617 9.90093C25.7617 8.86634 25.344 7.87412 24.6004 7.14256C23.8569 6.41099 22.8484 6 21.7969 6C20.7453 6 19.7369 6.41099 18.9933 7.14256C18.2498 7.87412 17.832 8.86634 17.832 9.90093ZM26 20L15.5664 20.4892C15.4162 20.4892 15.2721 20.4305 15.1659 20.3259C15.0597 20.2214 15 20.0797 15 19.9319V18.2601C15 17.5211 15.2984 16.8123 15.8295 16.2898C16.3606 15.7672 17.0809 15.4737 17.832 15.4737H25.7617C26.5128 15.4737 27.2332 15.7672 27.7643 16.2898C28.2954 16.8123 27.5 17.761 27.5 18.5C27.5 18.6478 27.2441 18 26.7441 19.5C25.3163 17.4341 26.1502 20 26 20Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 0H11C8.23858 0 6 2.23858 6 5V8.1069C6.32518 8.03641 6.66056 8 7 8C7.33944 8 7.67482 8.03641 8 8.1069V5C8 3.34315 9.34315 2 11 2H23C24.6569 2 26 3.34315 26 5V17C26 18.6569 24.6569 20 23 20H14.4087C14.4689 20.2247 14.5 20.457 14.5 20.6923V22H23C25.7614 22 28 19.7614 28 17V5C28 2.23858 25.7614 0 23 0ZM6 15.7714C6.41076 15.6714 7.02512 15.5277 8 15.3212V15.308L6 15.7034V15.7714Z"
      />
    </svg>
  )
}
