import React, { useEffect } from 'react'
import styled from 'styled-components'

import { IFakeMediasoupOuterTrack } from '../../../Sidebar/UsersInCall/types'
import { ParticipantsTileWrapper } from '../styles'
import ScreenTrack from './ScreenTrack'

interface Props {
    screenTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack,
    onClick?: () => void
}

function PresentationTile(props: Props) {
    const { screenTrack } = props

    useEffect(() => {
        updateLayout()
        window?.addEventListener('resize', updateLayout)
        return () => {
            window?.removeEventListener('resize', updateLayout)
        }
    }, [screenTrack])

    const checkDiv = (increment: number, count: number, width: number, height: number, margin: number = 10) => {
        let i: number = 0, w: number = 0
        let h = increment * 0.75 + (margin * 2)

        while (i < (count)) {
            if ((w + increment) > width) {
                w = 0
                h = h + (increment * 0.75) + (margin * 2)
            }
            w = w + increment + (margin * 2)
            i++
        }
        if (h > height) return false
        else return increment
    }

    const updateLayout = () => {
        let margin: number = 2, width: number = 0, height: number = 0;
        let callContainer: HTMLElement | null = document.getElementById('presentationContainer');

        if(callContainer !== null) {
            width = callContainer.offsetWidth - (margin * 2);
            height = callContainer.offsetHeight - (margin * 2);
        }

        let callParticipants: HTMLCollectionOf<Element> = document.getElementsByClassName('presentationParticipant');
        
        let max: number = 0;
        let i: number = 1;
        while (i < 5000) {
            let w = checkDiv(i, callParticipants?.length, width, height, margin);
            if (w === false) {
                max =  i - 1
                break
            }
            i++
        }

        max = max - (margin * 2);
        setWidth(max, margin);
    }

    const setWidth = (width: number, margin: number) =>  {
        let callParticipants = document.querySelectorAll<HTMLElement>('.presentationParticipant');

        for (var s = 0; s < callParticipants.length; s++) {
            callParticipants[s].style.width = (width + 356) + "px";
            callParticipants[s].style.margin = margin + "px";
            callParticipants[s].style.height = (width * 0.75) + "px";
        }
    }

    return (
        <PresentationContainer id="presentationContainer" onClick={props.onClick}>
            <ParticipantsTileWrapper className="presentationParticipant container rounded inner">
                <ScreenTrack key={`${Math.random()}-videotrack`} track={screenTrack}/>
            </ParticipantsTileWrapper>
        </PresentationContainer>
    )
}

const PresentationContainer = styled.div`
    width: calc(100% - 220px);
    min-width: calc(100% - 220px);
    justify-content: center;
    overflow: auto;
    height: 100%;
    display: flex;

    & + #callContainer {
        width: 220px;
        left: auto;
        bottom: auto;
        min-height: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        align-content: baseline;
        /* padding-top: 50px;
        padding-right: 20px; */


        > .callParticipants {
            min-width: 200px;
            max-width: 200px;
            height: 160px;
            max-height: 160px;
            min-height: 160px;

            .host-indicator {
                width: 14px;
                
                svg {
                    width: 14px;
                    margin-right: 5px;
                }
            }


            .call-control {
                padding: 12px !important;
                font-size: 14px;
                margin: 2px !important;
            }
        }

        > .screenshareTile {
            display: none;
        }
    }

    @media (max-width: 970px) {
        margin-top: 160px;
    }

    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 160px);
        min-height: calc(100% - 160px);
        max-height: calc(100% - 160px);
        margin-top: 80px;

        & + #callContainer {
            width: 100%;
            top: auto;
            bottom: 0;
            min-height: 160px;
            max-height: 160px;
            height: 160px;
            align-content: center;
            left: 0;
            overflow: auto;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: baseline;
            > .callParticipants {
                max-width: 150px;
                min-width: 150px;
                max-height: 130px;
                min-height: 130px; 

                .call-control {
                    padding: 10px !important;
                    font-size: 14px;
                    margin: 2px !important;
                }
            }
        }
    }
`

function areEqual(prevProps: Props, nextProps: Props) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    return JSON.stringify(prevProps.screenTrack) === JSON.stringify(nextProps.screenTrack)
}

export default React.memo(PresentationTile, areEqual)
