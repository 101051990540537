import moment from 'moment'
import styled from 'styled-components'
import { Error } from 'sharedb'
import React, { MouseEvent, useEffect, useMemo, useState } from 'react'
import { IoCloseCircle as CloseIcon } from '@react-icons/all-files/io5/IoCloseCircle'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'

import { NotesItemWrapper } from '../styledcomponents'
import DropdownWrapper from '../../../../../../loop-widgets/_Shared/DropdownWrapper'
import Modal from '../../../../../../loop-widgets/_Shared/Modal'
import useNotesContext from '../useNotesContext'
import DeleteNotes from '../modals/DeleteNotes'
import MoreOptions from '../Content/MoreOptions'

interface NotesItemProps {
    id: string
    isActive: boolean
}

interface INotesData { 
    id: string
    title: string
    content: string
    created_by: string
    date_created: string
    date_updated: string
    updated_by: string
    isPrivate: boolean
}

function Items({
    id,
    isActive
}: NotesItemProps) {

    const { setActiveNote, activeNote, deleteNote, userId, connection, setInitialized, setInitializedTitle } = useNotesContext()
    const [ openDelete, setOpenDelete ] = useState<boolean>(false)
    const [notesData, setNotesData] = useState<INotesData>({
        id: '',
        title: '',
        content: '',
        created_by: '',
        date_created: '',
        date_updated: '',
        updated_by: '',
        isPrivate: false
    })

    const checkDate = (curDate: any) => {
        return curDate ? moment(curDate).calendar({
            sameDay: '[Today]',
        }) : ''
    }

    useEffect(() => {
        //@ts-ignore
        const note =  connection.get("sharednotessidebar", id);
        function unsubscribeNotesDoc() {
            note.unsubscribe((err: any) => {
                // console.error("note item unsubscribe error: ", {err})
            })
        }
        
        note.subscribe(update)
        note.on('op', update)
        function update(err: Error) {
            // if(err) //console.log("Error in notes item: ", {err, id})
            setNotesData({ ...(note?.data || {}) })
        }
        return () => {
            unsubscribeNotesDoc()
        }
    }, [id, userId])

    const similar = activeNote === id

    return useMemo(() => {
        const { 
            title,
            content,
            date_created: dateCreated,
            date_updated: dateUpdated,
            isPrivate,
            created_by
         } = notesData
        return <NotesItemWrapper className={isActive ? 'topbar' : ''} onClick={() => {
            if(!similar) {
                setActiveNote(id); setInitialized(false); setInitializedTitle(false);
            }
        }}>
        <Modal show={openDelete} overlayClicked={() => setOpenDelete(false)}>
            <DeleteNotes
                handleClose={() => setOpenDelete(false)}
                handleSubmit={(e: any) => { 
                    deleteNote({ id, e })
                    setOpenDelete(false)
                }}
            />
        </Modal>
        <div className="preview-header">
            <div className="preview-title">{title || 'New Note'}</div>
            <div className="preview-date preview-options">
                <Dropdown items={MoreOptions} componentProps={{ setOpenDelete, created_by, userId, isPrivate, id }}>
                    <MoreOptionsIcon size={18} />
                </Dropdown>
            </div>
        </div>
        <div className='preview-header'>
            <div className="preview-content">{content || 'No additional content'}</div>
            <div className="preview-date">
                {
                    checkDate(dateUpdated || dateCreated) === 'Today' ?
                        moment(dateUpdated || dateCreated).fromNow() :
                        dateUpdated ?
                            moment(dateUpdated).format("MMMM DD, YYYY") :
                            moment(dateCreated).format("MMMM DD, YYYY")
                }
            </div>
        </div>
        
        {isPrivate ? <div className="private-flag"><LockedIcon size={9} /></div> : ''}
    </NotesItemWrapper>
    }, [id, notesData, isActive, openDelete, similar])
}

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	position: relative;
    .dropdown-content {
        right: -10px;
    }
`

export default Items