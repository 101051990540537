import React from "react"
import styled from "styled-components"

interface Props {
  onClick: () => void
  iconSrc: string
  children: string
  outline?: boolean
}

function QuickAccess(props: Props) {
  const {onClick, iconSrc, children, outline} = props

  return (
    <Container onClick={onClick}>
        <ImageWrapper className="topbar rounded inner" outline={!!outline}>
          <img src={iconSrc} alt={children} />
        </ImageWrapper>
      {children}
    </Container>
  )
}

const ImageWrapper = styled.div<{outline: boolean}>`
  padding: 20px;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;

  & > img {
    width: 100%;
    ${({outline}) => outline ? `
      background-color: #e9e6ec;
      padding: 12px 10px;
      border-radius: 4px;
      border-color: transparent;
    ` : null}
  }
`

const Outline = styled.div`
  width: 80%;
  height: 80%;
  background-color: gray;
`

const Container = styled.div`
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`

export default QuickAccess
