import React from 'react';
import styled from 'styled-components';
import chevron from './chevron.svg';
import chevronDown from './chevron-down.svg';
import moment from 'moment';
import Select from '../../../_Shared/Select';
import FormField from '../../../_Shared/FormField';
import Api, { Actions, Endpoints } from '../../../_Shared/Api';

const Sidebar = (props: any) => {
    const { UpdateSelf } = props.actions;
    const { id, properties } = props.data?.deal;
    const { getDeals } = props;

    const goBackToDeals = () => {
        UpdateSelf({ screen: 'deals' });
    };

    const handleChange = async (e: any) => {
        await Api(
            Endpoints['Hubspot'],
            Actions['UpdateDeal'],
            props.external_token,
            { body: { dealstage: e.target.value } },
            [['dealId', id]]
        );
        await getDeals();
        UpdateSelf({ screen: 'deals' });
    };

    return (
        <>
            <Container>
                <Clickable onClick={goBackToDeals}>
                    <img src={chevron} />
                    <Title>Deals</Title>
                </Clickable>
                <Details>
                    <div className='hub-deal-name'><div className='title'>{properties?.dealname}</div> <div className='hub-deal-link' onClick={() => console.log(properties)}>edit</div></div>
                    <div className='hub-deal-amount'><div>{parseFloat(properties?.amount).toFixed(2)}</div> <div className='hub-deal-amount-link' onClick={() => console.log(properties)}>edit</div></div>
                    <div>{moment(properties?.closedate).format('lll')}</div>
                    <div>
                        <Select
                            hoverEffect
                            fitContent
                            name='dealstage'
                            placeholder={'Set deal stage'}
                            defaultValue={properties?.dealstage || ''}
                            onChange={handleChange}
                            padding={0}
                            optionsList={(props.data.stages.stages || []).map((s: any) => ({ label: s.label, value: s.stageId, order: s.displayOrder })).sort((a: any, b: any) => a.order - b.order)}
                        />
                    </div>
                </Details>
                <FoldableDetails>
                    <div className='title'>
                        <img src={chevronDown} /> About this deal
                    </div>
                    <div>{id}</div>
                    <div>{moment(properties?.createdate).format('lll')}</div>
                    <div>{properties?.pipeline}</div>
                </FoldableDetails>
            </Container>
        </>
    );
};

export default Sidebar;

const Title = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #ff9900;
    margin-left: 10px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Clickable = styled.div`
    display: flex;
    cursor: pointer;
`;

const Details = styled.div`
    margin-top: 30px;
    line-height: 20px;
    .title {
        font-weight: bold;
    }
    .hub-deal-name {
        display: flex
    }
    .hub-deal-link {
        margin-left: 5px;
        cursor: pointer;
        color: blue;
    }
    .hub-deal-amount {
        display: flex
    }
    .hub-deal-amount-link {
        margin-left: 5px;
        cursor: pointer;
        color: blue;
    }
`;

const FoldableDetails = styled.div`
    margin-top: 30px;
    line-height: 20px;
    .title {
        font-weight: bold;
        cursor: pointer;
    }
`;
