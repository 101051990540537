import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { FaLink as LinkIcon } from '@react-icons/all-files/fa/FaLink'
import Icon from './icon.svg'
import IconWithLabel from './icon-with-label.svg'
import { ThemingStore } from '../../stores/ThemingStore'

// From https://github.com/remarkablemark/youtube-video-id
const regex = /(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
const url = 'https://www.youtube.com/'
const getYouTubeVideoId = (try_string: string) => {
	const match = try_string.match(regex)
	if (match && match.length > 1) {
		return {id: match[2], url: try_string}
	}
	return {id: null}
}

interface Props {
	videoId?: string | null
	changeVideo: (videoId: string) => void
	addLink: (l: string) => void
}

interface State {
	showModal?: boolean,
	videoId?: string | null,
	error: boolean
	color_scheme: string
}

export default class VideoChanger extends Reflux.Component<typeof ThemingStore, Props, State> {
	constructor(props: Props) {
		super(props)

		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this)

		this.store = ThemingStore
        this.storeKeys = ['color_scheme']

		this.state = {
			showModal: !this.props.videoId,
			videoId: '',
			error: false,
			color_scheme: 'Light'
		}
	}

	handleSubmit(e: any) {
		e.preventDefault()
		if (!this.state.videoId || this.state.videoId.length === 0) {
			this.setState({error: true})
			return
		}

		const {id: videoId, url} = getYouTubeVideoId(this.state.videoId)
		if(videoId) {
			this.props.changeVideo(videoId)
			url && this.props.addLink(url);
			this.setState({ videoId: '', showModal: false, error: false })
		} else {
			this.setState({videoId: '', error: true})
		}
	}

	handleChange(e: any) {
		this.setState({ videoId: e.target.value, error: false })
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.videoId !== this.props.videoId && this.state.showModal) {
			this.setState({ showModal: false })
		}
	}

	render() {
		const isVideoLoaded = !!this.props.videoId;
		const letterColor = this.state.color_scheme === 'Light' ? '#282828' : '#ffffff'
		return (
			<div>
				{this.state.showModal ? (
					<SetupModal isVideoLoaded={isVideoLoaded}>
						<SetupForm onSubmit={this.handleSubmit}>
							<a href={url} target="_blank">
								<svg width="226" height="51" viewBox="0 0 226 51" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M70.5 7.89999C69.7 4.79999 67.2 2.4 64.1 1.5C58.5 0 36 0 36 0C36 0 13.5 0 7.9 1.5C4.8 2.3 2.4 4.79999 1.5 7.89999C0 13.5 0 25.2 0 25.2C0 25.2 0 36.9 1.5 42.5C2.3 45.6 4.8 48 7.9 48.9C13.5 50.4 36 50.4 36 50.4C36 50.4 58.5 50.4 64.1 48.9C67.2 48.1 69.6 45.6 70.5 42.5C72 36.9 72 25.2 72 25.2C72 25.2 72 13.5 70.5 7.89999Z" fill="#FF0000"/>
									<path d="M28.8999 36L47.4999 25.2L28.8999 14.4V36Z" fill="white"/>
									<path d="M104.4 45.8C103 44.8 102 43.4 101.4 41.3C100.8 39.3 100.5 36.6 100.5 33.2V28.7C100.5 25.3 100.8 22.6 101.5 20.5C102.2 18.4 103.3 16.9 104.7 16C106.2 15.1 108.1 14.6 110.4 14.6C112.7 14.6 114.6 15.1 116 16C117.4 17 118.4 18.5 119.1 20.5C119.8 22.5 120.1 25.3 120.1 28.6V33.2C120.1 36.6 119.8 39.3 119.2 41.3C118.6 43.3 117.5 44.8 116.1 45.8C114.7 46.7 112.7 47.2 110.3 47.2C107.8 47.3 105.9 46.8 104.4 45.8ZM112.4 40.9C112.8 39.9 113 38.2 113 35.9V26.1C113 23.8 112.8 22.2 112.4 21.1C112 20.1 111.3 19.5 110.3 19.5C109.3 19.5 108.7 20 108.3 21.1C107.9 22.1 107.7 23.8 107.7 26.1V35.9C107.7 38.2 107.9 39.9 108.3 40.9C108.7 41.9 109.4 42.4 110.4 42.4C111.3 42.4 112 41.9 112.4 40.9Z" fill={letterColor}/>
									<path d="M213.6 33.3999V35C213.6 37 213.7 38.4999 213.8 39.5999C213.9 40.5999 214.2 41.3 214.5 41.8C214.9 42.3 215.5 42.5 216.2 42.5C217.3 42.5 218 42.1 218.4 41.3C218.8 40.5 219 39.1 219 37.2L225.1 37.5999C225.1 37.8999 225.1 38.3 225.1 38.7C225.1 41.6 224.3 43.8 222.7 45.2C221.1 46.6 218.9 47.3999 215.9 47.3999C212.4 47.3999 210 46.2999 208.5 44.0999C207 41.8999 206.4 38.4999 206.4 33.8999V28.3999C206.4 23.6999 207.1 20.2 208.6 18C210.1 15.8 212.5 14.7 216.1 14.7C218.5 14.7 220.4 15.1 221.7 16C223 16.9 223.9 18.3 224.5 20.2C225 22.1 225.3 24.7 225.3 28V33.3999H213.6ZM214.5 20.1C214.1 20.5 213.9 21.2999 213.8 22.2999C213.7 23.2999 213.6 24.7999 213.6 26.8999V29.2H218.8V26.8999C218.8 24.8999 218.7 23.2999 218.6 22.2999C218.5 21.2999 218.2 20.5 217.9 20.1C217.5 19.7 217 19.5 216.2 19.5C215.4 19.4 214.9 19.6 214.5 20.1Z" fill={letterColor}/>
									<path d="M87.2001 32.8L79.1001 3.69997H86.1001L88.9001 16.9C89.6001 20.2 90.1001 22.9 90.5001 25.2H90.7001C90.9001 23.6 91.5001 20.8 92.3001 16.9L95.2001 3.59998H102.2L94.0001 32.8V46.8H87.0001V32.8H87.2001Z" fill={letterColor}/>
									<path d="M143 15.2V46.7H137.5L136.9 42.8H136.8C135.3 45.7 133 47.2 130 47.2C127.9 47.2 126.4 46.4999 125.4 45.0999C124.4 43.6999 123.9 41.6 123.9 38.7V15.2H131V38.3C131 39.7 131.1 40.7 131.5 41.3C131.8 41.9 132.3 42.2 133 42.2C133.6 42.2 134.2 41.9999 134.8 41.5999C135.4 41.1999 135.8 40.7 136.1 40.2V15.2H143Z" fill={letterColor}/>
									<path d="M179.4 15.2V46.7H173.9L173.3 42.8H173.2C171.7 45.7 169.4 47.2 166.4 47.2C164.3 47.2 162.8 46.4999 161.8 45.0999C160.8 43.6999 160.3 41.6 160.3 38.7V15.2H167.4V38.3C167.4 39.7 167.5 40.7 167.9 41.3C168.2 41.9 168.7 42.2 169.4 42.2C170 42.2 170.6 41.9999 171.2 41.5999C171.8 41.1999 172.2 40.7 172.5 40.2V15.2H179.4Z" fill={letterColor}/>
									<path d="M162.3 9.29997H155.3V46.7H148.3V9.29997H141.3V3.59998H162.3V9.29997Z" fill={letterColor}/>
									<path d="M202.9 20.3C202.5 18.3 201.8 16.9 200.8 16C199.8 15.1 198.5 14.7 196.8 14.7C195.5 14.7 194.3 15.1 193.2 15.8C192.1 16.5 191.2 17.5 190.6 18.7H190.5V2.09998H183.7V46.7H189.6L190.3 43.7H190.4C190.9 44.8 191.8 45.6 192.9 46.2C194 46.8 195.2 47.1 196.5 47.1C198.9 47.1 200.7 46 201.8 43.8C202.9 41.6 203.5 38.1 203.5 33.4V28.4C203.5 25 203.3 22.3 202.9 20.3ZM196.4 33.1C196.4 35.4 196.3 37.2 196.1 38.5C195.9 39.8 195.6 40.7 195.2 41.3C194.8 41.8 194.2 42.1 193.4 42.1C192.8 42.1 192.3 42 191.8 41.7C191.3 41.4 190.9 41 190.6 40.5V22.6C190.8 21.7 191.3 21 191.8 20.5C192.4 20 193 19.7 193.7 19.7C194.4 19.7 195 20 195.4 20.5C195.8 21.1 196.1 22 196.2 23.3C196.3 24.6 196.4 26.5 196.4 29V33.1Z" fill={letterColor}/>
								</svg>
							</a>
							<Input
								type="text"
								value={this.state.videoId || ''}
								onChange={this.handleChange}
								placeholder="Add Youtube link here"
								className="button topbar border-light"
								error={this.state.error}
							/>
							<SubmitButton className="button primary " type="submit" value="Sync Video" />
						</SetupForm>
						<UnderlinedText 
							isVideoLoaded={isVideoLoaded}
							onClick={() => this.setState({ showModal: false, error: false })}
						>
							<span>Close</span>
						</UnderlinedText>
					</SetupModal>
				) : (
					<Container onClick={() => this.setState({ showModal: true })}>
						<LinkIcon />
					</Container>
				)}
			</div>
		)
	}
}

const SetupModal = styled.div<{isVideoLoaded: boolean}>`
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	background-color: ${({isVideoLoaded}) => isVideoLoaded ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
`

const SetupForm = styled.form`
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
	min-width: 200px;

	> * {
		margin: 4px;
	}

	a {
		align-self: center;
		svg {
			height: 30px;
			margin-bottom: 15px;
		}
	}
`

const SubmitButton = styled.input`
	border: none;
	cursor: pointer;
`

const Container = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: ${props => props.theme.shadows.light};
	background-color: #363B45;
	color: white;
`

const Input = styled.input<{error: boolean}>`
	text-align: left;
	width: 256px;
	border: 1px solid;
	padding-left: 20px !important;

	&[type=text] {
		${({error}) => error ? 'border: 3px solid #ff8700 !important' : null}
	}
`

const UnderlinedText = styled.p<{isVideoLoaded: boolean}>`
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;

    span {
        font-weight: 500;
        font-size: 14px;
		${({isVideoLoaded}) => isVideoLoaded ? 
			'border-bottom: 2px solid #fff; color: #fff;' :
			'border-bottom: 2px solid #363b45; color: #363b45;'
		}
    }
`
