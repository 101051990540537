import React from 'react'
import {useAppState} from '../../../../../../contexts/AppStateProvider'
import useDevices from '../../hooks/useDevices'
import Dropdown from '../../../../../../components/Dropdown'
import { FiSpeaker as SpeakerIcon } from "@react-icons/all-files/fi/FiSpeaker";

export default function AudioOutputList() {
  const {audioOutputDevices} = useDevices()
  const {activeSinkId, setActiveSinkId} = useAppState()
  const activeOutputLabel = audioOutputDevices.find(
    (device) => device.deviceId === activeSinkId,
  )?.label

  const audio_output_options: any = audioOutputDevices.map(device => ({
    name: device.label,
    value: device.deviceId
  }))

  return (
    <div className="inputSelect">
      {audioOutputDevices.length >= 1 ? (
        <div>
          <div className="label">Speaker</div>
          <div className="select-device-wrapper">
            <Dropdown
              name="video-devices"
              items={audio_output_options}
              disabled={audioOutputDevices.length === 0}
              value={activeSinkId || ''}
              // inputWidth={100%}
              onChange={(item, name) => {
                setActiveSinkId(item.value as string)
              }}
              dropdownIcon={<SpeakerIcon />}
            />
          </div>
          {/* <select
            className="select-custom border-lightimp topbar hover-container"
            onChange={(e) => setActiveSinkId(e.target.value as string)}
            value={activeSinkId || ''}
          >
            {audioOutputDevices.map((device) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select> */}
        </div>
      ) : (
        <div>
          <div className="label">Speaker</div>
          <div className="select-device-wrapper">
            <Dropdown
              name="video-devices"
              items={[{
                  name: 'System Default Audio Output',
                  value: 'default',
                  disabled: false
              }]}
              disabled={false}
              value={'default'}
              // inputWidth={100%}
              onChange={(item, name) => {
                setActiveSinkId(item.value as string)
              }}
              dropdownIcon={<SpeakerIcon />}
            />
          </div>
          {/* <p style={{ marginBottom: '10px', textAlign: 'center' }}>{activeOutputLabel || 'System Default Audio Output'}</p> */}
        </div>
      )}
    </div>
  )
}
