import moment from 'moment'
import React, { useState, useEffect }  from 'react'
import styled from 'styled-components'

import { colorSchemeMapper, defaultColors, defaultStyles } from '../../stores/ThemingStore'
import CalendarView from './Calendar'
import TimeTravelHeader from './Header'

import LoopApi from '../../helpers/LoopApi'
import { Theme } from './types'
import { useHistory } from 'react-router'
import ModalManager from '../Meeting2.0/ModalManager'
import { ModalActions } from '../../stores/ModalStore'



function TimeTravel() {
    const [ theme ] =  useState<Theme>('Light')

    const [ selectedYear, setSelectedYear ] = useState(moment().format("YYYY"))
    const [ arrayYears, setArrayYears ] = useState<any[]>([])

    const [ selectedMonth, setSelectedMonth ] = useState(moment().format("MMMM"))
    const [ arrayMonths, setArrayMonths ] = useState<any[]>([])

    const [ selectedDay, setSelectedDay ] = useState(moment().format("D"))
    const [ arrayDays, setArrayDays ] = useState<any[]>([])

    const [ currentWeek, setCurrentWeek ] = useState(moment().format("MMMM D, YYYY"))

    const [ transcriptions, setTranscriptions ] = useState({})
    const [ recordings, setRecordings ] = useState({})
    const [ syncs, setSyncs ] = useState({})

    const [ selectedData, setSelectedData ] = useState<any>({
        transcriptions: {},
        recordings: {}
    })

    const history = useHistory();

    const [ activeDate, setActiveDate ] = useState<string>('')

    useEffect(() => {
        generateArrayDaysByMonth(selectedYear, selectedMonth)
    }, [selectedYear, selectedMonth])
    
    useEffect(() => {
        generateArrayYears()
        generateArrayMonths()
    }, [])

    const generateArrayYears = () => {
        const years = []
        const dateStart = moment().subtract(30, 'y')
        const dateEnd = moment().add(15, 'y')
        while (dateEnd.diff(dateStart, 'years') >= 0) {
            years.push({ name: dateStart.format('YYYY'), value: dateStart.format('YYYY') })
            dateStart.add(1, 'year')
        }

        setArrayYears(years)
    }

    const generateArrayMonths = () => {
        const months = moment.months()
        setArrayMonths(
            months.map((month: string) => ({
                name: month, value: month
            }))
        )
    }

    const generateArrayDaysByMonth = (year: string, month: string) => {
        const pMonth = `${year}-${month}`
        const daysInMonth = moment(pMonth).daysInMonth();
        setArrayDays(
            Array.from({length: daysInMonth}, (v, k) => k + 1)
            .map((day) => ({
                name: day.toString(), value: day.toString()
            }))
        )
    }

    useEffect(() => {
        getTranscriptions()
    }, [])

    useEffect(() => {
        getRecordings()
    }, [])

    useEffect(() => {
        getSyncs()
    }, [])


    const getTranscriptions = async () => {
        try {
            // regex: this.state.search_regex, value: this.state.search_value // this is passed as a param // search text inside the transcription
            const res = await LoopApi(null, 'GetTranscriptions', {  })
    
            const filterTranscriptions = res.map((o: any) => {
                return {...o, dateCreated: moment(o.created_at).format('MMMM D, YYYY'), transcriptions: o.transcriptions.map((t: any) => {
                    if(!t.text.includes(o.searched)) return
                    return t
                }).filter((t: any) => t!== undefined)}
            })
    
            const groupedTranscription = groupBy(filterTranscriptions, 'dateCreated')
            setTranscriptions(groupedTranscription)
        } catch(err) {
            console.error("ERROR IN GETTING TRANSCRIPTIONS: ", err)
        }
    }

    const getRecordings = async () => {
        try {
            const { recordings: res } = await LoopApi(null, 'MeetingRecordings', {}, [
                ['name', window.location.pathname.split('/')[2]],
            ])
            const filteredRecording = res.map((o: any) => {
                return {...o, dateCreated: moment(o.created_at).format('MMMM D, YYYY')}
            })
            const groupedRecording = groupBy(filteredRecording, 'dateCreated')
            setRecordings(groupedRecording)
        } catch(err) {
            console.error("ERROR IN GETTING RECORDINGS: ", err)
        }
    }

    const getSyncs = async () => {
        try {
            const syncs = await LoopApi(null, 'GetSyncs')
            const mappedSyncs = syncs.map((o: any) => {
                return {...o, dateCreated: moment(o.created_at).format('MMMM D, YYYY')}
            })
            const groupedSyncs = groupBy(mappedSyncs, 'dateCreated')

            let newSyncs = {}
            Object.entries(groupedSyncs).forEach(
                ([key, value]: any) => {
                    newSyncs = {
                        ...newSyncs,
                        [key]: groupBy(value, 'name')
                    }
                }
            );

            setSyncs(newSyncs)
        } catch(err) {
            console.error("ERROR IN GETTING SYNC DATA: ", err)
        }
    }

    const groupBy = (array: any[], key: string) => {
        // Return the reduced array
        return array.reduce((result, currentItem) => {
          // If an array already present for key, push it to the array. Otherwise create an array and push the object.
          (result[currentItem[key]] = result[currentItem[key]] || []).push( currentItem );
          // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
          return result;
        }, {}); // Empty object is the initial value for result object
    };

    useEffect(() => {
        const yourDate: any = `${selectedMonth} ${selectedDay}, ${selectedYear}`
        const tr: any = transcriptions
        const rc: any = recordings
        const sy: any = syncs
        setSelectedData({
            transcriptions: tr?.[yourDate] || [],
            recordings: rc?.[yourDate] || [],
            syncs: sy?.[yourDate] || {}
        })
    }, [selectedYear, selectedMonth, selectedDay])


    const color_scheme = colorSchemeMapper[theme]

    return (
        <Container {...defaultColors[theme]} color_scheme={color_scheme}>
            <TimeTravelHeader 
                theme={theme} selectedYear={selectedYear} selectedMonth={selectedMonth} selectedDay={selectedDay} 
                setSelectedDay={setSelectedDay} setSelectedMonth={setSelectedMonth} setSelectedYear={setSelectedYear} setCurrentWeek={setCurrentWeek} 
                setSelectedData={setSelectedData}
                arrayDays={arrayDays} arrayMonths={arrayMonths} arrayYears={arrayYears} activeDate={activeDate} setActiveDate={setActiveDate}
            />
            <Body>
                <CalendarView 
                    selectedDate={`${selectedMonth} ${selectedDay}, ${selectedYear}`} 
                    selectedDay={selectedDay} 
                    setCurrentWeek={setCurrentWeek} 
                    currentWeek={currentWeek} 
                    setSelectedYear={setSelectedYear}
                    setSelectedMonth={setSelectedMonth}
                    setSelectedDay={setSelectedDay}
                    transcriptions={transcriptions}
                    recordings={recordings}
                    syncs={syncs}
                    selectedData={selectedData} 
                    setSelectedData={setSelectedData}
                    activeDate={activeDate}
                    setActiveDate={setActiveDate}
                />
            </Body>

            <ModalManager history={history} />
        </Container>
    )
}

const Container = styled.div<{ color_scheme: string }>`
    display: flex;
    flex-direction: column;
    flex: 1;

    ${Object.entries(defaultStyles || {})
		.map(([key, val]) => `${key} {${val}}`)
		.join('\n')}

	${({ color_scheme }) => {
		const styleobj = Object.entries(color_scheme || {})
			.map(([key, val]) => `${key} {${val}}`)
			.join('\n')
		return styleobj
	}}
`

const Body = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
`


export default TimeTravel
