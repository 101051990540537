import React, { Component } from 'react'
import styled from 'styled-components'

import { FaFolder } from '@react-icons/all-files/fa/FaFolder'
import { IFile, IFolder } from './types'
import FileIcon from './FileIcon'
import { timeTodayDateElse, humanFileSize } from '../../helpers'

interface Props {
	item: IFile | IFolder
	selected?: boolean
	onClick?: () => void
	onDoubleClick?: () => void
	disabled?: boolean
	isGridView?: boolean
	users: any
	files?: any
}

export default class File extends Component<Props> {
	static defaultProps = {
		item: null,
		onClick: () => { },
		onDoubleClick: () => { }
	}

	renderInner() {
		const { item, isGridView = false } = this.props
		const time = item.created ? timeTodayDateElse(item.created) : ''
		const userobj = (!!this.props.users && this.props.users.length && item.user_id) ?
		this.props.users.find((u: any) => u.id === item.user_id) : ''
		const user = userobj ?  userobj.name : ''

		if ("_id" in item) {
			if (isGridView) {
				return (
					<GridContainer className="topbar">
						<GridContainerInner>
							<FileIcon filename={item.filename} size={22} />
							<div className="item-name" >{item.filename}</div>
							<div className="item-size" >{item.size ? `File size: ${humanFileSize(item.size)}` : ''}</div>
						</GridContainerInner>
					</GridContainer>
				)
			} else {
				return (
					<ListWrapper>
						<IconContainer>
							<FileIcon filename={item.filename} size={22} />
						</IconContainer>
						<Name >{item.filename}</Name>
						<Owner>{user || ''}</Owner>
						<DateUploaded>{time}</DateUploaded>
					</ListWrapper>
				)
			}
		} else {
			if (isGridView) {
				const files = item.files.filter(i => this.props.files[i])
				const length = files.length
				const filesCount = !!!length ? '' : length > 1 ? `${length} files` : `${length} file`
				return (
					<GridContainer className="topbar">
						<FaFolder size={22} fill="#2483f3" />
						<div className="item-name folder-item-name" >{item.name}</div>
						<div className="item-children-count" >{filesCount}</div>
					</GridContainer>
				)
			} else {
				return (
					<ListWrapper>
						<IconContainer>
							<FaFolder size={22} fill="#2483f3" />
						</IconContainer>
						<Name >{item.name}</Name>
						<Owner>{user || ''}</Owner>
						<DateUploaded>{time}</DateUploaded>
					</ListWrapper>
				)
			}
		}
	}

	render() {
		const { selected = false, onClick, onDoubleClick, disabled = false, isGridView = false } = this.props
		const noop = () => { }
		return <FileItem
			isGridView={isGridView}
			selected={selected}
			onClick={disabled ? noop : onClick}
			onDoubleClick={disabled ? noop : onDoubleClick}
			disabled={disabled}
		>
			{this.renderInner()}
		</FileItem>

	}
}

const ListWrapper = styled.div`
	// width: 100%;
  //   display: flex;
  //   align-items: center;
  //   padding: 5px 10px;
	display: grid;
	grid-template-columns: 50px 3fr 1fr 1fr;
	padding: 8px 16px;
	min-height: 48px;
	width: 100%;

	> div {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		place-self: center stretch;
		font-size: 13px;
    font-weight: 400;
	}
`

const FileItem = styled.div<{ selected: boolean, disabled: boolean, isGridView: boolean }>`
	display: flex;
	align-items: center;
	padding: ${props => props.isGridView ? '10px' : '0'};
	font-size: ${props => props.theme.textMD};
	font-weight: ${props => props.theme.textRegular};
	margin-left: 8px;
	margin-top: 8px;
	border-radius: ${props => props.selected ? "5px" : 0};

	opacity: ${props => props.disabled ? 0.5 : 1};
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
	background-color: ${props => props.selected ? "rgba(0,0,0,0.1)" : null};
`

const IconContainer = styled.div`
	// width: 24px;
`

const GridContainer = styled.div`
	width: 100%;
	padding: 24px;
	border-radius: 10px;

	.item-name, .item-children-count, .item-size {
		width: 100%;
		word-break: break-word;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.folder-item-name {
		margin-top: 8px;
	}
`

const GridContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > img {
		width: 24px;
		margin-bottom: 16px;
	}
`

const Name = styled.div`
	user-select: none;
	// margin-left: 8px;
	// text-overflow: ellipsis;
	// overflow: hidden;
	// white-space: nowrap;

	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
`

const Owner = styled.div`

`

const DateUploaded = styled.div`

`