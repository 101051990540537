import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { DAYS_SHORT } from '../props/constants'
import { MEMBERS } from '../dummy-events'

function WeekView() {
  return (
    <WeeklyWrapper>
      <ContentRow>
        <HeaderItem className="topic-header" />
        {
          DAYS_SHORT.map((__day, __index) =>
            <HeaderItem key={__index}>
              <NumberDate>{__index+1}</NumberDate>
              <ShortDay>{__day}</ShortDay>
            </HeaderItem>)
        }
      </ContentRow>

      {
        MEMBERS.map((__member, __index) =>
          <ContentRow key={__index}>
            <MemberItem>
              <MemberAvatar>ID</MemberAvatar>
              <MemberName>{__member.name}</MemberName>
            </MemberItem>
            {
              __member.events.map((__events, __event_index) =>
                <EventItem key={__event_index} className={__events[0] && __events[0].type.toLocaleLowerCase()}>
                  {
                  __events.map((__event, __idx) =>
                    <EventTopic key={__idx}>
                      {moment(__event.time).format('h:mmA')} {__event.topic}
                    </EventTopic>)
                  }
                </EventItem>
              )
            }
          </ContentRow>
        )
      }
    </WeeklyWrapper>
    )
}


const WeeklyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 15px;
`

const ContentRow = styled.div`
  display: flex;
  flext-direction: row;
`

const HeaderItem = styled.div`
  box-sizing: border-box;
  width: 12.5%;
  height: 84px;

  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 15px;
  margin: 5px 5px 2px 0px;

  &:first-child {
    width: 11%;
  }

  &.topic-header {
    border: none;
  }
`

const NumberDate = styled.div`
  padding: 13px 10px 2px;
  font-size: 40px;
  line-height: 40px;
`

const ShortDay = styled.div`
  padding: 0px 10px 10px;
  font-size: 11px;
`


const MemberItem = styled.div`
  background: #F2F2F2;
  border-radius: 5px;
  width: 11%;
  margin: 5px 5px 2px 0px;

  padding: 10px 0px 5px 5px;

  > div {
    vertical-align: middle;
  }
`
const MemberAvatar = styled.div`
  display: inline-block;
  width: 36px;
  height: 36px;

  background: #363B45;
  border-radius: 18px;
  text-align: center;
  color: white;
  padding-top: 6px;
`
const MemberName = styled.div`
  display: inline-block;
  width: 85px;
  font-size: 11px;
  margin-left: 2px;
`

const EventItem = styled.div`
  box-sizing: border-box;
  width: 12.5%;
  height: 56px;

  border: 1px solid rgba(242, 244, 246, 0.43);
  background: rgba(242, 244, 246, 0.43);
  border-radius: 5px;
  margin: 5px 5px 2px 0px;
  padding: 10px;

  &.standard {
    background: linear-gradient(180deg, #00265C 0%, rgba(0, 38, 92, 0.8) 100%);
    border-color: rgba(242, 244, 246, 0);
  }

  &.holiday {
    background: linear-gradient(180deg, #FF9900 0%, rgba(255, 153, 0, 0.8) 100%);
    border-color: rgba(242, 244, 246, 0);
  }
`
const EventTopic = styled.div`
  font-size: 8px;
  color: white;
`



export default WeekView