import * as twilio from 'twilio-video'
import {LocalVideoTrack} from 'twilio-video'
import moment from 'moment'
import axios from 'axios'

// stores
import {VariableCallingActions} from '../stores/VariableCallingStore'

// types
import {BaseProviderInstance, CallingProviderName} from './types'

// helpers
import {colorLog, initializeTranscription} from './_utils'
import Sagas from '../helpers/Sagas'
import useToastify from '../helpers/useToastify'
import LoopApi from '../helpers/LoopApi'
import {isMobile, parseQuotedStrings} from './_helpers'
import {
  SELECTED_AUDIO_INPUT_KEY,
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
} from './_constants'
import {VideoRoomMonitor} from '@twilio/video-room-monitor'

const CONSOLE_TAG = 'Twilio'

type NonDataTrackT = twilio.VideoTrack | twilio.AudioTrack

export class TwilioInstance extends BaseProviderInstance {
  room: twilio.Room | null = null
  private access_token: string
  protected local_user_id: string
  protected type?: string
  providerName = CallingProviderName.Twilio
  in_connection_process = false
  _participants: any[]
  _startTime: any
  private constraints: MediaStreamConstraints
  protected local_presenter_id: string

  constructor(access_token: string, local_user_id: string, type?: string) {
    super()
    this.access_token = access_token
    this.local_user_id = local_user_id
    this.type = type
  }

  get participants() {
    if (!this.room) {
      return []
    }

    return this._participants
  }

  get startTime() {
    return this._startTime
  }

  private _log(...args: any[]) {
    console.log('**twil', ...args)
  }

  getConnectionOptions = () => {
    // See: https://sdk.twilio.com/js/video/releases/2.0.0/docs/global.html#ConnectOptions
    // for available connection options.
    const connectionOptions: twilio.ConnectOptions = {
      bandwidthProfile: {
        video: {
          mode: 'presentation',
          dominantSpeakerPriority: 'standard',
          // trackSwitchOffMode: undefined,
          contentPreferencesMode: 'auto',
          clientTrackSwitchOffControl: 'auto',
        },
      },
      dominantSpeaker: true,
      networkQuality: {local: 1, remote: 1},
      maxAudioBitrate: Number(this.type === 'PRO' ? 40000 : 16000),
      preferredVideoCodecs: [{codec: 'VP8', simulcast: true}],
    }

    // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
    if (isMobile && connectionOptions?.bandwidthProfile?.video) {
      connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000
    }

    return connectionOptions
  }

  getInitialTracks = async (constraints: MediaStreamConstraints) => {
    const tracks: any[] = []

    if (constraints.audio) {
      const selectedAudioDeviceId = parseQuotedStrings(
        localStorage.getItem(SELECTED_AUDIO_INPUT_KEY),
      )
      const audioOptions = {
        deviceId: {
          ideal: selectedAudioDeviceId || 'default',
        },
        echoCancellation: true,
      }

      const audioTrack = await twilio.createLocalAudioTrack(audioOptions)
      const transcriptionTrack = initializeTranscription(audioTrack)

      VariableCallingActions.SetTranscriptionTrack(transcriptionTrack)

      tracks.push(audioTrack)
      VariableCallingActions.SetLocalAudioTrack(audioTrack)
    }

    if (constraints.video) {
      const selectedVideoDeviceId = localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY,
      )
      const videoOptions = {
        deviceId: {ideal: selectedVideoDeviceId || 'default'},
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      }
      const videoTrack = await twilio.createLocalVideoTrack(videoOptions)
      tracks.push(videoTrack)

      VariableCallingActions.SetLocalVideoTrack(videoTrack)
    }

    return tracks
  }

  public async connect(room_name: string, constraints: MediaStreamConstraints) {
    this._log('Connecting to Twilio', {room_name, constraints})

    // Cancel if already connecting
    if (this.in_connection_process) return

    try {
      // Destroy room if already exists
      if (this.room) {
        await this.teardown()
      }

      // Create room (create or retrieve current room)
      const twilioRoom = await LoopApi(null, 'CreateTwilioRoom', {
        roomName: room_name,
        statusCallback:
          process.env.REACT_APP_TWILIO_WEBHOOK_URL ||
          'https://grapl-dev.ngrok.io/api/webhooks/twilio/callback',
      })

      // const tracks = await this.getInitialTracks(constraints)
      const connectOptions = this.getConnectionOptions()

      // Connect to Room
      let room = await twilio.connect(this.access_token, {
        name: twilioRoom.uniqueName,
        tracks: [],
        ...connectOptions,
      })

      //console.log('ROOM:', room)

      // Update (Start Meeting / Join Meeting)
      Sagas.Clients.Emit('main', 'message', {action: 'CallConnected'})

      this._startTime = moment(+new Date(twilioRoom.dateCreated)).format('X')

      // @ts-ignore
      window.constraints = constraints

      VariableCallingActions.SetRoom(room)
      this.room = room

      // @ts-ignore
      window.twilioRoom = room
      VideoRoomMonitor.registerVideoRoom(room)

      // get session
      const session = await LoopApi(null, 'GetRecordingBySessionId', {}, [
        ['sessionId', twilioRoom.sid],
      ])
      VariableCallingActions.SetLock({meetingLocked: session.recording?.locked})
      ////console.log('**twil', 'SESSION', session)

      const disconnect = () => room.disconnect()

      // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
      // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
      room.setMaxListeners(50)

      // @ts-ignore
      window.removeRoom = () => this.teardown()

      room.once('disconnected', () => {
        // Reset the room only after all other `disconnected` listeners have been called.
        setTimeout(() => {
          this.teardown()
        })

        window.removeEventListener('beforeunload', disconnect)

        if (isMobile) {
          window.removeEventListener('pagehide', disconnect)
        }
      })

      room.localParticipant.videoTracks.forEach((publication) =>
        // All video tracks are published with 'low' priority because the component of the video track
        // that will be used for presenting will have it's priority
        // set to 'high' via track.setPriority()
        publication.setPriority('low'),
      )

      // Add a listener to disconnect from the room when a user closes their browser
      //  window.addEventListener('beforeunload', disconnect);

      if (isMobile) {
        // Add a listener to disconnect from the room when a mobile user closes their browser
        window.addEventListener('pagehide', disconnect)
      }
    } catch (err) {
      this._log(`Unable to connect to Room: ${err.message}`)
      VariableCallingActions.Leave()
      this.teardown()
      useToastify({
        type: 'error',
        message: `Unable to connect to Room: ${
          err?.message || err
        }. Please refresh the page.`,
      })
    }
  }

  public async teardown() {
    try {
      this._log('Destroying Room')

      VariableCallingActions.SetRoom(null)
      this.room?.disconnect()
      this.room?.removeAllListeners()
      this.room = null
      VariableCallingActions.Leave()

      Sagas.Clients.Emit('main', 'message', {action: 'CallDisconnected'})
    } catch (e) {
      this._log(`Error during teardown: ${e.message}`)
    }
  }
}
