import React from 'react';
import styled from 'styled-components';
import Table from '../../_Shared/Table';
import moment from 'moment';
import { WidgetContentItem } from '../../_Shared/Elements';
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash';
import Dropdown from '../../_Shared/DropdownWrapper';
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'
import Tablev2 from '../../_Shared/Tablev2';


const Contacts = (props: any) => {
  const [search, setSearch] = React.useState('')
  const { results } = props.data.contacts;
  const { UpdateSelf } = props.actions;
  const { getContacts } = props;

  const handleClick = (data: any) => {
    UpdateSelf({ screen: 'contact', contact_id: data.id });
  };

  const deleteContact = async (id: string, e: any) => {
    await Api(Endpoints['Hubspot'], Actions['RemoveContact'], props.external_token, {}, [['contactId', id]]);
    getContacts();
  };

  const handleSearch = (value:any) => {
    setSearch(value)
  }

  const searchContact = async (e: any) => {
    const data = { query: search }
    e.preventDefault(); 
    const contacts =  await Api(Endpoints['Hubspot'], Actions['SearchContact'], props.external_token, { body: data });
  }

  return (
    <>
      <SpaceWrapper>
        <Button className='button default' onClick={() => UpdateSelf({ screen: 'createContact' })} ref={props.useRef}>
          Create Contact
        </Button>
      </SpaceWrapper>
      <Header className="text-primary">Contacts</Header>
      <SubHeader className="text-secondary">{results?.length ?? 0} records</SubHeader> 
      {/* <SearchDiv>
          <AiOutlineSearch size="22px" />
          <SearchInput onChange={(e) => //console.log(e.target.value)} className="topbar" type='text' placeholder='Search' />
      </SearchDiv> */}

      {/* NEW TABLE  */}
      <Tablev2 
        columns={[{
            Header: 'Name',
            accessor: '',
            Cell: ({ original }) => `${original?.properties?.firstname}, ${original?.properties?.lastname}`
          }, {
            Header: 'Email',
            accessor: '',
            Cell: ({ original }) => `${original?.properties?.email ?? '-'}`
          }, {
            Header: 'Phone',
            accessor: '',
            Cell: ({ original }) => `${original?.properties?.phone ?? '-'}`
          },
          // {
          //   Header: 'Last Activity',
          //   accessor: '',
          //   Cell: ({ original }) => `${moment(original?.properties?.lastmodifieddate).format('LLL')}`
          // }
          {
            Header: 'Associated Company',
            accessor: '',
            Cell: ({ original }) => `${original?.properties?.company ?? '-'}`
          }, {
            Header: '',
            accessor: '',
            width: 60,
            Cell: ({ original }) => {
              const DropdownContent = (props: any) => {
                return (
                  <React.Fragment>
                    <WidgetContentItem
                      padding={10}
                      onClick={(e: any) => {
                        deleteContact(original.id, e);
                        props.onClose();
                      }}
                    >
                      <Trash width={16} className='error' />
                      Remove Contact
                    </WidgetContentItem>
                  </React.Fragment>
                );
              };
              return (
                <Dropdown items={DropdownContent}>
                  <IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} />
                </Dropdown>
              );
            }
          }
        ]}
        data={results || []}
        showPagination={false}
        sortable={false}
        onRowClick={(row: any) => handleClick(row)}
      />
      {/* <CTable
        headers={[
          {
            label: 'Name',
            width: '30%',
            row: (row: any) => {
              const { firstname, lastname } = row.properties;
              return (
                <Assignees>
                  {firstname}, {lastname}
                </Assignees>
              );
            },
          },
          {
            label: 'Email',
            width: '30%',
            row: (row: any) => {
              const { email } = row.properties;
              return <Assignees>{email}</Assignees>;
            },
          },
          {
            label: 'Phone',
            width: '30%',
            row: (row: any) => {
              const { phone } = row.properties;
              return <Assignees>{phone}</Assignees>;
            },
          },
          // {
          //   label: 'Last Activity',
          //   width: '30%',
          //   row: (row: any) => {
          //     const { lastmodifieddate } = row.properties;
          //     return <Assignees>{moment(lastmodifieddate).format('LLL')}</Assignees>;
          //   },
          // },
          {
            label: 'Associated Company',
            width: '30%',
            row: (row: any) => {
              const { company } = row.properties;
              return <Assignees>{company}</Assignees>;
            },
          },
          {
            label: '',
            value: '',
            row: (row: any) => {
              const DropdownContent = (props: any) => {
                return (
                  <React.Fragment>
                    <WidgetContentItem
                      padding={10}
                      onClick={(e: any) => {
                        deleteContact(row.id, e);
                        props.onClose();
                      }}
                    >
                      <Trash width={16} className='error' />
                      Remove Contact
                    </WidgetContentItem>
                  </React.Fragment>
                );
              };
              return (
                <Dropdown items={DropdownContent}>
                  <IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} />
                </Dropdown>
              );
            },
            width: '5%',
          },
        ]}
        data={results || []}
        // expandingData={taskChild}
        parentKey='id'
        onClick={(row: any) => handleClick(row)}
        expandingRowClick={(row: any) => handleClick(row)}
      /> */}
    </>
  );
};

export default Contacts;

const SpaceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: 0;
`;

const Button = styled.div`
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  &.custom-padding {
    padding: 10px 15px !important;
    font-size: 12px;
    margin: 0 10px;
  }

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;


const Assignees = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > div:not(:last-child) {
    margin-left: -10px;
  }

  & > div {
    transition: 0.3s transform ease-out;
    border: 1px solid white;
    &:hover {
      z-index: 2;
      border: 1px solid white;
      transform: scale(1.1);
    }
  }
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`

const SubHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 15px;
`

const SearchInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 294px;  
  background-color: transparent !important;
  outline: none;
  border: none;
`

const SearchDiv = styled.div`
  align-items: center;
  border-radius: 999px;
  border: 1px solid #6F6868; 
  display: flex;
  padding: 2px;
  margin-right: 5px;
  margin-top: 30px;
  margin-bottom: -30px;
  width: 294px;  
  padding-left: 10px;
`

const CTable = styled(Table)`
    display: block;
    overflow-x: auto;
    white-space: nowrap;
`