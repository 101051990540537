import styled from 'styled-components'
import { FaArrowLeft as ArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner'
import FileDrop from "react-file-drop";

export const IsUploading = styled.div`
  padding: 6px;
  border-radius: 4px;
  background-color: ${props => props.theme.color.string()};
  color: white;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 14px;
`

export const Controls = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 4px;
  left: 4px;
`

export const BackButton = styled(ArrowLeft)`
  padding: 4px;
  margin-right: 4px;
  color: white;
  background-color: ${props => props.theme.color.string()};
  border-radius: 50%;
`

export const UploadIndicator = styled(FaSpinner)`
  position: absolute;
  bottom: 16px;
  right: 16px;

  animation: rotation 1s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 30px;

  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px
  }

  h1.subheader {
    font-size: 14px;
    font-weight: 600;
  }
`

export const NoFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  span {
    margin: 16px;
  }
`

export const UploadButton = styled.button`
  background-color: #3E83F7;
  color: white;
  height: 40px;
  width: 140px;
  outline: 0;
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(62, 131, 247, 0.8);
  }
`

export const GdriveButton = styled.button`
  background-color: transparent;
  color: #000;
  height: 40px;
  // width: 140px;
  outline: 0;
  border: 1px solid #eee;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
`

// Folder List

export const FolderRow = styled.div<{ selected: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textRegular};
  background-color: ${props => props.selected ? props.theme.hoverPrimary : 'white'};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

export const IconButton = styled.button`
  outline: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  font-size: 13px;
  background: none !important;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const IconContainer = styled.div`
	width: 20px;
`

export const Name = styled.div`
	user-select: none;
	margin-left: 8px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
`

export const FileWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;

  a {
    text-decoration: none;
  }

  .file-breadcrumb {
    margin-bottom: 15px;
  }
`

export const QuickAccessButton = styled.button`
  cursor: pointer;
  margin-right: 16px;
  height: 80px;
  width: 100px;
  border: 1px solid #333;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const QuickAccessWrapper = styled.div`
  // margin-bottom: 30px;
  padding: 15px 30px 0 30px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 30px;
`

export const TopControls = styled.div`
  display: flex;
  padding: 0px 30px 0px 30px;
  align-items: center;
  /* margin-top: 30px; */
`

export const ContainerFileDrop = styled(FileDrop as any)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  position: relative;
  padding: 30px 0;

  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px
  }

  h1.subheader {
    font-size: 14px;
    font-weight: 600;
  }

	.file-drop-target {
		flex: 1;
		display: flex;
		align-items: stretch;
		overflow: hidden;
		position: relative;
    width: 100%;
		flex-direction: column;
    // overflow-y: scroll;
	}

	.show-while-dragging,
  .show-while-dragging-over {
    display: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 32px;
    color: white;
    font-weight: 500;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }

  .padding-bottom-20 {
    padding-bottom: 20px;
  }
`

export const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 20px;
        border: none;
        align-self: center;
        margin: 0 14px 0 0;
    }
`

export const NameHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 0px 30px 15px;

    &.mobile {
        flex-direction: column;
        align-items: baseline;
        margin-top: 15px;
    }
`

export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	border: 1px solid;
	border-radius: 32px;
  margin-left: 20px;
  margin-top: 20px;

  @media (min-width: 640px){
      margin-top: 0;
  }
`

export const SearchInput = styled.input`
	background: none;
	border: none;
	padding-left: 8px;
`

export const Menus = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 220px;
  // position: sticky;
  // top: 73px;
  // height: auto;
  // @media (min-width: 971px){
  //   height: 830px;
  // }
`

export const SubmenusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
`

export const SubmenuLabel = styled.div`
  border-radius: 0px 30px 30px 0;
  padding: 15px 15px 15px 30px;
  cursor: pointer;
`

export const QuickAccessItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Present = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 8px 12px !important;

  span {
      margin-left: 5px;
      white-space: nowrap;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
`

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 72px);
`

export const SubContent = styled.div<{viewType: string}>`
  padding: ${({viewType}) => viewType !== 'grid' ? '5px 30px 15px 30px' : '0px 30px 15px'};
  margin: ${({viewType}) => viewType !== 'grid' ? '5px 30px 15px 30px' : ''};
`

export const Heading = styled.h1`
  padding: 5px 30px;
`

export const MainList = styled.div`
  overflow: auto;
  height: calc(100% - 70px);
`