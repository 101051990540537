import LoopApi from "../../../../../helpers/LoopApi";

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export async function onDownload(url : string, name: string) {
    const resp = await LoopApi(null, 'DownloadUrlHash', { type: 'download', filename: name }, [['url', url]])
    if (resp.url) {
        const link = document.createElement('a')
        link.href = resp.url

        // if (CallingInstanceState.Connected === this.state.status)
        //     link.target = '_blank'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}