import {LocalAudioTrack, Room} from 'twilio-video'
import {useCallback} from 'react'
import useIsTrackEnabled from './useIsTrackEnabled'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import { CallingProviderName } from '../../../../../contexts/types'
import useStreamStates from './useStreamStates'
import { DailyCall } from '@daily-co/daily-js'
import { closeAwsTranscriber, initializeAwsTranscriber } from '../../../../../helpers/aws-transcriber'
import { WidgetActions } from '../../../../../stores/MainStore'
import { v4 } from 'uuid'
const { GlobalState } = require('reflux')

export default function useLocalAudioToggle(onboarding?: boolean) {
  const {localTracks, callProvider, room} = useMeetingContext()
  const localParticipant = (room as Room)?.localParticipant || (room as DailyCall)?.participants().local
  const audioTrack = localTracks.find(
    (track) => track.kind === 'audio',
  ) as LocalAudioTrack
  const isEnabled = useIsTrackEnabled(audioTrack)
  const { isMicMuted } = useStreamStates()

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  const toggleAudioEnabled = useCallback(() => {
    if(!!!onboarding) {
      if(audioTrack?.isEnabled || (!isTwilio && !!!isMicMuted)) {
          closeAwsTranscriber();
      } else {
          initializeAwsTranscriber(WidgetActions.TranscriptionResult, v4, GlobalState.main.db_meeting.settings.transcribe, !isTwilio && !!isMicMuted ? { enabled: true } : audioTrack)
      }

    }
    if(isTwilio || onboarding) {
      if (audioTrack) {
        audioTrack?.isEnabled ? audioTrack.disable() : audioTrack.enable()
      }
    } else {
      (room as DailyCall)?.setLocalAudio(!!isMicMuted || false)
    }
  }, [audioTrack, localParticipant, isMicMuted])

  return [(isTwilio || onboarding) ? isEnabled : !!!isMicMuted, toggleAudioEnabled] as const
}
