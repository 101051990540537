import React from 'react'
import AudioInputList from '../AudioInputList'
import AudioOutputList from '../AudioOutputList'
import VideoInputList from '../VideoInputList'

function DeviceList() {
  return (
    <React.Fragment>
        <AudioInputList onboarding />
        <VideoInputList onboarding />
        <AudioOutputList />
    </React.Fragment>
  )
}

export default DeviceList