import React, { Component } from "react";
import styled from "styled-components";

// Components
import AudioTrack from "./AudioTrack";
import VideoTrack from "./VideoTrack";
import VolumeIcon from "/assets/icons/micOn.svg";
import { VariableCallingActions } from "../../../../stores/VariableCallingStore";
import TrackWaveMeter from '../../../Meeting/Sidebar/TrackWaveMeter'
import { RemoteParticipant, RemoteTrack, RemoteTrackPublication } from "twilio-video";
import { getNonNullTracks } from "/calling/_helpers.tsx";

interface State {
  tracks: any[];
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  videoDevices: any[];
  audioDevices: any[];
  transcriptionTrack: any;
}

interface Props {
  participant: RemoteParticipant;
  isLocalParticipant?: boolean;
  user: any;
  room: any;
  canSharescreen?: boolean;
  isSharingScreen?: boolean;
  localVideoTrack?: any;
  localAudioTrack?: any;
}

class Participant extends Component<Props, State> {
  joinedWithoutAudio: boolean
  joinedWithoutVideo: boolean

  constructor(props: Props) {
    super(props);

    this.joinedWithoutAudio = [...this.props.participant.audioTracks.values()].length === 0
    this.joinedWithoutVideo = [...this.props.participant.videoTracks.values()].length === 0

    const nonNullTracks = getNonNullTracks(this.props.participant.tracks as any)

    const videoTracks = [...this.props.participant.videoTracks.values()]
    const audioTracks = [...this.props.participant.audioTracks.values()]
    
    this.state = {
      tracks: nonNullTracks,
      isAudioEnabled: audioTracks[0] ? audioTracks[0].isTrackEnabled : false,
      isVideoEnabled: videoTracks[0] ? videoTracks[0].isTrackEnabled : false,
      videoDevices: [],
      audioDevices: [],
      transcriptionTrack: null,
    }
  }

  setActivePresenter = (participant: RemoteParticipant) => {
    const participantId = participant.identity.split('::')[1]
    if (participantId) {
      VariableCallingActions.SetActivePresenter(participantId)
    }
  }

  componentDidMount() {
    const { participant } = this.props;

    // Participants Tracks that are already published
    participant.tracks.forEach((publication) => {
      // Tracks that are already PUBLISHED by the participant
      if (publication.isSubscribed) {
        if (publication.trackName === 'screen-share-track') {
          VariableCallingActions.SetParticipantScreenshareTrack(publication.track)
          this.setActivePresenter(participant)
        } else {
          if (publication.track) {
            this.handleTrackEvents(publication.track);
          }
        }
      }
    });

    participant.on("trackSubscribed", (track) => {
      //console.log('**twil, TRACK Subscribed: ', track)
      
      if(track.name === 'screen-share-track' && track.kind === 'video') {
        //console.log('**twil, SOMEONE IS SCREENSHARING...: ', track, participant)
        VariableCallingActions.SetParticipantScreenshareTrack(track)
        this.setActivePresenter(participant)

      } else {
        this.handleTrackEvents(track)
        this.addTrack(track);
      }
    });

    // Participant Tracks that WILL be published / unpublished
    participant.on("trackPublished", this.handleTrackPublished)
    participant.on("trackUnpublished", this.handleTrackUnpublished);
    
  }

  handleTrackPublished = (publication: RemoteTrackPublication) => {
    //console.log('**twil, TRACK Published: ', publication)
  }

  handleTrackUnpublished = (publication: RemoteTrackPublication) => {
    if(publication.trackName === 'screen-share-track' && publication.kind === 'video') {
      // Remove screenshare track
      VariableCallingActions.SetParticipantScreenshareTrack(null)
      VariableCallingActions.SetActivePresenter(null);
    }
    this.removeTrack(publication);
  }

  addTrack(track: any) {
    if (track) {
      this.setState({
        tracks: [...this.state.tracks, track],
      });
    }
  }

  removeTrack(publication: RemoteTrackPublication) {
    //console.log('**twil', 'removing track', publication, this.state.tracks);
    if (publication) {
      const newTracks = this.state.tracks.filter(
        (t) => t.name !== publication.trackName
      );

      this.setState({
        tracks: newTracks,
      });
    }
  }
  
  handleTrackEvents = (track: RemoteTrack) => {
    track.on("disabled", () => {
      //console.log("**twil, TRACK Disabled", track);

      if (track.kind === "video" && track.name !== 'screen-share-track') {
        this.setState({ isVideoEnabled: false });
      }
      if (track.kind === "audio") {
        this.setState({ isAudioEnabled: false });
      }
    });
    track.on("enabled", () => {
      //console.log("**twil, TRACK Enabled", track);
      if (track.kind === "video" && track.name !== 'screen-share-track') {
        this.setState({ isVideoEnabled: true });
      }
      if (track.kind === "audio") {
        this.setState({ isAudioEnabled: true });
      }
    });
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return JSON.stringify(nextProps.participant) !== JSON.stringify(this.props.participant) || 
      JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user) || 
      this.props.canSharescreen !== nextProps.canSharescreen || 
      this.props.isSharingScreen !== nextProps.isSharingScreen || 
      JSON.stringify(this.state.tracks) !== JSON.stringify(nextState.tracks) || 
      this.state.isAudioEnabled !== nextState.isAudioEnabled || 
      this.state.isVideoEnabled !== nextState.isVideoEnabled 
  }

  render() {
    
    const { isVideoEnabled, isAudioEnabled, tracks } = this.state;
    const { user } = this.props;
    
    const audioTrack = tracks.find((track: any) => track.kind === 'audio')
    const videoTrack = tracks.find((track: any) => track.kind === 'video' && track.name !== 'screen-share-track')

    return (
      <React.Fragment>
        <AspectRatioEnforcer>
          { !isVideoEnabled && <Background
            className="container content"
            avatar_url={(user || {}).avatar_url || ""}
            style={{ zIndex: 1 }}
          /> }

          { audioTrack && <AudioTrack track={audioTrack} /> }

          { videoTrack && <VideoTrack key={`${videoTrack?.name}-videotrack`} isLocalParticipant={false} track={videoTrack} /> }

          {
            isAudioEnabled && <MutedWrapper>
              <TrackWaveMeter track={audioTrack} />
            </MutedWrapper>
          }

          {
            !isAudioEnabled && <MutedWrapper>
              <IconButtonMute className="button" muted={true}>
                <img src={VolumeIcon} width={11} />
                <XLine />
              </IconButtonMute>
            </MutedWrapper>
          }

        </AspectRatioEnforcer>
      </React.Fragment>
    );
  }
}

export default Participant;

const AspectRatioEnforcer = styled.div`
  /* padding-top: 100%; */
  height: 100%;
  width: 100%;
  background-color: black;
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Background = styled.div<{ avatar_url: string }>`
  background-image: url("${(props) => props.avatar_url}");
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
`;

const MutedWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
`;

export const IconButtonMute = styled.div<{ muted?: boolean }>`
  color: white;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  font-size: 16px;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  opacity: 0;
  position: relative;
  ${(props) => (props.muted ? "opacity: 1!important;" : "")}
  cursor: pointer;
`;

const XLine = styled.div`
  position: absolute;
  height: 1px;
  width: 16px;
  background-color: #fff;
  transform: rotate(135deg);
  pointer-events: none;
`;
