import React from 'react';
import styled, { keyframes } from 'styled-components';
import Reflux from 'reflux';
const { GlobalState } = require('reflux');
import { FiUsers as UsersIcon } from '@react-icons/all-files/fi/FiUsers';
import { IoMdClose as LeaveIcon } from '@react-icons/all-files/io/IoMdClose';
import { IoLockOpen as LockOpenIcon } from '@react-icons/all-files/io5/IoLockOpen';
import { IoLockClosed as LockClosedIcon } from '@react-icons/all-files/io5/IoLockClosed';
import { FaSpinner as LoadingIcon } from '@react-icons/all-files/fa/FaSpinner';

import { CallingInstanceState, CallingProviderName } from '../../../calling/types';

import {
    VariableCallingActions,
    VariableCallingStore,
    IVariableCallingStoreState,
} from '../../../stores/VariableCallingStore';
import LoopApi from '../../../helpers/LoopApi';
import DropdownWrapper from '../Sidebar/TopBar/DropdownWrapper';
import CallDropdown from '../Sidebar/CallControls/CallDropdown';
import { MainStore } from '../../../stores/MainStore';
import NewCallDropdown from '../Sidebar/CallControls/NewCallDropdown';
import CarllDropdown2 from '../Sidebar/CallControls/CallDropdown2';
import { ModalActions, ModalStore } from '../../../stores/ModalStore';
import useParticipants from '../Widgets/KeynoteWidgets/hooks/useParticipants';
import JoinMeetingButton from './JoinMeetingButton';
import { AuthStore } from '../../../stores/AuthStore';
import CustomTip from '../../../helpers/CustomTip';
import Onboarding from '../Widgets/KeynoteWidgets/Main/components/Onboarding/OnboardingFunctional';
import { closeAwsTranscriber } from '../../../helpers/aws-transcriber';
import { toast } from 'react-toastify';

type IState = Pick<
    IVariableCallingStoreState,
    'status' | 'session' | 'connectedUserIds' | 'meetingLocked' | 'session_id' | 'currentParticipants'
>;

interface State extends IState {
    meetingName: '';
    toggleStatus: string;
    db_meeting: any;
    is_mobile: boolean;
    billings: any;
    limits: any;
    modalShown: boolean;
    callDropdownOpen: boolean;
    jwt: any
    host: any
    call_starter: string
}

const is_mobile = window.matchMedia('(max-width: 767px)');

export default class BasicTopbarControls extends Reflux.PureComponent {
    participants: any;
    modalTimeout: any

    constructor(props: never) {
        super(props);
        this.stores = [VariableCallingStore, MainStore, ModalStore, AuthStore];
        this.storeKeys = [
            'status',
            'connectedUserIds',
            'meetingLocked',
            'meetingName',
            'db_meeting',
            'session_id',
            'currentParticipants',
            'callDropdownOpen',
            'host',
            'jwt',
            'call_starter'
        ];

        this.modalTimeout = null

        this.toggleLockMeeting = this.toggleLockMeeting.bind(this);
        this.startToggle = this.startToggle.bind(this);
        this.showMaximumModal = this.showMaximumModal.bind(this);
        this.mediaChange = this.mediaChange.bind(this);
    }
    state: State;


    mediaChange({ matches }: { matches: any }) {
        this.setState({ is_mobile: matches });
    }

    async componentDidMount() {
        try {
            if(!!!localStorage.getItem('token')) {
                throw new Error('invalid token')
            }
            is_mobile.addListener(this.mediaChange);
            const limits = await LoopApi(null, 'StripeGetRoomOwnerLimits');
            this.setState({ limits, is_mobile: is_mobile.matches, modalShown: false });
    
            this.modalTimeout = setTimeout(() => {
                if (this.state.modalShown === false) {
                    this.setState({ modalShown: true })
                }
            }, 3000);
        } catch (err) {
			//console.log("error: ", err)
            if(err === 'invalid token') {
                localStorage.removeItem('token')
                if(localStorage.getItem('audio_input_device_id')) {
                    localStorage.removeItem('audio_input_device_id')
                }
                if(localStorage.getItem('video_input_device_id')) {
                    localStorage.removeItem('video_input_device_id')
                }
                if(localStorage.getItem('audio_output_device_id')) {
                    localStorage.removeItem('audio_output_device_id')
                }

                if(this.props.history) {
                    this.props.history?.push(`/login`)
                } else {
                    window.location.href = '/login'
                }
            }
        }
    }

    componentDidUpdate(_: any, prevState: State) {
        const numUsersInCall = this.state.connectedUserIds?.length === undefined ? (Object.keys(this.state.connectedUserIds) || [])?.filter((key: any) => !isNaN(parseInt(key)))?.length : this.state.connectedUserIds?.length
        const isInCall = this.props.meetingContext.callStatus === CallingInstanceState.Connected
        if (this.state.modalShown === false) {
            if (numUsersInCall && !!!isInCall && !!this.state.db_meeting.settings) {
                ModalActions.SetModal("MeetingOngoing");
                this.setState({ modalShown: true })
                clearTimeout(this.modalTimeout)
                this.modalTimeout = null
            }
        } else if(!!!numUsersInCall) {
            ModalActions.SetModal("");
            clearTimeout(this.modalTimeout)
            this.modalTimeout = null
        }


    }

    componentWillUnmount() {
        is_mobile.removeListener(this.mediaChange);
    }

    startToggle() {
        this.setState({ toggleStatus: 'toggling' });
        this.toggleLockMeeting();
    }

    async toggleLockMeeting() {
        try {
            const resp = await LoopApi(null, 'ToggleLockMeeting', {
                session_id: this.state.session_id,
                locked: !this.state.meetingLocked
            }, [
                ['name', this.state.meetingName],
            ])
            this.setState({ toggleStatus: resp.status })

        } catch (e) {
            this.setState({ toggleStatus: 'failed' })
        }
    }

    showMaximumModal() {
        ModalActions.SetModal('MaximumAllowedParticipants');
    }

    render() {
        const provider = (this.state.db_meeting?.settings?.calling_provider || 'webrtc') as CallingProviderName;
        const isHost = this.state.host === this.state.jwt.data._id;
        const type = this.state.jwt.data.type
        const StartButtons = (
            ongoingParticipants: any,
            closeCb: (video: boolean, _: unknown, audio: boolean) => void
        ) => {
            return (
                <Container key='call-controls-start'>
                    {(ongoingParticipants || 0) + 1 > this.state.limits?.meeting ? (
                        <ExternalButtonDisabled
                            className={`button ${ongoingParticipants ? 'secondary' : 'primary'}`}
                            onClick={this.showMaximumModal}
                        >
                            <React.Fragment>
                                <JoinLabel>Limit Reached</JoinLabel>
                            </React.Fragment>
                        </ExternalButtonDisabled>
                    ) : ongoingParticipants === 3 && provider === 'DAILYCO' ? (
                        <ExternalButtonDisabled
                            className={`button ${ongoingParticipants ? 'secondary' : 'primary'}`}
                            onClick={this.showMaximumModal}
                        >
                            <React.Fragment>
                                <StyledUsersIcon size={18} />
                                <JoinLabel>
                                    Join Meeting
                                    <span>{ongoingParticipants}-In-Meeting</span>
                                </JoinLabel>
                            </React.Fragment>
                        </ExternalButtonDisabled>
                    ) 
                    // : type === 'guest' && !!!ongoingParticipants  ? 
                    //     <ExternalButtonDisabled
                    //     className={`button ${ongoingParticipants ? 'secondary' : 'default'}`}
                    // >
                    //     <React.Fragment>
                    //         <StyledUsersIcon size={18} />
                    //         {!this.state.is_mobile && (
                    //             <JoinLabel>Start Meeting</JoinLabel>
                    //         )}
                    //     </React.Fragment>
                    // </ExternalButtonDisabled> 
                    : this.state.limits?.isFreePlan ? null 
                    :
                        <ExternalButton
                            className={`button ${ongoingParticipants ? 'secondary' : 'default'}`}
                            closeCb={(...args: [any, any, any]) => {
                                //console.log(args);
                                closeCb(...args);
                            }}
                            width='500px'
                            right
                            video
                            DropdownItems={Onboarding}
                            overlayIndex={99}
                            containerIndex={100}
                            callDropdownOpen={this.state.callDropdownOpen}
                            onOverlayClick={() => {
                                setTimeout(() => {
                                    this.props.meetingContext.removeLocalVideoTrack()
                                    this.props.meetingContext.removeLocalAudioTrack()
                                }, 500)
                            }}
                            closeToggle={!this.props.meetingContext.localTracks?.length && !this.props.meetingContext.isAcquiringLocalTracks}
                        >
                            {ongoingParticipants ? (
                                <React.Fragment>
                                    <StyledUsersIcon size={18} />
                                    {!this.state.is_mobile && (
                                        <JoinLabel>
                                            Join Meeting
                                            <span>{ongoingParticipants}-In-Meeting</span>
                                            <JoinMeetingButton />
                                        </JoinLabel>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                   <StyledUsersIcon size={18} /> {!this.state.is_mobile && 'Start Meeting'}
                                </React.Fragment>
                            )}
                        </ExternalButton>
                    }
                </Container>
            );
        };

        if (this.props.meetingContext.callStatus === CallingInstanceState.Joining) {
            return <JoiningWrapper className="button container content">
                <div className="dots">
                    <div className="dot dot-1 button default"></div>
                    <div className="dot dot-2 button default"></div>
                    <div className="dot dot-3 button default"></div>
                </div>
                {!this.state.is_mobile && 'Joining'}
                {/* Joining */}
            </JoiningWrapper>
        }

        if (this.props.meetingContext.callStatus !== CallingInstanceState.Connected) {
          const startRen = StartButtons(
            this.state.connectedUserIds?.length === undefined
              ? (Object.keys(this.state.connectedUserIds) || [])?.filter(
                  (key: any) => !isNaN(parseInt(key)),
                )?.length
              : this.state.connectedUserIds?.length,
            (
              video: MediaTrackConstraints | boolean,
              _: unknown,
              audio: MediaTrackConstraints | boolean,
            ) => {
              VariableCallingActions.Join({video, audio})
            },
          )
          return startRen
        }

        return (
            <Wrapper>
                {this.state.toggleStatus !== 'toggling' ?
                    <React.Fragment>
                        {
                            this.state.meetingLocked ?
                                <div className={`color-primary locked ${isHost ? '' : 'disabled'}`} data-tip data-for='longMessage' onClick={this.startToggle}><LockClosedIcon size={16} /></div> :
                                <div className={`color-text unlocked ${isHost ? '' : 'disabled'}`} data-tip data-for='unres' onClick={this.startToggle}><LockOpenIcon size={16} data-tip /></div>

                        }
                        <CustomTip top={-40} left={-25} class='top-right' place='left' tip={isHost ? 'When lock screen is enable, other users except the host will not be able to navigate Syncs bar.' : 'Restricted meeting navigation'} dataFor='longMessage' />
                        <CustomTip top={-40} left={-25} class='top-right' place='left' tip="Unrestricted meeting navigation" dataFor='unres' />
                        {/* <CustomTip top={-40} left={-25} class='top-right' place='left' tip="Unrestricted meeting navigation" dataFor='meetingNav' /> */}
                    </React.Fragment> :
                    <React.Fragment>
                        <div className="color-text loading"><LoadingIcon size={16} /></div>
                    </React.Fragment>
                }
                <Button
                  className="button danger"
                  onClick={() => ModalActions.SetModalWithParams('ConfirmLeaveMeeting', {
                      cb: () => {
                        if(this.props.meetingContext.callProvider === CallingProviderName.DailyCo) {
                            //   this.props.meetingContext.room?.stopRecording()
                            this.props.meetingContext.room?.leave()
                            this.props.meetingContext.room?.destroy()
                            this.props.meetingContext.stopLocalTracks()
                        } else {
                            this.props.meetingContext.room?.disconnect()
                        }
                        closeAwsTranscriber();
                        toast.dismiss('meetingRecordingNotif')
                      }
                  })}
                >
                  <StyledLeaveIcon size={18} />
                  {!this.state.is_mobile && 'Leave'}
                </Button>
              </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    .locked,
    .unlocked {
        margin-right: 5px;
        cursor: pointer;
    }
    .loading {
        cursor: default;
        margin-right: 5px;
        animation: spin 2s linear infinite;
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
    .disabled {
        pointer-events: none;
    }
`;

const JoinLabel = styled.div`
    display: flex;
    flex-direction: column;
    span {
        display: none;
        font-size: 11px;
        text-align: center;
        transition: display 0.3s ease-in-out;
    }
    &:hover {
        span {
            display: block;
        }
    }
`;

const dot = keyframes`
	0% {
		transform: translateY(3px);
	}
	50% {
		transform: translateY(-3px);
	}
	100% {
		transform: translateY(3px)
	}
`;
const dots = keyframes`
	0%, 60% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
`;

const JoiningWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 12px 15px !important;
    margin-right: 6px;
    margin-left: 6px;
    .dots {
        animation: ${dots} 1s ease-in-out both;
        display: flex;
        justify-content: center;
        width: 20px;
        height: 10px;
        margin-right: 5px;
        .dot {
            float: left;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            padding: 0px !important;
        }
        .dot-2 {
            margin: 0 3px;
        }
    }
    .dot-1 {
        animation: ${dot} 1s ease-in-out infinite;
    }
    .dot-2 {
        animation: ${dot} 1s ease-in-out -0.2s infinite;
    }
    .dot-3 {
        animation: ${dot} 1s ease-in-out -0.4s infinite;
    }
    
	.dots {
		animation: ${dots} 1s ease-in-out both;
		display: flex;
		justify-content: center;
		width: 20px;
		height: 10px;
		margin-right: 5px;
		
		.dot {
			float: left;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			padding: 0px !important;
		}
		
		.dot-2 {
			margin: 0 3px;
		}
	}
	
	.dot-1 {
		animation: ${dot} 1s ease-in-out infinite;
	}
	
	.dot-2 {
		animation: ${dot} 1s ease-in-out -0.2s infinite;
	}
	
	.dot-3 {
		animation: ${dot} 1s ease-in-out -0.4s infinite;
	}
	@media (max-width: 767px) {
		.dots {
			margin-right: initial;
		}
	}
`

const Button = styled.button`
    border: none;
    display: flex;
    align-items: center;
    padding: 12px 15px !important;
    margin-right: 6px;
    margin-left: 6px;
    cursor: pointer;
`;

const ExternalButton = styled(DropdownWrapper)`
    cursor: pointer;
    margin-right: 6px;
    margin-left: 6px;
    padding: 12px 15px !important;
    font-weight: 400;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
` as any;

const ExternalButtonDisabled = styled.div`
    cursor: pointer;
    margin-right: 6px;
    margin-left: 6px;
    padding: 12px 15px !important;
    font-weight: 400;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6; 
` as any;

const StyledUsersIcon = styled(UsersIcon)`
    margin-right: 5px;
    @media (max-width: 767px) {
        margin-right: initial;
    }
`;

const StyledLeaveIcon = styled(LeaveIcon)`
    margin-right: 5px;
	@media (max-width: 767px) {
		margin-right: initial;
	}
`

const ringingAnimation = keyframes`
    0% { transform:rotate(-0deg) scale(1) skew(1deg); }
    10% { transform:rotate(-15deg) scale(1) skew(1deg); }
    20% { transform:rotate(15deg) scale(1) skew(1deg); }
    30% { transform:rotate(-15deg) scale(1) skew(1deg); }
    40% { transform:rotate(15deg) scale(1) skew(1deg); }
    50% { transform:rotate(-0deg) scale(1) skew(1deg); }
    100% { transform:rotate(-0deg) scale(1) skew(1deg); }
`;

const Container = styled.div`
    width: auto;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .floatingIcon {
        width: 20px;
        margin-right: 5px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-45deg);
        animation: ${ringingAnimation} 2s infinite ease-in-out;
    }
    @media (max-width: 860px) {
        margin-left: 38px;
    }
`;
