import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import FileDrop from "react-file-drop";

import ImageGallery from './ImageGallery'
import SelectedImage from './SelectedImage'

import { IWidgetProps, AllWidgets } from '../types'

import LoopApi from '../../helpers/LoopApi'
import Compressor from '../../helpers/Compressor'

import DeleteImage from './DeleteImage'
import useToastify from '../../helpers/useToastify';
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast';
import moment from 'moment';

const { GlobalState } = require('reflux')

type Props = IWidgetProps<AllWidgets.Images>

interface State {
	signedUrls: any[],
	isUploading: boolean,
	overflowHeight: boolean,
	showDelete: boolean,
	toDelete: string
	current_image: any
}

export default class ImagesWidget extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.ProgressImages = this.ProgressImages.bind(this)
		this.imgClicked = this.imgClicked.bind(this)
		this.handleUpload = this.handleUpload.bind(this)
		this.triggerFileInputClick = this.triggerFileInputClick.bind(this)
		this.uploadImages = this.uploadImages.bind(this)

		this.state = {
			signedUrls: [],
			isUploading: false,
			overflowHeight: false,
			showDelete: false,
			toDelete: '',
			current_image: this.props.data.images[0] || null
		}
	}

	selectedRef = React.createRef<HTMLDivElement>()
	mainCont = React.createRef<HTMLDivElement>()

	componentDidUpdate(prevProps: Props) {
		if(prevProps.data.images !== this.props.data.images) {
			// reselect deleted current image
			const image = this.transformImageData().find((image: any) =>
			!!this.state.current_image && image.url === this.state.current_image.url)
			if(!image)
			this.setState({current_image: this.transformImageData()[0] || null})
		}
	}

	handleUpload(e: any) {
		const imageExts = [
			".apng",
			".avif",
			".gif",
			".jpg",
			".jpeg",
			".jfif",
			".pjpeg",
			".pjp",
			".png",
			".svg",
			".webp",
			".bmp",
			".ico",
			".cur",
			".tif",
			".tiff",
			".webp",
		]

		// const isImageFile = imageExts.find((ext) => e.target.value.endsWith(ext))
		// if(!!!isImageFile) return useToastify({
		// 	message: () => SuccessErrorToast({ message: 'File type not supported.', type: 'error' }),
		// 	position: "top-right",
		// 	autoClose: 1500,
		// 	closeButton: false,
		// 	hideProgressBar: true,
		// 	className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
		// 	bodyClassName: "grow-font-size",
		// })

		this.uploadImages(e.target.files)
	}

	async uploadImages(targetFiles: any) {
		console.log({ targetFiles })
		let uploadedLinks: any = null
		try {
			const imageExts = [
				"gif",
				"jpg",
				"jpeg",
				"png",
				"webp",
			]
			const files = Array.from(targetFiles).filter(({ type }) => type.includes('image/') && imageExts.some(a => type.includes(a)))
			const filesNotSupported = Array.from(targetFiles).filter(({ type }) => !!!type.includes('image/') || !!!imageExts.some(a => type.includes(a)))
			// console.log({ files })

			if (filesNotSupported.length > 0 && files.length) {
				useToastify({
					message: () => SuccessErrorToast({ message: 'Some file types are not supported but will continue to upload supported files.', type: 'error' }),
					position: "top-right",
					autoClose: 3000,
					closeButton: false,
					hideProgressBar: true,
					className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
					bodyClassName: "grow-font-size",
				})
			}

			if(files.length === 0) {
				return useToastify({
					message: () => SuccessErrorToast({ message: `File type${filesNotSupported.length > 1 ? 's' : ''} not supported.`, type: 'error' }),
					position: "top-right",
					autoClose: 1500,
					closeButton: false,
					hideProgressBar: true,
					className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
					bodyClassName: "grow-font-size",
				})
			}

			// Start Loading
			this.setState({ isUploading: true })

			const upload = async (file: any) => {
				const data = new FormData()
				const newFile =  new File([file], `${moment().format('X')}-${file.name}`, {
					type: file.type,
					lastModified: file.lastModified,
				});
				
				data.append('file', newFile)
				data.append('targetWidget', 'images')
				let response = await LoopApi(null, 'UploadFile', {}, undefined, data)
				return response.uri
			}

			const links: any[] = []

			for (const file of files) {
				const uri = await upload(file)
				links.push(uri)
			}

			targetFiles = ''

			uploadedLinks = links
			this.props.actions.AddLink(links)
		} catch (err) {
			//console.log(err)
		} finally {
			// End Loading
			setTimeout(() => {
				if(uploadedLinks) {
					const image = this.transformImageData().find((image: any) => image.url === uploadedLinks[0])
					if(image)
					this.setState({ isUploading: false,  current_image: image})
				} else {
					this.setState({ isUploading: false })
				}
			}, 500)
		}
	}

	triggerFileInputClick() {
		let files: any = document.getElementById('files-to-upload')
		files.value = ''

		if (files) {
			files.click()
		}
	}

	imgClicked(current_image: any) {
		this.setState({current_image})
		// this.props.actions.UpdateSelf({ current_image })
	}

	ProgressImages(new_index: number) {
		if (this.props.data.images[new_index]) {
			this.setState({current_image: this.props.data.images[new_index]})
		}
	}

	toggleModal = (val: boolean, item?: string) => {
		if(val && !!item)
		this.setState({toDelete: item})

		this.setState({showDelete: val})
	}

	deleteItem = () => {
		if(!!this.state.toDelete) {
			this.props.actions.RemoveLink(this.state.toDelete)
			this.setState({toDelete: ''})
		}
	}

	transformImageData = () => {
		let images: any = []
		const imgs = this.props.data.images
		if(imgs) {
			if(!Array.isArray(imgs)) {
				images = (Object.values(imgs) || []).sort((a: any, b: any) => a.indx - b.indx)
				return images
			}
			return imgs
		}
		return images
	}

	render() {
		const images = this.transformImageData()

		let curr_img_index = -1
		if (images.length > 0 && this.state.current_image) {
			const hash_to_find = this.state.current_image.image_hash
			curr_img_index = images.findIndex(
				(img: any) => img.image_hash === hash_to_find
			)
		}

		return (
			<div ref={this.mainCont} style={{height: '100%'}}>
				<Container
					onDrop={(e: any) => {
						this.uploadImages(e)
					}}
				>
					<div className="show-while-dragging">Drag Files Here</div>
					<div className="show-while-dragging-over">Drop Files</div>
					{(images || []).length ? <ImageGallery
						images={images}
						imgClicked={this.imgClicked}
						triggerFileInputClick={this.triggerFileInputClick}
						currentImage={this.state.current_image}
						isUploading={this.state.isUploading}
						toggleModal={this.toggleModal}
						travelling={this.props.travelling}
					/> : null}
					<SelectedImage
						curr_img_index={curr_img_index}
						image={this.state.current_image}
						ProgressImages={this.ProgressImages}
						prevAndAfter={[
							images[curr_img_index - 1],
							images[curr_img_index + 1],
						]}
						triggerFileInputClick={this.triggerFileInputClick}
						isUploading={this.state.isUploading}
						addLink={this.props.actions.AddLink}
						selectedRef={this.selectedRef}
						travelling={this.props.travelling}
					/>
					{/* <ImageInput addLink={this.props.actions.AddLink} /> */}
					<input type="file" hidden multiple id="files-to-upload" onChange={this.handleUpload} accept="image/*" />

					{this.state.showDelete && (
						<DeleteImage
							toggleModal={this.toggleModal}
							deleteItem={this.deleteItem}
						/>
					)}
				</Container>
			</div>
		)
	}
}

const Container = styled(FileDrop as any)`
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	overflow: hidden;
	position: relative;
	padding: 15px;
	height: 100%;

	.file-drop-target {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		overflow: hidden;
		position: relative;
	}

	.show-while-dragging,
  .show-while-dragging-over {
    display: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 32px;
    color: white;
    font-weight: 500;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }


	@media (max-width: 768px) {
		flex-direction: column-reverse;

		.file-drop-target {
			flex-direction: column;
		}
	}
`
