import moment from 'moment';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { BiTimeFive as TimeIcon } from '@react-icons/all-files/bi/BiTimeFive'

const { GlobalState } = require('reflux')

function CallTimer(props: { startTime: any, stopped?: any}) {
    const [ timeSinceStart, setTimeSinceStart ] = useState(0)
    let interval: any = null

    useEffect(() => {
        resetInterval()
        return () => {
            clearInterval(interval)
            interval = null
        }
    }, [props.startTime])

    const resetInterval = () => {
		clearInterval(interval)
		interval = setInterval(() => {
			const then = moment.unix(props.startTime).format("DD/MM/YYYY HH:mm:ss.SSS")
			const now = moment().format()
			const timeStart = moment().diff(moment(then, "DD/MM/YYYY HH:mm:ss.SSS"));

			return !props.stopped &&
			setTimeSinceStart(timeStart)
		}, 1000);
	}

    const duration = moment.duration(timeSinceStart);
    const timer = Math.floor(duration.asHours()) + moment.utc(timeSinceStart).format(":mm:ss");
    return (
        <DurationWrapper className="main-timer bg-primary">
            {/* <RecorderWrapper className="container content">
                <Recorder />
            </RecorderWrapper> */}
            <RecorderWrapper>
                <TimeIcon />
            </RecorderWrapper>
            {timer}
        </DurationWrapper>
    )
}

export default CallTimer


const DurationWrapper = styled.div`
	display: flex;
	align-items: center;
	font-weight: 400; 
    width: 170px;
    justify-content: center;
	transition: 0.3s all ease-in-out;
	margin-top: 20px;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    z-index: 3;
    width: auto;
    padding: 5px 10px;
    border-radius: 999px;
    min-width: 100px;
    box-shadow: ${props => props.theme.shadows.newlight};
    max-width: 100px;
    margin: 0 auto;
    color: #fff;

	@media (max-width: 970px) {
		position: fixed;
		top: 80px;
		left: 0;
		right: 0;
	}
`

const RecorderWrapper = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
`

