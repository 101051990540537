import React from 'react'
import styled from 'styled-components'

interface Props {
    data: IData[]
    active?: any
    hoverEffect?: boolean
}

interface IData {
    itemOnClick?: () => void
    _id?: string
    title?: string
    content: React.ReactElement | string
}

function Timeline(props: Props) {
    const { data = [], active = {}, hoverEffect = true } = props

    return (
        <TimelineWrapper className="timeline-wrapper">
            {
                data?.map((d, i) => {
                    return (
                        <TimelineBlock key={i} onClick={d?.itemOnClick} className={`timeline-item-block ${active?._id && active?._id === d?._id ? 'container' : ''}`} hoverEffect={hoverEffect}>
                            <TimelineMarker className={`topbar ${active?._id && active?._id === d?._id ? 'border-primary' : 'border-default'}`} />
                            <div className="timeline-content">
                                <TimelineDatetime>{d?.title || ''}</TimelineDatetime>
                                <TimelineContent>{d?.content || ''}</TimelineContent>
                            </div>
                        </TimelineBlock>
                    )
                })
            }
        </TimelineWrapper>
    )
}


const TimelineMarker = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid;
    margin-top: 10px;
    margin-left: 2px;
    z-index:999;
`

const TimelineWrapper = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 15px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        margin-left: -1px;
        width: 2px;
        height: 100%;
        background: #CCD1D9;
        z-index: 1;
        left: 8px;
        width: 2px;
    }

    .timeline-content {
        width: 95%;
        padding: 0 15px;
    }
`

const TimelineBlock = styled.div<{hoverEffect: boolean}>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
        background: ${props => !props.hoverEffect ? 'transparent': '#F2F4F6'};
    }
`

const TimelineDatetime = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 5px;
`

const TimelineContent = styled.div`
    font-size: 16px;
    margin: 10px 0;
`

export default Timeline
