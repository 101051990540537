import { EventEmitter } from 'events'

export enum CallingProviderName {
	Twilio = 'TWILIO',
	Zoom = 'ZOOM',
	WebRTC = 'WEBRTC',
	DailyCo = 'DAILYCO',
	TwilioStandard = 'TWILIO::STANDARD',
	TwilioPro = 'TWILIO::PRO',
}

export const callingProviderLoadableClasses: Record<
	CallingProviderName,
	(access_token: string, localId: string, type?: string) => Promise<BaseProviderInstance>
> = {
	[CallingProviderName.Twilio]: (access_token: string, localId: string) =>
		import('./twilio').then((t) => new t.TwilioInstance(access_token, localId)),
	[CallingProviderName.TwilioStandard]: (access_token: string, localId: string, type: string) =>
		import('./twilio').then((t) => new t.TwilioInstance(access_token, localId, type)),
	[CallingProviderName.TwilioPro]: (access_token: string, localId: string, type: string) =>
		import('./twilio').then((t) => new t.TwilioInstance(access_token, localId, type)),
	[CallingProviderName.WebRTC]: (access_token: string, localId: string) =>
		import('./webrtc').then((t) => new t.WebRTCInstance(access_token, localId)),
	[CallingProviderName.Zoom]: (access_token: string, localId: string) =>
		import('./twilio').then((t) => new t.TwilioInstance(access_token, localId)),
	[CallingProviderName.DailyCo]: (access_token: string, localId: string) =>
		import('./dailyco').then((t) => new t.DailyCoInstance(access_token, localId)),
}

export enum CallingInstanceState {
	UninitializedWithoutToken = 'UNINITIALIZED',
	Initializing = 'INITIALIZING',
	ReadyToJoin = 'READY_TO_JOIN',
	Joining = 'JOINING',
	Connected = 'CONNECTED',
	JoiningFailed = 'JOINING_FAILED',
	Disconnected = 'DISCONNECTED',
}

export class BaseProviderInstance extends EventEmitter {
	providerName: CallingProviderName
	protected local_user_id: string
	protected local_presenter_id: string

	constructor() {
		super()
	}

	get participants(): IParticipant[] {
		return []
	}

	get localParticipant(): IParticipant | null {
		return null
	}

	get callObj(): any {
		return null
	}

	get getAudios(): any {
		return null
	}

	get startTime(): any {
		return null
	}

	get sortedParticipants(): any {
		return []
	}

	public async teardown() { }
	public async connect(
		room_name: string,
		constraints: MediaStreamConstraints
	) { }
}

export interface CallingProviderInstanceEvents {
	participantChange: () => void
	localStateCahange: () => void
}

export declare interface BaseProviderInstance {
	on<U extends keyof CallingProviderInstanceEvents>(
		event: U,
		listener: CallingProviderInstanceEvents[U]
	): this

	emit<U extends keyof CallingProviderInstanceEvents>(
		event: U,
		...args: Parameters<CallingProviderInstanceEvents[U]>
	): boolean

	teardown(): void
	connect(room_name: string, constraints: MediaStreamConstraints): void
	toggleScreenshare(open: boolean): void
}

export interface IParticipant {
	userId: string
	tracks: MediaStreamTrack[]
}


export const STATE_IDLE = 'STATE_IDLE';
export const STATE_CREATING = 'STATE_CREATING';
export const STATE_JOINING = 'STATE_JOINING';
export const STATE_JOINED = 'STATE_JOINED';
export const STATE_LEAVING = 'STATE_LEAVING';
export const STATE_ERROR = 'STATE_ERROR';
