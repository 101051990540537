import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import LoopApi from '../../helpers/LoopApi'
import WidgetHeader, { Subtitle, Title } from '../_Shared/WidgetHeader'
import { WidgetContainer } from '../_Shared/Elements'

import { ITeam } from './types'
import LoadingComponent from '../_Shared/LoadingComponent'
import { LoadingComponentWrapper } from './'
import useClickupContext from './useClickupContext'
import { WidgetActions } from '../../stores/MainStore'

function TeamsList() {
    const { external_token, clickupData, authFailed } = useClickupContext()
    const [teamList, setTeamList] = useState<any>([])
    const [ loading, setLoading ] = useState<boolean>(false)

    const handleTeamClick = (team: ITeam) => {
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                view: 'spaces',
                currentTeam: team
            }
        })
    }

    const listTeams = async() => {
        if (!external_token) return

        setLoading(true)
        await LoopApi(null, 'GetClickupTeams', {})
        .then((resp: any) => {
            if (resp.error) return authFailed()
            setTeamList(resp?.teams || [])
            setLoading(false)
        })
        .catch((err: any) => {
            console.error(err)
            setLoading(false)
            return authFailed()
        })
    }

    useEffect(() => {
        listTeams()
    }, [external_token])

    if (!external_token) return <React.Fragment />

    return (
        <WidgetContainer padding="30px" height="100%">
            <WidgetHeader
                name={
                    <NameHeader>
                        <Ico>
                            <img src={require("./icon.svg")} />
                        </Ico>
                        <span className="desktop">Team List</span>
                    </NameHeader>
                }
                subname="You can view the team here"
                subnameClassName="desktop"
            />
            <NameHeader className="mobile imp">
                <Title> Team List </Title>
                <Subtitle className="subheader">{'You can view the ClickUp space here'}</Subtitle>
            </NameHeader>
            <ProjectWrapper>
                {
                    loading ? 
                    <LoadingComponentWrapper className="rounded inner topbar"  style={{ marginTop: '30px' }}>
                        <LoadingComponent />
                    </LoadingComponentWrapper> :
                    teamList?.length > 0 ? teamList.map((team: any) => {
                        return (
                            <ListWrapper className="topbar rounded" key={team.name}>
                                <IconWrapper color={team.color}>{team.avatar ? <img src={team.avatar} alt={team.name} /> : !team.avatar && team.name && <span>{team.name.charAt(0)}</span>}</IconWrapper>
                                <div className="projname">{team.name}</div>
                                <Button
                                    className="button primary custom-padding"
                                    onClick={() => { handleTeamClick(team) }}>
                                    Explore
                                </Button>
                            </ListWrapper>
                        )
                        // }) : !!!this.state.loading ? <div>No team to be selected</div> : '' 
                    }) : ''
                }
                
            </ProjectWrapper>
        </WidgetContainer>
    )
}

const NotificationWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0px !important;
    cursor: pointer;

    transform: rotate(-45deg);
    animation: shake 1.5s infinite ease-in-out;
    @keyframes shake {
        0% { transform:rotate(-0deg) scale(1) skew(1deg); }
        10% { transform:rotate(-15deg) scale(1) skew(1deg); }
        20% { transform:rotate(15deg) scale(1) skew(1deg); }
        30% { transform:rotate(-15deg) scale(1) skew(1deg); }
        40% { transform:rotate(15deg) scale(1) skew(1deg); }
        50% { transform:rotate(-0deg) scale(1) skew(1deg); }
        100% { transform:rotate(-0deg) scale(1) skew(1deg); }
    }
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;

    &.mobile {
        flex-direction: column; 
        align-items: baseline;
        margin-top: 15px;
    }
`

const ProjectWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    flex: 1;
`

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    flex: 1 1 200px;
    height: 200px;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-out;

    .custom-padding {
        padding: 10px 25px !important;
        font-size: 12px;
    }

    .projname {
        font-weight: ${props => props.theme.textBold};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        box-shadow: ${props => props.theme.shadows.newlight};
    }
`


const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const IconWrapper = styled.div<{ color?: string }>`
    background: ${(props) => props.color ? props.color : 'transparent'};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 10px;

    color: hsla(0,0%,100%,.9);
    font-size: 16px;
    font-weight: ${props => props.theme.textBold};

    img {
        width: 100%;
        border-radius: 50%;
    }
`

const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 35px;
        border: none;
        align-self: center;
        margin: 0 14px;
    }
`


export default TeamsList
