import React from 'react'

import Header from '../Header'
import Form from './Form'
import { Container } from './styled'

function SignUp() {
  return (
    <Container>
        <Header login />
        <Form />
    </Container>
  )
}

export default SignUp