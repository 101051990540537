import React from "react"
import styled from "styled-components"
import { IoEllipsisVerticalSharp as EllipsisIcon } from "@react-icons/all-files/io5/IoEllipsisVerticalSharp"
import Downshift from "downshift"

import { DropboxFile, SelectionItem } from "../types"
import getIcon from "../_Shared/FileIcon"
import { humanFileSize } from '../../helpers'

interface Props {
	file: DropboxFile,
	downloadFile: Function
	viewFile: Function
	presentFile: Function
	options: SelectionItem[]
	setFileToDownload: (file: DropboxFile) => void
	onPresent: Function
	colorScheme: string
}

function GridFileItem(props: Props) {
	const {file, options, downloadFile, viewFile, presentFile, setFileToDownload, onPresent, colorScheme} = props
	const FileIcon = getIcon(file.name)

	const changeHandler = async (selected: SelectionItem) => {
		switch (selected.value) {
			case "download":
				downloadFile(file)
				break;

			case "view":
				viewFile(file)
				break;

			case "pdf-presenter":
				presentFile(file)
				break;

			case "present":
				await viewFile(file)
				onPresent()
				break;

			default:
				// no-op
				break;
		}
	}

	const viewOrDownload = () => {
		const viewIndex = options.findIndex(option => option.value === "view")

		if (viewIndex > -1) {
			viewFile(file)
		} else {
			setFileToDownload(file)
		}
	}

	return (
		<Container className="topbar" onClick={viewOrDownload} isLight={colorScheme === 'Light'}>
			<Wrapper>
				<IconWrapper>
					<FileIcon size={24} fill="#fff" />
				</IconWrapper>
				<Text>{file.name}</Text>
				{
					file.size ?
					<Text className="d-filesize">File Size: {humanFileSize(file.size)}</Text> : null
				}

			</Wrapper>

			<Downshift onChange={changeHandler}>
				{props => (
					<DropdownContainer {...props.getRootProps()}>
						<EllipsisIcon {...props.getToggleButtonProps({
							onClick(event) {
								event.stopPropagation()
							}
						})} />
						<DropdownList {...props.getMenuProps()} isLight={colorScheme === 'Light'}>
							{props.isOpen && options.map((option, index) => (
								<DropdownListItem
									{...props.getItemProps({
										key: option.value,
										item: option,
										index,
										onClick(event) {
											event.stopPropagation()
										}
									})}
								>
									<option.icon fill="#fff" /> {option.text}
								</DropdownListItem>
							))}
						</DropdownList>
					</DropdownContainer>
				)}
			</Downshift>
		</Container>
	)
}

const Container = styled.div<{isLight: boolean}>`
	position: relative;
	cursor: pointer;
	padding: 16px 35px;
	background-color: ${({isLight}) => isLight ? '#fff' : '#1b1b25'};
	color: ${({isLight}) => isLight ? 'rgb(54, 59, 69)' : '#fff'};
	border-radius: 8px;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.d-filesize {
		font-size: 10px;
		color: rgb(172, 172, 172);
		text-align: left;
	}
`

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	max-height: 80px;
    min-height: 80px;

	img {
		height: 50px !important;
    	width: 50px !important;
	}
`

const Text = styled.p`
	margin-top: 16px;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

const DropdownContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	width: 24px;
	padding: 4px;
	text-align: end;
`

const DropdownListItem = styled.li<{isLight: boolean}>`
	padding: 10px;
	display: flex;
	align-items: center;
	transition: background 0.3s;

	& > svg {
		margin-right: 8px;
	}
`

const DropdownList = styled.ul<{isLight: boolean}>`
	position: absolute;
	right: 40%;
	width: 161px;
	background-color: ${({isLight}) => isLight ? '#fff' : '#181820'};
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	${DropdownListItem} {
		${({isLight}) => isLight ?
			`
				color: #000;
				&:hover {
					background: #e7e7e7;
				}
				& > svg {
					fill: #000 !important;
					color: #000;
				}
			`
			:
			`
				color: #fff;
				&:hover {
					background: #2b2b39;
					color: blue;
					> svg {
						color: blue;
						fill: blue;
					}
				}
				& > svg {
					color: #fff;
				}
			`
		}
	}
`


export default GridFileItem
