import MainParticipantInfo from './MainParticipantInfo'
import ParticipantTracks from './ParticipantTracks'
import React from 'react'
import useMainParticipant from '../../hooks/useMainParticipant'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'
import {useCallback} from 'react'
import useMainParticipantNoScreenshare from '../../hooks/useMainParticipantNoScreenshare'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { Room } from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'

interface Props {
  hideScreenshareLabel?: boolean
}

export default function MainParticipantNoScreenshare(props: Props) {
  const mainParticipant = useMainParticipantNoScreenshare()
  const {room} = useMeetingContext()
  const localParticipant = (room as Room)!.localParticipant || (room as DailyCall)?.participants()?.local
  const screenShareParticipant = useScreenShareParticipant()

  const videoPriority = mainParticipant === screenShareParticipant ? 'high' : null

  return (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo
      participant={mainParticipant}
      isScreenSharing={!!screenShareParticipant}
      hideScreenshareLabel={props.hideScreenshareLabel}
    >
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={false}
        videoPriority={videoPriority}
        isLocalParticipant={false}
      />
    </MainParticipantInfo>
  )
}
