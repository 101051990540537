import {useState, useEffect} from 'react'
import {LocalVideoTrack, RemoteVideoTrack} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

type TrackType = LocalVideoTrack | RemoteVideoTrack | MediaStreamTrack
type TwilioTrackType = LocalVideoTrack | RemoteVideoTrack

export default function useVideoTrackDimensions(track?: TrackType, onboarding?: boolean) {
  const [dimensions, setDimensions] = useState((track as TwilioTrackType)?.dimensions)
  const { callProvider } = useMeetingContext()

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  useEffect(() => {
    if(isTwilio || onboarding) {
      setDimensions((track as TwilioTrackType)?.dimensions)
  
      if (track) {
        const handleDimensionsChanged = (newTrack: TwilioTrackType) =>
          setDimensions({
            width: newTrack.dimensions.width,
            height: newTrack.dimensions.height,
          });
        (track as TwilioTrackType).on('dimensionsChanged', handleDimensionsChanged);
        return () => {
          (track as TwilioTrackType).off('dimensionsChanged', handleDimensionsChanged);
        }
      }
    }
  }, [track])

  return dimensions
}
