import { Flip, toast } from 'react-toastify';

type IType = 'success' | 'default' | 'info' | 'warning' | 'error' | 'dark'
type IPosition = 'top-right' | 'top-left' | 'top-center' | 'bottom-left' | 'bottom-right' | 'bottom-center'

interface Props {
    message: string | React.ReactNode
    position?: IPosition
    type?: IType
    autoClose?: number | false
    draggable?: boolean
    closeOnClick?: boolean
    pauseOnHover?: boolean
    className?: string
    bodyClassName?: string
    progressClassName?: string
    closeButton?: boolean
    onClose?: () => void
    hideProgressBar?: boolean
    transition?: any
    toastId?: string
}

function useToastify(props: Props) {
  const { message = '', type = 'default', autoClose = 3000, draggable = true, position = 'top-right', closeOnClick = true, pauseOnHover = true, closeButton = true, transition = Flip, ...rest } = props;
  if (document.hidden) {
    return
  }
  return message && toast(message, { type, autoClose, draggable, position, closeOnClick, pauseOnHover, closeButton, transition, ...rest });
}

export default useToastify
