import React from 'react'
import styled from 'styled-components'
import { FaCrown as HostIcon } from '@react-icons/all-files/fa/FaCrown'
import { CallControlsContainer } from './styles'
import CustomTip from '../../../../../helpers/CustomTip'

interface Props {
	transferHostAccess?: () => void
	showTransferAccess?: boolean
}

function OtherParticipantsControl(props: Props) {
	return (
		<CallControlsContainer className="call-control-container">
			{
				props.showTransferAccess &&
				<IconButtonMore
					title="Make Host"
					className="button default-opaque call-control"
					onClick={() => {
						if (props.transferHostAccess) props.transferHostAccess()
					}}
				>
					<HostIcon data-tip data-for='makeH' />
					<CustomTip top={-40} left={-25} class='top-right' place='left' tip='Make Host' dataFor='makeH' />

				</IconButtonMore>
			}
		</CallControlsContainer>
	)
}

export default OtherParticipantsControl

export const IconButtonMore = styled.div<{ muted?: boolean, active?: boolean }>`
	padding: 16px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 4px;
	border-radius: 2px;
	font-size: 20px;
	transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
	opacity: 0;
	position: relative;
	cursor: pointer;
`