import React, { useEffect, useMemo, useState } from 'react'
import { ChatContext } from '../ChatContext'
import { withMemoizedContext } from 'react-memoized-context';
import { IContext } from '../types';
import styled from 'styled-components';
import { AiOutlineFile } from '@react-icons/all-files/ai/AiOutlineFile'
import { formatBytes } from '../chatHelper';
import moment from 'moment';
import ChatAvatar from '../ChatAvatar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    context: IContext
}

function DummyAttachment({ context }: Props) {

    const dummyuploads = context.uploading || {}


    const FileTile = (file: any) => <>
        <FileIconWrapper>
            <FIleType>
                {file.file.name?.substr(file.file.name.lastIndexOf('.') + 1)}
            </FIleType>
            <AiOutlineFile size={32} />
        </FileIconWrapper>
        <FileName>{file.file.name}</FileName>
        <FileSize> {formatBytes(file.file.size)}</FileSize>
        <DownloadButton >
            Uploading...
        </DownloadButton>
    </>

    const PhotoTile = (photos: []) => useMemo(() => {
        return (
            < >
                {
                    photos.slice(0, 2).map((p: any, indx: number) =>
                        <img
                            // onClick={() => props.viewImage(p.index)}
                            key={indx}
                            src={p}
                            alt={`img${indx}`}
                            style={{
                                width: photos.length > 1 ? 125 : 'unset',
                                objectFit: photos.length > 1 ? 'cover' : 'contain',
                                maxWidth: photos.length > 1 ? 125 : 250,
                                maxHeight: photos.length > 1 ? 125 : 250,
                                cursor: 'pointer'
                            }}
                        />
                    )
                }
                {
                    photos.length > 2 && <CountOverlay>
                        +{photos.length - 2}
                    </CountOverlay>
                }


            </ >
        )
    }, [JSON.stringify(photos)])

    const Progress = (progress: number) => {

        return <ProgressOverlay>
            <ProgressCont>
                <CircularProgressbar
                    styles={buildStyles({

                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',

                        // Text size
                        textSize: '16px',

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        pathColor: `rgba(255, 255, 255, ${progress})`,
                        textColor: '#ffffff',
                        trailColor: `rgba(255, 255, 255, .25)`,
                    })}
                    value={progress}
                    text={`${progress}%`} />
            </ProgressCont>
        </ProgressOverlay>
    }

    return Object.keys(dummyuploads).length ? (
        <>
            <MyCont prev={false}>
                <ChatContent>
                    <ChatInfo>
                        {/* {moment(props.chat.timeStamp).format('ddd, MMM DD YYYY - h:mm A')} */}
                        {moment().format('h:mm A')}
                    </ChatInfo>

                    {Object.keys(dummyuploads).map((key: any) => {
                        let photos: any = []
                        let progress = 0
                        if (key.indexOf('photos') === 0) {

                            return Object.values(dummyuploads[key]).map((val: any, index: number) => {
                                if (typeof val === 'object') {
                                    photos.push(val.signedUrl.imgUrl)
                                } else {
                                    progress = val
                                }

                                if (index === Object.values(dummyuploads[key]).length - 1)
                                    return <PhotoCont>
                                        {PhotoTile(photos)}
                                        {Progress(progress)}
                                    </PhotoCont>
                            })

                        } else {
                            return <ChatMessage className='bg-gradient bubble'>
                                {FileTile(dummyuploads[key])}
                                {Progress(dummyuploads[key].progress)}
                            </ChatMessage>
                        }

                    })}

                </ChatContent>
                <ChatAvatar id={context.myId} />
            </MyCont>

        </>

    ) : <></>
}

const ComponentContexted = withMemoizedContext(ChatContext)(DummyAttachment, ['uploading', 'photoCount', 'myId']);


export default ComponentContexted

const ProgressOverlay = styled.div`
    position: absolute;
    background-color: #00000066;
    height: 100%;
    padding: 51px;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 100%;
`


const ProgressCont = styled.div`
    width: 50px;
    height: 50px;
`

const FileName = styled.span`
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 125px;
`

const FileSize = styled.span`
    font-size: 10px;
`

const FIleType = styled.span`
    position: absolute;
    font-size: 10px;
    margin-top: 5px;
`

const DownloadButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: center ;
    margin-top: 5px;
    padding-top: 10px;
    border-top: 2px solid;
    font-weight: bold;
    cursor: pointer;
`

const FileIconWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    max-width: 125px;
    margin-bottom: 10px;
`

const CountOverlay = styled.div`
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center ;
    background-color: #000000a1;
    font-size: 25px;
    color: white;
    position: absolute;
    right: 0;
    pointer-events: none ;
`

const PhotoCont = styled.div`
    margin-top: 3px;
    min-height: 125px;
    overflow: hidden;
    border-radius: 15px;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    position: relative;
`

const MyCont = styled.div <{ prev: boolean }>`
    margin: ${({ prev }) => prev ? '1px 10px' : '10px 10px 1px 10px'};
    display: flex;
    justify-content: right;
`

const ChatMessage = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    color: white;
    word-break: break-word;
    max-width: 250px;
    margin-top: 2px;
    position: relative;
`

const ChatContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin-right: 10px;
    align-items: flex-end;

`

const ChatInfo = styled.span`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    font-size: 10px;
`
