import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { AllWidgets } from '..'
import { IWidgetProps } from '../types'
import Modal from '../_Shared/Modal'
import ProgressBar from '../_Shared/ProgressBar'
import CreateTaskForm from './CreateTaskForm'
import TaskItem from './TaskItem'
import moment from 'moment'
import RDatepicker from '../_Shared/Datepicker'
import Dropdown from '../_Shared/DropdownWrapper'
import { IoFilter as FilterIcon } from '@react-icons/all-files/io5/IoFilter'
import { TiDelete } from '@react-icons/all-files/ti/TiDelete'
import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'

import { Todos, Todo, Tasks } from './types'
import Toggle from '../_Shared/Toggle'
import CustomTip from '../../helpers/CustomTip'

type Props = {
    toggleCollapseSidebar: () => void
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    users: any
    data: any
    userId: any
    collapsed: boolean
    addLink: (s: string) => void
    changeIndexState: (s: any) => void
    todos: Todos | null
    selectedGroup: string
    travelling: boolean
}

type state = {
    completed: number
    showMoreDescription: boolean
    showFilter: boolean
    filteredAssignee: any[]
    filteredDate: string
    showModal: boolean
    selectedTask: string
    dateRange: boolean
    endDate: string
}

export class SelectedGroup extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            completed: 0,
            showMoreDescription: false,
            showFilter: false,
            filteredAssignee: [],
            filteredDate: '',
            showModal: false,
            selectedTask: '',
            dateRange: false,
            endDate: ''
        }

        this.handleCheck = this.handleCheck.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
    }

    toggleShowMoreDescription = () => {
        this.setState(prevState => ({
            ...prevState,
            showMoreDescription: !prevState.showMoreDescription
        }))
    }

    cropText = (text: string) => {
        return text?.length > 60
            ? text.split('').slice(0, 60).join('') + '...'
            : text
    }

    handleEdit(e: string) {
        this.setState({ showModal: true, selectedTask: e })
    }

    handleCheck(checkedTask: string) {
        const todos = { ...this.props.todos }
        const tasks = todos[this.props.selectedGroup].tasks
        if (tasks && tasks[checkedTask]) {
            const checked = tasks[checkedTask]
            const prevDone = checked.done
            todos[this.props.selectedGroup].tasks = {
                ...tasks,
                [checkedTask]: {
                    ...checked,
                    done: !prevDone
                }
            }
        }
        this.props.actions.UpdateSelf({ todo: todos })
    }

    isToday = (someDate: Date) => {
        const today = new Date()

        const tomorrow = new Date(today)

        return someDate === moment(today).format('YYYY-MM-DD')
    }

    isTomorrow = (someDate: Date) => {
        const today = new Date()

        const tomorrow = new Date(today)

        return (
            someDate ===
            moment(tomorrow.setDate(tomorrow.getDate() + 1)).format(
                'YYYY-MM-DD'
            )
        )
    }

    isYesterday = (someDate: Date) => {
        const today = new Date()

        const tomorrow = new Date(today)

        return (
            someDate ===
            moment(tomorrow.setDate(tomorrow.getDate() - 1)).format(
                'YYYY-MM-DD'
            )
        )
    }

    sortDates = (dates: any) => {
        // return dates.sort((a: any, b: any) => b - a);

        var sorted = dates.sort(function (a: any, b: any) {
            a = a.split('/').reverse().join('')
            b = b.split('/').reverse().join('')
            // return a > b ? 1 : a < b ? -1 : 0;
            return a.localeCompare(b) // <-- alternative
        })

        return sorted
    }

    toggleFilter = () => {
        this.setState((prevState: state) => ({ showFilter: !prevState.showFilter }))
    }

    addAssignee = (assignee: any, e: any) => {
        e.stopPropagation()

        var check = false
        this.state.filteredAssignee.map((m: any) => {
            if (m.id === assignee.id) {
                return (check = true)
            }
        })

        if (!check) {
            const filteredAssignee = [...this.state.filteredAssignee, { ...assignee }]

            this.setState({ filteredAssignee })
        } else {
            this.removeAssignee(assignee, e)
        }
    }

    removeAssignee = (assignee: any, e: any) => {
        e.stopPropagation()
        const filteredAssignee = this.state.filteredAssignee.filter(
            (a: any) => a.id !== assignee.id
        )

        this.setState({ filteredAssignee })
    }

    render() {
        if (!this.props.todos || (this.props.selectedGroup && this.props.todos && !this.props.todos[this.props.selectedGroup]))
            return

        let selected: Todo = { ...this.props.todos[this.props.selectedGroup] }

        let tasks: Tasks | null = selected?.tasks

        let completed = 0
        let done = 0
        let todo = 0

        if (tasks && Object.keys(tasks).length) {
            Object.values(tasks).map((m: any) => {
                if (m.done) {
                    done += 1
                } else {
                    todo += 1
                }
            })
            completed = Math.round((done / (done + todo)) * 100)
        }

        if (tasks && this.state.filteredDate && Object.keys(tasks).length) {
            if (!this.state.endDate) {


                tasks = Object.entries(tasks).reduce((acc, [key, task]) => {
                    //console.log(task.dueDate, !this.state.endDate);

                    if (task.dueDate && moment(task?.dueDate).format('YYYY-MM-DD') === this.state.filteredDate) {
                        return {
                            ...acc,
                            [key]: task
                        }
                    }
                    return acc
                }, {})
            } else {
                tasks = Object.entries(tasks).reduce((acc, [key, task]) => {
                    const dueDate = moment(task?.dueDate).format('YYYY-MM-DD')

                    //console.log(dueDate);


                    if (moment(dueDate).isSameOrAfter(this.state.filteredDate) && moment(dueDate).isSameOrBefore(this.state.endDate)) {
                        return {
                            ...acc,
                            [key]: task
                        }
                    }
                    return acc
                }, {})
            }
        }

        if (tasks && this.state.filteredAssignee?.length > 0) {
            tasks = Object.entries(tasks).reduce((acc, [key, task]) => {
                if (task.assignees.length) {
                    const intersected = task.assignees.find((ta: any) => {
                        const fas = this.state.filteredAssignee.find((fa: any) => {
                            return fa.id === ta.id
                        })
                        return !!fas ? true : false
                    })

                    if (intersected)
                        return { ...acc, [key]: task }
                    else
                        return acc
                }
                return acc
            }, {})
        }

        const Assignees = () => {
            return (this.props.users || []).filter((user: any) => user.type !== 'guest').map((user: any) => {
                const bordered =
                    (this.state.filteredAssignee || []).filter(
                        (a: any) => a.id === user.id
                    ).length > 0
                return (
                    <UserWrapper
                        className={`hover-container ${bordered ? 'container' : ''}`}
                        style={{ padding: '2px 10px' }}
                        onClick={(e) => this.addAssignee(user, e)}
                    >
                        <ImageWrapper
                            style={{ marginRight: '5px' }}
                            bordered={bordered}
                            className="border-light"
                        >


                            {bordered ? (
                                <TiDelete
                                    className="remove-assignee bottom"
                                    onClick={(e: any) =>
                                        this.removeAssignee(user, e)
                                    }
                                />
                            ) : (
                                ''
                            )}
                            {user.avatar_url ? (
                                <img
                                    src={user.avatar_url}
                                    alt={user.name || user.email}
                                />
                            ) : (
                                <span className="assignee-name">
                                    {user.initials}
                                </span>
                            )}
                        </ImageWrapper>
                        {user.name || user.email}
                    </UserWrapper>
                )
            })
        }

        var dates: Array<Date> = []

        const toRender = (this.props.todos[this.props.selectedGroup] &&
            (!tasks || !Object.keys(this.props.todos[this.props.selectedGroup]).length)) ? (
            <Right className="topbar rounded" id='selected-group-cont'>
                <Header >
                    <MenuWrapper>
                        <SideMenuIcon onClick={this.props.toggleCollapseSidebar} />
                    </MenuWrapper>
                    <Color background={selected.groupColor}></Color>
                    <Info>
                        <NameSpan>
                            <span className="groupname" onClick={() => {
                                !this.props.travelling &&
                                    this.props.changeIndexState({ showCreateGroup: true, groupIdtoEdit: this.props.selectedGroup })
                            }}>
                                {selected.groupName}
                            </span>
                        </NameSpan>
                        <DescSpan>
                            {this.state.showMoreDescription ? selected.desc : this.cropText(selected.desc)}
                            {selected.desc?.length > 60
                                ? (
                                    <ToggleDesc onClick={this.toggleShowMoreDescription} className="color-primary">
                                        View {this.state.showMoreDescription ? "Less" : "More"}
                                    </ToggleDesc>
                                )
                                : null}
                        </DescSpan>
                    </Info>
                </Header>
                <MainCont>
                    <CreateSpan
                        className="container-hover rounded inner"
                        onClick={() =>
                            !this.props.travelling && this.setState({ showModal: true })
                        }
                    >
                        Add Task
                    </CreateSpan>
                </MainCont>
            </Right>
        ) : (
            <Right className="topbar rounded">
                <Header marginBottom={this.state.showFilter}>
                    <MenuWrapper>
                        <SideMenuIcon onClick={this.props.toggleCollapseSidebar} />
                    </MenuWrapper>
                    <Color background={selected.groupColor}></Color>
                    <Info>
                        <NameSpan>
                            <span className="groupname" onClick={() => {
                                !this.props.travelling &&
                                    this.props.changeIndexState({ showCreateGroup: true, groupIdtoEdit: this.props.selectedGroup })
                            }}>
                                {selected.groupName}
                            </span>
                        </NameSpan>
                        <DescSpan title={!this.state.showMoreDescription ? selected.desc : ''}>
                            {this.state.showMoreDescription ? selected.desc : this.cropText(selected.desc)}
                        </DescSpan>
                        {selected.desc?.length > 60
                            ? (
                                <ToggleDesc onClick={this.toggleShowMoreDescription} className="color-primary">
                                    View {this.state.showMoreDescription ? "Less" : "More"}
                                </ToggleDesc>
                            )
                            : null}
                    </Info>
                    {
                        !this.props.travelling &&
                        <Fragment>
                            <FilterIc data-tip data-for='taskFilter' size={18} onClick={this.toggleFilter} className={`${this.state.showFilter || this.state.filteredAssignee?.length || this.state.filteredDate ? 'color-primary' : ''}`} />
                            <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Filter' dataFor='taskFilter' />
                        </Fragment>
                    }
                </Header>
                <FilterContainer className="button default rounded inner" show={!this.props.travelling && this.state.showFilter}>
                    <NameSpan>Filter</NameSpan>
                    <div className="filter-wrapper">
                        <div>
                            <Label>Assignee</Label>
                            <Dropdown
                                items={Assignees}
                                left
                                addTop={22}
                                width="250px"
                                scrollable
                                containerClass="assignee-dropdown"
                            >
                                <div className="hover-container assignee-picker border-lightinvereseimp">
                                    {this.state.filteredAssignee?.length > 0 ? this.state.filteredAssignee.map((user, i) => {
                                        return (
                                            <>
                                                <ImageWrapper
                                                    key={i}
                                                    data-tip
                                                    data-for={user?.name || user?.email || ''}
                                                    style={{ marginRight: '5px' }}
                                                    className="border-light"
                                                >
                                                    <CustomTip top={-20} left={-10} class='top-right' place='left' tip={user?.name || user?.email || ''} dataFor={user?.name || user?.email || ''} />
                                                    <TiDelete
                                                        className="remove-assignee"
                                                        onClick={(e) =>
                                                            this.removeAssignee(user, e)
                                                        }
                                                    />
                                                    {user.avatar_url ? (
                                                        <img
                                                            src={user.avatar_url}
                                                            alt={user.name || user.email}
                                                        />
                                                    ) : (
                                                        <span className="assignee-name">
                                                            {user.initials}
                                                        </span>
                                                    )}
                                                </ImageWrapper>
                                                <button className='assignee-button' onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setState({ filteredAssignee: [] })
                                                }}>x</button>

                                            </>)

                                    }) : <span className="placeholder">Select assignees</span>}
                                    {/* {this.state.filteredAssignee.length > 0 && <button className='assignee-button' onClick={() => this.setState({ filteredAssignee: [] })}>x</button>} */}

                                </div>
                            </Dropdown>
                        </div>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <Label>Due Date</Label>

                                <div style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: '5px' }}>
                                    <Toggle label="Date Range" onChange={() => this.setState({ dateRange: !this.state.dateRange })} checked={this.state.dateRange} />
                                </div>
                            </div>

                            <DateWrapper>

                                <TaskDatePicker
                                    name="filteredDate"
                                    wrapperClassName="datepicker"
                                    placeholderText={`${this.state.dateRange ? 'From' : "Select due date"}`}
                                    onChangeRaw={(e: any) => e.preventDefault()}
                                    isClearable
                                    onSelect={(date) =>
                                        // this.props.actions.UpdateSelf({
                                        //     dueDate: moment(date).format('YYYY-MM-DD'),
                                        // })
                                        this.setState({
                                            filteredDate: moment(date).format('YYYY-MM-DD')
                                        })
                                    }
                                    selected={
                                        this.state.filteredDate
                                            ? moment(this.state.filteredDate).toDate()
                                            : null
                                    }
                                    onChange={(date) => date ? this.setState({
                                        filteredDate: moment(date).format('YYYY-MM-DD')
                                    }) : this.setState({ filteredDate: '' })}
                                    readOnly
                                    containerClassName="border-lightinvereseimp hover-container task-datepicker selected-datepicker"
                                    dateFormat="MMMM dd, yyyy"
                                    // inverseHover
                                />
                                <div style={{ height: '10px' }}></div>
                                {this.state.dateRange &&

                                    <TaskDatePicker
                                        name="endDate"
                                        wrapperClassName="datepicker"
                                        placeholderText='To'
                                        onChangeRaw={(e: any) => e.preventDefault()}
                                        isClearable
                                        onSelect={(date) =>
                                            // this.props.actions.UpdateSelf({
                                            //     dueDate: moment(date).format('YYYY-MM-DD'),
                                            // })
                                            this.setState({
                                                endDate: moment(date).format('YYYY-MM-DD')
                                            })
                                        }
                                        selected={
                                            this.state.endDate
                                                ? moment(this.state.endDate).toDate()
                                                : null
                                        }
                                        onChange={(date) => date ? this.setState({
                                            endDate: moment(date).format('YYYY-MM-DD')
                                        }) : this.setState({ endDate: '' })}
                                        readOnly
                                        containerClassName="border-lightinvereseimp hover-container task-datepicker selected-datepicker"
                                        dateFormat="MMMM dd, yyyy"
                                        // inverseHover
                                    />
                                }

                            </DateWrapper>

                        </div>
                    </div>
                </FilterContainer>
                {<StyledProgressBar
                    completed={completed}
                />}

                {tasks && Object.values(tasks)?.map((m: any) => {
                    dates.indexOf(m.dueDate) === -1 ? dates.push(m.dueDate) : ''
                })}

                <TaskCont>
                    {dates.map((date: any, i: number) => {
                        return (
                            <EachDateCont key={i}>
                                <DateSpan>
                                    {date
                                        ? this.isToday(date)
                                            ? 'Today'
                                            : this.isTomorrow(date)
                                                ? 'Tomorrow'
                                                : this.isYesterday(date)
                                                    ? 'Yesterday'
                                                    : moment(date).format(
                                                        'MMMM DD, YYYY'
                                                    )
                                        : 'No due date'}
                                </DateSpan>
                                <TodoItemsCont>
                                    {tasks && Object.entries(tasks).map(([k, m], i: number) => {
                                        if (date === m.dueDate) {
                                            return (
                                                <TaskItem
                                                    check={() => this.handleCheck(k)}
                                                    delete={() => {
                                                        this.props.changeIndexState({
                                                            taskIdToDelete: k,
                                                            showDeleteModal: true
                                                        })
                                                    }}
                                                    edit={() => this.handleEdit(k)}
                                                    tasks={m}
                                                    key={i}
                                                    travelling={this.props.travelling}
                                                />
                                            )
                                        }
                                    })}
                                </TodoItemsCont>
                            </EachDateCont>
                        )
                    })}
                </TaskCont>
                {/* <TaskCont>
                        {selected.tasks?.map((m: any) => {
                            return <TaskItem check={this.handleCheck} tasks={m} />;
                        })}
                    </TaskCont> */}

                {!this.props.travelling && <ButtonWrapper>
                    <CreateButton
                        onClick={() =>
                             this.setState({ showModal: true })
                        }
                        className="button default"
                    >
                        Add Task
                    </CreateButton>
                </ButtonWrapper>}
            </Right>
        )

        return (
            <RightWrapper collapsed={this.props.collapsed}>
                {toRender}
                <Modal show={this.state.showModal}>
                    <CreateTaskForm
                        {...this.props}
                        addLink={this.props.addLink}
                        closeModal={() => this.setState({ showModal: false, selectedTask: '' })}
                        selectedTask={this.state.selectedTask}
                        selected={selected}
                        todos={this.props.todos}
                        selectedGroup={this.props.selectedGroup}
                    />
                </Modal>
            </RightWrapper>
        )
    }
}


export const MenuWrapper = styled.div`
    /* position: absolute; */
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 1;
`


const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const ImageWrapper = styled.div<{ color?: string; bordered?: boolean }>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid;
    ${(props) => (props.bordered ? 'border: 2px solid #2D81FF;' : '')}
    font-weight: ${(props) => props.theme.textRegular};

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        /* color: white; */
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    /* &.transparent-border {
        border-color: transparent !important;
    } */

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed rgba(0,0,0,0.4);
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`


const Label = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    filter: brightness(0.8);
`

const TaskDatePicker = styled(RDatepicker)`
    border-radius: 99px !important;
    width: 100%;
`


const FilterIc = styled(FilterIcon)`
    cursor: pointer;
    `

const FilterContainer = styled.div<{ show?: boolean }>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px !important;
    color: white;

    .assignee-button{
        position: absolute;
        right: 15px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: white;
        background-color: #216ba5;
        border: none;
        cursor: pointer;
    }

    .react-datepicker-wrapper input {
        color: #fff;
        font-weight: 400;
    }

    .filter-wrapper {
        display: flex;

        > * {
            flex: 1;
            margin: 0 2px;
        }
    }

    .selected-datepicker, .assignee-picker  {
        border-radius: 999px;
    }

    .assignee-picker {
        padding: 0 5px;
        border: 1px solid;
        font-weight: 400;
        font-size: 13px;
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;

        .placeholder {
            opacity: 0.3;
            margin-left: 10px;
        }
    }

    .assignee-dropdown {
        transform: translateX(0);
    }

    @media (max-width: 1560px) {
        .filter-wrapper {
            flex-direction: column;
        }

        .selected-datepicker {
            width: inherit;

            >div, .react-datepicker-wrapper.datepicker {
                width: 100%;
            }
        }
    }
`

const DateWrapper = styled.div`
    display: flex;

     @media (max-width: 1560px) {
        flex-direction: column;
    }

`

const ButtonWrapper = styled.div`
    padding: 0 30px;
`

const StyledProgressBar = styled(ProgressBar)`
    margin: 30px 0;
`

const RightWrapper = styled.div<{ collapsed?: boolean }>`
    display: flex;
    flex: 1 1 0;
    overflow: auto;
    padding: 15px;
    flex-direction: column;
    width: 0;

    @media (max-width: 1100px) {
        ${props => !props.collapsed ? 'display:none' : ''};
    }

    @media (min-width: 970px) {
        /* height: calc(100vh - 122px); */
    }

`

const Right = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 30px;
    flex-direction: column;
`

const Header = styled.div<{ marginBottom?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: ${props => props.marginBottom ? '15px' : '30px'};
`
const Color = styled.div<{ background: string }>`
    width: 2rem;
    height: 2rem;
    background-color: ${(props) => props.background};
    border-radius: 50%;
    transition: background-color 1s ease-in-out;
`
const Info = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
`
const NameSpan = styled.div`
    font-size: 18px;
    font-weight: 500;
    vertical-align: bottom;
    word-break: break-all;

    span.groupname {
        cursor: pointer;
    }
`
const DescSpan = styled.div`
    font-weight: 400;
    opacity: 0.8;
    margin-top: 5px;
    font-size: 14px;
    word-break: break-all;
`

const TaskCont = styled.div`
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    height: 100%;
    min-height: 200px;

    @media (min-width: 1101px) {
        ::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }

        ::-webkit-scrollbar-thumb {
            background: transparent;
        }

        /* Handle */
        &:hover::-webkit-scrollbar-thumb {
            background: darkgray;
        }
	}

`

const TodoItemsCont = styled.div`
    /* padding-left: 10px; */
    margin-bottom: 10px;
    margin-top: 10px;
`

const DateSpan = styled.span`
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
`

const EachDateCont = styled.div`
    margin-top: 30px;
`
const CreateButton = styled.button`
    margin-top: auto;
    margin-left: auto;
    cursor: pointer;
    border: none;
    width: 100%;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }
`

const CreateSpan = styled.div`
    font-size: 16px;
    font-weight: 400;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    width: 90%;
    padding: 20px;
    text-align: center;
    cursor: pointer;
`

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20vh;

    @media(min-width: 970px) {
        margin-top: 30vh;
    }
`

const ToggleDesc = styled.span`
    margin-top: 7px;
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
`

export default SelectedGroup
