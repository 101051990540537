import moment from 'moment'
import React, { Dispatch, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Marker, MarkerInnerWrapper, MarkerWrapper } from './index'

interface Props {
    changeSelectedDate: Dispatch<any>
    setSelectedData: Dispatch<any>
    selectedDate: string
    selectedDay: string
    currentMY: string
    transcriptions: any
    recordings: any
    syncs: any
}

function FullCalendar(props: Props) {
    const { selectedDate, selectedDay, currentMY, changeSelectedDate, transcriptions, recordings, syncs, setSelectedData } = props
    const [ currentMonth, setCurrentMonth ] = useState(selectedDate)

    useEffect(() => {
        setCurrentMonth(selectedDate)
    }, [selectedDate])

    const renderCalendarCells = () => {
        const monthStart = moment(currentMonth).startOf('month')
        const monthEnd = moment(monthStart).endOf('month')
        const startDate = moment(monthStart).startOf('week')
        const endDate = moment(monthEnd).endOf('week')

        const rows = []
        let days = []
        let day = startDate
        let formattedDate = ""

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = moment(day).format('D')
                const today = moment().isSame(moment(day), 'day')
                const yourDate = moment(day).format("MMMM D, YYYY")

                const curRecordings = (recordings?.[yourDate] || []).filter((_: any, idx: number) => !!_.isRecording)
                days.push(
                    <Col
                        className={`col cell border-light rounded inner hover-container ${
                            !moment(monthStart).isSame(moment(day), 'month')
                            ? "disabled"
                            : formattedDate === selectedDay && currentMY === moment(day).format("MMMM, YYYY") ? "selected border-primary" : ""
                        }`}
                        onClick={() => {
                            changeSelectedDate(yourDate)
                            setSelectedData({
                                transcriptions: transcriptions?.[yourDate] || [],
                                recordings: recordings?.[yourDate] || [],
                                syncs: syncs?.[yourDate] || {}
                            })
                        }}
                    >
                        <Day className="number">{formattedDate}</Day>
                        <LocaleDay>{moment(day).format("ddd")}</LocaleDay>
                        <DayHover className="bg">{formattedDate}</DayHover>
                        <Today>{ today && 'Today'}</Today>
                        <MarkerWrapper>
                            <MarkerInnerWrapper title={`Transcription (${transcriptions?.[yourDate]?.length || 0} records)`} >
                                {
                                    (transcriptions?.[yourDate] || []).filter((_: any, idx: number) => idx < 5).map(() => {
                                        return <Marker key={transcriptions._id} whichOne="Transcription" title={`Transcription (${transcriptions?.[yourDate]?.length} record${(transcriptions?.[yourDate]?.length || 0) > 1 ? 's' : ''})`} />
                                    })
                                }
                            </MarkerInnerWrapper>
                            <MarkerInnerWrapper title={`Recording (${curRecordings?.length || 0} records)`}>
                                {
                                    curRecordings.filter((_: any, idx: number) => idx < 5).map(() => {
                                        return <Marker key={transcriptions._id} whichOne="Recording" title={`Recording (${curRecordings?.length} record${(curRecordings?.length || 0) > 1 ? 's' : ''})`} />
                                    })
                                }
                            </MarkerInnerWrapper>
                            <MarkerInnerWrapper title={`Syncs (${Object.keys((syncs?.[yourDate] || {}))?.length || 0} records)`}>
                                {
                                    (Object.keys((syncs?.[yourDate] || {}))).filter((_: any, idx: number) => idx < 5).map(() => {
                                        return <Marker key={transcriptions._id} whichOne="Syncs" title={`Syncs (${Object.keys((syncs?.[yourDate] || {}))?.length} sync${(Object.keys((syncs?.[yourDate] || {}))?.length || 0) > 1 ? 's' : ''})`} />
                                    })
                                }
                            </MarkerInnerWrapper>
                        </MarkerWrapper>
                    </Col>
                )
                day = day.clone().add(1, 'd')
            }
            rows.push(
                <React.Fragment>
                    {days}
                </React.Fragment>
            )
            days = []
        }

        return <CellBody className="body">{rows}</CellBody>
    }

    return (
        <CalendarWrapper className="calendar">
            {renderCalendarCells()}
        </CalendarWrapper>
    )
}

const Day = styled.div`
    font-weight: 500;
    font-size: 25px;
`

const LocaleDay = styled.div`
    padding-top: 7px;
    font-size: 13px;
`

const Today = styled.div`
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    right: 15px;
    top: 17px;
`

const DayHover = styled.div`
    font-weight: 700;
    line-height: 1;
    opacity: 0;
    font-size: 8em;
    position: absolute;
    bottom: -0.2em;
    right: -0.05em;
    transition: 0.15s ease-out;
    letter-spacing: -0.07em;
`

const Col = styled.div`
    position: relative;
    border: 1px solid;
    overflow: hidden;
    cursor: pointer;
    transition: 0.15s ease-out;
    padding: 15px;
    display: flex;
    flex-direction: column;

    &.disabled {
        div:not(.bg) {
            opacity: 0.5;
        }
    }
`

const CellBody = styled.div`
    display: grid;
    grid-template-columns: repeat(7, minmax(150px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 15px;
`

const CalendarWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    font-weight: 400;

    .cell:hover .bg, .selected .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
    }

`

export default FullCalendar
