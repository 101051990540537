import React from 'react'
import BackgroundThumbnail from './BackgroundThumbnail'
import Drawer from '@material-ui/core/Drawer'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {backgroundConfig} from '../../VideoProvider/useBackgroundSettings'
import useVideoContext from '../../hooks/useVideoContext'
import VideoTrack from './VideoTrack'
import {LocalVideoTrack} from 'twilio-video'
import {FiSlash as NoneIcon} from '@react-icons/all-files/fi/FiSlash'
import BlurIcon from '../../../../../../assets/icons/blurbg.svg'
import {Scrollbars} from 'react-custom-scrollbars'
import RadioButton from '../../../../../../components/Radio'
import DefaultLayout from '../../../../../../assets/layouts/Default.svg'
import GridLayout from '../../../../../../assets/layouts/Grid.svg'
import SpeakerLayout from '../../../../../../assets/layouts/Speaker.svg'

import {MeetingLayouts} from '../../hooks/useView'

type Props = {
  closeModal: Function
  layout: string
  setLayout: Function
}

export default function ModalLayoutSelection(props: Props) {
  const {isBackgroundSelectionOpen, setIsBackgroundSelectionOpen, localTracks} =
    useVideoContext()

  const localVideoTrack = localTracks.find(
    (track) => track.kind === 'video',
  ) as LocalVideoTrack | undefined

  const imageNames = backgroundConfig.imageNames
  const images = backgroundConfig.images
  return (
    <div className="room-modal-backdrop" onClick={() => props.closeModal()}>
      <div
        className="room-modal topbar-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <div className="header">Change Layout</div>
          <div>
            {/* <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{  height: 'calc(80vh - 310px)' }}
            > */}
            <div>
              <div className="layout-options">
                <RadioButton
                  id="grid"
                  name="meeting-layout"
                  label="Default"
                  value={MeetingLayouts.Default}
                  checked={props.layout === MeetingLayouts.Default}
                  onChange={() => props.setLayout(MeetingLayouts.Default)}
                />
                <div
                  onClick={() => props.setLayout(MeetingLayouts.Default)}
                  style={{cursor: 'pointer'}}
                >
                  <img src={GridLayout} />
                </div>
              </div>
              
              <div className="layout-options">
                <RadioButton
                  id="default"
                  name="meeting-layout"
                  label="Sidebar"
                  value={MeetingLayouts.Sidebar}
                  checked={props.layout === MeetingLayouts.Sidebar}
                  onChange={() => props.setLayout(MeetingLayouts.Sidebar)}
                />
                <div
                  onClick={() => props.setLayout(MeetingLayouts.Sidebar)}
                  style={{cursor: 'pointer'}}
                >
                  <img src={DefaultLayout} />
                </div>
              </div>

              <div className="layout-options">
                <RadioButton
                  id="speakerview"
                  name="meeting-layout"
                  label="Speaker View"
                  value={MeetingLayouts.Floating}
                  checked={props.layout === MeetingLayouts.Floating}
                  onChange={() => props.setLayout(MeetingLayouts.Floating)}
                />
                <div
                  onClick={() => props.setLayout(MeetingLayouts.Floating)}
                  style={{cursor: 'pointer'}}
                >
                  <img src={SpeakerLayout} />
                </div>
              </div>
              {/* <div className="default-options">
                  <BackgroundThumbnail icon={<NoneIcon />} thumbnail={'none'} name={'None'} label={'None'}/>
                  <BackgroundThumbnail icon={<img src={BlurIcon} />} thumbnail={'blur'} name={'Blur'} label={'Blur'} />
                </div> */}

              {/* <div className="thumb-list">
                  {images.map((image, index) => (
                    <BackgroundThumbnail
                      thumbnail={'image'}
                      name={imageNames[index]}
                      index={index}
                      imagePath={image}
                      key={image}
                    />
                  ))}
                </div> */}
            </div>
            {/* </Scrollbars> */}
          </div>
        </div>
      </div>
    </div>
  )
}
