import { bind } from 'lodash';
import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { isJSON } from '../../helpers';
import LoopApi from '../../helpers/LoopApi';
import { WidgetActions } from '../../stores/MainStore';
import { AllWidgets, IWidgetProps } from '../types';

const { GlobalState } = require('reflux')

type Props = IWidgetProps<AllWidgets.WhiteBoard>;

function index(props: Props) {

    const iframeRef: any = useRef()
    const meetingName = GlobalState.main.meetingName

    const [bind, setBind] = useState(false)

    let colorTimeout: any = null


    useEffect(() => {

        //receive any incoming massages from WBoard
        bindEvent(window, 'message', listener)

        return () => {
            console.log('unmount');
            localStorage.setItem('wbFile', '')
            window.removeEventListener('message', listener)
        }


    }, [])

    async function listener(e: any) {
        clearTimeout(colorTimeout)

        colorTimeout = setTimeout(() => {
            if (!isJSON(e.data)) return
            const message: any = JSON.parse(e.data)
            console.log('bindEvent called', message);
            if (bind) return
            setBind(true)
            const filename: string = `${message.fileName}`


            if (message.type === 'PDF') {

                uploadFiles([dataURLtoFile(message.data, `${filename + '.pdf'}`)])

            } else {
                console.log('image');
                var files: any = []
                message.data.map((file: any, idx: number) => {

                    files.push(dataURLtoFile(file, `${filename + '_frame_' + (idx + 1) + '.png'}`))
                })

                uploadImages(files)

            }

        }, 1000);

    }


    function dataURLtoFile(dataurl: any, filename: string) {

        console.log('filename', filename);

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }



    const bindEvent = (element: any, eventName: any, eventHandler: any) => {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }


    const uploadFiles = async (_files: any[]) => {

        try {
            const files = Array.from(_files);
            console.log({ files });

            const upload = (file: any) => {
                const data = new FormData();
                data.append("file", file);
                data.append('targetWidget', 'files')
                data.append('size', `${file.size}`)
                return LoopApi(null, "UploadFile", {}, undefined, data);
            };

            if (files.length < 1) {
                return;
            }

            // Start Upload
            console.log('uploading', files);


            const response = []
            for (const file of files) {
                let r = await upload(file)
                response.push(r)
            }


            // console.log({ response });
        } catch (err) {
            console.error(err);
            setTimeout(() => {
                setBind(false)
            }, 1000);
        } finally {
            // WidgetActions.SetCurrentWidget({ currentWidget: 'files' })
            console.log('Upload dones');
            setTimeout(() => {
                setBind(false)
            }, 1000);
        }
    }


    const uploadImages = async (targetFiles: any) => {

        let uploadedLinks: any = null
        try {
            const files = Array.from(targetFiles).filter(({ type }) => type.includes('image/'))
            //console.log({ files })

            if (files.length === 0) {
                return
            }

            // Start Loading
            console.log('uploading', files);


            const upload = async (file: any) => {
                const data = new FormData()
                data.append('file', file)
                data.append('targetWidget', 'images')
                let response = await LoopApi(null, 'UploadFile', {}, undefined, data)
                return response.uri
            }

            const links: any[] = []

            for (const file of files) {
                const uri = await upload(file)
                links.push(uri)
            }

            console.log(links);

            uploadedLinks = links
            props.actions.AddLink(links)
        } catch (err) {
            console.log(err)
            setTimeout(() => {
                setBind(false)
            }, 1000);
        } finally {
            // End Loading
            setTimeout(() => {
                console.log('upload complete');
                setTimeout(() => {
                    setBind(false)
                }, 1000);
                // if (uploadedLinks) {
                //     const image = this.transformImageData().find((image: any) => image.url === uploadedLinks[0])
                //     if (image)
                //         this.setState({ isUploading: false, current_image: image })
                // } else {
                //     this.setState({ isUploading: false })
                // }
            }, 500)
        }
    }



    var sendMessage = function (msg: any) {
        const iFrame: any = document.getElementById('wb_iframe')
        // Make sure you are sending a string, and to stringify JSON
        iFrame?.contentWindow.postMessage(msg, 'https://wboard.grapl.it/');
        // iFrame?.contentWindow.postMessage(msg, 'http://localhost:3000/');
    };


    return (
        <>
            {/* <iframe style={{ borderRadius: '20px' }} ref={iframeRef} id='wb_iframe' src={`http://localhost:3000/whiteboard/${meetingName ? meetingName : ''}`} width='100%' height='100%' onLoad={() => { */}
             <iframe ref={iframeRef} style={{ borderRadius: '20px' }} id='wb_iframe' src={`https://wboard.grapl.it/whiteboard/${meetingName}`} width='100%' height='100%' onLoad={() => {
                setTimeout(() => {
                    sendMessage({ token: localStorage.getItem('token'), extraFile: localStorage.getItem('wbFile') })
                    localStorage.setItem('wbFile','')
                }, 1000)
            }}>

            </iframe>
        </>
    )
}



export default index
