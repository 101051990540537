import React, { Component } from 'react'
import styled from 'styled-components'
import { FaHashtag as IconHash } from '@react-icons/all-files/fa/FaHashtag'
import { FaLock as IconLock } from '@react-icons/all-files/fa/FaLock'
import { FaCaretDown as CaretDown } from '@react-icons/all-files/fa/FaCaretDown'

import { AButton } from '../Slack/SharedStyles'
import { ActionButton, WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName, WidgetRightContainer } from '../_Shared/Elements'
import {
    useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { SlackMenuInvite, SlackMenuJoin } from './SlackMenu'
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs'
interface Props {
    token: string
    channelList: any[]
    users: any
    handleUpdates: (obj: object) => void
    pokeNow: (obj: object, e: any) => void
    joinChannel: (id: string, e: any) => void
    isHost?: boolean
    toggleVisibility: () => void
    selected: string | null
}

interface State {
    collapsed: boolean
}

export default class ChannelList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            collapsed: false
        }
    }


    handleItemClick = ({ event, props, data, triggerEvent }: any) => {
        switch (event.currentTarget.id) {
            case "slack-invite":
                this.props.pokeNow(JSON.parse(props.data), event)
                //console.log(props.id); // contain to item.id passed by `show`
                break;
            case "slack-join":
                this.props.joinChannel(props.id, event)
                break;
            default:
                break;
        }
    }

    toggleCollapsible = () => {
        this.setState((prevState) => ({
            collapsed: !this.state.collapsed
        }))
    }
    

    render() {
        if (!!!this.props.channelList.length) {
            return null
        }


        const { show: showMember } = useContextMenu({
            id: 'slack-member'
        });
        const { show: showNotMember } = useContextMenu({
            id: 'slack-not-member'
        });

        const { show: showNothing } = useContextMenu({
            id: 'nothing'
        });

        const displayMenu = (e: any) => {
            const { c_data, c_id, is_member } = e?.currentTarget?.dataset
    
            if(is_member === 'true') {
                showMember(e, { props: { data: c_data, id: c_id } })
            } else {
                showNotMember(e, { props: { data: c_data, id: c_id }  })
            }
        }

        

        const channels = this.props.channelList.filter((c: any) => !c.is_archived).map(c => {
            const isSelected = !!this.props.selected && this.props.selected === c.id ? ' topbar slack-text' : '';
            return (
            <CWidgetContentItem
                small
                key={c.id}
                onClick={() => {
                    this.props.toggleVisibility();
                    this.props.handleUpdates({ selected: c, messages: [] })
                }}
                className={`cursor-pointer${isSelected}`}
                style={{padding: '12px 25px 12px 32px', marginLeft: '0px'}}
                data-is_member={c.is_member}
                data-c_data={JSON.stringify(c)}
                data-c_id={c.id}
                onContextMenu={displayMenu}
            >
                <div style={{marginRight: '10px'}}>{c.is_private ? <IconLock /> : <IconHash />}</div>
                <WidgetContentItemName>{c.name}</WidgetContentItemName>

                
                {/* <WidgetRightContainer>
                    {
                        c.is_member ?
                            <AButton className="button primary" onClick={e => this.props.pokeNow(c, e)}>
                                INVITE
                        </AButton> : !c.is_member ?
                                <AButton className="button quaternary" onClick={e => this.props.joinChannel(c.id, e)}>
                                    JOIN
                        </AButton> : ''
                    }
                </WidgetRightContainer> */}
            </CWidgetContentItem>
        )})

        return (
            <WidgetContainer>
                <SlackMenuInvite handleClick={this.handleItemClick} />
                <SlackMenuJoin handleClick={this.handleItemClick} />
                <WidgetContent padding="0 0 14px">
                    <Title onClick={this.toggleCollapsible} collapsed={this.state.collapsed}>
                        <CaretDown className="arrow-collapse" /> &nbsp;Channels
                    </Title>
                    <ChannelsWrapper collapsed={this.state.collapsed}>{channels}</ChannelsWrapper>
                </WidgetContent>
            </WidgetContainer>
        )
    }
}

const Title = styled.h4<{ collapsed: boolean }>`
	margin: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .arrow-collapse {
        transition: transform 0.3s ease-out;
        ${props => props.collapsed && `
            transform: rotate(-90deg);
        `}
    }
`

const Name = styled.div`
	flex: 1;
`

const CWidgetContentItem = styled(WidgetContentItem)`
    /* padding: 7px 0px 7px 10px;
    margin-left: 20px; */
    border-radius: 0px;
`

const ChannelsWrapper = styled.div<{ collapsed: boolean }>`
    max-height: auto;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    ${props => props.collapsed && `
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    `}

`