import React from 'react'
import styled from 'styled-components'

import logowhite from '../assets/logowhite.svg'
import undermaintenance from '../assets/undermaintenance.png'

function UnderMaintenance({ reason, title }: any) {
  return (
    <UnderMaintenanceWrapper>
        <div className='logo'><img src={logowhite} alt="Grapl" /></div>
        <div className='illustration'><img src={undermaintenance} alt="Under Maintenance" /></div>
        <div className='title'>{title || "Grapl will be back online shortly!"}</div>
        <div className='subtitle'>{reason || 'We sincery apologize for the inconvenience. Our site is currently under maintenance and will return shortly. Thank you for your understanding.'}</div>
    </UnderMaintenanceWrapper>
  )
}

const UnderMaintenanceWrapper = styled.div`
    background-image: linear-gradient(to bottom,#363B45, #AAAAB7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    padding: 10px;

    .logo {
        max-width: 250px;
        width: 100%;
        margin-bottom: 25px;
    }

    .illustration {
        max-height: 40%;
        text-align: center;
        width: 90%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 42px;
        text-align: center;
        margin: 30px 0 20px;
    }

    .subtitle {
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        width: 80%;
    }

    @media (min-width: 1025px) {
        .illustration {
            img {
                width: initial;
            }
        }
    }

    @media (max-height: 1024px) {
        .title {
            font-size: 32px;
            line-height: 34px;
        }

        .subtitle {
            font-size: 20px;
            line-height: 22px;
        }
    }

    @media (max-width: 480px) {
        .logo {
            max-width: 200px;
        }

        .title {
            font-size: 24px;
            line-height: 26px;
        }

        .subtitle {
            font-size: 16px;
            line-height: 18px;
        }
    }
`

export default UnderMaintenance