import React from "react";
import Reflux from "reflux";

import styled from "styled-components";

import LoopApi from "../../../../helpers/LoopApi";

import Participant from "./Participant";
import LocalParticipant from "./LocalParticipant";
import { ParticipantsTileWrapper } from "../../NewCall/CallSlide/styles";
import { Name } from "../../NewCall/CallSlide/CallTiles/styles";
import MoreCallItemControlsTwilio from "./MoreCallItemControlsTwilio";
import { MainStore } from "../../../../stores/MainStore";
import { VariableCallingStore } from "../../../../stores/VariableCallingStore";
import { AuthStore } from "../../../../stores/AuthStore";

interface IProps {
  user?: {
    avatar_url?: string;
    username?: string;
    name: string;
    id: string;
  };
  isHost: boolean;
  participant: any;
  isLocalParticipant?: boolean;
  room: any;
  canSharescreen?: boolean;
  isSharingScreen?: boolean;
  localVideoTrack?: any;
  localAudioTrack?: any;
  transcriptionTrack?: any;
}

interface State {
  meetingName: string;
  host: string;
  jwt: any;
}

export default class CallUserUITwilio extends Reflux.Component<
  typeof VariableCallingStore | typeof MainStore | typeof AuthStore,
  IProps,
  State
> {
  constructor(props: IProps) {
    super(props);

    this.stores = [MainStore, VariableCallingStore, AuthStore];
    this.storeKeys = ["meetingName", "host", "jwt"];
  }

  componentDidMount() {
    //console.log("CALL ONCE");
  }

  renderCrown = () => {
    return (
      this.props.isHost && (
        <HostWrapper>
          <CrownIcon />
        </HostWrapper>
      )
    );
  };

  renderName = () => {
    const isHost = this.state.host === this.props.user?.id;
    if (!this.props.user) {
      return null;
    }

    return (
      <Name
        className={`${this.props.isLocalParticipant ? "curname" : "othername"}`}
        isCurrentUser={this.props.isLocalParticipant}
        style={{ zIndex: 1 }}
      >
        {isHost && (
          <div className="host-indicator color-primary">
            <CrownIcon />
          </div>
        )}
        <div className="tile-username">
          <div className="ellipsis ellipsis-tile-username">
            {this.props.isLocalParticipant ? "You" : this.props.user.name || this.props.user.username || ""}
          </div>
        </div>
      </Name>
    );
  };

  transferHostAccess = async () => {
    try {
      await LoopApi(null, "TransferHost", { participant: this.props.user }, [["name", this.state.meetingName]]);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const isHost = this.state.host === this.props.user?.id;
    const youAreHost = this.state.host === this.state.jwt?.data?._id;
    return (
      <div className="participant">
        <ParticipantsTileWrapper className="presentationParticipant container rounded inner">
          {/* { this.renderCrown() } */}

          {this.props.isLocalParticipant ? (
            <LocalParticipant
              isLocalParticipant={this.props.isLocalParticipant}
              participant={this.props.participant}
              user={this.props.user}
              room={this.props.room}
              canSharescreen={this.props.canSharescreen}
              isSharingScreen={this.props.isSharingScreen}
              localVideoTrack={this.props.localVideoTrack}
              localAudioTrack={this.props.localAudioTrack}
              transcriptionTrack={this.props.transcriptionTrack}
            />
          ) : (
            <Participant
              isLocalParticipant={this.props.isLocalParticipant}
              participant={this.props.participant}
              user={this.props.user}
              room={this.props.room}
              canSharescreen={this.props.canSharescreen}
              isSharingScreen={this.props.isSharingScreen}
              localVideoTrack={this.props.localVideoTrack}
              localAudioTrack={this.props.localAudioTrack}
            />
          )}

          {this.renderName()}
          <MoreCallItemControlsTwilio
            transferHostAccess={this.transferHostAccess}
            showTransferAccess={!isHost && youAreHost}
          />
        </ParticipantsTileWrapper>
      </div>
    );
  }
}

export const IconButtonOption = styled.div<{
  muted?: boolean;
  active?: boolean;
}>`
  position: absolute;
  padding: 16px !important;
  font-size: 15px;
  color: white;
  cursor: pointer;
  z-index: 2;
`;

const HostWrapper = styled.div`
  position: fixed;
  z-index: 2;
  transform: rotate(-45deg) translate(0px, -20px);

  svg {
    width: 24px;
  }
`;

const CrownIcon = ({ size = 13 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-fill-primary"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M24.625 21.5H8.375C8.03125 21.5 7.75 21.7812 7.75 22.125V23.375C7.75 23.7188 8.03125 24 8.375 24H24.625C24.9688 24 25.25 23.7188 25.25 23.375V22.125C25.25 21.7812 24.9688 21.5 24.625 21.5ZM27.125 9C26.0898 9 25.25 9.83984 25.25 10.875C25.25 11.1523 25.3125 11.4102 25.4219 11.6484L22.5938 13.3437C21.9922 13.7031 21.2148 13.5 20.8672 12.8906L17.6836 7.32031C18.1016 6.97656 18.375 6.46094 18.375 5.875C18.375 4.83984 17.5352 4 16.5 4C15.4648 4 14.625 4.83984 14.625 5.875C14.625 6.46094 14.8984 6.97656 15.3164 7.32031L12.1328 12.8906C11.7852 13.5 11.0039 13.7031 10.4062 13.3437L7.58203 11.6484C7.6875 11.4141 7.75391 11.1523 7.75391 10.875C7.75391 9.83984 6.91406 9 5.87891 9C4.84375 9 4 9.83984 4 10.875C4 11.9102 4.83984 12.75 5.875 12.75C5.97656 12.75 6.07813 12.7344 6.17578 12.7187L9 20.25H24L26.8242 12.7187C26.9219 12.7344 27.0234 12.75 27.125 12.75C28.1602 12.75 29 11.9102 29 10.875C29 9.83984 28.1602 9 27.125 9Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="33"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export const IconButtonMute = styled.div<{ muted?: boolean }>`
  color: white;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  font-size: 14px;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  opacity: 0;
  position: relative;
  ${(props) => (props.muted ? "opacity: 1!important;" : "")}
  cursor: pointer;
`;
