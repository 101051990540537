import React, { Component } from 'react'
import Reflux from 'reflux'

import ParticipantTile from './CallTiles/ParticipantTile'
import { AuthStore } from '../../../../stores/AuthStore'
import { MainStore } from '../../../../stores/MainStore'
import { IVariableCallingStoreState, VariableCallingStore } from '../../../../stores/VariableCallingStore'

import { CallContainer } from './styles'
import { IParticipant } from '../../../../calling/types'
import PresentationTile from './CallTiles/PresentationTile'
import CallTimer from './CallTiles/CallTimer'

type IState = Pick<
	IVariableCallingStoreState,
	'session' | 'current_speaker' | 'participants' | 'current_presenter' | 'call_starter'  | 'host' | 'startTime'
> & {
	users: any[]
	callObject: any
	jwt: any
    host: any
	meetingName: string
}

class CallSlide extends Reflux.Component {
    constructor(props: never) {
        super(props)

		this.stores = [VariableCallingStore, MainStore, AuthStore]
		this.storeKeys = ['participants', 'users', 'callObject', 'current_presenter', 'call_starter', 'jwt', 'meetingName', 'host', 'startTime']
    }
	state: IState

    componentDidMount() {
        this.updateLayout()

        if (window) {
            window?.addEventListener('resize', this.updateLayout)
            // //console.log('EVENT LISTENER CHAT')
            window?.addEventListener('AUDIO_DEVICE_CHANGE', this.handleAudioDeviceChanged)
            window?.addEventListener('VIDEO_DEVICE_CHANGE', this.handleVideoDeviceChanged)
          }
    }

    componentDidUpdate(prevProps: never, prevState: IState) {
        const { participants, callObject } = this.state
        const { participants: prevParticipants, callObject: prevCallObject } = prevState

        if(JSON.stringify(participants) !== JSON.stringify(prevParticipants)) {
            this.updateLayout()
        }
    }

    componentWillUnmount() {
        window?.removeEventListener('resize', this.updateLayout)
        if (this.state.current_speaker) {
            window?.removeEventListener('AUDIO_DEVICE_CHANGE', this.handleAudioDeviceChanged)
            window?.removeEventListener('VIDEO_DEVICE_CHANGE', this.handleVideoDeviceChanged)
        }
    }

    handleAudioDeviceChanged = (e: any) => {
        const { detail } = e
        //console.log('handleAudioDeviceChanged:', detail.audio_input_device_id)
        this.updateAudioDevice(detail.audio_input_device_id)
    }
    
    handleVideoDeviceChanged = (e: any) => {
        const { detail } = e
        //console.log('handleVideoDeviceChanged:', detail.video_input_device_id)
        this.updateVideoDevice(detail.video_input_device_id)
    }

    updateVideoDevice = async (videoDeviceId: string) => {
        try {
            localStorage.setItem("video_input_device_id", videoDeviceId)
            if(this.state.callObject) {
                this.state.callObject?.setInputDevicesAsync({
                    videoDeviceId
                })
            }
        } catch (err) {
            //console.log('updateNewDevice err:', err)
        }
    }

    updateAudioDevice = async (audioDeviceId: string) => {
        try {
            localStorage.setItem("audio_input_device_id", audioDeviceId)
            if(this.state.callObject) {
                this.state.callObject?.setInputDevicesAsync({
                    audioDeviceId
                })
            }
        } catch (err) {
            //console.log('updateNewDevice err:', err)
        }
    }
    

    checkDiv = (increment: number, count: number, width: number, height: number, margin: number = 10) => {
        let i: number = 0, w: number = 0
        let h = increment * 0.75 + (margin * 2)

        while (i < (count)) {
            if ((w + increment) > width) {
                w = 0
                h = h + (increment * 0.75) + (margin * 2)
            }
            w = w + increment + (margin * 2)
            i++
        }
        if (h > height) return false
        else return increment
    }

    updateLayout = () => {
        let margin: number = 5, width: number = 0, height: number = 0;
        let callContainer: HTMLElement | null = document.getElementById('callContainer');

        if(callContainer !== null) {
            width = callContainer.offsetWidth - (margin * 5);
            height = callContainer.offsetHeight - (margin * 5);
        }

        let callParticipants: HTMLCollectionOf<Element> = document.getElementsByClassName('callParticipants');
        
        let max: number = 0;
        let i: number = 1;
        while (i < 5000) {
            let w = this.checkDiv(i, callParticipants?.length, width, height, margin);
            if (w === false) {
                max =  i - 1
                break
            }
            i++
        }

        max = max - (margin);
        this.setWidth(max, margin);
    }

    setWidth = (width: number, margin: number) =>  {
        let callParticipants = document.querySelectorAll<HTMLElement>('.callParticipants');

        for (var s = 0; s < callParticipants.length; s++) {
            callParticipants[s].style.width = width + "px";
            callParticipants[s].style.margin = margin + "px";
            callParticipants[s].style.height = (width * 0.75) + "px";
        }

        const tileUsernames = document.getElementsByClassName("ellipsis-tile-username");
        for (var s=0; s < tileUsernames.length; s++) {
            const el: any = tileUsernames[s]
            el.style.maxWidth = (width - 70) + "px";
        }
    }

    getStreamStates(participant: any = {}) {
		const { callObject } = this.state;

		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
			callObject &&
			callObject.participants &&
			callObject.participants()
		) {
			const participants = Object.keys(callObject.participants()).map(i => callObject.participants()[i])
			const localParticipant = participants.find((p) => participant?.id === p?.user_name)
			isCameraMuted = !localParticipant?.video;
			isMicMuted = !localParticipant?.audio;
			isSharingScreen = localParticipant?.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}

    onVideoToggle = (participant: any = {}) => {
		const { callObject } = this.state
        const {isCameraMuted, isMicMuted, isSharingScreen} = this.getStreamStates(participant)

        return callObject?.setLocalVideo ? callObject?.setLocalVideo(isCameraMuted) : () => {}
    }

    onAudioToggle = (participant: any = {}) => {
        const { callObject } = this.state
        const {isCameraMuted, isMicMuted, isSharingScreen} = this.getStreamStates(participant)

        return callObject && callObject.setLocalAudio ? callObject.setLocalAudio(isMicMuted) : () => {}
    }

    renderLocalParticipant = (id: string | IParticipant, isCurrentUser: boolean) => {
		const part =
			typeof id !== 'string'
				? id
				: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const participant = this.state.users.find((u: any) => u.id === part.userId)

        const {isCameraMuted, isMicMuted, isSharingScreen} = this.getStreamStates(participant)

		const isHost = this.state.host === part.userId
		const youAreHost = this.state.host === this.state.jwt?.data?._id

		return <ParticipantTile
            currentUser={isCurrentUser}
            participant={participant}
            preview={!this.props.showOtherUsers} 
            videoTrack={part.tracks.find((t) => t.kind === 'video')}
            audioTrack={part.tracks.find((t) => t.kind === 'audio')}
            isAudioEnabled={!!!isMicMuted}
            isCameraEnabled={!!!isCameraMuted}
            isHost={isHost}
            youAreHost={youAreHost}
            meetingName={this.state.meetingName}
		/>
	}

    renderScreenPresentation = (id: string | IParticipant) => {
		const part =
		typeof id !== 'string'
			? id
			: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')

		const participant = this.state.users.find((u: any) => u.id === part.userId)

        const {isCameraMuted, isMicMuted, isSharingScreen} = this.getStreamStates(participant)

		if(!screenTrack && !isSharingScreen) return
		return (
            <PresentationTile
                screenTrack={screenTrack}
            />
		)
	}

    renderSideScreenPresentation = (id: string | IParticipant) => {
        const part =
			typeof id !== 'string'
				? id
				: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')
        
		if(!screenTrack) return
		return <ParticipantTile
            screenshareTile
            preview={!this.props.showOtherUsers} 
            videoTrack={screenTrack}
            isAudioEnabled={false}
            isCameraEnabled={true}
            onClick={() => this.props._setActiveWidget(null)}
		/>
    }

    render() {
        const { participants } = this.state
        let current_speaker = this.state.current_speaker
		let current_presenter = this.state.current_presenter
 		if (!current_speaker && participants.length) {
			current_speaker = participants[0].userId
		}

        return (
            <React.Fragment>
                <CallTimer startTime={this.state.startTime} />
                {
                    !!current_presenter && this.renderScreenPresentation(current_presenter)
                }
                <CallContainer id="callContainer">
                    { !!current_speaker && this.renderLocalParticipant(current_speaker, true) }
                    { !!current_presenter && this.renderSideScreenPresentation(current_presenter) }
                    { 
                        !!this.props.showOtherUsers && 
                        (participants || []).filter((p) => p.userId !== current_speaker)
                        .map((p) => {
                            return this.renderLocalParticipant(p, false)
                        }) 
                    }
                </CallContainer>
            </React.Fragment>
        )
    }
}

export default CallSlide
