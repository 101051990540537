import React, { Component, createRef, Fragment } from 'react'
import styled from "styled-components"
import { DropboxFile, FolderStackItem } from '../types'
import FileItems from "./FileItems"
import FolderItems from "./FolderItems"
import { HiViewList as ListIcon } from "@react-icons/all-files/hi/HiViewList"
import { HiViewGrid as GridIcon } from "@react-icons/all-files/hi/HiViewGrid"
import ReactTooltip from "react-tooltip"
import { FaDropbox } from 'react-icons/fa'
import { FiUsers as UsersIcon } from 'react-icons/fi'
import { IoMdTime as Time } from 'react-icons/io'
import { AiOutlineStar as Star } from 'react-icons/ai'
import { HiUpload as Upload} from 'react-icons/hi'
import { FaAngleLeft as CollapseIcon } from 'react-icons/fa'

interface Props {
	addFolderStackItem: (folderStackItem: FolderStackItem) => void
	files: any[]
	getFiles: (f: object) => void
	folderStack: FolderStackItem[]
	downloadFile: Function
	viewFile: Function
	presentFile: Function
	setFileToDownload: (file: DropboxFile) => void
	colorScheme: string
	menu: string
	changeIndexState: Function
	isLeftVisible: boolean
	upload: Function
	onPresent: Function
	isUploading: boolean
	isPresenterOnline: boolean
}

interface ReducedFiles {
	folders: DropboxFile[]
	files: DropboxFile[]
}

const sideMenus = {
	mydrive: {
		name: 'All Files',
		value: 'allfiles',
		icon: <FaDropbox />
	},
	// sharedwithme: {
	// 	name: 'Shared with me',
	// 	value: 'sharedwithme',
	// 	icon: <UsersIcon />
	// },
	// recent: {
	// 	name: 'Recent',
	// 	value: 'recent',
	// 	icon: <Time />
	// },
	// starred: {
	// 	name: 'Starred',
	// 	value: 'starred',
	// 	icon: <Star />
	// }
}

export default class Files extends Component<Props> {
	static defaultProps = {
		files: [],
	}

	state = {
		viewType: "list"
	}

	uploadRef = createRef<HTMLInputElement>()

	openFolder = (folderId: string, folderName: string) => {
		this.props.addFolderStackItem({path: folderId, name: folderName})
		this.props.getFiles({folder_path: folderId})
	}

	sortFiles = (files: DropboxFile[]) =>
		files.length > 1 ?
		files.sort((a: DropboxFile, b: DropboxFile) => a.name.localeCompare(b.name)) :
		files

	clickUploadRef = () => {
		this.uploadRef.current?.click()
	}

	handleUpload = (e: any) => {
		this.props.changeIndexState({isUploading: true})
		this.props.upload(e.target.files)
	}

	render() {
		const files: ReducedFiles = this.props.files.reduce((files, file) => {
			if (file['.tag'] === "folder") {
				return {
					...files,
					folders: [...files.folders, file]
				}
			}

			if (file['.tag'] === "file") {
				return {
					...files,
					files: [...files.files, file]
				}
			}

			return files
		}, {folders: [], files: []})

		return (
			<MainContainer>
				<SideBar isLeftVisible={this.props.isLeftVisible}>
					<input
							ref={this.uploadRef}
							type="file" hidden multiple id="files-to-upload"
							onChange={this.handleUpload}
							onClick={(event: any)=> {
								event.target.value = null
						   }}
					/>

					<UploadButton
						onClick={this.clickUploadRef}
						isUploading={this.props.isUploading}
					>
						{this.props.isUploading ?
							<div className="overflow">
								<div className="circle-line">
									<div className="circle-red">&nbsp;</div>
									<div className="circle-blue">&nbsp;</div>
									<div className="circle-green">&nbsp;</div>
									<div className="circle-yellow">&nbsp;</div>
								</div>
							</div> :
							<Fragment>
								<Upload size={16}/>
								<div>Upload</div>
							</Fragment>
						}

					</UploadButton>

					{Object.entries(sideMenus).map(([key, menu]) => {
						return (
							<SideMenuWrapper
								isSelectedMenu={this.props.menu === menu.value}
								isLight={this.props.colorScheme === 'Light'}
							>
								<SideMenus
									key={key}
									onClick={() => {
										if(menu.value === 'allfiles') {
											this.props.changeIndexState({
												menu: menu.value,
												isLeftVisible: false,
												folderStack: [{ path: "", name: "Dropbox" }]
											}, () => this.props.getFiles({}))
										}
									}}>
									{menu.icon}
									<span>{menu.name}</span>
								</SideMenus>
							</SideMenuWrapper>
						)
					})}
				</SideBar>
				<Container isLeftVisible={this.props.isLeftVisible}>
					<BackButton
						isLeftVisible={this.props.isLeftVisible}
						className="button primary"
						onClick={() => this.props.changeIndexState({ isLeftVisible: true })}
					>
						<CollapseIcon size={16} title="Collapse" />
					</BackButton>
					<Wrapper>
						<Heading>Folders</Heading>

						<div>
							{this.state.viewType === "grid" ? (
								<React.Fragment>
									<ListIcon
										data-tip
										data-for="list-icon"
										size={20}
										onClick={() => this.setState({ viewType: "list" })}
									/>

									<ReactTooltip id="list-icon">
										<span>List View</span>
									</ReactTooltip>
								</React.Fragment>
							) : (
								<React.Fragment>
									<GridIcon
										data-tip
										data-for="grid-icon"
										size={20}
										onClick={() => this.setState({ viewType: "grid" })}
									/>

									<ReactTooltip id="grid-icon">
										<span>Grid View</span>
									</ReactTooltip>
								</React.Fragment>
							)}
						</div>
					</Wrapper>

					{
						this.state.viewType !== 'grid' &&
						<ColumnHeader>
							<div></div>
							<div>Name</div>
							<div></div>
							<div>Last Modified</div>
						</ColumnHeader>
					}

					<FolderItems
						view={this.state.viewType}
						folders={this.sortFiles(files.folders)}
						openFolder={this.openFolder}
						colorScheme={this.props.colorScheme}
					/>

					<Wrapper>
						<Heading>Files</Heading>
					</Wrapper>

					<FileItems
						view={this.state.viewType}
						files={this.sortFiles(files.files)}
						downloadFile={this.props.downloadFile}
						viewFile={this.props.viewFile}
						presentFile={this.props.presentFile}
						setFileToDownload={this.props.setFileToDownload}
						colorScheme={this.props.colorScheme}
						onPresent={this.props.onPresent}
						isPresenterOnline={this.props.isPresenterOnline}
					/>
				</Container>
			</MainContainer>
		)
	}
}

const Container = styled.div<{isLeftVisible: boolean}>`
	overflow-x: hidden;
	padding: 16px 26px 26px;
    flex-direction: column;
    flex: 1 1 0%;

	${({isLeftVisible}) => isLeftVisible ? 'display: none;' : 'display: flex;'}

	@media (min-width: 1101px){
		display: flex;
	}
`

const Heading = styled.h1`
	font-weight: 100;
	margin-bottom: 16px;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 16px 0;

	& > h1 {
		margin-bottom: 0;
	}

	& > div > svg {
		padding: 4px;
		margin-left: 4px;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background-color: none;
			@media (min-width: 769px) {
				background-color: rgba(0,0,0,0.3);
			};
		}
	}
`

const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const SideBar = styled.div<{isLeftVisible: boolean}>`
	flex-direction: column;
	padding: 0px 0px 5px;

	${({isLeftVisible}) => isLeftVisible ?
	`min-width: 100%; display: flex;` :
	`min-width: 0%; display: none;`}

	@media (min-width: 1101px){
		display: flex;
		min-width: 335px;
		position: sticky;
		top: 73px;
		height: 400px;
	}
`

const SideMenuWrapper = styled.div<{isSelectedMenu: boolean, isLight: boolean}>`
	${({isSelectedMenu, isLight}) => isSelectedMenu ?
		`background: ${isLight ? '#fff' : '#272732'};`
		:
		`&:hover {
			background: ${isLight ? '#efefef' : '#1b1b25'};
		}
	`}

	@media (min-width: 1101px){
		border-top-right-radius: 21em 30em;
		border-bottom-right-radius: 20em 30em;
	}
`

const SideMenus = styled.div`
	padding: 5px;
	display: flex;
    align-items: center;
    margin-left: 50px;
	padding: 20px;
	cursor: pointer;

	svg {
		height: 20px;
		width: 20px;
		margin-right: 20px;
	}

`

const UploadButton = styled.div<{isUploading: boolean}>`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	padding: 14px 30px;
	color: #fff;
	background-color: #0061ff;
	border-radius: 2px;
	max-width: 150px;
    align-items: center;
    justify-content: space-between;
	border-radius: 7px;
	margin: 50px 0 50px 50px;
	font-size: 16px;
	position: relative;

	svg {
		margin-right: 10px;
	}


	${({isUploading}) => isUploading &&
		`cursor: default;
		pointer-events: none;
		height: 44px;`
	}


	.overflow, .circle-line {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.circle-line {
		width: 110px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.circle-red,.circle-green,.circle-yellow,.circle-blue{
		position: relative;
		width: 8px;
		height: 8px;
		border-radius: 8px;
		margin: 5px;
	}

	.circle-red {
		background-color: #FFF;
		animation: movingUp 0.5s infinite alternate;
	}

	.circle-blue {
		background-color: #FFF;
		animation: movingUp 0.5s 0.1s infinite alternate;
	}

	.circle-green {
		background-color: #FFF;
		animation: movingUp 0.5s 0.2s infinite alternate;
	}

	.circle-yellow {
		background-color: #FFF;
		animation: movingUp 0.5s 0.3s infinite alternate;
	}

	@keyframes movingUp {
		from {
			top: -3px;
		}
		to {
			top: 3px;
		}
	}
`

const ColumnHeader = styled.div`
	display: grid;
	grid-template-columns: 50px 3fr 1fr 1fr;
	padding: 8px 16px;
	min-height: 48px;

	> div {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`

const BackButton = styled.div<{isLeftVisible: boolean}>`
    height: 27px;
    width: 27px;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 5px !important;
    display: ${({isLeftVisible}) => isLeftVisible ? 'none' : 'block'};

    @media (min-width: 1101px){
        display: none;
    }
`