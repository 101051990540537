import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, ButtonLink } from '../../components/Elements'
// import { ImFilePdf as PDFIcon } from '@react-icons/all-files/im/ImFilePdf'
// import { FiDownloadCloud as DownloadIcon } from '@react-icons/all-files/fi/FiDownloadCloud'
// import { FiEye as EyeIcon } from '@react-icons/all-files/fi/FiEye'
// import { FaSpinner as SpinnerIcon } from '@react-icons/all-files/fa/FaSpinner'
// import { MdPresentToAll as PresentationIcon } from '@react-icons/all-files/md/MdPresentToAll'
import ReactPlayer from 'react-player'
// import getIcon from '../_Shared/FileIcon'
import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
import { FcPrevious, FcNext } from 'react-icons/fc'

import { WidgetActions } from '../..//stores/MainStore'
import LoopApi from '../..//helpers/LoopApi'

import { FiZoomOut } from '@react-icons/all-files/fi/FiZoomOut'
import { FiZoomIn } from '@react-icons/all-files/fi/FiZoomIn'
import { IFilesWidgetState } from './types'

interface Props {
  uri: string | undefined
  filename: string | undefined
  UpdateSelf: (obj: any) => void
  updateState: (
    fn: (prevState: IFilesWidgetState) => IFilesWidgetState,
    cb?: Function,
  ) => void
  parent: any
  changeCurrentFile: any
}

interface State {
  viewing: boolean
  scale: number
  browsableFiles: any
  browsableIndex: any
}

export default class FileView extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.presentNow = this.presentNow.bind(this)
    this.state = {
      viewing: true,
      scale: 1.0,
      browsableFiles: [],
      browsableIndex: 0,
    }
  }

  async presentNow() {
    try {
      let url = this.props.uri || ''
      if (
        !url.startsWith(
          process.env.REACT_APP_API_URL || 'http://localhost:8000',
        )
      ) {
        const resp = await LoopApi(null, 'GetUrlHash', undefined, [
          ['url', this.props.uri],
        ])
        if (resp.error) {
          return console.error(resp)
        }
        url = resp.url
      }
      WidgetActions.CreateOrUpdateWidget(
        {
          name: 'pdfpresenter',
          url,
          scrollPerc: 0,
          page: 1,
        },
        true,
      )
    } catch (e) {
      console.error(e)
    }
  }

  componentDidMount() {
    this.setBrowsableFiles()
    if (!can_display(this.props.filename as string)) {
      window.open(this.props.uri, '_blank')
      this.props.updateState((prevState) => ({
        ...prevState,
        currentFolderId: null,
        currentFileId: null,
      }))
      // this.props.UpdateSelf({ currentFolderId: null, currentFileId: null });
      return
    }
  }

  componentDidUpdate() {

  }

  setBrowsableFiles = () => {
    const currentFile = this.props.parent.state.currentFileId
    const browsableFiles = () => {
      if (!this.props.parent.state.currentFolderId) {
        return (Object.values(this.props.parent.props.data.files).filter(
          (o: any) =>
            o.filetype.includes('video') || o.filetype.includes('image'),
        ) as any).sort((a: any, b: any) => a.filename.localeCompare(b.filename))
      }

      if (this.props.parent.state.currentFolderId) {
        const data = Object.keys(this.props.parent.props.data.childFiles)
          .map((o) => ({
            folder: this.props.parent.props.data.childFiles[o],
            file: o,
          }))
          .filter((f) => f.folder === this.props.parent.state.currentFolderId)
          .map((f) => this.props.parent.props.data.files[f.file])
          .filter(
            (o) => o?.filetype?.includes('video') || o?.filetype?.includes('image'),
          )
        return data
      }
      return []
    }

    const browsableIndex = (browsableFiles().findIndex(
      (f: any) => f._id === currentFile,
    ) as any)

    console.log('=======>',browsableFiles(), this.props)

    this.setState({
      browsableFiles: browsableFiles(),
      browsableIndex,
    })

    this.props.changeCurrentFile(browsableFiles()[browsableIndex])
  }

  isImage = () => {
    const images = ['svg', 'jpg', 'jpeg', 'gif', 'png', 'jfif', 'webp']
    const extension = (
      this.props.parent.props.data.file ||
      this.state.browsableFiles[this.state.browsableIndex]
    )?.filetype

    const toRet = !!extension && extension.toLowerCase().includes('image')

    return toRet
  }

  isVideo = () => {
    const images = ['mp4', 'mov', 'wmv', 'flv', 'avi', 'webm', 'mkv']
    const extension = this.state.browsableFiles[
      this.state.browsableIndex
    ]?.filename
      .split('.')
      .pop()
    const toRet = !!extension && images.includes(extension.toLowerCase())
    return toRet
  }

  zoomIn(e: any) {
    e.preventDefault()
    if (this.state.scale > 5.0) return

    this.setState({ scale: this.state.scale + 0.2 })
  }

  zoomOut(e: any) {
    e.preventDefault()
    if (this.state.scale < 0.4) return

    this.setState({ scale: this.state.scale - 0.2 })
  }

  render() {
    const { scale } = this.state
    const isImage = this.isImage()
    const isVideo = this.isVideo()

    const PrevButton = () => {
      if (this.state.browsableIndex === 0) return <div></div>
      if (
        (this.props.parent.state.presentation?.files || '') === 'presenting' ||
        (this.props.parent.state.presentation?.files || '') === 'viewing'
      ) {
        return <div></div>
      }

      return (
        <div
          className="preview"
          onClick={() => {
            if (this.state.browsableIndex === 0) return
            this.setState({
              browsableIndex: this.state.browsableIndex - 1,
            })
            this.props.changeCurrentFile(this.state.browsableFiles[this.state.browsableIndex - 1])
          }}
        >
          <FcPrevious />
        </div>
      )
    }

    const NextButton = () => {
      if (this.state.browsableIndex === this.state.browsableFiles.length - 1) {
        return <div></div>
      }

      if (
        (this.props.parent.state.presentation?.files || '') === 'presenting' ||
        (this.props.parent.state.presentation?.files || '') === 'viewing'
      ) {
        return <div></div>
      }

      return (
        <div
          className="next"
          onClick={() => {
            if (
              this.state.browsableIndex ===
              this.state.browsableFiles.length - 1
            )
              return
            this.setState({
              browsableIndex: this.state.browsableIndex + 1,
            })
            this.props.changeCurrentFile(this.state.browsableFiles[this.state.browsableIndex + 1])
          }}
        >
          <FcNext />
        </div>
      )
    }

    return (
      <React.Fragment>
        {isImage ? (
          <React.Fragment>
            <Center>
              <button onClick={(e) => this.zoomIn(e)}>
                <FiZoomIn size={18} title="Zoom in" />
              </button>
              <div className="zoom">{(this.state.scale * 100).toFixed(0)}%</div>
              <button onClick={(e) => this.zoomOut(e)}>
                <FiZoomOut size={18} title="Zoom out" />
              </button>
            </Center>
            <ImageWrapper>
              <PrevButton />
              {/* {this.state.browsableFiles ? ( */}
                <img
                  src={makeSource(
                    (
                      this.props.parent.props.data.file ||
                      this.state.browsableFiles[this.state.browsableIndex]
                    )?.uri || '',
                    (
                      this.props.parent.props.data.file ||
                      this.state.browsableFiles[this.state.browsableIndex]
                    )?.filename || '',
                  )}
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: '0,0',
                  }}
                />
              {/* ) : (
                'loading...'
              )} */}
              <NextButton />
            </ImageWrapper>
          </React.Fragment>
        ) : isVideo ? (
          <React.Fragment>
            <ImageWrapper>
              <PrevButton />
              {this.state.browsableFiles ? (
                <Frame
                src={makeSource((
                    this.props.parent.props.data.file ||
                    this.state.browsableFiles[this.state.browsableIndex]
                  )?.uri, (
                    this.props.parent.props.data.file ||
                    this.state.browsableFiles[this.state.browsableIndex]
                  )?.filename)}
              />
              ) : (
                'loading...'
              )}
              <NextButton />
            </ImageWrapper>
          </React.Fragment>
        ) : (
          <ImageWrapper>
            <Frame
              src={makeSource(this.props.uri || '', this.props.filename || '')}
            />
          </ImageWrapper>
        )}

        {/* <AbsButton
                    className="files-back"
                    onClick={() => {
                        this.props.UpdateSelf({ scale: 1.0 });
						this.props.updateState(prevState => ({ ...prevState, currentFolderId: null, currentFileId: null }))
                    }}
                >
                    Back
                </AbsButton> */}
      </React.Fragment>
    )
  }
}

export const AbsButton = styled(Button)`
  cursor: pointer;
  opacity: 1;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  color: white;
`

export const Frame = styled.iframe`
  flex: 1;
  width: 100%;
`

const ImageWrapper = styled.div`
  height: 60vh;
  overflow: scroll;
  padding: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  img {
    transform-origin: 0 0;
  }

  @media (min-width: 970px) {
    max-height: 77vh;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  display: flex;
  justify-content: space-between;

  .preview {
    margin-top: 200px;
    margin-left: 30px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.75);
    transition: background 0.2s, opacity 0.34s,
      transform 0.34s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    z-index: 0;
    color: white;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }

  .next {
    margin-top: 200px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.75);
    transition: background 0.2s, opacity 0.34s,
      transform 0.34s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    z-index: 0;
    color: white;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }
`

const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  margin: 10px 0;

  .zoom {
    padding: 6px;
    border: 2px solid;
    font-size: 13px;
    border-radius: 4px;
    min-width: 55px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    padding: 0 12px;
    cursor: pointer;
    color: inherit;
    background-color: transparent;
    border: 0;
    font-size: 16px;
  }
`

const browserable = [
  'pdf',
  'png',
  'svg',
  'jpg',
  'jfif',
  'jpeg',
  'gif',
  'txt',
  'mp4',
  'mov',
  'wmv',
  'flv',
  'avi',
  'webm',
  'mkv',
  'webp'
]
const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']
const can_display = (filename: string) => {
  let match = filename.match(/\.(\w+)$/)
  if (!match || !match[1]) {
    return false
  }
  return (
    browserable.includes(match[1].toLowerCase()) ||
    office.includes(match[1].toLowerCase())
  )
}

export const makeSource = (uri: string, filename: string) => {
  let match = filename.match(/\.(\w+)$/)
  if (!match || !match[1]) {
    return uri
  }
  if (office.includes(match[1].toLocaleLowerCase())) {
    return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
      uri,
    )}`
  }
  return uri
}
