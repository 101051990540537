const { GlobalState } = require('reflux')
import React, { Component, Fragment, createRef } from 'react'
import styled from 'styled-components'
import PDF from 'react-pdf-js'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.parcel'

import LoopApi from '../../helpers/LoopApi'

import SetUrl from './SetUrl'
import { IWidgetProps, AllWidgets } from '../types'

import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import { FiZoomOut } from '@react-icons/all-files/fi/FiZoomOut'
import { FiZoomIn } from '@react-icons/all-files/fi/FiZoomIn'

import FileDrop from "react-file-drop";

import Loading from '../../screens/Meeting2.0/Setup/Loading';
import { VariableCallingStore } from '../../stores/VariableCallingStore';
import { CallingInstanceState } from '../../calling/types';
import Reflux from 'reflux';
import CustomTip from '../../helpers/CustomTip'

type Props = IWidgetProps<AllWidgets.PdfPresenter>

interface State {
	total_pages: number | any
	isUploading: boolean
	file: any
	containerWidth: number
	containerHeight: number
	meetingLocked: boolean
	status: string
	call_starter: string
	enableControl: boolean
	pdfHeight: number
	pdfWidth: number
	scale: number
	isLocal: boolean
}

export default class PdfPresenterWidget extends Reflux.Component<typeof VariableCallingStore, Props, State> {
	myScrollPerc: number
	mainContain: any
	scrollTimeout: any
	containerRef = createRef<HTMLDivElement>();
	pagRef: any = createRef<HTMLDivElement>();

	constructor(props: Props) {
		super(props)
		this.state = {
			total_pages: 1,
			isUploading: false,
			file: this.props.data.url || null,
			containerWidth: 0,
			containerHeight: 0,
			meetingLocked: false,
			status: '',
			call_starter: '',
			enableControl: true,
			pdfHeight: 0,
			pdfWidth: 0,
			scale: 1.0,
			isLocal: true
		}
		this.handleKeypress = this.handleKeypress.bind(this)
		this.onDocumentComplete = this.onDocumentComplete.bind(this)
		this.StopPresenting = this.StopPresenting.bind(this)
		this.presentNow = this.presentNow.bind(this)
		this._handleScroll = this._handleScroll.bind(this)
		this.zoomIn = this.zoomIn.bind(this)
		this.zoomOut = this.zoomOut.bind(this)
		this.zoomReset = this.zoomReset.bind(this)
		this.resize = this.resize.bind(this)

		this.myScrollPerc = 0
		this.mainContain = null
		this.scrollTimeout = null

		this.stores = [VariableCallingStore]
		this.storeKeys = [
			'status',
			'call_starter',
			'meetingLocked'
		]
	}

	_handleScroll(e: any) {
		const { scrollTop, scrollHeight, offsetHeight } = e.target
		const scrollPerc =
			Math.round(scrollTop / (scrollHeight - offsetHeight) * 100) / 100
		clearTimeout(this.scrollTimeout)
		this.scrollTimeout = setTimeout(() => {
			this.myScrollPerc = scrollPerc
			this.props.actions.UpdateSelf({
				scrollPerc,
			})
		}, 500)
	}

	componentDidCatch(error: any) {
		console.error(error)
		if (this.props.data.url) {
			this.StopPresenting()
		}
	}

	handleKeypress(e: any) {
		if (!this.state.total_pages || !this.props.data.url) {
			return
		}

		if (e.key === 'ArrowLeft') {
			this.changePage((this.props.data.page || 1) - 1)
		} else if (e.key === 'ArrowRight') {
			this.changePage((this.props.data.page || 1) + 1)
		}
	}

	StopPresenting() {
		this.setState({ file: null })
		this.props.actions.UpdateSelf({
			page: null,
			url: null,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.data.url?.url !== this.props.data.url?.url) {
			this.setState({ total_pages: 1 })
			this.setState({ file: null })
			setTimeout(() => this.setState({ file: nextProps.data.url }), 300);
		}
		if (
			nextProps.data.scrollPerc !== this.props.data.scrollPerc &&
			this.myScrollPerc !== nextProps.data.scrollPerc
		) {
			this.myScrollPerc = nextProps.data.scrollPerc
			if (this.mainContain) {
				const { scrollHeight, offsetHeight } = this.mainContain
				this.mainContain.scrollTop =
					(scrollHeight - offsetHeight) * nextProps.data.scrollPerc
			}
		}
	}

	componentDidUpdate = (prevProps: Props, prevState: State) => {
		if (
			prevState.status !== this.state.status ||
			prevState.meetingLocked !== this.state.meetingLocked ||
			prevState.call_starter !== this.state.call_starter
		) {
			this.enableControl();
		}
	}

	onDocumentComplete(total_pages: any) {
		this.setState({ total_pages: total_pages })
	}

	changePage(page: number) {
		if (!this.state.total_pages || !this.props.data.url || page < 1 || page > (typeof this.state.total_pages === 'number' ? this.state.total_pages : this.state.total_pages._pdfInfo.numPages)) {
			return
		}
		this.props.actions.UpdateSelf({ page })
	}

	componentDidMount() {
		pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
		//console.log(this.props)
		document.addEventListener('keydown', this.handleKeypress)
		setTimeout(() => this.resize(), 200);
		window.addEventListener('resize', this.resize)
		this.enableControl();
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeypress)
		window.removeEventListener('resize', this.resize)
	}

	resize() {
		if (this.containerRef.current) {
			this.setState({
				containerHeight: this.containerRef.current.offsetHeight - 100,
				containerWidth: this.containerRef.current.offsetWidth - 50,
			})
		}
	}

	async uploadPdf(targetFiles: any) {
		try {
			this.setState({ isUploading: true });
			const file = targetFiles[0];

			if (file.type !== 'application/pdf') {
				return
			}

			const data = new FormData();
			data.append("file", file);
			data.append('targetWidget', 'pdfpresenter')
			let response = await LoopApi(null, "UploadFile", {}, undefined, data);

			this.presentNow(response.uri);
		} catch (err) {
			console.error(err);
		} finally {
			this.setState({ isUploading: false });
		}
	}

	async presentNow(given_url: string) {
		try {
			const url = given_url.startsWith(
				process.env.REACT_APP_API_URL || 'http://localhost:8000'
			)
				? given_url
				: await LoopApi(null, 'GetUrlHash', {}, [['url', given_url]])
			if (url.error) {
				return console.error(url)
			}

			this.props.actions.UpdateSelf({ page: null, url, scrollPerc: 0 })

		} catch (e) {
			console.error(e)
		}
	}

	zoomIn() {
		if(this.state.isLocal) {
			if (this.state.scale > 1.9) return
			this.setState({ scale: this.state.scale + 0.1 })
		} else {
			if (this.props.data.scale > 1.9) return
			this.props.actions.UpdateSelf({ scale: this.props.data.scale + 0.1 })
		}
	}

	zoomOut() {
		if(this.state.isLocal) {
			if (this.state.scale < 1) return
			this.setState({ scale: this.state.scale - 0.1 })
		} else {
			if (this.props.data.scale < 1) return
			this.props.actions.UpdateSelf({ scale: this.props.data.scale - 0.1 })
		}
	}

	zoomReset() {
		if(this.state.isLocal) {
			this.setState({ scale: 1.0 })
		} else {
			this.props.actions.UpdateSelf({ scale: 1.0 })
		}
	}

	enableControl = () => {

		if(this.props.travelling) {
			this.setState({ enableControl: false})
		} else if (
			CallingInstanceState.Connected === this.state.status &&
			this.state.meetingLocked
		) {
			if(this.state.call_starter === GlobalState.auth.jwt.data._id)
			this.setState({ enableControl: true, isLocal: false })
			else
			this.setState({ enableControl: false})
		} else {
			this.setState({ enableControl: true })
		}
	}

	render() {
		if (!this.props.data.url) {
			return <SetUrl SetUrl={this.presentNow} addLink={this.props.actions.AddLink} travelling={this.props.travelling}/>
		}

		const url = this.props.data.url?.url || this.props.data.url

		return (
			<Container ref={this.containerRef}>
				<Drop
					onScroll={this._handleScroll}
					{...{ ref: (r: any) => (this.mainContain = r) } as any}
					onDrop={(e: any) => { this.uploadPdf(e) }}
				>
					<div className="show-while-dragging">Drag Files Here</div>
					<div className="show-while-dragging-over">Drop Files</div>
					<ControlsTOP className="container content">
						<Left>
							{this.state.enableControl && <button className="stop-button" onClick={this.StopPresenting}>
								<svg
									data-for='sPresenting'
									data-tip
									stroke="currentColor"
									fill="none"
									strokeWidth="2"
									viewBox="0 0 24 24"
									strokeLinecap="round"
									strokeLinejoin="round"
									height="24"
									width="24"
									xmlns="http://www.w3.org/2000/svg">
									<defs>
										<linearGradient id="GrayGradient">
											<stop offset="50%" stopColor="#596c76" />
											<stop offset="51%" stopColor="#474f54" />
										</linearGradient>
										<linearGradient id="OrangeGradient">
											<stop offset="50%" stopColor="#fdbf00" />
											<stop offset="51%" stopColor="#ff9100" />
										</linearGradient>
									</defs>
									<circle cx="12" cy="12" r="10"></circle>
									<rect x="9" y="9" width="6" height="6"></rect>
								</svg>
							</button>}
							<CustomTip top={-20} left={10} class='top-left' place='right' tip='Stop Presenting' dataFor='sPresenting' />
						</Left>
						<Center>
							{this.state.enableControl && <button
								onClick={this.zoomIn}
							>
								<FiZoomIn size={18} data-for='zIn' data-tip />
							</button>}
							<div className="zoom">
								{((this.state.isLocal ? this.state.scale : this.props.data.scale) * 100).toFixed(0)}%
							</div>
							{this.state.enableControl && <button
								onClick={this.zoomOut}
							>
								<FiZoomOut size={18} data-for='zOut' data-tip />
							</button>}

							<CustomTip top={-20} left={10} class='top-left' place='right' tip='Zoom In' dataFor='zIn' />
							<CustomTip top={-20} left={10} class='top-left' place='right' tip='Zoom Out' dataFor='zOut' />
						</Center>
						<Right>
							{
								isNaN(this.state.total_pages) &&
								<Fragment>
									{this.state.enableControl && <ArrowButtons onClick={() => this.changePage((this.props.data.page || 1) - 1)}>
										<FiChevronLeft size={18} data-tip data-for='ppage' />
									</ArrowButtons>}
									<Pages>
										{this.props.data.page || 1}/{this.state.total_pages._pdfInfo.numPages}
									</Pages>
									{this.state.enableControl && <ArrowButtons onClick={() => this.changePage((this.props.data.page || 1) + 1)}>
										<FiChevronRight size={18} data-tip data-for='npage' />
									</ArrowButtons>}
									<CustomTip top={-20} left={-10} class='top-right' place='left' tip='Next Page' dataFor='npage' />
									<CustomTip top={-20} left={-10} class='top-right' place='left' tip='Previous Page' dataFor='ppage' />
								</Fragment>
							}

						</Right>

					</ControlsTOP>

					{this.state.file &&
						<PdfWrapper>
							<Document
								file={this.state.file}
								loading={
									<LoadingWrapper
										containerHeight={this.state.containerHeight}
										containerWidth={this.state.containerWidth}
									>
										<Loading />
									</LoadingWrapper>
								}
								onLoadSuccess={this.onDocumentComplete}
								onLoadError={(err: any) => { this.StopPresenting() }}>
								<Page
									key={`page_${this.props.data.page || 1}`}
									pageNumber={this.props.data.page || 1}
									scale={(this.state.isLocal ? this.state.scale : this.props.data.scale)}
									renderAnnotationLayer={false}
									inputRef={this.pagRef}
								/>
							</Document>
						</PdfWrapper>
					}
				</Drop>
			</Container>
		)
	}
}

const PdfWrapper = styled.div`
	.react-pdf__Document {
		overflow-x: auto;
		overflow-y: hidden;
	}
`

const Left = styled.div`
	display: flex;
    justify-content: flex-start;
    align-items: center;
	min-width: 60px;

	.stop-button {
		/* padding: 10px !important; */
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		
		> svg {
			fill: #ff9800;
			> circle {
				stroke:url(#GrayGradient);
				fill:url(#OrangeGradient);
				stroke-width: 3px;
			}
			> rect {
				// fill: #363B45;
				fill:url(#GrayGradient);
				stroke:url(#GrayGradient);
			}
		}
	}
`

const Center = styled.div`
	flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

	.zoom {
		padding: 6px;
		border: 2px solid;
		font-size: 13px;
		border-radius: 4px;
		min-width: 55px;
		min-height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	button {
		padding: 0 12px;
	}
`

const Right = styled.div`
	display: flex;
    justify-content: center;
    align-items: center;
`

const Controls = styled.div`
	opacity: 0;
	transition: opacity 0.3s ease-out;
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
`
const ControlsTOP = styled.div`
	display: flex;
	flex: 1;
	position: sticky;
	top: 0;
    padding: 15px 0;
    align-items: center;
    justify-content: center;

	button {
		/* color: white; */
		cursor: pointer;
		color: inherit;
		background-color: transparent;
		border: 0;
		font-size: 16px;
	}

	input {
		height: 30px;
		width: 60px;
		text-align: center;
	}
`
const Drop = styled(FileDrop as any)`
	flex-direction: column;
	overflow-y: scroll;
	/* background-color: #333; */
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 0 15px 15px;

	&:hover ${Controls} {
		opacity: 1 !important;
	}
	&:hover ${ControlsTOP} {
		opacity: 1 !important;
	}

	canvas {
		display: block;
		margin-left: auto;
		margin-right: auto;
		border-radius: 10px;
	}

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.file-drop-target {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		overflow: hidden;
		position: relative;
    width: 100%;
		flex-direction: column;
	}

	.show-while-dragging,
  .show-while-dragging-over {
    display: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 32px;
    color: white;
    font-weight: 500;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }

  .file-drop-dragging-over-frame.file-drop-dragging-over-target
    .show-while-dragging {
    display: none;
  }
  .file-drop-dragging-over-target .show-while-dragging-over {
    display: flex;
    background-color: rgba(46, 204, 113, 0.6);
  }
`

const Button = styled.button`
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	& > svg:first-child {
		margin-right: 4px;
	}

	& > svg:last-child {
		margin-left: 4px;
	}
`

const Pages = styled.div`
	align-items: center;
	font-size: 11px;
	font-weight: 400;
	border: 2px solid;
	border-radius: 4px;
	cursor: pointer;
	min-width: 32px;
	min-height: 26px;
	height: fit-content;
	width: fit-content;
	padding: 7px;
`

const ArrowButtons = styled.button`
	align-self: center;
    display: flex;
`

const LoadingWrapper = styled.div<{ containerWidth: number, containerHeight: number }>`
	width: ${({ containerWidth }) => containerWidth}px;
	height: ${({ containerHeight }) => containerHeight}px;
`
const Container = styled.div`
	width: 100%;
	height: 100%;
`
