import Color from 'color'
import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import { defaultColors, ThemingStore } from '../../stores/ThemingStore'

interface Props {
    pre?: React.ReactNode
    name?: string | React.ReactNode
    subname?: string | React.ReactNode
    end?: React.ReactNode
    subnameClassName?: string
    className?: string
    sticky?: boolean
}

interface State {
    color_scheme: string
}

export default class WidgetHeader extends Reflux.Component<
    typeof ThemingStore,
    Props,
    State
> {
    store: any
    colorSchemeMapper: any

    constructor(props: Props) {
        super(props)

        this.store = ThemingStore
        this.storeKeys = ['color_scheme']
        this.state = {
            color_scheme: 'Light'
        }
    }

    render() {
        const color_scheme = defaultColors[this.state.color_scheme]
        return (
            <WidgetHeaderWrapper {...color_scheme} color_scheme={this.state.color_scheme} sticky={this.props.sticky} className={this.props.className}>
                <WidgetHeaderLeft>
                    <Title className="header">{this.props.name}</Title>
                    <Subtitle className={`subheader ${this.props.subnameClassName}`}>{this.props.subname}</Subtitle>
                </WidgetHeaderLeft>

                <WidgetHeaderRight>
                    {this.props.end}
                </WidgetHeaderRight>
            </WidgetHeaderWrapper >
        )
    }
}

const WidgetHeaderWrapper = styled.div<{ textColor: string, color_scheme: string, sticky?: boolean }>`
    display: flex;
    align-items: center;
    color: ${(props) => Color(props.textColor).toString()};

    .subheader {
		color: ${(props) => Color(props.textColor).alpha(0.7).toString()};
	}

    ${props => props.sticky ? `
        position: sticky;
        top: 0;
        z-index: 2;
    ` : ''}



`

const WidgetHeaderLeft = styled.div`
    flex: 1;
`

const WidgetHeaderRight = styled.div`
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
`

export const Title = styled.div`
    font-size: ${props => props.theme.textXXL};
    font-weight: ${props => props.theme.textBold};
`

export const Subtitle = styled.div`
    font-size: ${props => props.theme.textMD};
    margin-top: 5px;
`
