import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import LoopApi from '../../helpers/LoopApi'
import { GFile } from '../types'
import ModalSignIn from './ModalSignIn'

interface Props {
	file: GFile
	perms: string | null
	bot_mode: null | Boolean
	CreateOrUpdateWidget: (obj: object, m: boolean) => void
	isOwner: boolean
}
export default class File extends Component<Props> {
	f: any
	interval: any = null

	constructor(props: Props) {
		super(props)
		this.f = null
		this.presentNow = this.presentNow.bind(this)
		this.f = createRef()
	}

	state = {
		signInModal: false,
		randm: 0,
		disableSignIn: false
	}

	// componentWillUnmount() {
	// 	if(this.props.isOwner) {
	// 		this.clearIntrvl()
	// 	}
	// }

	// clearIntrvl = () => {
	// 	if(this.interval) {
	// 		clearInterval(this.interval)
	// 		this.interval = null
	// 	}
	// }

	// checkIframe = () => {
	// 	if(this.props.isOwner) {
	// 		this.clearIntrvl()

	// 		if(!this.state.disableSignIn) {
	// 			this.interval = setInterval(() => {
	// 				try{
	// 					if(this.f?.current?.contentWindow) {
	// 						const a = this.f.current.contentWindow[0]
	// 					}
	// 					this.clearIntrvl()
	// 				} catch(err) {
	// 					if(this.state.signInModal === false)
	// 					this.setState({signInModal: true})
	// 				}
	// 			}, 1000)
	// 		}
	// 	}
	// }

	async presentNow() {
		try {
			const url = await LoopApi(null, 'GetUrlHash', undefined, [
				['url', `https://docs.google.com/file/d/${this.props.file.id}/view`],
			])
			this.props.CreateOrUpdateWidget(
				{
					name: 'pdfpresenter',
					url,
					scrollPerc: 0,
					page: 1,
				},
				true
			)
		} catch (e) {
			console.error(e)
		}
	}

	render() {
		let filetype = this.props.file.mtype
		let src = ''
		switch (filetype) {
			case 'presentation':
				src = `https://docs.google.com/presentation/d/${this.props.file.id
					}?widget=true&headers=true`
				break
			case 'spreadsheet':
				src = `https://docs.google.com/spreadsheets/d/${this.props.file.id
					}?widget=true&headers=true`
				break
			case 'document':
				src = `https://docs.google.com/document/d/${this.props.file.id
					}/edit?usp=drivesdk`
				break
			default:
				src = `https://docs.google.com/file/d/${this.props.file.id}/preview?usp=drivesdk`
		}

		return (
			<>
				<Frame
					ref={this.f}
					src={src}
					key={this.props.file.id + this.props.perms + this.state.randm}
					id="gframe"
					// onLoad={()=> this.checkIframe()}
				/>
				{false && this.props.file.mimeType === 'application/pdf' && (
					<PresentButton onClick={this.presentNow}>Present</PresentButton>
				)}
				{/* {this.state.signInModal &&
					<ModalSignIn
						actionModal={() => {
							this.clearIntrvl()
							this.setState({
								signInModal: false,
								randm: ++this.state.randm
							})
						}}
						closeModal={() => {
							this.clearIntrvl()
							this.setState({
								signInModal: false,
								disableSignIn: true
							})
						}}
					/>
				} */}
			</>
		)
	}
}


const Frame = styled.iframe`
	flex: 1;
	width: 100%;
	border-radius: 15px;
	padding: 10px;
`

const PresentButton = styled.div`
	cursor: pointer;
	&:hover {
		opacity: 0.85;
	}
	background-color: ${props => props.theme.color.string()};
	color: white;
	padding: 4px;
	font-size: 12px;
	font-weight: 500;
	position: absolute;
	right: 4px;
	top: 4px;
`
