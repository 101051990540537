import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment'
import styled from 'styled-components'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import { FiSearch as SearchIcon } from '@react-icons/all-files/fi/FiSearch'
import { FiCalendar as CalendarIcon } from '@react-icons/all-files/fi/FiCalendar'
import { FiChevronLeft as BackIcon } from '@react-icons/all-files/fi/FiChevronLeft'

import { Theme } from './types'
import Dropdown from '../../components/Dropdown'
import { Dispatch } from 'react'
import LoopApi from '../../helpers/LoopApi'
import { ModalActions } from '../../stores/ModalStore'
import CustomTip from '../../../src/helpers/CustomTip'

interface Props {
    theme: Theme
    selectedDay: string
    selectedMonth: string
    selectedYear: string
    setSelectedDay: Dispatch<any>
    setSelectedMonth: Dispatch<any>
    setSelectedYear: Dispatch<any>
    setCurrentWeek: Dispatch<any>
    setActiveDate: Dispatch<any>
    setSelectedData: Dispatch<any>
    arrayYears: any[]
    arrayDays: any[]
    arrayMonths: any[]
    activeDate: string
}

function TimeTravelHeader(props: Props) {
    const { selectedDay, selectedMonth, selectedYear, arrayYears, arrayMonths, arrayDays, setSelectedYear, setSelectedMonth, setSelectedDay, setCurrentWeek, activeDate, setActiveDate, setSelectedData } = props
    const { meetingName } = useParams() as any
    const history = useHistory() as any;


    return (
        <Header className="border-light">
            <LogoContain>
                <CloseButton onClick={() => {
                    if(activeDate) {
                        return setActiveDate('')
                    }

                    // return history.push(`/${meetingName}`)
                    window.close()
                }} className="border-light">{
                    activeDate ? <BackIcon /> : <CloseIcon />
                }</CloseButton>
                {/* <img src={props.theme === 'Dark' ? logowhite : logoText} alt="Grapl" height="30px" className="border-light" /> */}
                <LogoWrapper className="button primary" title="Time Travel">
                    <TimeTravelIcon className="background-button fill-white" size={24} />
                </LogoWrapper>
                <Title>Time Travel</Title>
            </LogoContain>
            <Inputs>
                <Dropdown
                    name="selectedYear"
                    value={selectedYear}
                    items={arrayYears}
                    inputWidth={64}
                    onChange={(item) => {
                        setSelectedYear(item?.value)
                        setCurrentWeek(`${selectedMonth} ${selectedDay}, ${item?.value}`)
                    }}
                    rounded={false}
                    customIcon={<CalendarIcon />}
                />
                <Dropdown
                    name="selectedMonth"
                    value={selectedMonth}
                    items={arrayMonths}
                    inputWidth={90}
                    onChange={(item) => {
                        setSelectedMonth(item?.value)
                        setCurrentWeek(`${item?.value} ${selectedDay}, ${selectedYear}`)
                    }}
                    rounded={false}
                    customIcon={<CalendarIcon />}
                />
                <Dropdown
                    name="selectedDay"
                    value={selectedDay}
                    items={arrayDays}
                    inputWidth={64}
                    onChange={(item) => {
                        setSelectedDay(item?.value)
                        setCurrentWeek(`${selectedMonth} ${item?.value}, ${selectedYear}`)
                    }}
                    rounded={false}
                />
            </Inputs>

            <SearchWrapper className="border-light topbar hover-container pointer" onClick={() => ModalActions.SetModalWithParams('TranscriptionSearch', { setSelectedYear, setSelectedMonth, setSelectedDay, setSelectedData, setActiveDate })}>
                <SearchIcon data-tip data-for="search-ico" className="search-ico" size={18} />
                <CustomTip top={-40} left={-25} class='top-right' place='left' tip='advanced search' dataFor='search-ico' />
            </SearchWrapper>
        </Header>
    )
}


const TimeTravelIcon = ({ className, size }: any) => {
	return <svg className={className} width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
		<g>
			<path d="M242.4,189.1l-26.9,17.6c-0.6,0.4-1,1-1.1,1.5l-3.7,31.8c0,0.6,0.2,1.2,0.6,1.6l14.8,12c1.7,1.3,5.5,1.5,7.4,0.1l9.3-6.2
				c0.6-0.4,1-1,1.1-1.5l6.6-55.2C250.9,188.4,245.3,187.2,242.4,189.1z"/>
			<path d="M147.6,191.4c0.8,1,2,1.6,3.3,1.6h53.4c1.3,0,2.4-0.5,3.1-1.4l26.2-29.7c2.3-2.5,0.4-6.5-3.1-6.5H128.9
				c-3.3,0-5.3,3.5-3.3,6.2L147.6,191.4z"/>
			<path d="M125.5,355.7l3.7-31.4c0.1-0.6-0.2-1.2-0.9-1.6l-14.9-11.9c-1.7-1.3-5.3-1.5-7.3-0.1l-9.2,6c-0.6,0.4-1,1-1.1,1.5l-6.3,55
				c-0.3,2.4,5.2,3.7,8.1,1.8l26.6-17.8C125,356.7,125.4,356.2,125.5,355.7z"/>
			<path d="M137.8,264.6l-13.3,15.1c-1.2,1.4-1.3,3.3-0.2,4.8l10.8,14.7c0.8,1.1,2,1.7,3.3,1.7h61c1.3,0,2.4-0.5,3.1-1.4l13.4-15.1
				c1.3-1.4,1.3-3.4,0.2-4.8L204,263.4l-63.2-0.1C139.8,263.3,138.6,263.7,137.8,264.6z"/>
			<path d="M193.1,372.7c-0.8-1-2-1.6-3.3-1.6h-53.4c-1.3,0-2.4,0.5-3.1,1.4L107,402.2c-2.3,2.5-0.4,6.5,3.1,6.5h101.7
				c3.3,0,5.3-3.5,3.3-6.2L193.1,372.7z"/>
			<path d="M239.4,316.5l-7.1-5.6c-1.8-1.3-5.5-1.5-7.4-0.1l-18.2,12c-0.6,0.4-1,1-1.1,1.5l-3.7,31.4c-0.1,0.6,0.2,1.2,0.9,1.6
				l22.7,17.8c2.5,1.9,8,1.1,8.3-1.3l6.4-55.6C240.4,317.5,240.1,316.9,239.4,316.5z"/>
			<path d="M107.6,253.6c1.7,1.3,5.5,1.5,7.4,0.1l18.5-12.2c0.6-0.4,1-1,1.1-1.5l3.7-31.4c0.1-0.6-0.2-1.2-0.9-1.6l-22.7-17.9
				c-2.5-1.9-8-1.1-8.3,1.3l-6.7,55.6c-0.1,0.6,0.2,1.1,0.7,1.6L107.6,253.6z"/>
			<path d="M411.2,316.5l-7.1-5.6c-1.8-1.3-5.5-1.5-7.4-0.1l-18.2,12c-0.6,0.4-1,1-1.1,1.5l-3.7,31.4c-0.1,0.6,0.2,1.2,0.9,1.6
				l22.7,17.8c2.5,1.9,8,1.1,8.3-1.3l6.4-55.6C412.2,317.5,411.9,316.9,411.2,316.5z"/>
			<path d="M279.5,253.6c1.7,1.3,5.5,1.5,7.4,0.1l18.4-12.2c0.6-0.4,1-1,1.1-1.5l3.7-31.4c0.1-0.6-0.2-1.2-0.9-1.6l-22.7-17.9
				c-2.5-1.9-8-1.1-8.3,1.3l-6.7,55.5c-0.1,0.6,0.2,1.1,0.9,1.6L279.5,253.6z"/>
			<path d="M297.4,355.7l3.7-31.4c0-0.6-0.3-1.2-0.9-1.6l-14.9-11.9c-1.7-1.3-5.3-1.5-7.3-0.1l-9.2,6c-0.6,0.4-1,1-1.1,1.5l-6.3,55
				c-0.3,2.4,5.2,3.7,8.1,1.8l26.6-17.8C297,356.7,297.3,356.2,297.4,355.7z"/>
			<path d="M319.4,191.4c0.8,1,2,1.6,3.3,1.6h53.4c1.3,0,2.4-0.5,3.1-1.4l26.2-29.7c2.2-2.5,0.3-6.5-3.1-6.5H300.8
				c-3.3,0-5.3,3.5-3.3,6.2L319.4,191.4z"/>
			<path d="M365,372.7c-0.8-1-2-1.6-3.3-1.6h-53.4c-1.3,0-2.4,0.5-3.1,1.4l-26.2,29.7c-2.3,2.5-0.4,6.5,3.1,6.5h101.7
				c3.3,0,5.3-3.5,3.3-6.2L365,372.7z"/>
			<path d="M414.4,189.1l-26.9,17.6c-0.6,0.4-1,1-1.1,1.5l-3.7,31.8c-0.1,0.6,0.2,1.2,0.6,1.6l14.8,12c1.7,1.3,5.5,1.5,7.4,0.1
				l9.3-6.2c0.6-0.4,1-1,1.1-1.5l6.6-55.2C422.8,188.4,417.2,187.2,414.4,189.1z"/>
			<path d="M309.7,264.7l-13.3,15.2c-1.2,1.4-1.3,3.3-0.2,4.8l10.8,14.5c0.8,1.1,2,1.7,3.3,1.7h61c1.3,0,2.4-0.5,3.1-1.4l13.4-15.1
				c1.3-1.4,1.3-3.4,0.2-4.8L376,263.4h-63.2C311.6,263.3,310.5,263.8,309.7,264.7z"/>
		</g>
		<g>
			<path d="M255.7,494.8L255.7,494.8c0-7.9,6.2-14.4,14-14.9c116.5-7.3,209-104.4,209-222.7c0-123-100.1-223.1-223.1-223.1
				c-58.1,0-113.9,22.8-155.4,63c-5.7,5.5-14.6,5.7-20.4,0.4l0,0c-6.4-5.8-6.6-15.7-0.4-21.7c21-20.5,45.3-37,72.2-49.2
				c32.8-14.9,67.8-22.4,104.1-22.4c67.6,0,131.1,26.3,178.9,74.1s74.1,111.3,74.1,178.9s-26.3,131.1-74,178.9
				c-44,44-101.5,69.8-163.1,73.6C263,510.3,255.7,503.4,255.7,494.8z"/>
			<path d="M134.3,123.3H67.5c-8.3,0-14.9-6.7-14.9-14.9V47c0-8.3,6.7-14.9,14.9-14.9l0,0c8.3,0,14.9,6.7,14.9,14.9v31.5
				c0,8.3,6.7,14.9,14.9,14.9h36.9c8.3,0,14.9,6.7,14.9,14.9l0,0C149.2,116.5,142.5,123.3,134.3,123.3z"/>
		</g>
	</svg>

}

const Inputs = styled.div`
    display: flex;

    > * {
        margin: 0 5px;
    }

    margin-right: 30px;
`

const SearchWrapper = styled.div`
    border-radius: 30px;
    display: flex;
    align-items: center;
    border: 1px solid;
    padding: 10px;

    input {
        border: none;
        background: none;
        outline: none;
        font-size: 13px;

        &::placholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    .search-ico {
        opacity: 0.5;
    }
`

const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    margin-left: 15px;
`

const LogoWrapper = styled.div`
    width: 40px;
    height: 40px;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    svg.fill-white {
		path {
			fill: white
		}
	}
`

const LogoContain = styled.div`
	padding: 5px;
	font-weight: 500;
	font-size: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.2px;
    flex: 1;

	img {
		padding-left: 30px;
		border-left: 1px solid;
	}
`

const CloseButton = styled.div`
	cursor: pointer;
	font-size: 20px;
	margin-right: 15px;
    padding: 4px 20px 4px 0px !important;
    border-right: 1px solid;
`

const Header = styled.div`
    border-bottom: 1px solid;
    height: 72px;
    padding: 15px;

    display: flex;
    align-items: center;
`

export default TimeTravelHeader
