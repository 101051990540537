import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { TiMicrophone as VolumeIcon } from '@react-icons/all-files/ti/TiMicrophone'

import DecibalMeter from '../../../Meeting/Sidebar/DecibalMeter'
import CallItemControls, { IconButton } from './CallItemControls'
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from './types'
import WaveMeter from '../../../Meeting/Sidebar/WaveMeter'
import MoreCallItemControls from './MoreCallItemControls'

interface IProps {
	participant?: {
		avatar_url?: string
		username?: string
		name: string
		id: string
	}
	participants?: any[]
	currentUser?: boolean
	preview?: boolean
	backgroundPlayer?: boolean
	audioTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	videoTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	callObject?: any
	forceRender?: () => void
	forceRenderDate?: number
}

// interface IState {
// 	showMenu: boolean
// }

type IState = {
	showMenu: boolean,
}

export default class UserInCallItem extends Reflux.Component<typeof Reflux.Store, IProps, IState> {
	audioPlayer: any
	videoPlayer: any
	videoAudioPlayer: any

	constructor(props: IProps) {
		super(props)
		this.audioPlayer = null
		this.videoPlayer = null
		this.videoAudioPlayer = null

		this.BindStream = this.BindStream.bind(this)
		this.setSinkIds = this.setSinkIds.bind(this)
		this.checkArea = this.checkArea.bind(this)
		this.handleSetCallComponentSize = this.handleSetCallComponentSize.bind(this)
		this.setWidth = this.setWidth.bind(this)
	}
	state: IState

	componentDidMount() {
		this.BindStream(this.props.audioTrack, this.props.videoTrack)
		this.setSinkIds()
		this.handleSetCallComponentSize()
        window.addEventListener('resize', this.handleSetCallComponentSize)
	}
	UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		setTimeout(() => {
			this.BindStream(nextProps.audioTrack, nextProps.videoTrack)
		}, 0)
		this.setSinkIds()
		this.handleSetCallComponentSize()
        window.addEventListener('resize', this.handleSetCallComponentSize)
	}

	componentWillUnmount() {
        window.removeEventListener('resize', this.handleSetCallComponentSize)
	}
	
	checkArea(Increment: number, Count: number, Width: number, Height: number, Margin = 10) {
        let i = 0, w = 0 ;
        let h = Increment * 0.75 + (Margin * 2);
        while (i < (Count)) {
            if ((w + Increment) > Width) {
                w = 0;
                h = h + (Increment * 0.75) + (Margin * 2);
            }
            w = w + Increment + (Margin * 2);
            i++;
        }
        if (h > Height) return false;
        else return Increment;
    }

    handleSetCallComponentSize() {
            let Margin = 10;
            let Wrapper = document.getElementById('participantsWrapper');
			let Width = 0;
			let Height = 0;
			if(Wrapper) {
				Width = Wrapper.offsetWidth - (Margin * 2) - 30;
				Height = Wrapper.offsetHeight - (Margin * 2);
			}
            let Cameras = document.getElementsByClassName('call-participant');
            let max = 0;
        
			// loop (optimize this)
            let i = 1;
            while (i < 5000) {
                let w = this.checkArea(i, Cameras.length, Width, Height, Margin);
                if (w === false) {
                    max =  i - 1;
                    break;
                }
                i++;
            }
            max = max - (Margin * 2);
            this.setWidth(max, Margin);
    }

    setWidth(width: number, margin: number) {
        let Cameras = document.getElementsByClassName('call-participant') as any;
        for (var s = 0; s < Cameras.length; s++) {
            Cameras[s].style.width = width + "px";
            Cameras[s].style.margin = margin + "px";
            Cameras[s].style.height = (width * 0.75) + "px";
        }
    }

	setSinkIds() {
		if (localStorage.audio_output_device_id) {
			this.videoAudioPlayer &&
				this.videoAudioPlayer.setSinkId(localStorage.audio_output_device_id)
			this.audioPlayer &&
				this.audioPlayer.setSinkId(localStorage.audio_output_device_id)
		}
	}

	BindStream(
		audioTrack: IProps['audioTrack'],
		videoTrack: IProps['videoTrack']
	) {
		if (this.videoPlayer) {
			const stream = new MediaStream()
			const audioSeparateStream = new MediaStream()

			!this.props.currentUser && audioTrack &&
				normalizedCallHelpers.addTrackToStream(audioSeparateStream, audioTrack)
			videoTrack && normalizedCallHelpers.addTrackToStream(stream, videoTrack)

			this.videoPlayer.srcObject = stream
			this.videoAudioPlayer.srcObject = audioSeparateStream
		} else if (this.audioPlayer) {
			const stream = new MediaStream()

			!this.props.currentUser && audioTrack && normalizedCallHelpers.addTrackToStream(stream, audioTrack)

			this.audioPlayer.srcObject = stream
		} else {
		}
	}

	getStreamStates() {
		const { callObject } = this.props;
		const participant =
		this.props.participant ||
		({} as Partial<NonNullable<IProps['participant']>>)

		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
			callObject &&
			callObject.participants()
		) {
			const participants = Object.keys(callObject.participants()).map(i => callObject.participants()[i])
			const localParticipant = participants.find((p) => participant.id === p.user_name)
			isCameraMuted = !localParticipant.video;
			isMicMuted = !localParticipant.audio;
			isSharingScreen = localParticipant.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}

	
	render() {
		const participant = this.props.participant || ({} as Partial<NonNullable<IProps['participant']>>)
		const streamStates = this.getStreamStates()

		if (this.props.backgroundPlayer) {
			return <audio autoPlay ref={(el) => (this.audioPlayer = el)} />
		}


		if (
			this.props.videoTrack &&
			!normalizedCallHelpers.isTrackPaused(this.props.videoTrack)
		) {
			return (
				<Item className="call-participant rounded">
					<AspectRatioEnforcer>
						<Background avatar_url={participant.avatar_url || ''} />
						<Vid
							playsInline
							autoPlay
							{...{ ref: (el: any) => (this.videoPlayer = el) } as any}
							width="100%"
							height="100%"
						/>
						<audio ref={(r) => (this.videoAudioPlayer = r)} autoPlay />
						{
							this.props.audioTrack && normalizedCallHelpers.isTrackPaused(
								this.props.audioTrack
							) || streamStates.isMicMuted ? <MutedWrapper currentUser={this.props.currentUser}>
								<IconButtonMute
									className="button default"
									muted={true}
									>
									<VolumeIcon />
									<XLine />
								</IconButtonMute>
							</MutedWrapper> : <MutedWrapper currentUser={this.props.currentUser}>
								<WaveMeter targetId={participant.id} />
							</MutedWrapper>
						}
						{!this.props.preview && <Name className="button default" currentUser={this.props.currentUser}>{this.props.currentUser ? 'You' : participant.name || participant.username || ''}</Name>}
					</AspectRatioEnforcer>
					{
						this.props.currentUser ?
						<CallItemControls
							audioTrack={this.props.audioTrack}
							videoTrack={this.props.videoTrack}
							callObject={this.props.callObject}
							// forceRender={this.props.forceRender}
						/> : <MoreCallItemControls />
					}
				</Item>
			)
		}

		return (
			<Item className="call-participant rounded">
				<AspectRatioEnforcer>
					<Background avatar_url={participant.avatar_url || ''} />
					{/* <DecibalMeter targetId={participant.id} /> */}
					{/* <Avatar src={participant.avatar_url} /> */}
					{
						this.props.audioTrack && normalizedCallHelpers.isTrackPaused(
							this.props.audioTrack
						) || streamStates.isMicMuted ? <MutedWrapper currentUser={this.props.currentUser}>
							<IconButtonMute
								className="button default"
								muted={true}
								>
								<VolumeIcon />
								<XLine />
							</IconButtonMute>
						</MutedWrapper> : <MutedWrapper currentUser={this.props.currentUser}>
							<WaveMeter targetId={participant.id} />
						</MutedWrapper>
					}
					{!this.props.preview && <Name className="button default" currentUser={this.props.currentUser}>{this.props.currentUser ? 'You' : participant.name || participant.username || ''}</Name>}
					<audio autoPlay ref={(el) => (this.audioPlayer = el)} />
				</AspectRatioEnforcer>
				{
					this.props.currentUser ?
					<CallItemControls
						audioTrack={this.props.audioTrack}
						videoTrack={this.props.videoTrack}
						callObject={this.props.callObject}
						// forceRender={this.props.forceRender}
					/> : <MoreCallItemControls />
				}
			</Item>
		)
	}
}

const MutedWrapper = styled.div<{currentUser?: boolean}>`
	position: absolute;
	top: 10px;
	right: 10px;
	text-align: center;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Vid = styled.video`
	height: 100%;
    width: 100%;
	border-radius: 6px;
	transform: scaleX(-1);
	object-fit: cover;
`

const AspectRatioEnforcer = styled.div`
	/* padding-top: 100%; */
	height: inherit;
    width: inherit;
	background-color: black;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const Background = styled.div<{ avatar_url: string }>`
	background-image: url('${(props) => props.avatar_url}');
	background-size: cover;
	background-position: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	/* filter: blur(5px) brightness(0.4); */
`

const Avatar = styled.img`
	position: absolute;
	left: 25%;
	opacity: 0.5;
	top: 25%;
	height: 50%;
	width: 50%;
	border-radius: 50%;
`

const Name = styled.div<{currentUser?: boolean}>`
	position: absolute;
	top: 10px;
	lefT: 10px;
	/* right: 20px; */
	text-align: center;
	font-size: 12px;
	padding: 12px 15px !important;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Item = styled.div`
	width: inherit;
	height: inherit;
	/* min-width: 50%;
	flex-grow: 1; */
	overflow: hidden;
	position: relative;

	&:hover {
		${IconButton} {
			opacity: 1;
		}
	}
`

export const IconButtonMute = styled.div<{ muted?: boolean }>`
	color: white;
	padding: 5px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 4px;
	border-radius: 2px;
	font-size: 14px;
	transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
	opacity: 0;
	position: relative;
	${(props) => (props.muted ? 'opacity: 1!important;' : '')} 
	cursor: pointer;
`

const XLine = styled.div`
	position: absolute;
	height: 1px;
	width: 16px;
	background-color: #fff;
	transform: rotate(135deg);
	pointer-events: none;
`
