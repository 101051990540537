import React from 'react';
import styled, { keyframes } from 'styled-components'

interface Props {
    containerWidth?: any
    containerHeight?: any
    circl?: boolean
}

function Loader(props: Props) {
  return <LoaderWrapper className="container content" style={{ width: props.containerWidth || '100%', height: props.containerHeight || '100%', borderRadius: props.circle ? '50%' : '10px' }}>
        <LoaderComponent className='border-loader-primary border-primary'></LoaderComponent>
      </LoaderWrapper>
}

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const SpinAnimation = keyframes`
    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
`

const LoaderComponent = styled.div`
    border: 3px solid;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    -webkit-animation: ${SpinAnimation} 1s linear infinite;
    animation: ${SpinAnimation} 1s linear infinite;
`


export default Loader;
