import React, {useCallback, useRef} from 'react'
import {FiVideo as VideoOn} from '@react-icons/all-files/fi/FiVideo'
import {FiVideoOff as VideoOff} from '@react-icons/all-files/fi/FiVideoOff'

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle'
import useDevices from '../../../hooks/useDevices'
import useMeetingContext from '../../../../../../../contexts/useMeetingContext'

export default function ToggleVideoButton(props: {
  disabled?: boolean
  className?: string
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(true)
  const lastClickTimeRef = useRef(0)
  const {hasVideoInputDevices} = useDevices()
  const {localTracks} = useMeetingContext()
  const hasVideoTrack = localTracks.some((track) => track.kind === 'audio')

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideoEnabled()
    }
  }, [toggleVideoEnabled])

  return (
    <button
      data-tip
      data-for="vid"
      className={`button ${!isVideoEnabled && hasVideoTrack ? 'danger' : 'default'} controlbutton`}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || !hasVideoTrack || props.disabled }
    >
      {!hasVideoInputDevices ? (
        <VideoOff />
      ) : isVideoEnabled ? (
        <VideoOn />
      ) : (
        <VideoOff />
      )}
    </button>
  )
}
