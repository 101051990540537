import axios from 'axios'
import React, { createContext, ReactChild, useCallback, useEffect, useState } from 'react'
import Lightbox from 'react-images'
import Compressor from '../../../../../helpers/Compressor'
import LoopApi from '../../../../../helpers/LoopApi'
// import { chat } from '../../../../../helpers/pushNotification'
import { IAttachment, IChat, IChatDB, IUser } from './types'
const { GlobalState } = require('reflux')

export const ChatContext: any = createContext('')

interface Props {
    updateSelf: (obj: any) => void,
    sendNotification: (obj: any) => void,
}

function ChatContextProvider(props: React.PropsWithChildren<Props>) {
    //Global state initialization
    const meetingURL = GlobalState.main.meetingName
    const myId = GlobalState.auth.jwt.data._id
    const users = GlobalState.main.users
    const myInfo = GlobalState.main.users.find((user: IUser) => user.id === myId)
    const roomName = GlobalState.main.db_meeting.name_alias
    const dbMeeting = GlobalState.main.db_meeting

    const [chatsDB, setChatsDB] = useState<IChatDB>()
    const [uploading, setUploading] = useState<any>({})
    const [receiver, setReceiver] = useState(meetingURL)
    const [photoCount, setPhotoCount] = useState(0)

    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
    const [lightBoxIndex, setLightBoxIndex] = useState(3)
    const [skip, setSkip] = useState(10)
    const [photos, setPhotos]: any = useState([])

    const [chats, setChats] = useState<IChat[]>([])
    const [ready, setReady] = useState(false)
    const [height, setHeight] = useState(0)

    const [fetching, setFetching] = useState(false)



    //@ts-ignore
    var doc = window.shareDbConnection.get('chats', meetingURL)

    //useEffect for initializing data from sharedb
    useEffect(() => {
        // props.updateSelf({ unreadMessages: 64 })

        doc.subscribe((error: any) => {
            if (error) return console.error(error)

            if (!doc.type) {
                doc.create({
                    roomId: meetingURL,
                    users: [myId],
                    seen: { [myId]: new Date().getTime() },
                    notification: { [myId]: 'all' },
                }, (error: any) => {
                    if (error) return console.error(error)
                    console.log('updated create', doc.data);
                    setChatsDB(doc.data)
                })
            } else {
                const chatData = doc.data
                //Check if im already on users list
                if (chatData.users.indexOf(myId) === -1) {
                    //if not add my id
                    doc.submitOp([
                        { p: ['users', 0], li: myId },
                        { p: ['seen', myId], oi: new Date().getTime() },
                        { p: ['notification', myId], oi: 'all' },
                    ], {}, () => { setChatsDB(chatData); sendMessage('', [], [], true) })
                } else {
                    //if yes update my seen timestamp
                    seen()
                }

                console.log('dox', { doc }, { users }, { myId }, { GlobalState }, { myInfo });
            }
        })

        doc.on('op', () => {
            setChatsDB(doc.data)
        })

        return () => doc.unsubscribe()


    }, [])

    const seen = useCallback(() => {
        doc.submitOp([
            { p: ['seen', myId], oi: new Date().getTime() },
        ])
    }, [])

    const ObjectId = (m = Math, d = Date, h = 16, s = (s: number) => m.floor(s).toString(h)) =>
        s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

    const sendMessage = useCallback((text: string, mentions: string[], attachments: IAttachment[], info?: boolean) => {

        if (info) {
            //@ts-ignore
            var doc = window.shareDbConnection.get('chat-contents', ObjectId())
            const timeStamp = new Date().getTime()

            doc.create({
                receiver,
                meetingURL,
                sender: {
                    avatar: myInfo.avatar_url || '',
                    name: myInfo.name,
                    id: myId
                },
                type: 'info',
                message: `${myInfo.name} joined the conversation.`,
                timeStamp,
            }, (err: any) => { if (err) console.error(err) })
        }

        //send notification for mention
        !!mentions.length && props.sendNotification({
            body: {
                type: 'mention'
            },
            target_user_ids: mentions,
            widget: 'chat'
        })

        //push message to sharedb
        if (text !== '') {
            //@ts-ignore
            var doc = window.shareDbConnection.get('chat-contents', ObjectId())
            const timeStamp = new Date().getTime()

            doc.create({
                receiver,
                meetingURL,
                sender: {
                    avatar: myInfo.avatar_url || '',
                    name: myInfo.name,
                    id: myId
                },
                type: 'message',
                message: text,
                timeStamp,
                attachments: [],
                photos: [],
                reactions: []
            }, (err: any) => {
                if (err)
                    console.error(err)
                else {
                    // sending chat push notification (pansamantagal only)
                    LoopApi(null, 'SendPushNotification', {
                        user: myInfo,
                        meeting: dbMeeting,
                        message: { type: 'text', text, timeStamp, mentions }
                    })
                }
            })
        }

        if (!attachments.length) return
        uploadAttachments(attachments)

        //Count the photos for dummy attachment
        let photos = 0
        attachments.map((a: any, idx: number) => {
            if (a.file.type.indexOf('image') === 0) {
                photos++
            }

            if (idx === attachments.length - 1)
                setPhotoCount(photos)
        })
    }, [])

    //upload attachments to s3
    const uploadAttachments = async (attachments: IAttachment[]) => {
        var modifiedAttachments: any

        // Get Signed URLs
        const promises = attachments.map((a: IAttachment) => LoopApi(null, 'S3PresignedURL', {
            type: a.file.type,
            fileExtension: a.file.name.substr(a.file.name.lastIndexOf('.') + 1)
        }, undefined))

        let signedUrls = await Promise.all(promises)
        let photos: any = [], files: any = []
        modifiedAttachments = attachments.map((file: IAttachment, index: number) => {

            // return ({ ...file, signedUrl: signedUrls[index] })
            if (file.file.type.indexOf('image') === 0) {
                photos.push({ ...file, signedUrl: signedUrls[index] })
            } else {
                files.push({ ...file, signedUrl: signedUrls[index] })
            }
        })



        // Upload the photos
        const batchId = `photos-${new Date().getTime()}`
        files.map((file: any) => uploadFiles(file))
        const uploadPromises = photos.map(async (file: any, index: number) => {
            let type = file.file.type

            //Axios config for upload progress
            const config = {
                onUploadProgress: function (progressEvent: any) {
                    const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total)
                    // setUploading((prevState: any) => ({ ...prevState, [batchId]: { [file.signedUrl.url]: { progress, ...file, index } } }))

                    if (file.file.type.indexOf('image') === 0) {

                        setUploading((prevState: any) => {
                            let batchProgress = 0
                            Object.values(prevState?.[batchId] || {}).map((val: any) => {
                                batchProgress = batchProgress + (val?.progress || 0)
                            })

                            return ({
                                ...prevState,
                                [batchId]: {
                                    ...(prevState?.[batchId] || {}),
                                    [file.signedUrl.key]: {
                                        progress, ...file, index
                                    },
                                    progress: Math.ceil(batchProgress / (Object.keys(prevState?.[batchId] || {}).length - 1))
                                }
                            })
                        })

                    } else {
                        setUploading((prevState: any) => ({ ...prevState, [file.signedUrl.key]: { progress, ...file, index } }))
                    }
                },
                headers: { "Content-Type": type }
            }

            try {
                let fileToUpload = file.file

                return Compressor(fileToUpload, .8)
                    .then(async (res) => await axios.put(file.signedUrl.url, res, config))

            } catch (err) {
                return console.error('Error uploading photos', photos);
            }
        })


        let photoArr: any = []
        await Promise.all(uploadPromises).then(() => {
            photos.map((a: any, idx: number) => {
                photoArr.push(a.signedUrl.imgUrl)
                if (photos.length - 1 === idx) {
                    sendAttachments([], photoArr, batchId)
                }
            })
        })

    }

    const uploadFiles = async (file: any) => {
        let type = file.file.type
        let key = file.signedUrl.key
        //Axios config for upload progress
        const config = {
            onUploadProgress: function (progressEvent: any) {
                const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total)
                setUploading((prevState: any) => ({ ...prevState, [file.signedUrl.key]: { progress, ...file } }))
            },
            headers: { "Content-Type": type }
        }

        try {
            let fileToUpload = file.file
            const { data } = await axios.put(file.signedUrl.url, fileToUpload, config)
            return data
        } catch (err) {
            return console.error('Error uploading file', file.file);

        } finally {
            sendAttachments([file], [], key)
        }
    }

    const sendAttachments = (files: any, photos: any, key: string) => {

        if (!!photos.length) {
            //@ts-ignore
            var doc = window.shareDbConnection.get('chat-contents', ObjectId())
            const timeStamp = new Date().getTime()

            doc.create({
                receiver,
                meetingURL,
                sender: {
                    avatar: myInfo.avatar_url || '',
                    name: myInfo.name,
                    id: myId
                },
                type: 'photos',
                timeStamp,
                photos,
                reactions: []
            }, (err: any) => {
                if (err)
                    console.error(err)
                else {
                    removeFromUploading(key)

                    // sending chat push notification with photos (pansamantagal only)
                    LoopApi(null, 'SendPushNotification', {
                        user: myInfo,
                        meeting: dbMeeting,
                        message: { type: 'photos', timeStamp, photos }
                    })
                }
            })

        }

        if (!!files.length)
            files.map((f: any) => {
                //@ts-ignore
                var fileDoc = window.shareDbConnection.get('chat-contents', ObjectId())
                const timeStamp = new Date().getTime()

                fileDoc.create({
                    receiver,
                    meetingURL,
                    sender: {
                        avatar: myInfo.avatar_url || '',
                        name: myInfo.name,
                        id: myId
                    },
                    type: 'files',
                    timeStamp,
                    file: {
                        type: f.file.type,
                        name: f.file.name,
                        url: f.signedUrl.imgUrl,
                        size: f.file.size
                    },
                    reactions: []
                }, (err: any) => {
                    if (err)
                        console.error(err)
                    else {
                        removeFromUploading(key)

                        // sending chat push notification with photos (pansamantagal only)
                        LoopApi(null, 'SendPushNotification', {
                            user: myInfo,
                            meeting: dbMeeting,
                            message: { type: 'files', timeStamp, file: { type: f.file.type, name: f.file.name, url: f.signedUrl.imgUrl, size: f.file.size } }
                        })
                    }
                })
            })


    }

    const removeFromUploading = (key: string) => {
        const uploadsArr = { ...uploading }
        delete uploadsArr[key]
        setUploading(uploadsArr)

        setTimeout(() => {
            console.log('verifying ', key, ' if still in ', uploading, (key in uploading));

            if (key in uploading) {
                const uploadsArr = { ...uploading }
                delete uploadsArr[key]
                setUploading(uploadsArr)
            }

        }, 10000);
    }

    //===========================// Conversation Section //=========================//

    useEffect(() => {

        console.log('conv useEffect');

        //@ts-ignore
        var query = window.shareDbConnection.createSubscribeQuery('chat-contents', {
            receiver,
            meetingURL,
            // sender: props.sender
            // $skip: skip,
            $limit: 10,
            $sort: { timeStamp: -1 },
            // "timeStamp": { $gt: 1656695026144 }
        })

        query.on('ready', () => {
            console.log("READY", query.results);

            localStorage.setItem('chats', '[]')
            populateChat(query.results)
        })

        // query.on('changed', (result: any) => {
        //     console.log('changed', result);

        //     populateChat(query.results)
        // })

        query.on('insert', function (docs: any, index: number) {
            if (docs[0].data.sender.id === myId)
                seen()
            
            populateChat(docs)
        })

        return () => {
            localStorage.setItem('chats', '[]')
            query.destroy()
        }

    }, [])


    const populateChat = (data: any[], height?: boolean) => {
        // console.log('populaate called', data);

        const localChat: string = localStorage.getItem('chats') || '[]'
        // if (accumulatedChats > chats) return
        const chatArray: IChat[] = [...(JSON.parse(localChat || '[]') || [])]
        let photoArr: Array<any> = []

        // console.log('chat mutate', chatArray);

        const mapProcess = data.map((r: any) => {
            // console.log('add this', r.data);

            return chatArray.push(r.data)
        })

        Promise.all(mapProcess).then(() => {
            const rootNode = document.getElementById("scrollableDiv");
            if (rootNode && height) {
                const scrollPosition = Math.ceil(-1 * rootNode.scrollTop)
                setHeight(-scrollPosition)
            }

            setChats(chatArray)
            localStorage.setItem('chats', JSON.stringify(chatArray || {}))
            // console.log('newChat', chatArray);


            // console.log('new chats', chatArray);

            chatArray.map((c: IChat, index: number) => {
                //Push images to photoArry for image viewing
                if (c.type === 'photos') {
                    c.photos.map((url: string) => {
                        photoArr.push({ src: url, srcSet: [url] })

                    })
                }

                if (index === chatArray.length - 1)
                    setPhotos(photoArr)

            })
        })
    }

    const fetchMoreData = useCallback(() => {

        setFetching(true)
        //@ts-ignore
        var query = window.shareDbConnection.createSubscribeQuery('chat-contents', {
            receiver,
            meetingURL,
            // sender: props.sender
            $skip: skip,
            $limit: 10,
            $sort: { timeStamp: -1 }
        })

        query.on('ready', () => {

            setSkip(skip + 10)
            populateChat(query.results, true);
            query.destroy()

            setTimeout(() => {
                setFetching(false)
            }, 500);

        })


    }, [skip])


    useEffect(() => {
        console.log('fetch statung', fetching);

    }, [fetching])


    useEffect(() => {
        const rootNode = document.getElementById("scrollableDiv");
        if (rootNode && height !== 0) {
            rootNode.scrollTo(0, height)
            setHeight(0)
        }
    }, [chats])


    const handleThumbnailClick = (lightBoxIndex: number) => {
        setLightBoxIndex(lightBoxIndex)
    }


    const openLightBox = useCallback(
        (idx: number) => {
            setLightBoxIndex(idx)
            setIsLightBoxOpen(true)
        },
        [],
    )


    return (
        <ChatContext.Provider value={{
            chatsDB,
            myId,
            users,
            sendMessage,
            uploading,
            receiver,
            meetingURL,
            roomName,
            photoCount,
            openLightBox,
            fetchMoreData,
            fetching,
            chats,
            seen
        }}>
            {props.children}

            <Lightbox
                images={photos}
                backdropClosesModal={true}
                isOpen={isLightBoxOpen}
                onClose={() => setIsLightBoxOpen(false)}
                onClickNext={() =>
                    setLightBoxIndex(lightBoxIndex + 1)
                }
                onClickPrev={() =>
                    setLightBoxIndex(lightBoxIndex - 1)
                }
                showThumbnails={true}
                showImageCount={false}
                onClickThumbnail={handleThumbnailClick}
                currentImage={lightBoxIndex}
            />

        </ChatContext.Provider >
    )
}

export default ChatContextProvider