import React, { memo } from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import { FiMoreHorizontal as MoreOptionsIcon } from '@react-icons/all-files/fi/FiMoreHorizontal'

import Modal from '/components/Modalv2';
import check from '../../../../src/assets/icons/check.svg';
import checkLight from '../../../../src/assets/icons/check-light.svg';
import LoopApi from '../../../helpers/LoopApi';
import moment from 'moment';
import { ModalActions } from '/stores/ModalStore';
import { MdUnarchive } from 'react-icons/md';
import useToastify from '../../../helpers/useToastify';
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import Table from '../../../components/Table';
import { WorkspaceAvatar } from '../../Meeting2.0/styles';

const { GlobalState } = require('reflux');


const ArchiveRooms = memo((props) => {
    const [rooms, setRooms] = React.useState([])

    React.useEffect(() => {
        getArchivedMeetings()
    }, [])

    const getArchivedMeetings = async () => {
        const { meetings } = await LoopApi(null, 'GetArchivedMeetings')
        setRooms(meetings)
    }

    const unarchiveRoom = async (id, name) => {
        try {
            await LoopApi(null, 'UnarchiveMeeting', { id })
            await GlobalState.modal.fetchSpacesAndRooms()
            await getArchivedMeetings()
            return useToastify({
                message: () => SuccessErrorToast({ message: 'Room has been unarchived', type: 'success'  }),
                position: "top-right",
                autoClose: 2000,
                closeButton: false,
                hideProgressBar: true,
                className: GlobalState.theming.color_scheme === 'Light' ? 'toastL' : 'toastD',
                bodyClassName: "grow-font-size",
            })
        } catch (error) {
            if(error === 'Max room limit reached.') {

                return useToastify({
                    message: () => SuccessErrorToast({ message: 'Cannot unarchive room. Max room limit reached.', type: 'error'  }),
                    position: "top-right",
                    autoClose: 2000,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState.theming.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })
            }
            console.error('Error unarchiving meeting', error.message)
        }
    }

    const removeForever = async (id, name) => {
        try {
            ModalActions.SetModalWithParams('RemoveForever', { id, name, getArchivedMeetings } )
            // await LoopApi(null, 'RemoveMeetingForever', { id, name })
            // getArchivedMeetings()
        } catch (error) {
            console.error('Error removing room forever', error.message)
        }
    }


    return (
        <Modal closeModal={props.closeModal} dismissible={true}>
            <Container>
                <Contents>
                    <Title className="header">Archived Rooms</Title>
                    <Details className="subheader">Rooms shown below will be automatically removed forever after 30 days</Details>
                </Contents>
                <Table
                    key={`${(rooms || []).length}-archivedlists`}
                    columns={[{
                        Header: 'Room',
                        accessor: '',
                        Cell: ({ original }) => {
                            return <RoomWrapper>
                                <WorkspaceAvatar>
                                    <img src={original.avatar_url || `https://ui-avatars.com/api/?name=${original.name_alias || original.name || 'Room'}&background=${(GlobalState.theming.color_scheme || 'Light') === 'Light' ? 'F2F4F6' : '21212B&color=FEFEFE'}&format=svg`} alt={original.name_alias || original.name} />
                                </WorkspaceAvatar>
                                {original.name_alias || original.name || '-'}
                            </RoomWrapper>
                        }
                      },{
                        Header: 'Date Archived',
                        accessor: '',
                        Cell: ({ original }) => {
                            return <RoomWrapper className="center">
                                {
                                    `${original.archiveDate ? moment(original.archiveDate).format('MMM DD, YYYY hh:mm a') : '-'}`
                                }
                            </RoomWrapper>
                        }
                      },{
                        Header: '',
                        accessor: '',
                        width: 60,
                        Cell: ({original}) => {
                            return <RoomWrapper className="center" onClick={(e) => { e.stopPropagation() }}>
                                <Tooltip
                                    // visible={currentlyOpenMenu === meeting._id}
                                    // visible
                                    overlay={
                                        <MoreOptionsOverlay unarchiveRoom={() => unarchiveRoom(original._id, original.name)} removeForever={() => removeForever(original._id, original.name)}/>
                                    }
                                    placement="bottomRight"
                                    trigger={['click']}
                                    overlayClassName={`archive-dropdown rctooltip${(GlobalState.theming.color_scheme || 'Light') === 'Light' ? 'L' : 'D'}`}
                                >
                                <div style={{ cursor: 'pointer' }}>
                                    <MoreOptionsIcon size={20} />
                                </div>
                            </Tooltip>

                            </RoomWrapper>
                        }
                      }
                    ]}
                    data={rooms || []}
                    defaultPageSize={(rooms || []).length === 0 ? 5 : (rooms || []).length}
                    showPagination={false}
                    sortable={false}
                    noPointer
                    noBorder
                    withMinHeight={false}
                    withMaxHeight={false}
                />
                {/* {rooms.map((r) => {
                    return <div className='row'><div>{r.name_alias || r.name}</div> <a href="#" onClick={() => unarchiveRoom(r._id, r.name)}><MdUnarchive size={30} title="Unarchive" /></a></div>
                })} */}
            </Container>
        </Modal>
    );
});

const ArchivedTable = () => {
    return
}

const MoreOptionsOverlay = ({ unarchiveRoom, removeForever }) => {
    return <Wrapper>
    <div onClick={() => unarchiveRoom()}>Restore</div>
        <div onClick={() => removeForever()}>Remove Permanently</div>
    </Wrapper>
}

export default ArchiveRooms;

const RoomWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    &.center {
        text-align: center;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        padding: 5px 0;
        cursor: pointer;
    }

    .disabled {
		cursor: default;
		opacity: 0.3;
    }
`

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
    max-width: 500px;

    .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 3px;
        align-items: center
    }
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`


const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`