import { DailyCall } from '@daily-co/daily-js'
import React from 'react'
import { toast } from 'react-toastify'
import { Room } from 'twilio-video'
import { CallingProviderName } from '../../../calling/types'
import useMeetingContext from '../../../contexts/useMeetingContext'
import { closeAwsTranscriber } from '../../../helpers/aws-transcriber'
import Sagas from '../../../helpers/Sagas'
import { ModalActions } from '../../../stores/ModalStore'
import BasicTopbarControls from './BasicTopbarControls'

function BasicTopbarControlsFunctional() {
    const meetingContext = useMeetingContext()
    //@ts-ignore
    window.leaveMeeting = () => {
      if(meetingContext.callProvider === CallingProviderName.DailyCo) {
        //   meetingContext.room?.stopRecording()
          (meetingContext.room as DailyCall)?.leave();
          (meetingContext.room as DailyCall)?.destroy()
          meetingContext.stopLocalTracks()
      } else {
          (meetingContext.room as Room)?.disconnect()
          meetingContext.stopLocalTracks()
      }
      Sagas.Clients.Emit('main', 'message', {action: 'CallDisconnected'})
      closeAwsTranscriber();
      toast.dismiss('meetingRecordingNotif')
    }
  return (
    <BasicTopbarControls meetingContext={meetingContext} />
  )
}

export default BasicTopbarControlsFunctional