import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import Modal from '../../../components/Modalv2'
import { FiInfo as InfoIcon } from '@react-icons/all-files/fi/FiInfo'
import { ModalActions } from '../../../stores/ModalStore'
import LoopApi from '../../../helpers/LoopApi';
import { VariableCallingStore } from '../../../stores/VariableCallingStore'

export default class MeetingOngoing extends Reflux.Component<any> {
  constructor(props: any) {
    super(props)

    this.store = VariableCallingStore
    this.storeKeys = ['connectedUserIds']
    this.Remove = this.Remove.bind(this)
    this.openCallDropdown = this.openCallDropdown.bind(this)
  }

  openCallDropdown() {
    ModalActions.UpdateParams({ callDropdownOpen: true })
    this.props.closeModal()
  }

  Remove() {
    this.props.closeModal()
  }

  async componentDidMount() {
    try {
      if(!!!localStorage.getItem('token')) {
        throw new Error('invalid token')
      }
      const limits = await LoopApi(null, 'StripeGetRoomOwnerLimits');
      this.setState({ limits })
    } catch(err) {
      //console.log("error: ", err)
      if(err === 'invalid token') {
        localStorage.removeItem('token')
        if(localStorage.getItem('audio_input_device_id')) {
          localStorage.removeItem('audio_input_device_id')
        }
        if(localStorage.getItem('video_input_device_id')) {
          localStorage.removeItem('video_input_device_id')
        }
        if(localStorage.getItem('audio_output_device_id')) {
          localStorage.removeItem('audio_output_device_id')
        }

        this.props.history.push(`/login`)
      }
    }
  }

  render() {

    return (
      <Modal closeModal={this.props.closeModal} dismissible={false}>
        <Container>
          <Icon className="container">
            <InfoIcon />
          </Icon>
                <Title>Meeting already started!</Title>
                <Info>
                  {
                    this.state.connectedUserIds?.length + 1 > this.state.limits?.meeting ? 'Maximum meeting participants limit reached.' :
                    <React.Fragment>Click the <span className="color-primary" style={{ fontWeight: 500 }}>Join Meeting</span> button on the header to enter the meeting.</React.Fragment>
                  }
                </Info>
                <Options>
                  {
                    this.state.connectedUserIds?.length + 1 > this.state.limits?.meeting ?
                    <Button
                      onClick={() => ModalActions.SetModal('MaximumAllowedParticipants')}
                      id="dismiss" className="button primary"
                    >
                      Room is full
                    </Button> : 
                      <Button onClick={this.openCallDropdown} id="dismiss" className="button primary">Join Now</Button>
                  }
                  <Button onClick={this.Remove} id="dismiss" className="button default">Later</Button>
                </Options>
        </Container>
      </Modal>
    )
  }
}

const Icon = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: inherit;
`

const Info = styled.div`
  font-weight: 400;
  color: inherit;
  line-height: 20px;
  margin: 10px 0 15px;
`

const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;
  max-width: 350px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0 5px;
  &::first-letter {
    text-transform: uppercase;
  }
`

const Options = styled.div`
  display: flex;
  width: 100%;
`

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 14px;
  margin: 4px;
  font-weight: 400;
`
