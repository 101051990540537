import React, { Component } from "react";
import Reflux from "reflux";
import styled from "styled-components";
import { TiMicrophone as VolumeIcon } from "@react-icons/all-files/ti/TiMicrophone";
import { MdVideocam as VideoIcon } from "@react-icons/all-files/md/MdVideocam";
import { MdCallEnd as EndCallIcon } from "@react-icons/all-files/md/MdCallEnd";
import { BsThreeDotsVertical as MoreOptionIcon } from "@react-icons/all-files/bs/BsThreeDotsVertical";
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from "./types";
import {
  VariableCallingActions,
  VariableCallingStore,
} from "../../../../stores/VariableCallingStore";
import DropdownWrapper from "../../../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper";
import { FaRegClosedCaptioning as CaptionIcon } from "@react-icons/all-files/fa/FaRegClosedCaptioning";
import { FaCrown as HostIcon } from "@react-icons/all-files/fa/FaCrown";
import useToastify from "../../../../helpers/useToastify";

import LoopApi from "../../../../helpers/LoopApi";
import { MainStore } from "../../../../stores/MainStore";

interface IProps {
  audioEnabled: boolean
  videoEnabled: boolean
  onVideoClick: Function
  onAudioClick: Function
  startScreenshare: Function
  canSharescreen?: boolean
  isSharingScreen?: boolean
  audioDevices?: any[]
  videoDevices?: any[]
  updateVideoDevice?: Function
  updateAudioDevice?: Function
  participant?: any
}

interface State {
	meetingName: string
  host: string
}

export default class CallItemControls extends Reflux.PureComponent<
  typeof VariableCallingStore | typeof MainStore,
  IProps,
  State
> {
  constructor(props: never) {
    super(props);
    this.stores = [VariableCallingStore, MainStore];
    this.storeKeys = ["callObject", "meetingName", "call_starter", "host"];
  }

  askHostAccess = async() => {
		debugger
		try {
			const resp = await LoopApi(null, 'AskHostAccess', {host_id: this.state.host }, [
				['name', this.state.meetingName],
			])

			if (resp.status === 'failed') {
				useToastify({
					type:'error',
					message: 'Denied access'
				})
			}

			// this.setState({ status: resp.status })
			// this.knockTimeout = setTimeout(() => this.tryKnock(), 2000)
		} catch (e) {
			console.error(e)
			// this.setState({ status: 'failed' })
		}
	}

  render() {
    const { audioDevices = [], videoDevices = [], updateVideoDevice = () => {}, updateAudioDevice = () => {}, participant = {} } = this.props
    const isHost = participant?.identity?.split("::")?.[1] === this.state.host

    const MoreOptions = (props: any) => {
      return (
        <React.Fragment>
          <Item
            className={`hover-container ${this.props.isSharingScreen ? "color-primaryimp" : ""}`}
            onClick={() => {
              if (this.props.isSharingScreen) {
                VariableCallingActions.StopLocalShareScreen()
              } else if (this.props.canSharescreen) {
                this.props.startScreenshare()
              }
              props.onClose()
            }}
            style={this.props.isSharingScreen ? {} : {
              color: this.props.canSharescreen ? 'unset' : 'rgba(0,0,0,0.5)',
              cursor: this.props.canSharescreen ? 'unset' : 'not-allowed'
            }}
          >
            <ScreenshareIconSvg />
            {this.props.isSharingScreen ? 'Stop Screenshare' : 'Screenshare'}
          </Item>
          {!isHost && <Item className="hover-container" onClick={() => {
            this.askHostAccess()
            props.onClose()
          }}>
            <HostIcon size={16} />
            Ask for host access
          </Item>}
          {/* <Item className="label">
            <strong>Camera</strong>
          </Item>
          {
            Array.isArray(videoDevices) && videoDevices.length > 0 && videoDevices.map((device: any) => {
              let selectedVideoDeviceId = localStorage.getItem("video_input_device_id");
              let disabled = selectedVideoDeviceId === device.deviceId
              return <Item
                key={device.deviceId}
                className="hover-container"
                onClick={() => {
                  if (disabled) {
                    return
                  }
                  updateVideoDevice(device.deviceId)
                  props.onClose()
                }}
                style={{
                  color: !disabled ? 'unset' : 'rgba(0,0,0,0.5)',
                  cursor: !disabled ? 'unset' : 'not-allowed'
                }}
              >
              - { device.label }
            </Item>
            })
          }
          <Item className="label">
            <strong>Microphone</strong>
          </Item>
          {
            Array.isArray(audioDevices) && audioDevices.length > 0 && audioDevices.map((device: any) => {
              let selectedAudioDeviceId = localStorage.getItem("audio_input_device_id");
              let disabled = selectedAudioDeviceId === device.deviceId
              return <Item 
                key={device.deviceId} 
                className="hover-container" 
                onClick={() => {
                  if (disabled) {
                    return
                  }
                  updateAudioDevice(device.deviceId)
                  props.onClose()
                }}
                style={{
                  color: !disabled ? 'unset' : 'rgba(0,0,0,0.5)',
                  cursor: !disabled ? 'unset' : 'not-allowed'
                }}
              >
              - { device.label }
            </Item>
            })
          } */}
          {/* {!this.props.isHost && <Item className="hover-container" onClick={() => {
					this.askHostAccess()
					props.onClose()
				}}>
					<HostIcon size={16} />
					Ask for host access
				</Item>} */}
        </React.Fragment>
      );
    };

    return (
      <Container className="call-control-container">
        <IconButton
          className="button default-opaque call-control"
          muted={false}
          onClick={() => { this.props.onAudioClick() }}
        >
          <VolumeIcon />
          {(!this.props.audioEnabled) ? <XLine /> : null}
        </IconButton>

        <IconButton
          muted={false}
          className="button default-opaque call-control"
          onClick={() => { this.props.onVideoClick() }}
        >
          <VideoIcon />
          {(!this.props.videoEnabled) ? <XLine /> : null}
        </IconButton>

        <Dropdown DropdownItems={MoreOptions}>
          <IconButton className={`button default-opaque call-control`}>
            <MoreOptionIcon />
          </IconButton>
        </Dropdown>

        <IconButton
          className="button danger-opaque call-control"
          onClick={() => {
            VariableCallingActions.Leave();
          }}
        >
          <EndCallIcon />
        </IconButton>
      </Container>
    );
  }
}

const ScreenshareIconSvg = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9V12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H11"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 17H15"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 13V17"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 13V17"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 1H19V5"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 6L19 1"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const Item = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 5px 4px;
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & > img,
  & > svg {
    margin-right: 8px;
  }

  &:hover {
    /* background-color: rgba(0, 200, 0, 0.075); */
    cursor: pointer;
  }

  &.label {
    &:hover {
      cursor: unset;
    }
  }
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
`;

export const IconButton = styled.div<{ muted?: boolean; active?: boolean }>`
  /* color: ${(props) => (props.active ? "#008bff !important" : "white")}; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  font-size: 20px;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  /* box-shadow: ${(props) => props.theme.shadows.light}; */
  opacity: 0;
  position: relative;
  /* ${(props) => (props.muted ? "opacity: 1!important;" : "")}  */
  cursor: pointer;
  width: 38px;
  height: 38px;
`;

const XLine = styled.div`
  position: absolute;
  height: 1px;
  width: 24px;
  background-color: #fff;
  transform: rotate(135deg);
  pointer-events: none;
`;

const Dropdown = styled(DropdownWrapper)<{ screenSharing?: boolean }>`
  .dropdown-content {
    position: fixed;
    bottom: unset;
    transform: translate(-100px, -100px);
  }

  @media (max-width: 970px) {
    .dropdown-content {
      position: fixed;
      bottom: unset;
      transform: translate(-100px, -100px);
    }
  }
`;
