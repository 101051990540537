import React from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'

import Modal from '../../../components/Modalv2'

const { GlobalState } = require('reflux')

function EmailRegistered(props: any) {

    let history = useHistory()
    const { meetingName } = useParams() as any

    const onCancel = () => {
        props.closeModal && props.closeModal()
	}

	const onLeave = async () => {
		// GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
		// GlobalState.modal.onSubmit()

        history.push(`/login/${meetingName}}`)
        props.closeModal && props.closeModal()
	}

	return (
		<Modal closeModal={() => {
			// props.closeModal()
		}}>
			<Container className="topbar-modal">
					<Contents>
						<Title className="header">Uh oh!</Title>
						<Details className="subheader">We're sorry, it looks like that an account is already registered using that email. Please use a different email address or login. </Details>
					</Contents>

					<Options>
						<Button className="button default" onClick={onCancel}>Cancel</Button>
						<Button className="button primary" onClick={onLeave}>Go to Login</Button>
					</Options>
				</Container>
		</Modal>
	)
}

export default EmailRegistered

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    background-color: white;
    color: #363B45;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
    font-size: 14px;

    &.button.primary {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); 
        color: white;
    }

	&.button.default {
        color: white; 
        background-color: #363B45;
    } 
`
