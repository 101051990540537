import React from "react";
import styled from "styled-components";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight"
import { getLinks } from "./helpers";
import { IFile, IFolder, IFoldersMap } from "./types";

interface Props {
  currentFolder: IFolder | null;
  folders: IFoldersMap;
  currentFile: IFile | null;
  onFolderClick?: (folder: IFolder) => void;
  onRootFolderClick?: Function;
  onBackFromFileClick?: Function
  backVisible : boolean
}

const Directory = (props: Props) => {
  const {
    currentFolder,
    folders,
    currentFile,
    onFolderClick = (folder) => {},
    onRootFolderClick = () => {},
    onBackFromFileClick = () => {},
    backVisible
  } = props;

  let hierarchy = getLinks(currentFolder, folders);

  if(hierarchy.length && hierarchy.length > 2) {
    hierarchy = hierarchy.slice(hierarchy.length - 2)
  }

  const links = hierarchy.map((folder, index) => (
    <HierarchyWrapper key={folder.uuid}>
      <Separator>
        <ChevronRight />
      </Separator>
      <Link
        onClick={() => onFolderClick(folder)}
        disabled={!currentFile && index === hierarchy.length - 1}
        className={`${!currentFile && index === hierarchy.length - 1 ? 'color-primary' :''}`}
      >
        {folder.name}
      </Link>
    </HierarchyWrapper>
  ));

  const handleback = () => {
    if(hierarchy?.length > 1 && !currentFile) {
      return onFolderClick(hierarchy?.[hierarchy?.length - 2])
    }

    if(currentFile) {
      return onBackFromFileClick()
    }

    return onRootFolderClick()
  }

  return (
    <Wrapper className="file-breadcrumb">
      <HierarchyWrapper>
        {
          (hierarchy?.length > 0 || currentFile && !hierarchy.length) && backVisible ?
          <BackButton className="button default" onClick={handleback}>
            Back
          </BackButton> :
          ''
        }
        <Link
          onClick={() => onRootFolderClick()}
          className="button"
        >
          <h1>All Files</h1>
        </Link>
      </HierarchyWrapper>
      {currentFolder && links}
      {currentFile && (
        <HierarchyWrapper>
          <Separator>
            <ChevronRight />
          </Separator>
          <Link
            style={{
              cursor: "auto",
            }}
            disabled={true}
          >
            {currentFile.filename}
          </Link>
        </HierarchyWrapper>
      )}
    </Wrapper>
  );
};

export default Directory;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Link = styled.button`
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 !important;
  color: inherit;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:disabled {
    /* background-color: #33333d;
     */
    background: none;
    font-weight: bold;
    cursor: not-allowed;
    /* color: #fff; */
  }

  &:not(:disabled) {
    background-color: transparent;
  }
`;

const Separator = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HierarchyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChevronRight = styled(BiChevronRight)`
  margin-right: 8px;
  margin-left: 8px;
`

export const BackButton = styled.div`
  cursor: pointer;
  padding: 10px 12px !important;
  margin-right: 10px;
`
