import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import play from './play-lg.svg'

const RecordingViewer = (props) => {
    const playerRef = React.useRef(null)

    React.useEffect(() => { 
        //console.log('trying to show preview')
        playerRef.current.showPreview();
    }, [props.recording])

    const player = <StyledPlayer ref={playerRef} controls light url={props.recording.uri} playIcon={<img src={play} />} height="1000px" />

    return (
        <Container>
            <Card>{player}</Card>
        </Container>
    )
}

export default RecordingViewer;

const Card = styled.div`
	margin: 12px;
	box-shadow: ${props => props.theme.shadows.heavy};
	background-color: white;
	border-radius: 2px;
	width: 100%;
	height: auto;
	position: relative;
`

const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
    height: 1000px;
	align-items: stretch;
`

const StyledPlayer = styled(ReactPlayer)`
	top: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
`
