import React from 'react'
import styled from 'styled-components'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import { AiOutlineSearch as SearchIcon } from '@react-icons/all-files/ai/AiOutlineSearch'

interface Props {
    handleChange: (e: any) => void
}

function SearchField(props: Props) {
    const { handleChange } = props
    return (
        <SearchContainer className="border-light topbar">
            <label htmlFor="search_file">
                <SearchIcon />
            </label>
            <SearchInput
                id="search_file"
                type="search"
                name="searchValue"
                placeholder="Search"
                className="dropbox-search-input"
                onChange={handleChange}
                // value={searchFilter}
            />
        </SearchContainer>
    )
}

export default SearchField


const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	border: 1px solid;
	border-radius: 32px;
`

const SearchInput = styled.input`
	background: none;
	border: none;
	padding-left: 8px;
`


