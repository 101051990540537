import moment from 'moment';
import React, { useState, useEffect, Dispatch } from 'react'
import styled from 'styled-components';
import { FiChevronLeft as LeftIcon } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight as RightIcon } from '@react-icons/all-files/fi/FiChevronRight'

import SingleDate from './SingleDate';
import FullCalendar from './FullCalendar';
import DataDisplay from '../Display';

interface Props {
    selectedDate: string
    selectedDay: string
    currentWeek: string
    setCurrentWeek: Dispatch<any>
    setSelectedDay: Dispatch<any>
    setSelectedMonth: Dispatch<any>
    setSelectedYear: Dispatch<any>
    setSelectedData: Dispatch<any>
    setActiveDate: Dispatch<any>
    activeDate: string
    transcriptions: any
    recordings: any
    syncs: any
    selectedData: any
}

function CalendarView(props: Props) {
    const { selectedDate, selectedDay, currentWeek, setCurrentWeek, setSelectedDay, setSelectedMonth, setSelectedYear, transcriptions, recordings, syncs, selectedData, setSelectedData, activeDate, setActiveDate } = props

    const [ currentMY, setCurrentMY ] = useState(moment().format("MMMM, YYYY"))
    const [ days, setDays ] = useState<any>([])

    useEffect(() => {
        generateDaysOfWeek(currentWeek)
    }, [currentWeek])

    const generateDaysOfWeek = (selectedD: string) => {
        const startOfWeek = moment(selectedD).startOf('week')
        const endOfWeek = moment(selectedD).endOf('week')

        let currentDays: any[] = []
        let day: any = startOfWeek

        while (day <= endOfWeek) {
            currentDays.push(day.toDate())
            day = day.clone().add(1, 'd')
        }

        setCurrentMY(moment(selectedD).format("MMMM, YYYY"))
        setDays(currentDays)
    }

    const generatePreviousWeek = () =>  {
        const startOfCurrentWeek = moment(currentWeek).startOf('week')
        const endOfPrevWeek = startOfCurrentWeek.clone().subtract(1, 'd')

        const startOfWeek = moment(endOfPrevWeek).startOf('week')
        const endOfWeek = moment(endOfPrevWeek).endOf('week')

        let currentDays: any[] = [];
        let day: any = startOfWeek;

        while (day <= endOfWeek) {
            currentDays.push(day.toDate());
            day = day.clone().add(1, 'd');
        }

        setDays(currentDays)
        setCurrentWeek(startOfWeek)
        setCurrentMY(moment(currentWeek).format("MMMM, YYYY"))
    }

    const generateNextWeek = () =>  {
        const startOfCurrentWeek = moment(currentWeek).endOf('week')
        const endOfPrevWeek = startOfCurrentWeek.clone().add(1, 'd')

        const startOfWeek = moment(endOfPrevWeek).startOf('week')
        const endOfWeek = moment(endOfPrevWeek).endOf('week')

        let currentDays: any[] = [];
        let day: any = startOfWeek;

        while (day <= endOfWeek) {
            currentDays.push(day.toDate());
            day = day.clone().add(1, 'd');
        }

        setDays(currentDays)
        setCurrentWeek(startOfWeek)
        setCurrentMY(moment(currentWeek).format("MMMM, YYYY"))
    }

    const changeSelectedDate = (curSelectedDate: any) => {
        const cYear = moment(curSelectedDate).format("YYYY")
        const cMonth = moment(curSelectedDate).format("MMMM")
        const cDay = moment(curSelectedDate).format("D")

        setCurrentWeek(curSelectedDate)
        setActiveDate(curSelectedDate)
        setSelectedDay(cDay)
        setSelectedMonth(cMonth)
        setSelectedYear(cYear)
    }
    return (
        <React.Fragment>
            {
                !activeDate ?
                <FullCalendar selectedDate={selectedDate} selectedDay={selectedDay} currentMY={currentMY} changeSelectedDate={changeSelectedDate} transcriptions={transcriptions} recordings={recordings} syncs={syncs} setSelectedData={setSelectedData} /> :
                <React.Fragment>
                    <CalendarWrapper>
                        <MoveLeft className="border-light topbar" onClick={generatePreviousWeek}>
                            <LeftIcon size={12} />
                        </MoveLeft>
                        {
                            days.map((day: any, index: number) => {
                                return <SingleDate key={index} day={day} selectedDay={selectedDay} currentMY={currentMY} selectedDate={selectedDate} changeSelectedDate={changeSelectedDate} currentWeek={currentWeek} transcriptions={transcriptions} recordings={recordings} syncs={syncs} setSelectedData={setSelectedData} />
                            })
                        }
                        <MoveRight className="border-light topbar" onClick={generateNextWeek}>
                            <RightIcon size={12} />
                        </MoveRight>
                    </CalendarWrapper>
                    <DataDisplay selectedData={selectedData} setSelectedData={setSelectedData} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const HorizontalCalendar = styled.div`
    display: flex;

    .singledate {
        min-width: 150px;
        min-height: 150px;
    }
`

const BackWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`

const Back = styled.div`
    box-shadow: ${props => props.theme.shadows.newlight};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid;
    cursor: pointer;
`

const MoveLeft = styled.div`
    box-shadow: ${props => props.theme.shadows.newlight};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid;
    position: absolute;
    transform: translateX(-12px);
    left: 0;
    z-index: 2;
    margin: auto 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
`

const MoveRight = styled.div`
    box-shadow: ${props => props.theme.shadows.newlight};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid;
    position: absolute;
    transform: translateX(12px);
    right: 0;
    z-index: 2;
    margin: auto 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
`

const CalendarWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(7, minmax(150px, 1fr));
    grid-template-rows: repeat(1, minmax(150px, 1fr));
    grid-gap: 15px;
    font-weight: 400;
    position: relative;
`

export const Marker = styled.div<{whichOne?: any}>`
    width: 12px;
    height: 12px;
    background: ${
        props => 
            props.whichOne === 'Transcription' ? 'linear-gradient(114deg,#adadad 0%,#21262b 74%);' : 
            props.whichOne === 'Recording' ? 'linear-gradient(114deg, #FF9900 0%, #FF8200 100%)' :
            props.whichOne === 'Syncs' ? 'linear-gradient(90deg, #213465 0%, #008BFF 100%)' :
            'transparent'
    };
    margin: 2px;
    border-radius: 30px;
`

export const MarkerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
`

export const MarkerInnerWrapper = styled.div`
    display: flex;
    height: 16px;
`

export default CalendarView
