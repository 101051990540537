import React, { createElement } from 'react';
import styled from 'styled-components'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { BiLink as LinkIcon } from '@react-icons/all-files/bi/BiLink'
import { BiUnlink as UnlinkIcon } from '@react-icons/all-files/bi/BiUnlink'
import { renderToStaticMarkup } from "react-dom/server";

export const EditorComponent = (props) => {

    return (
        <Container>
            <Editor
                editorState={props.state}
                toolbarClassName='draft-toolbar2 border-lightimp'
                wrapperClassName='draft-wrapper border-lightimp '
                editorClassName='draft-editor hover-container'
                onEditorStateChange={props.onChange}
                toolbar={{
                    options: ['link'],
                    link: {
                        options: ['link', 'unlink'],
                        link: { className: "task-notes-link" },
                        unlink: { className: "task-notes-unlink" },
                    },
                }}
            />
        </Container>
    );
};

const reactSvgComponentToMarkupString = (Component, props) =>
    `data:image/svg+xml,${encodeURIComponent(
        renderToStaticMarkup(createElement(Component, props))
    )}`;

const Container = styled.div`
    .draft-toolbar2 {
        background-color: transparent;
		border: none;
		border-bottom: solid 1px;
        margin-bottom: 0;

        .task-notes-link {
            /* background-color: transparent;
            filter: invert(1); */
            border: none;
            width: 20px;
            height: 20px;
            padding: 0px;
                content: ${({ defColor }) =>

        `url(${reactSvgComponentToMarkupString(LinkIcon, {
            color: defColor
        })})`};


                &.rdw-option-active {
              
                    content: ${({ color }) =>
        `url(${reactSvgComponentToMarkupString(LinkIcon, {
            color: color
        })})`};
                }
            
        }

        .task-notes-unlink {
            /* background-color: transparent;
            filter: invert(1); */
            border: none;
            width: 20px;
            height: 20px;
            padding: 0px;
          
                content: ${({ defColor }) =>
        `url(${reactSvgComponentToMarkupString(UnlinkIcon, {
            color: defColor
        })})`};
            

            &.rdw-option-active {
              
                    content: ${({ color }) =>
        `url(${reactSvgComponentToMarkupString(UnlinkIcon, {
            color: color
        })})`};
                }
            
        }
    }
`
// const Container = styled.div`
//     /* width: 500px;
//     border: 1px solid #E9ECEF; */
//     // min-height: 92px;
// `
const ToolbarStyles = styled.style`
    background-color: red
`
