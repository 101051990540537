import moment from 'moment'
import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import {BiTimeFive as TimeIcon} from '@react-icons/all-files/bi/BiTimeFive'
import {VariableCallingStore} from '../../../../../../stores/VariableCallingStore'
import {MainStore} from '../../../../../../stores/MainStore'

interface Props {
  startTime: string
}

interface State {
  timeSinceStart: number
}
class CallDuration extends Component<Props,State> {
  interval: any
  constructor(props: any) {
    super(props)

    this.state = {
      timeSinceStart: 0
    }

  }

  componentDidMount() {
    this.resetInterval()
  }

  resetInterval() {
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      const startTime = moment(+new Date(this.props.startTime)).format('X')
      const then = moment
        .unix(startTime)
        .format('DD/MM/YYYY HH:mm:ss.SSS')
      const now = moment().format()
      const timeSinceStart = moment().diff(
        moment(then, 'DD/MM/YYYY HH:mm:ss.SSS'),
      )

      return this.setState({
        timeSinceStart,
      })
    }, 1000)
  }

  componentDidUpdate(prevProps: Props, _: State) {
    if (this.props.startTime !== prevProps.startTime) {
      this.resetInterval()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.interval = null
  }

  render() {
    const {timeSinceStart} = this.state

    const duration = moment.duration(timeSinceStart)
    const hour = Math.floor(duration.asHours())
    const hr = hour < 10 && hour !== 0 ? `0${hour}:` : hour !== 0 ? hour : ''
    const remaining = moment.utc(timeSinceStart).format('mm:ss')
    const timer = `${hr}${remaining}`

    return (
      <DurationWrapper className="button default meeting-duration">
        <RecorderWrapper>
          <TimeIcon />
        </RecorderWrapper>
        {timer}
      </DurationWrapper>
    )
  }
}

const DurationWrapper = styled.div`
  display: flex;
  padding: 4px 7px !important;
  align-items: center;
  min-width: 85px;
`

const RecorderWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`

const Recorder = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: #ff3c3c;
`

export default CallDuration
