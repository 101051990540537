import React from "react"
import styled from "styled-components"
import { MdFolder } from '@react-icons/all-files/md/MdFolder'
import { DropboxFile } from "../types"
import { timeTodayDateElse } from '../../helpers'

interface Props {
	file: DropboxFile,
	openFolder: (folderId: string, folderName: string) => void
	colorScheme: string
}

function ListFileItem(props: Props) {
	const {file, openFolder} = props

	return (
		<Container onClick={() => openFolder(file.path_lower, file.name)}>
			<FolderIcon size={24} />
			<Text>{file.name}</Text>
			<Text></Text>
			<Text>{timeTodayDateElse(file.client_modified)}</Text>
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: 50px 3fr 1fr 1fr;
	min-height: 48px;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer; 

	&:hover {
		background-color: rgba(0,0,0,0.1);
	}
`

const Text = styled.p`
	font-size: 12px;
	word-break: break-all;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

const FolderIcon = styled(MdFolder)`
	fill: #2483f3;
`

export default ListFileItem
