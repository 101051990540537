import React from 'react';
import styled from 'styled-components';
import FormField from '../../_Shared/FormField';
import TextareaAutosize from 'react-autosize-textarea';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import Select from '../../_Shared/Select';
import RDatepicker from '../../_Shared/Datepicker';
import moment from 'moment';

const CreateDeal = (props: any) => {
  const [data, setData] = React.useState({ dealstage: null, closedate: '', amount: 0 });
  const [error, setError] = React.useState('');
  const { UpdateSelf } = props.actions;
  const { getDeals } = props;

  const handleChange = (e: any) => {
    if(e.target.name === "amount") {
        var regex = /^\d+$/;
        if(!regex.test(e.target.value)) return setData({ ...data, amount: 0 });
        setData({
            ...data,
            [e.target.name]: parseInt(e.target.value),
          });
    } else {
        setData({
            ...data,
            [e.target.name]: e.target.value,
          });
    }
  };

  const CreateDeal = async () => { 
    const new_deal = await Api(Endpoints['Hubspot'], Actions['CreateDeal'], props.external_token, { body: { properties: data} });
    //console.log(new_deal);
    if (new_deal.status === 'error') return setError('There was an error creating your deal.');
    await getDeals();
    UpdateSelf({ screen: 'deals' });
  };

  return (
    <CreateDealContainer>
      <Button className='button default' onClick={CreateDeal} ref={props.useRef}>
        Create
      </Button>
      <CustomFormField
        label='Deal Name'
        field={
          <div>
            <TextareaAutosize
              name='dealname'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Deal Name'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Pipeline'
        field={
          <div>
            <Select
              hoverEffect
              fitContent
              name='pipeline'
              placeholder={'Select a pipeline'}
              defaultValue={'default'}
              onChange={handleChange}
              optionsList={[
                {
                  value: 'default',
                  label: 'Sales Pipeline',
                },
              ]}
              selectWrapperStyle={{ zIndex: 4 }}
            />
          </div>
        }
      />

      <CustomFormField
        label='Deal Stage'
        field={
          <div>
            <Select
              hoverEffect
              fitContent
              name='dealstage'
              placeholder={'Select a stage'}
              defaultValue={data.dealstage || ''}
              onChange={handleChange}
              optionsList={[
                {
                  value: 'appointmentscheduled',
                  label: 'Appointment Scheduled',
                },
                {
                  value: 'qualifiedtobuy',
                  label: 'Qualified to Buy',
                },
                {
                  value: 'presentationscheduled',
                  label: 'Presentation Scheduled',
                },
                {
                  value: 'decisionmakerboughtin',
                  label: 'Decision Make Bought-in',
                },
                {
                  value: 'contractsent',
                  label: 'Contract Sent',
                },
                {
                  value: 'closedwon',
                  label: 'Closed Won',
                },
                {
                  value: 'closedlost',
                  label: 'Closed Lost',
                },
              ]}
            />
          </div>
        }
      />
      <CustomFormField
        label='Amount'
        field={
          <div>
            <TextareaAutosize
              name='amount'
              value={data.amount}
              className='textarea hover-container task-title'
              placeholder='Enter Amount'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Close Date'
        field={
          <div>
            <RDatepicker
              name='closedate'
              wrapperClassName='datepicker'
              placeholderText='No due date'
              displayOnly
              enableIcon
              onChangeRaw={(e: any) => e.preventDefault()}
              onSelect={(date) => setData({ ...data, closedate: date.toISOString() })}
              selected={data.closedate ? moment(data.closedate).toDate() : ''}
              onChange={() => {}}
              readOnly
              dateFormat='MMMM dd'
            />
          </div>
        }
      />
      <Error className="error">{error}</Error>
    </CreateDealContainer>
  );
};

export default CreateDeal;

const CreateDealContainer = styled.div`
  display: flex;
  /* margin-top: 20px; */
  flex-direction: column;
`;

const CustomFormField = styled(FormField)`
  align-self: stretch;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const Button = styled.div`
  align-self: flex-start;
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;

const Error = styled.p`
`;
