import React from 'react'
import BackgroundThumbnail from './BackgroundThumbnail'
import Drawer from '@material-ui/core/Drawer'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {backgroundConfig} from '../../VideoProvider/useBackgroundSettings'
import VideoTrack from './VideoTrack'
import {LocalVideoTrack} from 'twilio-video'
import {FiSlash as NoneIcon} from '@react-icons/all-files/fi/FiSlash'
import BlurIcon from '../../../../../../assets/icons/blurbg.svg'
import {Scrollbars} from 'react-custom-scrollbars'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'

type Props = {
  closeModal: Function
}

export default function ModalBackgroundSelection(props: Props) {
  const {isBackgroundSelectionOpen, setIsBackgroundSelectionOpen, localTracks} =
    useMeetingContext()

  const localVideoTrack = localTracks.find(
    (track) => track.kind === 'video',
  ) as LocalVideoTrack | undefined

  const imageNames = backgroundConfig.imageNames
  const images = backgroundConfig.images
  return (
    <div className="room-modal-backdrop" onClick={() => props.closeModal()}>
      <div
        className="room-modal topbar-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <div className="header">Background</div>
          <div>
            <div className="modal-video-track-container">
              {localVideoTrack && (
                <VideoTrack isLocal track={localVideoTrack} />
              )}
            </div>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              style={{height: 'calc(80vh - 320px)'}}
            >
              <div>
                <div className="default-options">
                  <BackgroundThumbnail
                    icon={<NoneIcon />}
                    thumbnail={'none'}
                    name={'None'}
                    label={'None'}
                  />
                  <BackgroundThumbnail
                    icon={<img src={BlurIcon} />}
                    thumbnail={'blur'}
                    name={'Blur'}
                    label={'Blur'}
                  />
                </div>

                <div className="thumb-list">
                  {images.map((image, index) => (
                    <BackgroundThumbnail
                      thumbnail={'image'}
                      name={imageNames[index]}
                      index={index}
                      imagePath={image}
                      key={image}
                    />
                  ))}
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}
