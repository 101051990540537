import React from 'react'
import { FaTrash as TrashIcon } from '@react-icons/all-files/fa/FaTrash'
import { FaLock as LockedIcon } from '@react-icons/all-files/fa/FaLock'
import { IoIosUnlock as UnlockedIcon } from '@react-icons/all-files/io/IoIosUnlock'

import { WidgetContentItem } from '../../_Shared/Elements'
import { DropdownOption } from '../styledcomponents'
import useNotesContext from '../useNotesContext'

interface Props {
    onClose?: () => void
    componentProps?: any
}

function MoreOptions({ onClose, componentProps }: Props) {
    const { updateNotePrivacy } = useNotesContext()
    return (
        <React.Fragment>
            <WidgetContentItem padding={10} onClick={(e: any) => {
                if(componentProps) componentProps?.setOpenDelete(true)
                if(onClose) onClose()
            }}>
                <DropdownOption>
                    <TrashIcon size={12} className="error" /><span className="icon-label">Remove note</span>
                </DropdownOption>
            </WidgetContentItem>
            {
                componentProps?.created_by === componentProps?.userId && componentProps?.isPrivate ?
                <WidgetContentItem padding={10} onClick={(e: any) => {
                    // this.handleNotePrivacy((this.state.activeData?.id) as string, activeIndex, e)
                    updateNotePrivacy({e})
                    if(onClose) onClose()
                }}><DropdownOption>{
                    componentProps?.isPrivate ? 
                        <React.Fragment>
                            <UnlockedIcon size={12} /> 
                            <span className="icon-label">Mark as public</span>
                        </React.Fragment> : 
                        <React.Fragment>
                            <LockedIcon size={12} />
                            <span className="icon-label">Mark as private</span>
                        </React.Fragment>
                    }       
                    </DropdownOption>
                </WidgetContentItem> : ''
            }
        </React.Fragment>
    )
}

export default MoreOptions