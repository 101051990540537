import React from 'react'
import styled from 'styled-components'
import ChatAvatar from '../ChatAvatar'
import moment from 'moment'
import { IChat } from '../types'
import { formatBytes, onDownload } from '../chatHelper'
import { AiOutlineFile } from '@react-icons/all-files/ai/AiOutlineFile'
import Message from './Message'

interface Props {
    chat: IChat
    prev: boolean
    openLightBox: (idx: number) => void
}

function TheirChat(props: Props) {

    const senderName = props.chat.senderInfo?.name || props.chat.sender.name

    
    const body = () => {
        switch (props.chat.type) {
            case 'photos':

                return (
                    <PhotoCont >
                        {
                            props.chat.photos.slice(0, 2).map((p: any, indx: number) =>
                                <img
                                    onClick={() => props.openLightBox(p.index)}
                                    key={indx}
                                    src={p.url}
                                    alt={`img${indx}`}
                                    style={{
                                        width: props.chat.photos.length > 1 ? 125 : 'unset',
                                        objectFit: props.chat.photos.length > 1 ? 'cover' : 'contain',
                                        maxWidth: props.chat.photos.length > 1 ? 125 : 250,
                                        maxHeight: props.chat.photos.length > 1 ? 125 : 250,
                                        cursor: 'pointer'
                                    }}
                                />
                            )
                        }
                        {
                            props.chat.photos.length > 2 && <CountOverlay>
                                +{props.chat.photos.length - 2}
                            </CountOverlay>
                        }
                    </PhotoCont >
                )

            case 'message':
                return <ChatMessage className='bubble'>
                    <Message message={props.chat.message} />
                </ChatMessage>


            case 'files':
                return <ChatMessage className='bubble'>
                    <FileIconWrapper>
                        <FIleType>
                            {props.chat.file.name.substr(props.chat.file.name.lastIndexOf('.') + 1)}
                        </FIleType>
                        <AiOutlineFile size={32} />
                    </FileIconWrapper>
                    <FileName>{props.chat.file.name}</FileName>
                    <FileSize> {formatBytes(props.chat.file.size)}</FileSize>
                    <DownloadButton onClick={() => onDownload(props.chat.file.url, props.chat.file.name)}>
                        Download
                    </DownloadButton>
                </ChatMessage>

            default:
                break;
        }
    }

    return (
        <MyCont prev={props.prev}>
            <ChatAvatar hidden={props.prev} id={props.chat.sender.id} src={props.chat.sender.avatar} />
            <ChatContent>
                {!props.prev && <ChatInfo>
                    {senderName?.length > 12 ? senderName.substring(0, 12) + '..., ' : senderName + ', '}
                    {moment(props.chat.timeStamp).format('h:mm A')}
                </ChatInfo>}
                {body()}
            </ChatContent>
        </MyCont>
    )
}

const DownloadButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: center ;
    margin-top: 5px;
    padding-top: 10px;
    border-top: 2px solid;
    font-weight: bold;
    cursor: pointer;
`

const FileName = styled.span`
    margin-top: auto;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 125px;
`
const FileSize = styled.span`
    font-size: 10px;
`

const FIleType = styled.span`
    position: absolute;
    font-size: 10px;
    margin-top: 5px;
`

const FileIconWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    max-width: 125px;
    margin-bottom: 10px;
`

const CountOverlay = styled.div`
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center ;
    background-color: #000000a1;
    font-size: 25px;
    color: white;
    position: absolute;
    right: 0;
    pointer-events: none ;
`
const PhotoCont = styled.div`
    overflow: hidden;
    border-radius: 15px;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    position: relative;
`


const MyCont = styled.div <{ prev: boolean }>`
    margin: ${({ prev }) => prev ? '1px 10px' : '10px 10px 1px 10px'};
    display: flex;
    justify-content: left;
`

const ChatContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin-left: 10px;
    align-items: flex-start;

    /* div:first-of-type{
        border-bottom-left-radius: 0;
    }

    .bubble:last-child{
        border-top-left-radius: 0;
    }

    .bubble:not(:last-child):not(:first-child):not(:first-of-type){
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    } */
`

const ChatInfo = styled.span`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    font-size: 10px;
`

const ChatMessage = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    color: black;
    background-color: #ddd !important;
    word-break: break-word;

   
`

export default TheirChat