import React, { useEffect } from 'react'
import ReactTable from 'react-table-v6'
import styled from 'styled-components'

import 'react-table-v6/react-table.css'
import LoadingComponent from './LoadingComponent'

const { GlobalState } = require('reflux')

interface Props {
    columns: IColumns[] | []
    data: any[]
    subRowsKey?: string
    showPagination?: boolean
    sortable?: boolean
    loading?: boolean
    noPointer?: boolean
    resizable?: boolean
    TheadComponent?: any
    SubComponent?: (row: any) => void
    onRowClick?: (row: any) => void
    onResizedChange?: (row: any) => void
    defaultPageSize?: number
    wrapperClassName?: string
    withMinHeight?: boolean
}

interface IColumns {
    Header: string | ((props: any) => void)
    accessor: string
    Cell?: (props: any) => void
    Expander?: (props: any) => void
    id?: string
    width?: string | number
    expander?: boolean
    style?: any
}

function Tablev2(props: Props) {
    const { 
        showPagination = true, 
        sortable = true, 
        loading = false, 
        data = [], 
        columns = [], 
        onRowClick, 
        noPointer, 
        defaultPageSize = 10, 
        TheadComponent, 
        SubComponent, 
        subRowsKey, 
        onResizedChange, 
        resizable = true,
        wrapperClassName = '',
        withMinHeight = true
    } = props
    let _tBodyComponent: any = null

    const TbodyComponent = (props: any) => {
        for (let i = 0; i < props.children[0].length; i++) {
          props.children[0][i] = React.cloneElement(props.children[0][i], { minWidth: props.style.minWidth })
        }
  
        return <div className="rt-tbody">{props.children}</div>
    }
  
    const TrGroupComponent = (props: any) => {
        return <div className="rt-tr-group" role="rowgroup" style={{ minWidth: props.minWidth }}>{props.children}</div>
    }

    const handleScroll = (event: any) => {
        let headers = document.getElementsByClassName("rt-thead")
        for (let i = 0; i < headers.length; i++) {
            headers[i].scrollLeft = event.target.scrollLeft
        }
    }

    useEffect(() => {
        _tBodyComponent = document.getElementsByClassName("rt-tbody")[0]
        _tBodyComponent.addEventListener("scroll", handleScroll)

        return () => {
            _tBodyComponent.removeEventListener("scroll", handleScroll)
        }
    }, [])
    
    return (
        <Wrapper className={wrapperClassName} theme={GlobalState.theming.color_scheme} withMinHeight={withMinHeight}>
            <ReactTable
                data={data}
                columns={columns}
                defaultPageSize={defaultPageSize}
                className="-highlight"
                TbodyComponent={TbodyComponent}
                TrGroupComponent={TrGroupComponent}
                TheadComponent={TheadComponent}
                showPagination={showPagination}
                sortable={sortable}
                resizable={resizable}
                loading={loading}
                noDataText={'no records found'}
                loadingText={<LoadingComponent />}
                subRowsKey={subRowsKey}
                SubComponent={SubComponent}
                onResizedChange={onResizedChange}
                getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {

                    return noPointer ? {} : onRowClick ? {
                        style: { cursor: 'pointer' },
                        onClick: (e: any, handleOriginal: any) => {
                            e.preventDefault()

                            if(column?.expander && handleOriginal) {
                                handleOriginal()
                            }

                            const row = rowInfo?.original || rowInfo;
                            if (row && !!!column?.expander) {
                                onRowClick(row);
                            }
                        }
                    } : {}
                }}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div<{withMinHeight?: boolean}>`
    .ReactTable {
        border-radius: 5px;
        font-size: 14px;

        .rt-thead {
            color: #fff;
            overflow-x: hidden;
            overflow-y: auto;
            box-shadow: none;
            border-radius: 5px 5px 0 0;
            padding-top: 7px;
            padding-bottom: 7px;
            font-weight: 500;
        }

        .rt-table {
            overflow: hidden;
        }

        .rt-thead,
        .rt-tbody {
            min-width: auto !important;
        }

        .rt-tbody {
            font-weight: 400;
            max-height: 449px;

            ${props => props.withMinHeight && 'min-height: 449px;'}

            .rt-tr-group {
                .rt-td {
                    padding: 13px;
                }

                .rt-tr-group {
                    background: ${props => props.theme === 'Light' ? 'rgba(255, 130, 0, 0.2)' : 'rgba(0, 139, 255, 0.2)'};
                }
            }
        }

        .rt-tr[data-qnt="0"] > .rt-expandable {
            visibility: hidden;
        }
    }
`

export default Tablev2
