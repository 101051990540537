import * as twilio from 'twilio-video'
import {isMobile} from '../calling/_helpers'
import {CallingProviderName} from './types'

export const getConnectionOptions = (meetingProvider: CallingProviderName) => {
  const connectionOptions: twilio.ConnectOptions = {
    bandwidthProfile: {
      video: {
        mode: 'presentation',
        dominantSpeakerPriority: 'standard',
        trackSwitchOffMode: 'detected',
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'auto',
      },
    },
    dominantSpeaker: true,
    networkQuality: {local: 3, remote: 3},
    maxAudioBitrate: Number(
      meetingProvider === CallingProviderName.TwilioPro
        ? 40000
        : 16000,
    ),
    preferredVideoCodecs: 'auto',
    // [{codec: 'VP8', simulcast: true}]
  }

  // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
  if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000
  }

  return connectionOptions
}