import React, { Component } from 'react'
import styled from 'styled-components'
import { buildImageProxy } from '../../../../../helpers/ApiCaller'
import CustomTip from '../../../../../helpers/CustomTip'
import DeleteImage from '../../../../../loop-widgets/Images/DeleteImage'
import { WidgetActions } from '../../../../../stores/MainStore'



interface Props {
	url: string,
	hash: string,
	viewImage: (ind: number) => void,
	ind: number,
	toDelete: (e: any) => void
}



export class ImageThumbnail extends Component<Props> {


	render() {
		return (
			<ImageThumb
				className="rounded inner"
				// onClick={() =>this.props.imgClicked()}
				src_url={buildImageProxy(
					this.props.url,
					this.props.hash
				)}
				onClick={() => this.props.viewImage(this.props.ind)}
			>
				{<RemoveImageButton
					data-tip
					data-for="remove-button-mini"
					onClick={(e) => { e.stopPropagation(); this.props.toDelete(this.props.url) }}
				>
					&#10005;
				</RemoveImageButton>}

				<CustomTip top={-20} left={-10} class='top-right' place='left' tip='Remove Image' dataFor='remove-button-mini' />
			</ImageThumb>
		)
	}
}

const RemoveImageButton = styled.button`
	width: 25px;
	height: 25px;
	background: rgba(0,0,0,0.2);
	outline: none;
	border: none;
	position: absolute;
	top: 4px;
	right: 4px;
	color: white;
	cursor: pointer;
	font-weight: bolder;
	font-size: 16px;
	display: none;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
`

const ImageThumb = styled.div<{ src_url: string }>`
	width: 150px;
	height: 150px;
	min-height: 140px;
	background-image: url(${props => props.src_url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;
	cursor: pointer;
	position: relative;

	&:hover {
		opacity: 0.85;

		${RemoveImageButton} {
			display: flex;
		}
	}

	@media (max-width: 768px) {
		margin-bottom: 0px;
		margin-right: 15px;
		min-width: 140px;
	}

	@media (max-width: 480px) {
		min-width: 100px;
		min-height: 100px;
		height: 100px;
		width: 100px;
	}
`

export default ImageThumbnail
