import React, { PureComponent, Component } from 'react'

import styled from 'styled-components'
// import { TiMicrophone as VolumeIcon } from '@react-icons/all-files/ti/TiMicrophone'
import VolumeIcon from '/assets/icons/micOn.svg'
import MoreOptionIcon from '/assets/icons/moreVertical.svg'

import LoopApi from '../../../../helpers/LoopApi'
import WaveMeter from '../../../Meeting/Sidebar/WaveMeter'
import CallItemControls, { IconButton } from './CallItemControls'
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from './types'
import { CrownIcon } from './CrownIcon'
import DropdownWrapper from '../../../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import { FaCrown as HostIcon } from '@react-icons/all-files/fa/FaCrown'
import MoreCallItemControls, { IconButtonMore } from './MoreCallItemControls'

interface IProps {
    participant?: {
		avatar_url?: string
		username?: string
		name: string
		id: string
	}
    isCurrentUser?: boolean
	audioTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	videoTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	screenTrack?: MediaStreamTrack | IFakeMediasoupOuterTrack
	preview: boolean
	streamStates: any
	isHost: boolean
	youAreHost: boolean
	meetingName: string
}


interface State {
    
}

export default class CallUserUI extends Component<IProps, State> {
	audioPlayer: any
	videoPlayer: any
	videoAudioPlayer: any

    constructor(props: IProps) {
		super(props)

		this.BindStream = this.BindStream.bind(this)
		this.setSinkIds = this.setSinkIds.bind(this)

		this.checkArea = this.checkArea.bind(this)
		this.handleSetCallComponentSize = this.handleSetCallComponentSize.bind(this)
		this.setWidth = this.setWidth.bind(this)
		this.transferHostAccess = this.transferHostAccess.bind(this)
	}

	componentDidMount() {
		this.BindStream(this.props.audioTrack, this.props.videoTrack)
		this.setSinkIds()
		this.handleSetCallComponentSize()
        window.addEventListener('resize', this.handleSetCallComponentSize)
	}

	UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if(JSON.stringify(this.props.videoTrack) !== JSON.stringify(nextProps.videoTrack) || JSON.stringify(this.props.audioTrack) !== JSON.stringify(nextProps.audioTrack) ) {
			setTimeout(() => {
				this.BindStream(nextProps.audioTrack, nextProps.videoTrack)
			}, 0)
			this.setSinkIds()
		}
		this.handleSetCallComponentSize()
        window.addEventListener('resize', this.handleSetCallComponentSize)
	}

	shouldComponentUpdate(nextProps: IProps) {
		return JSON.stringify(this.props.videoTrack) !== JSON.stringify(nextProps.videoTrack) || JSON.stringify(this.props.audioTrack) !== JSON.stringify(nextProps.audioTrack) || JSON.stringify(nextProps.streamStates) !== JSON.stringify(this.props.streamStates) || this.props.isHost !== nextProps.isHost || this.props.youAreHost !== nextProps.youAreHost ||
		nextProps.meetingName !== this.props.meetingName || nextProps.screenTrack !== this.props.screenTrack
	}

	componentWillUnmount() {
        window.removeEventListener('resize', this.handleSetCallComponentSize)
	}

	checkArea(Increment: number, Count: number, Width: number, Height: number, Margin = 10) {
        let i = 0, w = 0 ;
        let h = Increment * 0.75 + (Margin * 2);
        while (i < (Count)) {
            if ((w + Increment) > Width) {
                w = 0;
                h = h + (Increment * 0.75) + (Margin * 2);
            }
            w = w + Increment + (Margin * 2);
            i++;
        }
        if (h > Height) return false;
        else return Increment;
    }

	handleSetCallComponentSize() {
		let Margin = 10;
		let Wrapper = document.getElementById('participantsWrapper');
		let Width = 0;
		let Height = 0;
		if(Wrapper) {
			Width = Wrapper.offsetWidth - (Margin * 2) - 30 - 10;
			Height = Wrapper.offsetHeight - (Margin * 2) - 10;
		}
		let Cameras = document.getElementsByClassName('call-participant');
		let max = 0;
	
		// loop (optimize this)
		let i = 1;
		while (i < 5000) {
			let w = this.checkArea(i, Cameras.length, Width, Height, Margin);
			if (w === false) {
				max =  i - 1;
				break;
			}
			i++;
		}
		max = max - (Margin * 2);
		this.setWidth(max, Margin);
	}

	setWidth(width: number, margin: number) {
        let Cameras = document.getElementsByClassName('call-participant') as any;
        for (var s = 0; s < Cameras.length; s++) {
            Cameras[s].style.width = width + "px";
            Cameras[s].style.margin = margin + "px";
            Cameras[s].style.height = (width * 0.75) + "px";
        }
    }
	

	setSinkIds() {
		if (localStorage.audio_output_device_id) {
			this.videoAudioPlayer &&
				this.videoAudioPlayer.setSinkId(localStorage.audio_output_device_id)
			this.audioPlayer &&
				this.audioPlayer.setSinkId(localStorage.audio_output_device_id)
		}
	}

	BindStream(
		audioTrack: IProps['audioTrack'],
		videoTrack: IProps['videoTrack'],
	) {
		if (this.videoPlayer) {
			if(this.videoPlayer.srcObject) return
			const stream = new MediaStream()
			const audioSeparateStream = new MediaStream()

			!this.props.isCurrentUser && audioTrack &&
				normalizedCallHelpers.addTrackToStream(audioSeparateStream, audioTrack)
			videoTrack && normalizedCallHelpers.addTrackToStream(stream, videoTrack)

			this.videoPlayer.srcObject = stream
			this.videoAudioPlayer.srcObject = audioSeparateStream
		} else if (this.audioPlayer) {
			const stream = new MediaStream()

			!this.props.isCurrentUser && audioTrack && normalizedCallHelpers.addTrackToStream(stream, audioTrack)

			this.audioPlayer.srcObject = stream
		} else {
		}
	}

	async transferHostAccess() {
		const participant = this.props.participant || ({} as Partial<NonNullable<IProps['participant']>>)
		try {
			await LoopApi(null, 'TransferHost', { participant }, [
				['name', this.props.meetingName],
			])
		} catch (e) {
			console.error(e)
		}
	}


    render() {
		const participant = this.props.participant || ({} as Partial<NonNullable<IProps['participant']>>)
		const {streamStates, screenTrack} = this.props

		if (
			this.props.videoTrack &&
			!normalizedCallHelpers.isTrackPaused(this.props.videoTrack)
		) {
			return (
				<TileWrapper className="call-tile-wrapper">
					{this.props.isHost && <HostWrapper className="host-wrapper">
						<CrownIcon />
					</HostWrapper>}
					<Item className="call-participant rounded">
						<AspectRatioEnforcer>
							<Background avatar_url={participant.avatar_url || ''} />
							<Vid
								playsInline
								autoPlay
								{...{ ref: (el: any) => (this.videoPlayer = el) } as any}
								width="100%"
								height="100%"
							/>
							<audio ref={(r) => (this.videoAudioPlayer = r)} autoPlay />
							{
								this.props.audioTrack && normalizedCallHelpers.isTrackPaused(
									this.props.audioTrack
								) || streamStates.isMicMuted ? <MutedWrapper className={this.props.isCurrentUser ? `muted` : ''} isCurrentUser={this.props.isCurrentUser}>
									<IconButtonMute className="button" muted={true}>
										<img src={VolumeIcon} width={11} />
										<XLine />
									</IconButtonMute>
								</MutedWrapper> : 
								<MutedWrapper className={this.props.isCurrentUser ? `muted` : ''} isCurrentUser={this.props.isCurrentUser}>
									<WaveMeter targetId={participant.id} />
								</MutedWrapper>
							}
							{!this.props.preview && <Name className={`${this.props.isCurrentUser ? 'curname' : 'othername'}`} isCurrentUser={this.props.isCurrentUser}>{this.props.isCurrentUser ? 'You' : participant.name || participant.username || ''}</Name>}
						</AspectRatioEnforcer>
						{
							this.props.isCurrentUser ? 
							<CallItemControls
								isHost={this.props.isHost}
								youAreHost={this.props.youAreHost}
								audioTrack={this.props.audioTrack}
								videoTrack={this.props.videoTrack}
								screenTrack={screenTrack}
							/> : <MoreCallItemControls 
									transferHostAccess={this.transferHostAccess}
									showTransferAccess={!this.props.isHost && this.props.youAreHost}
								/>
						}
					</Item>
				</TileWrapper>
			)
		}

		return (
			<TileWrapper className="call-tile-wrapper">
				{this.props.isHost && <HostWrapper className="host-wrapper">
					<CrownIcon />
				</HostWrapper>}
				<Item className="call-participant rounded">
					<AspectRatioEnforcer>
						<Background avatar_url={participant.avatar_url || ''} />
						{
							this.props.audioTrack && normalizedCallHelpers.isTrackPaused(
								this.props.audioTrack
							) || streamStates.isMicMuted ? <MutedWrapper className={this.props.isCurrentUser ? `muted` : ''} isCurrentUser={this.props.isCurrentUser}>
								<IconButtonMute className="button" muted={true}>
									<img src={VolumeIcon} width={11} />
									<XLine />
								</IconButtonMute>
							</MutedWrapper> : 
							<MutedWrapper className={this.props.isCurrentUser ? `muted` : ''} isCurrentUser={this.props.isCurrentUser}>
								<WaveMeter targetId={participant.id} />
							</MutedWrapper>
						}
						{!this.props.preview && <Name className={`${this.props.isCurrentUser ? 'curname' : 'othername'}`} isCurrentUser={this.props.isCurrentUser}>{this.props.isCurrentUser ? 'You' : participant.name || participant.username || ''}</Name>}
						<audio autoPlay ref={(el) => (this.audioPlayer = el)} />
					</AspectRatioEnforcer>
					{
						this.props.isCurrentUser ?
						<CallItemControls
							isHost={this.props.isHost}
							youAreHost={this.props.youAreHost}
							audioTrack={this.props.audioTrack}
							videoTrack={this.props.videoTrack}
							screenTrack={screenTrack}
						/> : <MoreCallItemControls 
								transferHostAccess={this.transferHostAccess}
								showTransferAccess={!this.props.isHost && this.props.youAreHost}
							/>
					}
				</Item>
			</TileWrapper>
		)
    }
}

const TileWrapper = styled.div`
	position: relative;
	padding: 6px 20px 0;

	@media (max-width: 970px) {
		padding: 20px 0;
	}
`

export const IconButtonOption = styled.div<{ muted?: boolean, active?: boolean }>`
	position: absolute;
	padding: 16px !important;
	font-size: 15px;
	color: white;
    cursor: pointer;
    z-index: 2;
`

const NewItem = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 5px 4px;
	font-weight: 400;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	& > img, & > svg {
		margin-right: 8px;
	}

	&:hover {
		cursor: pointer;
	}
`




const Dropdown = styled(DropdownWrapper)`
	.dropdown-content {
		position: fixed; 
		transform: translate(5px, -15px);
		bottom: auto;
	}

	@media (max-width: 970px) {
		.dropdown-content {
			position: fixed; 
			transform: translate(5px, -15px);
			bottom: auto;
		}
	}

`

const HostWrapper = styled.div`
	position: absolute;
	z-index: 2;
    /* transform: rotate(-45deg) translate(0px, -20px); */
	/* transform: rotate(-45deg) translate(-86px,-59px); */
	transform: rotate(-45deg);
    left: 5px;
    top: 1px;

	svg {
		width: 24px;
	}

	@media (max-width: 970px) {
		/* left: -5px;
		top: 5px; */
		top: 5px;
		left: 2px;
	}
`

const Name = styled.div<{isCurrentUser?: boolean}>`
	position: absolute;
	top: 10px;
	left: 10px;
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	padding: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #fff;
	text-shadow: 0px 0px 2px rgba(0,0,0,0.4);
`

const MutedWrapper = styled.div<{isCurrentUser?: boolean}>`
	position: absolute;
	top: 10px;
	right: 10px;
	text-align: center;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Vid = styled.video`
	height: 100%;
    width: 100%;
	border-radius: 6px;
	transform: scaleX(-1);
	object-fit: cover;
`

const AspectRatioEnforcer = styled.div`
	/* padding-top: 100%; */
	height: inherit;
    width: inherit;
	background-color: black;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const Background = styled.div<{ avatar_url: string }>`
	background-image: url('${(props) => props.avatar_url}');
	background-size: cover;
	background-position: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
`

const Item = styled.div`
	width: inherit;
	height: inherit;
	overflow: hidden;
	position: relative;

	&:hover {
		${IconButton}, ${IconButtonMore} {
			opacity: 1;
		}
	}
`

export const IconButtonMute = styled.div<{ muted?: boolean }>`
	color: white;
	padding: 5px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 4px;
	border-radius: 2px;
	font-size: 14px;
	transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
	opacity: 0;
	position: relative;
	${(props) => (props.muted ? 'opacity: 1!important;' : '')} 
	cursor: pointer;
`

const XLine = styled.div`
	position: absolute;
	height: 1px;
	width: 16px;
	background-color: #fff;
	transform: rotate(135deg);
	pointer-events: none;
`
