import React, { ReactNode } from 'react'
import styled from 'styled-components'

import AnimatedGrapl from "../../assets/loaders/Grapl-Icon-Animation-250.gif";

function LoadingComponent({ info = '', isAbsolute = true, width = '100%', height = '100%', className = '' }: { info?: ReactNode, isAbsolute?: boolean, width?: string, height?: string, className?: string }) {
    return (
        <Container isAbsolute={isAbsolute} width={width} height={height} className={className}>
            <img src={AnimatedGrapl} width={60} height={60} />
            {info && <div className='container content info'>{info}</div>}
        </Container>
    )
}


const Container = styled.div<{ isAbsolute: boolean, width: string, height: string }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ isAbsolute }) => isAbsolute && 'position: absolute; z-index: 2;'}
  flex-direction: column;

  .info {
      margin-top: 20px;
      font-weight: 500;
      background: transparent !important;
  }
`

export default LoadingComponent
