import { isPlainObject } from 'is-plain-object'

import {
  RemoteTrackPublication,
  RemoteTrack,
  LocalTrackPublication,
  LocalTrack,
  Track,
  TrackPublication,
  RemoteAudioTrack,
  RemoteVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
} from 'twilio-video'

export const getNonNullTracks: (
  participantTracks: (RemoteTrackPublication | LocalTrackPublication)[],
) => RemoteTrack[] = (participantTracks: RemoteTrackPublication[]) => {
  const existingPublications = Array.from(participantTracks.values())
  const existingTracks = existingPublications.map(
    (publication: RemoteTrackPublication) => publication.track,
  )
  const nonNullTracks = existingTracks.filter(
    (track): track is RemoteTrack => track !== null,
  )

  return nonNullTracks
}

export const getMediaDevices: () => Promise<{
  video: MediaDeviceInfo[]
  audio: MediaDeviceInfo[]
  audioOutput: MediaDeviceInfo[]
}> = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput' && device.label !== '',
        )
        const audioDevices = devices.filter(
          (device) => device.kind === 'audioinput' && device.label !== '',
        )
        const audioOutputDevices = devices.filter(
          (device) => device.kind === 'audiooutput' && device.label !== '',
        )

        if (
          !localStorage.getItem('audio_output_device_id') &&
          audioOutputDevices[0]
        ) {
          localStorage.setItem(
            'audio_output_device_id',
            audioOutputDevices[0].deviceId,
          )
        }
        if (!localStorage.getItem('audio_input_device_id') && audioDevices[0]) {
          localStorage.setItem(
            'audio_input_device_id',
            audioDevices[0].deviceId,
          )
        }
        if (!localStorage.getItem('video_input_device_id') && videoDevices[0]) {
          localStorage.setItem(
            'video_input_device_id',
            videoDevices[0].deviceId,
          )
        }

        resolve({
          video: videoDevices,
          audio: audioDevices,
          audioOutput: audioOutputDevices,
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const AudioContext = window.AudioContext || (window as any).webkitAudioContext
const audioContext = AudioContext ? new AudioContext() : null // warning: The AudioContext was not allowed to start. It must be resumed (or created) after a user gesture on the page. https://goo.gl/7K7WLu

function rootMeanSquare(samples: Uint8Array) {
  const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0)
  return Math.sqrt(sumSq / samples.length)
}

export const pollAudioLevel = async (
  track: RemoteAudioTrack | LocalAudioTrack | MediaStreamTrack,
  onLevelChanged: Function,
) => {
  if (!audioContext) {
    return
  }

  // Due to browsers' autoplay policy, the AudioContext is only active after
  // the user has interacted with your app, after which the Promise returned
  // here is resolved.
  await audioContext.resume()

  // Create an analyser to access the raw audio samples from the microphone.
  const analyser = audioContext.createAnalyser()
  analyser.fftSize = 1024
  analyser.smoothingTimeConstant = 0.5

  // Connect the LocalAudioTrack's media source to the analyser.
  const stream = new MediaStream([((track as RemoteAudioTrack | LocalAudioTrack)?.mediaStreamTrack || track)])
  const source = audioContext.createMediaStreamSource(stream)
  source.connect(analyser)

  const samples = new Uint8Array(analyser.frequencyBinCount)
  let level: number | null = null

  // Periodically calculate the audio level from the captured samples,
  // and if changed, call the callback with the new audio level.
  let id2: any = null
  let id3: any = null
  const id = requestAnimationFrame(function checkLevel() {
    analyser.getByteFrequencyData(samples)
    const rms = rootMeanSquare(samples)
    const log2Rms = rms && Math.log2(rms)

    // Audio level ranges from 0 (silence) to 10 (loudest).
    const newLevel = Math.ceil((10 * log2Rms) / 8)
    if (level !== newLevel) {
      level = newLevel
      onLevelChanged(level)
    }

    // Continue calculating the level only if the audio track is live.
    if (((track as RemoteAudioTrack | LocalAudioTrack).mediaStreamTrack || track).readyState === 'live') {
      id2 = requestAnimationFrame(checkLevel)
    } else {
      id3 = requestAnimationFrame(() => onLevelChanged(0))
    }
  })

  return {
    stop: () => {
      cancelAnimationFrame(id)
      cancelAnimationFrame(id2)
      cancelAnimationFrame(id3)
    },
  }
}

export const mapToArray = (map: Map<any, any>) => {
  return Array.from(map.values())
}

export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false
  }

  return /Mobile/.test(navigator.userAgent)
})()

export const isMobileOrNotIpad = (() => {
  console.log(navigator.userAgent.toLowerCase().indexOf('ipad'))
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string' || 
    navigator.userAgent.toLowerCase().indexOf('ipad') !== -1
  ) {
    return false
  }

  return /Mobile/.test(navigator.userAgent)
})()

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj

  const target: {[name: string]: any} = {}

  for (const key in obj) {
    const val = obj[key]
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val)
    }
  }

  return target as T
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices()

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(
      (device) => device.kind === 'audiooutput',
    ),
    hasAudioInputDevices: devices.some(
      (device) => device.kind === 'audioinput',
    ),
    hasVideoInputDevices: devices.some(
      (device) => device.kind === 'videoinput',
    ),
  }
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({name})
      return result.state === 'denied'
    } catch {
      return false
    }
  } else {
    return false
  }
}

export const keyBy = (array: any, key: string) =>
  (array || []).reduce((r: any, x: any) => ({...r, [key ? x[key] : x]: x}), {})

export const parseQuotedStrings = (val: any) => {
  if (typeof val !== 'string') {
    return val
  }

  if (val[0] === '"' && val[val.length - 1] === '"') {
    return JSON.parse(val)
  }

  return val
}

export const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  } else if ((/iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return "iOS"
  }

  return "Other"
}