// Modules
import React, { Component } from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

// Icons
import { FaFolder } from "@react-icons/all-files/fa/FaFolder";
import { FaFolderPlus } from "@react-icons/all-files/fa/FaFolderPlus";
import { FaFileUpload } from "@react-icons/all-files/fa/FaFileUpload";
import { BsFillFolderSymlinkFill as MoveFileIcon } from "@react-icons/all-files/bs/BsFillFolderSymlinkFill";

// Components

// Styled Components
import {
  FlexRow,
  FolderRow,
  IconButton,
  IconContainer,
  Name,
} from "./StyledComponents";

// Types
import { IItemAction } from "./types";
import CustomTip from "../../helpers/CustomTip";

interface Props {
  actions: IItemAction[];
  onClick?: Function;
}

export default class ItemActions extends React.Component<Props> {
  render() {
    const { onClick = () => { } } = this.props;
    return (
      <FlexRow onClick={(e) => onClick()}>
        {this.props.actions.map((action, key: number) => {
          return (
            <>
              <CustomTip top={-20} left={-20} class='top-right' place='left' tip={action.label} dataFor={action.label} />
              <IconButton
                className="topbar"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!action.disabled) {
                    action.onClick();
                  }
                }}
                disabled={action.disabled}
                key={key.toString()}
                data-tip
                data-for={action.label}
                style={{ padding: '4px 10px' }}
              >
                <IconContainer>{action.icon}</IconContainer>
                <Name></Name>
              </IconButton>
            </>
          );
        })}
      </FlexRow>
    );
  }
}
