import React, {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video'
import Sharedb from 'sharedb/lib/client';
import {ErrorCallback} from './types'

import useLocalTracks from './useLocalTracks'
// import useBackgroundSettings, {
//   BackgroundSettings,
// } from './useBackgroundSettings'

import useHandleRoomDisconnection from './useHandleRoomDisconnection'
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed'
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange'
import useScreenShareToggle from './useScreenShareToggle'
import LoopApi from '../../../../../helpers/LoopApi'
import Sagas from '../../../../../helpers/Sagas'
import {getDeviceInfo, keyBy} from '../../../../../calling/_helpers'
// import {forEach} from 'lodash.clonedeep/node_modules/@types/lodash'
import {
  PREVIOUS_AUDIO_INPUT_KEY,
  PREVIOUS_AUDIO_OUTPUT_KEY,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_AUDIO_OUTPUT_KEY,
} from '../../../../../calling/_constants'
import useLocalStorage from '../hooks/useLocalStorage'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import { DailyCall } from '@daily-co/daily-js'
import { CallingProviderName } from '../../../../../contexts/types'
import useToastify from '../../../../../helpers/useToastify'
import InitializingRecording from '../../../../../components/Notifications/InitializingRecording'
import ReconnectingWebSocket from 'reconnecting-websocket';
// import useLocalTracks from '../../../../../contexts/useLocalTracks'
const {GlobalState} = require('reflux')

type SessionDataType = {
  cameraFlipStatus: {[key: string]: boolean}
}


const Connection = Sharedb.Connection
const shareDbsocket = new ReconnectingWebSocket(process.env.REACT_APP_SHAREDB_SOCKET_URL || 'wss://localhost:8080')
const connection = new Connection(shareDbsocket as any)

export interface IVideoContext {
  // room: Room | null
  localTracks: (LocalAudioTrack | LocalVideoTrack)[]
  onError: ErrorCallback
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions,
  ) => Promise<void | LocalVideoTrack>
  getLocalAudioTrack: (deviceId?: string) => Promise<void | LocalAudioTrack>
  isAcquiringLocalTracks: boolean
  removeLocalVideoTrack: () => void
  removeLocalAudioTrack: () => void
  isSharingScreen: boolean
  toggleScreenShare: () => void
  getAudioAndVideoTracks: () => Promise<void>
  isBackgroundSelectionOpen: boolean
  setIsBackgroundSelectionOpen: (value: boolean) => void
  // backgroundSettings: BackgroundSettings
  // setBackgroundSettings: (settings: BackgroundSettings) => void

  currentWidgetName: string | null
  setActiveWidget: (name: string | null) => void
  setActiveSinkId: (sinkId: string | null) => void
  activeSinkId: string | null
  users: any[]
  host: string

  recordMeeting: Function
  // meeting: any

  shouldRecord: boolean

  setCameraFlipped: Function

  sessionData: SessionDataType
}

export const VideoContext = createContext<IVideoContext>(null!)

interface VideoProviderProps {
  // onError: ErrorCallback
  children: ReactNode
  // room: Room
  currentWidgetName: string | null
  setActiveWidget: () => void
  setActiveSinkId: (activeSinkId: string) => void
  activeSinkId: string | null
  users: any[]
  host: string
  // meeting: any
}

export function VideoProvider({
  // room,
  children,
  // onError = () => {},
  currentWidgetName,
  setActiveWidget = () => {},
  users,
  host,
  setActiveSinkId = (activeSinkId: string) => {},
  activeSinkId,
  // meeting,
}: VideoProviderProps) {
  const {onError, room, callProvider, session } = useMeetingContext()
  const onErrorCallback: ErrorCallback = useCallback(
    (error: any) => {
      console.error(`ERROR: ${error.message}`, error)
      onError(error)
    },
    [onError],
  )

  const [audioInputId, setAudioInputId] = useLocalStorage(
    SELECTED_AUDIO_INPUT_KEY,
    '',
  )

  const [audioOutputId, setAudioOutputId] = useLocalStorage(
    SELECTED_AUDIO_OUTPUT_KEY,
    '',
  )

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks()

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError,
    callProvider
  )

  // This returns the type of the value that is returned by a promise resolution
  type ThenArg<T> = T extends PromiseLike<infer U> ? U : never

  const audioDevices = useRef<MediaDeviceInfo[] | null>(null)

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  )
  useHandleTrackPublicationFailed(room, onError, callProvider)
  useRestartAudioTrackOnDeviceChange(localTracks)

  // recording status
  const [shouldRecord, setShouldRecord] = useState(false)
  // session
  const [sessionData, setSessionData] = useState<SessionDataType>({
    cameraFlipStatus: {},
  })


  // @ts-ignore
  var doc = connection.get('MeetingSession', session?.session_id)

  useEffect(() => {
    // START SHAREDB
    const init = () => {
      doc.subscribe((error: any) => {
        if (error) return console.error(error)

        if (!doc.type) {
          doc.create({ cameraFlipStatus: {} }, (error: any) => {
            if (error) console.error(error)
          })
        } else {
          setSessionData({...doc.data})
          setCameraFlipped(true)
        }
      })

      doc.on('op', (op: any) => {
        setSessionData({...doc.data})
      })
    }

    if(session) {
      init()
    }
  }, [session])

  const localAudioTrack = localTracks.find(
    (track) => track.kind === 'audio',
  ) as LocalAudioTrack

  // Mark: Audio Source Change Handler
  // useEffect(() => {
  //   // device change

  //   const replaceTrack = (newDeviceId: string) => {
  //     setAudioInputId(newDeviceId)
  //     localAudioTrack?.restart({deviceId: {exact: newDeviceId}})
  //   }

  //   // const getDevices = () => {
  //   //   console.log("HELLO getting devices")
  //   //   getDeviceInfo().then((devices: any) => {
  //   //     const _audioInputDevices = devices.audioInputDevices.filter(
  //   //       (device: any) => device.label,
  //   //     )
  //   //     const _audioOutputDevices = devices.audioOutputDevices.filter(
  //   //       (device: any) => device.label,
  //   //     )

  //   //     const _audioDevices = [..._audioInputDevices, ..._audioOutputDevices]

  //   //     // Compare Previous and Current Audio Devices
  //   //     if (Array.isArray(audioDevices.current)) {
  //   //       const _audioDeviceIds = _audioDevices.map((device) => device.deviceId)
  //   //       const audioDeviceIds = audioDevices.current.map(
  //   //         (device) => device.deviceId,
  //   //       )

  //   //       if (_audioDevices.length > audioDevices.current.length) {
  //   //         const addedDeviceIds = _audioDeviceIds.filter(
  //   //           (deviceId) => !audioDeviceIds.includes(deviceId),
  //   //         )

  //   //         const audioDevicesObject: {[key: string]: MediaDeviceInfo} = keyBy(
  //   //           _audioDevices,
  //   //           'deviceId',
  //   //         )

  //   //         addedDeviceIds.forEach((deviceId) => {
  //   //           const addedDevice = audioDevicesObject[deviceId]

  //   //           if (addedDevice.kind === 'audioinput') {
  //   //             replaceTrack(addedDevice.deviceId)
  //   //             console.log(" HELLO ADDED DEVICE: ", addedDevice.deviceId)
  //   //           } else if (addedDevice.kind === 'audiooutput') {
  //   //             setAudioOutputId(addedDevice.deviceId)
  //   //             setActiveSinkId(addedDevice.deviceId)
  //   //           }
  //   //           //console.log({addedDevice})
  //   //         })
  //   //       } else if (_audioDevices.length < audioDevices.current.length) {
  //   //         const removedDeviceIds = audioDeviceIds.filter(
  //   //           (deviceId) => !_audioDeviceIds.includes(deviceId),
  //   //         )

  //   //         removedDeviceIds.forEach((deviceId) => {
  //   //           const audioDevicesObject: {[key: string]: MediaDeviceInfo} =
  //   //             keyBy(audioDevices.current, 'deviceId')

  //   //           const removedDevice = audioDevicesObject[deviceId]
  //   //           //console.log({removedDevice})
  //   //           if (removedDevice) {
  //   //             if (
  //   //               removedDevice.kind === 'audioinput' &&
  //   //               removedDevice.deviceId === audioInputId
  //   //             ) {
  //   //               console.log(" HELLO REMOVED DEVICE: ", removedDevice.deviceId, audioInputId)
  //   //               replaceTrack('default')
  //   //               setAudioInputId('default')
  //   //             } else if (
  //   //               removedDevice.kind === 'audiooutput' &&
  //   //               removedDevice.deviceId === audioOutputId
  //   //             ) {
  //   //               setAudioOutputId('default')
  //   //               setActiveSinkId('default')
  //   //             }
  //   //           }
  //   //         })

  //   //         //console.log('removed devices', removedDeviceIds)
  //   //       }
  //   //     }

  //   //     audioDevices.current = _audioDevices
  //   //   })
  //   // }

  //   // navigator.mediaDevices.addEventListener('devicechange', getDevices)
  //   // getDevices()

  //   // return () => {
  //   //   navigator.mediaDevices.removeEventListener('devicechange', getDevices)
  //   // }
  // }, [localAudioTrack])

  const setCameraFlipped = (oi: boolean) => {
    const p = ['cameraFlipStatus', (room as Room)?.localParticipant?.identity || (room as DailyCall)?.participants()?.local?.user_name] // path
    doc.submitOp({p, oi})
  }
  // END SHAREDB

  const recordMeeting = async (include: boolean, usr: any, h: any) => {
    if(callProvider === CallingProviderName.DailyCo) {
      (room as DailyCall).startRecording()
        useToastify({
          message: () => InitializingRecording(),
          toastId: 'initializedRecording',
          position: "top-right",
          autoClose: false,
          closeButton: false,
          hideProgressBar: true,
          className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
          bodyClassName: "grow-font-size",
        })
    } else {
      await LoopApi(null, 'UpdateRecordingRules', {
        room_sid: (room as Room)?.sid,
        rules: [{type: include ? 'include' : 'exclude', all: true}],
        isRecordingOngoing: include
      })
      if(include) {
        Sagas.Clients.Emit('main', 'message', { action: 'NotifyRecordingParticipants', identity: GlobalState?.auth?.jwt?.data._id, meetingName: GlobalState?.main?.meetingName })
      }
    }

    setShouldRecord(include)

    if(callProvider !== CallingProviderName.DailyCo) {
      const getUser = (id: string) => usr.find((u: any) => u.id === id)
      const me = getUser(h || host)
      Sagas.Clients.Emit('main', 'message', {
        action: 'ToggleRecording',
        currentUser: me,
        onoff: include,
        users,
      })
    }

    // setShouldRecord(include)
  }

  const handleToggleRecording = (e: any) => {
    const { detail } = e
    setShouldRecord(detail.onoff)
  }

  // Mount
  useEffect(() => {
    const cdm = async () => {
      if (window) {
        window.addEventListener('TOGGLE_RECORDING', handleToggleRecording)
      }
    };

    cdm();

    return () => {
      window.removeEventListener('TOGGLE_RECORDING', handleToggleRecording)
    };
  }, []);

  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] =
    useState(false)
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack | undefined
  // const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(
  //   videoTrack,
  //   room,
  // )

  return (
    <VideoContext.Provider
      value={{
        // room,
        onError: onErrorCallback,

        // tracks
        localTracks,
        getLocalAudioTrack,
        getLocalVideoTrack,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        getAudioAndVideoTracks,

        // screenshare
        isSharingScreen,
        toggleScreenShare,

        // background
        isBackgroundSelectionOpen,
        setIsBackgroundSelectionOpen,
        // backgroundSettings,
        // setBackgroundSettings,

        // widgets
        currentWidgetName,
        setActiveWidget,

        // global
        setActiveSinkId,
        activeSinkId,

        users,
        host,

        recordMeeting,

        shouldRecord,

        // camera flip
        setCameraFlipped,
        sessionData,
      }}
    >
      {children}
    </VideoContext.Provider>
  )
}
