import React, {useCallback, useRef} from 'react'
import {FiVideo as VideoOn} from '@react-icons/all-files/fi/FiVideo'
import {FiVideoOff as VideoOff} from '@react-icons/all-files/fi/FiVideoOff'

import useDevices from '../../hooks/useDevices'
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle'
import CustomTip from '../../../../../../helpers/CustomTip'

export default function ToggleVideoButton(props: {
  disabled?: boolean
  className?: string
  isMobile?: boolean
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const lastClickTimeRef = useRef(0)
  const {hasVideoInputDevices} = useDevices()

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideoEnabled()
    }
  }, [toggleVideoEnabled])

  return (
    <>
      <button
        data-tip
        data-for='vid'
        className={`button ${isVideoEnabled ? 'default' : 'danger'} controlbutton`}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
      >
        {!hasVideoInputDevices ? (
          <VideoOff />
        ) : isVideoEnabled ? (
          <VideoOn />
        ) : (
          <VideoOff />
        )}
      </button>
      {!!!props.isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip={`${isVideoEnabled ? 'Turn Off Camera' : 'Turn On Camera'}`}
          dataFor="vid"
        />
      )}
    </>
  )
}
