import React from 'react';
import styled from 'styled-components';
import FormField from '../../_Shared/FormField';
import TextareaAutosize from 'react-autosize-textarea';
import Api, { Actions, Endpoints } from '../../_Shared/Api';

const CreateCompany = (props: any) => {
  const [data, setData] = React.useState({ company: null, industry: null });
  const [error, setError] = React.useState('');
  const { UpdateSelf } = props.actions;
  const { getCompanies } = props;

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


  const CreateCompany = async () => {
    const new_company = await Api(Endpoints['Hubspot'], Actions['CreateCompany'], props.external_token, { body: { properties: data } });
    //console.log(new_company);
    if (new_company.status === 'error') return setError('There was an error creating your company.');
    await getCompanies();
    UpdateSelf({ screen: 'companies' });
  };


  return (
    <CreateCompanyContainer>
      <Button className='button default' onClick={CreateCompany} ref={props.useRef}>
        Create
      </Button>
      <CustomFormField
        label='Domain Name'
        field={
          <div>
            <TextareaAutosize
              name='domain'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Domain Name'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Name'
        field={
          <div>
            <TextareaAutosize
              name='name'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Company Name'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      
      <CustomFormField
        label='Industry'
        field={
          <div>
            <TextareaAutosize
              name='industry'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Industry'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='City'
        field={
          <div>
            <TextareaAutosize
              name='city'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter City'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='State'
        field={
          <div>
            <TextareaAutosize
              name='state'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter State'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Phone'
        field={
          <div>
            <TextareaAutosize
              name='phone'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Phone'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <Error className="error">{error}</Error>
    </CreateCompanyContainer>
  );
};

export default CreateCompany;

const CreateCompanyContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const CustomFormField = styled(FormField)`
  align-self: stretch;
`;

const Button = styled.div`
  align-self: flex-start;
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;

const Error = styled.p`
`;
