const { GlobalState } = require('reflux')
import { createActions, Store } from 'reflux'
import LoopApi from '/helpers/LoopApi'
import useToastify from '../helpers/useToastify'
import { Slide } from 'react-toastify'
import NotificationMessages from '../screens/Meeting2.0/KeynoteSidebar/Dropdowns/NotificationMessages'
import AllNewNotifications from '../screens/Meeting2.0/KeynoteSidebar/Dropdowns/AllNewNotifications'
import { ModalActions } from './ModalStore'

export var NotificationActions = createActions([
	'SetMeetingCall',
	'SetMeetingBadgeCount',
	'UpdateAvailable',
	'WidgetUpdated',
	'PresentedWidget',
	'VD_Bitmap',
	'AskForVDBitmap',
	'SetNotifications',
	'NotificationUpdate',
	'BadgeClick',
	'WidgetBadgeClick',
	'ShowTodo',
	'ShowChat',
	'NotifyRemovedUser',
	'GetMoreNotifications'
])

export class NotificationStore extends Store {
	constructor() {
		super()
		this.state = {
			calls: {},
			badges: {},
			presented_widget: null,
			last_updated_widget: null,
			last_widget_update_time: null,
			last_widget_updater: null,
			VD_Bitmap: null,
			notifications: [],
			userBadges: { count: 0 },
			showTodo: {
				todoId: '',
				taskId: ''
			},
			showChat: false,
			skipCount: 0,
			nextNotification: true
		}
		this.listenables = NotificationActions
	}

	onAskForVDBitmap(want) {
		this.setState({ VD_Bitmap: null })
		LoopApi('main', 'AskForVDBitmap', { want })
	}

	onVD_Bitmap(VD_Bitmap) {
		this.setState({ VD_Bitmap })
	}

	onPresentedWidget(presented_widget) {
		if (presented_widget !== this.state.presented_widget) {
			this.setState({ presented_widget })
		}
	}

	onWidgetUpdated(name, updater) {
		const change_obj = { last_widget_update_time: Date.now() }
		if (name && this.state.last_updated_widget !== name) {
			change_obj.last_updated_widget = name
		}
		if (updater && this.state.last_widget_updater !== updater) {
			change_obj.last_widget_updater = updater
		}
		this.setState(change_obj)
	}

	onSetMeetingCall(name, active = true) {
		const calls = { ...this.state.calls }
		calls[name] = active
		this.setState({ calls })
	}

	onSetMeetingBadgeCount(name, count = true) {
		const badges = { ...this.state.badges }
		if (typeof count === 'number') {
			badges[name] = count
		} else {
			badges[name] = (badges[name] || 0) + 1
		}
		this.setState({ badges })
	}

	onUpdateAvailable() {
		//console.log('UPDATE AVAILABLE')
	}

	async onSetNotifications() {

		try {
			const { notifications, userBadges } = await LoopApi(null, 'GetNotifications', {}, [['skip', this.state.skipCount]])	
			this.setState({
				...(notifications ? { notifications } : null),
				...(userBadges ? { userBadges } : null),
				...(notifications && notifications.length < 10 ? {nextNotification: false} : {})
			})
	
			setTimeout(() => {
				if (userBadges && userBadges.count > 0) {
					useToastify({
						message: AllNewNotifications,
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: true,
						transition: Slide,
						pauseOnFocusLoss: false,
						className: GlobalState.theming.color_scheme === 'Light' ? 'toastL topM' : 'toastD topM',
						bodyClassName: "grow-font-size",
						progressClassName: GlobalState.theming.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
					})
				}
			}, 100)
		} catch(err) {
			console.error(err)
		}
	}

	async onGetMoreNotifications() {
		const skip = this.state.skipCount + 10
		const { notifications } = await LoopApi(null, 'GetNotifications', {}, [['skip', skip]])

		if(notifications && notifications.length) {
			this.setState({
				skipCount: skip, 
				notifications: [...this.state.notifications, ...notifications],
				...(notifications.length < 10 ? {nextNotification: false} : {})
			})
			return
		} else {
			this.setState({nextNotification: false})
		}
	}

	onNotificationUpdate(payload) {
		delete payload.event
		delete payload.connection_type

		this.setState({
			notifications: [
				...this.state.notifications,
				...[payload]
			],
			...({
				userBadges:
				{
					count: this.state.userBadges && this.state.userBadges.count ? this.state.userBadges.count + 1 : 1,
					[payload.widget]: this.state.userBadges && this.state.userBadges[payload.widget] ?
						this.state.userBadges[payload.widget] + 1 : 1
				}
			})
		})

		const user = GlobalState.main.users.find((u) => u.id == payload.author)
		useToastify({
			message: NotificationMessages({ notification: payload, user }),
			position: "top-right",
			autoClose: 3000,
			closeButton: false,
			hideProgressBar: true,
			transition: Slide,
			pauseOnFocusLoss: false,
			className: GlobalState.theming.color_scheme === 'Light' ? 'toastL topM' : 'toastD topM',
			bodyClassName: "grow-font-size",
			progressClassName: GlobalState.theming.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD',
		})
	}

	async onBadgeClick() {
		const { query } = await LoopApi(null, 'UpdateNotifications', { emptybadges: true })
		if (query.ok && query.nModified > 0) {
			const keys = Object.keys(this.state.userBadges).filter(x => x !== 'count')
			const toSet = keys.reduce((acc, curr) => {
				return { ...acc, [curr]: 0 }
			}, {})
			this.setState({
				userBadges: {
					count: 0,
					...toSet
				}
			})
		}
	}

	async onWidgetBadgeClick(widget, notification) {
		let payload

		if (this.state.userBadges.count === 0)
			return
		else
			payload = { emptyone: notification._id }

		const { query } = await LoopApi(null, 'UpdateNotifications', payload)

		if (query && query._id) {
			this.setState({
				userBadges: {
					count: this.state.userBadges.count - 1,
					[widget]: this.state.userBadges[widget] - 1
				}
			})
		}
	}

	onShowTodo({ todoId, taskId }) {
		this.setState({ showTodo: { todoId, taskId } })
	}

	onNotifyRemovedUser({ user }) {
		if (user._id === GlobalState.auth.jwt.data._id) {
			return
		}
		ModalActions.SetModal('RemovedUser')
	}

	onShowChat(val = null) {
		if (val === null)
			this.setState({ showChat: !this.state.showChat })
		else
			this.setState({ showChat: val })
	}
}

NotificationStore.id = 'notification'
