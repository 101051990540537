import React from 'react'
import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { AiOutlineOrderedList as OrderedIcon } from '@react-icons/all-files/ai/AiOutlineOrderedList'
import { AiOutlineUnorderedList as UnorderedIcon } from '@react-icons/all-files/ai/AiOutlineUnorderedList'
import { HiLink as LinkIcon } from '@react-icons/all-files/hi/HiLink'
import { AiOutlineUndo as UndoIcon } from '@react-icons/all-files/ai/AiOutlineUndo'
import { AiOutlineRedo as RedoIcon } from '@react-icons/all-files/ai/AiOutlineRedo'

import { NoteToolbar, NoteToolbarBlock, ToolbarButton } from '../styledcomponents'

function Toolbar() {
  return (
    <NoteToolbar id={`custom-notes-toolbar`}>
        <NoteToolbarBlock className="ql-formats">
            <ToolbarButton className="ql-bold container" title="Bold"><BoldIcon title="Bold" /></ToolbarButton>
            <ToolbarButton className="ql-italic container" title="Italic"><ItalicIcon title="Italic" /></ToolbarButton>
            <ToolbarButton className="ql-underline container" title="Underline"><UnderlineIcon title="Underline" /></ToolbarButton>
            <ToolbarButton className="ql-strike container" title="Strikethrough"><StrikeIcon title="Strikethrough" /></ToolbarButton>
            <ToolbarButton className="ql-code-block container" title="Code block"><CodeIcon title="Code block" /></ToolbarButton>
            <ToolbarButton className="ql-blockquote container" title="Blockquote"><QuoteIcon title="Blockquote" /></ToolbarButton>
            <ToolbarButton className="ql-list container" value="ordered" title="Ordered list"><OrderedIcon title="Ordered list" /></ToolbarButton>
            <ToolbarButton className="ql-list container" value="bullet" title="Unordered list"><UnorderedIcon title="Unordered list" /></ToolbarButton>
            <ToolbarButton className="ql-link container" title="Link"><LinkIcon title="Link" /></ToolbarButton>
            <ToolbarButton className="ql-undo container" title="Undo"><UndoIcon title="Undo" /></ToolbarButton>
            <ToolbarButton className="ql-redo container" title="Redo"><RedoIcon title="Redo" /></ToolbarButton>
        </NoteToolbarBlock>
    </NoteToolbar>
  )
}


export default Toolbar