import React, {useState} from 'react'
import styled from 'styled-components'
import getIcon from '../_Shared/FileIcon'
import { FiEdit2 as EditIcon } from 'react-icons/fi'
import { BiTrashAlt as DeleteIcon } from 'react-icons/bi'
import moment from 'moment'
import EditSavedLink from './EditSavedLink'
import SyncModal from '../_Shared/SyncModal'

interface Props {
  externals: any
  updateSelf: Function
  isTravelling: boolean
  urls: any[]
  viewType: string
}

const Urls = (props: Props) => {

  const [editModal, setEditModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)

  const { urls, externals, viewType } = props

  const calcDuration = (duration: any) => {
    return duration.years() ? `${duration.years()}Y` :
    duration.months() ? `${duration.months()}M` :
    duration.weeks() ? `${duration.weeks()}W` :
    duration.days() ? `${duration.days()}D` :
    duration.hours() ? `${duration.hours()}h` :
    duration.minutes() ? `${duration.minutes()}m` :
    duration.seconds() ? `${duration.seconds()}s` :
    '< 1s'
  }

  const setItem = (item: any) => {
    const { external, name, savedTime, linkTitle, linkDescription } = item

    const FileIcon = getIcon(name)
    let IcoType = <FileIcon size={32} />
    const now = moment(new Date()).format()
    let duration = moment.duration(moment(now).diff(moment(savedTime)))
    let link = ''

    if(external === 'googleDrive') {
      const { id, mimeType, iconLink, mtype } = item
      IcoType = mimeType.includes('vnd.google-apps') && !!iconLink ?
                      <img src={iconLink.replace('/16/', '/32/')} /> :
                      <FileIcon size={32} />
      switch (mtype) {
        case 'presentation':
            link = `https://docs.google.com/presentation/d/${id}`
            break
        case 'spreadsheet':
            link = `https://docs.google.com/spreadsheets/d/${id}`
            break
        case 'document':
            link = `https://docs.google.com/document/d/${id}`
            break
        default:
            link = `https://docs.google.com/file/d/${id}`
      }
    }

    return { IcoType, duration, link, linkTitle, linkDescription }
  }

  const saveEdit = ({ title, description, id, external }: { title: string, description: string, id: string, external: string }) => {
    const ex = externals[external]
    const { [id]: str, ...others } = ex
    const toUpdate = {
      ...others,
      [id]: {
        ...ex[id],
        linkTitle: title,
        linkDescription: description
      }
    }

    props.updateSelf({
      externals: {
      ...externals,
      [external]: toUpdate
    }})
    setEditModal(null)
  }

  const deleteLink = () => {
    if(deleteModal) {
      const { id, external } = deleteModal
      const ex = externals[external]
      const { [id]: str, ...others } = ex
      const toUpdate = {
        ...others
      }

      props.updateSelf({externals: {
        ...externals,
        [external]: toUpdate
      }})
      setDeleteModal(null)
    }
  }

  return (
    <>
      {viewType !== 'grid' && urls.map((u, indx) => {
        const { link, IcoType, linkTitle, linkDescription, duration } = setItem(u)
        return (
          <Item key={indx} onClick={() => {window.open(link, '_blank')?.focus()}}>
            {IcoType}
            <div className="details">
              <div className="name">{linkTitle}</div>
              <div className="description">{linkDescription}</div>
              <div className="bottom">
                <span className="time">{calcDuration(duration)} - </span>
                <span className="url">{link}</span>
              </div>
            </div>
            {!props.isTravelling && <div className="options">
              <EditIcon
                className="fs-d"
                onClick={(e) => {
                  if (e.stopPropagation)
                  e.stopPropagation()
                  setEditModal(u)
                }}
              />
              <DeleteIcon
                className="fs-d"
                onClick={(e) => {
                  if (e.stopPropagation)
                  e.stopPropagation()
                  setDeleteModal(u)
                }}
              />
              </div>
            }
          </Item>
        )
      })}

      {viewType === 'grid' &&
        <Grid>
          {urls.map((u) => {
            const { linkTitle, link } = setItem(u)
            return (
              <Wrapper>
                <GridContainer className="topbar" onClick={() => {window.open(link, '_blank')?.focus()}}>
                  <div className="linktitle">{linkTitle}</div>
                </GridContainer>
              </Wrapper>
            )
          })}
        </Grid>
      }

      {editModal &&
        <EditSavedLink
          actionModal={(edit) => saveEdit(edit)}
          closeModal={() => setEditModal(null)}
          editItem={editModal}
        />
      }

      {deleteModal &&
        <SyncModal
          actionModal={() => deleteLink()}
          closeModal={() => setDeleteModal(null)}
          type="FSGDdeleteLink"
        />
      }
    </>
  )
}

const Item = styled.div`
  margin: 30px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 3fr 1fr;

  .details {
		overflow: hidden;
		white-space: nowrap;

    > div {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .name {
      font-weight: 600;
      line-height: 17px;
    }

    .description {
      line-height: 17px;
    }

    .bottom {
      margin-top: 5px;
      line-height: 17px;

      .url {
        font-size: 12px;
      }
    }
  }

  .options {
    justify-self: end;

    .fs-d {
      cursor: pointer;
      height: 17px;
      width: 17px;
      transition: all 200ms ease 0s;
      padding: 5px;
      :hover {
        transform: scale(1.2);
      }
    }
  }
`

const GridContainer = styled.div`
	width: 100%;
	padding: 24px;
	border-radius: 10px;

	.linktitle {
		display: flex;
    justify-content: center;
	}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 0px;
  // grid-column-gap: 28px;
  // margin-left: 13px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  margin-left: 8px;
  margin-top: 8px;
  border-radius: 0;
  opacity: 1;
  cursor: pointer;
`

export default Urls


