import {useState, useEffect} from 'react'
import {LocalVideoTrack, RemoteVideoTrack} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

type TrackType = RemoteVideoTrack | LocalVideoTrack | undefined | null

// The 'switchedOff' event is emitted when there is not enough bandwidth to support
// a track. See: https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api#understanding-track-switch-offs

export default function useIsTrackSwitchedOff(track: TrackType) {
  const [isSwitchedOff, setIsSwitchedOff] = useState(
    track && track.isSwitchedOff,
  )
  const {callProvider} = useMeetingContext()

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  useEffect(() => {
    // Reset the value if the 'track' variable changes
    setIsSwitchedOff(track && track.isSwitchedOff)
    if(isTwilio) {
      if (track) {
        const handleSwitchedOff = () => setIsSwitchedOff(true)
        const handleSwitchedOn = () => setIsSwitchedOff(false)
        track.on('switchedOff', handleSwitchedOff)
        track.on('switchedOn', handleSwitchedOn)
        return () => {
          track.off('switchedOff', handleSwitchedOff)
          track.off('switchedOn', handleSwitchedOn)
        }
      }
    }
  }, [track])

  return !!isSwitchedOff
}
