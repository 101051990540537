import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  show: boolean;
  overlayClicked?: (e?: any) => void;
  noOverlay?: boolean
  closeOnEsc?: () => void
  width?: string
  height?: string
};

export class Modal extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {}

    this.handleOverlayClick = this.handleOverlayClick.bind(this)
  }

  handleOverlayClick(e: any) {
    e.stopPropagation()
    const { overlayClicked = () => { } } = this.props
    overlayClicked()
  }

  escEvent(e: any) {
    if (e.keyCode === 27) {
      //console.log('Esc');
    }

  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escEvent, false);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escEvent, false);
  }

  render() {


    const { noOverlay = false } = this.props

    return !this.props.show ? null : (
      <Overlay onClick={this.handleOverlayClick} noOverlay={noOverlay}>
        <Content width={this.props.width} height={this.props.height} onClick={e => e.stopPropagation()}>{this.props.children}</Content>
      </Overlay>
    );
  }
}

const backDropEntrance = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const modalEntrance = keyframes`
from {
  opacity: 0;
  transform: scale(0.9);
}
to {
  opacity: 1;
  transform: scale(1.0);
}
`;

const Overlay = styled.div<{ noOverlay: boolean }>`
    display: flex;
    align-content: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.noOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.8)'};
    transition: 500ms;
    z-index: 1000;
    animation: ${backDropEntrance} 0.2s ease-out;
`;

const Content = styled.div<{ width?: string, height?: string }>`
    margin-top: auto;
    margin-bottom: auto;
    width: ${p => p.width ? p.width : 'fit-content'} ;
    height: ${p => p.height ? p.height : 'fit-content'} ;
    background-color: transparent;
    z-index: 999;
    animation: ${modalEntrance} 0.2s ease-out;
`;

export default Modal;
