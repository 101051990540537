import React from "react";
import Reflux from "reflux";
import styled from "styled-components";
// import Color from 'color'

import Modal from "../../../src/components/Modalv2";
import LoopApi from '../../helpers/LoopApi'
// import { defaultColors } from '../../../src/stores/ThemingStore'

class ModalFileRename extends Reflux.Component {
  inputRef: any

  constructor(props: any) {
    super(props);

    // refs
    this.inputRef = React.createRef()

    // binds
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      newFileName: this.setFileName(this.props.file.filename),
      isLoading: false
    };
  }

  setFileName = (filename: string) => {
    return (filename === "" || filename.lastIndexOf(".") === -1) ? filename : filename.substr(0, filename.lastIndexOf("."))
  }

  setFileExtension = (filename: string) => {
    return (filename === "" || filename.lastIndexOf(".") === -1) ? "" : filename.substr(filename.lastIndexOf("."))
  }

  componentDidMount() {
    this.inputRef.focus()
    this.inputRef.select()
  }

  async handleSubmit(e: any) {
    e.preventDefault()

    let filename = this.setFileName(this.state.newFileName).trim()
    let extension = this.setFileExtension(this.props.file.filename)

    if (filename) {
			try {
        // Start Loading
        this.setState({
          isLoading: true
        })

        // API Request
        const res = await LoopApi(
          null,
          'UpdateFileFromMeeting',
          { filename: `${filename}${extension}` },
          [ ['name', this.props.meetingName], ['file_id', this.props.file._id] ]
        )

        const filesObj = await this.props.getFiles()
        this.props.UpdateSelf({ files: filesObj });
      } catch (err) {
        console.error(err)
      } finally {
        // End Loading & Close Modal
        this.setState({
          isLoading: false
        }, () => {
          this.props.closeModal()
        })
      }

    }
  }

  render() {
    const { file } = this.props;
    const { filename } = file;
    return (
      <Modal {...this.props}>
        <Container extension={this.setFileExtension(filename)}>
          <div className="name">Rename File</div>
          <form onSubmit={this.handleSubmit}>
            <div className="input-wrapper">
              <input
                className="hover-container border-lightimp"
                type="text"
                value={this.state.newFileName}
                onChange={(e) =>
                  this.setState({
                    newFileName: e.target.value,
                  })
                }
                ref={ref => this.inputRef = ref}
                disabled={this.state.isLoading}
              ></input>
              <div className="name ext">{this.setFileExtension(filename)}</div>
            </div>
            <div className="actions-wrapper">
              <div className="button default" onClick={() => this.props.closeModal()}>Cancel</div>
              <div className="button primary" onClick={(e) => this.handleSubmit(e)}>Save</div>
            </div>
          </form>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div<{extension: string}>`
  width: 100%;
  height: 100%;
  padding: 15px;

  & div.name {
    padding: 8px;
  }

  & div.ext {
    font-weight: 500;
  }

  & > form input {
    text-align: center;
    background: none;
    border-radius: 999px;
    border: 1px solid;
    padding: 10px;
    max-width: ${({extension}) => !!extension ? '100px' : 'none'};

    @media (min-width: 350px) {
      max-width: none;
    }
  }

  & div.input-wrapper,
  & div.actions-wrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  & div.actions-wrapper {
    justify-content: center;

    > div {
      cursor: pointer;
      min-width: 68px;
      padding: 8px 20px;
    }

    > div:first-child {
      margin-right: 5px;
    }
  }

`;

export default ModalFileRename;
