import React, { Component } from 'react'
import styled from 'styled-components'

// Components
import Item from './Item'
import ContextMenu from './ContextMenu'
import EmptyFileContents from '../_Shared/EmptyFileContents'

import { FiEye } from '@react-icons/all-files/fi/FiEye'
import { BiRename as RenameIcon } from '@react-icons/all-files/bi/BiRename'
import { FiMove as MoveIcon } from '@react-icons/all-files/fi/FiMove'
import { FiTrash as RemoveIcon } from '@react-icons/all-files/fi/FiTrash'
import { FiDownload as DownloadIcon } from '@react-icons/all-files/fi/FiDownload'

// Types
import { IFolder, IFilesWidgetState, Item as ItemType } from './types'

interface Props {
  folderArray: [string, IFolder][]
  currentFolder: IFolder | null
  UpdateSelf: Function
  updateState: (
    fn: (prevState: IFilesWidgetState) => IFilesWidgetState,
    cb?: Function,
  ) => void
  filesWidgetState: IFilesWidgetState
  deleteItems: Function
  isTravelling: boolean
  setLastView: (s: string) => void
  users: any
  files: any
}

export default class Folders extends Component<Props> {
  render() {
    const {
      folderArray,
      currentFolder,
      UpdateSelf,
      updateState,
      filesWidgetState,
      deleteItems,
      isTravelling,
    } = this.props
    const { selectedItems, rightClickedItem } = filesWidgetState

    let isPartOfSelected = false

    if (rightClickedItem && 'uuid' in rightClickedItem) {
      console.log({
        selectedIds: selectedItems
          .map((item) => (item as IFolder).uuid)
          .filter((item) => !!item),
        rightclickedId: rightClickedItem.uuid,
      })
      isPartOfSelected = selectedItems
        .map((item) => (item as IFolder).uuid)
        .includes(rightClickedItem.uuid)
    }

    // Filter According to Current Folder
    const folders = folderArray.filter((entry) => {
      const [uuid, data] = entry
      if (currentFolder) {
        return currentFolder.folders.includes(entry[0])
      } else {
        return !data.parentFolderUuid
      }
    })

    if (folders.length === 0) {
      return <EmptyFileContents>No folders found</EmptyFileContents>
    }

    const foldersToRender = folders
      .sort((entry1, entry2) => {
        return (entry1[1].name || '').toLowerCase()
          .localeCompare(entry2[1].name.toLowerCase())
      })
      .map(([uuid, data]: any) => {
        return (
          <ContextMenu
            isEnabled={!isTravelling}
            key={uuid}
            actions={[
              {
                label: 'Open Folder',
                show: true,
                icon: <FiEye />,
                menuItemProps: {
                  data,
                  onClick: (e, data: IFolder) => {
                    console.log('Opening', data)

                    updateState((prevState) => ({
                      ...prevState,
                      currentFolderId: uuid,
                    }))
                  },
                },
              },
              {
                label: 'Rename Folder',
                show: true,
                icon: <RenameIcon />,
                menuItemProps: {
                  data,
                  onClick: (e, data: IFolder) => {
                    console.log('RENAMING', data)
                    updateState((prevState) => ({
                      ...prevState,
                      modals: {
                        ...prevState.modals,
                        renameFolder: true,
                      },
                    }))
                  },
                },
              },
              {
                label: isPartOfSelected
                  ? selectedItems.length > 1
                    ? `Move ${selectedItems.length} item(s)`
                    : 'Move'
                  : 'Move',
                show: true,
                icon: <MoveIcon />,
                menuItemProps: {
                  data,
                  onClick: (e, data: IFolder) => {
                    console.log('MOVING TO', data, { rightClickedItem })
                    updateState((prevState) => ({
                      ...prevState,
                      modals: {
                        ...prevState.modals,
                        fileList: true,
                      },
                    }))
                  },
                },
              },
              {
                label: isPartOfSelected
                  ? selectedItems.length > 1
                    ? `Remove ${selectedItems.length} item(s)`
                    : 'Remove'
                  : 'Remove',
                show: true,
                icon: <RemoveIcon />,
                menuItemProps: {
                  data,
                  onClick: (e, data: IFolder) => {
                    console.log('REMOVING', data)
                    // deleteItems()
                    updateState((prevState) => ({
                      ...prevState,
                      modals: {
                        ...prevState.modals,
                        delete: true,
                      },
                    }))
                  },
                },
              },
            ]}
            contextMenuProps={{
              id: `folder-${uuid}-context-menu`,
              onShow: () => {
                updateState((prevState) => {
                  return {
                    ...prevState,
                    isFromRightClick: true,
                    rightClickedItem: data,
                  }
                })
              },
            }}
          >
            <Item
              isGridView={filesWidgetState.viewType === 'grid'}
              item={data}
              onClick={() => {
                const { metaKey } = filesWidgetState.keyboardKeys

                let isPartOfSelected = selectedItems
                  .map((item) => (item as IFolder).uuid)
                  .includes(data.uuid)

                updateState((prevState) => {
                  return {
                    ...prevState,
                    selectedItems: metaKey
                      ? !isPartOfSelected
                        ? [...prevState.selectedItems, data]
                        : prevState.selectedItems.filter((item) => {
                            if ('_id' in item) {
                              // if file, return true
                              return item
                            } else {
                              // folde
                              return item.uuid !== data.uuid
                            }
                          })
                      : [data],
                  }
                })
              }}
              onDoubleClick={() => {
                updateState(
                  (prevState) => ({
                    ...prevState,
                    selectedItems: [],
                    currentFolderId: uuid,
                  }),
                  () => this.props.setLastView('folder'),
                )
              }}
              disabled={false}
              selected={(selectedItems || [])
                .map((item: IFolder) => item.uuid)
                .includes(uuid)}
              users={this.props.users}
              files={this.props.files}
            />
          </ContextMenu>
        )
      })

    if (filesWidgetState.viewType === 'grid') {
      return <Grid>{foldersToRender}</Grid>
    } else {
      return (
        <List>
          <ColumnHeader>
            <div></div>
            <div>Name</div>
            <div>Owner</div>
            <div>Date Uploaded</div>
          </ColumnHeader>
          {foldersToRender}
        </List>
      )
    }
  }
}

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 0px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnHeader = styled.div`
  display: grid;
  grid-template-columns: 50px 3fr 1fr 1fr;
  padding: 8px 16px;
  min-height: 48px;
  font-size: 13px;
  font-weight: 400;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
