import styled from "styled-components"
import React, {Fragment} from 'react'
import { CrownIcon } from "../screens/Meeting2.0/Sidebar/UsersInCall/CrownIcon"

function RequestHostAccess ({ user, meetingName, isPrevHost, isCurrentHost, denied = false }: any) {
    return  <Fragment>
        <HostPermission>
            <div className="icon color-primary bg-primary-opaque"><CrownIcon /></div>
            <HostPermissionContent>
                <HostPermissionMessage>
                    <div>
						{
							denied ? 
							<React.Fragment>
								Your host access request has been denied
							</React.Fragment> :
							isPrevHost ? 
							<React.Fragment>
								You transferred the host access to <span className="name color-primary">{user?.name || user?.username}</span>
							</React.Fragment> :
							isCurrentHost ?
							<React.Fragment>
								<span className="name color-primary">You&nbsp;</span> are the new host
							</React.Fragment> :
							<React.Fragment>
								<span className="name color-primary">{user?.name || user?.username}&nbsp;</span> is the new host
							</React.Fragment>
						}
                    </div>
                </HostPermissionMessage>
            </HostPermissionContent>
        </HostPermission>
    </Fragment>
}

export default RequestHostAccess;

export const HostButton = styled.div`
	padding: 10px 15px;
    background: red;
    border-radius: 30px;
    color: white;
	flex: 1;
	text-align: center;
    /* flex: 1;
    margin: 0 7.5px 0 15px; */
`
export const HostPermissionContent = styled.div`
	display: flex;    
	justify-content: center;
	flex-direction: column;
    flex: 1;
`

export const HostPermissionMessage = styled.div`
	/* height: 30px; */
	min-height: 30px;
    display: flex;
    align-items: center;

	.name {
		font-weight: 500;
		word-break: break-word;
	}
`

export const HostPermissionButtons = styled.div`
	display: flex;
	justify-content: space-between;
    width: 100%;
	margin-top: 10px;

	.left {
		margin-right: 5px;
	}

	.right {
		margin-left: 5px;
	}
`

export const HostPermission = styled.div`
	display: flex;
	font-weight: 400;

	.icon {
		width: 30px;
		height: 30px;
		padding: 5px;
		border-radius: 30px;
		margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
	}

`