import React from 'react'
import styled from 'styled-components'
import { FiUser } from '@react-icons/all-files/fi/FiUser'
import { ChatContext } from './ChatContext'
import { withMemoizedContext } from 'react-memoized-context';
import { IContext } from './types';
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'


interface Props {
    context: IContext
    updateSync: VoidFunction

}
function ChatHeader({ context, updateSync }: Props) {
    console.log('header', context.chatsDB);

    return (
        <Header>
            {context.roomName}
            <UserIcon>
                <FiUser />
                <span style={{ marginLeft: '2px', fontWeight: 'normal' }}>
                    {context.chatsDB?.users?.length}
                </span>
            </UserIcon>
            <CloseIcon style={{ cursor: 'pointer' }} size={20} onClick={() => { context.seen(); updateSync() }} />
        </Header>
    )
}


const ComponentContexted = withMemoizedContext(ChatContext)(ChatHeader, ['chatsDB', 'roomName', 'updateActiveSync', 'seen']);


export default ComponentContexted

const Header = styled.div`
    display: flex;
    align-items: center ;
    height: 50px;
    padding: 10px 10px 10px 15px;
    font-weight: bold;
`
const UserIcon = styled.div`
    background-color: lightgray;
    padding: 5px;
    margin-left: auto;
    border-radius: 5px;
    display: flex;
    margin-right: 10px;
`