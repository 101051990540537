import { DailyCall, DailyParticipant } from '@daily-co/daily-js'
import React, { useEffect, useState } from 'react'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

interface IState {
    isCameraMuted: boolean
    isMicMuted: boolean
    isSharingScreen: boolean
}

function getStreamStates(room: DailyCall) {
    let isCameraMuted,
      isMicMuted,
      isSharingScreen = false;
    if (
      room &&
      room.participants() &&
      room.participants().local
    ) {
      const localParticipant = room.participants().local;
      isCameraMuted = !localParticipant.video;
      isMicMuted = !localParticipant.audio;
      isSharingScreen = localParticipant.screen;
    }
    return [isCameraMuted, isMicMuted, isSharingScreen];
}

function useStreamStates() {
    const { callProvider, room } = useMeetingContext()
    const [state, setState] = useState<IState>({
        isCameraMuted: false,
        isMicMuted: false,
        isSharingScreen: false
    })

    const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

    useEffect(() => {
        if(isTwilio || !room) return

        function handleNewParticipantsState() {
            const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(
              room as DailyCall
            );
            setState({
                isCameraMuted: isCameraMuted as boolean,
                isMicMuted: isMicMuted as boolean,
                isSharingScreen: isSharingScreen as boolean
            })
        }

        // Use initial state
        handleNewParticipantsState();

        // Listen for changes in state
        (room as DailyCall)?.on("participant-updated", handleNewParticipantsState);

        // Stop listening for changes in state
        return () => {
            (room as DailyCall)?.off("participant-updated", handleNewParticipantsState);
        };

    }, [room, callProvider])

    return { ...state };
}

export default useStreamStates