import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash'
import { FiBell } from '@react-icons/all-files/fi/FiBell'
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown'

import { WidgetContainer, WidgetContentItem } from '../_Shared/Elements'
import WidgetHeader, { Subtitle, Title } from '../_Shared/WidgetHeader'
import FormField from '../_Shared/FormField'
import Table from '../_Shared/Table'
import Dropdown from '../_Shared/DropdownWrapper'

import ShowNotification from '../../helpers/Notification'
import LoopApi from '../../helpers/LoopApi'
import { IWidgetProps, AllWidgets } from '../types'
import { ITask } from './types'
import Tablev2 from '../_Shared/Tablev2'
import EmptyDropboxContents from '../_Shared/EmptyFileContents'
import { LoadingComponentWrapper } from './'
import LoadingComponent from '../_Shared/LoadingComponent'
import useToastify from '../../helpers/useToastify'
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast'
import useClickupContext from './useClickupContext'
import { WidgetActions } from '../../stores/MainStore'

const { GlobalState } = require('reflux')

function Tasks() {
    // const { isHost, checkWhosAsking, data, authFailed, askToBeTheHost } = props
    const { authFailed, external_token, clickupData} = useClickupContext()
    const [taskList, setTaskList] = useState([])
    const [ loading, setLoading ] = useState(false)
    const columns = [
        {
          expander: true,
          accessor: '',
          width: 35,
          Header: '',
          Expander: ({ isExpanded, ...rest }: any) => {
            if (rest?.original?.children?.length > 0) {
              return (
                <div>{isExpanded ? <FiChevronDown /> : <FiChevronRight />}</div>
              );
            }
    
            return <span />;
          }
        },
        {
            Header: 'Task Name',
            accessor: 'name',
            Cell: ({ original }: any) => `${original?.name}`
        },
        {
            Header: 'Assignees',
            accessor: 'assignees',
            Cell: ({ original }: any) => {
                const firstThree = ([...original?.assignees])
    
                if (firstThree.length > 3) {
                    firstThree.length = 3
                }
    
                return original?.assignees && original?.assignees.length > 0 ?
                    <Assignees>
                        {
                            firstThree.length === 3 && original?.assignees.length > 3 ? <ImageWrapper className="topbar" title={'Other assignees'} color="rgba(0, 0,0,0.4)">
                                +{original?.assignees.length - 3}
                            </ImageWrapper> : ''
                        }
                        {
                            ([...firstThree]).reverse().map((assignee: any) => (
                                <ImageWrapper title={assignee?.username || ''} color={assignee?.color || 'rgb(123, 104, 238)'}>
                                    {assignee.profilePicture ? <img src={assignee.profilePicture} alt={assignee.username} /> : assignee.initials}
                                </ImageWrapper>
                            ))
                        }
                    </Assignees> : ''
            }
        },
        {
            Header: 'Due Date',
            accessor: '',
            Cell: ({ original }: any) => {
                const finalOutput = original?.due_date ? moment(original?.due_date, 'x').calendar({
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday]',
                    lastWeek: 'MMM DD',
                    sameElse: 'MMM DD'
                }) : ''
    
                if (finalOutput === 'Today' || finalOutput === 'Tomorrow') return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} title={finalOutput} className="success">{finalOutput}</div>
                if ((finalOutput === 'Yesterday' && !!!original?.date_closed) || (original?.due_date && moment(original?.due_date, 'x').isBefore(moment()) && !!!original?.date_closed)) return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} title={finalOutput} className="error">{finalOutput}</div>
                return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} title={finalOutput}>{finalOutput}</div>
            }
        },
        {
            Header: '',
            accessor: '',
            width: 60,
            Cell: ({ original }: any) => {
                const DropdownContent = (p: any) => {
                    return (
                        <React.Fragment>
                            <WidgetContentItem padding={10} onClick={(e: any) => {
                                deleteTask(original?.id, e)
                                p.onClose()
                            }}><Trash width={16} className="error" />Remove task</WidgetContentItem>
                        </React.Fragment>
                    )
                }
                return <Dropdown items={DropdownContent}><IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} /></Dropdown>
            }
        }
    ]

    let timeout: any = null
    const [ subTableCol, setSubTableCol ] = useState<any>([
        {
            Header: "",
            accessor: "toggle",
            width: 35
        },
        ...columns
    ])

    const taskGrouped = taskList.filter((t: any) => !!!t.parent).reduce((acc: any, obj: any) => {
        const key = obj['status']['status'];
        const color = obj['status']['color'];
        if (!acc[`${key}|${color}`]) {
            acc[`${key}|${color}`] = [];
        }
        acc[`${key}|${color}`].push(obj);
        return acc;
    }, {});

    const keys = Object.keys(taskGrouped)

    const taskChild = taskList.filter((t: any) => !!t.parent).reduce((acc: any, obj: any) => {
        const key = obj['parent'];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});

    const handleDescriptionChange = (event: any) => {
        clearTimeout(timeout);
        const { value } = event.target
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                currentList: { ...(clickupData?.currentList || {}), content: value },
            }
        })
        timeout = setTimeout(() => {
            updateDescription(value)
        },2000);
    }

    const updateDescription = async(content: string) => {
        const d = {
            content,
        }

        await LoopApi(null, 'UpdateClickupListDescription', { ...d }, [['list_id', clickupData?.currentList?.id]])
            .then(async (resp) => {
                if (resp.error) {
                    return authFailed()
                }
                // await ShowNotification({ message: 'Subtask successfully added', type: 'success' })
            })
            .catch((err) => {
                console.error(err)
                return authFailed()
            })
    }

    const listTasks = async() => {
        if (!external_token) { return }

        setLoading(true)
        await LoopApi(null, 'GetClickupTasks', {}, [['list_id', clickupData?.currentList?.id]])
        .then((resp) => {
            setLoading(false)
            if (resp.error) {
                return authFailed()
            }
            console.log("RESP TASKS: ", resp.tasks)
            setTaskList(resp.tasks)
        })
        .catch((err) => {
            console.error(err)
            setLoading(false)
            return authFailed()
        })
    }

    const handleTaskClick = (task: ITask) => {
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                view: 'task',
                currentTask: task,
            }
        })
    }

    const handleBack = () => {
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                view: 'spaces',
                currentList: null,
            }
        })
        // props.actions.UpdateSelf({ list: null, taskList: [] })
    }

    const startNewTask = () => {
        WidgetActions.SetLastView({
            sync: 'clickup',
            data: {
                ...clickupData,
                view: 'task',
                currentTask: { name: '', description: '', id: null  },
            }
        })
    }

    const deleteTask = async(task_id: string | number | undefined, e: any) => {
        e.stopPropagation()
        if (!external_token) { return }

        await LoopApi(null, 'DeleteClickupTask', {}, [['task_id', task_id]])
            .then(async (resp) => {
                if (resp.error) {
                    return authFailed()
                }

                await listTasks()
                useToastify({
                    message: () => SuccessErrorToast({ message: 'Task successfully removed.' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
        
                })
            })
            .catch((err) => {
                console.error(err)
                return authFailed()
            })
    }

    useEffect(() => {
        listTasks()
    }, [external_token, clickupData?.currentList?.id])

    const onResizedChange = (newResized: any) => {
        const columnsWidth = subTableCol.map((column: any) => {
          const currentColumn = newResized.find(
            (resized: any) => resized.id === column.accessor
          );
          if (currentColumn) {
            return {
              ...column,
              width: currentColumn.value
            };
          }
          return column;
        });
        setSubTableCol(columnsWidth);
    };

    return (
        <WidgetContainer padding="30px" height="100%">
            <WidgetHeader
                name={
                    <NameHeader>
                        <Ico>
                            <img src={require("./icon.svg")} />
                        </Ico>
                        <span className="desktop">{clickupData?.currentList?.name || "Tasks List"}</span>
                    </NameHeader>
                }
                subname="You can create, edit, and remove the tasks here"
                subnameClassName="desktop"
                end={
                    <React.Fragment>
                        <Button className="button" onClick={() => handleBack()}>Back</Button>
                        <Button className={`button default`} onClick={() => startNewTask()}>New Task</Button>
                    </React.Fragment>
                }
            />
            <NameHeader className="mobile imp">
                <Title> {clickupData?.currentList?.name || "Tasks List"} </Title>
                <Subtitle className="subheader">{'You can create, edit, and remove the tasks here'}</Subtitle>
            </NameHeader>
            {
                loading ? 
                <LoadingComponentWrapper className="rounded inner topbar" style={{ marginTop: '30px' }}>
                    <LoadingComponent />
                </LoadingComponentWrapper> :
                <React.Fragment>
                    <FormField
                        className="height-flex-auto"
                        label="Description"
                        field={
                            <TextareaAutosize
                                style={{ width: 'calc(100% - 12px)', height: '14px !important' }}
                                value={clickupData?.currentList?.content}
                                className="textarea over-container-focus hover-container focus"
                                placeholder="List description"
                                rows={2}
                                maxRows={6}
                                onResize={(e) => { }}
                                onChange={handleDescriptionChange}
                            />
                        }
                    />
                    <TasksWrapper className="topbar rounded inner">

                    { 
                        keys?.length ? keys?.map((k: any) => {
                            const splitted = k.split("|");
                            const name = splitted?.[0]
                            const color = splitted?.[1]

                            const newArr = taskGrouped?.[k].map((parent: any) => {
                                return {
                                    ...parent,
                                    children: taskChild?.[parent?.id] || []
                                }
                            })

                            return (
                                <TableWrapper>
                                    <TaskName color={color}><div className="status">{name}</div></TaskName>
                                    <Tablev2 
                                        columns={columns}
                                        data={newArr || []}
                                        sortable={false}
                                        defaultPageSize={newArr?.length}
                                        subRowsKey="children"
                                        showPagination={false}
                                        withMinHeight={false}
                                        onRowClick={(row: any) => handleTaskClick(row)}
                                    />
                                </TableWrapper>
                            )
                        }) : <EmptyDropboxContents fullView>No tasks</EmptyDropboxContents>
                    }
                    </TasksWrapper>
                </React.Fragment>
            }
        </WidgetContainer>
    )
}

const TableWrapper = styled.div``

const NotificationWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0px !important;
    cursor: pointer;

    transform: rotate(-45deg);
    animation: shake 1.5s infinite ease-in-out;
    @keyframes shake {
        0% { transform:rotate(-0deg) scale(1) skew(1deg); }
        10% { transform:rotate(-15deg) scale(1) skew(1deg); }
        20% { transform:rotate(15deg) scale(1) skew(1deg); }
        30% { transform:rotate(-15deg) scale(1) skew(1deg); }
        40% { transform:rotate(15deg) scale(1) skew(1deg); }
        50% { transform:rotate(-0deg) scale(1) skew(1deg); }
        100% { transform:rotate(-0deg) scale(1) skew(1deg); }
    }
`

const NameHeader = styled.div`
    display: flex;
    align-items: center;

    &.mobile {
        flex-direction: column;
        align-items: baseline;
        margin-top: 15px;
    }
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};
    margin: 0 2px;

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const ImageWrapper = styled.div<{ color?: string }>`
    width: 25px;
    height: 25px;
    object-fit: cover;
    background: ${props => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;

    img {
        width: 100%;
        border-radius: 50%;
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > div {
        transition: 0.3s transform ease-out;
        border: 1px solid white;
        &:hover {
            z-index: 2;
            border: 1px solid white;
            transform: scale(1.1);
        }
    }
`

const TagWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Tags = styled.div<{ color?: string }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${props => props.color};
    border-radius: 4px;
    color: white;
    padding: 5px;
    font-weight: ${props => props.theme.textRegular};
    margin: 2px auto;
`

const TasksWrapper = styled.div`
    overflow-y: auto;
    height: 100%;
    padding: 15px;

    & > div:first-child {
        margin-top: 0px !important;
    }

    & > div:not(:first-child) {
        margin-top: 20px !important;
    }

    .noborder {
        .ReactTable {
            border: none;
        }
    }

`

const TaskName = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;

    .status {
        text-transform: uppercase;
        background: ${props => props.color};
        font-weight: ${props => props.theme.textBold};
        color: #fff;
        padding: 7px 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }
`

const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 35px;
        border: none;
        align-self: center;
        margin: 0 14px;
    }
`

export default Tasks
