import React, { useState, memo } from 'react'
import styled from 'styled-components'
import moment from 'moment';
import { HiOutlineArrowsExpand as EnterFullscreenIcon } from '@react-icons/all-files/hi/HiOutlineArrowsExpand'
import { BiCollapse as ExitFullScreenIcon } from '@react-icons/all-files/bi/BiCollapse'

import EmptyFileContents from './EmptyFileContents'
import Sprite from './icons.svg'
import 'plyr/dist/plyr.css'
import WidgetSimulator from './WidgetSimulator';
import VideoPlayer from './VideoPlayer'
import History from './History'

const { GlobalState } = require('reflux')

interface Props {
    sItem: any
    users: any
    setSItem: any
}

function RightDisplay(props: Props) {
    const { sItem, users, setSItem} = props
    const [ isFullScreen, setIsFullScreen ] = useState(false)
    const [search, setSearch] = useState('')
    const [showHighlights, setShowHighlights] = useState(false)

    const isTwilio = sItem.provider === 'TWILIO'

    // TRANSCRIPTIONS-RELATED STUFF
    const downloadTranscription = (transcriptions: any) => { 
        let raw_transcriptions = ""; 
        try {
            transcriptions.forEach((t: any) => {
                if(showHighlights) {
                    if(!t.stared) return
                } 
                raw_transcriptions += `[${moment(t?.timestamp).format('hh:mm:ss')}]${users[((Object.keys(users) || []).find((u) => u === t.speaker)) || '']?.username ?? 'user'}: ${t.text}\n`;
            });
        } catch (e) {
            console.log(e.message)
        } 

        const element = document.createElement("a");
        const file = new Blob([raw_transcriptions], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `Transcription_Time-travel_${moment().format('MMDDYYYY_HHMMSS')}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const getSource = () => {
        if (isTwilio) {
            if (sItem.compositionSid && sItem.compositionAvailable) {
                return `${process.env.REACT_APP_BACKEND_URL}/api/webhooks/twilio/records/${sItem.compositionSid}`
            } else {
                return ''
            }
        } else {
            return sItem.uri
        }
    }

    const isTwilioLoadingVideo = sItem.compositionSid && !sItem.compositionAvailable
    const hasHistory = Array.isArray(sItem.history) ? sItem.history.length > 0 : false


    const handleTranscriptionSearch = (event: any) => {
        setSearch(event.target.value)
    }

    return (
        <RightDisplayWrapper className="container rounded inner" isFullScreen={isFullScreen} isSyncDisplay={!!sItem?.data}>
            { !hasHistory && !getSource() && !sItem?.transcriptions && !sItem?.data && <EmptyFileContents folder={false}><React.Fragment>No {!hasHistory && !getSource() ? 'Recordings' : 'Records'} Found</React.Fragment></EmptyFileContents>}
            {/* SYNCS */}
            {
                sItem?.data && <SyncsDisplay>
                    <div className="fullscreen" onClick={() => setIsFullScreen(!isFullScreen)}>{isFullScreen ? <ExitFullScreenIcon /> : <EnterFullscreenIcon />}</div>
                    <WidgetSimulator simulated_widget={sItem.data} users={users}/>
                </SyncsDisplay>
            }
            {/* RECORDINGS */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    { !isTwilioLoadingVideo && <VideoPlayer source={getSource()} /> }
                    { hasHistory && <History history={sItem.history} users={users} />}
                </div>
            {/* TRANSCRIPTIONS */}
            {
                sItem?.transcriptions && <div>

                    <TranscriptionHeader>
                        <div className="title">{sItem?.title}</div>
                        <input className="searchTranscription" placeholder="Search" onChange={handleTranscriptionSearch} />
                        <div className="fullscreen" onClick={() => setIsFullScreen(!isFullScreen)}>{isFullScreen ? <ExitFullScreenIcon /> : <EnterFullscreenIcon />}</div>
                        <div className="download"><div className="button primary" onClick={() => downloadTranscription(sItem.transcriptions)}>Download</div></div>
                        <div className="download"><div className="button primary" onClick={() => setShowHighlights(!showHighlights)}>{showHighlights ? 'Hide' : 'Show'} Highlights</div></div>
                    </TranscriptionHeader>
                    <TranscriptionBody className="rounded inner topbar txnBody">
                        {
                            sItem?.transcriptions.filter((i: any) => i.text.toLowerCase().includes(search.toLocaleLowerCase())).map((t: any, i: number) => {
                                const keys = ((Object.keys(users) || []).find((u: any) => u === t?.speaker)) || ''
                                const you = GlobalState?.auth?.jwt?.data?._id === t?.speaker
                                const name = users[keys]?._id === t?.speaker ? users[keys]?.username : you ? 'You' : 'User'
                                if(showHighlights)  {
                                    if(!t.stared) return <></>
                                }
                                return <TranscriptionContent key={i}>
                                    <Name className={`${you ? 'bg-gradient' : 'container'}`} you={you} title={name}>
                                        <div className="speaker">{name}</div>
                                    </Name>
                                    <TextEntry stared={t.stared}>
                                        {t.text}
                                    </TextEntry>
                                </TranscriptionContent>
                            }) 
                        }
                    </TranscriptionBody>
                </div>
            }

        </RightDisplayWrapper>
    )
};

const SyncsDisplay = styled.div`
    position: relative;

    .fullscreen {
        position: absolute;
        right: 15px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 2;
        font-size: 22px;
    }
`

const TranscriptionContent = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const TranscriptionHeader = styled.div`
    padding:  0 15px;
    display: flex;
    align-items: center;

    .title {
        font-weight: 500;
        flex: 1;
    }

    .fullscreen {
        margin-right: 10px;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .download {
        cursor: pointer;
        margin-right: 5px;
    }

    .searchTranscription {
        border: 1px solid grey;
        background: none;
        outline: none;
        font-size: 13px;
        border-radius: 15px;
        padding: 8px;
        margin-right: 10px;
    }
`

const TranscriptionBody = styled.div`
    margin-top: 15px;
    padding: 15px;
    max-height: calc(100vh - 350px);
    overflow: auto;
`


const Name = styled.div<{you?: boolean}>`
	font-weight: 500;
	margin-right: 4px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-height: 35px;
    margin-top: 5px;

    .speaker {
        width: 86px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        color: ${props => props.you ? 'white' : 'inherit'};
    }
`

const TextEntry = styled.div<{stared: boolean}>`
    padding: 4px;
    flex: 1;
    border-radius: 4px;
    padding-top: 10px;
    background-color: ${props => props.stared ? '#ffff00' : 'none'};
`

const RightDisplayWrapper = styled.div<{isFullScreen: boolean, isSyncDisplay: boolean}>`
    flex: 1;
    padding: 15px;
    margin-left: 15px;
    width: calc(100% - 30px);
    ${({isSyncDisplay}) => isSyncDisplay && 'padding-left: 0; padding-right: 0;' }

    --plyr-color-main: #FF9900;

    .plyr--video.plyr--html5 {    
        height: calc(100vh - 297px);
        border-radius: 10px;
    }

    .plyr__controls {
        background: #363B45;
        border-radius: 150px;
        padding: 7px 10px;
        margin: 15px;
    }

    ${props => props.isFullScreen && `
        position: fixed;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        margin-left: 0;
        box-shadow: ${props.theme.shadows.heavy};
        z-index: 999;

        .txnBody {
            max-height: calc(100vh - 113px);
        }

        .syncBody {
            max-height: calc(100vh - 123px);
            height: calc(100vh - 123px);
        }
    `}
`


export default memo(RightDisplay)
