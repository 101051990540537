import React from 'react'
import styled from 'styled-components'

import Modal from '../../../components/Modalv2'

function UnableToShareScreen(props: any) {

	const onLeave = async () => {
        props.closeModal && props.closeModal()
	}

	return (
		<Modal closeModal={() => {
		}}>
			<Container className="topbar-modal">
					<Contents>
						<Title className="header">Unable to share you screen</Title>
						<Details className="subheader">You might not have permision to use screen recording on your computer. Please configure your settings.</Details>
					</Contents>

					<Options>
						<Button className="button primary" onClick={onLeave}>OK</Button>
					</Options>
				</Container>
		</Modal>
	)
}

export default UnableToShareScreen

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    background-color: white;
    color: #363B45;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
	margin-bottom: 10px;
`

const Button = styled.div<{ disabled?: boolean }>`
    ${props => props.disabled ? 'opacity: 0.3; pointer-events: none;' : ''}
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
    font-size: 14px;
`
