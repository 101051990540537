import moment from 'moment'
import React, { useMemo, useState, useEffect } from 'react'
import { BsThreeDotsVertical as MoreOptionIcon } from '@react-icons/all-files/bs/BsThreeDotsVertical'

import { NotesHeaderWrapper } from '../styledcomponents'
import useNotesContext from '../useNotesContext'
import MoreOptions from './MoreOptions'
import Dropdown from '../../_Shared/DropdownWrapper'
import Modal from '../../_Shared/Modal'
import DeleteNotes from '../modals/DeleteNotes'

interface INotesData { 
    id: string
    title: string
    content: string
    created_by: string
    date_created: string
    date_updated: string
    updated_by: string
    isPrivate: boolean
}

function Header() {
    const { sidebarNotes, activeNote, users, userId, deleteNote, connection } = useNotesContext()
    const [ openDelete, setOpenDelete ] = useState<boolean>(false)
    const [notesData, setNotesData] = useState<INotesData>({
        id: '',
        title: '',
        content: '',
        created_by: '',
        date_created: '',
        date_updated: '',
        updated_by: '',
        isPrivate: false
    })

    useEffect(() => {
        //@ts-ignore
        const note =  connection.get("sharednotessidebar", activeNote);
        function unsubscribeNotesDoc() {
            note.unsubscribe()
        }
        
        note.subscribe(update)
        note.on('op', update)
        function update(err: Error) {
            // if(err) //console.log("Error in notes header: ", err)
            setNotesData({ ...(note?.data || {}) })
        }
        return () => {
            unsubscribeNotesDoc()
        }
    }, [activeNote, userId])


    return useMemo(() => {
        const currentNote = sidebarNotes[activeNote || '']
        if(!currentNote) return <React.Fragment />

        const { date_created, date_updated, created_by, updated_by, isPrivate } = notesData
        const owner = (users || []).find((user: any) => user?.id === created_by)
        const modifiedBy = (users || []).find((user: any) => user?.id === updated_by)
        
        return <React.Fragment>
            <Modal show={openDelete} overlayClicked={() => setOpenDelete(false)}>
                <DeleteNotes
                    handleClose={() => setOpenDelete(false)}
                    handleSubmit={(e: any) => { 
                        deleteNote({ id: activeNote, e })
                        setOpenDelete(false)
                    }}
                />
            </Modal>
            <NotesHeaderWrapper>
                <div className="note-header-left">
                    <div className="notes-date">
                        { 
                            date_updated || date_created ? 
                            moment(date_updated || date_created).format("MMMM DD, YYYY") : 
                            moment().format('MMMM DD, YYYY')
                        } at&nbsp;
                        {
                            date_updated || date_created ? 
                            moment(date_updated || date_created).format("hh:mm A") : 
                            moment().format("hh:mm A")
                        }
                    </div>
                    <div className="notes-updatedby">
                        Created by { owner?.name || owner?.username } |&nbsp;  
                        { 
                            date_created ? 
                            moment(date_created).format("MMMM DD, YYYY") : 
                            moment().format('MMMM DD, YYYY')
                        } at&nbsp;
                        {
                            date_created ? 
                            moment(date_created).format("hh:mm A") : 
                            moment().format("hh:mm A")
                        }
                        <br/>
                        {(modifiedBy?.name || modifiedBy?.username) && `Last updated by ${ modifiedBy?.name || modifiedBy?.username}`}
                    </div>
                </div>
                <div className='note-header-right'>
                    <Dropdown items={MoreOptions} addTop={10} componentProps={{ setOpenDelete, created_by, userId, isPrivate }}>
                        <MoreOptionIcon />
                    </Dropdown>
                </div>
            </NotesHeaderWrapper>
        </React.Fragment>
    }, [activeNote, users, notesData, openDelete])
}

export default Header