import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { IParticipant } from '../../../../calling/types'
import { updateLayout } from '../../../../helpers'
import { AuthStore } from '../../../../stores/AuthStore'

import { MainStore } from '../../../../stores/MainStore'
import { IVariableCallingStoreState, VariableCallingActions, VariableCallingStore } from '../../../../stores/VariableCallingStore'
import CallUserUITwilio from '../../Sidebar/UsersInCall/CallUserUITwilio'
import PresentationUI from '../../Sidebar/UsersInCall/PresentationUI'
import CallTimer from '../CallSlide/CallTiles/CallTimer'
import PresentationTileTwilio from '../CallSlide/CallTiles/PresentationTileTwilio'
import { CallContainer } from '../CallSlide/styles'
import TwilioRoom from './TwilioRoom/TwilioRoom'

type IState = Pick<
	IVariableCallingStoreState,
	'session' | 'current_speaker' | 'participants' | 'current_presenter' | 'call_starter' | 'participantTracks' | 'room' | 'localScreenshareTrack' | 'remoteScreenshareTrack' | 'localVideoTrack' | 'localAudioTrack' | 'host' | 'startTime' | 'transcriptionTrack'
> & {
	users: any[]
	callObject: any
	jwt: any
	meetingName: string
}
declare const window: any;

export default class TwilioCallingSlide extends Reflux.Component {
	constructor(props: never) {
		super(props)
		this.stores = [VariableCallingStore, MainStore, AuthStore]
		this.storeKeys = ['participants', 'users', 'callObject', 'current_presenter', 'call_starter', 'jwt', 'meetingName', 'participantTracks', 'room', 'localScreenshareTrack', 'remoteScreenshareTrack', 'localVideoTrack', 'localAudioTrack', 'host', 'startTime', 'transcriptionTrack']

		this.presentationRef = null
	}
	state: IState
	presentationRef: any

	componentDidMount() {
        updateLayout()

        window?.addEventListener('resize', updateLayout)
    }

    componentDidUpdate(prevProps: never, prevState: IState) {
        const { participants, callObject } = this.state
        const { participants: prevParticipants, callObject: prevCallObject } = prevState

        // if(JSON.stringify(participants) !== JSON.stringify(prevParticipants)) {
            updateLayout()
        // }
    }

    componentWillUnmount() {
        window?.removeEventListener('resize', updateLayout)
    }

	renderLocalScreenshare = () => {

		if (!this.state.room || !this.state.current_presenter || !this.state.localScreenshareTrack) {
			return null
		}

		return (
			// <div id="rightCallWrapper" className="right-call">
			// 	<PresentationUI
			// 		screenTrack={this.state.localScreenshareTrack.mediaStreamTrack}
			// 	/>
			// </div>
			<PresentationTileTwilio screenTrack={this.state.localScreenshareTrack} />
		)

	}

	renderParticipantsScreenshare = () => {

		if (!this.state.room || !this.state.remoteScreenshareTrack) {
			return null
		}

		// find one participant that is screensharing
		//console.log('RENDER SCREENSHARE TRACKS====================')
		//console.log(this.state.remoteScreenshareTrack)

		let sids = this.state.participantTracks.map((participant: any) => {
			return [...participant.videoTracks.values()].map((track: any) => track.trackSid)
		}).flat()

		//console.log({ sids })

		if (!sids.includes(this.state.remoteScreenshareTrack.sid)) {
			VariableCallingActions.SetParticipantScreenshareTrack(null)
			return null
		}

		return (

			<PresentationTileTwilio screenTrack={this.state.remoteScreenshareTrack} />
			// <div id="rightCallWrapper" className="right-call">
			// 	<PresentationUI
			// 		screenTrack={this.state.remoteScreenshareTrack.mediaStreamTrack}
			// 	/>
			// </div>
		)
	}

	renderLocalParticipant = () => {
		if (!this.state.room) {
			return null
		}

		const user = this.state.users.find((u: any) => u.id === (this.state.room as any).localParticipant.identity.split('::')[1])

		const isHost = this.state.host === user.id

		return <CallUserUITwilio
			participant={(this.state.room as any).localParticipant} 
			user={user}
			isLocalParticipant={true}
			localVideoTrack={this.state.localVideoTrack}
			localAudioTrack={this.state.localAudioTrack}
			isHost={isHost}
			room={this.state.room}
			canSharescreen={!this.state.localScreenshareTrack && !this.state.remoteScreenshareTrack}
			isSharingScreen={!!this.state.localScreenshareTrack}
            transcriptionTrack={this.state.transcriptionTrack}
		/>
	}

	renderParticipants = () => {
		if (!this.state.room || !Array.isArray(this.state.participantTracks)) {
			return null
		}

		return this.state.participantTracks.map((participant: any) => {
			const user = this.state.users.find((u: any) => u.id === participant.identity.split('::')[1])
			const isHost = this.state.host === user?.id
			
			return <CallUserUITwilio
				key={participant.identity}
				participant={participant} 
				user={user}
				isLocalParticipant={false}
				isHost={isHost}
				room={this.state.room}
				canSharescreen={!this.state.localScreenshareTrack && !this.state.remoteScreenshareTrack}
			/>
		})
		
	}

	makeScreenPresentation = (id: string | IParticipant) => {
		const part =
		typeof id !== 'string'
			? id
			: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}
		// const streamStates = this.getStreamStates(participant)
		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')

		if(!screenTrack) return
		return (
			// <div id="rightCallWrapper" className="right-call">
			// 	<PresentationUI
			// 		key={`${id}-presentation`}
			// 		screenTrack={screenTrack}
			// 	/>
			// </div>
			<PresentationTileTwilio key={`${id}-presentation`} screenTrack={screenTrack} />
		)
	}

	getStreamStates(participant: any = {}) {
		const { callObject } = this.state;

		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
			callObject &&
			callObject.participants &&
			callObject.participants()
		) {
			const participants = Object.keys(callObject.participants()).map(i => callObject.participants()[i])
			const localParticipant = participants.find((p) => participant.id === p.user_name)
			isCameraMuted = !localParticipant?.video;
			isMicMuted = !localParticipant?.audio;
			isSharingScreen = localParticipant?.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}


	render() {
		// return <div style={{ overflow: 'scroll' }}>
		// 	<h1>Twilio Calling Slide</h1>
		// 	{
		// 		this.state.participantTracks && this.state.jwt && this.state.jwt.data && this.state.room && <TwilioRoom
		// 			participantTracks={this.state.participantTracks}
		// 			localParticipantId={this.state.jwt.data._id}
		// 			room={this.state.room}
		// 		/>
		// 	}
		// </div>
		let current_speaker = this.state.current_speaker
		let current_presenter = this.state.current_presenter
 		if (!current_speaker && this.state.participants.length) {
			current_speaker = this.state.participants[0].userId
		}

		// //console.log("_________ :", this.state.call_starter)
		return (
			<React.Fragment>
				{/* { 
					!!current_presenter ? this.makeScreenPresentation(current_presenter) : 
					(this.state.participants || [])
						.filter((p) => p.userId !== current_speaker)
						.map((p) => {
							return this.makeScreenPresentation(p)
						})  
						
				} */}

					{
						this.state.startTime && <CallTimer startTime={this.state.startTime} />
					}
					{ this.renderLocalScreenshare() }
					{ this.renderParticipantsScreenshare() }
					{/* <pre>{JSON.stringify(this.state.room, null, 2)}</pre>	 */}
					<CallContainer id="callContainer">
						{ this.renderLocalParticipant() }
						{ this.renderParticipants() }
					</CallContainer>
			</React.Fragment>
		)
	}
}

const Outer = styled.div`
	/* height: 100%; */
	width: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	.right-call {
		/* width: 80%; */
		height: 100%;
		min-height: 100%;
		flex: 2;

		& + .left-call {
			/* width: 20%; */
			flex-direction: column;
			align-self: flex-start;

			.call-participant {
				width: 100% !important;
				max-height: 200px !important;
				height: 200px !important;
				width: 200px !important;
				min-width: 200px !important;

				video {
					/* object-fit: */
				}

				.curname {
					top: 10px;
					right: 10px;
					left: auto;
					bottom: auto;
				}

				.muted {
					top: 10px;
					left: 10px;
					right: auto;
					bottom: auto;
				}

				.call-control {
					padding: 11px !important;
					font-size: 16px;
				}
			}
		}
	}


	.left-call {
		display: inline-flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	&.upsidedown {
		flex-direction: column;

		.right-call {
			width: 100%;
			height: 75%;
			min-height: 75%;
		}

		.left-call {
			flex-direction: row;
			align-self: center;
			overflow-x: auto;
			max-width: 100%;
			width: 100%;

			.call-participant {
				width: 100% !important;
				max-height: 150px !important;
				height: 150px !important;
				width: 180px !important;
				min-width: 180px !important;

				video {
					/* object-fit: */
				}
			}
		}

		@media (max-width: 970px) {
			flex-direction: row;

			.right-call {
				flex: 1;
			}

			.left-call {
				width: auto;
				align-self: baseline;
				flex-direction: column;
			}
		}
	}
`
