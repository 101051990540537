import React from "react"
import styled from "styled-components"
import { BsEye as EyeIcon } from "@react-icons/all-files/bs/BsEye"
import { AiOutlineDownload as DownloadIcon } from "@react-icons/all-files/ai/AiOutlineDownload"
import { CgPresentation as PresentIcon } from "@react-icons/all-files/cg/CgPresentation"
import { FiShare2 as ShareIcon } from "@react-icons/all-files/fi/FiShare2"
import EmptyFileContents from "../_Shared/EmptyFileContents"
import ListFileItem from "./ListFileItem"
import GridFileItem from "./GridFileItem"
import { DropboxFile } from "../types"

interface Props {
	view: string,
	files: DropboxFile[]
	downloadFile: Function
	viewFile: Function
	presentFile: Function
	setFileToDownload: (file: DropboxFile) => void
	onPresent: Function
	colorScheme: string
	isPresenterOnline: boolean
}

const browserable = ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'txt', 'jfif', 'gif', 'webp']
const office = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx']


function FileItems(props: Props) {
	const {view, files, downloadFile, viewFile, presentFile, setFileToDownload, onPresent, colorScheme, isPresenterOnline} = props

	const options = [
		{
			value: "download",
			text: "Download File",
			icon: DownloadIcon,
			showCondition() {
				return true
			}
		},

		{
			value: "view",
			text: "View File",
			icon: EyeIcon,
			showCondition(fileName: string) {
				let match = fileName.match(/\.(\w+)$/)
				if (!match || !match[1]) {
					return false
				}
				return (
					browserable.includes(match[1].toLowerCase()) ||
					office.includes(match[1].toLowerCase())
				)
			}
		},

		{
			value: "pdf-presenter",
			text: "To PDF Presenter",
			icon: PresentIcon,
			showCondition(fileName: string) {
				return fileName.endsWith('.pdf')
			}
		},

		...!isPresenterOnline ? [{
			value: "present",
			text: "Present",
			icon: ShareIcon,
			showCondition(fileName: string) {
				let match = fileName.match(/\.(\w+)$/)
				if (!match || !match[1]) {
					return false
				}
				return (
					browserable.includes(match[1].toLowerCase()) ||
					office.includes(match[1].toLowerCase())
				)
			}
		}] : []
	]

	if (files.length === 0) {
		return <EmptyFileContents>No Files Found</EmptyFileContents>
	}

	if (view === "list") {
		const fileItems = files.map(file => {
			const filteredOptions = options.filter(option => option.showCondition(file.name))

			return (
				<ListFileItem
					key={file.id}
					file={file}
					options={filteredOptions}
					downloadFile={downloadFile}
					viewFile={viewFile}
					presentFile={presentFile}
					setFileToDownload={setFileToDownload}
					onPresent={onPresent}
					colorScheme={colorScheme}
				/>
			)
		})

		return <div>{fileItems}</div>
	}

	if (view === "grid") {
		const fileItems = files.map(file => {
			const filteredOptions = options.filter(option => option.showCondition(file.name))

			return (
				<GridFileItem
					key={file.id}
					file={file}
					options={filteredOptions}
					downloadFile={downloadFile}
					viewFile={viewFile}
					presentFile={presentFile}
					setFileToDownload={setFileToDownload}
					onPresent={onPresent}
					colorScheme={colorScheme}
				/>
			)
		})

		return <Grid>{fileItems}</Grid>
	}

	return null
}

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 16px;
	padding: 0 16px;
	margin: 16px 0;

	@media (min-width: 456px){
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (min-width: 768px){
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	@media (min-width: 1101px){
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (min-width: 1300px){
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`

export default FileItems
