import OauthCaller from '../OauthCaller'

const endpoints = {
    Asana: {
        baseURL: 'https://app.asana.com/api/1.0',
        endpoint: {
            ListWorkspaces: {
                url: '/workspaces',
                method: 'GET',
            },
            CreateTask: {
                url: '/tasks',
                method: 'POST',
            },
            UpdateTask: {
                url: '/tasks',
                method: 'PUT',
            },
            DeleteTask: {
                url: '/tasks',
                method: 'DELETE',
            },
            ListTasks: {
                url: '/tasks',
                method: 'GET',
            },
            GetTask: {
                url: '/tasks',
                method: 'GET',
            },
            ListProjects: {
                url: '/projects',
                method: 'GET'
            },
            ListSections: {
                url: '/projects/:project_gid/sections',
                method: 'GET'
            },
            ListUsers: {
                url: '/users',
                method: 'GET'
            },
            UpdateStatus: {
                url: '/tasks',
                method: 'POST'
            }
        } as any
    },
    Dropbox: {
        baseURL: 'https://api.dropboxapi.com/2',
        endpoint: {
            Files: {
                url: '/files/list_folder',
                method: 'POST',
            },
            TemporaryLink: {
                url: '/sharing/create_shared_link_with_settings',
                method: 'POST',
            },
            UnshareLink: {
                url: '/sharing/unshare_file',
                method: 'POST',
            },
            ListShared: {
                url: '/sharing/list_shared_links',
                method: 'POST',
            },
            RevokeLink: {
                url: '/sharing/revoke_shared_link',
                method: 'POST',
            },
            RevokeToken: {
                url: '/auth/token/revoke',
                method: 'POST'
            },
            GetFileMetaData: {
                url: '/sharing/get_file_metadata',
                method: 'POST'
            }
        }
    },
    DropboxContent : {
        baseURL: 'https://content.dropboxapi.com/2',
        endpoint:{
            Upload: {
                url: '/files/upload',
                method: 'POST'
            },
            UploadSession: {
                url: '/files/upload_session',
                method: 'POST'
            },
            Download: {
                url: '/files/download',
                method: 'POST'
            }
        }
    },
    Gists: {
        baseURL: 'https://api.github.com',
        endpoint: {
            ListGists: {
                url: '/gists',
                method: 'GET',
            },
            CreateGist: {
                url: '/gists',
                method: 'POST',
            },
            UpdateGist: {
                url: '/gists',
                method: 'PATCH',
            },
        } as any
    },
    Github: {
        baseURL: 'https://api.github.com',
        endpoint: {
            ListRepos: {
                url: '/user/repos',
                method: 'GET',
                group: ''
            },
            ListFiles: {
                url: '/contents',
                group: '/repos',
                method: 'GET',
            },
        }
    },
    GoogleDrive: {
        baseURL: 'https://www.googleapis.com/drive/v3',
        endpoint: {
            Files: {
                url: '/files',
                method: 'GET',
            },
            DELETEFiles: {
                url: '/files',
                method: 'DELETE',
            },
            POSTFiles: {
                url: '/files',
                method: 'POST',
            },
            PATCHFiles: {
                url: '/files',
                method: 'PATCH',
            },
            Copy: {
                url: '/files',
                method: 'POST',
            }
        }
    },
    GoogleDriveUpload: {
        baseURL: 'https://www.googleapis.com/upload/drive/v3',
        endpoint: {
            POSTUpload: {
                url: '/files',
                method: 'POST',
            }
        }
    },
    GoogleDriveOauth: {
        baseURL: 'https://oauth2.googleapis.com',
        endpoint: {
            RevokeToken: {
                url: '/revoke',
                method: 'POST',
            }
        }
    },
    Slack: {
        baseURL: `${process.env.REACT_APP_BACKEND_URL || 'https://localhost:8000'}/proxy/${encodeURIComponent("https://slack.com/api")}`,
        endpoint: {
            ListChannels: {
                url: '/conversations.list',
                method: 'GET'
            },
            ListUsers: {
                url: '/users.list',
                method: 'GET',
            },
            ListMessages: {
                url: '/conversations.history',
                method: 'GET'
            },
            ListEmojis: {
                url: '/emoji.list',
                method: 'GET'
            },
            PostMessage: {
                url: '/chat.postMessage',
                method: 'POST'
            },
            JoinChannel: {
                url: '/conversations.join',
                method: 'POST'
            },
            GetTeamInfo: {
                url: '/team.info',
                method: 'GET'
            },
            SearchAll: {
                url: '/search.all',
                method: 'GET'
            }
        }
    },
    Hubspot: {
        baseURL: `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000'}/proxy/${encodeURIComponent("https://api.hubapi.com")}`,
        endpoint: {
            GetContacts: {
                url: '/crm/v3/objects/contacts?limit=100&properties=phone,website,company,firstname,lastname,email,createdate,lastmodifieddate',
                method: 'GET'
            },
            CreateContact: {
                url: '/crm/v3/objects/contacts',
                method: 'POST'
            },
            RemoveContact: {
                url: '/crm/v3/objects/contacts/:contactId',
                method: 'DELETE'
            },
            ViewContact: {
                url: '/crm/v3/objects/contacts/:contactId?properties=phone,website,company,firstname,lastname,email,createdate,lastmodifieddate',
                method: 'GET'
            },
            SearchContact: {
                url: '/crm/v3/objects/contacts/search',
                method: 'POST'
            },
            GetCompanies: {
                url: '/crm/v3/objects/companies?limit=100&properties=city,domain,industry,name,phone,state',
                method: 'GET'
            },
            ViewCompany: {
                url: '/crm/v3/objects/companies/:companyId?limit=100&properties=city,domain,industry,name,phone,state',
                method: 'GET'
            },
            CreateCompany: {
                url: '/crm/v3/objects/companies',
                method: 'POST'
            },
            RemoveCompany: {
                url: '/crm/v3/objects/companies/:companyId',
                method: 'DELETE'
            },
            GetDeals: {
                url: '/crm/v3/objects/deals?limit=100',
                method: 'GET'
            },
            GetDealsPipeline: {
                url: '/crm-pipelines/v1/pipelines/deals',
                method: 'GET'
            },
            CreateDeal: {
                url: '/crm/v3/objects/deals',
                method: 'POST'
            },
            RemoveDeal: {
                url: '/crm/v3/objects/deals/:dealId',
                method: 'DELETE'
            },
            ViewDeal: {
                url: '/crm/v3/objects/deals/:dealId',
                method: 'GET'
            },
            UpdateDeal: {
                url: '/crm/v3/objects/deals/:dealId',
                method: 'PATCH'
            },
            GetReports: {
                url: '/analytics/v2/views',
                method: 'GET'
            },
            AnalytAllMetrics: {
                url: '/analytics/v2/reports/totals/total',
                method: 'GET'
            },
            AnalytDailyTraffic: {
                url: '/analytics/v2/reports/totals/summarize/daily',
                method: 'GET'
            },
            AnalytWeeklyVisitorSessions: {
                url: '/analytics/v2/reports/sessions/weekly',
                method: 'GET'
            },
            AnalytTrafficBySource: {
                url: '/analytics/v2/reports/sources/total',
                method: 'GET'
            },
            AnalytDailyVisitByTwitter: {
                url: '/analytics/v2/reports/sources/daily',
                method: 'GET'
            },
            AnalytAllVisitByGeo: {
                url: '/analytics/v2/reports/geolocation/total',
                method: 'GET'
            },
            AnalytMonthlyVisitByCountry: {
                url: '/analytics/v2/reports/geolocation/monthly?f=:f',
                method: 'GET'
            },
            AnalytAllVisitAllCampaigns: {
                url: '/analytics/v2/reports/utm-campaigns/total',
                method: 'GET'
            },
            AnalytWeeklyVisitByCampaignName: {
                url: '/analytics/v2/reports/utm-campaigns/summarize/weekly',
                method: 'GET'
            },
            AnalytAllWebPages: {
                url: '/analytics/v2/reports/standard-pages/total',
                method: 'GET'
            },
            AnalytDailyAllLandingPages: {
                url: '/analytics/v2/reports/landing-pages/summarize/daily',
                method: 'GET'
            },
            AnalytAllBlogPosts: {
                url: '/analytics/v2/reports/blog-posts/total',
                method: 'GET'
            },
            CreateCalendarTask: {
                url: '/calendar/v1/events/task',
                method: 'POST'
            },
            GetCalendarTasks: {
                url: '/calendar/v1/events/task?startDate=0&endDate=9926408434936',
                method: 'GET'
            },
            RemoveCalendarTask: {
                url: '/calendar/v1/events/task/:taskId',
                method: 'DELETE'
            },
            UpdateCalendarTasks: {
                url: '/calendar/v1/events/task/:taskId',
                method: 'PUT'
            },
            GetCalendarTask: {
                url: '/calendar/v1/events/task/:taskId',
                method: 'GET'
            },
            GetAccountDetails: {
                url: '/oauth/v1/access-tokens/:accessToken',
                method: 'GET'
            },
            GetAssociationsDealsToNotes: {
                url: '/crm/v3/objects/deals/:dealId/associations/notes/',
                method: 'GET'
            },
            GetAssociationsDealsToTask: {
                url: '/crm/v3/objects/deals/:dealId/associations/task/',
                method: 'GET'
            },
            GetEngagements: {
                url: '/engagements/v1/engagements/:associationId',
                method: 'GET'
            },
            GetOwner: {
                url: '/crm/v3/owners/:ownerId',
                method: 'GET'
            },
            CreateEngagement : {
                url: '/engagements/v1/engagements',
                method: 'POST'
            },
        }
    }
}


enum Endpoints {
    Asana = 'Asana',
    Dropbox = 'Dropbox',
    DropboxContent = 'DropboxContent',
    Gists = 'Gists',
    Github = 'Github',
    GoogleDrive = 'GoogleDrive',
    GoogleDriveUpload = 'GoogleDriveUpload',
    GoogleDriveOauth = 'GoogleDriveOauth',
    Slack = 'Slack',
    Hubspot = 'Hubspot',
}

enum Actions {
    ListWorkspaces = 'ListWorkspaces',
    CreateTask = 'CreateTask',
    UpdateTask = 'UpdateTask',
    DeleteTask = 'DeleteTask',
    ListTasks = 'ListTasks',
    UpdateStatus = 'UpdateStatus',
    GetTask = 'GetTask',
    ListProjects = 'ListProjects',
    ListGists = 'ListGists',
    CreateGist = 'CreateGist',
    UpdateGist = 'UpdateGist',
    ListRepos = 'ListRepos',
    ListFiles = 'ListFiles',
    ListChannels = 'ListChannels',
    ListUsers = 'ListUsers',
    ListMessages = 'ListMessages',
    ListEmojis = 'ListEmojis',
    PostMessage = 'PostMessage',
    JoinChannel = 'JoinChannel',
    Files = 'Files',
    DELETEFiles = 'DELETEFiles',
    POSTFiles = 'POSTFiles',
    PATCHFiles = 'PATCHFiles',
    Copy = 'Copy',
    TemporaryLink = 'TemporaryLink',
    UnshareLink = 'UnshareLink',
    ListShared = 'ListShared',
    ListSections = 'ListSections',
    GetContacts = 'GetContacts',
    CreateContact = 'CreateContact',
    RemoveContact = 'RemoveContact',
    ViewContact = 'ViewContact',
    SearchContact = 'SearchContact',
    GetCompanies = 'GetCompanies',
    ViewCompany = 'ViewCompany',
    CreateCompany = 'CreateCompany',
    RemoveCompany = 'RemoveCompany',
    GetDeals = 'GetDeals',
    GetDealsPipeline = 'GetDealsPipeline',
    CreateDeal = 'CreateDeal',
    RemoveDeal = 'RemoveDeal',
    ViewDeal = 'ViewDeal',
    UpdateDeal = 'UpdateDeal',
    GetReports = 'GetReports',
    AnalytAllMetrics = 'AnalytAllMetrics',
    AnalytDailyTraffic = 'AnalytDailyTraffic',
    AnalytWeeklyVisitorSessions = 'AnalytWeeklyVisitorSessions',
    AnalytTrafficBySource = 'AnalytTrafficBySource',
    AnalytDailyVisitByTwitter = 'AnalytDailyVisitByTwitter',
    AnalytAllVisitByGeo = 'AnalytAllVisitByGeo',
    AnalytMonthlyVisitByCountry = 'AnalytMonthlyVisitByCountry',
    AnalytAllVisitAllCampaigns = 'AnalytAllVisitAllCampaigns',
    AnalytWeeklyVisitByCampaignName = 'AnalytWeeklyVisitByCampaignName',
    AnalytAllWebPages = 'AnalytAllWebPages',
    AnalytDailyAllLandingPages = 'AnalytDailyAllLandingPages',
    AnalytAllBlogPosts = 'AnalytAllBlogPosts',
    CreateCalendarTask = 'CreateCalendarTask',
    GetCalendarTasks = 'GetCalendarTasks',
    RemoveCalendarTask = 'RemoveCalendarTask',
    UpdateCalendarTasks = 'UpdateCalendarTasks',
    GetCalendarTask = 'GetCalendarTask',
    GetAccountDetails = 'GetAccountDetails',
    GetAssociationsDealsToNotes = 'GetAssociationsDealsToNotes',
    GetAssociationsDealsToTask = 'GetAssociationsDealsToTask',
    GetEngagements = 'GetEngagements',
    CreateEngagement = 'CreateEngagement',
    GetOwner = 'GetOwner',
    SearchAll = 'SearchAll',
    POSTUpload = 'POSTUpload',
    GetTeamInfo = 'GetTeamInfo',
    RevokeToken = 'RevokeToken',
    Upload = 'Upload',
    UploadSession = 'UploadSession',
    GetFileMetaData = 'GetFileMetaData',
    RevokeLink = 'RevokeLink',
    Download = 'Download'
}


export { Endpoints, Actions }
// Feel free to call this directly
export default async function CallEndpoint(name: Endpoints, action: Actions, token: string | null | undefined, data: object = {}, parameters?: any[]) {
    const { baseURL, endpoint } = endpoints[name]
    const { url, group, method } = endpoint[action]

    if (!url) {
        throw new Error('Endpoint not found!!!')
    }
    return await OauthCaller(baseURL + (group || ''), url, method, token, data, parameters)
}
