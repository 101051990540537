import React, {useEffect, useRef, useState} from 'react'
import {AudioTrack as IAudioTrack} from 'twilio-video'
import { useAppState } from '../../../../../../contexts/AppStateProvider'
import { CallingProviderName } from '../../../../../../contexts/types'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { normalizedCallHelpers } from '../../../../Sidebar/UsersInCall/types'
import useVideoContext from '../../hooks/useVideoContext'
// import { useAppState } from '../../state';

interface AudioTrackProps {
  track: IAudioTrack | MediaStreamTrack
  onboarding?: boolean
}

function AudioTrack({track, onboarding}: AudioTrackProps) {
  const {activeSinkId} = useAppState()
  const audioEl = useRef<HTMLAudioElement>()
  const { callProvider } = useMeetingContext()
  const [ betaStream, setBetaStream ] = useState<any>(null)

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  const isDaily = callProvider === CallingProviderName.DailyCo

  useEffect(() => {
    if(isTwilio || onboarding) {
      audioEl.current = (track as IAudioTrack).attach()
      audioEl.current.setAttribute('data-cy-audio-track-name', (track as IAudioTrack).name)
      document.body.appendChild(audioEl.current)
      
    } else if(isDaily) {
      const el = audioEl.current
      if(!track) return
      
      let stream = new MediaStream()
      track && normalizedCallHelpers.addTrackToStream(stream, track as MediaStreamTrack)
      // startBetaTrack(track as MediaStreamTrack)
      if(el) {
        el.srcObject = stream;
      }

    }
    return () => {
      if(isTwilio || onboarding) {
        (track as IAudioTrack).detach().forEach((el) => {
          el.remove()
  
          // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
          // See: https://github.com/twilio/twilio-video.js/issues/1528
          el.srcObject = null
        })
      } else if(isDaily) {
        // stopBetaTrack()
      }
    }
  }, [track])

  const startBetaTrack = async(trck: MediaStreamTrack) => {
    // const trck = stream.getAudioTracks()?.[0]
    if(!trck) return
    const audio = await window?.betaStartCustomTrack({ track: trck, mode: 'music' });
    setBetaStream(audio)
  }

  const stopBetaTrack = async() => {
    await window.betaStopCustomTrack({ mediaTag: betaStream });
  }

  // Update Audio Output
  useEffect(() => {
    console.log({ activeSinkId })
    audioEl.current?.setSinkId?.(activeSinkId || '')
  }, [activeSinkId])

  return isTwilio || onboarding ? null : <audio {...{ ref: audioEl } as any} autoPlay/>
}

function areEqual(prevProps: AudioTrackProps, nextProps: AudioTrackProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
}

export default React.memo(AudioTrack, areEqual)
