import React from 'react'

import {FiMicOff as MicOff} from '@react-icons/all-files/fi/FiMicOff'
import {FiMic as MicOn} from '@react-icons/all-files/fi/FiMic'
import useMeetingContext from '../../../../../../../contexts/useMeetingContext'
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle'

export default function ToggleAudioButton(props: {
  disabled?: boolean
  className?: string
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(true)
  const {localTracks} = useMeetingContext()
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio')

  return (
    <button
      className={`button ${!isAudioEnabled && hasAudioTrack ? 'danger' : 'default'} controlbutton`}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-tip
      data-for="mic"
    >
      {!hasAudioTrack ? <MicOff /> : isAudioEnabled ? <MicOn /> : <MicOff />}
    </button>
  )
}
