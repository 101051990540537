import React, {memo} from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'

import Modal from '../../../components/Modalv2'
import check from '../../../../src/assets/icons/check.svg'
import LoopApi from '../../../helpers/LoopApi'
import { ModalActions } from '../../../stores/ModalStore'

interface Props {
  closeModal: () => void
}

const Subscription = memo((props: Props) => {
  const [subPlan, setSubPlan] = React.useState(null)
  const [products, setProducts] = React.useState<any[] | null>(null)
  const [error, setError] = React.useState(null)
  const [planInterval, setPlanInterval] = React.useState('year')

  React.useEffect(() => {
    getSubscription()
    getProducts()
  }, [])

  const getSubscription = async () => {
    const subscription = await LoopApi(null, 'StripeGetSubscription')
    setSubPlan(subscription.plan)
  }

  const getProducts = async () => {
    const products = await LoopApi(null, 'StripeGetAllProducts')
    setProducts(products)
  }

  const createSession = async (plan: string) => {
    const session = await LoopApi(null, 'StripeCreateSession', {}, [
      ['plan', [plan]],
    ])
    window.location.href = session.url
  }

  const createTrial = async (price: any) => {
    const session = await LoopApi(null, 'StripeCreateTrial', { price })
    window.location.href = '/upgrade'
  }

  const switchPlan = async (plan: any, planname: any) => {
    ModalActions.SetModalWithParams('SubscriptionConfirmation',  {
      current: subPlan,
      switch: plan,
      planname
    })
  }

  return (
    <Modal closeModal={props.closeModal} dismissible={true}>
      <Container className='topbar-modal'>
        <p>Pricing options that scale with you</p>
        <div className="duration">
          <button className={`yearly ${planInterval === 'year' ? 'sub-button-y active' : 'topbar sub-color-y'}`} onClick={() => setPlanInterval('year')}>
            Yearly
          </button>
          <button className={`monthly ${planInterval === 'month' ? 'sub-button-m active' : 'topbar sub-color-m'}`} onClick={() => setPlanInterval('month')}>
            Monthly
          </button>
        </div>
        <div className="sub-container">
          <Scrollbar
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
          <div className='plan-container'>
          {products?.map((p) => {
            return (
              <div className="plan">
                <div className="rectangle rounded topbar">
                  <div className="body">
                    <div className="header">
                      <div className="title">{p?.name}</div>
                      <div className="description ">
                        Small Teams & Startups
                      </div>
                      <div className="price-wrapper">
                        <div className='price'>
                          <span className='price-sign'>$</span><span className="price-decimal">
                              {((p?.prices?.find((price: any) => price?.recurring?.interval === planInterval)?.unit_amount / 100) / (planInterval === 'year' ? 12 : 1)).toFixed(2)}
                          </span>
                        </div>
                        <div className="price-desc">per user per month</div>
                      </div>
                    </div>
                    <Content 
                      className='content'
                      height={((subPlan === null && p?.id !== (process.env.REACT_APP_INITIAL_PLAN || 'prod_Kujg3Y02x8e5AW')) && p?.id === 'prod_KujnePXEUhOAfp') && p.trialAvailable ? '244px' : '300px'}
                    >
                      <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                      >
                        {p?.description?.split(',').map((d: any) => {
                          return (
                            <div className="feature ">
                              <img src={check} /> <span>{d}</span>
                            </div>
                          )
                        })}
                      </Scrollbars>
                    </Content>
                  </div>
                  <div className="footer">
                    {p?.prices.map((price: any) => {
                      if (price?.recurring.interval !== planInterval) return

                      return (
                        <React.Fragment>
                          {
                            (((subPlan === null && p?.id !== (process.env.REACT_APP_INITIAL_PLAN || 'prod_Kujg3Y02x8e5AW')) && p?.id === (process.env.REACT_APP_TRIAL_PLAN || 'prod_KujnePXEUhOAfp')) && p.trialAvailable) ?
                              <div
                                className={`btn-price`}
                                style={{ background: '#0F61DD', margin: '10px auto 5px auto' }}
                                onClick={() =>
                                  p?.trialAvailable ? createTrial(price?.id) : null
                                }
                              >
                                Free 7 days Trial
                              </div> : null
                          }
                          <div
                            className={`btn-price ${planInterval === 'year' ? 'sub-button-y' : 'sub-button-m'} ${price?.id === subPlan || (subPlan === null && p?.id === (process.env.REACT_APP_INITIAL_PLAN || 'prod_Kujg3Y02x8e5AW')) ? 'currentPlan color-textimp' : ''}`}
                            onClick={() =>
                            price?.id === subPlan || 
                            (subPlan === null && p?.id === (process.env.REACT_APP_INITIAL_PLAN || 'prod_Kujg3Y02x8e5AW'))
                                ? null
                                : subPlan === null
                                ? createSession(price?.id)
                                : switchPlan(price?.id, p?.name)
                            }
                          >
                            {
                              price?.id === subPlan || 
                              (subPlan === null && p?.id === (process.env.REACT_APP_INITIAL_PLAN || 'prod_Kujg3Y02x8e5AW')) ?
                                `Current Plan` : 
                                  subPlan === null ? `Get Plan` : `Switch Plan`
                            }
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
          </div>
          </Scrollbar>
        </div>
        <FooterWrapper className='rounded inner topbar'>
          <div className='contact-label'>For Large Organization, please contact us to discuss our Enterprise options</div>
          <div className='button primary contact-button' onClick={() => window.open('https://grapl.it/contact-us', '_blank')}>Contact Sales </div>
        </FooterWrapper>
      </Container>
    </Modal>
  )
})

export default Subscription

const Content = styled.div<{height?: string}>`
  height: ${(props) => props.height};
`

const FooterWrapper = styled.div`
  display: flex;
  border: 2px solid rgba(0, 0, 0, 0.4);
  width: calc(100% - 30px);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contact-label {
    padding: 0 100px 0 0;
    font-size: 16px;
    font-weight: 500;
  }

  .contact-button {
    cursor: pointer;
  }
`

const Container = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80vw;
  /* max-width: 400px; */
  /* background: #f3f7fb; */
  overflow: hidden;

  .sub-container {
    display: flex;
    margin-top: 10px;
    width: 100%;
    height: 550px;
  }

  p {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: 600;
    /* color: #002059; */
  }

  .duration {
    display: flex;
    margin-top: 10px;

    .active {
      color: #ffffff;
    }

    > button:not(.active) {
      box-shadow: ${props => props.theme.shadows.newheavy};
    }

    .monthly, .yearly {
      width: 100px;
      height: 37px;
      border-radius: 20px;
      border: 0;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
    }

    .monthly {
      margin-right: 10px;
    }

    .yearly {
      margin-left: 10px;
    }
  }
`

const Scrollbar = styled(Scrollbars) `
  .plan-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .plan {
    padding: 10px 5px;
    margin-top: 20px;

    &:first-child {
      padding: 10px 5px 10px 15px;
    }

    &:last-child {
      padding: 10px 15px 10px 5px;
    }

    .rectangle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 20px;

      width: 320px;
      min-height: 500px;

      box-shadow: ${props => props.theme.shadows.newheavy};
      transition: 0.2s transform ease-in-out;

      &:hover {
        transform: translateY(-20px);
      }

      .title {
        font-size: 1.6em;
        font-weight: 600;
      }

      .title-pro {
        font-size: 1.6em;
        color: #ffffff;
      }

      .description {
        font-size: 0.8em;
        margin-bottom: 20px;
      }

      .feat-title {
        margin-bottom: 10px;
      }

      .feature {
        margin-top: 8px;
        text-align: left;
      }

      .content {
        width: 100%;
      }

      .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .header {
          width: 100%;
          text-align: center;

          .price-wrapper {
            margin-bottom: 10px;

            .price-desc {
              font-size: 0.7rem;
            }

            .price-sign {
              padding: 0 5px;
              font-weight: 500;
            }
            .price-decimal {
              font-weight: 600;
              font-size: 1.5rem;
            }
          }
        }
      }

      .footer {
        width: 100%;

        .btn-price {
          width: 80%;
          padding: 15px 0;

          /* background: #0659a9; */
          border-radius: 30px;

          display: flex;
          flex-direction: column;
          color: #ffffff;

          justify-content: space-around;
          cursor: pointer;
          margin: 0 auto;

          &.currentPlan {
            font-weight: 500;
            background: transparent !important;
          }
        }

        .btn-price-pro {
          height: 58.43px;

          background: #ff9900;
          border-radius: 10px;

          display: flex;
          flex-direction: column;
          color: #ffffff;

          justify-content: space-around;
          cursor: pointer;
        }
      }
    }

    .pro {
      background: #00205b;
      color: #ffffff;
    }
  }
`