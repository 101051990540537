import React from 'react'
const {GlobalState} = require('reflux')

import Premades from '../Premades'
import CallingSlide from '../../NewCall/CallingSlide'
import TwilioCallingSlide from '../../NewCall/TwilioCallingSlide'
import SidebarUsersInCall from '../../Sidebar/SidebarUsersInCall'
import SidebarUsersInCallTwilio from '../../Sidebar/SidebarUsersInCallTwilio'
import ConnectionProblemOverlays from '../../../../components/ConnectionProblemOverlays'

import AllWidgets from '../../../../loop-widgets'
import WidgetItem, {WidgetContainer} from '../WidgetItem'
import {
  CallingInstanceState,
  CallingProviderName,
} from '../../../../calling/types'
import {
  CurrentWidget,
  CurrentWidgetWrapper,
  GhostContainer,
  JoinedUserUI,
} from './StyledComponents'
import {WidgetActions} from '../../../../stores/MainStore'
import LoopApi from '../../../../helpers/LoopApi'
import CallSlide from '../../NewCall/CallSlide'
import Main from './Main'
import useMeetingContext from '../../../../contexts/useMeetingContext'

function KeynoteMain(props: any) {
  // constants
  const {mainstore, callingstore, authstore, usersWhoJoined, useCurrentWidget, mobileCollapse, color_scheme} =
    props

  // main store
  const {
    db_meeting,
    widgets,
    meetingName,
    files,
    users,
    transcription,
    locallyHiddenSync,
    localCurrentWidget,
    localWidgetOrder,
  } = mainstore
  let {currentWidget} = mainstore
  let {
    settings: {widget_display_method, widgetOrder, minimizedWidgets = []},
  } = db_meeting
  // calling store
  const { meetingConnection, meetingLocked, call_starter, host} =
    callingstore
  const { callStatus } = useMeetingContext()
  const isInCall = callStatus === CallingInstanceState.Connected
  // auth store
  const {jwt, external_tokens} = authstore
  const userData = jwt?.data

  const enabledWidgets =
    process.env.REACT_APP_ENABLED_WIDGETS ||
    'asana,clickup,dropbox,files,gists,github,googledrive,hubspot,images,links,notes,pdfpresenter,scheduler,slack,tictactoe,todo,transcription,twitch,youtube,whiteboard'
  const enabledWidgetsArray = enabledWidgets
    .split(',')
    .map((item) => {
      return item.trim()
    })
    .filter((item) => !!item)

  let curWidget = currentWidget
  if (
    curWidget &&
    !(enabledWidgetsArray || []).includes(currentWidget) &&
    (enabledWidgetsArray || []).length > 0
  )
    curWidget = enabledWidgetsArray[0]
  if (
    curWidget &&
    !(enabledWidgetsArray || []).includes(currentWidget) &&
    !!!(enabledWidgetsArray || []).length
  )
    curWidget = null

  // check if there are available widgets on the sidebar
  widgetOrder = (widgetOrder || []).filter((w: any) => !!widgets?.[w])
  const missing = Object.keys(widgets).filter((w) => !widgetOrder.includes(w))
  const order = [...(widgetOrder || []), ...missing]

  let widgetList =
    widgets &&
    order
      .filter((w) => AllWidgets[w] || AllWidgets[widgets[w]._component])
      .filter((el) => enabledWidgetsArray.indexOf(el) >= 0)

  if (!isInCall || (isInCall && !meetingLocked)) {
    widgetList = widgetList.filter(
      (el: any) => (locallyHiddenSync?.[meetingName] || [])?.indexOf(el) === -1,
    )
  } else {
    widgetList = widgetList.filter((el: any) => !minimizedWidgets.includes(el))
  }

  if (
    localWidgetOrder &&
    Object.keys(localWidgetOrder).length &&
    localWidgetOrder[db_meeting._id]
  ) {
    let widgetOrder = localWidgetOrder[db_meeting._id]
    widgetOrder = (widgetOrder || []).filter((w: any) =>
    widgetList.includes(w),
    )
    const missing = widgetList.filter((w: any) => !widgetOrder.includes(w))
    const order = [...(widgetOrder || []), ...missing]
    if (order.length) widgetList = order
  }

  if (curWidget === undefined && widgetList?.length > 0)
    curWidget = widgetList[0]
  if (curWidget === undefined && !(widgetList || []).length) curWidget = null
  if (currentWidget === undefined && widgetList?.length > 0)
    currentWidget = widgetList[0]
  if (currentWidget === undefined && !(widgetList || []).length)
    currentWidget = null
  if (
    curWidget === null &&
    currentWidget === null &&
    widgetList?.length > 0 &&
    !isInCall
  ) {
    curWidget = widgetList[0]
    currentWidget = widgetList[0]
  }

  if (!!!useCurrentWidget) {
    curWidget = null
    currentWidget = null
  }

  let currentWidgetRender = curWidget &&
    widgets[curWidget] &&
    AllWidgets[curWidget] && (
      <WidgetItem
        key={curWidget + '-itemrender'}
        componentOverride={(widgets[curWidget] || {})._component}
        widgetName={curWidget}
        userId={userData?._id}
        meetingName={meetingName}
        files={files}
        widgets={widgets}
        users={users}
        height={'100%'}
        transcription={transcription}
        scrollIntoView={props.scrollWidgetIntoView}
        external_token={
          AllWidgets[curWidget]?.widgetConfig?.externalTokenKey
            ? external_tokens[
                AllWidgets[curWidget]?.widgetConfig?.externalTokenKey
              ]
            : external_tokens[curWidget]
        }
        color_scheme={color_scheme}
      />
    )

  // //console.log('deemid', { curWidget, widgets })

  const provider = (GlobalState.main?.db_meeting?.settings?.calling_provider ||
    'webrtc') as CallingProviderName

  const _setActiveWidget = (name: string | null) => {
    if (!isInCall || (isInCall && !meetingLocked)) {
      const localCurrentWidgets = {
        ...localCurrentWidget,
        [meetingName]: name,
      }
      WidgetActions.SetLocalCurrentWidget({
        localCurrentWidget: localCurrentWidgets,
      })

      // LOCAL STATE
      localStorage.setItem('currentWidget', JSON.stringify(localCurrentWidgets))
    } else {
      if ((host || call_starter) !== userData?._id) return
      // SYNCED BEHAVIOR ** Uncomment after the toggle for host is implemented **
      LoopApi('main', 'SetCurrentWidget', {currentWidget: name})
      WidgetActions.SetCurrentWidget({currentWidget: name})
    }
  }

  // Start Updates
  let mainContent = currentWidgetRender
  let isSettingUpWidget =
    !Object.keys(widgets).length ||
    !!!enabledWidgetsArray.length ||
    widgetList.length === 0

  return (
    <Main
      mainContent={mainContent}
      isInCall={isInCall}
      currentWidgetName={curWidget}
      widgets={widgets}
      isSettingUpWidget={isSettingUpWidget}
      widgetDisplayMethod={widget_display_method}
      currentWidgetData={{
        key: curWidget + '-itemrender',
        componentOverride: (widgets[curWidget] || {})._component,
        widgetName: curWidget,
        userId: userData?._id,
        meetingName,
        files,
        widgets,
        users,
        height: '100%',
        transcription,
        scrollIntoView: props.scrollWidgetIntoView,
        external_token: AllWidgets[curWidget]?.widgetConfig?.externalTokenKey
          ? external_tokens[
              AllWidgets[curWidget]?.widgetConfig?.externalTokenKey
            ]
          : external_tokens[curWidget],
        color_scheme
      }}
      setActiveWidget={_setActiveWidget}
      mobileCollapse={mobileCollapse}
    />
  )
  // End Updates

  if (!currentWidgetRender) {
    if (isInCall) {
      // Call view
      currentWidgetRender = (
        <WidgetContainer>
          {provider.includes('TWILIO') ? (
            <TwilioCallingSlide showOtherUsers />
          ) : (
            <CallSlide showOtherUsers />
          )}
        </WidgetContainer>
      )
    } else if (
      !Object.keys(widgets).length ||
      !!!enabledWidgetsArray.length ||
      widgetList.length === 0
    ) {
      // Premade data / Widget Setup
      currentWidgetRender = (
        <GhostContainer>
          <Premades />
        </GhostContainer>
      )
    }
  }

  const widgetToRender = (
    <CurrentWidget
      className="container main"
      {...{
        id: 'participantsWrapper',
        currentWidget,
        isInCall,
        callView: !currentWidget,
        expanded: props.expanded,
        keynote: widget_display_method === 'Keynote',
      }}
    >
      {currentWidgetRender}
      {!usersWhoJoined ? (
        <React.Fragment />
      ) : (
        <JoinedUserUI className="topbar rounded inner">
          {usersWhoJoined ? (
            <React.Fragment>
              <div className="image-wrapper">
                <img
                  src={
                    usersWhoJoined?.avatar_url ||
                    `https://ui-avatars.com/api/?name=${
                      usersWhoJoined?.name
                    }&format=svg&background=${
                      usersWhoJoined?.color || 'random'
                    }&color=fefefe`
                  }
                  alt={usersWhoJoined?.name}
                />
              </div>
              <div className="joined-message">
                <span className="name">{usersWhoJoined?.name}</span> joined the
                room
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
        </JoinedUserUI>
      )}
      <ConnectionProblemOverlays
        key="connectionproblemcontainer"
        connection_state={meetingConnection}
      />
    </CurrentWidget>
  )

  // const _setActiveWidget = (name: string | null) => {

  //     if (!isInCall || (isInCall && !meetingLocked)) {
  //         const localCurrentWidgets = {
  //             ...localCurrentWidget,
  //             [meetingName]: name
  //         }
  //         WidgetActions.SetLocalCurrentWidget({ localCurrentWidget: localCurrentWidgets })

  //         // LOCAL STATE
  //         localStorage.setItem("currentWidget", JSON.stringify(localCurrentWidgets))
  //     } else {
  //         if ((host || call_starter) !== userData?._id) return
  //         // SYNCED BEHAVIOR ** Uncomment after the toggle for host is implemented **
  //         LoopApi('main', 'SetCurrentWidget', { currentWidget: name })
  //         WidgetActions.SetCurrentWidget({ currentWidget: name })

  //     }
  // }

  // //console.log("isInCall: ", isInCall, "!!currentWidget: ",!!currentWidget, "!!curWidget: ",!!curWidget, "currentWidget: ",currentWidget,"curWidget: ",curWidget, !!!currentWidget || !!!curWidget)

  return (
    <React.Fragment>
      {!!currentWidget ? (
        <CurrentWidgetWrapper
          {...{keynote: widget_display_method === 'Keynote'}}
        >
          {widgetToRender}
          {isInCall &&
            (provider.includes('TWILIO') ? (
              <SidebarUsersInCallTwilio
                showOtherUsers
                _setActiveWidget={_setActiveWidget}
              />
            ) : (
              <SidebarUsersInCall
                showOtherUsers
                _setActiveWidget={_setActiveWidget}
              />
            ))}
        </CurrentWidgetWrapper>
      ) : (
        <React.Fragment>{widgetToRender}</React.Fragment>
      )}
    </React.Fragment>
  )
}

export default React.memo(KeynoteMain)
