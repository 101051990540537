import React, { useMemo } from 'react'
import ParticipantInfo from './ParticipantInfo'
import ParticipantTracks from './ParticipantTracks'
import {Participant as IParticipant} from 'twilio-video'

interface ParticipantProps {
  participant: IParticipant
  videoOnly?: boolean
  enableScreenShare?: boolean
  // onClick?: () => void;
  isSelected?: boolean
  isLocalParticipant?: boolean
  hideParticipant?: boolean
  user: any
  dominantSpeakerId?: string | null
  filterScreenshare?: boolean
}

export default function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  // onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
  user,
  filterScreenshare
}: ParticipantProps) {
  return useMemo(() => (
    <ParticipantInfo
      participant={participant}
      // onClick={onClick}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      user={user}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
        filterScreenshare={filterScreenshare}
      />
    </ParticipantInfo>
  ), [participant, hideParticipant, user, isLocalParticipant, isSelected, enableScreenShare, videoOnly ])
}
