import React from 'react'
import processString from 'react-process-string'

interface Props {
    message: string
    mine?: boolean
}

function Message({ message, mine }: Props) {
    let config = [{
        regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
        fn: (key :any, result: any) => <span key={key}>
                                 <a style={{color: mine ? 'white' : 'black', fontWeight: 'bold' }}  target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                             </span>
    }, {
        regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
        fn: (key : any, result: any) => <span key={key}>
                                 <a style={{color: mine ? 'white' : 'black', fontWeight: 'bold' }} target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
    }];

    // let stringWithLinks = "Watch this on youtube.com";
    let processed = processString(config)(message);

    return (
        <div>{processed}</div>
    )
}

export default Message