import React, {useCallback, useRef} from 'react'
import {Img} from 'react-image'
import VisibilitySensor from 'react-visibility-sensor'
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Room,
  TrackPublication,
} from 'twilio-video'
import {FiMaximize2 as MaximizeIcon} from '@react-icons/all-files/fi/FiMaximize2'

import Loader from '../../../../../../components/Loader'
import useIsRecording from '../../hooks/useIsRecording'
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting'
import usePublications from '../../hooks/usePublications'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'
import useTrack from '../../hooks/useTrack'
import useVideoContext from '../../hooks/useVideoContext'
import {MutedIcon, ParticipantContainer} from './styledComponents'
import TrackWaveMeter from './TrackWaveMeter'
import Name from './Name'
import Sagas from '../../../../../../helpers/Sagas'
import ParticipantOverlay from './ParticipantOverlay'
import LoopApi from '../../../../../../helpers/LoopApi'
import useDominantSpeaker from '../../hooks/useDominantSpeaker'
import useView from '../../hooks/useView'

import Grapl from '../../../../../../assets/Icon-SVG.svg'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { DailyCall, DailyParticipant } from '@daily-co/daily-js'
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled'
import { CallingProviderName } from '../../../../../../contexts/types'

interface MainParticipantInfoProps {
  participant: Participant | DailyParticipant
  children: React.ReactNode
  isScreenSharing?: boolean
  hideScreenshareLabel?: boolean
}

export default function MainParticipantInfo({
  participant,
  children,
  isScreenSharing,
  hideScreenshareLabel
}: MainParticipantInfoProps) {
  const {users, host, setActiveWidget} = useVideoContext()
  const {room, callProvider} = useMeetingContext()
  const {currentWidgetName} = useView()
  const lastClickTimeRef = useRef(0)
  const localParticipant = (room as Room)!.localParticipant || (room as DailyCall)?.participants()?.local
  const isLocal = localParticipant === participant

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  const isDaily = callProvider === CallingProviderName.DailyCo

  const screenShareParticipant = useScreenShareParticipant()
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant

  const publications = usePublications(participant)
  const videoPublication = (publications as any).find(
    (p: TrackPublication | MediaStreamTrack) => !((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen') && p?.kind === 'video',
  )
  const screenSharePublication = (publications as any).find((p: TrackPublication | MediaStreamTrack) =>
  ((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen'),
  )

  const videoTrack = useTrack(screenSharePublication || videoPublication)
  const isVideoEnabled = Boolean(videoTrack)

  const audioPublication = (publications as any).find((p: TrackPublication | MediaStreamTrack) => p?.kind === 'audio')
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined

  const isAudioTrackEnabled = useIsTrackEnabled(audioTrack)

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  )
  const isParticipantReconnecting = useParticipantIsReconnecting(participant)

  const isRecording = useIsRecording()

  const getUser = (id: string) => users.find((u) => u.id === id)
  const user = getUser((participant as Participant)?.identity?.split('::')?.[1] || ((participant as DailyParticipant)?.user_name || '')?.split('::')?.[1])
  const presenterUser = getUser(
    ((screenShareParticipant as Participant)?.identity || (screenShareParticipant as DailyParticipant)?.user_name || '')?.split('::')?.[1],
  )
  const participantUser = getUser(((participant as Participant)?.identity || (participant as DailyParticipant)?.user_name || '')?.split('::')?.[1])

  const dominantSpeaker = useDominantSpeaker()
  const dominantSpeakerId = dominantSpeaker?.identity?.split('::')?.[1]
  const isDominantSpeaker = participantUser?.id === dominantSpeakerId

  const isScreensharing =
    screenShareParticipant && presenterUser && presenterUser?.id === user?.id

  const [meetingName] = ((participant as Participant)?.identity || (participant as DailyParticipant)?.user_name || '')?.split('::')
  //@ts-ignore
  const me = getUser((localParticipant?.identity || (localParticipant as DailyParticipant)?.user_name || '')?.split('::')?.[1])
  const isHost = me?.id === host

  const mute = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()

      let [meetingName] = ((participant as Participant)?.identity || (participant as DailyParticipant)?.user_name || '')?.split('::')
      Sagas.Clients.Emit('main', 'message', {
        action: 'MuteParticipant',
        meetingName,
        identity: (participant as Participant)?.identity || (participant as DailyParticipant)?.user_name,
        user,
        currentUser: me,
      })
    }
  }, [participant])

  const transferHostAccess = async () => {
    try {
      await LoopApi(null, 'TransferHost', {participant: participantUser}, [
        ['name', meetingName],
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const IconSvg = <img src={Grapl} alt="Grapl" />

  return (
    <ParticipantContainer
      className={`participant main-participant container contentimp ${
        isDominantSpeaker && audioTrack ? 'border-primary' : 'border-topbarimp'
      }`}
      avatar={user?.avatar_url || ''}
      // @ts-ignore
      isScreenSharing={isScreenSharing}
      style={{border: '2px solid', transition: 'border 0.3s ease-in-out'}}
    >
      {children}

      <div className="avatar-img-wrapper rounded inner">
        <VisibilitySensor>
          <Img
            key={Math.random()}
            src={[
              user?.avatar_url || '',
              `https://ui-avatars.com/api/?name=${
                user?.username || user?.name || ''
              }&format=svg&background=${user?.color || 'random'}&color=fefefe`,
              'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg',
            ]}
            className="avatar-img rounded inner"
            loader={<Loader />}
            unloader={IconSvg}
          />
        </VisibilitySensor>
      </div>
      {!!screenShareParticipant && currentWidgetName && (
        <div className="floating-boundary">
          <button onClick={() => setActiveWidget(null)}>
            <MaximizeIcon />
          </button>
        </div>
      )}

      {!!isScreensharing ? (
        ''
      ) : (audioTrack && isAudioTrackEnabled && isTwilio) || (isDaily && audioTrack)  ? (
        <TrackWaveMeter track={audioTrack} />
      ) : (
        <MutedIcon />
      )}
      {(!!!isScreensharing || hideScreenshareLabel) && (
        <Name
          isHost={user?.id === host}
          isLocalParticipant={isLocal}
          name={user?.name || user?.username || ''}
        />
      )}

      {isScreensharing && !!!hideScreenshareLabel &&
        (isLocal ? (
          <div className="presentation-label">
            <span className="current-presenter color-primary">You</span> are
            presenting
          </div>
        ) : (
          <div className="presentation-label">
            <span className="current-presenter color-primary">
              {presenterUser?.name || presenterUser?.username || ''}
            </span>{' '}
            is presenting
          </div>
        ))}

      {/* {
        !isLocal && audioTrack && isHost && <button onClick={() => {
          mute()
        }} style={{
          top: 0,
          left: 0,
          position: 'absolute'
        }}>Mute</button>
      } */}

      {!isLocal && isHost && !!!isScreensharing && (
        <ParticipantOverlay
          transferHostAccess={transferHostAccess}
          transferHostAccessDisabled={!isHost}
          muteParticipant={mute}
          muteParticipantDisabled={!(!isLocal && ((audioTrack && isAudioTrackEnabled && isTwilio) || (isDaily && audioTrack)) && isHost)}
        />
      )}
    </ParticipantContainer>
  )
}
