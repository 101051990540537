import React, { useEffect, useState, memo } from "react";
import Plyr from "plyr";
import Sprite from "./icons.svg";
import "plyr/dist/plyr.css";

interface Props {
  source: string;
}

const VideoPlayer = (props: Props) => {
  const { source } = props;

  let player: any = null;
  const [curPlayer, setCurPlayer] = useState<any>(null);

  useEffect(() => {
    if (curPlayer) {
      curPlayer.destroy();
    }
    const controls = [
      "play-large",
      "current-time",
      "rewind",
      "play",
      "fast-forward",
      "progress",
      "fullscreen",
      "duration",
    ];
    const sources = {
      type: "video",
      sources: [{ src: source }],
    };

    player = new Plyr(".time-travel-plyr", { controls, toggleInvert: true, iconUrl: Sprite, iconPrefix: "icon" });
    player.source = sources;
    setCurPlayer(player);
  }, [source]);

  useEffect(() => {
    return () => {
      if (curPlayer) {
        curPlayer.destroy();
        setCurPlayer(null);
      }
    };
  }, []);

  if (!source) {
    return null;
  }

  return (
    <div style={{ marginRight: 15, width: "70%" }}>
      <video id={`player-${source}`} className="plyr time-travel-plyr" />
    </div>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return JSON.stringify(prevProps.source) === JSON.stringify(nextProps.source);
}

export default memo(VideoPlayer, areEqual);
