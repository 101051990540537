import React from 'react'
import styled from 'styled-components'
import { WidgetActions } from '../../stores/MainStore'

import Logo from './icon.svg'
import useClickupContext from './useClickupContext'

function HostClickup() {
    const { setStarted } = useClickupContext()
    return (
        <Container>
            <div><img src={Logo} alt="Slack" /></div>
            <div className="info">Please take note that ClickUp Guest account users will have limited access to this sync</div>
            <div> <button className='button primary pointer' onClick={() => {
                setStarted(true);
                WidgetActions.SetLastView({
                    sync: 'clickup',
                    data: {
                        view: 'teamlist',
                    }
                })
            }}>Start Exploring</button> </div>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    > .info {
        padding: 20px;
        text-align: center;
    }
    img {
        height: 50px;
    }

    button {
        border: none;
        padding: 10px 12px !important;
    }
`

export default HostClickup
