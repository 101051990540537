
import React, { useState, useEffect, Fragment } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import {
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core'

import {
  FiChevronLeft as BackIcon,
  FiChevronRight as ForwardIcon,
} from 'react-icons/fi'

import {
  FaSquare as BulletIcon
} from 'react-icons/fa'

import {
  CalendarCell, DateCell, NumberDate, ShortDay, CalendarContainer,
  CalendarNavigation, NavMonthYear, NavNextPrev, DayLabel, CalendarEvent
} from './styles/CalendarStyles'
import { IEvent } from './props/Interfaces'

// Some config for convenience
import { DAYS_SHORT, MONTHS } from './props/constants'


const toStartOfDay = (date: any) => {
	const newDate = new Date(date)
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)

  return newDate
}


// This can filter invalid envents and missing prpos or events that can't be parsed to contain valid to/from dates
const parseEvents = (events: any) => {
  return events.map((__event: { dateFrom: string, dateTo: string }) => {
  	const from = new Date(__event.dateFrom)
    const to = new Date(__event.dateTo)

    return {
      ...__event,
      from,
      to
    }
  })
}

const findEventsForDate = (events: any[], date: any) => {
	const dateTime = date.getTime()

  return events.filter((event: { from: any, to: any }) => {
    const event_from_time = toStartOfDay(event.from).getTime()
    const event_to_time = toStartOfDay(event.to).getTime()

    return (dateTime >= event_from_time && dateTime <= event_to_time)
  })
}


const Navigation = ({ date, setDate }: { date: any; setDate: any }) => {
  return (
    <CalendarNavigation>
      <NavMonthYear>
        {MONTHS[date.getMonth()]} {date.getFullYear()}
      </NavMonthYear>

      <NavNextPrev className="prev" onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() - 1)
          setDate(newDate)
        }}>
          <BackIcon />
      </NavNextPrev>

      <NavNextPrev className="next" onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() + 1)
          setDate(newDate)
        }}>
          <ForwardIcon />
      </NavNextPrev>
    </CalendarNavigation>
  )
}


// Week day headers from Sunday to Saturday
const DayLabels = () => {
  return (<>
    {
      DAYS_SHORT.map((dayLabel, index) => {
        return <DayLabel className="cell" key={index}>{dayLabel}</DayLabel>
      })
    }
    </>)
}

// An individual event displayed within the calendar grid itself, can be clicked to open the main event view
const MiniEvent = ({ event, setViewingEvent }: { event: any, setViewingEvent: any }) => {
  // overlayInnerStyle={{ borderRadius: '30px', fontSize: '11px', padding: '8px 10px' }}
  return (
    <Tooltip placement="top" title={`${moment(event.dateFrom).format('h:mmA')} ${event.topic}`}>
      <CalendarEvent
        className={`miniEvent ${event.type ? event.type.toLowerCase() : "standard"}`}
        onClick={() => {}/*setViewingEvent(event)*/}>
        <BulletIcon /> {moment(event.dateFrom).format('h:mmA')} {event.topic.slice(0, 10) + (event.topic.length > 10 ? '...' : '')}
      </CalendarEvent>
    </Tooltip>
  )
}

// context menu
const initialState = {
  mouseX: null,
  mouseY: null,
};

// The grid of days, renders a month's worth of days and also populates the events on the relevant dates
const Grid = ({ date, events, setViewingEvent, actualDate, size, createEvent }: {
  date: any
  events: any[]
  setViewingEvent: any
  actualDate: any
  size: string
  createEvent: (e: any) => any
}) => {
  const firstOfMonth = moment(date).startOf('month').format('ddd');
  const lastOfMonth = moment(date).endOf('month').format('ddd');

  // Determine the number of rows for the month based on the position of first and last day of the month
  const ROWS_COUNT = ['Fri', 'Sat'].indexOf(firstOfMonth) !== -1 && ['Sun', 'Mon'].indexOf(lastOfMonth) !== -1 ? 6 : 5
  const currentDate = toStartOfDay(new Date())

	// Finds the closest Monday relative to the first day of the target month/year,
  // Then increment upon this day until we have a full set of date objects to work with
  const startingDate = new Date(date.getFullYear(), date.getMonth(), 1)
  startingDate.setDate(startingDate.getDate() - (startingDate.getDay()))

  const dates = []
  for (let i = 0; i < (ROWS_COUNT * 7); i++) {
    const date = new Date(startingDate)
    dates.push({ date, events: findEventsForDate(events, date) })
    startingDate.setDate(startingDate.getDate() + 1)
  }

  // context menu
  const [context, setContext] = useState<any>(initialState);
  const [contextId, setContextId] = useState<any>(null);
  const handleContextMenu = (id: any, event: any) => {
    event.preventDefault();
    setContext({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    // alert('selected context id', event.key);
    setContextId(id);
  }
  const handleContextClose = () => {
    setContext(initialState);
    setContextId(null);
  };

  const contextMenuItems = [
        {
          label: 'Create Schedule',
          key: 'create-schedule'
        },
        {
          label: 'View In Week',
          key: 'view-week'
        },
        {
          label: 'View Details',
          key: 'this-day'
        }
      ]

  const contextMenu = (
    <Menu
      open={context.mouseY !== null}
      onClose={handleContextClose}
      anchorReference="anchorPosition"
      anchorPosition={
        context.mouseY !== null && context.mouseX !== null
          ? { top: context.mouseY, left: context.mouseX }
          : undefined
      }
    >
      {contextMenuItems.map((__item, __i) => <MenuItem key={__i} onClick={() => createEvent(__item.key)}>{__item.label}</MenuItem>)}
    </Menu>
  );

  const isCurrent = (__date: any) => {
    return __date.date.getTime() == currentDate.getTime() ? ' current' : ''
  }

  const isOther = (__date: any) => {
    return __date.date.getMonth() != actualDate.getMonth() ? ' other' : ''
  }

  return (
    <Fragment>
      {dates.map((date, index) => {
        return (
            <CalendarCell
              key={index}
                onContextMenu={(e) => {
                  if (size === 'big')
                    handleContextMenu(date, e)
                  }
                } style={{ cursor: 'context-menu' }}
              className={`${size}` + isCurrent(date) + isOther(date)}
            >
              <DateCell className={`${size}` + isCurrent(date) + isOther(date)}>
                <NumberDate className={size}>{date.date.getDate()}</NumberDate>

                {
                size === 'big'
                  ? <ShortDay className={size}>{DAYS_SHORT[date.date.getDay()]}</ShortDay>
                  : null
                }

              </DateCell>
              { size === 'big' && date.events.map((event, index) =>
                <MiniEvent key={index} event={event} setViewingEvent={setViewingEvent} />
              )}
            </CalendarCell>
        )
      })}

      {contextMenu}
    </Fragment>
  )
}


const Calendar = ({ month, year, loadedEvents = [], onChangeCalendar = (undefined) => {}, createEvent = (undefined) => {}, dataRefresh = false, size = 'big' }: {
  month: number
  year: number
  loadedEvents?: IEvent[]
  onChangeCalendar?: (e: any) => void
  createEvent?: (e: any) => void
  dataRefresh?: boolean
  size?: string
}) => {
  const selectedDate = new Date(year, month - 1)

  const [date, setDate] = useState<any>(selectedDate)
  const [viewingEvent, setViewingEvent] = useState<boolean | null>(false)
  const parsedEvents = parseEvents(loadedEvents)
  const [events, setEvents] = useState(parsedEvents)

  useEffect(() => {
    // if list of events has been updated
    const parsed_events = parseEvents(loadedEvents)
    console.log('parsed_events:', parsed_events)
    setEvents(parsed_events)
  }, [dataRefresh])

  useEffect(() => {
    // next or previous months
    const new_date = new Date(year, month - 1)
    setDate(new_date)
  }, [year, month])

  useEffect(() => {
    // We can call Api here inside calendar every time the date changed
  	console.log("date changed, fetch more data from api..")

    // update the calendar from the canvass
    onChangeCalendar(date)
  }, [date])

  return (
    <CalendarContainer className={`${size}`}>
      {size === 'small' &&
        <Navigation date={date} setDate={setDate} />
      }

      {
        size === 'small' && <DayLabels />
      }

      <Grid
        date={date}
        events={events}
        setViewingEvent={setViewingEvent}
        actualDate={date}
        size={size}
        createEvent={createEvent}
      />

    </CalendarContainer>
  )
}


export default Calendar