import React, { Component } from 'react'
import styled from 'styled-components'
import { FaSearch as FaSearch } from '@react-icons/all-files/fa/FaSearch'

interface State {
	searchKeyword: string
}

export default class SearchLinkInput extends Component<any, State> {
	constructor(props: any) {
		super(props)

		this.handleChange = this.handleChange.bind(this)
		this.state = {
			searchKeyword: ''
		}
	}

	handleChange(e: any) {
		const searchKeyword = e.target.value
		this.setState({ searchKeyword }, ()=> {
			this.props.onSearch(this.state.searchKeyword)
		});
	}

	render() {
		return (
			<Container className="link-input-container">
				{!this.props.travelling &&
					<Button className='button default rounded pointer' onClick={() => this.props.openAdd()}>Add Link</Button>
				}
				<LinkForm>
					<FaSearch size={16} style={{ position: 'absolute', top: 'calc(50% - 8px)', left: 4 }}/>
					<Field
						type="text"
						className="container content"
						placeholder="Search Link"
						value={this.state.searchKeyword}
						onChange={this.handleChange}
					/>

				</LinkForm>
			</Container>
		)
	}
}

const Button = styled.div`
	margin-left: 10px;
    padding: 12px 20px !important;

`

const FormSubmit = styled.div`
	color: inherit;
	height: 100%;
	display: flex;
	font-weight: 500;
	padding: 12px 16px !important;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	cursor: pointer;
	box-sizing: border-box;
`;


const Container = styled.div<{ isValid?: boolean }>`
	height: 60px;
	display: flex;
	padding: 10px;
	border-color: ${props =>
		!props.isValid && (props.theme.betterColors.red.string() + ' !important')};
	transition-duration: 0.2s;
	z-index: 2;
	flex-direction: row-reverse;


    border-radius: 0px 0px 10px 10px;
`

const LinkForm = styled.div`
	display: block;
	position: relative;
	width: 250px;
	float: right;
`

const Field = styled.input`
	width: 210px;
	padding: 12px;
	padding-left: 40px;
	border-radius: 999px;
	font-weight: 400;
	border: none;
	color: inherit;
	float: right;

	&::placeholder {
		opacity: 0.5;
	}
`
