import moment from "moment";
import React, { createContext, Dispatch, MouseEvent, ReactNode, SetStateAction, useEffect, useState } from "react";
import { Doc, Error } from "sharedb";

export interface INotesContext {
    roomId: string,
    roomDoc?: Doc | null,
    notesDoc?: Doc | null,
    sidebarNotesDoc?: Doc | null,
    activeNote: string | null,
    sidebarNotes: any
    userId: any
    users: any
    addNewNotes: ({ isPrivate }: { isPrivate: boolean }) => void,
    deleteNote: ({ id, e }: { id: string | null, e?: MouseEvent<SVGElement> }) => void
    updateNotePrivacy: ({ e, id }: { e?: MouseEvent, id?: string }) => void,
    setActiveNote: Dispatch<SetStateAction<string | null>>
    toggleCollapseSidebar: () => void
    collapsedSidebar: boolean,
    updateActiveNote: ({ sidebarNotesKey, sideNotes, id }: any) => void
    connection: any,
    updateActiveSync: (s: string | null) => void
    initialized: boolean
    setInitialized: React.Dispatch<React.SetStateAction<boolean>>
    initializedTitle: boolean
    setInitializedTitle: React.Dispatch<React.SetStateAction<boolean>>
}

interface NotesProviderProps {
    children: ReactNode,
    userId: any,
    users: any,
    roomId: string,
    roomDoc?: Doc | null,
    notesDoc?: Doc | null,
    sidebarNotesDoc?: Doc | null,
    sidebarNotes: any,
    active: string | null,
    toggleCollapseSidebar: () => void
    collapsedSidebar: boolean,
    connection: any,
    updateActiveSync: (s: string | null) => void
}
const is_mobile = window.matchMedia('(max-width: 970px)')

export const NotesContext = createContext<INotesContext>(null!)
export function NotesContextProvider({
    roomId,
    roomDoc,
    userId,
    users,
    notesDoc,
    sidebarNotesDoc,
    children,
    sidebarNotes,
    active,
    toggleCollapseSidebar,
    collapsedSidebar,
    connection,
    updateActiveSync
}: NotesProviderProps) {
    const [ activeNote, setActiveNote ] = useState<string | null>(null)
    // const [ uid, setUID ] = useState<string | null>(null)
    const [ isMobile, setIsMobile ] = useState<boolean>(is_mobile.matches)
    const [initialized, setInitialized] = useState<boolean>(false)
    const [initializedTitle, setInitializedTitle] = useState<boolean>(false)

    useEffect(() => {
        mediaChange({ matches: is_mobile.matches })
        is_mobile.addListener(mediaChange)
        return () => {
          is_mobile.removeListener(mediaChange)
        }
    }, [])
    
    const mediaChange = ({matches}: {matches: any}) => {
        setIsMobile(matches)
        if(matches && !!!collapsedSidebar) {
            toggleCollapseSidebar()
        }
    }

    useEffect(() => {
        setActiveNote(active)
    }, [active])


    const addNewNotes = ({ isPrivate }: { isPrivate: boolean }) => {
        if(!roomDoc) return 

        try {
            const id = `${moment().format("MMDDYYYYhhmmssA")}-${Math.floor((Math.random() * 100000000) + 1)}`
            const newNotesData = {
                id,
                title: '',
                content: '',
                created_by: userId || null,
                date_created: moment().format(),
                date_updated: moment().format(),
                updated_by: userId || null,
                isPrivate,
                users: userId ? [userId] : []
            }

            //@ts-ignore
            const notesSidebar = connection.get("sharednotessidebar", id);
            notesSidebar.create({...newNotesData}, (error: Error) => {
                if (error) return console.error("Error in creating notes sidebar (notes creation): ", error)

                roomDoc.submitOp([
                    { p: ['notes', 0], li: id },
                    { p: ['sidebarNotes', id], oi: { id, created_by: userId || null, isPrivate } }
                ], undefined, (error: Error) => {
                    if(error) return console.error("Error in creating notes (room doc): ", error)
                    
                    //@ts-ignore
                    const notes = connection.get("sharednotes", id);
                    notes.create([{ insert: '' }], 'rich-text', (error: Error) => {
                        if (error) return console.error("Error in creating notes (notes creation): ", error)
    
                        // Mark: Set as active note
                        // setActiveNote(id)
    
                        //@ts-ignore
                        const notesTitle = connection.get("sharednotestitle", id);
                        notesTitle.create([{ insert: '' }], 'rich-text', (error: Error) => {
                            if (error) return console.error("Error in creating notes title (notes creation): ", error)
                        })
                    })
                })
            })

            
        } catch(err) {
            if (err) return console.error("Error in creating notes: ", err)
        }
    }

    const deleteNote = ({ id, e }: { id: string | null, e?: MouseEvent<SVGElement> }) => {
        if(e) {
            e.stopPropagation()
        }

        if(!roomDoc || !!!id) return 
        const { notes: snotes } = roomDoc?.data
        const notesIndex = (snotes || []).findIndex(() => id)

        roomDoc.submitOp([
            { p: ['notes', notesIndex], ld: id },
            { p: ['sidebarNotes', id], od: {} }
        ], undefined, (error: Error) => {
            if(error) return console.error("Error in deleting notes (room doc): ", error)

            setActiveNote(null)
            
            // Mark: (TODO) delete the record also from sharednotes and o_sharednotes in mongodb
        })
    }

    const updateNotePrivacy = ({ e, id }: { e?: MouseEvent, id?: string}) => {
        if(e) {
            e.stopPropagation()
        }

        //@ts-ignore
        const notesSidebarDoc = connection.get("sharednotessidebar", id || activeNote);
        if(!notesSidebarDoc || !roomDoc) return 

        const date_updated = moment().format()
        notesSidebarDoc.submitOp([{
            p: ['isPrivate'],
            oi: false
        }, {
            p: ['date_updated'],
            oi: date_updated
        },{
            p: ['updated_by'],
            oi: userId || null
        }])
        roomDoc.submitOp([
            { p: ['sidebarNotes', id || activeNote], oi: { isPrivate: false } }
        ])
    }

    const updateActiveNote = ({ sidebarNotesKey, sideNotes, id }: any) => {
        const ownedbyyou = sidebarNotesKey.filter((note: any) => {
            const currentNote = sideNotes?.[note]
            return (currentNote?.isPrivate && currentNote?.created_by === userId) || !!!currentNote?.isPrivate 
        })
        let currentIndex = ownedbyyou.findIndex((n: string) => n === id)
        let newActiveNote = ownedbyyou.length === currentIndex + 1 ? ownedbyyou[currentIndex - 1] : ownedbyyou[currentIndex + 1]
        if(!!!ownedbyyou?.length) {
            active = null
        } else {
            active = newActiveNote
        }
        setActiveNote(active)
    }

    return <NotesContext.Provider value={{
        roomId,
        roomDoc,
        notesDoc,
        sidebarNotesDoc,
        sidebarNotes,
        addNewNotes,
        deleteNote,
        updateNotePrivacy,
        
        activeNote,
        setActiveNote,

        userId,
        users,

        collapsedSidebar,
        toggleCollapseSidebar,
        updateActiveNote,

        connection,
        updateActiveSync,
        initialized,
        setInitialized,
        initializedTitle,
        setInitializedTitle
    }}>
        {children}
    </NotesContext.Provider>
}

