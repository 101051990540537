import React, {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useMemo,
} from 'react'
import * as twilio from 'twilio-video'
import {isMobile} from '../calling/_helpers'
import {Meeting} from '../screens/Meeting2.0/types'
import useRoom from '../screens/Meeting2.0/Widgets/KeynoteWidgets/hooks/useRoom'
import useLocalTracks from './useLocalTracks'
import {CallingProviderName, IMeetingData, ISessionData, RoomType} from './types'
import {getConnectionOptions} from './twilio'
import useRestartAudioTrackOnDeviceChange from '../screens/Meeting2.0/Widgets/KeynoteWidgets/VideoProvider/useRestartAudioTrackOnDeviceChange'
import useHandleRoomDisconnection from '../screens/Meeting2.0/Widgets/KeynoteWidgets/VideoProvider/useHandleRoomDisconnection'
import useHandleTrackPublicationFailed from '../screens/Meeting2.0/Widgets/KeynoteWidgets/VideoProvider/useHandleTrackPublicationFailed'
import LoopApi from '../helpers/LoopApi'
import {CallingInstanceState} from '../calling/types'
import useScreenShareToggle from '../screens/Meeting2.0/Widgets/KeynoteWidgets/VideoProvider/useScreenShareToggle'
// import useBackgroundSettings, {
//   BackgroundSettings,
// } from '../screens/Meeting2.0/Widgets/KeynoteWidgets/VideoProvider/useBackgroundSettings'
import { showToast } from './daily'
import { getProviderConnectOptions } from './options'
import { DailyCall } from '@daily-co/daily-js'
const {GlobalState} = require('reflux')

export interface IMeetingContext {
  meeting: IMeetingData
  connect: ({ video, audio }: { video?: boolean, audio?: boolean }) => void
  room: twilio.Room | DailyCall | null,
  localTracks: (twilio.LocalAudioTrack | twilio.LocalVideoTrack)[]
  callStatus: CallingInstanceState
  callProvider: CallingProviderName
  onError: (er: Error) => void

  // tracks
  getLocalVideoTrack: (
    newOptions?: twilio.CreateLocalTrackOptions,
  ) => Promise<twilio.LocalVideoTrack>
  getLocalAudioTrack: (deviceId?: string) => Promise<twilio.LocalAudioTrack>
  isAcquiringLocalTracks: boolean
  removeLocalVideoTrack: () => void
  removeLocalAudioTrack: () => void
  stopLocalTracks: () => void
  getAudioAndVideoTracks: () => Promise<void>

  // background
  isBackgroundSelectionOpen: boolean
  setIsBackgroundSelectionOpen: (value: boolean) => void
  //Mark: DISABLE BACKGROUND UPON JOINING FOR NOW
  // backgroundSettings: BackgroundSettings
  // setBackgroundSettings: (settings: BackgroundSettings) => void

  session: ISessionData | null
  setScreenshareId: React.Dispatch<React.SetStateAction<string | null>>
  screenshareId: string | null
}

export const MeetingContext = createContext<IMeetingContext>(null!)

interface MeetingProviderProps {
  children: ReactNode
  meeting: IMeetingData
}

export function MeetingProvider({children, meeting}: MeetingProviderProps) {
  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    stopLocalTracks
  } = useLocalTracks()

  const [session, setSession] = useState<ISessionData | null>(null)
  const [callStatus, setCallStatus] = useState<CallingInstanceState>(
    CallingInstanceState.UninitializedWithoutToken,
  )
  const [screenshareId, setScreenshareId] = useState<string | null>(null)

  const [callProvider, setCallProvider] = useState<CallingProviderName>(CallingProviderName.DailyCo)

  const onError = (err: Error) => {
    console.log('Meeting Provider Error', err)
    // alert('Meeting Provider Error!')
    showToast({ message: err?.message })
  }

  const {room, isConnecting, connect} = useRoom(
    localTracks,
    onError,
    setCallStatus,
    getProviderConnectOptions(meeting.settings.calling_provider),
    meeting.settings.calling_provider
  )

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError,
    callProvider
  )

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  )
  useHandleTrackPublicationFailed(room, onError, callProvider)
  useRestartAudioTrackOnDeviceChange(localTracks)

  /*
    Background Settings
  */
  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] =
    useState(false)
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as twilio.LocalVideoTrack | undefined

  //Mark: DISABLE BACKGROUND UPON JOINING FOR NOW
  // const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(
  //   videoTrack,
  //   room,
  // )

  useEffect(() => {
    const run = async () => {
      if(!room) return 
      let roomSID = null
      if ((room as twilio.Room)?.sid) {
        roomSID = (room as twilio.Room)?.sid
      } else if((room as DailyCall) && callProvider === CallingProviderName.DailyCo) {
        const { meetingSession } = await (room as DailyCall).getMeetingSession()
        roomSID = meetingSession?.id
      }

      try {
        const _session = await LoopApi(null, 'GetRecordingBySessionId', {}, [
          ['sessionId', roomSID],
        ])
        if(_session.error) {
          console.log("SESSION ERROR")
        }
        setSession(_session.recording)
      } catch (err) {
        onError(err)
      }
    }
    

    run()
  }, [room])

  useEffect(() => {
    setCallProvider(meeting.settings.calling_provider)
  }, [meeting?.settings?.calling_provider])

  // @ts-ignore
  window.getAudioAndVideoTracks = getAudioAndVideoTracks
  // @ts-ignore
  window.connect = connect
  // console.log({backgroundSettings})

  return (
    <MeetingContext.Provider
      value={{
        meeting,
        connect,
        room,
        localTracks,
        callStatus,
        callProvider,
        onError,
        setScreenshareId,
        screenshareId,

        // tracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        stopLocalTracks,
        getAudioAndVideoTracks,

        // background
        isBackgroundSelectionOpen,
        setIsBackgroundSelectionOpen,

        //Mark: DISABLE BACKGROUND UPON JOINING FOR NOW
        // backgroundSettings,
        // setBackgroundSettings,

        //session
        session
      }}
    >
      {children}
    </MeetingContext.Provider>
  )
}
