import {Room, TwilioError} from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'
import {useEffect} from 'react'

import {Callback} from './types'

export default function useHandleRoomDisconnection(
  room: Room | DailyCall | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void,
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          onError(error)
        }

        removeLocalAudioTrack()
        removeLocalVideoTrack()
        if (isSharingScreen) {
          toggleScreenShare()
        }
      }

      (room as Room).on('disconnected', onDisconnected);
      return () => {
        (room as Room).off('disconnected', onDisconnected);
      }
    }
  }, [
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  ])
}
