import React from 'react'
import useMeetingContext from '../../../../../../../contexts/useMeetingContext'
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle'
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle'
import OnboardingClass from './OnboardingClass'

interface Props {
  onClose: (
    a: MediaTrackConstraints | boolean,
    c: unknown,
    b: MediaTrackConstraints | boolean,
  ) => void
}

const OnboardingFunctional = (props: Props) => {
  const meetingContext = useMeetingContext()
  const [isVideoEnabled] = useLocalVideoToggle(true)
  const [isAudioEnabled] = useLocalAudioToggle(true)

  return <OnboardingClass meetingContext={meetingContext} {...props} isVideoEnabled={isVideoEnabled} isAudioEnabled={isAudioEnabled}/>
}

export default OnboardingFunctional
