import React from "react"
import styled from "styled-components"
import EmptyFileContents from "../_Shared/EmptyFileContents"
import ListFolderItem from "./ListFolderItem"
import GridFolderItem from "./GridFolderItem"
import { DropboxFile } from "../types"

interface Props {
	view: string,
	folders: DropboxFile[]
	openFolder: (folderId: string, folderName: string) => void
	colorScheme: string
}

function FolderItems(props: Props) {
	const {view, folders, openFolder, colorScheme} = props

	if (folders.length === 0) {
		return <EmptyFileContents>No Folders Found</EmptyFileContents>
	}

	if (view === "list") {
		const fileItems = folders.map(file => (
			<ListFolderItem
				key={file.id}
				file={file}
				openFolder={openFolder}
				colorScheme={colorScheme}
			/>
		))

		return <div>{fileItems}</div>
	}

	if (view === "grid") {
		const fileItems = folders.map(file => (
			<GridFolderItem
				key={file.id}
				file={file}
				openFolder={openFolder}
				colorScheme={colorScheme}
			/>
		))

		return <Grid>{fileItems}</Grid>
	}

	return null
}

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 16px;
	padding: 0 16px;
	margin: 16px 0;

	@media (min-width: 456px){
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (min-width: 768px){
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	
	@media (min-width: 1101px){
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	
	@media (min-width: 1300px){
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`

export default FolderItems
