import React from 'react'

import styled from 'styled-components'
import {
  Drawer
} from '@material-ui/core'

export const CustomCalendarIcon = ({ size = '13px', svgIcon, style = {}, alt = 'custom-calendar-icon' }: {
  size?: string
  svgIcon: any
  alt?: string
  style?: any
}) => {
	return (<CustomIcon width={size} height={size} style={style} src={svgIcon} alt={alt} />)
}

export const CustomIcon = styled.img`
`

export const CustomDrawer = styled(Drawer)`
  .drawer-header {
    padding: 10px 24px;

    .drawer-header-title {
      .drawer-close {
        width: 60px;
        padding: 0;
        margin: 10px 10px 10px 0;
        color: #363B45;
        border-right: 1px solid #E9ECEF;
        border-radius: 0;
      }

      .drawer-title
    }
  }
`

export const Title = styled.h1`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    text-align: center;
`

export const Logo = styled.div`
    display: flex;
    padding: 5px;
    width: 350px;

    img {
        width: 100px;
    }
`

export const PageName = styled.div`
    width: 300px;
    padding-top: 10px;
    padding-left: 20px;
    font-size: 0.8em;
`

export const CalendarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

export const SideCalendar = styled.div`
  width: 30%;
  padding: 0 15px;
`

export const MainCalendar = styled.div`
  width: 68%;
  padding-left: 30px;
`

export const DatePickerWrapper = styled.div`
`

export const LinkCalendarWrapper = styled.div`
    margin-top: 20px;

    .ant-select {
      margin-bottom: 5px;
    }
`
