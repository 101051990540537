import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { MdFolder } from '@react-icons/all-files/md/MdFolder'
import getIcon from '../_Shared/FileIcon'
import { GDriveFolderStackItem, GFile } from '../types'
import { IoEllipsisVerticalSharp as Ellipsis, IoLockClosed as LockClosedIcon } from 'react-icons/io5'
import Downshift from 'downshift'
import { humanFileSize, timeTodayDateElse } from '../../helpers'
import Context from './Context'

interface Props {
	file: GFile
	viewType: string
	openFile?: (i: GDriveFolderStackItem) => void
	openFolder?: (i: GDriveFolderStackItem) => void
	options?: any[]
	suggested?: boolean
	isLight: boolean
}

const filetypeReg = /\.([^.]+)$/

export default class File extends Component<Props> {
	static defaultProps = {
		file: undefined,
	}

	state = {
		anchorPoint: { x: 0, y: 0 },
		showMenu: false
	}

	render() {
		const {
			file: {thumbnailLink, id, name, mimeType, iconLink, owners, ownedByMe, modifiedTime, size, permissions},
			viewType, openFolder, options, suggested, isLight
		} = this.props
		const FileIcon = getIcon(name)
		const matches = mimeType.match(filetypeReg) || name.match(filetypeReg)
		const filetype = matches && matches[1] || ''
		const IcoType = mimeType.includes('vnd.google-apps') && !!iconLink ?
		<img src={iconLink.replace('/16/', '/64/')} /> :
		<FileIcon size={24} />
		const owner = ownedByMe ? 'me' : owners && owners[0]?.displayName
		const time = modifiedTime && timeTodayDateElse(modifiedTime)
		const isGrid = viewType === 'grid'
		const isPublic = !!(permissions || [])?.find((p: any) => p.id === 'anyoneWithLink')

		if (mimeType !== 'application/vnd.google-apps.folder')
			return (
				<Context id={id} isGrid={isGrid} options={options} isLight={isLight}>
					<ContentItem
						onClick={() => !!this.props.openFile && this.props.openFile({
							folderId: id,
							name: name
						})}
						isGrid={isGrid}
						isFile={true}
						isLight={isLight}
						onContextMenu={e => {
							e.preventDefault();
							this.setState({ anchorPoint: { x: e.clientX, y: e.clientY } });
							this.setState({ showMenu : true })
						}}
					>
						{isGrid &&
							<Preview className="g-thumb" thumb={thumbnailLink}>
								{thumbnailLink && !mimeType.includes('vnd.google-apps') ?
								<ImgWrapper><img referrerPolicy="no-referrer" src={thumbnailLink} /> </ImgWrapper>: IcoType}
							</Preview>
						}

						{!isGrid && <FileIco className="g-icon">{IcoType}</FileIco>}

						<div className="g-name">
							{name}
							{!isPublic && <div className="color-primary" style={{display: 'inline'}}>
								<Locked size={10}/>
							</div>}
						</div>

						{isGrid && size &&
							<div className="g-size">File size: {humanFileSize(size)}</div>
						}

						{!isGrid &&
							<Fragment>
								<div className="g-owner">{owner}</div>
								<div className="g-time">{time}</div>
							</Fragment>
						}

						{isGrid && options &&
							<EllipsisWrapper>
								<Downshift>
									{props => (
										<DropdownContainer {...props.getRootProps()}>
											<Ellipsis key={'ellipsis'} size="15px" {...props.getToggleButtonProps({
												onClick(event) {
													event.stopPropagation()
												}
											})} />
											<DropdownList isLight={isLight} key={'dropdown'} {...props.getMenuProps()}>
												{props.isOpen && options.map((option, index) => (
													<DropdownListItem
														key={index}
														{...props.getItemProps({
															key: option.value,
															item: option,
															index,
															onClick: (event) => {
																option.onItemClick()
																event.stopPropagation()
															}
														})}
													>
														<div>
															<div className="first-row-gd">
																<option.icon />
																<span className="text">{option.text}</span>
															</div>
															{!!option.subtext ? <div className="subtext">{option.subtext}</div> : ''}
														</div>
													</DropdownListItem>
												))}
											</DropdownList>
										</DropdownContainer>
									)}
								</Downshift>
							</EllipsisWrapper>
						}
					</ContentItem>
				</Context>
			)
		else
			return (
				<ContentItem
					onClick={() => !!openFolder && openFolder({
						folderId: id,
						name: name
					})}
					isGrid={isGrid}
					isFile={false}
					isLight={isLight}
				>
					<FolderIcon size={24} />
					<div className="g-name">{name}</div>
					{!isGrid &&
						<Fragment>
							<div className="g-owner">{owner}</div>
							<div className="g-time">{time}</div>
						</Fragment>
					}
				</ContentItem>
			)
	}
}

const EllipsisWrapper = styled.div`
	position: absolute;
	right: 3px;
	top: 8px;
`

const ContentItem = styled.div<{isGrid: boolean, isFile: boolean, isLight: boolean}>`
	cursor: pointer;
	transition: background-color 0.3s;

	> div:not(${EllipsisWrapper}) {
		word-break: break-all;
	}

	${({isGrid, isFile, isLight}) => isGrid ?
		`
		display: flex;
		flex-direction: column;
		background-color: ${isLight ? '#fff' : '#1b1b25'};
		color: ${isLight ? 'rgb(54, 59, 69)' : '#fff'};
		border-radius: 8px;
    	padding: 16px 35px;
		${isFile ? `
		position: relative;
		.g-thumb{
			padding: 20px;
			align-items: center;
    		display: flex;
		}
		.g-name{
			margin: 10px 0;
			text-align: center;
			font-size: 14px;
			word-break: break-word;
		}
		.g-size{
			font-size: 10px;
			color: #acacac;
		}
		> div:not(${EllipsisWrapper}) {
			margin: 12px 0px;
		}
		` : `
		.g-name{
			margin: 10px 0;
			text-align: left;
			font-size: 14px;
			word-break: break-word;
		}
		`

		}` :
		`
		display: grid;
		position: relative;
		grid-template-columns: 50px 3fr 1fr 1fr;
		padding: 8px 16px;
		min-height: 48px;

		> img, svg {
			place-self: center stretch;
		}

		> div {
			font-size: 12px; place-self: center stretch;
		}

		&:hover {
			background-color: rgba(0,0,0,0.1);
		}

		`
	}

	.g-name, .g-owner, .g-time{
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	> svg {
		fill: #2483f3;
	}

`

const FolderIcon = styled(MdFolder)`
	fill: #2483f3;
`

const FileIco = styled.div`
	display: flex;
	align-items: center;

	img {
		width: 24px;
		height: 24px;
	}
`

const Preview = styled.div<{thumb?: string}>`
	max-height: 80px;
	min-height: 80px;
	overflow: hidden;
	width: 100%;
	${({thumb}) => thumb ? 'justify-content: center;' :
	`display: flex;
	align-items: center;
	justify-content: center;
		img {
			height: 50px !important;
			width: 50px !important;
		}
	`}
`

const ImgWrapper = styled.div`
	display: flex;
	justify-content: center;
`


const DropdownContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	width: 24px;
	padding: 4px;
	text-align: end;
`
const DropdownListItem = styled.li`
	padding: 10px;
	display: flex;
	align-items: center;
	transition: background 0.3s;

	& svg {
		margin-right: 8px;
	}

	&:hover {
		background: #e7e7e7;
	}

	.text {
		font-size: 16px;
	}

	.subtext {
		font-size: 7px;
    	margin-top: 3px;
		color: #929292;
	}

	.first-row-gd {
		display: flex;
    align-items: center;
	}
`

const DropdownList = styled.ul<{isLight: boolean}>`
	position: absolute;
	right: 40%;
	width: 130px;
	background-color: ${({isLight}) => isLight ? '#fff' : '#181820'};
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	${DropdownListItem} {
		${({isLight}) => isLight ?
			`
				color: #000;
				&:hover {
					background: #e7e7e7;
				}
				& svg {
					fill: #000;
				}
			`
			:
			`
				color: #fff;
				&:hover {
					background: #2b2b39;
				}
				& svg {
					fill: #fff;
				}
			`
		}
	}
`

const Locked = styled(LockClosedIcon)`
	margin-left: 5px;
`