import React, { useState, memo } from "react";
import styled from "styled-components";
import moment from "moment";

interface Props {
  history: any[];
  users: any;
}

const formatDuration = (seconds: any) => new Date(seconds * 1000).toISOString().substr(11, 8);
const getDuration = (duration: any) => {
  let [hours, minutes, seconds]: any = formatDuration(duration).split(':')
  // remove padding 0
  hours = parseInt(hours)
  minutes = parseInt(minutes)
  seconds = parseInt(seconds)

  const timestring = `${hours ? hours + 'hr ' : ''}${ minutes ? minutes + 'min ' : ''}${seconds ? seconds + 'sec' : ''}`
  return timestring
}

const History = (props: Props) => {
  const { history, users } = props;
  const [isAdvanced, setIsAdvanced] = useState(false)

  if (!Array.isArray(history)) {
    return null;
  }

  let result = history
    .sort(function (a: any, b: any) {
      return a.sequence_number - b.sequence_number;
    })
    .map((d: any) => {
      const username = users[d.user_id]?.username;

      if (d.event === "room-created") {
        return `${moment(d.timestamp).format("HH:mm:ss")}: Meeting Started!`;
      }

      if (d.event === "participant-connected") {
        return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} joined!`;
      }

      if (d.event === "participant-disconnected") {
        return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} disconnected! Duration: ${formatDuration(
          parseInt(d.duration)
        )}`;
      }

      if (d.event === "room-ended") {
        return `${moment(d.timestamp).format("HH:mm:ss")}: Meeting Ended! Duration: ${formatDuration(
          parseInt(d.duration)
        )}`;
      }

      if (d.event === "track-added" || d.event === "track-enabled") {
        if (d.trackKind === "video") {
          if (d.trackName === "screen-share-track") {
            return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} shared his/her screen`;
          } else {
            return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} turned ON his/her video`;
          }
        } else {
          return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} turned ON his/her audio`;
        }
      }

      if (d.event === "track-removed" || d.event === "track-disabled") {
        if (d.trackKind === "video") {
          if (d.trackName === "screen-share-track") {
            return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} stopped sharing his/her screen`;
          } else {
            return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} turned OFF his/her video`;
          }
        } else {
          return `${moment(d.timestamp).format("HH:mm:ss")}: ${username} turned OFF his/her audio`;
        }
      }
    });

  const renderHistory = () => {
    return history
    .sort(function (a: any, b: any) {
      return a.sequence_number - b.sequence_number;
    })
    .filter((d: any) => {
      if (!isAdvanced) {
        if (d.event === "track-added" || d.event === "track-enabled" || d.event === "track-removed" || d.event === "track-disabled") {
          return false
        }
      }

      return true
    })
    .map((d: any, index: any) => {
      const username = users[d.user_id]?.username;
      const time = moment(d.timestamp).format("HH:mm:ss")

      if (d.event === "room-created") {
        return <HistoryRow key={index.toString()}>
          <strong>{time}: </strong> Meeting Started!
        </HistoryRow>;
      }

      if (d.event === "participant-connected") {
        return <HistoryRow key={index.toString()}>
          <strong>{time}: </strong> <strong>{username}</strong> joined!
        </HistoryRow>;
      }

      if (d.event === "participant-disconnected") {
        return <HistoryRow key={index.toString()}>
          <div><strong>{time}: </strong> <strong>{username}</strong> disconnected! <strong>Duration:</strong> {getDuration(parseInt(d.duration))}</div>
        </HistoryRow>;
        
      }

      if (d.event === "room-ended") {
        return <HistoryRow key={index.toString()}>
          <strong>{time}: </strong> Meeting Ended! <strong>Duration:</strong> {getDuration(parseInt(d.duration))}
        </HistoryRow>;
      }

      if (d.event === "track-added" || d.event === "track-enabled") {
        if (d.trackKind === "video") {
          if (d.trackName === "screen-share-track") {
            return <HistoryRow key={index.toString()}>
              <strong>{time}: </strong> <strong>{username}</strong> shared his/her screen
            </HistoryRow>;
          } else {
            return <HistoryRow key={index.toString()}>
              <strong>{time}: </strong> <strong>{username}</strong> turned ON his/her video
            </HistoryRow>;
          }
        } else {
          return <HistoryRow key={index.toString()}>
            <strong>{time}: </strong> <strong>{username}</strong> turned ON his/her audio
          </HistoryRow>;
        }
      }

      if (d.event === "track-removed" || d.event === "track-disabled") {
        if (d.trackKind === "video") {
          if (d.trackName === "screen-share-track") {
            return <HistoryRow key={index.toString()}>
              <strong>{time}: </strong> <strong>{username}</strong> stopped sharing his/her screen
            </HistoryRow>;
          } else {
            return <HistoryRow key={index.toString()}>
              <strong>{time}: </strong> <strong>{username}</strong> turned OFF his/her video
            </HistoryRow>;
          }
        } else {
          return <HistoryRow key={index.toString()}>
            <strong>{time}: </strong> <strong>{username}</strong> turned OFF his/her audio
          </HistoryRow>;
        }
      }
    })
  }

  return (
    <HistoryWrapper style={{ marginTop: 0, width: "30%" }}>
      <h1 style={{ marginBottom: 10 }}>Meeting History</h1>
      <div style={{ marginBottom: 15 }}>
        <a href="#" style={{ color: '#000' }} onClick={() => {
          setIsAdvanced(!isAdvanced)
        }}>
          { isAdvanced ? 'Show Less' : 'Show More'}
        </a>
      </div>
      <HistoryList>
        {
          renderHistory()
        }
      </HistoryList>
    </HistoryWrapper>
  );
};

export default memo(History);

const HistoryRow = styled.div`
  margin-bottom: 4px;
`

const HistoryWrapper = styled.div`
    max-height: calc(100vh - 297px);
    overflow: hidden;
`

const HistoryList = styled.div`
  overflow: auto;
  max-height: calc(100% - 53px);
`