import React, { Component, createRef } from 'react'
import styled from 'styled-components'

import { Button } from '../../components/Elements'
import Api, { Actions, Endpoints } from '../_Shared/Api'
import AceComp from './AceComp'

type Languages = {
    [key: string]: () => void
}

const languages: Languages = {
    javascript: async () => await import(`brace/mode/javascript`),
    java: async () => await import(`brace/mode/java`),
    python: async () => await import(`brace/mode/python`),
    xml: async () => await import(`brace/mode/xml`),
    ruby: async () => await import(`brace/mode/ruby`),
    sass: async () => await import(`brace/mode/sass`),
    markdown: async () => await import(`brace/mode/markdown`),
    mysql: async () => await import(`brace/mode/mysql`),
    json: async () => await import(`brace/mode/json`),
    html: async () => await import(`brace/mode/html`),
    golang: async () => await import(`brace/mode/golang`),
    csharp: async () => await import(`brace/mode/csharp`),
    elixir: async () => await import(`brace/mode/elixir`),
    typescript: async () => await import(`brace/mode/typescript`),
    css: async () => await import(`brace/mode/css`),
    jsx: async () => await import(`brace/mode/jsx`),
}

import 'brace/mode/javascript'
import 'brace/theme/monokai'
import 'brace/ext/language_tools'
import 'brace/ext/searchbox'
import { WidgetContainer } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'

interface Props {
    token: string | null | undefined
    repo: IRepo
    file: IFile
    content: string
    syntax: string
    authFailed: () => void
    UpdateSelf: (obj: object) => void
    AddSelection: (s: string) => void
}

interface IRepo {
    full_name?: string
    name?: string
}

interface IFile {
    path?: string
}

interface State {
    syntax: string
    content: string
    selection: string | null,
    selectionCheck: boolean
}

export default class ViewFile extends Component<Props, State> {
    editor = createRef<any>()

    constructor(props: Props) {
        super(props)

        this.state = {
            syntax: 'javascript',
            content: '',
            selection: null,
            selectionCheck: true
        }
    }

    UNSAFE_componentWillMount() {
        this.GetFile(this.props.token, this.props.repo.full_name || '', this.props.file.path || '')
        this.setSyntax({ target: { value: this.props.syntax } })
    }

    componentDidUpdate() {
        if (this.props.syntax !== this.state.syntax) {
            this.setSyntax({ target: { value: this.props.syntax } })
        }
    }

    async GetFile(token: string | null | undefined, fullRepoName: string, path: string) {
        if (!token) {
            return
        }

        const file = await Api(Endpoints['Github'], Actions['ListFiles'], token, {
            urlPrepend: `/${fullRepoName}`,
            urlAppend: `/${path}`,
        })

        if (!file) {
            return this.props.authFailed()
        }

        this.props.UpdateSelf({ content: atob(file.content) })
    }

    async setSyntax(e: any) {
        const syntax = e.target.value
        await languages[syntax]()
        if (this.props.syntax === syntax) {
            this.setState({ syntax })
        } else {
            this.props.UpdateSelf({ syntax })
        }
    }

    changeSelection = (selection: string) => {
        this.setState({selection})
    }

    changeSelectionCheck = (selectionCheck: boolean) => {
        this.setState({selectionCheck})
    }

    render() {
        return (
            <WidgetContainer>
                <WidgetHeader
                    icon={require('./icon.svg')}
                    pre={<Button onClick={() => this.props.UpdateSelf({ currentFile: null })}>Back</Button>}
                    name={this.props.file.path}
                    end={
                        <React.Fragment>
                            {this.state.selection !== '' && (
                                <Button
                                    {...{
                                        type: 'primary-nobg',
                                        disabled: !this.state.selection || this.state.selectionCheck,
                                    }}
                                    onClick={() => this.props.AddSelection(this.state.selection || '')}
                                >
                                    Add Selection to Gist
                                </Button>
                            )}
                            <Select name='syntax' onChange={this.setSyntax.bind(this)} value={this.state.syntax}>
                                {Object.keys(languages).map((lang) => (
                                    <option key={lang} value={lang}>
                                        {lang}
                                    </option>
                                ))}
                            </Select>
                        </React.Fragment>
                    }
                />
                <AceComp
                    changeSelection={(s: string) => this.changeSelection(s)}
                    changeSelectionCheck={(sc: boolean) => this.changeSelectionCheck(sc)}
                    syntax={this.state.syntax}
                    content={this.props.content}
                    file={this.props.file}
                    updateSelf={this.props.UpdateSelf}
                />
            </WidgetContainer>
        )
    }
}


const Select = styled.select`
    border: 1px solid transparent;
    border-right: 1px solid ${(props) => props.theme.borderPrimary};
    padding: 0 14px;
    cursor: pointer;
    background-color: transparent;
    color: ${(props) => props.theme.textPrimary};
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};

    &:hover {
        background-color: ${(props) => props.theme.hoverPrimary};
    }
    &:focus {
        background-color: ${(props) => props.theme.bgPrimary};
        border: 1px solid ${(props) => props.theme.borderPrimary};
    }
`
