import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { IoIosRewind as ArrowLeftIcon } from '@react-icons/all-files/io/IoIosRewind'
import { IoIosFastforward as ArrowRightIcon } from '@react-icons/all-files/io/IoIosFastforward'

import TopBar from 'screens/Meeting2.0/Widgets/TopBar'
import WidgetRenderer from '/loop-widgets/WidgetRenderer'
import AllWidgets from '../../../loop-widgets'
import jwtDecode from 'jwt-decode'
import Encryption from '/helpers/Encryption'
import Color from 'color'

import LoopApi from '../../../helpers/LoopApi'
export default class WidgetSimulator extends Component {
	constructor(props) {
		super(props)

		this.decoded = jwtDecode(localStorage.token).data._id
		this.state = {
			current_index: 1,
			current_data: {},
		}
	}

	async componentDidMount () {
		if(this.props.simulated_widget) {
			const data = await this.setCheckpoint(1)
			this.setState({
				current_index: 1,
				current_data: data,
			})
		}
	}

	async componentDidUpdate(prevProps){
		if(this.props.simulated_widget !== prevProps.simulated_widget){
			const data = await this.setCheckpoint(1)
			this.setState({
				current_index: 1,
				current_data: data,
			})
		}
	}

	setCheckpoint = async(indx) => {
		const current_index = parseInt(indx) - 1
		const selected = this.props.simulated_widget.all_changes[current_index]
		const defaultData = (
							(
								(
									AllWidgets[
										this.props.simulated_widget.name.replace(/\d/g, '')
									] || {}
								).widgetConfig || {}
							).defaultProps || {}
						).data || {}

		const current_data = await LoopApi(null, 'GetVersion', {
			selectedtime: selected.timestamp,
			name: selected.name,
			version: selected.version,
			defaultData
		})

		return current_data
	}

	async nextCheckpoint(ind) {
		const current_data = await this.setCheckpoint(ind);
		this.setState({ current_index: parseInt(ind), current_data })
	}

	render() {
		if (!this.props.simulated_widget) {
			return null
		}
		const widget_render =
			AllWidgets[this.props.simulated_widget.name.replace(/\d/g, '')]
		let widget_data = {}
		widget_data[this.props.simulated_widget.name.replace(/\d/g, '')] =
			!!Object.keys(this.state.current_data).length && this.state.current_data || widget_render.widgetConfig.defaultProps
		if (
			sessionStorage &&
			sessionStorage.ghost_hash &&
			sessionStorage.ghost_meeting &&
			sessionStorage.ghost_meeting === window.location.pathname.split('/')[2]
		) {
			widget_data = Encryption.decrypt(widget_data)
		}

		const empty_func = () => {}

		const current_change = this.props.simulated_widget.all_changes[
			this.state.current_index
		]

		const users = Array.isArray(this.props.users)
			? this.props.users
			: Object.values(this.props.users).map(u => ({
					type: u.user_type,
					name: u.name,
					status: 'online',
					avatar_url: u.avatar_url,
					id: u.user_id,
			  }))

		let updater_name =
			current_change && users.find(u => u.id === current_change.updaters[0])
		updater_name = updater_name && updater_name.name
		let update_timestamp = this.props.simulated_widget.all_changes[
			this.state.current_index
		]
		update_timestamp =
			update_timestamp &&
			update_timestamp.timestamp &&
			new moment(update_timestamp.timestamp).format('LTS')

		const noKeys = this.props.simulated_widget.name.replace(/\d/g, '') === 'pdfpresenter'

		return (
            <Container>
				<WidgetContainer className="topbar syncBody">
					<TopBar
						className="widgetsimulator-header"
						icon={widget_render.widgetConfig.icon}
						renderedRef={el => (this.top = el)}
						nameOverride={widget_data._displayName}
						displayName={widget_render.widgetConfig.displayName}
						name={this.props.simulated_widget.name}
						minimizeWidget={empty_func}
						expandWidget={empty_func}
					/>
					<WidgetRenderer
						{...(noKeys ? {} : {key: this.state.current_index})}
						userId={this.decoded}
						stateKey={this.props.simulated_widget.name.replace(/\d/g, '')}
						RenderedWidget={widget_render}
						actions={{
							UpdateWidget: empty_func,
						}}
						users={users}
						widgets={widget_data}
						meetingName={window.location.pathname.split('/')[2]}
						transcription={[]}
						external_token={null}
                        travelling={true}
					/>
				</WidgetContainer>
				<Card>
					<InternalCard>
						{/* HIDE UPDATER FOR NOW */}
						{/* <InfoRow>
							{updater_name && (
								<InfoItem>
									<div>Updater:</div>
									<div>{updater_name}</div>
								</InfoItem>
							)}
							{update_timestamp && (
								<InfoItem>
									<div>Date:</div>
									<div>{update_timestamp}</div>
								</InfoItem>
							)}
						</InfoRow> */}
						<ButtonRow>
							<ArrowLeft
								onClick={() =>
									this.state.current_index > 1 && this.nextCheckpoint(this.state.current_index - 1)
								}
							/>
							<ProgressRow className>
								<ProgressBar
									type="range"
									step="1"
									min={1}
									max={this.props.simulated_widget.all_changes.length}
									value={this.state.current_index}
									onChange={e => this.nextCheckpoint(e.target.value)}
								/>
								<span>
									{this.state.current_index} /{' '}
									{this.props.simulated_widget.all_changes.length}
								</span>
							</ProgressRow>
							<ArrowRight
								onClick={() =>
									this.state.current_index < this.props.simulated_widget.all_changes.length &&
									this.nextCheckpoint(this.state.current_index + 1)
								}
							/>
						</ButtonRow>
					</InternalCard>
				</Card>
			</Container>
        );
	}
}

// const InfoRow = styled.div`
// 	display: flex;
// 	align-items: stretch;
// 	margin-bottom: 12px;
// 	flex-direction: column;
// `

// const InfoItem = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	font-size: 16px;
// 	margin: 8px;
// 	min-width: 220px;

// 	> *:first-child {
// 		font-weight: 500;
// 	}
// `

const ArrowLeft = styled(ArrowLeftIcon)`
	border-radius: 4px;
	padding: 7px;
	color: white;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		background: #F89809;
	}
`

const ArrowRight = styled(ArrowRightIcon)`
	border-radius: 4px;
	padding: 7px;
	color: white;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		background: #F89809;
	}
`

const ButtonRow = styled.div`
	display: flex;
	align-items: center;

	background: #363B45;
	border-radius: 150px;
    padding: 4px 10px;
	width: 100%;
	justify-content: center;
`

const ProgressRow = styled.div`
	display: flex;
	align-items: center;
	color: white;
	flex: 1;

	span {
		padding: 0 5px;
		font-size: 12px;
		font-weight: 400;
	}
`

const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: stretch;
    flex-direction: column;
    width: 100%;
`

const InternalCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 70%;
`

const Card = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
    margin-top: 15px;
`

const ProgressBar = styled.input`
	margin-right: 4px;
	cursor: pointer;
	height: 34px;
	flex: 1;
`

const WidgetContainer = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - 358px);
	flex-grow: 1;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	background-color: #F2F4F6 !important;

	> div:first-child > img:first-child {
		margin-left: 15px;
	}

	.widgetTitleBar {
		padding: 12px;

		img {
			height: 24px;
		}

		p {
			font-size: 16px;
		}
	}

	.widgetRender {
		> div.topbar {
			border-radius: 0 0 10px 10px;
		}
	}

	> div:first-child {
		padding: 10px 4px;
	}
`
