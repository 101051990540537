import useDominantSpeaker from './useDominantSpeaker'
import useParticipants from './useParticipants'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import { Room } from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'

export default function useMainParticipantNoScreenshare() {
  const dominantSpeaker = useDominantSpeaker()
  const participants = useParticipants()
  const {room} = useMeetingContext()
  const localParticipant = (room as Room)?.localParticipant || (room as DailyCall)?.participants()?.local

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return dominantSpeaker || participants[0] || localParticipant
}
