import React, {Fragment, useState} from 'react'
import {FiPlus as AddIcon} from '@react-icons/all-files/fi/FiPlus'

import WidgetAdder from '../WidgetAdder'
import WidgetItem, {WidgetContainer} from '../WidgetItem'
import LoopApi from '../../../../helpers/LoopApi'
import AllWidgets from '../../../../loop-widgets'
import KeynoteHelperButtons from '../KeynoteHelperButtons'
import {WidgetActions} from '../../../../stores/MainStore'
import Loader from '../../../../components/Loader'
import Grapl from '../../../../assets/Icon-SVG.svg'

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

const IconSvg = <img src={Grapl} alt="Grapl" />
import {
  CollapseWrapper,
  LeftWrapper,
  List,
  SyncsTileContainer,
  UserAvatarWidget,
  Collapse,
  MobileCollapseWrapper,
} from './StyledComponents'
import WidgetRelevantIndicator from '../WidgetRelevantIndicator'
import {CallingInstanceState} from '../../../../calling/types'
import TopBar from '../TopBar'
import splashIcon from '../../../../assets/icons/splashIcon.svg'
import { ModalActions } from '../../../../stores/ModalStore'

import {FaAngleUp as CollapseIcon} from '@react-icons/all-files/fa/FaAngleUp'
import Scrollbars from 'react-custom-scrollbars'
import styled from 'styled-components'
import CustomTip from '../../../../helpers/CustomTip'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {Img} from 'react-image'
import useMeetingContext from '../../../../contexts/useMeetingContext'

function KeynoteSidebar(props: any) {
  // state
  const [currentWidgetRelative, setCurrentWidgetRelative] = useState(0)

  // constants
  let widgetList: any = []
  let newWidgetList = []
  let currentIsMinimized = false
  const {
    mainstore,
    widget_refs,
    callingstore,
    authstore,
    mobileCollapse,
    toggleMobileCollapse,
  } = props

  // main store
  const {
    db_meeting,
    localCurrentWidget,
    widgets,
    locallyHiddenSync = {},
    meetingName,
    localWidgetOrder,
  } = mainstore
  let {currentWidget} = mainstore
  // calling store
  const { meetingLocked, call_starter, host} = callingstore
  const { callStatus } = useMeetingContext()
  const isInCall = callStatus === CallingInstanceState.Connected
  // auth store
  const {jwt} = authstore
  const userData = jwt?.data

  const enabledWidgets =
    process.env.REACT_APP_ENABLED_WIDGETS ||
    'asana,clickup,dropbox,files,gists,github,googledrive,hubspot,images,links,notes,sharedbnotes,pdfpresenter,scheduler,slack,tictactoe,todo,transcription,twitch,youtube,whiteboard'
  const enabledWidgetsArray = enabledWidgets
    .split(',')
    .map((item) => {
      return item.trim()
    })
    .filter((item) => !!item)

  // functions
  const _setActiveWidget = (name: string | null) => {
    if (!isInCall || (isInCall && !meetingLocked)) {
      const localCurrentWidgets = {
        ...localCurrentWidget,
        [meetingName]: name,
      }
      WidgetActions.SetLocalCurrentWidget({
        localCurrentWidget: localCurrentWidgets,
      })

      // LOCAL STATE
      localStorage.setItem('currentWidget', JSON.stringify(localCurrentWidgets))
    } else {
      if ((host || call_starter) !== userData?._id) return
      // SYNCED BEHAVIOR ** Uncomment after the toggle for host is implemented **
      LoopApi('main', 'SetCurrentWidget', {currentWidget: name})
      WidgetActions.SetCurrentWidget({currentWidget: name})
    }
  }

  const _listscroll = (e: React.SyntheticEvent) => {
    let current_widget_relative = 0
    if (
      currentWidget &&
      !db_meeting?.settings?.minimizedWidgets?.includes(currentWidget) &&
      widget_refs[currentWidget]
    ) {
      const curr_item = widget_refs[currentWidget]
      const parent = curr_item.parentElement
      if (curr_item.offsetHeight / 2 + curr_item.offsetTop < parent.scrollTop) {
        current_widget_relative = -1
      } else if (
        curr_item.offsetTop - curr_item.offsetHeight / 2 >
        parent.scrollTop + parent.clientHeight
      ) {
        current_widget_relative = 1
      }
    }
    if (current_widget_relative !== currentWidgetRelative) {
      setCurrentWidgetRelative(current_widget_relative)
    }
  }

  const transformWidgetList = () => {
    let {minimizedWidgets = []} = db_meeting.settings
    let widgetLst =
      widgets &&
      Object.keys(widgets)
        .filter((w) => AllWidgets[w] || AllWidgets[widgets[w]._component])
        .filter((el) => enabledWidgetsArray.indexOf(el) >= 0)

    if (!isInCall || (isInCall && !meetingLocked)) {
      widgetLst = widgetLst.filter(
        (el: any) =>
          (locallyHiddenSync?.[meetingName] || [])?.indexOf(el) === -1,
      )
    } else {
      widgetLst = widgetLst.filter((el: any) => !minimizedWidgets.includes(el))
    }

    if (
      localWidgetOrder &&
      Object.keys(localWidgetOrder).length &&
      localWidgetOrder[db_meeting._id]
    ) {
      let widgetOrder = localWidgetOrder[db_meeting._id]
      widgetOrder = (widgetOrder || []).filter((w: any) =>
        widgetLst.includes(w),
      )
      const missing = widgetLst.filter((w: any) => !widgetOrder.includes(w))
      const order = [...(widgetOrder || []), ...missing]
      if (order.length) widgetLst = order
    }

    return widgetLst
  }

  if (db_meeting?.settings) {
    let {minimizedWidgets = []} = db_meeting.settings

    currentIsMinimized = minimizedWidgets.includes(currentWidget)
    widgetList = transformWidgetList()

    newWidgetList = widgetList

    let curWidget = currentWidget
    if (
      curWidget &&
      !(enabledWidgetsArray || []).includes(currentWidget) &&
      (enabledWidgetsArray || []).length > 0
    )
      curWidget = enabledWidgetsArray[0]
    if (
      curWidget &&
      !(enabledWidgetsArray || []).includes(currentWidget) &&
      !!!(enabledWidgetsArray || []).length
    )
      curWidget = null
    if (curWidget === undefined && widgetList?.length > 0)
      curWidget = widgetList[0]
    if (curWidget === undefined && !(widgetList || []).length) curWidget = null
    if (currentWidget === undefined && widgetList?.length > 0)
      currentWidget = widgetList[0]
    if (currentWidget === undefined && !(widgetList || []).length)
      currentWidget = null
    if (
      curWidget === null &&
      currentWidget === null &&
      widgetList?.length > 0 &&
      !isInCall
    ) {
      curWidget = widgetList[0]
      currentWidget = widgetList[0]
    }

    widgetList = widgetList.map((w: any, ii: number) => {
      return (
        <Draggable draggableId={ii.toString()} index={ii}>
          {(provided) => (
            <SyncsTileContainer
              data-tip
              data-for={`${w}`}
              className={`topbar rounded inner ${
                currentWidget === w ? 'border-primary' : 'border-topbar'
              }`}
              key={w}
              //ref={(r) => {widget_refs[w] = r; return provided.innerRef}}
              onClick={(e) => {
                e.stopPropagation()
                _setActiveWidget(w)
              }}
              // title={(isInCall && meetingLocked && (host || call_starter) !== userData?._id) ? 'Meeting navigation locked' : AllWidgets?.[w]?.widgetConfig?.displayName}
              {...{current_widget: currentWidget === w, isInCall}}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div
                ref={(r) => {
                  widget_refs[w] = r
                }}
              >
                <CustomTip
                  top={-45}
                  left={15}
                  class="top-left"
                  place="right"
                  tip={
                    isInCall &&
                    meetingLocked &&
                    (host || call_starter) !== userData?._id
                      ? 'Meeting navigation locked'
                      : AllWidgets?.[w]?.widgetConfig?.displayName
                  }
                  dataFor={`${w}`}
                />

                <WidgetItem
                  current_widget={currentWidget === w}
                  preview
                  key={w + 'itemrender'}
                  widgets={widgets}
                  widgetName={w}
                  scrollIntoView={props.scrollWidgetIntoView}
                  // currentIsMinimized={minimizedWidgets.includes(w)}
                />
                {(!isInCall ||
                  (isInCall && !meetingLocked) ||
                  (host || call_starter) === userData?._id) && (
                  <KeynoteHelperButtons
                    widget_name={w}
                    minimized={minimizedWidgets}
                    parentRef={provided.innerRef}
                    onCallLocked={
                      isInCall &&
                      meetingLocked &&
                      (host || call_starter) === userData?._id
                    }
                  />
                )}
              </div>
            </SyncsTileContainer>
          )}
        </Draggable>
      )
    })

    let sidebarTitleSlide
    if (isInCall) {
      sidebarTitleSlide = (
        <SyncsTileContainer
          className={`rounded inner topbar ongoing-call-tile ${
            !currentWidget ? 'border-primary' : 'border-topbar'
          }`}
          key="ongoing_call"
          onClick={() => _setActiveWidget(null)}
          ref={(r) => (widget_refs.splash = r)}
          {...{
            dontBlur: true,
            current_widget: !currentWidget || currentIsMinimized,
            callingSlide: true,
            isInCall,
          }}
        >
          {/* <WidgetContainer className="container" key="ongoing_call" {...{ height: '180px' }}> */}
          <UserAvatarWidget className="rounded inner">
            <ReactVisibilitySensor>
              <Img
                className="rounded inner"
                key={
                  userData?.avatar_url ||
                  `https://ui-avatars.com/api/?name=${
                    userData?.username || userData?.name || ''
                  }&format=svg&background=${
                    userData?.color || 'random'
                  }&color=fefefe` ||
                  'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg'
                }
                src={[
                  userData?.avatar_url,
                  `https://ui-avatars.com/api/?name=${
                    userData?.username || userData?.name || ''
                  }&format=svg&background=${
                    userData?.color || 'random'
                  }&color=fefefe`,
                  'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg',
                ]}
                loader={<Loader />}
                alt={userData?.username || userData?.name}
                unloader={IconSvg}
              />
            </ReactVisibilitySensor>
            {/* <img
                            src={userData.avatar_url || `https://ui-avatars.com/api/?name=${userData.username}&format=svg&background=${userData.color || 'random'}&color=fefefe`}
                            alt={userData.username}
                            className="rounded inner"
                        /> */}
            <div className="ongoing">Ongoing Call</div>
          </UserAvatarWidget>
          {/* </WidgetContainer> */}
        </SyncsTileContainer>
      )
    } else if (!widgetList.length || !enabledWidgetsArray.length) {
      sidebarTitleSlide = (
        <SyncsTileContainer
          className="topbar rounded inner center border-primary"
          key="setup"
          onClick={() => _setActiveWidget(null)}
          ref={(r) => (widget_refs.splash = r)}
          {...{dontBlur: true, current_widget: true, isInCall}}
        >
          <TopBar
            icon={splashIcon}
            displayName="Room Setup"
            name="Room Setup"
          />
        </SyncsTileContainer>
      )
      //sidebarTitleSlide = null
    }
    sidebarTitleSlide && widgetList.unshift(sidebarTitleSlide)
  }

  const onDragEnd = (result: any) => {
    const {destination, source, draggableId} = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    )
      return

    const widgts = transformWidgetList()
    const newWidgetList = Array.from(widgts)
    newWidgetList.splice(source.index, 1)
    newWidgetList.splice(destination.index, 0, widgts[draggableId])
    WidgetActions.ReorderWidgets(newWidgetList)
  }

  const availableSyncs = Object.keys(AllWidgets)
    .filter((w) => !!!AllWidgets[w]?.widgetConfig?.hidden)
    .filter((el) => enabledWidgetsArray.includes(el))
  const addSyncDisabled = !(
    (!isInCall ||
      (isInCall && !meetingLocked) ||
      (host || call_starter) === userData?._id) &&
    availableSyncs?.length !== newWidgetList?.length
  )
  return (
    <Fragment>
      <LeftWrapper {...{collapse: mobileCollapse}} id="left-wrapper">
        <MobileCollapseWrapper>
          <div className="sync-title">Syncs</div>
          <div
            className={`add button primary ${
              addSyncDisabled ? 'disabled' : ''
            }`}
            onClick={
              addSyncDisabled
                ? () => {}
                : () => ModalActions.SetModal('AddWidget')
            }
          >
            <AddIcon size={16} title="Add Syncs" />
          </div>

          <Collapse
            className="button default"
            onClick={() => toggleMobileCollapse()}
            {...{collapse: mobileCollapse}}
          >
            <CollapseIcon size={16} title="Collapse" />
          </Collapse>
        </MobileCollapseWrapper>
        {props.expanded && (
          <CollapseWrapper>
            <span className="sync-title">Syncs</span>
            <div
              className={`button primary ${addSyncDisabled ? 'disabled' : ''}`}
              onClick={
                addSyncDisabled
                  ? () => {}
                  : () => ModalActions.SetModal('AddWidget')
              }
            >
              <AddIcon size={12} data-tip data-for="add-sync" />
            </div>
          </CollapseWrapper>
        )}
        <CustomTip
          top={-20}
          left={0}
          class="top-left"
          place="right"
          tip="Add Syncs"
          dataFor="add-sync"
        />

        {/* onScroll: (e: React.SyntheticEvent) => _listscroll(e), */}
        <List
          {...{
            expanded: props.expanded,
            isInCall: isInCall,
            collapse: mobileCollapse,
          }}
          {...{
            withAdder:
              (!isInCall ||
                (isInCall && !meetingLocked) ||
                (host || call_starter) === userData?._id) &&
              availableSyncs?.length !== newWidgetList?.length,
          }}
        >
          <WidgetAdder
            key="adderbutton"
            toggleSidebar={() => props.setExpanded(!props.expanded)}
            expanded={props.expanded}
            disabled={addSyncDisabled}
          />
          <Scrollbar
            style={{height: '100%'}}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="widgetDroppables">
                {(provided) => {
                  return (
                    <ListWrapper
                      key={`${(isInCall && !meetingLocked) ? 'isInMeeting' : (isInCall && !meetingLocked) ? 'isInMeeting-locked' : 'notInMeetinf' }`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {widgetList}
                      {provided.placeholder}
                    </ListWrapper>
                  )
                }}
              </Droppable>
            </DragDropContext>
          </Scrollbar>
          <WidgetRelevantIndicator
            key="WidgetRelevantIndicator"
            direction={currentWidgetRelative}
          />
        </List>
      </LeftWrapper>
    </Fragment>
  )
}

const ListWrapper = styled.div`
  @media (max-width: 970px) {
    display: flex;
  }
`

const Scrollbar = styled(Scrollbars)`
  /* @media (max-width: 768px) {
		display: flex;
		width: calc(100% - 155px) !important;
		height:160px !important;
		max-height: 160px !important;
		margin-left: 155px;
	}

	@media (max-width: 480px) {
		height: 120px !important;
		max-height: 120px !important;
		margin-left: 115px;
		width: calc(100% - 115px) !important;
	} */
`

export default React.memo(KeynoteSidebar)