import React, { Component } from 'react'
import styled from 'styled-components'
import Icon from './icon.svg'
import IconWithLabel from './icon-with-label.svg'

import { FaLink as LinkIcon } from '@react-icons/all-files/fa/FaLink'

interface Props {
  channel: string
  changeChannel(c: string): void
  addLink: (s: string) => void
}

interface State {
  showModal: boolean
  channel: string
  error: boolean
}

//Regex from react-player
const regexChannel = /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/
const regexVideo = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/
const url = 'https://www.twitch.tv/'

export default class LinkChanger extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showModal: !this.props.channel,
      channel: '',
      error: false
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e: any) {
    e.preventDefault()
    const link = this._checkLink()
    if(link) {
      this.props.changeChannel(this.state.channel)
      this.props.addLink(this.state.channel)
      this.setState({ showModal: false, channel: '', error: false })
    } else {
      this.setState({ channel: '', error: true })
    }
  }

  _checkLink = () => {
    const link = this.state.channel

    if(!link || link.length === 0) {
      this.setState({error: true})
      return
    }

    const matchChannel = link.match(regexChannel)
    if (matchChannel && matchChannel[1]) {
      this.setState({error: false})
      return link
    }

    const matchVideo = link.match(regexVideo)
    if(matchVideo && matchVideo[1]) {
      this.setState({error: false})
      return link
    }

    this.setState({error: true})
    return null;
  }

  render() {

    return (
      <div>
        {this.state.showModal ? (
          <SetupModal isVideoLoaded={!!this.props.channel}>
            <SetupForm onSubmit={this.onSubmit}>
              <a href={url} target="_blank">
							  <img src={IconWithLabel} alt="Twitch" />
              </a>
              <Input
                type="text"
                value={this.state.channel}
                onChange={e => this.setState({ channel: e.target.value, error: false })}
                placeholder="Add Twitch link here"
                className="button topbar border-light"
                error={this.state.error}
              />
              <SubmitButton className="button primary" type="submit" value="Sync Video" />
            </SetupForm>
            <UnderlinedText 
							isVideoLoaded={!!this.props.channel}
							onClick={() => this.setState({ showModal: false, error: false })}
						>
							<span>Close</span>
						</UnderlinedText>
          </SetupModal>
          ) : (
          <Container onClick={() => this.setState({ showModal: true })}>
            <LinkIcon />
          </Container>
          )}
      </div>
    )
  }
}


const SetupModal = styled.div<{isVideoLoaded: boolean}>`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	background-color: ${({isVideoLoaded}) => isVideoLoaded ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
`

const SetupForm = styled.form`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  min-width: 200px;

  > * {
    margin: 4px;
  }

  a {
		align-self: center;
		img {
			height: 60px;
			margin-bottom: 15px;
		}
	}
`

const SubmitButton = styled.input`
  border: none;
`

const Container = styled.div`
  position: absolute;
	right: 10px;
	top: 10px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadows.light};
  background-color: #363B45;
  color: white;
`

const Input = styled.input<{error: boolean}>`
	text-align: left;
	width: 256px;
	border: 1px solid;
	padding-left: 20px !important;
  &[type=text] {
		${({error}) => error ? 'border: 3px solid #ff8700 !important' : null}
	}
`

const UnderlinedText = styled.p<{isVideoLoaded: boolean}>`
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    margin-top: 15px;

    span {
        font-weight: 500;
        font-size: 14px;
		${({isVideoLoaded}) => isVideoLoaded ? 
			'border-bottom: 2px solid #fff; color: #fff;' :
			'border-bottom: 2px solid #363b45; color: #363b45;'
		}
    }
`
