export const Editables = {
    extension: {
        spreadsheet: ['xlsm', 'xls', 'xlsx', 'xlt', 'xla', 'xlam'],
        presentation: ['pptx', 'ppt'],
        document: ['docx', 'doc', 'dot']
    },
    mimetype: {
        spreadsheet: ['application/vnd.google-apps.spreadsheet'],
        presentation: ['application/vnd.google-apps.presentation'],
        document: ['application/vnd.google-apps.document']
    }
}

export const ExportsMenus = {
    'application/epub+zip' : 'EPUB Publication (.epub)',
    'application/pdf' : 'PDF Document (.pdf)',
    'application/rtf' : 'Rich Text Format (.rtf)',
    'application/vnd.oasis.opendocument.presentation' : 'OpenDocument (.odp)',
    'application/vnd.oasis.opendocument.spreadsheet' : 'OpenDocument Format (.ods)',
    'application/vnd.oasis.opendocument.text' : 'OpenDocument Format (.odt)',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation' : 'Microsoft Powerpoint (.pptx)',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'Microsoft Excel (.xlsx)',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'Microsoft Word (.docx)',
    'application/zip' : 'Web page zipped (.html, zipped)',
    'image/jpeg' : 'JPEG image(.jpg)',
    'image/png' : 'PNG image(.png)',
    'image/svg+xml' : 'Scalable Vector Graphics (.svg)',
    'text/csv' : 'Comma-separated values (.csv, current sheet)',
    'text/html' : 'Web page html (.html, zipped)',
    'text/plain' : 'Plain Text (.txt)',
    'text/tab-separated-values': 'Tab-separated values (.tsv, current sheet)'
}