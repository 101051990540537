import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'

import LoopApi from '../../../helpers/LoopApi'
import Modal from '../../../components/Modalv2'
import { ModalActions } from '../../../stores/ModalStore'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import useToastify from '../../../helpers/useToastify'
import { CallingInstanceState } from '../../../calling/types'

const { GlobalState } = require('reflux')

function ConfirmGuestToAccount(props: any) {

    let history = useHistory()
    const [updating, setUpdating] = useState(false)

    const onCancel = () => {
        props.closeModal && props.closeModal()
        ModalActions.SetModal('UserProfile')
	}

	const onLeave = async () => {
        onGuestSubmit()
	}

    const onGuestSubmit = async () => {
		setUpdating(true)
        try {

            let newData: any = {
                ...(GlobalState?.modal?.userProfileData || {})
            }

			const response = await LoopApi(null, 'ConvertAccount', { ...newData }, [['id', GlobalState?.auth?.jwt?.data?._id]])
            setUpdating(false)

            if(!!response.success) {
                useToastify({
                    message: () => SuccessErrorToast({ message: response.msg || 'Successfully converted your account.' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })

                props.closeModal && props.closeModal()
                localStorage.removeItem('token')
                if(localStorage.getItem('audio_input_device_id')) {
                    localStorage.removeItem('audio_input_device_id')
                }
                if(localStorage.getItem('video_input_device_id')) {
                    localStorage.removeItem('video_input_device_id')
                }
                if(localStorage.getItem('audio_output_device_id')) {
                    localStorage.removeItem('audio_output_device_id')
                }
                sessionStorage.removeItem('roomsAllowed')
                localStorage.removeItem('recentRooms')
                history.push('/login')
                window.location.reload()

                
            } else {
                useToastify({
                    message: () => SuccessErrorToast({ message: response.msg || 'Failed to convert account.', type: 'error' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })
                props.closeModal && props.closeModal()
                ModalActions.SetModal('UserProfile')
            }
		} catch (err) {
            useToastify({
                message: () => SuccessErrorToast({ message: err.msg || 'Failed to convert account.', type: 'error' }),
                position: "top-right",
                autoClose: 1500,
                closeButton: false,
                hideProgressBar: true,
                className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                bodyClassName: "grow-font-size",
            })
            props.closeModal && props.closeModal()
            ModalActions.SetModal('UserProfile')
		}
	}

	return (
		<Modal closeModal={() => {
			// props.closeModal()
		}}>
			<Container className="topbar-modal">
					<Contents>
						<Title className="header">Are you sure you want to convert your account?</Title>
						<Details className="subheader">If yes, you will be logged out and will need to verify your email first before you can login. We will send a verification link to your email after you agree. Otherwise, you can cancel this prompt message. </Details>
					</Contents>

					<Options>
						<Button className="button default" disabled={updating} onClick={onCancel}>No</Button>
						<Button className="button primary" disabled={updating} onClick={onLeave}>Yes</Button>
					</Options>
				</Container>
		</Modal>
	)
}

export default ConfirmGuestToAccount

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    background-color: white;
    color: #363B45;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div<{ disabled?: boolean }>`
    ${props => props.disabled ? 'opacity: 0.3; pointer-events: none;' : ''}
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
    font-size: 14px;

    &.button.primary {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); 
        color: white;
    }

	&.button.default {
        color: white; 
        background-color: #363B45;
    } 
`
