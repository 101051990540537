import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import React, { Component } from 'react';
import { FaUser as UserIcon } from '@react-icons/all-files/fa/FaUser';
import moment from 'moment';

import { WidgetContainer } from '../_Shared/Elements';
import ShowNotification from '../../helpers/Notification';
import Loader from '../_Shared/Loader';
import WidgetHeader from '../_Shared/WidgetHeader';
import Api, { Endpoints, Actions } from '../_Shared/Api';
import FormField from '../_Shared/FormField';
import Select from '../_Shared/Select';
import RDatepicker from '../_Shared/Datepicker';

interface Props {
    attachment?: IAttachment; 
    tasks: object[]; 
    users: object[];
    sections: object[];
    task: ITask;
    project: string | null | undefined;
    external_token: string | null | undefined;
    UpdateSelf(obj: object): void;
    authFailed(): void;
}

interface ITasks {
    gid?: string | number;
}

interface ITask {
    gid?: string | number;
    name?: string;
    description?: string;
    assignee?: object;
    status?: string;
    due_on?: string;
    completed?: boolean; 
}

interface IAttachment {
    description?: string;
    html_url?: string;
}

interface State {
    name?: string;
    description?: string;
    loading: boolean;
    assignee?: any;
    status?: string;
    status_details?: any;
    due_on?: any;
    completed?: boolean;
}

export default class Task extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            assignee: '',
            status: '',
            status_details: {},
            due_on: '',
            completed: false,
            loading: false,
        };

        this.getTask = this.getTask.bind(this);
        this.updateTask = this.updateTask.bind(this);
        this.taskQueryConfig = this.taskQueryConfig.bind(this);
        this.createOrUpdateTask = this.createOrUpdateTask.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getTask();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.external_token !== this.props.external_token ||
            prevProps.task.gid !== this.props.task.gid
        ) {
            this.getTask();
        } else if (
            prevProps.task.name !== this.props.task.name ||
            prevProps.task.description !== this.props.task.description ||
            prevProps.task.assignee !== this.props.task.assignee ||
            prevProps.task.status !== this.props.task.status ||
            prevProps.task.due_on !== this.props.task.due_on ||
            prevProps.task.completed !== this.props.task.completed
        ) {
            this.setState({
                ...(this.props.task as any),
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            name: '',
            description: '',
            due_on: '',
            status: '',
            assignee: '',
        });
    }

    async getTask() {
        if (!this.props.external_token || !this.props.task.gid) {
            return;
        }

        this.setState({ loading: true }); 
        const resp = await Api(
            Endpoints['Asana'],
            Actions['GetTask'],
            this.props.external_token,
            {
                urlAppend: `/${this.props.task.gid}`,
            }
        );
        if (resp.errors || !resp.data) {
            this.setState({ loading: false });
            return this.props.authFailed();
        }

        this.updateTask({
            name: resp.data.name,
            description: resp.data.notes,
            assignee: resp.data.assignee?.gid || '', 
            status:
                (resp.data.memberships &&
                    resp.data.memberships.length &&
                    resp.data.memberships[0]?.section?.gid) ||
                '',
            due_on: resp.data.due_on || '',
        });
        this.setState({ loading: false });
    }

    updateTask(t: object) {
        const task = { ...this.props.task, ...t };
        this.props.UpdateSelf({ task });
    }

    taskQueryConfig() {
        return this.props.task.gid
            ? {
                  action: Actions['UpdateTask'],
                  urlAppend: `/${this.props.task.gid}`,
                  data: {
                      assignee: this.state.assignee || 'me',
                      name: this.state.name,
                      notes: this.state.description,
                      due_on: this.state.due_on,
                  },
              }
            : {
                  action: Actions['CreateTask'],
                  urlAppend: `?projects=${this.props.project}`,
                  data: {
                      assignee: this.state.assignee || 'me',
                      name: this.state.name,
                      notes: this.state.description,
                      section: this.state.status,
                      due_on: this.state.due_on,
                  },
              };
    }

    async createOrUpdateTask() {
        this.setState({ loading: true });
        const { action, data, urlAppend } = this.taskQueryConfig();
        const BodyData = Object.assign({}, data);
        if (
            this.props.attachment &&
            this.props.attachment.description &&
            this.props.attachment.html_url
        ) {
            BodyData.notes = `Attached Gist:\n${this.props.attachment.description}\n${this.props.attachment.html_url}`;
        }

        if (!this.state.name) {
            this.setState({ loading: false });
            ShowNotification({
                message: 'Task name is required',
                type: 'error',
            });
            return //console.log('Invalid name or Description');
        }
        const resp = await Api(
            Endpoints['Asana'],
            action,
            this.props.external_token,
            { 
                urlAppend: urlAppend || '',
                body: { data: BodyData },
            }
        );

        const respstatus = await Api(
            Endpoints['Asana'],
            Actions['UpdateStatus'],
            this.props.external_token,
            {
                urlAppend: resp.data.gid
                    ? `/${resp.data.gid}/addProject`
                    : this.props.task.gid
                    ? `/${this.props.task.gid}/addProject`
                    : '',
                body: {
                    data: Object.assign(
                        {},
                        {
                            section: this.state.status,
                            project: this.props.project,
                        }
                    ),
                },
            }
        );

        this.setState({ loading: false });
        if (resp.errors) return //console.log('error');
        if (!this.props.task.gid) {
            //Means Add Mode,Push New Data to List
            const { gid = '', name = '', resource_type = '' } = resp.data;
            this.props.UpdateSelf({
                tasks: [
                    { gid: gid, name: name, resource_type: resource_type },
                    ...this.props.tasks,
                ],
                task: null,
            });
        } else {
            //Means Update Mode , Update Data in List
            let tasks = this.props.tasks;
            let taskInd = tasks.findIndex(
                (t: ITasks) => t.gid === this.props.task.gid
            );
            const { gid = '', name = '', resource_type = '' } = resp.data;
            tasks[taskInd] = {
                gid: gid,
                name: name,
                resource_type: resource_type,
            };
            this.props.UpdateSelf({ tasks: tasks, task: null });
        }
    }

    handleChange(e: any) {
        this.setState({ [e.target.name]: e.target.value } as Pick<
            State,
            keyof State
        >);
    }

    render() {
        const optionsList =
            this.props.users && this.props.users.length > 0 
                ? this.props.users.map((user: any) => ({
                      value: user.gid,
                      label: (
                          <UserWrapper>
                              <ImageWrapper title={user.name || ''}>
                                  <img
                                      src={
                                          user.photo?.image_36x36 ||
                                          `https://ui-avatars.com/api/?name=${user.name}&format=svg`
                                      }
                                      alt={user.name}
                                  />
                              </ImageWrapper>{' '}
                              {user.name}
                          </UserWrapper>
                      ),
                  }))
                : [];

        const finalOutput = this.state.due_on
            ? moment(this.state.due_on).calendar({ 
                  sameDay: '[Today]', 
                  nextDay: '[Tomorrow]',
                  nextWeek: 'dddd',
                  lastDay: '[Yesterday]',
                  lastWeek: 'MMM DD',
                  sameElse: 'MMM DD',
              })
            : '';

        let valueClassName = '';

        if (finalOutput === 'Today' || finalOutput === 'Tomorrow')
            valueClassName = 'success';
        if (
            (finalOutput === 'Yesterday' && !!!this.state.completed) ||
            (this.state.due_on &&
                moment().isAfter(this.state.due_on) &&
                !!!this.state.completed)
        )
            valueClassName = 'error';

        return (
            <WidgetContainer padding="30px">
                {/* { this.state.loading && <Loader />} */}
                <WidgetHeader
                    name={
                        <Header>
                            <Ico>
                                <img src={require("./icon.svg")} />
                            </Ico>
                            Task list
                        </Header>
                    }
                    subname="You can edit and update the task details here"
                    end={
                        <React.Fragment>
                            <Button
                                className="button"
                                onClick={() =>
                                    this.props.UpdateSelf({ task: null })
                                }
                            >
                                Back
                            </Button>
                            <Button
                                className="button default"
                                onClick={() => this.createOrUpdateTask()}
                            >
                                {this.props.task.gid ? 'Update' : 'Save'}
                            </Button>
                        </React.Fragment>
                    }
                />
                <TaskWrapper>
                    <TextareaAutosize
                        name="name"
                        value={this.state.name}
                        className="textarea hover-container task-title" 
                        placeholder="New Task"
                        onResize={(e) => {}}
                        onChange={(e: any) =>
                            this.setState({ name: e.target.value })
                        }
                    />
                    <FormField
                        className="asana-assignee" 
                        label="Assignee"
                        field={
                            <div>
                                <Select
                                    hoverEffect
                                    fitContent
                                    name="assignee"
                                    defaultValue={this.state.assignee || ''}
                                    placeholder={
                                        <UserWrapper>
                                            <ImageWrapper
                                                className="placeholder"
                                                title={'Select a user'} 
                                            >
                                                <UserIcon />
                                            </ImageWrapper>{' '}
                                            {`Select a user`}
                                        </UserWrapper>
                                    }
                                    onChange={this.handleChange}
                                    optionsList={optionsList}
                                    selectWrapperStyle={{zIndex: 4}}
                                />
                            </div>
                        }
                    />
                    <FormField
                        label="Status"
                        field={
                            <div>
                                <Select
                                    hoverEffect
                                    fitContent
                                    name="status"
                                    defaultValue={this.state.status || ''}
                                    placeholder={'Select a status'}
                                    onChange={this.handleChange}
                                    optionsList={this.props.sections.map(
                                        (section: any) => {
                                            return {
                                                value: section.gid,
                                                label: section.name,
                                            };
                                        }
                                    )}
                                />
                            </div>
                        }
                    />

                    <FormField
                        label="Due date"
                        field={
                            <div>
                                <RDatepicker
                                    name="due_on"
                                    wrapperClassName="datepicker"
                                    placeholderText="No due date"
                                    displayOnly
                                    enableIcon
                                    onChangeRaw={(e: any) => e.preventDefault()}
                                    onSelect={(date) =>
                                        this.setState({
                                            due_on: moment(date).format(
                                                'YYYY-MM-DD'
                                            ),
                                        })
                                    }
                                    selected={
                                        this.state.due_on
                                            ? moment(this.state.due_on).toDate()
                                            : ''
                                    }
                                    onChange={() => {}}
                                    readOnly
                                    dateFormat="MMMM dd"
                                    valueClassName={valueClassName}
                                />
                            </div>
                        }
                    />
                    <FormField
                        label="Description"
                        field={
                            <TextareaAutosize
                                style={{ width: 'calc(100% - 12px)' }}
                                value={this.state.description}
                                className="textarea over-container-focus hover-container focus"
                                placeholder="Add more detail to this task..."
                                rows={4}
                                onResize={(e) => {}}
                                onChange={(e: any) =>
                                    this.setState({
                                        description: e.target.value,
                                    })
                                }
                            />
                        }
                    />
                </TaskWrapper>
            </WidgetContainer>
        );
    }
}

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    min-width: 100px;
    margin: 0px 2px;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }
`;

const ImageWrapper = styled.div`
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: 10px;

    img {
        width: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
        font-size: 12px;
    }
`;

const TaskWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    flex-wrap: wrap;

    .task-title {
        font-size: ${(props) => props.theme.textLG};
    }

    > * {
        margin-top: 5px;
    }
`;

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
`;


const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Ico = styled.div`
    display: flex;
    background-color: transparent;

    > img {
        height: 35px;
        border: none;
        align-self: center;
        margin: 0 14px;
    }
`
