import React, { Component } from 'react'
import styled from 'styled-components'

interface Props {
	file: any
    back: Function
}

const GoogleDriveFile = (props: Props) => {
    const {file: {mtype, id}, back} = props
    let src = ''
    switch (mtype) {
        case 'presentation':
            src = `https://docs.google.com/presentation/d/${id
                }?widget=true&headers=true`
            break
        case 'spreadsheet':
            src = `https://docs.google.com/spreadsheets/d/${id
                }?widget=true&headers=true`
            break
        case 'document':
            src = `https://docs.google.com/document/d/${id
                }/edit?usp=drivesdk`
            break
        default:
            src = `https://docs.google.com/file/d/${id}/preview?usp=drivesdk`
    }

    return (
        <Container>
            <Back 
                className="button default" 
                onClick={() => back()}
            >
                Back
            </Back>
            <Frame
                src={src}
                key={id}
                id="gframe"
            />
        </Container>
    )
}

const Frame = styled.iframe`
	flex: 1;
	width: 100%;
    height: 100%;
	border-radius: 15px;
	padding: 10px;
`

const Back = styled.div`
    width: 55px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 12px;
`

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
`

export default GoogleDriveFile