import React from 'react'
import styled from 'styled-components'
import { ModalActions } from '../../stores/ModalStore'
import { AiOutlineCheck } from 'react-icons/ai'
import useToastify from '../../helpers/useToastify'
import { Zoom } from 'react-toastify'
import { CallingInstanceState } from '../../calling/types'

const GlobalState = require('reflux')

interface Props {
    fetchSpacesAndRooms?: () => void
    meeting: any
    meetingData: any
    user: any
    color_scheme: string
    call_status: any
}

function WorkspaceOptions(props: Props) {
    const { fetchSpacesAndRooms = () => {}, meeting, color_scheme = 'Light', call_status, user, meetingData } = props
    const SetMeetingAvatar = () => {
		ModalActions.SetModalWithParams('SetMeetingAvatar', { cb: () => {
            localStorage.removeItem('cached-rooms')
			fetchSpacesAndRooms()
		}, currentMeeting: meeting })
	}

    const LeaveMeeting = () => {
		ModalActions.SetModalWithParams('LeaveMeeting', { 
            modallabel: meeting?.creator_user_id === user?._id ? 'Archive' : 'Remove',
            meeting_name: meeting?.name_alias || meeting?.name, meetingId: meeting?.name, cb: () => {
                localStorage.removeItem('cached-rooms')
                fetchSpacesAndRooms()
            }})
	}

    const RenameRoom = () => {
		ModalActions.SetModalWithParams('RenameRoom', { 
            meeting_name: meeting?.name_alias || meeting?.name, meetingId: meeting?.name,
            cb: () => {

                localStorage.removeItem('cached-rooms')
                fetchSpacesAndRooms()
            }})
    }

    const copyRoomID = () => {
		navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL || 'http://localhost:1234'}/${meeting?.name}`)


		useToastify({
			hideProgressBar: true,
			message: <Copied><AiOutlineCheck /> Copied to clipboard</Copied>,
			position: 'top-center',
			autoClose: 1500,
			toastId: 'meetingLinkCopied',
			className: (color_scheme || 'Light') === 'Light' ? 'toastL ' : 'toastD',
			transition: Zoom
		})
	}


					// onClick={GlobalState?.main?.meetingName === null || status !== CallingInstanceState.UninitializedWithoutToken ? () => {} : LeaveMeeting} 
				
					// disabled={GlobalState?.main?.meetingName === null || status !== CallingInstanceState.UninitializedWithoutToken}
					// title={status !== CallingInstanceState.UninitializedWithoutToken ? 'Leave the meeting to remove this room' : 'Remove Room'}
    
    return (
        <Wrapper>
            <div onClick={copyRoomID}>Copy Room Link</div>
            {
                meeting?.creator_user_id === user?._id &&
                <React.Fragment>
                    <div onClick={RenameRoom}>Rename Room</div>
                    <div onClick={meeting?.name === null ? () => {} : SetMeetingAvatar}>Set Room Icon</div>
                </React.Fragment>
            }
            <div className={meetingData.name === meeting.name && call_status !== CallingInstanceState.UninitializedWithoutToken ? 'disabled' : ''} onClick={
                meetingData.name === meeting.name && call_status !== CallingInstanceState.UninitializedWithoutToken ? 
                () => {} : 
                LeaveMeeting 
            }
                title={meetingData.name === meeting.name && call_status !== CallingInstanceState.UninitializedWithoutToken ? `Leave the meeting to ${meeting?.creator_user_id === user?._id ? 'archive' : 'remove'} this room` : `${meeting?.creator_user_id === user?._id ? 'Archive' : 'Remove'} room`}
            >{meeting?.creator_user_id === user?._id ? 'Archive' : 'Remove'} Room</div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        padding: 5px 0;
        cursor: pointer;
    }

    .disabled {
		cursor: default;
		opacity: 0.3;
    }
`

const Copied = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 5px;
	}
`

export default WorkspaceOptions
