import React, { FormEvent, memo } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { useWindowUnloadEffect } from '../../../helpers/useWindowUnloadEffect'

interface Props  { 
    title: string, 
    isPrivate?: boolean,
    updateTitle: (e: FormEvent<HTMLTextAreaElement>, isPrivate?: boolean, fromBlur?: boolean) => void 
}

function TitleEditor(props: Props) {
    const { title, updateTitle, isPrivate } = props

    useWindowUnloadEffect(() => {
        updateTitle(title as any, isPrivate, true)
    }, true)
    return (
        <TextareaAutosize
            name="title"
            value={title || ''}
            className="textarea topbar notes-title"
            placeholder="New Note"
            onResize={(e) => { }}
            onChange={(e: FormEvent<HTMLTextAreaElement>) => updateTitle(e, isPrivate)}
            onBlur={(e: FormEvent<HTMLTextAreaElement>) => updateTitle(e, isPrivate, true)}
            maxRows={2}
            async={true}
        />
    )
}

function areEqual(prevProps: Props, nextProps: Props) {
    return prevProps.title === nextProps.title &&
    prevProps.isPrivate === nextProps.isPrivate
}


export default memo(TitleEditor, areEqual)
