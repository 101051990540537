import React, { useEffect, useMemo, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { IChat, IContext } from '../types'

import Lightbox from 'react-images'
import Chats from './Chats'
import DummyAttachment from './DummyAttachment'
import { ChatContext } from '../ChatContext'


import { AiOutlineDown } from '@react-icons/all-files/ai/AiOutlineDown'
import { withMemoizedContext } from 'react-memoized-context';
import { slideInUp, slideOutDown } from 'react-animations'
interface Props {
  context: IContext
}

function Conversations({ context }: Props) {

  const [scrollHeight, setScrollHeight] = useState(0)
  const [showFloat, setShowFloat] = useState(false)
  const [badge, setBadge] = useState(0)

  const scrollableRootRef = React.useRef<HTMLDivElement | null>(null);

  const handleRootScroll = React.useCallback(() => {
    const rootNode = scrollableRootRef.current;

    if (rootNode) {

      if (rootNode.scrollTop < -200) {
        setShowFloat(true)
      } else {
        setShowFloat(false)
      }

      if (scrollHeight !== rootNode?.scrollHeight)
        return setScrollHeight(rootNode.scrollHeight)

      const scrollPosition = Math.ceil(-1 * rootNode.scrollTop)
      const availableScroll = Math.ceil(scrollHeight - rootNode.clientHeight)
      const tolerance = 100

      if ((scrollPosition + tolerance > availableScroll) && !context.fetching)
        context.fetchMoreData()

    }

  }, [scrollHeight, context.fetching]);



  useEffect(() => {
    let query: any = null
    const { receiver, meetingURL } = context
    //@ts-ignore
    query = window.shareDbConnection.createSubscribeQuery('chat-contents', {
      receiver,
      meetingURL,
      // sender: props.sender
    })

    query.on('insert', function (docs: any, index: number, one: any) {
      console.log('insert', docs, one);

      if (docs[0].data.sender.id === context.myId)
        return setTimeout(() => {
          handleFloatingButtonClick()
        }, 10);

      const count = JSON.parse(localStorage.getItem('badge') || '0')
      setBadge(count + 1)
      localStorage.setItem('badge', JSON.stringify(count + 1))
    })

    return () => {
      query.destroy()
    }

  }, [])

  useEffect(() => {
    localStorage.setItem('badge', '0')
    setBadge(0)
  }, [showFloat])


  const handleFloatingButtonClick = () => {
    const rootNode = scrollableRootRef.current;

    if (!rootNode) return
    rootNode.style.scrollBehavior = 'smooth'
    rootNode.scrollTo(0, 0)
    rootNode.style.scrollBehavior = 'unset'
  }




  return (
    <Binder>
      <Loading show={context.fetching} className='topbar' >Loading...</Loading>
      <ChatContent id="scrollableDiv" ref={scrollableRootRef} onScroll={handleRootScroll}>
        <MainCont >
          <Chats />
          <DummyAttachment />
        </MainCont>
      </ChatContent>

      <FloatingButton
        className="container main"
        onClick={handleFloatingButtonClick}
        isScrollDownBtnVisible={showFloat}
      >
        <AiOutlineDown />
        {
          (badge > 0 && (
            <span>{badge}</span>
          ))
        }
      </FloatingButton>
    </Binder>
  )
}

const ComponentContexted = withMemoizedContext(ChatContext)(Conversations, ['fetching', 'meetingURL', 'receiver', 'myId', 'fetchMoreData']);


export default ComponentContexted

const slideInUpAnimation = keyframes`${slideInUp}`;
const slideOutAnimation = keyframes`${slideOutDown}`;

const FloatingButton = styled.button<{ isScrollDownBtnVisible: boolean }>`
    position: absolute;
    bottom: 40px;
    right: 23px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: ${props => props.theme.shadows.newheavy};
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #fcfcfc;
    animation: ${props => props.isScrollDownBtnVisible ? css`.4s ${slideInUpAnimation}` : css`.2s ${slideOutAnimation} forwards`};
    opacity: ${props => props.isScrollDownBtnVisible ? 1 : 0};
    transition: 300ms;


	span {
		position: absolute;
        top: -5px;
        right: -5px;
        width: 15px;
        height: 15px;
        background: #FF3C3C;
        color: white;
        font-size: 9px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  /* overflow: auto; */

  /* flex: 1;
  display: flex;
  flex-direction: column; */

`

const ChatContent = styled.div`
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
`

const Loading = styled.div <{ show: boolean }>`
  position: absolute;
  top: ${({ show }) => show ? 0 : '-20px'};
  opacity : ${({ show }) => show ? 1 : 0};
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;
  z-index: 2;
  box-shadow:  ${({ show }) => show ? '0 0 10px 0 #00000041' : 0} ;
  transition: 300ms;
`

const Binder = styled.div`
  position: relative;
  display: flex;
  height: 100px;
  flex: 1;
  overflow: hidden;
`

