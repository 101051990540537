import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'
import { AiOutlineCheckCircle } from 'react-icons/ai'

import LoopApi from '../../../helpers/LoopApi'
import Modal from '../../../components/Modalv2'

const { GlobalState } = require('reflux')

import Checkbox from '../../../components/Checkbox'
import { AuthActions } from '../../../stores/AuthStore'
import useToastify from '../../../helpers/useToastify'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import { ModalActions } from '../../../stores/ModalStore'

interface IData {
    username: string
    password: string,
    confirmPassword: string
    first_name: string
    last_name: string
    email: string
    phone: string
}

function UserProfile(props: any) {
	const [ data, setData ] = useState<IData>({
        username: '',
        password: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    })
    const [ initialData, setInitialData ] = useState<IData>({
        username: '',
        password: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
    })
	const [ errorMsg, setErrorMsg ] = useState<string | null>('')
	const [ updating, setUpdating ] = useState<boolean>(false)
    const [ willUpdatePassword, setUpdatePassword ] = useState<boolean>(false)

    useEffect(() => {
        //console.log("GlobalState?.auth?.jwt?.data: ", GlobalState?.auth?.jwt?.data)
        const { username = '', email = '', first_name = '', last_name = '', phone = '', marketingEmail = '' } = GlobalState?.auth?.jwt?.data
        setData({
            ...data,
            username,
            email: email || marketingEmail,
            first_name,
            last_name,
            phone
        })
        setInitialData({
            ...data,
            username,
            email: email || marketingEmail,
            first_name,
            last_name,
            phone
        })
    }, [])

    useEffect(() => {
        if(GlobalState?.auth?.jwt?.data?.type === 'guest') {
            setUpdatePassword(true)
        }
    }, [GlobalState?.auth?.jwt?.data?.type])

    useEffect(() => {
        if(GlobalState?.modal?.userProfileData) {
            setData({ ...(GlobalState?.modal?.userProfileData || {}) })
        }
    }, [GlobalState?.modal?.userProfileData])

	const updateValue = (e: any) => {
        let value = e.target.value
        if(
            (data?.first_name?.length > 50 && data?.last_name?.length > 50) ||
            (data?.first_name?.length > 50 && (e.target.name === 'last_name' && e.target.value?.length > 50)) ||
            (data?.last_name?.length > 50 && (e.target.name === 'first_name' && e.target.value?.length > 50))
        ) {
            setErrorMsg('First name and Last name should not exceed more than 50 characters')
        } else if(data?.last_name?.length > 50 || (e.target.name === 'last_name' && e.target.value?.length > 50)) {
            setErrorMsg('Last name should not exceed more than 50 characters')
        } else if(data?.first_name?.length > 50 || (e.target.name === 'first_name' && e.target.value?.length > 50)) {
            setErrorMsg('First name should not exceed more than 50 characters')
        } else if(data?.username?.length > 50 || (e.target.name === 'username' && e.target.value?.length > 50)) {
            setErrorMsg('Display name should not exceed more than 50 characters')
        } else if(e.target.name === 'phone') {
            value = value.replace(/[^0-9]/g, '');
            if(value.length > 10) {
                setErrorMsg('Phone number should not exceed more than 10 characters')
            } else {
                setErrorMsg(null)
            }
        } else if(data?.phone?.length > 10) {
            setErrorMsg('Phone number should not exceed more than 10 characters')
        } else {
            setErrorMsg(null)
        }





		setData({
            ...data,
            [e.target.name]: value
        })

	}


	const onSubmit = async (e: any) => {
		e.preventDefault()
        if(GlobalState?.auth?.jwt?.data?.type === 'guest') {
            return ModalActions.SetModalWithParams('ConfirmGuestToAccount', { userProfileData: data })
        }
		setErrorMsg(null)
		setUpdating(true)
        try {
            if(willUpdatePassword && !_isInputGood()) {
                setUpdating(false)

                return setErrorMsg('Password is invalid')
            }

            let newData: any = {}
            let errors: any = {
                name: false,
                pw: false
            }

            newData.username = data?.username
            if(data?.first_name !== initialData?.first_name && !!data?.first_name) newData.first_name = data?.first_name
            if(data?.last_name !== initialData?.last_name && !!data?.last_name) newData.last_name = data?.last_name
            if(data?.phone !== initialData?.phone && data?.phone !== '') newData.phone = data?.phone

			const token = await LoopApi(null, 'SetName', { ...newData })
			if (!!token && token.token) {
				localStorage.token = token.token
				AuthActions.SetJwt(token.token)
			}
            if(token.error) errors.name = token.msg || 'Something went wrong.'

            if(willUpdatePassword && data?.password) {
                const resp = await LoopApi(null, 'ChangePassword', { password: data?.password });
                if(!resp) errors.pw = 'Something went wrong.'
                if(resp.error) errors.pw = resp.msg || 'Something went wrong.'
            }


            setUpdating(false)

            if(!!!errors.name && !!!errors.pw) {
                useToastify({
                    message: () => SuccessErrorToast({ message: 'Successfully updated profile.' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })
            } else {
                useToastify({
                    message: () => SuccessErrorToast({ message: errors.pw || errors.name || 'Failed to update profile.', type: 'error' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })
            }

            GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
			props.closeModal && props.closeModal()
		} catch (err) {
			console.error(err)
			setErrorMsg(`${err}`)
		}
		// try {
        //     const { exists } = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
		// 		['name', roomName],
		// 	])

        //     if (exists) {
		// 		return setErrorMsg('This room name is taken')
		// 	}

		// 	let response = {}

		// 	if(GlobalState.modal.space_id !== 'global') {
		// 		response = await LoopApi(null, 'CreateRoomInSpace', {
		// 			name: roomName
		// 		}, [['id', GlobalState.modal.space_id]])
		// 	} else {
		// 		response = await LoopApi(null, 'CreateMeeting', {
		// 			name: roomName,
		// 			is_public: true,
		// 		})
		// 	}

		// 	setCreating(false)
		// 	GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
		// 	GlobalState.modal.onSubmit && GlobalState.modal.onSubmit(response)
		// 	props.closeModal && props.closeModal()
		// } catch (e) {
		// 	setCreating(false)
		// 	setErrorMsg(`${e}`)
        //     if(e.includes('limit')) ModalActions.SetModal('Subscription')
		// }
	}

    const _validatorUI = () => {
        const { password, confirmPassword } = data
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return {
            minMax: password.length >= 8 && password.length <= 16 ? true : false,
            lower: /[a-z]/.test(password) ? true : false,
            upper: /[A-Z]/.test(password) ? true : false,
            numeric: /\d/.test(password) ? true : false,
            symbol: format.test(password) ? true : false,
            confirm: password === confirmPassword ? true : false,
        };
    }

    const _isInputGood = () => {
        const { password, confirmPassword } = data
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const notempty = password && confirmPassword;
        const minLen = password.length >= 8;
        const maxLen = password.length <= 16;
        const lowercase = /[a-z]/.test(password);
        const uppercase = /[A-Z]/.test(password);
        const numeric = /\d/.test(password);
        const special = format.test(password);
        return (
          password === confirmPassword &&
          notempty &&
          minLen &&
          maxLen &&
          lowercase &&
          uppercase &&
          numeric &&
          special
        );
      }

    const _helper = () => {
        return <React.Fragment>
          <HelperWrapper className={`${_validatorUI().minMax ? 'success' : 'error'}`}>
              <CircleIcon /> At least 8-16 characters long
          </HelperWrapper>
          <HelperWrapper className={`${_validatorUI().lower ? 'success' : 'error'}`}>
              <CircleIcon /> One lowercase character
          </HelperWrapper>
          <HelperWrapper className={`${_validatorUI().upper ? 'success' : 'error'}`}>
              <CircleIcon /> One uppercase character
          </HelperWrapper>
          <HelperWrapper className={`${_validatorUI().numeric ? 'success' : 'error'}`}>
              <CircleIcon /> One numeric character
          </HelperWrapper>
          <HelperWrapper className={`${_validatorUI().symbol ? 'success' : 'error'}`}>
              <CircleIcon /> One special character
          </HelperWrapper>
          <HelperWrapper className={`${_validatorUI().confirm ? 'success' : 'error'}`}>
              <CircleIcon /> Password matched
          </HelperWrapper>
        </React.Fragment>
        // const { password, confirm_password } = this.state;
        // var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        // if (password.length < 8) return 'Password must at least 8 characters.';
        // if (password.length > 16) return 'Password must at maximum of 16 characters.';
        // if (!/[a-z]/.test(password)) return 'At least one lowercase character.';
        // if (!/[A-Z]/.test(password)) return 'At least one uppercase character.';
        // if (!/\d/.test(password)) return 'At least one numeric character.';
        // if (!format.test(password)) return 'At least one special character.';
        // if (password !== confirm_password) return 'Please make your password match.';
    }

    const handleCancel = () => {
        props.closeModal();
    }

	return (
		<Modal contentStyle={{ overflowY: 'hidden' }} closeModal={() => {
			GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
			props.closeModal()
		}} dismissible={() => props.closeModal()}>
			<Container className="topbar-modal">
				<Title className="header">{GlobalState?.auth?.jwt?.data?.type === 'guest' ? 'Register Account' : 'Update Profile'}</Title>
				<Subtitle className="subheader">{GlobalState?.auth?.jwt?.data?.type === 'guest' ? 'Convert from guest to account user' : 'Change your basic information here'}</Subtitle>
                <Scrollbars
					autoHide
					autoHideTimeout={1000}
					autoHideDuration={200}
                    autoHeight
                    autoHeightMax="calc(80vh - 200px)"
                >
                    <NameForm onSubmit={onSubmit}>
                        <Field>
                            <Label htmlFor="username">Display Name</Label>
                            <Input
                                className="border-light topbar"
                                type="text"
                                name="username"
                                value={data?.username}
                                onChange={updateValue}
                                placeholder={`Username`}
                                autoFocus
                                required
                            />
                        </Field>

                        <Field>
                            <Label htmlFor="first_name">First Name</Label>
                            <Input
                                className="border-light topbar"
                                type="text"
                                name="first_name"
                                value={data?.first_name}
                                onChange={updateValue}
                                placeholder={`First Name`}
                                autoComplete="off"
                            />
                        </Field>

                        <Field>
                            <Label htmlFor="last_name">Last Name</Label>
                            <Input
                                className="border-light topbar"
                                type="text"
                                name="last_name"
                                value={data?.last_name}
                                onChange={updateValue}
                                placeholder={`Last Name`}
                                autoComplete="off"
                            />
                        </Field>

                        <Field>
                            <Label htmlFor="email">Email Address</Label>
                            <Input
                                className="border-light topbar container"
                                type="text"
                                name="email"
                                value={data?.email}
                                onChange={updateValue}
                                placeholder={`Email Address`}
                                disabled
                            />
                        </Field>

                        <Field>
                            <Label htmlFor="email">Phone</Label>
                            <Input
                                className="border-light topbar"
                                type="text"
                                name="phone"
                                value={data?.phone}
                                onChange={updateValue}
                                placeholder={`Phone number`}
                            />
                        </Field>
                        {
                            GlobalState?.auth?.jwt?.data?.type === 'account' ? <FieldWrapper>
                                <Checkbox
                                    name="updatepassword"
                                    checked={willUpdatePassword}
                                    onChange={() => { setUpdatePassword(!willUpdatePassword); setErrorMsg(null); }}
                                />
                                <Label htmlFor="updatepassword">Change Password</Label>
                            </FieldWrapper> : ''
                        }
                        {
                            willUpdatePassword &&
                            <React.Fragment>
                                <Field>
                                    <Label htmlFor="password">Password</Label>
                                    <Input
                                        className="border-light topbar"
                                        type="password"
                                        name="password"
                                        value={data?.password}
                                        onChange={updateValue}
                                        placeholder={`Password`}
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="confirmPassword">Confirm Password</Label>
                                    <Input
                                        className="border-light topbar"
                                        type="password"
                                        name="confirmPassword"
                                        value={data?.confirmPassword}
                                        onChange={updateValue}
                                        placeholder={`Confirm Password`}
                                    />
                                </Field>
                                <HelperContainer>{_helper()}</HelperContainer>
                            </React.Fragment>
                        }

                        <SubmitButton
                            className="button primary"
                            type="submit"
                            value={GlobalState?.auth?.jwt?.data?.type === 'guest' ? 'Register' : GlobalState?.auth?.jwt?.data?.type === 'guest' && updating ? 'Registering' : updating ? 'Updating' : 'Update'}
                            disabled={updating || !!errorMsg || JSON.stringify(initialData) === JSON.stringify(data) || (willUpdatePassword && !_isInputGood())}
                        />
                        {errorMsg && (
                            <ErrorText className="error">{errorMsg}</ErrorText>
                        )}
                        <div className='cancel-btn' onClick={handleCancel}>
                            Cancel
                        </div>
                        {/* <SwitchLink onClick={this.toggleCreate}>{switchText}</SwitchLink> */}
                    </NameForm>

                </Scrollbars>
			</Container>
		</Modal>
	)
}

const HelperContainer = styled.div`
    font-size: 0.8rem;
    padding-left: 16px;
    width: 100%;
`;


const Field = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    input::placeholder {
        font-size: 12px;
    }
`

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;

    label {
        padding-left: 5px;
    }
`

const Label = styled.label`
    text-align: left;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    padding-left: 0px;
`

export default UserProfile

const ErrorText = styled.div`
	margin-top: 15px;
	text-align: center;
`
const Subtitle = styled.div`
	margin-top: 10px;
	margin-bottom: 30px;
	text-align: center;
`

const SubmitButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 13px !important;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const NameForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
    height: 100%;
    padding: 0px 15px;

    .cancel-btn {
        text-decoration: underline;
        text-align: center;
        cursor: pointer;
        margin-top: 5px;
        padding: 10px;
        font-weight: 500;
        font-size: 13px;
    }
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 30px 15px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 352px;
	z-index: 3;
    overflow-y: initial;
    max-height: 80vh;
`

const Input = styled.input`
	padding: 8px 12px !important;
	border-radius: 32px !important;
	border: 1px solid;
	font-size: 14px !important;
	background: none;

    &::placeholder {
        font-size: 12px;
    }
`

const HelperWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
`;

const CircleIcon = styled(AiOutlineCheckCircle)`
    font-size: 1.3rem;
    margin-right: 5px;
`;