import styled from "styled-components"
import React, {Fragment} from 'react'
import { FiVideo as RecordingIcon } from "@react-icons/all-files/fi/FiVideo"
import { VariableCallingActions } from "../stores/VariableCallingStore";
import LoopApi from '../helpers/LoopApi'
import { closeAwsTranscriber } from "../helpers/aws-transcriber";
import { CallingProviderName } from "../contexts/types";
import useMeetingContext from "../contexts/useMeetingContext";
import { DailyCall } from "@daily-co/daily-js";
import { Room } from "twilio-video";

const { GlobalState } = require('reflux')

function RecordingNotif () {
    const onLeaveMeeting = async() => {
        const event = new CustomEvent(`LEAVE_MEETING`, { detail: {} })
        window.dispatchEvent(event)
    }
    return  <Fragment>
        <HostPermission>
            <div className="icon color-primary bg-primary-opaque"><RecordingIcon /></div>
            <HostPermissionContent>
                <HostPermissionMessage>
                    <div className="title">
                        This meeting is being recorded
                    </div>
                    <div className="subtitle">
                        By continuing to be in the meeting, you are consenting to be recorded
                    </div>
                </HostPermissionMessage>
                <HostPermissionButtons>
                    <HostButton className="left bg-default" onClick={() => onLeaveMeeting()}>Leave Meeting</HostButton>
                    <HostButton className="right bg-primary" onClick={() => {}}>Continue</HostButton>
                </HostPermissionButtons>
            </HostPermissionContent>
        </HostPermission>
    </Fragment>
}

export default RecordingNotif;

export const HostButton = styled.div`
	padding: 10px 9px;
    background: red;
    border-radius: 30px;
    color: white;
	flex: 1;
	text-align: center;
    /* flex: 1;
    margin: 0 7.5px 0 15px; */
`
export const HostPermissionContent = styled.div`
	display: flex;    
	justify-content: center;
	flex-direction: column;
    flex: 1;
`

export const HostPermissionMessage = styled.div`
	/* height: 30px; */
	min-height: 30px;
    display: flex;
    flex-direction: column;

	.title {
		font-weight: 500;
		word-break: break-word;
	}

    .subtitle {
        font-size: 12px;
        margin-top: 5px;
    }
`

export const HostPermissionButtons = styled.div`
	display: flex;
	justify-content: space-between;
    width: 100%;
	margin-top: 10px;

	.left {
		margin-right: 5px;
	}

	.right {
		margin-left: 5px;
	}
`

export const HostPermission = styled.div`
	display: flex;
	font-weight: 400;

	.icon {
		width: 30px;
		height: 30px;
		padding: 5px;
		border-radius: 30px;
		margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
	}

`