import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import LoopApi from '../../../helpers/LoopApi'
import Modal from '../../../components/Modalv2'

const { GlobalState } = require('reflux')

import { ModalActions } from '../../../stores/ModalStore'
import { OrganizationWrapper } from '../../Meeting2.0/styles'
import Dropdown from '../../../components/Dropdown'

function CreateRoom(props: any) {
	const [ roomName, setRoomName ] = useState('')
	const [ errorMsg, setErrorMsg ] = useState<string | null>('')
	const [ creating, setCreating ] = useState<boolean>(false)
	const [ selectedOrg, setSelectedOrg ] = useState<string | null>(GlobalState.modal.selectedOrg)

	const updateValue = (e: any) => {
		let rName = e.target.value
		if(rName.length > 24) return
        if (rName.length > 0 && !rName.match(/^[a-zA-Z]/)) {
			rName = `${rName}`
		}
		// if (rName.length > 20) return setErrorMsg('Room name should not exceed more than 20 characters')

		setRoomName(rName)
	}

	const onSubmit = async (e: any) => {
		e.preventDefault()
		setErrorMsg(null)
		setCreating(true)
		try {
            const { exists } = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
				['name', `${roomName}`.trim()],
			])

            if (exists) {
				return setErrorMsg('This room name is taken')
			} 

			let response = {}
			if(GlobalState.modal.space_id !== 'global') {
				response = await LoopApi(null, 'CreateRoomInSpace', {
					name: `${roomName}`.trim(),
                    selectedOrg: selectedOrg !== 'personal' && selectedOrg?.toLowerCase() !== 'all' ? selectedOrg : null
				}, [['id', GlobalState.modal.space_id]])
			} else {
				response = await LoopApi(null, 'CreateMeeting', {
					name: `${roomName}`.trim(),
					is_public: true,
                    selectedOrg: selectedOrg !== 'personal' && selectedOrg?.toLowerCase() !== 'all' ? selectedOrg : null
				})
			}
			
			setCreating(false)
			GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
			GlobalState.modal.onSubmit && GlobalState.modal.onSubmit(response)
			props.closeModal && props.closeModal()
		} catch (e) {
			setCreating(false)
			setErrorMsg(`${e}`)
			GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
            if(e.includes('limit')) ModalActions.SetModal('LimitRoom')
		}
	}

	const Organization = () => {
		const savedorgs = GlobalState.auth.jwt?.data?.organizations || []
		const uniqueIds: any = [];
		const orgs = savedorgs.filter((o: any, index: number) => {
			const isDuplicate = uniqueIds.includes(o?.organization?._id);
			if (!isDuplicate) {
				uniqueIds.push(o?.organization?._id);
			
				return true;
			}
			return false;
		})
        //console.log(GlobalState.auth)
        if(!Array.isArray(orgs)) return <></>
        if(Array.isArray(orgs) && orgs.length === 0) return <></>
        if(!orgs[0]?.organization?._id) return <></>;

		let ORGANIZATIONS = [] as any

		if(GlobalState.auth.jwt?.data?.role === 'user') {
			ORGANIZATIONS = [
				...ORGANIZATIONS,
				{
					name: "My personal rooms",
					value: "personal"
				}
			]
		}

		ORGANIZATIONS = [
			...ORGANIZATIONS,
			...orgs.map((o) => ({
				name:o.organization?.name,
				value: o.organization?._id
			}))
		] as any
		


        return (
          <OrganizationWrapper style={{ width: '100%' }} className="withBorder">
			<Dropdown
				name="selectedOrg"
				value={selectedOrg}
				items={ORGANIZATIONS}
				// inputWidth={120}
				onChange={handleOrgChange}
			/>
          </OrganizationWrapper>
        )
    }

	const handleOrgChange = (item: any, name: any) => {
        setSelectedOrg(item.value)
    }

	useEffect(() => {
		setSelectedOrg(GlobalState.modal.selectedOrg)
	}, [GlobalState.modal.selectedOrg])


	const orgs = GlobalState.auth.jwt?.data?.organizations || []

	return (
		<Modal closeModal={() => {
			GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
			props.closeModal()
		}} dismissible={!!!creating}>
			<Container className="topbar-modal">
				<Title className="header">Create Room</Title>
				<Subtitle className="subheader">Create a name that describes the room<br /> activity or content</Subtitle>
				<NameForm onSubmit={onSubmit}>
					{Array.isArray(orgs) && orgs.length > 0 && <div style={{
						textAlign: 'left',
						marginBottom: '10px',
						fontSize: '12px'
					}}>Room will be saved under:</div>}
					<Organization />
					<Input
						className="border-light topbar"
						type="text"
						value={roomName}
						onChange={updateValue}
						placeholder={`room to create`}
						autoFocus
					/>
					<SubmitButton
						className="button primary"
						type="submit"
						value={creating ? 'Creating' : 'Create'}
						disabled={`${roomName}`.trim().length === 0 || creating}
					/>
					{errorMsg && (
						<ErrorText className="error">{errorMsg}</ErrorText>
					)}
					{/* <SwitchLink onClick={this.toggleCreate}>{switchText}</SwitchLink> */}
				</NameForm>
			</Container>
		</Modal>
	)
}

export default CreateRoom

const ErrorText = styled.div`
	margin-top: 15px;
	text-align: center;
`
const Subtitle = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	text-align: center;
`

const SwitchLink = styled.div`
	text-decoration: underline;
	text-align: center;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;
`

const SubmitButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const NameForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 30px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 352px;
	z-index: 3;
`

const Input = styled.input`
	padding: 8px !important;
	border-radius: 32px !important;
	border: 1px solid;
	font-size: 16px !important;
	background: none;
`
