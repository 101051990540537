import React, { Component } from 'react'
import styled from 'styled-components'

import StaticWidgetSimulator from '../StaticWidgetSimulator'
import TranscriptionItem from './TranscriptionItem'
import moment from 'moment'
const defaultUser = { name: 'User' }

export default class TranscriptionBreakdown extends Component {
	constructor(props) {
		super(props)

		this.state = {
			active_index: 0,
			active_delta_index: 0,
			day_breakdown: [],
            active_transcription: null,
            full_screen: false
		}
		this.entriesEl = null
		this.currentEntry = null
		this.wheelCounter = 0
		this.onWheel = this.onWheel.bind(this)
		this.setRef = this.setRef.bind(this)
        this.downloadTranscription = this.downloadTranscription.bind(this)
        this.escFunction = this.escFunction.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.active_index !== this.state.active_index) {
			this.currentEntry &&
				this.currentEntry.scrollIntoView &&
				this.currentEntry.scrollIntoView({
					behavior: 'auto',
					block: 'center',
					inline: 'center',
				})
		}
	}

    escFunction(event){
        if(event.keyCode === 27) {
          this.setState({ full_screen: false })
        }
      }

    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

	UNSAFE_componentWillMount() {
		this.genBreakdown(this.props.day_breakdown)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.day_breakdown !== this.props.day_breakdown) {
			this.genBreakdown(nextProps.day_breakdown)
		}
	}

	genBreakdown(passedBreakdown) {
		const deltas = passedBreakdown.filter(rec => rec.type === 'Delta')

		const filtered_trans = passedBreakdown.filter(
			rec => rec.type === 'Transcription'
		)
		const day_breakdown = filtered_trans.map((rec, ii) => {
			const user = this.props.users[rec.speaker] || defaultUser

			const nextTimestamp =
				filtered_trans[ii + 1] && filtered_trans[ii + 1].timestamp
			const recDeltas = deltas.filter(
				d =>
					d.timestamp >= rec.timestamp &&
					(!nextTimestamp || d.timestamp < nextTimestamp)
			)

			return { ...rec, deltaCount: recDeltas.length, deltas: recDeltas, user }
		})

		this.setState({ day_breakdown: day_breakdown.reverse() })
	}

	setRef(el) {
		this.currentEntry = el
	}

    downloadTranscription(transcriptions) { 
        let raw_transcriptions = ""; 
        //console.log(this.props)
        try {
            transcriptions.forEach((t) => {
                    raw_transcriptions += `[${moment(t?.timestamp).format('hh:mm:ss')}]${this.props.users[((Object.keys(this.props.users) || []).find((u) => u === t.speaker))]?.username ?? 'user'}: ${t.text}\n`;
              });
        } catch (e) {
            console.log(e.message)
        } 

        const element = document.createElement("a");
        const file = new Blob([raw_transcriptions], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `Transcription_Time-travel_${moment().format('MMDDYYYY_HHMMSS')}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
  }

	render() {
		const transcriptions = this.state.day_breakdown.map((rec, ii) => {
			return (
				<TranscriptionItem
					key={`Transcription-${ii}`}
					setRef={this.setRef}
					is_active={this.state.active_index === ii}
					active_delta_index={
						this.state.active_index === ii ? this.state.active_delta_index : -1
					}
					rec={rec}
				/>
			)
		})

		const currBreakdown =
			this.state.active_delta_index >= 0 &&
			this.state.day_breakdown[this.state.active_index] &&
			this.state.day_breakdown[this.state.active_index].deltas[
			this.state.active_delta_index
			]

        const { day_breakdown } = this.props
		return (
            <>
                {day_breakdown.map((d, i) => {
                    return (
                        <EntryContainer key={i} onClick={() => {
                            this.genBreakdown(d.transcriptions)
                            this.setState({ active_transcription: d})
                            }}>
                            <Title>Transcriptions</Title>
                            <Style2>{moment(d.created_at).format('LLLL')}</Style2>
                            <Style1>Length: {d.transcriptions.length}</Style1>
                            <Style2>
                                <button
                                    className='button primary'
                                    onClick={() => this.downloadTranscription(d.transcriptions)}
                                >
                                    download
                                </button>
                            </Style2>
                        </EntryContainer>
                    );
                })}
                {this.state.active_transcription ?
                <Container>
                    <Card fullScreen={this.state.full_screen} ref={(r) => (this.entriesEl = r)} onWheel={this.onWheel}>
                        <button onClick={() => this.setState({ full_screen: !this.state.full_screen })}>toggle fullscreen</button>
                        <InnerCon>{transcriptions}</InnerCon>
                    </Card>
                    {/* <Card>
                        <StaticWidgetSimulator users={this.props.users} record={currBreakdown} />
                    </Card> */}
                </Container> : null}
            </>
        );
	}

	onWheel(e) {
		this.wheelCounter += e.deltaY
		if (this.wheelCounter > 8) {
			this.wheelCounter = 0

			this.state.active_index < this.state.day_breakdown.length - 1 &&
				this.incrIndex(true)
		} else if (this.wheelCounter < -8) {
			this.wheelCounter = 0
			this.state.active_index > 0 && this.incrIndex(false)
		}
	}

	incrIndex(positive) {
		if (!positive) {
			if (this.state.active_delta_index <= 0) {
				this.state.active_index > 0 &&
					this.setState({
						active_delta_index:
							this.state.day_breakdown[this.state.active_index - 1].deltaCount -
							1,
						active_index: this.state.active_index - 1,
					})
			} else {
				this.setState({ active_delta_index: this.state.active_delta_index - 1 })
			}
		} else {
			if (
				this.state.active_delta_index + 1 <
				this.state.day_breakdown[this.state.active_index].deltaCount
			) {
				this.setState({ active_delta_index: this.state.active_delta_index + 1 })
			} else {
				this.state.active_index + 1 < this.state.day_breakdown.length &&
					this.setState({
						active_delta_index: 0,
						active_index: this.state.active_index + 1,
					})
			}
		}
	}
}

const InnerCon = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: stretch;
    margin-top: 30px;
`

const Card = styled.div`
	margin: 12px;
	box-shadow: ${props => props.theme.shadows.newlight};
	background-color: white;
	border-radius: 15px;
	padding: 15px;
	width: 100%;
	height: ${props => props.theme.blockHeight}px;
	overflow-y: hidden;
     
    ${props => props.fullScreen ? `
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: 'white';` : ''}

    button {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
        box-shadow: ${props => props.theme.shadows.newlight};
        transition: box-shadow 0.3s ease-out;
        border-radius: 999px;
        cursor: pointer;
        padding: 6px 12px;
        color: white;
        border: none;

        &:hover {
            box-shadow: ${props => props.theme.shadows.newlight};
        }
    }
`

const Container = styled.div`
	position: absolute;
	left: 24px;
	right: 24px;
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: stretch;
	justify-content: space-between;
`


const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    display: flex;
    align-items: center;

    color: #00265C;
`

const Style1 = styled.div` 
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    justify-content: space-evenly;
    /* or 200% */

    display: flex;
    align-items: center;

    color: #0659A9;
`

const Style2 = styled.div` 
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    /* or 200% */

    display: flex;
    align-items: center;

    color: #363B45;
`


const EntryContainer = styled.div`
	display: flex; 
    justify-content: space-between;
	padding: 12px;
	margin: 12px;
	cursor: pointer;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	${props => props.selected && `border-left: 6px solid blue;`};
    button {
        margin-left: auto;
        background: #363B45;
        border-radius: 10px;
        height: 40px;
        width: 114px;
        color: white;
        border: none;
        text-transform: capitalize;
    }
    div {
        width:30%;
    }
`