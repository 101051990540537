import React, { Component } from 'react'
import styled from 'styled-components'
import { BiTrashAlt as DeleteIcon } from '@react-icons/all-files/bi/BiTrashAlt'
import { FiEdit2 as EditIcon } from '@react-icons/all-files/fi/FiEdit2'
import { Todo } from './types'
import CustomTip from '../../helpers/CustomTip'

type Props = {
    todo: Todo
    toggleCollapseSidebar: Function
    isSelected: boolean
    changeIndexState: Function
    groupKey: string
    travelling: boolean
}

type state = {
    hover: boolean
}

export class GroupItem extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            hover: false,
        }

        this.editGroup = this.editGroup.bind(this)
    }

    editGroup() {
        this.props.changeIndexState({ selectedGroup: this.props.groupKey, showCreateGroup: true, groupIdtoEdit: this.props.groupKey })
    }

    deleteGroup = (e: any) => {
        e.stopPropagation()
        this.props.changeIndexState({ groupIdtoEdit: this.props.groupKey, showDeleteModal: true })
    }

    render() {
        const { todo, isSelected } = this.props
        const tasks = todo.tasks && Object.values(todo?.tasks)
        const tasksLength = !!tasks && tasks.length || 0
        const hasTasks = (tasks || [])?.length > 0
        return (
            <MainCont
                className="rounded inner"
                selected={isSelected ? true : false}
                onClick={() => {
                    this.props.changeIndexState({ selectedGroup: this.props.groupKey })
                    window.innerWidth <= 1100 && this.props.toggleCollapseSidebar()
                }}>
                <GroupWrapper selected={isSelected} className={`rounded inner ${isSelected ? 'border-primary' : 'border-topbar'}`}>
                    <div className="topbar-content topbar rounded inner">
                        <Left color={todo.groupColor}></Left>
                        <Center>
                            <Upper>{todo.groupName}</Upper>
                            <Lower>
                                {tasksLength}
                                {tasksLength > 1 ? ' tasks' : ' task'}
                            </Lower>
                        </Center>
                        <Right>
                            {!this.props.travelling && <Options>
                                <Edit onClick={(e) => {
                                    e.stopPropagation()
                                    this.editGroup()
                                }} data-tip data-for='gedit'>
                                    <EditIcon size={18} />
                                </Edit>
                                <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Edit Group' dataFor='gedit' />
                                <Delete onClick={hasTasks ? () => { } : (e) => this.deleteGroup(e)} disabled={hasTasks} data-tip data-for='gdelete'>
                                    <DeleteIcon size={20} />
                                </Delete>
                                <CustomTip top={-20} left={-10} class='top-right' place='left' tip={`${hasTasks ? 'Not Allowed To Remove Group' : 'Remove Group'}`} dataFor='gdelete' />
                            </Options>}
                        </Right>
                    </div>
                </GroupWrapper>
            </MainCont>
        )
    }
}

const GroupWrapper = styled.div<{ selected?: boolean }>`
	height: 100%;
	/* padding: 1px; */
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid;

	.topbar-content {
		height: inherit;
        display: flex;
        padding: 15px;
	}
`



const Left = styled.div<{ color: string }>`
    width: 3px;
    min-width: 3px;
    background-color: ${(props) => props.color};
    border-radius: 8px;
`
const Center = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    padding: 5px 15px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
`
const Right = styled.div`
    opacity: 0;
    transition: 200ms;
    margin-left: auto;
    width: 5rem;
    display: flex;
    align-items: center;
`
const Upper = styled.div`
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
const Lower = styled.div`
    opacity: 0.8;
    margin-top: 5px;
    font-size: 12px;
`

const Options = styled.div`
    margin-left: auto;
    width: fit-content;
    display: flex;
    font-size: 15px;
`

const Attach = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`
const Edit = styled.div`
    cursor: pointer;
    padding-left: 3px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`
const Delete = styled.div<{ disabled?: boolean }>`
    ${props => props.disabled ? 'opacity: 0.4; cursor: auto;' : 'cursor: pointer;'};
    padding-left: 5px;
    flex: 1;
    &:hover {
        transform: scale(1.2);
    }
    transition: 200ms;
`

const MainCont = styled.div<{ selected: boolean }>`
    cursor: default;
    display: flex;
    height: fit-content;
    /* border: ${(props) =>
        props.selected ? '#2d81ff solid 2px' : 'transparent solid 2px'}; */

    /* &:hover {
        border: #2d81ff solid 2px;
    } */

    @media (max-width: 1100px) {
        ${Right} {
            opacity: 1
        }
        cursor: pointer;
    }

    @media (min-width: 1101px) {
        &:hover {
            ${Right} {
                opacity: 1
            }
        }
    }
`

export default GroupItem
