import MainParticipantInfo from './MainParticipantInfo'
import ParticipantTracks from './ParticipantTracks'
import React from 'react'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { Room } from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'

interface Props {
  enableScreenShare?: boolean
  hideScreenshareLabel?: boolean
}

export default function LocalParticipant(props: Props) {
  const {room} = useMeetingContext()
  const localParticipant = (room as Room)?.localParticipant || (room as DailyCall)?.participants()?.local

  return (
    <MainParticipantInfo participant={localParticipant} hideScreenshareLabel={props.hideScreenshareLabel}>
      <ParticipantTracks
        participant={localParticipant}
        videoOnly
        enableScreenShare={props.enableScreenShare}
        videoPriority={null}
        isLocalParticipant={true}
      />
    </MainParticipantInfo>
  )
}
