import React from 'react';
import styled from 'styled-components';
import FormField from '../../_Shared/FormField';
import TextareaAutosize from 'react-autosize-textarea';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import Select from '../../_Shared/Select';
import RDatepicker from '../../_Shared/Datepicker';
import moment from 'moment'

const CreateTask = (props: any) => {
  const [data, setData] = React.useState({ category: null, eventDate: 0, state: 'TODO', createdBy: props.data.account.user_id });
  const [error, setError] = React.useState('');
  const { UpdateSelf } = props.actions;
  const { getTasks } = props;

  React.useEffect(() => {
    //console.log('creating task page')
  },[])

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const createTask = async () => {
    const new_task = await Api(Endpoints['Hubspot'], Actions['CreateCalendarTask'], props.external_token, { body: data });
    if (new_task.status === 'error') return setError('There was an error creating task.');
    await getTasks();
    UpdateSelf({ screen: 'tasks' });
  };

  return (
    <CreateContactContainer>
      <Button className='button default' onClick={createTask} ref={props.useRef}>
        Create
      </Button>
      <CustomFormField
        label='Category'
        field={
          <div>
            <Select
              hoverEffect
              fitContent
              name='category'
              placeholder={'Select a category'}
              defaultValue={data.category || ''}
              onChange={handleChange}
              optionsList={[
                { value: 'BLOG_POST', label: 'Blog Post' },
                { value: 'EMAIL', label: 'Email' },
                { value: 'LANDING_PAGE', label: 'Landing Page' },
                { value: 'CUSTOM', label: 'Custom' },
                ]}
            />
          </div>
        }
      />
      <CustomFormField
        label='Name'
        field={
          <div>
            <TextareaAutosize
              name='name'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter name'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Description'
        field={
          <div>
            <TextareaAutosize
              name='description'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter description'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Due Date'
        field={
          <div>
            <RDatepicker
              name='closedate'
              wrapperClassName='datepicker'
              placeholderText='No due date'
              displayOnly
              enableIcon
              onChangeRaw={(e: any) => e.preventDefault()}
              onSelect={(date) => setData({ ...data, eventDate: date.valueOf() })}
              selected={data.eventDate ? moment(data.eventDate).toDate() : ''}
              onChange={() => {}}
              readOnly
              dateFormat='MMMM dd'
            />
          </div>
        }
      />
      <Error className="error">{error}</Error>
    </CreateContactContainer>
  );
};

export default CreateTask;

const CreateContactContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const CustomFormField = styled(FormField)`
  align-self: stretch;
`;

const Button = styled.div`
  align-self: flex-start;
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;

const Error = styled.p`
`;
