import React from 'react'
import styled from 'styled-components'
import Modal from '../../../components/Modalv2'
import Sagas from '../../../helpers/Sagas'

const { GlobalState } = require('reflux')

function ConfirmRecording({ closeModal }: {
	closeModal: () => void
}) {

    const onCancel = () => {
        closeModal && closeModal()
	}

	const onLeave = async () => {
        if(GlobalState.modal.cb) {
            GlobalState.modal.cb()

			if(!GlobalState.modal.isTwilio) {
				Sagas.Clients.Emit('main', 'message', { action: 'NotifyRecordingParticipants', identity: GlobalState?.auth?.jwt?.data._id, meetingName: GlobalState?.main?.meetingName })
			}
        }
        closeModal && closeModal()
	}

	return (
		<Modal closeModal={() => {
			// props.closeModal()
		}}>
			<Container className="topbar-modal">
					<Contents>
						<Title className="header">Are you sure you want to record this meeting?</Title>
						<Details className="subheader">{
							GlobalState.modal.isTwilio ? 'If yes, please note that the recording will start if it detects an audio or video composition has started. Otherwhise, you can cancel this prompt message.' :
							'If yes, please note that the recording will be stopped only when the meeting ended. Otherwise, you can cancel this prompt message.'
						}</Details>
					</Contents>

					<Options>
						<Button className="button default" onClick={onCancel}>No</Button>
						<Button className="button primary" onClick={onLeave}>Yes</Button>
					</Options>
				</Container>
		</Modal>
	)
}

export default ConfirmRecording

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    background-color: white;
    color: #363B45;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div<{ disabled?: boolean }>`
    ${props => props.disabled ? 'opacity: 0.3; pointer-events: none;' : ''}
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
    font-size: 14px;

    &.button.primary {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); 
        color: white;
    }

	&.button.default {
        color: white; 
        background-color: #363B45;
    } 
`
