import React, { memo, MouseEvent, useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';

import NotesItem from './NotesItem';
import { Button, NoExistingNotesWrapper, SidebarWrapper } from './styledcomponents'

interface Props {
    notes: any,
    activeNote: string | null
    activePrivateNote: any,
    changeActiveNote: (id: string) => void
    deleteNote: (id: string, e: MouseEvent<SVGElement>) => void
    createNote: () => void
}

function Sidebar(props: Props) {
    const { notes, activeNote, activePrivateNote, changeActiveNote, deleteNote, createNote } = props

    return (
        <SidebarWrapper className="rounded container content">
            {/* <NoExistingNotesWrapper className="addnew">
                <Button className="button primary" onClick={createNote}>New Note</Button>
            </NoExistingNotesWrapper> */}
            {
                (notes || [])?.length ?
                <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    style={{ height: 'calc(100% - 40px)' }}
                >
                    {
                        (notes || []).map((note: any, idx: number) => {
                            let n = note
                            if(activePrivateNote?.id === n?.id) {
                                n = activePrivateNote
                            }
                            return <NotesItem 
                                // key={`notes-item-${note}-${idx}`}
                                id={n?.id}
                                title={n?.title as string} 
                                pureText={n?.pureText as string}
                                dateUpdated={n?.date_updated as string}
                                dateCreated={n?.date_created as string}
                                isPrivate={(n?.isPrivate || n?.private) as boolean}
                                isActive={activeNote === n?.id}
                                changeActiveNote={changeActiveNote}
                                deleteNote={deleteNote}
                            />
                        })
                    }
                </Scrollbars> : ''
            }
        </SidebarWrapper>
    )
}

function areEqual(prevProps: Props, nextProps: Props) {
    return JSON.stringify(prevProps.notes) === JSON.stringify(nextProps.notes) &&
    JSON.stringify(prevProps.activePrivateNote) === JSON.stringify(nextProps.activePrivateNote) &&
    prevProps.activeNote === nextProps.activeNote 
}


export default memo(Sidebar, areEqual)
