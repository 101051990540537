import MainParticipantInfo from './MainParticipantInfo'
import ParticipantTracks from './ParticipantTracks'
import React, { useMemo } from 'react'
import useMainParticipant from '../../hooks/useMainParticipant'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'
import {useCallback} from 'react'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { CallingProviderName } from '../../../../../../contexts/types'
import { Room } from 'twilio-video'
import { DailyCall } from '@daily-co/daily-js'

interface Props {}

export default function MainParticipant(props: Props) {
  const mainParticipant = useMainParticipant()
  const {room, callProvider} = useMeetingContext()

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
  
  const localParticipant = isTwilio ? (room as Room)!.localParticipant : (room as DailyCall)?.participants()?.local
  const screenShareParticipant = useScreenShareParticipant()

  const videoPriority =
    mainParticipant === screenShareParticipant &&
    mainParticipant !== localParticipant
      ? 'high'
      : null

  return useMemo(() => (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo
      participant={mainParticipant}
      isScreenSharing={!!screenShareParticipant}
    >
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={true}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  ), [mainParticipant, screenShareParticipant, localParticipant, videoPriority])
}
