import DailyIframe, { DailyCall, DailyCallOptions } from '@daily-co/daily-js';

import { CallingInstanceState } from '../calling/types';
import SuccessErrorToast from '../components/Notifications/SuccessErrorToast';

import useToastify from '../helpers/useToastify';
import LoopApi from '../helpers/LoopApi'
import { CallingProviderName } from './types';
import { sortDate } from '../helpers';
import { VariableCallingActions } from '../stores/VariableCallingStore';
import { Slide, toast } from 'react-toastify';
import RecordingNotif from '../components/RecordingNotif';

const {GlobalState} = require('reflux')

export const getDailyConnectionOptions = (meetingProvider?: CallingProviderName) => {
    const connectionOptions: DailyCallOptions = {
        dailyConfig: {
            experimentalChromeVideoMuteLightOff: true,
        }
    }
    return connectionOptions
  }

export const isProviderSupported = () => {
    if(!DailyIframe.supportedBrowser().supported) {
        showToast({ message: `Your browser does not support Daily. Please refresh the page to retry or use another browser.`})
    }
    return DailyIframe.supportedBrowser().supported
}

export const showToast = ({ message }: { message: string }) => {
    useToastify({
        message: () => SuccessErrorToast({ message, type: 'error' }),
        position: "top-right",
        autoClose: 1500,
        closeButton: false,
        hideProgressBar: true,
        className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
        bodyClassName: "grow-font-size",
    })
}

export const createRoom = async (): Promise<string | null> => {
    return await LoopApi(
        null, 
        'CreateDailyCoRoom', 
        { 
            room_name: GlobalState.main?.meetingName, 
            properties: { 
                meeting_join_hook: `${process.env.REACT_APP_BACKEND_URL || 'https://locahost:8000'}/api/webhooks/daily/callback`,
                enable_recording: 'cloud'
            } 
        }
    )
        .then(({ room }) => {
            if(room?.error && room?.info && (room?.info || '')?.includes('already exists')) {
                return `${process.env.REACT_APP_DAILYCO_URL || 'https://grapl.daily.co'}/${GlobalState.main?.meetingName}`
                
            } else if(room?.error) {
                throw new Error(room)
            }

            return room?.url || ''
        })
        .catch((error) => {
            console.log('  Error creating room (Daily)', { error })
            return null
        })
}

export const createNewRecordings = async (session_id: string, participants: any) => {
    try {
        const objArray = Object.values(participants)
        const sortedParticipants = objArray.slice().sort((a: any, b: any) => sortDate(a.joined_at, b.joined_at))
        const host = sortedParticipants.map((p: any) => (p?.local ? GlobalState.auth.jwt.data._id : p?.user_name?.split("::")?.[1]))?.[0]

        const { recordings } = await LoopApi(null, 'CreateRecording', {
            session_id,
            provider: 'DAILY',
            meeting_name: GlobalState.main?.meetingName,
            host
        }, []) 

        if(recordings?.data?.isRecording) {
            toast.dismiss('meetingRecordingNotif')
			useToastify({
				message: () => RecordingNotif(),
				position: "top-center",
				autoClose: false,
				closeButton: false,
				hideProgressBar: true,
				transition: Slide,
				toastId: 'meetingRecordingNotif',
				className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
				progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
			})

            setTimeout(() => {
                const event = new CustomEvent(`TOGGLE_RECORDING`, { detail: { onoff: true } })
                window.dispatchEvent(event)
            }, 500)
        }

        if(recordings?.res === 'error') {
            VariableCallingActions.SetHost({ host: recordings?.data?.host })
            VariableCallingActions.SaveMeetingSession({session_id: recordings?.data?.session_id})
            VariableCallingActions.SetLock({meetingLocked: recordings?.data?.locked})
        } else {
            VariableCallingActions.SetHost({ host: recordings?.host })
            VariableCallingActions.SaveMeetingSession({session_id: recordings?.session_id})
            VariableCallingActions.SetLock({meetingLocked: recordings?.locked})
        }        

        return recordings
    }catch (err) {
        console.log("Error creating recording: ", err)
    }
}