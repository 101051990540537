import styled from 'styled-components'
import { ActionButton } from '../_Shared/Elements'

const UserList = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0;
	margin-top: 6px;
	margin-bottom: 12px;
`

const Avatar = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 2px;
	margin-right: 12px;
	box-shadow: ${props => props.theme.shadows.newlight};
	/* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); */
`

const UserItem = styled.div<{ simple?: boolean }>`
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	padding: 6px 12px;

	&:nth-child(even) {
		background-color: ${props => props.simple ? 'transparent' : 'rgba(0, 0, 0, 0.025)'};
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.06) !important;
	}

	button,
	select {
		opacity: 0;
	}

	button:active,
	select:active,
	&:hover select,
	&:hover button {
		opacity: 1;
	}
`

const AButton = styled(ActionButton)`
    padding: 0px !important;
    width: 80px;
    padding: 0 !important;
    font-size: 12px;
    height: 26px;
`

export { UserList, Avatar, UserItem, AButton }
