import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import Modal from '../../../../../../components/Modalv2'
import { MainStore } from '../../../../../../stores/MainStore'
import { AuthStore } from '../../../../../../stores/AuthStore'
import { VariableCallingStore } from '../../../../../../stores/VariableCallingStore'
import { ModalStore } from '../../../../../../stores/ModalStore'
import Toggle from '../../../../../../components/Toggle'
import { MeetingLayouts, MeetingViews } from '../../../../../Meeting2.0/Widgets/KeynoteWidgets/hooks/useView'


export default class ModalMeetingParticipants extends Reflux.Component<typeof MainStore | typeof AuthStore | typeof VariableCallingStore> {
    constructor(props: any) {
        super(props)

        this.state = {
            users: [],
        }

        this.stores = [MainStore, AuthStore, VariableCallingStore]
        this.storeKeys = ['db_meeting', 'users', 'jwt', 'connectedUserIds',]
    }

    handleToggleParticipants = () => {
        const { setParticipantsHidden, participantsHidden, layout, currentWidgetName, setView, screenShareParticipant, setActiveWidget, firstWidget } = this.props
        
        setParticipantsHidden(!participantsHidden)
        if (!currentWidgetName) {
            // ON GOING CALL
            switch (layout) {
                case MeetingLayouts.Default:
                    if(!participantsHidden && screenShareParticipant) {
                        setView(MeetingViews.MainOnly)
                    } else if(!participantsHidden && !screenShareParticipant)  {
                        // move to other sync
                        setView(MeetingViews.MainOnly)
                    } else if(participantsHidden && screenShareParticipant) {
                        setView(MeetingViews.Complete)
                    } else {
                        setView(MeetingViews.SidebarOnly)
                    }
                    break

                case MeetingLayouts.Sidebar:
                    if(!participantsHidden && screenShareParticipant) {
                        setView(MeetingViews.MainOnly)
                    } else if(!participantsHidden && !screenShareParticipant)  {
                        // move to other sync
                        setView(MeetingViews.MainOnly)
                    } else if(participantsHidden && screenShareParticipant) {
                        setView(MeetingViews.Complete)
                    } else {
                        setView(MeetingViews.Complete)
                    }
                    break
        
        
                case MeetingLayouts.Floating:
                    if(!participantsHidden && screenShareParticipant) {
                        setView(MeetingViews.MainOnly)
                    } else if(!participantsHidden && !screenShareParticipant)  {
                        // move to other sync
                        setView(MeetingViews.MainOnly)
                    } else if(participantsHidden && screenShareParticipant) {
                        setView(MeetingViews.MainOnly)
                    } else {
                        setView(MeetingViews.MainOnly)
                    }
                    break
            }
          } else {
            // WIDGET MODE
            switch (layout) {
                case MeetingLayouts.Default:
                    if(!participantsHidden) {
                        setView(MeetingViews.MainOnly)
                    } else {
                        setView(MeetingViews.Complete)
                    }
                    break

                case MeetingLayouts.Sidebar:
                    if(!participantsHidden) {
                        setView(MeetingViews.MainOnly)
                    } else {
                        setView(MeetingViews.Complete)
                    }
                    break
        
                case MeetingLayouts.Floating:
                    setView(MeetingViews.MainOnly)
                    break
            }
          }
    }


    render() {
        const usersInCallKey = this.state.connectedUserIds.length === undefined ? (Object.keys(this.state.connectedUserIds) || []).filter((key) => !isNaN(parseInt(key))) : this.state.connectedUserIds
        const usersInCall = usersInCallKey.map((u: any) => this.state.connectedUserIds[u])

        let users = (this.state.users || [])
            .sort((x: any, y: any) => { return x.id === this.state.jwt?.data?._id ? -1 : y.id === this.state.jwt?.data?._id ? 1 : 0; })
            .filter((user: any) => (usersInCall || []).includes(user.id))
            .map((user: any) => {
                return <UserWrapper className="border-light" data-id={user.id} key={user.id}>
                    <div className="user-image-wrapper">
                        <img src={user.avatar_url} alt={user.name} />
                    </div>
                    <div className="user-data-wrapper">
                        <div className="username" >{user.name}</div>
                        <div className="user-type">{user.type === 'guest' ? 'Guest user' : 'Account user'}</div>
                        {/* <div className="user-type">{user.email || ''}</div> */}
                    </div>
                    <div className="user-action">
                    </div>
                </UserWrapper>
            })

        return (
            <Modal closeModal={this.props.closeModal} contentStyle={{overflowY: 'hidden'}}>
                <Container>
                    <HeaderContainer>
                        <Title className="header">Meeting Participants</Title>
                        <SubTitle className="subheader">List of users who are currently in the meeting</SubTitle>
                    </HeaderContainer>
                    <ControlsContainer>
                        <div className='participant-count'>{users?.length} participant{users?.length > 1 ? 's': ''}</div>
                        {
                            ((!!!this.props.currentWidgetName && this.props.screenShareParticipant) || (this.props.currentWidgetName && this.props.layout !== MeetingLayouts.Floating) || this.props.layout !== MeetingLayouts.Floating) && <ToggleWrapper>
                                <div className='text center hideParticipant'>{this.props.participantsHidden ? 'Show' : 'Hide'} Participants</div>
                                <Toggle
                                    checked={this.props.participantsHidden}
                                    onChange={this.handleToggleParticipants}
                                    />
                            </ToggleWrapper>

                        }
                    </ControlsContainer>
                    <UserCollection>
                        {users}
                    </UserCollection>
                </Container>
            </Modal>
        )
    }
}

const ControlsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .participant-count {
        font-weight: 500;
    }
`

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;

    .switch {
        .slider.slider200 {
            &::before {
                transform: translateX(-70px);
            }
        }
        input:checked + .slider:before {
            transform: translateX(2px);
        }
    }

    .hideParticipant {
        padding-right: 6px;
        font-weight: 500;
    }

`

const UserCollection = styled.div`
	max-height: 300px;
	overflow-y: auto;

`

const UserWrapper = styled.div`
	display: flex;
	padding: 10px 0;
    padding-right: 10px;

    .user-select {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        width: 25px;
    }

	.user-image-wrapper {
		width: 50px;
		height: 50px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
            object-fit: cover;
		}
	}

	.user-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-left: 15px;
        flex: 1;
        // margin-right: 15px;

		.username {
			font-weight: 500;
			font-size: 14px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 16px;
		}

		.user-type {
			margin-top: 2px;
			font-size: 12px;
			opacity: 0.5;
		}
	}

    .user-action {
		display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;

        > * {
            font-weight: 500;
        }

        .remove-user {
            cursor: pointer;
        }

        .disabled.remove-user {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
`

const Container = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    /* align-items: center; */
`

const HeaderContainer = styled.div`
    /* min-width: 400px; */
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;
`

const Title = styled.div`
    font-weight: ${props => props.theme.textBold};
    font-size: ${props => props.theme.textLG};
`

const SubTitle = styled.div`
    font-weight: ${props => props.theme.textRegular};
    font-size: ${props => props.theme.textMD};
    padding-top: 10px;
`
