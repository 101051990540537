import styled from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";
import { createElement } from "react";

import { MdFormatBold as BoldIcon } from '@react-icons/all-files/md/MdFormatBold'
import { MdFormatItalic as ItalicIcon } from '@react-icons/all-files/md/MdFormatItalic'
import { MdFormatUnderlined as UnderlineIcon } from '@react-icons/all-files/md/MdFormatUnderlined'
import { MdStrikethroughS as StrikeIcon } from '@react-icons/all-files/md/MdStrikethroughS'
import { HiCode as CodeIcon } from '@react-icons/all-files/hi/HiCode'
import { MdFormatQuote as QuoteIcon } from '@react-icons/all-files/md/MdFormatQuote'
import { GoListOrdered as OrderedIcon } from '@react-icons/all-files/go/GoListOrdered'
import { GoListUnordered as UnorderedIcon } from '@react-icons/all-files/go/GoListUnordered'
import { BiLink as LinkIcon } from '@react-icons/all-files/bi/BiLink'
import { BiUnlink as UnlinkIcon } from '@react-icons/all-files/bi/BiUnlink'
import { MdUndo as UndoIcon } from '@react-icons/all-files/md/MdUndo'
import { MdRedo as RedoIcon } from '@react-icons/all-files/md/MdRedo'

export const NotesWrapper = styled.div<{ travelling?: boolean }>`
    pointer-events: ${props => props.travelling ? 'none' : ''};
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 300px auto;
    grid-template-areas: 'sidebar content';
    overflow: hidden;
    height: 100%;
`

export const SidebarWrapper = styled.div`
    grid-area: sidebar;
    padding: 30px 0px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    position: relative;
    transition: 0.3s display ease-in-out;
`

export const NotesContentWrapper = styled.div<{ collapsed?: boolean, isInCall?: boolean }>`
    grid-area: content;
    margin: 15px;
    margin-left: ${props => props.collapsed ? '15px' : '0px'};
    font-weight: ${props => props.theme.textRegular};
    padding: 50px;
    max-height: ${props => props.isInCall ? 'calc(100vh - 220px)' : 'calc(100vh - 150px)'};

    textarea {
        width: 100%;
    }

    .textarea {
        padding-left: 0px;
    }

    .notes-date {
        padding: 10px;
        padding-left: 0px;
    }

    .CustomRichEditor-editor {
        height: calc(100% - 80px);
        overflow-y: auto;
    }
`

export const NotesItemWrapper = styled.div`
    padding: 20px 30px 20px 30px;
    cursor: pointer;
    position: relative;

    .preview-header {
        font-weight: ${props => props.theme.textBold};
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        .preview-title {
            text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
            flex: 1;
            margin-right: 5px;
        }

        .preview-date {
            font-size: 11px;
            line-height: 13px;
            opacity: 0.5;
            font-weight: ${props => props.theme.textRegular};
        }
    }

    .preview-content {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 5px;
        line-height: 1.1rem;
    }


    .close-icon {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        font-size: 16px;
        transition: 0.3s display ease-in-out;
        z-index: 2;
    }

    .private-flag {
        position: absolute;
        left: 15px;
        top: 20px;
    }

    &:hover {
        .close-icon {
            display: block;
        }
    }

`

const reactSvgComponentToMarkupString = (Component: any, props: any) =>
`data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props))
)}`;


export const EditorWrapper = styled.div<{ bgColor: string, defColor: string }>`
    .public-DraftEditorPlaceholder-inner {
        font-style: italic;
        font-weight: 400;
        color: ${props => props.defColor};
    }

    a {
        color: ${props => props.color} !important;
        text-decoration: none;
        font-weight: 500;
    }

    &.Custom-hidePlaceholder {
        .public-DraftEditorPlaceholder-root {
            display: none;
        }
    }

    .rdw-editor-main {
        word-break: break-word;
    }

    .notes-toolbar {
        z-index: 2;
        position: sticky;
        top: 0;
        font-size: 14px;
        padding: 20px 5px 10px;
        margin-bottom: 10px;
        border: none;
        border-radius: 0px;
        background-color: ${props => props.bgColor};

        .rdw-link-modal {
            left: -165px;
            background: ${props => props.bgColor};
            box-shadow: ${props => props.theme.shadows.neumorphiclight};
            border: none;

            @media (max-width: 549px) {
                left: 0px;
            }

            @media (max-width: 420px) {
                left: -80px;
            }

            @media (max-width: 420px) and (max-height: 670px) {
                top: -30px;
                max-height: 130px;
                overflow: auto;
            }

        }

        .rdw-link-wrapper {
            > .rdw-link-modal {

            }
        }

        .rdw-inline-wrapper, .rdw-inline-wrapper, .rdw-list-wrapper, .rdw-link-wrapper, .rdw-history-wrapper {
            margin-bottom: 0;
        }

        .notes-italic, .notes-bold, .notes-underline, .notes-strikethrough, .notes-ordered, .notes-unordered, .notes-link, .notes-unlink, .notes-undo, .notes-redo {
            img { display: none; }
        }

        .rdw-option-wrapper {
            border: none;
            background: transparent;

            &:hover {
                box-shadow: none;
            }
        }

        .rdw-option-active {
            box-shadow: none;
        }

        .notes-bold {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(BoldIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(BoldIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-italic {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(ItalicIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(ItalicIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-underline {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnderlineIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UnderlineIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-strikethrough {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(StrikeIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(StrikeIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-unordered {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnorderedIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UnorderedIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-ordered {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(OrderedIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(OrderedIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-link {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(LinkIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(LinkIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-unlink {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UnlinkIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UnlinkIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-blocktype {
            font-size: 0;

            div:first-child {
                &::before {
                    content: ${({ defColor }) =>
                    `url(${reactSvgComponentToMarkupString(QuoteIcon, {
                        color: defColor
                    })})`};
                }

                &.rdw-option-active {
                    &::before {
                        content: ${({ color }) =>
                        `url(${reactSvgComponentToMarkupString(QuoteIcon, {
                            color: color
                        })})`};
                    }
                }
            }

            div:nth-child(2) {
                &::before {
                    content: ${({ defColor }) =>
                    `url(${reactSvgComponentToMarkupString(CodeIcon, {
                        color: defColor
                    })})`};
                }

                &.rdw-option-active {
                    &::before {
                        content: ${({ color }) =>
                        `url(${reactSvgComponentToMarkupString(CodeIcon, {
                            color: color
                        })})`};
                    }
                }
            }
        }

        .notes-redo {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(RedoIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(RedoIcon, {
                        color: color
                    })})`};
                }
            }
        }

        .notes-undo {
            &::before {
                line-height: 0em;
                content: ${({ defColor }) =>
                `url(${reactSvgComponentToMarkupString(UndoIcon, {
                    color: defColor
                })})`};
            }

            &.rdw-option-active {
                &::before {
                    content: ${({ color }) =>
                    `url(${reactSvgComponentToMarkupString(UndoIcon, {
                        color: color
                    })})`};
                }
            }
        }

    }
`

export const NotesHeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;

    .notes-updatedby {
        padding: 0 10px 10px 0;
        font-size: 12px;
        font-style: italic;
        opacity: 0.5;
    }
`

export const Button = styled.div<{ disabled?: boolean }>`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }

    ${props => props.disabled ? 'opacity: 0.5; cursor: default;' : ''}
`

export const NoExistingNotesWrapper = styled.div`
    padding: 20px 30px 20px 30px;

    &.addnew {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }
`

export const NotePreview = styled.div<{ selectedRangeBounds?: any, color?: string }>`
    padding: 20px 30px 20px 30px;
    cursor: pointer;
    position: relative;

    &.whole-div {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        font-weight: ${props => props.theme.textBold};
    }

    &.no-note {
        flex-direction: column;
        font-weight: ${props => props.theme.textBold};
        text-align: center;

        .lighter {
            opacity: 0.4;
            margin-bottom: 20px;
        }
    }

`

