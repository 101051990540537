import React from 'react'
import styled from 'styled-components'

import Background from './MobileBG.png'
import Mobile from './MobileDesign.svg'
import GooglePlay from './google-play-badge.png'
import AppStore from './app-store-badge.svg'
import { getMobileOS } from '../calling/_helpers'

const { GlobalState } = require('reflux');

function MobileView() {
    const mobileOS = getMobileOS();

    const app = {
        launchApp: function() {
          window.location.replace("grapl://grapl.it");
        },
    
        openWebApp: function() {
          window.location.replace("https://apps.apple.com/ph/app/grapl/id1600259677");
        }
    };

    return (
        <MobileViewWrapper>
            <Title>Never lose track of your Ideas, Conversations, or Projects again.</Title>
            <Subtitle>Always pick up where you left off.</Subtitle>
            <img src={Mobile} alt="Grapl" />
            <DownloadWrapper>
                {
                    mobileOS === 'Android' || mobileOS === 'iOS' ?
                    <>
                        <DownloadTitle>DOWNLOAD NOW</DownloadTitle>
                        <Button onClick={() => {
                            if(mobileOS === 'Android') {
                                window.open('https://play.google.com/store/apps/details?id=com.tas.grapl', '_self')
                                // window.open('grapl://grapl.it')
                            } else if(mobileOS ===  'iOS') {
                                // app.launchApp()
                                window.open('https://apps.apple.com/ph/app/grapl/id1600259677', '_self')
                            }
                        }}>
                            {mobileOS === 'Android' && <img src={GooglePlay} alt="Google Play" />}
                            {mobileOS === 'iOS' && <img src={AppStore} alt="Apple Store" />}
                        </Button>
                    </> : <></>
                }
            </DownloadWrapper>
            <div className='already-installed'>If already installed, open <span onClick={() => {
                if(mobileOS === 'iOS') {
                    window.open(`grapl://grapl.it${window.location.pathname}${window.location.search}`)
                }

                if(mobileOS === 'Android') {
                    var url  = `grapl://app.grapl.it${window.location.pathname}${window.location.search}`;
                    window.location.replace(url)
                }
            }} className="link">Grapl</span> app</div>
        </MobileViewWrapper>
    )
}

const MobileViewWrapper = styled.div`
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .already-installed {
        text-align: center;
        color: white;
        font-weight: 400;
        font-size: 12px;

        .link {
            color: #f89809;
            font-weight: 600;
            cursor: pointer;
        }
    }

    > img {
        width: 100%;
        margin: 30px 0;

        @media (min-height: 800px) {
            margin: 50px 0 100px;
        }
    }
`

const Title = styled.div`
    color: #FFFFFF;
    font-size: 1.7em;
    font-weight: 600;
    margin: 20px 20px 0;
    
`

const Subtitle = styled.div`
    color: #FFFFFF;
    opacity: 0.7;
    font-size: 1.2em;
    font-weight: 500;
    margin: 20px;
`

const DownloadWrapper = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const DownloadTitle = styled.div`
    color: #FFFFFF;
    font-size: 1.9em;
    font-weight: 500;
`

const Button = styled.div`
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    img {
        width: 150px;
    }
`

export default MobileView