import { DailyCall, DailyEventObject, DailyEventObjectTrack, DailyParticipant, DailyTrackState } from '@daily-co/daily-js'
import moment from 'moment'
import {useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import {
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Room,
} from 'twilio-video'
import SuccessErrorToast from '../../../../../components/Notifications/SuccessErrorToast'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import useToastify from '../../../../../helpers/useToastify'

const { GlobalState } = require('reflux')

type TrackPublication = LocalTrackPublication | RemoteTrackPublication

export default function usePublications(participant: Participant | DailyParticipant) {
  const [publications, setPublications] = useState<TrackPublication[] | MediaStreamTrack[]>([])
  const {callProvider, room} = useMeetingContext()

  useEffect(() => {
    const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
    // Reset the publications when the 'participant' variable changes.
    if(isTwilio) {
      setPublications(
        Array.from((participant as Participant).tracks.values()) as TrackPublication[],
      )
  
      const publicationAdded = (publication: TrackPublication) => {
        let withScreen = 0;
        ((room as Room)?.participants).forEach((s: any) => {
          s.videoTracks.forEach((a: any) => {
            if(a.trackName === 'screen') {
              withScreen +=1;
            }
          })
        })
        setPublications((prevPublications: TrackPublication[]) => {
          if(withScreen && publication.trackName === 'screen' && (Object?.getPrototypeOf(publication)?.constructor?.name || '').includes('LocalVideo')) {
            // @ts-ignore
            window.stopScreenShareRef()
            toast.dismiss('someonealreadyScreensharing')
            useToastify({
              message: () => SuccessErrorToast({ message: 'Someone is already sharing their screen', type: 'error' }),
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
              hideProgressBar: true,
              toastId: 'someonealreadyScreensharing',
              className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
              bodyClassName: "grow-font-size",
            })
            return [...prevPublications]
          }
          return [...prevPublications, publication]
        })
      }

      const publicationRemoved = (publication: TrackPublication) =>
        setPublications((prevPublications: TrackPublication[]) =>
          prevPublications.filter((p) => p !== publication),
        );
  
      (participant as Participant).on('trackPublished', publicationAdded);
      (participant as Participant).on('trackUnpublished', publicationRemoved);

      return () => {
        (participant as Participant).off('trackPublished', publicationAdded);
        (participant as Participant).off('trackUnpublished', publicationRemoved);
      }
    } else if(callProvider === CallingProviderName.DailyCo) {
      let tracks = [] as any
      if((participant as DailyParticipant)?.audioTrack && (participant as DailyParticipant)?.audio) {
        tracks.push((participant as DailyParticipant)?.audioTrack)
      } 

      if((participant as DailyParticipant)?.screenVideoTrack && (participant as DailyParticipant)?.screen) {
        tracks.push((participant as DailyParticipant)?.screenVideoTrack)
      }

      if((participant as DailyParticipant)?.videoTrack && (participant as DailyParticipant)?.video) {
        tracks.push((participant as DailyParticipant)?.videoTrack)
      }

      const filteredTracks = tracks?.filter((t: any) => !!t)
      setPublications(filteredTracks)

      const publicationStarted = (e: DailyEventObjectTrack) => {
        const {participant: prtcpnt, track} = e
        const localScreenshares = Object.values((room as DailyCall).participants())
            .filter((participant: DailyParticipant) => participant.screen && !!!participant.local)

        if(localScreenshares?.length > 0 && prtcpnt?.local) {
          // @ts-ignore
          window.stopScreenShareRef()
          toast.dismiss('someonealreadyScreensharing')
          useToastify({
            message: () => SuccessErrorToast({ message: 'Someone is already sharing their screen', type: 'error' }),
            position: "top-right",
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
            toastId: 'someonealreadyScreensharing',
            className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
            bodyClassName: "grow-font-size",
          })
        }

        
        if((prtcpnt?.user_name || prtcpnt?.user_id) !== ((participant as DailyParticipant)?.user_name || (participant as DailyParticipant)?.user_id)) return 
        setPublications((prevPublications: MediaStreamTrack[]) => {
          let trcks = [] as any
          if((prtcpnt as DailyParticipant)?.audioTrack && (prtcpnt as DailyParticipant)?.audio) {
            trcks.push((prtcpnt as DailyParticipant)?.audioTrack)
          } 

          if((prtcpnt as DailyParticipant)?.screenVideoTrack && (prtcpnt as DailyParticipant)?.screen) {
            let screensharetrack = (prtcpnt as DailyParticipant).screenVideoTrack as MediaStreamTrack
            if(!!!screensharetrack.label?.includes('screen')) {
              screensharetrack = {
                ...screensharetrack,
                label: `screen-${screensharetrack}`
              }
            }
            trcks.push(screensharetrack)
          }

          if((prtcpnt as DailyParticipant)?.videoTrack && (prtcpnt as DailyParticipant)?.video) {
            trcks.push((prtcpnt as DailyParticipant)?.videoTrack)
          }
          return [...trcks]
        })
      }

      const pubicationStopped = (e: DailyEventObjectTrack) => {
        const {participant: prtcpnt, track} = e
        if((prtcpnt?.user_name || prtcpnt?.user_id) !== ((participant as DailyParticipant)?.user_name || (participant as DailyParticipant)?.user_id)) return 
        setPublications((prevPublications: TrackPublication[]) =>
          prevPublications.filter((p: any) => (p?.id || p?.label) !== (track?.id || track?.label)),
        );
      }

  
      (room as DailyCall).on('track-started', publicationStarted);
      (room as DailyCall).on('track-stopped', pubicationStopped);

      return () => {
        (room as DailyCall).off('track-started', publicationStarted);
        (room as DailyCall).off('track-stopped', pubicationStopped);
      }
    }
  }, [participant, callProvider, room])

  return publications
}
