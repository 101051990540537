import React from 'react'

// import AudioInputList from "./AudioInputList/AudioInputList";
import VideoInputList from './VideoInputList'
import AudioInputList from './AudioInputList'
import AudioOutputList from './AudioOutputList'

type Props = {
  closeModal: Function
}

export default function ModalDeviceSelection(props: Props) {
  return (
    <div className="room-modal-backdrop" onClick={() => props.closeModal()}>
      <div
        className="room-modal topbar-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <div className="header">Settings</div>
          <div className="field-wrapper">
            <AudioInputList />
          </div>
          <div className="field-wrapper">
            <VideoInputList />
          </div>
          <div className="field-wrapper">
            <AudioOutputList />
          </div>
        </div>
      </div>
    </div>
  )
}
