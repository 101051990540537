import React, { Component } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

import LinkChanger from './LinkChanger'
import { IWidgetProps, AllWidgets } from '../types'

type Props = IWidgetProps<AllWidgets.Twitch>

export default class TwitchWidget extends Component<Props> {

  player: any;

  constructor(props: Props) {
    super(props)

    this.player = {};
    this.changeChannel = this.changeChannel.bind(this);
  }

  state = {
    isPlaying: false,
    currentTime: 0,
    skipping: false,
    starting: true,
    gctUpdate: false
  }

  shouldComponentUpdate(props: Props) {
		return this.props.data.channel !== props.data.channel
	}

  UNSAFE_componentWillMount() {
		window.addEventListener('focus', this.forceUpdateCheck)
	}

	componentWillUnmount() {
		window.removeEventListener('focus', this.forceUpdateCheck)
	}
  
  UNSAFE_componentWillReceiveProps(props: Props) {
		this.checkForUpdates(props);
	}

  forceUpdateCheck = () => {
		this.checkForUpdates(this.props);
	}

  checkForUpdates = async(props: Props) => {
    
    const isLive = !!this.player && !!this.player.getInternalPlayer && !!this.player.getInternalPlayer().getChannel();

    if(this.state.starting === false && (props.data.playing !== this.state.isPlaying || isLive)) {
      props.data.playing
      		? this.player.getInternalPlayer().play()
      		: this.player.getInternalPlayer().pause()        
        this.setState({isPlaying: props.data.playing});
    }

    if(!!props.data.skippedTime === true && !this.state.skipping && !isLive) {
      this.setState({skipping: true})
      this.player.seekTo(props.data.skippedTime);
      setTimeout(() => {
        this.setState({skipping: false})
      }, 1000);
    }

    if(this.state.gctUpdate === false && props.data.getCurrentTime && !isLive) {
      this.setState({skipping: true})
      this.props.actions.UpdateSelf({ 
        skippedTime: this.player.getCurrentTime(),
        getCurrentTime: false
      })
      setTimeout(() => {
        this.setState({skipping: false})
      }, 1000);
    } else if (this.state.gctUpdate === true) {
      setTimeout(() => {
        this.setState({gctUpdate: false})
      }, 200);
    }
  }

  changeChannel(channel: string) {
    this.props.actions.UpdateSelf({ 
      channel,
      playing: false,
      changedChannel: true
    })
  }

  togglePlay = (isPlaying: boolean) => {
    const isLive = !!this.player.getInternalPlayer().getChannel();
      if(isLive) {
        this.props.actions.UpdateSelf({ 
          playing: isPlaying
        })
        this.setState({starting: false})
      } else if(!this.state.starting) {
        const { changedChannel, playing } = this.props.data;
        const playerState = this.player.getInternalPlayer()._player._playerState.playback;
          if(isPlaying === true) { 
            if((changedChannel || playerState === 'Idle' || playerState === 'Ready') && !playing) {
              this.props.actions.UpdateSelf({ 
                playing: isPlaying
              })
              this.setState({isPlaying: true})
              if(changedChannel) {
                this.props.actions.UpdateSelf({ 
                  changedChannel: false
                })
              }
            }
          } else if (!isPlaying) {
            setTimeout(() => {
              if(this.player.getInternalPlayer()._player._playerState.playback === 'Idle') {
                this.props.actions.UpdateSelf({ 
                  playing: false
                })
                this.setState({isPlaying: false})
              } 
            }, 500);
          }
      } else {
        this.setState({isPlaying})
      }
  } 

  onReady = () => {
    this.props.actions.UpdateSelf({ 
      skippedTime: 0
    })
  }

  onStart = () => {
    this.setState({isPlaying: true});
    const { playing } = this.props.data;
    if(!playing) {
      this.props.actions.UpdateSelf({ 
        playing: true
      })
    } else if(playing) {
      this.setState({gctUpdate: true})
      this.props.actions.UpdateSelf({ 
        getCurrentTime: true
      })
    }
  }

  onProgress = async() => {
    const isLive = !!this.player.getInternalPlayer().getChannel();
    if(!isLive) {
      if(this.state.skipping === false && Math.abs(this.state.currentTime - this.player.getCurrentTime()) > 10) {
        this.setState({skipping: true})
        this.props.actions.UpdateSelf({ 
          skippedTime: this.player.getCurrentTime()
        })
        
        setTimeout(() => {
          this.setState({skipping: false})
        }, 1000);
      }
      this.setState({currentTime: this.player.getCurrentTime()});
    }

    if(this.state.starting) {
      setTimeout(() => {
        this.setState({starting: false})
      }, 100);
    }
  }

  render() {
    return (
      <Container>
        {!!this.props.data.channel && (
          <ReactPlayer
            width="100%"
            height="100%"
            url={`https://www.twitch.tv/${this.props.data.channel}`}
            className="react-player"
            {...{ ref: (r: any) => (this.player = r) } as any}
            onPlay={() => this.togglePlay(true)}
            onPause={() => this.togglePlay(false)}
            onReady={() => this.onReady()}
            onStart={() => this.onStart()}
            onProgress={() => this.onProgress()}
            progressInterval={500}
            muted={false}
            config={{
              twitch: {
                  autoplay: false,
              },
            }}
          />
        )}
        <LinkChanger
          changeChannel={this.changeChannel}
          channel={this.props.data.channel}
          addLink={this.props.actions.AddLink}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: stretch;
  position: relative;
  padding-bottom: 4px;
  background: transparent;

  iframe {
    border-radius: 15px;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`
