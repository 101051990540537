import React, { useRef, useEffect } from "react";

const AudioTrack = ({ track }: { track: any }) => {
  const trackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const child = track.attach();
    //console.log('**twil', { child })
    if (
      trackRef &&
      trackRef.current
    ) {
      trackRef.current.classList.add(track.kind);
      trackRef.current.appendChild(child);
      if (child.setSinkId) {
        child.setSinkId(localStorage.audio_output_device_id);
      }
    }

    return () => {
      if (track.detach) {
        track.detach()
      }
    }
  }, []);

  return <div className="track" ref={trackRef}></div>;
};

function areEqual(prevProps: { track: any }, nextProps: { track: any }) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
}

export default React.memo(AudioTrack, areEqual);
