import moment from "moment";

export const swapArrayElements = (list: any[], sourceIndex: number, destinationIndex: number) => {
    if (list.length === 1) return list;
    list.splice(destinationIndex, 1, list.splice(sourceIndex, 1, list[destinationIndex])[0]);
    return list;
}

export const rearrangeElements = (list: any[], sourceIndex: number, destinationIndex: number, elem: any) => {
    const newlist = Array.from(list)
    newlist.splice(sourceIndex, 1)
    newlist.splice(destinationIndex, 0, elem)
    return newlist
}

export const moveElementToOtherArray = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
    const sourceClone = source;
    const destClone = destination;

    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result.source = sourceClone;
    result.destination = destClone;

    return result;
};

// Call without screenshare tile
export const checkDiv = (increment: number, count: number, width: number, height: number, margin: number = 10) => {
    let i: number = 0, w: number = 0
    let h = increment * 0.75 + (margin * 2)

    while (i < (count)) {
        if ((w + increment) > width) {
            w = 0
            h = h + (increment * 0.75) + (margin * 2)
        }
        w = w + increment + (margin * 2)
        i++
    }
    if (h > height) return false
    else return increment
}

export const updateLayout = () => {
    let margin: number = 5, width: number = 0, height: number = 0;
    let callContainer: HTMLElement | null = document.getElementById('callContainer');

    // debugger

    if(callContainer !== null) {
        width = callContainer.offsetWidth - (margin * 5);
        height = callContainer.offsetHeight - (margin * 5);
    }

    let callParticipants: HTMLCollectionOf<Element> = document.getElementsByClassName('callParticipants');

    let max: number = 0;
    let i: number = 1;
    while (i < 5000) {
        let w = checkDiv(i, callParticipants?.length, width, height, margin);
        if (w === false) {
            max =  i - 1
            break
        }
        i++
    }

    max = max - (margin);
    setWidth(max, margin);
}

export const setWidth = (width: number, margin: number) =>  {
    let callParticipants = document.querySelectorAll<HTMLElement>('.callParticipants');

    for (var s = 0; s < callParticipants.length; s++) {
        callParticipants[s].style.width = width + "px";
        callParticipants[s].style.margin = margin + "px";
        callParticipants[s].style.height = (width * 0.75) + "px";
    }

    const tileUsernames = document.getElementsByClassName("ellipsis-tile-username");
    for (var s=0; s < tileUsernames.length; s++) {
        const el: any = tileUsernames[s]
        el.style.maxWidth = (width - 70) + "px";
    }
}

// call with screenshare tile
export const checkDivPresentation = (increment: number, count: number, width: number, height: number, margin: number = 10) => {
    let i: number = 0, w: number = 0
    let h = increment * 0.75 + (margin * 2)

    while (i < (count)) {
        if ((w + increment) > width) {
            w = 0
            h = h + (increment * 0.75) + (margin * 2)
        }
        w = w + increment + (margin * 2)
        i++
    }
    if (h > height) return false
    else return increment
}

export const updateLayoutPresentation = () => {
    let margin: number = 2, width: number = 0, height: number = 0;
    let callContainer: HTMLElement | null = document.getElementById('presentationContainer');

    if(callContainer !== null) {
        width = callContainer.offsetWidth - (margin * 2);
        height = callContainer.offsetHeight - (margin * 2);
    }

    let callParticipants: HTMLCollectionOf<Element> = document.getElementsByClassName('presentationParticipant');

    let max: number = 0;
    let i: number = 1;
    while (i < 5000) {
        let w = checkDivPresentation(i, callParticipants?.length, width, height, margin);
        if (w === false) {
            max =  i - 1
            break
        }
        i++
    }

    max = max - (margin * 2);
    setWidthPresentation(max, margin);
}

export const setWidthPresentation = (width: number, margin: number) =>  {
    let callParticipants = document.querySelectorAll<HTMLElement>('.presentationParticipant');

    for (var s = 0; s < callParticipants.length; s++) {
        callParticipants[s].style.width = (width + 356) + "px";
        callParticipants[s].style.margin = margin + "px";
        callParticipants[s].style.height = (width * 0.75) + "px";
    }
}

export const sortDate = (dateA: any, dateB: any, direction = 'asc') => {
    const formats = ['DD-MM-YYYY']; // can be several
    return (moment(dateA, formats).isBefore(moment(dateB, formats)) ? -1
        : moment(dateA, formats).isAfter(moment(dateB, formats)) ? 1 : 0) * (direction === 'asc' ? 1 : -1)
}

export const humanFileSize = (bytes: number, si=true, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

export const shuffleArray = (a: any[]) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const timeTodayDateElse = (date: Date) => {
    return moment(date).calendar(null, {
        'lastDay' : 'D MMMM',
        'sameDay' : 'h:mm A',
        'nextDay' : 'D MMMM',
        'lastWeek' : 'D MMMM',
        'nextWeek' : 'D MMMM',
        'sameElse' : 'D MMMM'
    });
}

export const appendScript = (scriptToAppend: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = scriptToAppend
        script.async = true
        script.defer = true
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script)
    })
}

export const removeScript = (scriptToremove: string) => {
    let allsuspects=document.getElementsByTagName("script");
    for (let i=allsuspects.length; i>=0; i--){
        if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null
        && allsuspects[i].getAttribute("src")?.indexOf(`${scriptToremove}`) !== -1 ){
           allsuspects[i].parentNode?.removeChild(allsuspects[i])
        }
    }
}

export const isJSON = (str: any) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}