import React, { FormEvent } from 'react'
import { FiArrowLeft as BackIcon } from '@react-icons/all-files/fi/FiArrowLeft'

import { BackButton, Content, LoginForm, SubmitButton, Title } from './styled'
import LoopApi from '../../helpers/LoopApi'
import useToastify from '../../helpers/useToastify'
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast'

interface Props {
    onBack: () => void
    username?: string
    email: string
}

function ResendVerification({ onBack, username, email }: Props) {

    const onResendVerification = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            const response = await LoopApi(null, 'ResendToken', { email })
            useToastify({
                message: () => SuccessErrorToast({ message: response.msg || 'Successfully resent verification', type: response.error ? 'error' : 'success' }),
                position: "top-right",
                autoClose: 1500,
                closeButton: false,
                hideProgressBar: true,
                className: 'toastL',
                bodyClassName: "grow-font-size",
            })
        } catch(err) {
            useToastify({
                message: () => SuccessErrorToast({ message: 'Failed to resend verification', type: 'error' }),
                position: "top-right",
                autoClose: 1500,
                closeButton: false,
                hideProgressBar: true,
                className: 'toastL',
                bodyClassName: "grow-font-size",
            })
        }
    }

  
  return (
    <LoginForm onSubmit={onResendVerification}>
        <Title>
            <BackButton onClick={onBack}><BackIcon /></BackButton> 
            Verify your email
        </Title>
        <Content>Hi, <span className='emphasis'>{username || 'User'}</span>! It seems that your account is <span style={{fontWeight: 600}}>not verified</span> yet. Please check your email for the verification or use the button below to resend a verification email. Thank you!</Content>
        <SubmitButton
            className="button primary"
            // disabled={this.state.isMagicLink ? this.state.isSendingMagicLink : this.state.loggingIn}
            value="Resend verification email"
            type="submit"
        />
    </LoginForm>
  )
}

export default ResendVerification