const SAMPLE_TEXT = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."

export const MEMBERS = [
    { 
      value: 'member1@email.com', 
      label: 'member1',
      name: "My Schedules", 
      events: [
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Standard' }, { topic: 'topic', time: '2022-05-20T14:00:00', type: 'Holiday' }, { topic: 'topic', time: '2022-05-20T17:00:00', type: 'Standard' }],
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [],
        [{ topic: 'topic', time: '2022-05-20T09:00:00', type: 'Holiday' }, { topic: 'topic', time: '2022-05-20T16:00:00', type: 'Holiday' }],
        [{ topic: 'topic', time: '2022-05-20T16:00:00', type: 'Standard' }],
      ]
    }, 
    { 
      value: 'member2@email.com', 
      label: 'member2',
      name: "Member 2",
      events: [
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [],
        [{ topic: 'topic', time: '2022-05-20T17:00:00', type: 'Standard' }],
        [{ topic: 'topic', time: '2022-05-20T16:00:00', type: 'Standard' }],
        [],
        [{ topic: 'topic', time: '2022-05-20T09:00:00', type: 'Holiday' }, { topic: 'topic', time: '2022-05-20T21:00:00', type: 'Holiday' }],
        [],
      ]
    }, 
    { 
      value: 'testmem@gmail.com', 
      label: 'testmem',
      name: "Test Member", 
      events: [
        [{ topic: 'topic', time: '2022-05-20T09:00:00', type: 'Holiday' }],
        [],
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [{ topic: 'topic', time: '2022-05-20T02:00:00', type: 'Standard' }, { topic: 'topic', time: '2022-05-20T11:00:00', type: 'Standard' }, { topic: 'topic', time: '2022-05-20T17:00:00', type: 'Standard' }],
        [],
      ]
    }, 
    { 
      value: 'orayt@email.com', 
      label: 'orayt',
      name: "Mr Orayt", 
      events: [
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T09:00:00', type: 'Holiday' }, { topic: 'topic', time: '2022-05-20T14:30:00', type: 'Holiday' }],
        [],
      ]
    },
    { 
      value: 'member3@email.com', 
      label: 'member3',
      name: "Member 3", 
      events: [
        [{ topic: 'topic', time: '2022-05-20T09:00:00', type: 'Holiday' }],
        [],
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [],
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Standard' }, { topic: 'topic', time: '2022-05-20T14:00:00', type: 'Holiday' }, { topic: 'topic', time: '2022-05-20T17:30:00', type: 'Standard' }],
      ]
    }, 
    { 
      value: 'testmem2@gmail.com', 
      label: 'testmem2',
      name: "Test Mem2", 
      events: [
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [],
        [{ topic: 'topic', time: '2022-05-20T17:00:00', type: 'Standard' }],
        [{ topic: 'topic', time: '2022-05-20T16:00:00', type: 'Standard' }],
        [],
        [{ topic: 'topic', time: '2022-05-20T09:30:00', type: 'Holiday' }, { topic: 'topic', time: '2022-05-20T22:00:00', type: 'Holiday' }],
        [],
      ]
    }, 
    { 
      value: 'orayt2@email.com', 
      label: 'orayt2',
      name: "Mrs Orayt2", 
      events: [
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T11:00:00', type: 'Holiday' }],
        [],
        [],
        [{ topic: 'topic', time: '2022-05-20T15:00:00', type: 'Standard' }, { topic: 'topic', time: '2022-05-20T19:00:00', type: 'Standard' }],
        [],
      ]
    }
  ] 


export default [
    {
      id: 1,
      topic: "Holiday",
      dateFrom: "2022-04-29T12:00",
      dateTo: "2022-05-03T08:45",
      text: SAMPLE_TEXT,
      type: "Holiday"
    },
    {
      id: 2,
      topic: "Meeting",
      dateFrom: "2022-05-01T09:45",
      dateTo: "2022-05-04T22:00",
      text: SAMPLE_TEXT,
      type: "Standard"
    },
    {
      id: 3,
      topic: "Away",
      dateFrom: "2022-05-01T01:00",
      dateTo: "2022-05-01T23:59",
      text: SAMPLE_TEXT,
      type: "Busy"
    },
    {
      id: 4,
      topic: "Inspection",
      dateFrom: "2022-05-19T07:30",
      dateTo: "2022-05-21T23:59",
      text: SAMPLE_TEXT,
      type: "Standard"
    },
    {
      id: 5,
      topic: "Holiday - Greece",
      dateFrom: "2022-05-14T08:00",
      dateTo: "2022-05-16T23:59",
      text: SAMPLE_TEXT,
      type: "Holiday"
    },
    {
      id: 6,
      topic: "Holiday - Spain",
      dateFrom: "2022-05-29T08:00",
      dateTo: "2022-05-31T23:59",
      text: SAMPLE_TEXT,
      type: "Holiday"
    }
  ]