import React from "react"
import styled from "styled-components"
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu"
import { DropboxFile, SelectionItem } from "../types"
import getIcon from "../_Shared/FileIcon"
import { timeTodayDateElse } from '../../helpers'

interface Props {
	file: DropboxFile,
	downloadFile: Function
	viewFile: Function
	presentFile: Function
	setFileToDownload: (file: DropboxFile) => void
	options: SelectionItem[]
	onPresent: Function
	colorScheme: string
}

function ListFileItem(props: Props) {
	const {
		file,
		options,
		downloadFile,
		presentFile,
		viewFile,
		setFileToDownload,
		onPresent
	} = props

	const FileIcon = getIcon(file.name)

	const viewOrDownload = () => {
		const viewIndex = options.findIndex(option => option.value === "view")

		if (viewIndex > -1) {
			viewFile(file)
		} else {
			setFileToDownload(file)
		}
	}

	const handleContextMenuClick = async (_: any, selected: SelectionItem) => {

		switch (selected.value) {
			case "download":
				downloadFile(file)
				break;

			case "view":
				viewFile(file)
				break;

			case "pdf-presenter":
				presentFile(file)
				break;

			case "present":
				await viewFile(file)
				onPresent()
				break;

			default:
				// no-op
				break;
		}
	}

	return (
		<div>
			<ContextMenuTrigger id={file.id || ""}>
				<Container onClick={viewOrDownload}>
					<IconWrapper>
						<FileIcon size={24} fill="#fff" />
					</IconWrapper>

					<Text>{file.name}</Text>
					<Text></Text>
					<Text>{timeTodayDateElse(file.client_modified)}</Text>
				</Container>
			</ContextMenuTrigger>

			<StyledContextMenu id={file.id || ""}>
				{options.map(option => (
					<StyledMenuItem
						data={option}
						onClick={handleContextMenuClick}
					>
						<option.icon className="todo-dropmenu-svg" /> {option.text}
					</StyledMenuItem>
				))}
			</StyledContextMenu>
		</div>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: 50px 3fr 1fr 1fr;
	min-height: 48px;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer;

	&:hover {
		background-color: rgba(0,0,0,0.1);
	}
`

const IconWrapper = styled.div`
	border-radius: 4px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Text = styled.p`
	font-size: 12px;
	word-break: break-all;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

const StyledContextMenu = styled(ContextMenu)`
	background-color: #000;
`

const StyledMenuItem = styled(MenuItem)`
	color: #fff;

	&:hover, &:hover > svg {
		color: blue;
		fill: blue;
	}
`

export default ListFileItem
