import React, { Component } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import { FaLink as ExternalLink } from '@react-icons/all-files/fa/FaLink'
import { IoIosGlobe as Globe } from '@react-icons/all-files/io/IoIosGlobe'
import { buildImageProxy } from '../../helpers/ApiCaller'

import LinkTimer from './LinkTimer'

import { TiDelete as Remove } from '@react-icons/all-files/ti/TiDelete'
import { FiEdit2 as EditIcon } from '@react-icons/all-files/fi/FiEdit2'

interface Props {
  link: ILink
  onClickRaw: React.MouseEventHandler<HTMLDivElement>
  onClick: React.MouseEventHandler<HTMLDivElement>
  nolazy: boolean
  onClickRemove: React.MouseEventHandler<SVGElement>
  travelling: boolean | undefined
  onClickEdit: React.MouseEventHandler<SVGElement>
}

interface ILink {
  url: string
  logo: string
  logo_hash: string
  image: string
  image_hash: string
  title: string
  description: string
  timestamp: number
}

export default class LinkItem extends Component<Props> {
  render() {
    let my_fav = null
    const { travelling } = this.props
    if (this.props.link.url) {
      my_fav = this.props.link.logo ? (
        <Favicon src={buildImageProxy(this.props.link.logo, this.props.link.logo_hash)} />
      ) : <GlobeIco />

      if (!this.props.nolazy) {
        my_fav = (
          // <LazyLoad overflow once height={16}>
          //   {my_fav}
          // </LazyLoad>
          <div>{my_fav}</div>
        )
      }
    }

    let my_link_img = null
    if (this.props.link.image) {
      my_link_img = (
        <LinkImage
          src={buildImageProxy(
            this.props.link.image,
            this.props.link.image_hash
          )}
        />
      )

      if (!this.props.nolazy) {
        my_link_img = (
          // <LazyLoad overflow once height={100}>
          //   {my_link_img}
          // </LazyLoad>
          <div>{my_link_img}</div>
        )
      }
    }

    return (
      <Container className={`link-item-container ${`link-${this.props.link.timestamp}`}`} travelling={travelling} {...!travelling && {onClick:this.props.onClick}}>
        <LinkItemContainer>
          <Link hasPic={!!this.props.link.image}>
            <LinkTitle>
              {my_fav}
              <div>{this.props.link.title}</div>
            </LinkTitle>
            <LinkDescription>{this.props.link.description}</LinkDescription>
            <LinkLine {...!travelling && {onClick: this.props.onClickRaw}} className="color-primaryhover">
              <LinkTimer timestamp={this.props.link.timestamp} /> -
              <UrlWrap className="color-primaryhover">{this.props.link.url}</UrlWrap>
              <OpenLinkIcon />
            </LinkLine>
          </Link>
          {my_link_img}
          {!travelling && <OptionsWrapper>
            <EditIcon className="link-edit" onClick={this.props.onClickEdit}/>
            <Remove className="link-remove" onClick={this.props.onClickRemove} />
          </OptionsWrapper>}
        </LinkItemContainer>
      </Container>
    )
  }
}

const UrlWrap = styled.div`
  text-decoration: underline;
  display: inline;
  margin-left: 4px;
  margin-right: 4px;
`

const Container = styled.div<{travelling: boolean | undefined}>`
  padding: 12px;
  cursor: ${({travelling}) => travelling ? 'default' : 'pointer'};
  position: relative;
  border-bottom: 2px dashed #5e5f61;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.selected-link {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const LinkItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OpenLinkIcon = styled(ExternalLink)`
  opacity: 0;
  transition: opacity 0.3s ease-out;
`

const LinkImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: fill;
  margin-right: 40px;

  // &:after {
  //   content: '\f1c5';

  //   font-size: 36px;
  //   font-family: FontAwesome;
  //   color: rgb(100, 100, 100);
  //   padding-right: 24px;

  //   display: block;
  //   position: absolute;
  //   z-index: 2;
  //   top: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #fff;
  // }
`

const Link = styled.div<{ hasPic: boolean }>`
  flex-grow: 1;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: ${props => (props.hasPic ? '70%' : '100%')};
`

const Favicon = styled.img`
  width: auto;
  height: 16px;
  margin-right: 4px;
  position: relative;

  // &:after {
  //   content: '\f08e';

  //   font-size: 16px;
  //   font-family: FontAwesome;
  //   color: rgb(100, 100, 100);

  //   display: block;
  //   position: absolute;
  //   z-index: 2;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #fff;
  // }
`

const GlobeIco = styled(Globe)`
  width: auto;
  height: 16px;
  margin-right: 4px;
  position: relative;

  // &:after {
  //   content: '\f08e';

  //   font-size: 16px;
  //   font-family: FontAwesome;
  //   color: rgb(100, 100, 100);

  //   display: block;
  //   position: absolute;
  //   z-index: 2;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #fff;
  // }
`

const LinkDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const LinkTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.textPrimary};
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textBlack};

  > div {
    padding-bottom: 4px;
    padding-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const LinkLine = styled.div`
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  overflow: hidden;
  padding-bottom: 4px;
  text-overflow: ellipsis;
  color: ${props => props.theme.textSecondary};
  font-size: ${props => props.theme.textMD};
  font-weight: ${props => props.theme.textBold};
  &:hover {

    ${OpenLinkIcon} {
      opacity: 1;
    }
  }
`

const OptionsWrapper = styled.div`
  position: absolute;
  right: 2px;
  cursor: pointer;
  top: 2px;
  display: flex;
  align-items: center;

  .link-remove {
    height: 25px;
    width: 25px;
    transition: all 200ms ease 0s;
    :hover {
      transform: scale(1.2);
    }
  }

  .link-edit {
    height: 17px;
    width: 17px;
    transition: all 200ms ease 0s;
    :hover {
      transform: scale(1.2);
    }
  }
`
