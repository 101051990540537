import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { IParticipant } from '../../../../calling/types'
import { AuthStore } from '../../../../stores/AuthStore'

import { MainStore } from '../../../../stores/MainStore'
import { IVariableCallingStoreState, VariableCallingStore } from '../../../../stores/VariableCallingStore'
import CallSlide from '../../NewCall/CallSlide'
import PresentationTile from '../../NewCall/CallSlide/CallTiles/PresentationTile'
import CallUserUI from '../../Sidebar/UsersInCall/CallUserUI'
import PresentationUI from '../../Sidebar/UsersInCall/PresentationUI'
import CallTimer from '../CallControls/CallTimer'

type IState = Pick<
	IVariableCallingStoreState,
	'session' | 'current_speaker' | 'participants' | 'current_presenter' | 'startTime' | 'call_starter'
> & {
	users: any[]
	callObject: any
	jwt: any
	meetingName: ''
}
declare const window: any;

export default class SidebarUsersInCall extends Reflux.Component {
	constructor(props: never) {
		super(props)
		this.stores = [VariableCallingStore, MainStore, AuthStore]
		this.storeKeys = ['participants', 'users', 'callObject', 'current_presenter', 'startTime', 'call_starter', 'jwt', 'meetingName']

		this.presentationRef = null
	}
	state: IState
	presentationRef: any

	makeCallUser = (id: string | IParticipant, currentUser: boolean, screen?: boolean) => {
		const part =
			typeof id !== 'string'
				? id
				: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const participant = this.state.users.find((u: any) => u.id === part.userId)
		const streamStates = this.getStreamStates(participant)
		const isHost = this.state.call_starter === part.userId
		const youAreHost = this.state.call_starter === this.state.jwt?.data?._id

		return (
			<CallUserUI 
				youAreHost={youAreHost}
				meetingName={this.state.meetingName}
				isHost={isHost}
				participant={participant} 
				isCurrentUser={currentUser} 
				preview={!this.props.showOtherUsers} 
				streamStates={this.getStreamStates(participant)} 
				key={part.userId}
				videoTrack={part.tracks.find((t) => t.kind === 'video')}
				audioTrack={part.tracks.find((t) => t.kind === 'audio')}
				screenTrack={part.tracks.find((t) => t.kind === 'screenVideo')}
			/>
		)
	}

	makeScreenPresentation = (id: string | IParticipant) => {
		const part =
		typeof id !== 'string'
			? id
			: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}
		// const streamStates = this.getStreamStates(participant)
		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')

		if(!screenTrack) return
		return (
            <PresentationUI
                withBg
                key={`${id}-presentation`}
                screenTrack={screenTrack}
				onClick={() => this.props._setActiveWidget(null)}
            />
		)
	}

	renderScreenPresentation = (id: string | IParticipant) => {
		const part =
		typeof id !== 'string'
			? id
			: this.state.participants.find((p) => p.userId === id)
		if (!part) {
			return null
		}

		const screenTrack = part.tracks.find((t) => t.kind === 'screenVideo')

		const participant = this.state.users.find((u: any) => u.id === part.userId)

        const {isCameraMuted, isMicMuted, isSharingScreen} = this.getStreamStates(participant)

		//console.log("SCREENTRACK: ", screenTrack)
		if(!screenTrack && !isSharingScreen) return
		return (
            <PresentationTile
                screenTrack={screenTrack}
				onClick={() => this.props._setActiveWidget(null)}
            />
		)
	}

	getStreamStates(participant: any = {}) {
		const { callObject } = this.state;

		let isCameraMuted,
		  isMicMuted,
		  isSharingScreen = false;
		if (
			callObject &&
			callObject.participants &&
			callObject.participants()
		) {
			const participants = Object.keys(callObject.participants()).map(i => callObject.participants()[i])
			const localParticipant = participants.find((p) => participant.id === p.user_name)
			isCameraMuted = !localParticipant?.video;
			isMicMuted = !localParticipant?.audio;
			isSharingScreen = localParticipant?.screen;
		}
		return { isCameraMuted, isMicMuted, isSharingScreen };
	}

	shouldComponentUpdate(nextProps: never, nextState: IState) {
		return JSON.stringify(this.state.participants) !== JSON.stringify(nextState.participants)
	}


	render() {
		let current_speaker = this.state.current_speaker
		let current_presenter = this.state.current_presenter
 		if (!current_speaker && this.state.participants.length) {
			current_speaker = this.state.participants[0].userId
		}

		return (
			<React.Fragment>
				<CallTimer startTime={this.state.startTime} mobile/>
				<Outer className="container main">
					<CallTimer startTime={this.state.startTime} />
					<CallSlide showOtherUsers _setActiveWidget={this.props._setActiveWidget}/>
				</Outer>
			</React.Fragment>
		)
	}
}

const Outer = styled.div`
	display: flex;
    flex-direction: column;
	align-items: center;
    /* padding-top: 15px; */
    padding-bottom: 15px;
	padding-right: 15px;
    overflow-y: auto;
	width: 220px;
	position: relative;

	.main-timer {
		display: none;
	}

	& > #callContainer {
        width: 220px;
        left: auto;
        bottom: auto;
        min-height: 100%;
        align-content: baseline;
        padding-top: 50px;
        padding-right: 20px;

        > .callParticipants {
            max-height: 150px;

            .host-indicator {
                width: 14px;
                
                svg {
                    width: 14px;
                    margin-right: 5px;
                }
            }

			.tile-username {
				.ellipsis-tile-username {
					max-width: 120px !important;
				}
			}


            .call-control {
                padding: 12px !important;
                font-size: 14px;
                margin: 2px !important;
            }
        }

		> .callParticipants.screenshareTile {
			display: inline-block;
			cursor: pointer;
		}
    }

	/* @media (max-width: 970px) {
		flex-direction: row;
		width: 100%;
		height: 190px;

		> #
	} */

    @media (max-width: 970px) {
        width: 100%;
        min-width: 100%;
        /* height: calc(100% - 160px);
        min-height: calc(100% - 160px);
        max-height: calc(100% - 160px); */

		height: 170px;

        & > #callContainer {
            width: 100%;
            top: auto;
            bottom: 0;
            min-height: 160px;
            max-height: 160px;
            align-content: center;
            left: 0;
            overflow: auto;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: baseline;
			padding: 0 20px 10px 20px;


            > .callParticipants {
                max-width: 150px;
				min-width: 150px;
                max-height: 150px;
                min-height: 130px;

                .call-control {
                    padding: 10px !important;
                    font-size: 14px;
                    margin: 2px !important;
                }

				.tile-username {
					.ellipsis-tile-username {
						max-width: 80px !important;
					}
				}
            }

			> #participantContainer {
                max-width: 150px;
                max-height: 150px;
                min-height: 130px;
			}
        }
    }
`
// .call-participant, .presentation-ui, .callParticipants {
// 	width: 200px !important;
// 	min-width: 200px !important;
// 	height: 180px !important;
// 	max-height: 180px !important;
// 	min-height: 180px !important;
// 	margin: 10px 0 0px 0 !important;

// 	video {
// 		/* object-fit: */
// 	}

// 	.curname {
// 		top: 10px;
// 		left: 10px;
// 		right: auto;
// 		bottom: auto;
// 	}

// 	.muted {
// 		top: 10px;
// 		right: 10px;
// 		left: auto;
// 		bottom: auto;
// 	}

// 	.call-control {
// 		padding: 11px !important;
// 		font-size: 16px;
// 	}
// }

// &.upsidedown {
// 	flex-direction: column;

// 	.right-call {
// 		width: 100%;
// 		height: 75%;
// 		min-height: 75%;
// 	}

// 	.left-call {
// 		flex-direction: row;
// 		align-self: center;
// 		overflow-x: auto;
// 		max-width: 100%;
// 		width: 100%;

// 		.call-participant {
// 			width: 100% !important;
// 			max-height: 150px !important;
// 			height: 150px !important;
// 			width: 180px !important;
// 			min-width: 180px !important;

// 			video {
// 				/* object-fit: */
// 			}
// 		}
// 	}

// 	@media (max-width: 970px) {
// 		flex-direction: row;

// 		.right-call {
// 			flex: 1;
// 		}

// 		.left-call {
// 			width: auto;
// 			align-self: baseline;
// 		}
// 	}
// }



// .call-tile-wrapper {
// 	padding: 20px 6px;
// }

// .call-participant, .presentation-ui, .callParicipants {
// 	width: 150px !important;
// 	min-width: 150px !important;
// 	height: 130px !important;
// 	max-height: 130px !important;
// 	min-height: 130px !important;
// 	margin: 0 10px !important;

// 	.curname, .othername {
// 		font-size: 9px;
// 		padding: 8px 5px !important;
// 	}

// 	.call-control-container, .call-more-controls-container {
// 		left: 10px;
// 		right: 10px;
// 		bottom: 10px;
// 	}

// 	.call-control {
// 		padding: 7px !important;
// 	}
// }