import { DailyCall, DailyParticipant, DailyTrackState } from '@daily-co/daily-js'
import {useEffect, useState} from 'react'

import {Participant, Room, TrackPublication} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

/*
  Returns the participant that is sharing their screen (if any). This hook assumes that only one participant
  can share their screen at a time.
*/
export default function useScreenShareParticipant() {
  const {room, callProvider, setScreenshareId} = useMeetingContext()
  const [screenShareParticipant, setScreenShareParticipant] =
    useState<Participant | DailyParticipant>()

  useEffect(() => {
    if (room) {
      const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
      
      const updateScreenShareParticipant = () => {
        // for twilio provider
        if(isTwilio) {
          setScreenShareParticipant(
            Array.from<Participant>((room as Room)?.participants?.values())
              // the screenshare participant could be the localParticipant
              .concat((room as Room).localParticipant)
              .find((participant: Participant) =>
                Array.from<TrackPublication>(participant.tracks.values()).find(
                  (track) => track.trackName.includes('screen'),
                ),
              ),
          )
          // for daily provider
        } else if(callProvider === CallingProviderName.DailyCo) {
          const ssParticipant = Object.values((room as DailyCall).participants())
            .find((participant: DailyParticipant) => participant.screen)
          setScreenShareParticipant(ssParticipant)
          setScreenshareId(ssParticipant?.screenVideoTrack?.id || null)
          
        }
      }
      updateScreenShareParticipant()

      if(isTwilio) {
        (room as Room).on('trackPublished', updateScreenShareParticipant);
        (room as Room).on('trackUnpublished', updateScreenShareParticipant);
        (room as Room).on('participantDisconnected', updateScreenShareParticipant);

        // the room object does not emit 'trackPublished' events for the localParticipant,
        // so we need to listen for them here.
        (room as Room).localParticipant.on('trackPublished', updateScreenShareParticipant);
        (room as Room).localParticipant.on('trackUnpublished', updateScreenShareParticipant);
      } else if(callProvider === CallingProviderName.DailyCo) {
        (room as DailyCall).on('participant-updated', updateScreenShareParticipant);  
        (room as DailyCall).on('participant-left', updateScreenShareParticipant);  

        (room as DailyCall).on('left-meeting', updateScreenShareParticipant);
      }

      return () => {
        if(isTwilio) {
          (room as Room).off('trackPublished', updateScreenShareParticipant);
          (room as Room).off('trackUnpublished', updateScreenShareParticipant);
          (room as Room).off('participantDisconnected', updateScreenShareParticipant);
  
          (room as Room).localParticipant.off(
            'trackPublished',
            updateScreenShareParticipant,
          );
          (room as Room).localParticipant.off(
            'trackUnpublished',
            updateScreenShareParticipant,
          );
        } else if(callProvider === CallingProviderName.DailyCo) {
          (room as DailyCall).off('participant-updated', updateScreenShareParticipant);  
          (room as DailyCall).off('participant-left', updateScreenShareParticipant);

          (room as DailyCall).off('left-meeting', updateScreenShareParticipant);
        }
      }
    }
  }, [room, callProvider])

  return screenShareParticipant
}
