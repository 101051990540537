import React from 'react'
import styled from 'styled-components'
import { FiX as CloseIcon } from '@react-icons/all-files/fi/FiX'
import Color from 'color'
import NoImage from './NoImage'
import Reflux from 'reflux'
import { FaSpinner as LoadingIcon } from '@react-icons/all-files/fa/FaSpinner'

import { MainStore, WidgetActions } from '../../../../../stores/MainStore'
import ImageThumbnail from './ImageThumbnail'
import { BiImageAdd as AddImage } from '@react-icons/all-files/bi/BiImageAdd'
import { CgArrowsExpandUpLeft as Expand } from '@react-icons/all-files/cg/CgArrowsExpandUpLeft'
import LoopApi from '../../../../../helpers/LoopApi'
import Lightbox from 'react-images'
import { buildImageProxy } from '../../../../../helpers/ApiCaller'
import DeleteImage from '../../../../../loop-widgets/Images/DeleteImage'
import CustomTip from '../../../../../helpers/CustomTip'
import { VariableCallingStore } from '../../../../../stores/VariableCallingStore'
import { CallingInstanceState } from '../../../../../calling/types'
import useToastify from '../../../../../helpers/useToastify'
import SuccessErrorToast from '../../../../../components/Notifications/SuccessErrorToast'
import moment from 'moment'

const { GlobalState } = require('reflux')

interface Props {
    _updateActiveSync: (arg0: any) => void,
}

interface state {
    widgets: any
    images: any,
    isUploading: boolean,
    lightboxOpen: boolean,
    lightboxIndex: number,
    confirmDelete: boolean,
    toDelete: any
    status: any
}

export default class FloatingImages extends Reflux.Component<typeof Reflux.Store, Props, state> {


    constructor(props: Props) {
        super(props)

        this.stores = [MainStore, VariableCallingStore]
        this.storeKeys = [
            'widgets',
            'status'
        ]
        this.state = {
            isUploading: false,
            widgets: {
                images: {
                    images: []
                }
            },
            images: [],
            lightboxOpen: false,
            lightboxIndex: 0,
            confirmDelete: false,
            toDelete: '',
            status: ''
        }

        this.handleUpload = this.handleUpload.bind(this)
        this.uploadImages = this.uploadImages.bind(this)
        this.handleThumbnailClick = this.handleThumbnailClick.bind(this)
        this.openLightbox = this.openLightbox.bind(this)
        this.askDelete = this.askDelete.bind(this)
        this.hideConfirm = this.hideConfirm.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
    }

    transformImageData = () => {
		let images: any = []
		const imgs = this.state.widgets.images?.images
		if(imgs) {
			if(!Array.isArray(imgs)) {
				images = Object.values(imgs).sort((a: any, b: any) => a.indx - b.indx)
				return images
			}
			return imgs
		}
		return images
	}

    handleUpload(e: any) {
        const imageExts = [
			".apng",
			".avif",
			".gif",
			".jpg",
			".jpeg",
			".jfif",
			".pjpeg",
			".pjp",
			".png",
			".svg",
			".webp",
			".bmp",
			".ico",
			".cur",
			".tif",
			".tiff",
      ".webp"
		]

		// const isImageFile = imageExts.find((ext) => e.target.value.endsWith(ext))
		// if(!!!isImageFile) {
        //     let files = document.getElementById('files-upload')
        //     files = null
        //     return useToastify({
        //         message: () => SuccessErrorToast({ message: 'File type not supported.', type: 'error' }),
        //         position: "top-right",
        //         autoClose: 1500,
        //         closeButton: false,
        //         hideProgressBar: true,
        //         className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
        //         bodyClassName: "grow-font-size",
        //     })
        // }

        this.uploadImages(e.target.files)
    }

    async uploadImages(targetFiles: any) {
        let uploadedLinks: any = null
        try {
			const imageExts = [
				"gif",
				"jpg",
				"jpeg",
				"png",
				"webp",
			]
			const files = Array.from(targetFiles).filter(({ type }) => type.includes('image/') && imageExts.some(a => type.includes(a)))
			const filesNotSupported = Array.from(targetFiles).filter(({ type }) => !!!type.includes('image/') || !!!imageExts.some(a => type.includes(a)))
			// console.log({ files })

			if (filesNotSupported.length > 0 && files.length) {
				useToastify({
					message: () => SuccessErrorToast({ message: 'Some file types not supported but will continue to upload supported files.', type: 'error' }),
					position: "top-right",
					autoClose: 300,
					closeButton: false,
					hideProgressBar: true,
					className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
					bodyClassName: "grow-font-size",
				})
			}

			if(files.length === 0) {
				return useToastify({
					message: () => SuccessErrorToast({ message: `File type${filesNotSupported.length > 1 ? 's' : ''} not supported.`, type: 'error' }),
					position: "top-right",
					autoClose: 1500,
					closeButton: false,
					hideProgressBar: true,
					className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
					bodyClassName: "grow-font-size",
				})
			}

            // Start Loading
            this.setState({ isUploading: true })

            const upload = async (file: any) => {
                const data = new FormData()
                const newFile =  new File([file], `${moment().format('X')}-${file.name}`, {
					type: file.type,
					lastModified: file.lastModified,
				});
				
				data.append('file', newFile)
                data.append('targetWidget', 'images')
                let response = await LoopApi(null, 'UploadFile', {}, undefined, data)
                return response.uri
            }

            const links: any[] = []

            for (const file of files) {
                const uri = await upload(file)
                links.push(uri)
            }

			targetFiles = ''

            uploadedLinks = links
            WidgetActions.AddLink(links)


            this.setState({ isUploading: false })
        } catch (err) {
            console.log(err)
        }
    }

    triggerFileInputClick() {
        let files: any = document.getElementById('files-upload')
        files.value = ''
        if (files) {
            files.click()
        }
    }

    handleThumbnailClick(ind: number) {
        this.setState({ lightboxIndex: ind })
    }

    openLightbox(ind: number) {
        this.setState({
            lightboxIndex: ind,
            lightboxOpen: true
        })
    }

    hideConfirm() {
        this.setState({ confirmDelete: false })
    }

    deleteImage() {

        WidgetActions.RemoveLink(this.state.toDelete)

    }

    askDelete(e: any) {
        this.setState({ confirmDelete: true, toDelete: e })
    }

    render() {

        const images = this.transformImageData()
        const lightbox_images = images?.map((img: any) => ({ src: img ? buildImageProxy(img.url, img.image_hash) : '' }))


        return (
            <div>
                <input type="file" hidden multiple id="files-upload" onChange={this.handleUpload} accept="image/*" />

                <Header className="border-light">
                    <HeaderLeft>Images</HeaderLeft>
                    <HeaderRight>
                        {/* <NewIcon size={20} onClick={() => this._createTemplateNote()} /> */}
                        {this.state.isUploading ? <LoadingIcon className='spin' size={20} /> : <AddImage size={21} onClick={() => this.triggerFileInputClick()} data-tip data-for='add-image-mini' />}
                        <Expand className={this.state.status === CallingInstanceState.Connected ? 'disabled' : ''} size={20} onClick={() => {
                            if(this.state.status === CallingInstanceState.Connected) return
                            WidgetActions.ExpandFloatingWidgets({ currentWidget: 'images', localPush: true })
                            this.props._updateActiveSync('')
                        }
                        } data-tip data-for='expand-mini-img' />

                        <CloseIcon size={20} onClick={() => this.props._updateActiveSync('')} />
                    </HeaderRight>
                </Header>
                <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Expand' dataFor='expand-mini-img' />
                <CustomTip top={-20} left={-10} class='top-right' place='left' tip='Add Image' dataFor='add-image-mini' />
                <Body className="BODY">
                    {
                        images?.length > 0 ?

                            <Images>
                                {images?.map((image: any, ind: number) => <ImageThumbnail ind={ind} url={image.url} hash={image.image_hash} viewImage={this.openLightbox} toDelete={(e: any) => this.askDelete(e)} />)}
                            </Images>
                            // {/* <UploadPhotoBtn
                            //     className="button primary"
                            // >
                            //     {
                            //         false ? (
                            //             <div><LoadingIcon></LoadingIcon></div>
                            //         ) : (
                            //             'Upload Photos'
                            //         )
                            //     }
                            // </UploadPhotoBtn> */}

                            :
                            <NoImage triggerFileInputClick={this.triggerFileInputClick} />
                    }

                </Body>
                {lightbox_images &&
                    <Lightbox
                        images={lightbox_images}
                        backdropClosesModal={true}
                        isOpen={this.state.lightboxOpen}
                        onClose={() => this.setState({ lightboxOpen: false })}
                        onClickNext={() =>
                            this.setState({ lightboxIndex: this.state.lightboxIndex + 1 })
                        }
                        onClickPrev={() =>
                            this.setState({ lightboxIndex: this.state.lightboxIndex - 1 })
                        }
                        showThumbnails={true}
                        showImageCount={false}
                        onClickThumbnail={this.handleThumbnailClick}
                        currentImage={this.state.lightboxIndex}
                    />
                }

                {this.state.confirmDelete && <DeleteImage toggleModal={this.hideConfirm} deleteItem={this.deleteImage} />}
            </div>
        )
    }
}



const Body = styled.div`

    max-height: 440px;
    min-height: 440px;


    @media (max-height: 740px) {
        min-height: 280px;
        max-height: 280px;


    }

    @media (max-width: 480px) {
        min-height: 280px;
        max-height: 280px;


    }
`
const Images = styled.div`

    overflow: auto;
    max-height: 440px;
    min-height: 440px;
    /* display: flex; */
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    display: grid;
    grid-template-areas: "a a";
    gap: 10px;
    justify-content: center;
    @media (max-height: 740px) {
        min-height: 280px;
        max-height: 280px;


    }

    @media (max-width: 480px) {
        min-height: 280px;
        max-height: 280px;


    }
`

const Header = styled.div`
    border-bottom: 1px solid;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

const HeaderLeft = styled.div`
    flex: 1;
`

const HeaderRight = styled.div`
    > * {
        margin-left: 10px;
        cursor: pointer;
    }

    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .spin{
        cursor: not-allowed;
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}

    }
`