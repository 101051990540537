import React, { Component } from "react";
import Reflux from "reflux";
import styled from "styled-components";
import { IParticipant } from "../../../../calling/types";
import { AuthStore } from "../../../../stores/AuthStore";

import { MainStore } from "../../../../stores/MainStore";
import {
  IVariableCallingStoreState,
  VariableCallingStore,
  VariableCallingActions,
} from "../../../../stores/VariableCallingStore";
import CallUserUI from "../../Sidebar/UsersInCall/CallUserUI";
import PresentationUITwilio from "../../Sidebar/UsersInCall/PresentationUITwilio";
import CallTimer from "../CallControls/CallTimer";
import CallUserUITwilio from "../../Sidebar/UsersInCall/CallUserUITwilio";

type IState = Pick<
  IVariableCallingStoreState,
  | "session"
  | "current_speaker"
  | "participants"
  | "current_presenter"
  | "startTime"
  | "call_starter"
  | "participantTracks"
  | "room"
  | "localScreenshareTrack"
  | "remoteScreenshareTrack"
  | "localVideoTrack"
  | "localAudioTrack"
  | "host"
  | "transcriptionTrack"
> & {
  users: any[];
  callObject: any;
  jwt: any;
  meetingName: "";
};
declare const window: any;

export default class SidebarUsersInCallTwilio extends Reflux.Component {
  constructor(props: never) {
    super(props);
    this.stores = [VariableCallingStore, MainStore, AuthStore];
    this.storeKeys = [
      "participants",
      "users",
      "callObject",
      "current_presenter",
      "startTime",
      "call_starter",
      "jwt",
      "meetingName",
      "participantTracks",
      "room",
      "localScreenshareTrack",
      "remoteScreenshareTrack",
      "localVideoTrack",
      "localAudioTrack",
      'host',
      'transcriptionTrack'
    ];

    this.presentationRef = null;
  }
  state: IState;
  presentationRef: any;

  renderLocalScreenshare = () => {
    if (
      !this.state.room ||
      !this.state.current_presenter ||
      !this.state.localScreenshareTrack
    ) {
      return null;
    }

    return (
      <div id="rightCallWrapper" className="right-call">
        <PresentationUITwilio
          onClick={() => this.props._setActiveWidget(null)}
          screenTrack={this.state.localScreenshareTrack}
        />
      </div>
    );
  };

  renderParticipantsScreenshare = () => {
    if (!this.state.room || !this.state.remoteScreenshareTrack) {
      return null;
    }

    // find one participant that is screensharing
    //console.log("RENDER SCREENSHARE TRACKS====================");
    //console.log(this.state.remoteScreenshareTrack);

    let sids = this.state.participantTracks
      .map((participant: any) => {
        return [...participant.videoTracks.values()].map(
          (track: any) => track.trackSid
        );
      })
      .flat();

    //console.log({ sids });

    if (!sids.includes(this.state.remoteScreenshareTrack.sid)) {
      VariableCallingActions.SetParticipantScreenshareTrack(null);
      return null;
    }

    return (
      <div id="rightCallWrapper" className="right-call">
        <PresentationUITwilio
          onClick={() => this.props._setActiveWidget(null)}
          screenTrack={this.state.remoteScreenshareTrack}
        />
      </div>
    );
  };

  shouldComponentUpdate(nextProps: never, nextState: IState) {
    return JSON.stringify(this.state.participants) !== JSON.stringify(nextState.participants) || JSON.stringify(this.state.participantTracks) !== JSON.stringify(nextState.participantTracks) ||
    JSON.stringify(nextState.current_presenter) !== JSON.stringify(this.state.current_presenter) || 
    JSON.stringify(nextState.localScreenshareTrack) !== JSON.stringify(this.state.localScreenshareTrack) || 
    JSON.stringify(nextState.remoteScreenshareTrack) !== JSON.stringify(this.state.remoteScreenshareTrack) || 
    JSON.stringify(nextState.localAudioTrack) !== JSON.stringify(this.state.localAudioTrack) || 
    JSON.stringify(nextState.localVideoTrack) !== JSON.stringify(this.state.localVideoTrack) || 
    JSON.stringify(nextState.transcriptionTrack) !== JSON.stringify(this.state.transcriptionTrack)
  }

  renderLocalParticipant = () => {
    if (!this.state.room) {
      return null;
    }

    const user = this.state.users.find(
      (u: any) => u.id === (this.state.room as any).localParticipant.identity.split('::')[1]
    );

    const isHost = this.state.host === user.id;

    return (
      <CallUserUITwilio
        participant={(this.state.room as any).localParticipant}
        user={user}
        isLocalParticipant={true}
        isHost={isHost}
        room={this.state.room}
        canSharescreen={
          !this.state.localScreenshareTrack &&
          !this.state.remoteScreenshareTrack
        }
        isSharingScreen={!!this.state.localScreenshareTrack}
        localAudioTrack={this.state.localAudioTrack}
        localVideoTrack={this.state.localVideoTrack}
        transcriptionTrack={this.state.transcriptionTrack}
      />
    );
  };

  renderParticipants = () => {
    if (!this.state.room || !Array.isArray(this.state.participantTracks)) {
      return null;
    }

    return this.state.participantTracks.map((participant: any) => {
      const user = this.state.users.find(
        (u: any) => u.id === participant.identity.split('::')[1]
      );
      const isHost = this.state.host === user?.id;

      return (
        <CallUserUITwilio
          key={participant.identity}
          participant={participant}
          user={user}
          isLocalParticipant={false}
          isHost={isHost}
          room={this.state.room}
          canSharescreen={!this.state.localScreenshareTrack && !this.state.remoteScreenshareTrack}
        />
      );
    });
  };

  makeCallUser = (
    id: string | IParticipant,
    currentUser: boolean,
    screen?: boolean
  ) => {
    const part =
      typeof id !== "string"
        ? id
        : this.state.participants.find((p) => p.userId === id);
    if (!part) {
      return null;
    }

    const participant = this.state.users.find((u: any) => u.id === part.userId);
    const streamStates = this.getStreamStates(participant);
    const isHost = this.state.host === part.userId;
    const youAreHost = this.state.host === this.state.jwt?.data?._id;

    return (
      <CallUserUI
        youAreHost={youAreHost}
        meetingName={this.state.meetingName}
        isHost={isHost}
        participant={participant}
        isCurrentUser={currentUser}
        preview={!this.props.showOtherUsers}
        streamStates={this.getStreamStates(participant)}
        key={part.userId}
        videoTrack={part.tracks.find((t) => t.kind === "video")}
        audioTrack={part.tracks.find((t) => t.kind === "audio")}
        screenTrack={part.tracks.find((t) => t.kind === "screenVideo")}
      />
    );
  };

  makeScreenPresentation = (id: string | IParticipant) => {
    const part =
      typeof id !== "string"
        ? id
        : this.state.participants.find((p) => p.userId === id);
    if (!part) {
      return null;
    }
    // const streamStates = this.getStreamStates(participant)
    const screenTrack = part.tracks.find((t) => t.kind === "screenVideo");

    if (!screenTrack) return;
    return (
      <PresentationUITwilio
        withBg
        key={`${id}-presentation`}
        screenTrack={screenTrack}
        onClick={() => this.props._setActiveWidget(null)}
      />
    );
  };

  getStreamStates(participant: any = {}) {
    const { callObject } = this.state;

    let isCameraMuted,
      isMicMuted,
      isSharingScreen = false;
    if (callObject && callObject.participants && callObject.participants()) {
      const participants = Object.keys(callObject.participants()).map(
        (i) => callObject.participants()[i]
      );
      const localParticipant = participants.find(
        (p) => participant.id === p.user_name
      );
      isCameraMuted = !localParticipant?.video;
      isMicMuted = !localParticipant?.audio;
      isSharingScreen = localParticipant?.screen;
    }
    return { isCameraMuted, isMicMuted, isSharingScreen };
  }

  render() {
    let current_speaker = this.state.current_speaker;
    let current_presenter = this.state.current_presenter;
    if (!current_speaker && this.state.participants.length) {
      current_speaker = this.state.participants[0].userId;
    }

    return (
      <Outer className="container main">
        {
          this.state.startTime && <CallTimer startTime={this.state.startTime} />
        }
        {/* { !!current_speaker && this.makeCallUser(current_speaker, true)} */}
        {this.renderLocalParticipant()}
        {this.renderLocalScreenshare()}
        {this.renderParticipantsScreenshare()}
        {this.renderParticipants()}

        {/* { this.renderLocalParticipant() } */}
        {/* { 
					!!current_presenter ? this.makeScreenPresentation(current_presenter) : 
					(this.state.participants || [])
						.filter((p) => p.userId !== current_speaker)
						.map((p) => {
							return this.makeScreenPresentation(p)
						})  
				}
                {
                    (this.state.participants || []).filter((p) => p.userId !== current_speaker)
                    .map((p) => {
                        return this.makeCallUser(p, false)
                    }) 
                } */}
      </Outer>
    );
  }
}

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  overflow-y: auto;

  .call-participant,
  .presentation-ui,
  .callParticipants {
    width: 200px !important;
    min-width: 200px !important;
    height: 160px !important;
    max-height: 160px !important;
    min-height: 160px !important;
    margin: 10px 0 0px 0 !important;

    video {
      /* object-fit: */
    }

    .curname {
      top: 10px;
      left: 10px;
      right: auto;
      bottom: auto;
    }

    .muted {
      top: 10px;
      right: 10px;
      left: auto;
      bottom: auto;
    }

    .tile-username {
      .ellipsis-tile-username {
        max-width: 120px !important;
      }
    }

    .call-control {
      padding: 11px !important;
      font-size: 16px;
    }
  }

  &.upsidedown {
    flex-direction: column;

    .right-call {
      width: 100%;
      height: 75%;
      min-height: 75%;
    }

    .left-call {
      flex-direction: row;
      align-self: center;
      overflow-x: auto;
      max-width: 100%;
      width: 100%;

      .call-participant {
        width: 100% !important;
        max-height: 150px !important;
        height: 150px !important;
        width: 180px !important;
        min-width: 180px !important;

        video {
          /* object-fit: */
        }
      }
    }

    @media (max-width: 970px) {
      flex-direction: row;

      .right-call {
        flex: 1;
      }

      .left-call {
        width: auto;
        align-self: baseline;
      }
    }
  }

  @media (max-width: 970px) {
    flex-direction: row;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-top: 5px;

    .call-tile-wrapper {
      padding: 20px 6px;
    }

    .call-participant,
    .presentation-ui,
    .callParticipants {
      width: 150px !important;
      min-width: 150px !important;
      height: 130px !important;
      max-height: 130px !important;
      min-height: 130px !important;
      margin: 0 10px !important;

      .tile-username {
        .ellipsis-tile-username {
          max-width: 80px !important;
        }
      }

      .call-control-container,
      .call-more-controls-container {
        left: 10px;
        right: 10px;
        bottom: 10px;
      }

      .call-control {
        padding: 7px !important;
      }
    }
  }
`;
