import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, Input, CustomButtonLink } from '../../components/Elements'

import Api, { Actions, Endpoints } from '../_Shared/Api'
import Login from '../_Shared/Login'
import BrowseGists from './BrowseGists'
import GistEditor from './GistEditor'
import Alert from 'react-s-alert'
import { IWidgetProps, AllWidgets } from '../types'
import { WidgetContainer } from '../_Shared/Elements'
import WidgetHeader from '../_Shared/Header'
import { GlobalState } from 'reflux'

const syncedInfo = ['Ability to edit Gists you choose']

type Props = IWidgetProps<AllWidgets.Gists>

interface State {
    newGistName: string
    color_scheme: string
    fileRef: any
}

export default class GistsWidget extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            newGistName: '',
            color_scheme: GlobalState.theming.color_scheme,
            fileRef: React.createRef(),
        }
    }

    async SelectGist(gist: any) {
        let gistText = `// Start typing!`
        if (gist !== 'new') {
            gistText = await (await fetch(gist.files[Object.keys(gist.files)[0]].raw_url)).text()
        }
        this.props.actions.UpdateSelf({ gistText, currentGist: gist })
    }

    AttachGist() {
        this.state.fileRef.current.click()
        this.props.actions.UpdateWidget({
            name: 'asana',
            add: true,
            attachment: this.props.data.currentGist,
        })
    }

    attachFile = (e: any) => {
        //console.log(this.props)
        const reader = new FileReader()
        reader.onload = async (e: any) => {
            const text = e.target.result
            //this.props.actions.UpdateSelf({ gistText: text });
            this.props.actions.UpdateSelf({ gistText: text })
        }
        reader.readAsText(e.target.files[0])
    }

    async SaveChanges() {
        Alert.closeAll()
        if (!this.props.external_token) {
            return
        }

        const { gistText, currentGist } = this.props.data
        let resp

        if (currentGist === 'new') {
            if (this.state.newGistName === '') {
                Alert.error('Must supply a name for the gist', {
                    position: 'bottom-right',
                })
                return
            }
            let body = {
                description: this.state.newGistName,
                public: false,
                files: {} as any,
            }
            body.files[this.state.newGistName] = { content: gistText }
            resp = await Api(Endpoints['Gists'], Actions['CreateGist'], this.props.external_token, {
                body,
            })
        } else {
            let body = { files: {} as any }
            body.files[Object.keys(currentGist.files)[0]] = {
                content: gistText,
            }
            resp = await Api(Endpoints['Gists'], Actions['UpdateGist'], this.props.external_token, {
                urlAppend: `/${currentGist.id}`,
                body,
            })
        }

        this.props.actions.UpdateSelf({ currentGist: resp })
        Alert.success('Gist saved!', { position: 'bottom-right' })
        this.setState({
            newGistName: '',
        })
    }

    render() {
        if (!this.props.external_token && !this.props.data.currentGist) {
            return <Login name='Github Gists' loginKey='github' logo={require('./icon.svg')} syncedInfo={syncedInfo} />
        }

        if (this.props.external_token && !this.props.data.currentGist) {
            return (
                <WidgetContainer>
                    <BrowseGists token={this.props.external_token} SelectGist={this.SelectGist.bind(this)} />
                </WidgetContainer>
            )
        }

        const { gistText, syntax, theme, currentGist } = this.props.data
        return (
            <WidgetContainer>
                <WidgetHeader
                    icon={require('./icon.svg')}
                    divider=''
                    name={
                        this.props.external_token && currentGist === 'new' ? (
                            <CustomButtonLink
                                {...{ type: 'basic' }}
                                onClick={() => this.props.actions.UpdateSelf({ currentGist: null })}
                            >
                                View your gists
                            </CustomButtonLink>
                        ) : (
                            <GistLink
                                href={currentGist.html_url}
                                target='_blank'
                                rel='noopener'
                                isDark={this.state.color_scheme === 'Dark' ? true : false}
                                className='link'
                            >
                                {currentGist.description !== '' ? currentGist.description : `gist:${currentGist.id}`}
                            </GistLink>
                        )
                    }
                    end={
                        <React.Fragment>
                            {this.props.external_token && currentGist === 'new' ? null : (
                                <GistButtonDiscard
                                    className='button'
                                    onClick={() => this.props.actions.UpdateSelf({ currentGist: null })}
                                >
                                    Discard
                                </GistButtonDiscard>
                            )}

                            {this.props.external_token && (
                                <>
                                    <GistButton
                                        className='button'
                                        onClick={() => this.SaveChanges()}
                                        isDark={this.state.color_scheme === 'Dark' ? true : false}
                                    >
                                        {currentGist === 'new' ? 'Save' : 'Update'}
                                    </GistButton>
                                    <GistButton
                                        className='button'
                                        onClick={() => this.AttachGist()}
                                        isDark={this.state.color_scheme === 'Dark' ? true : false}
                                    >
                                        Add File
                                    </GistButton>
                                    <input
                                        type='file'
                                        accept='.txt,.js,.jsx,.py,.java,.xml,.json,.scss,.css,.ts,.tsx'
                                        hidden
                                        ref={this.state.fileRef}
                                        onChange={this.attachFile}
                                    />
                                </>
                            )}

                            {currentGist !== 'new' && <></>}
                        </React.Fragment>
                    }
                />

                {this.props.external_token && currentGist === 'new' ? (
                    <InputContainer
                        placeholder='Gist description...'
                        onChange={(e) => this.setState({ newGistName: e.target.value })}
                        value={this.state.newGistName}
                        isDark={this.state.color_scheme === 'Dark' ? true : false}
						            className="gist-desc"
                    />
                ) : null}

                <GistEditor
                    gistText={gistText}
                    syntax={syntax}
                    theme={theme}
                    UpdateSelf={this.props.actions.UpdateSelf}
                    isNew={currentGist === 'new' ? true : false} 
                />
            </WidgetContainer>
        )
    }
}

const GistLink = styled.a`
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    @media (max-width: 768px) {
        display: none;
    }
`

const GistButton = styled.div`
    width: 103px;
    height: 38px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 5px;
    &:hover {
        background-color: ${(props) => props.theme.hoverSecondary};
        color: ${(props) => props.theme.textPrimaryInvert};
    }
`

const GistButtonDiscard = styled.div`
    width: 103px;
    height: 38px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 5px;
    color: ${(props) => props.theme.betterColors.red} !important;
    &:hover {
        background-color: ${(props) => props.theme.betterColors.red};
        color: ${(props) => props.theme.textPrimaryInvert} !important;
    }
`

const InputContainer = styled.input`
    margin: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 10px;
    border: none;
`
