import React from 'react'

export const CrownIcon = () => {
    return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 12 12" xmlSpace="preserve" className="svg-fill-primary">
        <path fill="currentColor" d="M11.1,2.6c-0.5,0-0.9,0.4-0.9,0.9c0,0.1,0,0.3,0.1,0.4L8.9,4.7C8.6,4.9,8.3,4.8,8.1,4.5L6.6,1.8
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.5-0.4-0.9-0.9-0.9S5.1,0.6,5.1,1.1c0,0.3,0.1,0.5,0.3,0.7L3.9,4.5C3.7,4.8,3.4,4.9,3.1,4.7L1.7,3.9
        c0.1-0.1,0.1-0.2,0.1-0.4c0-0.5-0.4-0.9-0.9-0.9S0,3,0,3.5s0.4,0.9,0.9,0.9H1l1.3,3.4C2.4,7.9,2.5,8,2.6,8h6.7
        c0.1,0,0.3-0.1,0.3-0.2L11,4.4h0.1C11.6,4.4,12,4,12,3.5S11.6,2.6,11.1,2.6z M9.1,10.1c-0.1,0.2-0.3,0.3-0.5,0.3
        C7.8,10.1,6.9,10,6,10c-0.9,0-1.8,0.1-2.7,0.4c-0.2,0.1-0.4-0.1-0.5-0.3L2.5,8.8C2.5,8.6,2.6,8.4,2.8,8.4H6h3.2
        c0.2,0,0.4,0.2,0.3,0.4L9.1,10.1z"/>
        </svg>

}