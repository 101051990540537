import React from 'react'
import styled from 'styled-components'

interface Props {
  file: any
  src: string
}

const images = ['png', 'svg', 'jpg', 'jpeg', 'jfif', 'gif', 'webp']

const MainFile = (props: Props) => {
  const { src, file: { file, resp } } = props

  const toDisplay = () => {
    const filename = file.name
    const match = filename.match(/\.(\w+)$/)

    if(match?.[1] && images.includes(match[1].toLowerCase())) {
      return (
        <Image src={src} />
      )
    }

    return (
      <Frame src={src} />
    )
  }

  return (
    <Container>
      {
        !!file && toDisplay()
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex !important;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
  height: calc(100% - 72px);
  max-width: 100%;
  padding: 20px;
`

const Frame = styled.iframe`
	flex: 1;
	width: 100%;
  height: 100%;
`

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`

export default MainFile