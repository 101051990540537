import React, { Component } from 'react'
import styled from 'styled-components'

type Props = {
    handleClose: (data: null, e: any) => void
    handleSubmit: (e: any) => void
}

function DeleteNotes({ handleClose, handleSubmit }: Props) {
  return (
    <MainCont className="topbar rounded inner">
        <div className="warning-wrapper">
            <WarningHeader>
                Remove note?
            </WarningHeader>
            <WarningMessage>
                Are you sure you want to remove this note?
            </WarningMessage>
        </div>

        <ButtonCont>
            <CancelBtn className="button default" onClick={(e) => handleClose(null, e)}>
                Cancel
            </CancelBtn>
            <DeleteBtn
                className="button danger"
                onClick={handleSubmit}
            >
                Remove
            </DeleteBtn>
        </ButtonCont>
    </MainCont>
  )
}

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    .warning-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const WarningHeader = styled.div`
    font-weight: 500;
    font-size: 16px;
`

const WarningMessage = styled.span`
    font-weight: 400;
    font-size: 13px;
    padding-top: 10px;
`
const ButtonCont = styled.div` 
    display: flex;
    width: 70%;
`
const DeleteBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 14px;
    margin: 4px;
`
const CancelBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 4px;
`

export default DeleteNotes
