import React, { Component } from 'react'
import styled from 'styled-components'

const jsondiffpatch = require('jsondiffpatch').create()
import { generateNewCheckpoint } from '../../helpers/TimeTravel'
import TopBar from '../../screens/Meeting2.0/Widgets/TopBar'
import WidgetRenderer from '../../loop-widgets/WidgetRenderer'
import AllWidgets from '../../loop-widgets'
import jwtDecode from 'jwt-decode'
import Encryption from '../../helpers/Encryption'

export default class StaticWidgetSimulator extends Component {
	constructor(props) {
		super(props)

		this.decoded = jwtDecode(localStorage.token).data._id
		this.cached_record = {}
		this.state = {
			current_data: {},
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.record !== this.cached_record && nextProps.record) {
			if (
				!this.state.loading &&
				nextProps.record.name === (this.cached_record || {}).name &&
				Math.abs(nextProps.record.version - this.cached_record.version) === 1
			) {
				const current_data = { ...this.state.current_data }
				if (nextProps.record.version - this.cached_record.version === 1) {
					if (nextProps.record.delta) {
						jsondiffpatch.patch(current_data, nextProps.record.delta)
					} else if (nextProps.record.deltas) {
						nextProps.record.deltas.forEach(d => {
							jsondiffpatch.patch(current_data, d)
						})
					}
				} else {
					jsondiffpatch.unpatch(current_data, this.cached_record.delta)
				}

				this.setState({
					current_data,
				})
			} else {
				this.setState({ loading: true })
				this.buildWidget(nextProps.record)
			}

			this.cached_record = nextProps.record
		}
	}

	UNSAFE_componentWillMount() {
		if (this.props.record) {
			this.cached_record = this.props.record
			this.setState({ loading: true })
			this.buildWidget(this.props.record)
		}
	}

	async buildWidget(passed_record) {
		try {
			const record = passed_record || this.props.record
			const current_data = await generateNewCheckpoint(
				record.name,
				record.timestamp
			)
			this.setState({ current_data, loading: false })
		} catch (e) {
			console.error(e)
		}
	}

	render() {
		if (this.state.loading && !this.state.current_data) {
			return null
		}

		const users = Array.isArray(this.props.users)
			? this.props.users
			: Object.values(this.props.users).map(u => ({
					type: u.user_type,
					name: u.name,
					status: 'online',
					avatar_url: u.avatar_url,
					id: u.user_id,
			  }))

		if (this.state.loading) {
			return <div />
		}

		let widget_data = {}
		widget_data[this.cached_record.name] = this.state.current_data

		if (
			sessionStorage &&
			sessionStorage.ghost_hash &&
			sessionStorage.ghost_meeting &&
			sessionStorage.ghost_meeting === window.location.pathname.split('/')[2]
		) {
			widget_data = Encryption.decrypt(widget_data)
		}
		const widget_render = AllWidgets[this.cached_record.name]
		if (!widget_render) {
			return null
		}
		const empty_func = () => {}
		return (
			<div>
				<WidgetContainer inactive={!this.props.record}>
					<TopBar
						icon={widget_render.widgetConfig.icon}
						renderedRef={el => (this.top = el)}
						nameOverride={widget_data._displayName}
						displayName={widget_render.widgetConfig.displayName}
						name={this.cached_record.name}
						minimizeWidget={empty_func}
						expandWidget={empty_func}
					/>
					<WidgetRenderer
						stateKey={this.cached_record.name}
						RenderedWidget={widget_render}
						actions={{
							UpdateWidget: empty_func,
						}}
						users={users}
						widgets={widget_data}
						meetingName={window.location.pathname.split('/')[2]}
						transcription={[]}
						external_token={null}
						userId={this.decoded}
					/>
				</WidgetContainer>
			</div>
		)
	}
}

const WidgetContainer = styled.div`
	position: relative;
	width: auto;
	width: 100%;
	height: ${props => props.theme.blockHeight}px;
	background-color: white;
	flex-grow: 1;
	margin-right: 14px;
	margin-bottom: 14px;
	display: flex;
	flex-direction: column;
	box-shadow: ${props => props.theme.shadows.newlight};

	${props => props.inactive && `opacity: .5;`};
`
