import React from "react";
import Reflux from "reflux";
import styled from "styled-components";
// import Color from 'color'

import Modal from "../../../src/components/Modalv2";
import LoopApi from '../../helpers/LoopApi'
// import { defaultColors } from '../../../src/stores/ThemingStore'

interface Props {
  folder: any,
  folders: any,
  closeModal: Function,
  meetingName: string
  UpdateSelf: Function
  checkDataFiles: Function
}

class ModalFolderRename extends Reflux.Component {
  inputRef: any

  constructor(props: Props) {
    super(props);

    // refs
    this.inputRef = React.createRef()

    // binds
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      newFolderName: this.props.folder.name,
      isLoading: false
    };
  }

  componentDidMount() {
    this.inputRef.focus()
    this.inputRef.select()
  }

  async handleSubmit(e: any) {

    e.preventDefault()
    const { folder, folders, checkDataFiles } = this.props
    let folderName = this.state.newFolderName.trim()
    console.log({ folder, folders })
    if (folderName) {

      // Time Travel patch
      const filesObj = checkDataFiles()

      const updatedFolders = { 
        ...folders,
        [folder.uuid]: {
          ...folders[folder.uuid],
          name: folderName
        }
      }
  
      this.props.UpdateSelf({ 
        folders: updatedFolders,
        ...(filesObj ? { files: filesObj } : {})  // Time Travel patch 
      })
      this.props.closeModal()
    }

  }

  render() {
    return (
      <Modal {...this.props}>
        <Container>
          <div className="name">Rename Folder</div>
          <form onSubmit={this.handleSubmit}>
            <input
              className="hover-container border-lightimp"
              type="text"
              value={this.state.newFolderName}
              onChange={(e) =>
                this.setState({
                  newFolderName: e.target.value,
                })
              }
              ref={ref => this.inputRef = ref}
            ></input>
            <div className="actions-wrapper">
              <div className="button default" onClick={() => this.props.closeModal()}>Cancel</div>
              <div className="button primary" onClick={(e) => this.handleSubmit(e)}>Save</div>
            </div>
          </form>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;

  & > div.name {
    padding: 8px;
  }

  & > div.ext {
    font-weight: 500;
  }

  & > form > input {
    text-align: center;
    background: none;
    border-radius: 999px;
    border: 1px solid;
    padding: 10px;
    margin-top: 15px;
  }

  & div.actions-wrapper {
    justify-content: center;
    margin-top: 15px;
    display: flex;
    align-items: center;

    > div {
      cursor: pointer;
      min-width: 68px;
      padding: 8px 20px;
    }

    > div:first-child {
      margin-right: 5px;
    }
  }
`;

export default ModalFolderRename;
