import React, { Component } from 'react'
import styled from 'styled-components'
import { FaSpinner as LoadingIcon } from '@react-icons/all-files/fa/FaSpinner'
import { Scrollbars } from 'react-custom-scrollbars';

import ImageGalleryItem from './ImageGalleryItem'


interface Props {
	images: any[]
	imgClicked: (a: any) => void
	triggerFileInputClick: () => void
	currentImage: any
	isUploading: boolean
	toggleModal: (a: boolean, b: string) => void
	travelling: boolean | undefined
}

export default class ImageGallery extends Component<Props> {
	scroller: any
	constructor(props: Props) {
		super(props)

		this.scroller = React.createRef()
	}

	scrollToBottom() {
		this.scroller?.current?.scrollToBottom()
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.images.length !== this.props.images.length) {
			this.scrollToBottom()
		}
	}

	componentDidMount() {
		this.scrollToBottom()
	}

	render() {
		const len = this.props.images.length
		const { currentImage, travelling } = this.props
		const hasCurrentImage = currentImage && currentImage.url

		const imgs = this.props.images.map((im, ii) => (
			<ImageGalleryItem
				image={im}
				key={ii}
				imgClicked={() => this.props.imgClicked(im)}
				lazy={len - ii >= 6}
				isLoading={false}
				toggleModal={this.props.toggleModal}
				travelling={travelling}
			/>
		))
		return (
			<React.Fragment>
				<Container 
					autoHide
					autoHideTimeout={1000}
					autoHideDuration={200}
					{...{ innerRef: (r: any) => (this.scroller = r) } as any}
				>
					<Internal hasCurrentImage={hasCurrentImage}>{imgs}</Internal>
				</Container>
				{
					hasCurrentImage && (
						<UploadPhotoBtn
							className="button primary"
							onClick={this.props.triggerFileInputClick}
							disabled={this.props.isUploading}
							travelling={travelling}
						>
							{
								this.props.isUploading ? (
									<div><LoadingIcon></LoadingIcon></div>
									) : (
										'Upload Photo'
										)
									}
						</UploadPhotoBtn>
					)
				}
			</React.Fragment>
		)
	}
}

const Container = styled(Scrollbars)`
	position: relative;
	width: 155px !important;
	
	@media (max-width: 768px) {
		display: flex;
		width: calc(100% - 155px) !important;
		height:160px !important;
		max-height: 160px !important;
		margin-left: 155px;
	}

	@media (max-width: 480px) {
		height: 120px !important;
		max-height: 120px !important;
		margin-left: 115px;
		width: calc(100% - 115px) !important;
	}

	
`

const Internal = styled.div<{ hasCurrentImage: boolean }>`
	display: flex;
	flex-direction: column;
	/* padding-bottom: ${props => props.hasCurrentImage ? '10px' : '0' }; */
	height: calc(100% - 60px);

	@media (max-width: 768px) {
		flex: 1;
		padding: 0;
		flex-direction: row;
		align-items: center;
		height: 100%;
	}
`

const UploadPhotoBtn = styled.button<{travelling: boolean | undefined}>`
	/* background-color: #3E83F7; */
	position: absolute;
	color: white;
	height: 40px;
	width: 140px;
	outline: 0;
	border: none;
	/* position: -webkit-sticky; */
	/* position: sticky; */
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
	bottom: 10px;
	cursor: pointer;
	${({travelling}) => travelling ? 'display: none' : ''};

	&:disabled {
		cursor: not-allowed;
		background-color: rgba(62, 131, 247, 0.8);
	}

	div {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}

	@media (max-width: 768px) {
		height: 140px;
		min-width: 140px;
		min-height: 140px;
		border-radius: 10px !important;
		left: 0;
		position: absolute;
		top: 10px;
	}

	@media (max-width: 480px) {
		height: 100px;
		width: 100px;
		min-width: 100px;
		min-height: 100px;
	}
`
