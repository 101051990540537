import React, { FormEvent, MouseEvent } from 'react'
import Reflux from 'reflux'
import cloneDeep from 'lodash.clonedeep'


import Sidebar from './Sidebar'
import NotesContent from './NotesContent'
import { NotesWrapper } from './styledcomponents'

import LoopApi from '../../helpers/LoopApi'
import { VariableCallingStore } from '../../stores/VariableCallingStore'
import { ThemingStore } from '../../stores/ThemingStore'
import { IWidgetProps, AllWidgets } from '../types'
import moment from 'moment'
import Modal from '../_Shared/Modal'
import PrivatePublicModal from '../Notes/PrivatePublicModal'
import DeleteNotes from '../Notes/DeleteNotes'


type Props = IWidgetProps<AllWidgets.Notes>

interface State {
    activeNote: null | string
    createModalOpen: boolean
    deleteModalOpen: boolean
    activePrivateNote: any
    deleteId: string | null
}

export class NotesSync extends Reflux.Component<typeof VariableCallingStore | typeof ThemingStore, Props, State> {
    updatingTimeout: any
    constructor(props: Props) {
        super(props)

        this.state = {
            activeNote: null,
            createModalOpen: false,
            deleteModalOpen: false,
            deleteId: null,
            activePrivateNote: {},
        }

        this.updatingTimeout = null

        this.changeActiveNote = this.changeActiveNote.bind(this)
        this.updateTitle = this.updateTitle.bind(this)
        this.updateContent = this.updateContent.bind(this)
        this.updateUpdating = this.updateUpdating.bind(this)
        this.deleteNote = this.deleteNote.bind(this)
        this.createNote = this.createNote.bind(this)
        this.sendContentUpdate = this.sendContentUpdate.bind(this)
        this.openDeleteModal = this.openDeleteModal.bind(this)
    }

    changeActiveNote(activeNote: string | null) {
        if(this.state.activeNote === activeNote) return 
        this.setState({ activeNote, activePrivateNote: {} })
    }

    updateTitle(e: FormEvent<HTMLTextAreaElement> | string, isPrivate?: boolean, fromBlur?: boolean) {
        let value = ''
        if(typeof e !== 'string') {
            const { value: v } = e.target as HTMLInputElement
            value = v
        } else {
            value = e
        }

        const { activeNote } = this.state
        const date_updated = moment().format()
        let notes = cloneDeep(this.props.data?.notes)
        let currentNote = notes?.find(n => n?.id === activeNote)

        if(isPrivate && !!!fromBlur) {
            if(currentNote) {
                currentNote.title = value
                currentNote.date_updated = date_updated
                currentNote.updated_by = this.props.userId || null

                this.setState({ activePrivateNote: currentNote })
            }
        }
        
        if((isPrivate && !!fromBlur) || !isPrivate) {
            let otherNotes = notes?.filter(n => n?.id !== activeNote)
            if(currentNote) {
                currentNote.title = value
                currentNote.date_updated = date_updated
                currentNote.updated_by = this.props.userId || null
    
                notes = [
                    currentNote,
                    ...otherNotes
                ]
    
                this.props.actions.UpdateSelf({ notes })
                // this.setState({ activeNote })
            }
        }
    }

    updateContent(content: string, pureText: string, isPrivate: boolean, fromBlur: boolean, fromUnmount?: boolean) {
        if(isPrivate && !fromBlur) {
            const { activeNote } = this.state
            const date_updated = moment().format()
    
            let notes = cloneDeep(this.props.data?.notes)
            let currentNote = notes?.find(n => n?.id === activeNote)
            if(currentNote) {
                currentNote.content = content
                currentNote.pureText = pureText
                currentNote.date_updated = date_updated
                currentNote.updated_by = this.props.userId || null
                currentNote.updating = this.props.userId

                //console.log("currentNote: ",  currentNote)
                this.setState({ activeNote, activePrivateNote: currentNote })
            }
            return
        }

        if(isPrivate && fromBlur) {
            clearTimeout(this.updatingTimeout);
            this.updatingTimeout = setTimeout(() => this.sendContentUpdate(content, pureText), 1500);
            return this.setState({ activePrivateNote: {} })
        }

        if(fromUnmount) {
            this.sendContentUpdate(content, pureText)
            return this.setState({ activePrivateNote: {} })
        }

        clearTimeout(this.updatingTimeout);
        this.updatingTimeout = setTimeout(() => this.sendContentUpdate(content, pureText), 1000);
    }

    sendContentUpdate(content: string, pureText: string) {
        const { activeNote } = this.state
        const date_updated = moment().format()

        let notes = cloneDeep(this.props.data?.notes)
        let currentNote = notes?.find(n => n?.id === activeNote)
        let otherNotes = notes?.filter(n => n?.id !== activeNote)

        if(currentNote) {
            currentNote.content = content
            currentNote.pureText = pureText
            currentNote.date_updated = date_updated
            currentNote.updated_by = this.props.userId || null
            currentNote.updating = this.props.userId

            notes = [
                currentNote,
                ...otherNotes
            ]
            //console.log("REMOVED KEY: ",  notes)
            this.props.actions.UpdateSelf({ notes })
            this.setState({ activeNote })
        }
    }

    updateUpdating() {
        const { activeNote } = this.state

        let notes = cloneDeep(this.props.data?.notes)
        let currentNote = notes?.find(n => n?.id === activeNote)
        let otherNotes = notes?.filter(n => n?.id !== activeNote)

        if(currentNote) {
            currentNote.updating = null

            notes = [
                currentNote,
                ...otherNotes
            ]

            this.props.actions.UpdateSelf({ notes })
            this.setState({ activeNote })
        }
    }

    openDeleteModal(deleteId: string | null, e: any) {
        if(e &&  e.stopPropagation) {
            e.stopPropagation()
        }

        this.setState((prevState) => ({ deleteModalOpen: !prevState.deleteModalOpen, deleteId }))
    }

    deleteNote(id: string | null, e: MouseEvent<SVGElement>) {
        e.stopPropagation()
        const { activeNote } = this.state
        let active = activeNote

        let notes = cloneDeep(this.props.data?.notes)

        if(id) {
            notes = notes?.filter(n => n?.id !== id)
        }

        
        if(activeNote === id) {
            let yournotes = notes.filter(n => (!!(n?.private || n?.isPrivate) && n?.created_by === this.props.userId) || !!!(n?.private || n?.isPrivate))
            let currentIndex = yournotes.findIndex(n => n?.id === id)
            let activeData = yournotes.length === currentIndex + 1 ? yournotes[currentIndex - 1] : yournotes[currentIndex + 1]

            if(!!!notes?.length) {
                // this.changeActiveNote(null)
                active = null
            } else {
                // this.changeActiveNote(activeData?.id)
                active = activeData?.id
            }
        }
        // debugger

        this.props.actions.UpdateSelf({ notes })
        this.setState({ deleteId: null, deleteModalOpen: false, activeNote: active })
    }

    createNote(privateValue: boolean) {
        const id = `${moment().format("MMDDYYYYhhmmssA")}-${Math.floor((Math.random() * 100000000) + 1)}`

        const data = cloneDeep(this.props.data?.notes)
        let notes = [...data]

        notes = [
            {
                id,
                title: '',
                pureText: '',
                content: ' ',
                date_created: moment().format(),
                created_by: this.props.userId || null,
                date_updated: moment().format(),
                updated_by: this.props.userId || null,
                isPrivate: privateValue
            },
            ...data
        ]

        this.props.actions.UpdateSelf({ notes })
        const d: any = {}
        if(privateValue) {
            d.activePrivateNote = id
            d.activeNote = id
        } else {
            d.activeNote = id
        }
        this.setState({ createModalOpen: false, ...d })
    }

    componentDidMount() {
        let notes = cloneDeep(this.props?.data?.notes || [])
        notes = notes.filter(n => (!!(n?.private || n?.isPrivate) && n?.created_by === this.props.userId) || !!!(n?.private || n?.isPrivate))
        const activeNote: string = notes?.[0]?.id || ''
        this.setState({ activeNote })
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if(JSON.stringify(prevProps?.data?.notes) !== JSON.stringify(this.props?.data?.notes)) {
            let notes = cloneDeep(this.props?.data?.notes || [])
            notes = notes.filter(n => (!!(n?.private || n?.isPrivate) && n?.created_by === this.props.userId) || !!!(n?.private || n?.isPrivate))
            
            if(!!!notes?.find(n => n?.id === this.state.activeNote) && notes?.length) {
                const activeNote: string = notes?.[0]?.id || ''
                this.setState({ activeNote })
            } 

            if(!!!notes?.find(n => n?.id === this.state.activeNote) && !!!notes?.length) {
                this.setState({ activeNote: null })
            }
            this.setState({})
        }
    }

    render() {
        const { data } = this.props
        const { activeNote, createModalOpen, deleteModalOpen, activePrivateNote } = this.state

        let notes = cloneDeep(data?.notes || [])
        notes = notes.filter(n => (!!(n?.private || n?.isPrivate) && n?.created_by === this.props.userId) || !!!(n?.private || n?.isPrivate))


        return (
            <NotesWrapper>
                <Modal show={createModalOpen} overlayClicked={() => this.setState({ createModalOpen: false })}>
                    <PrivatePublicModal submit={this.createNote} />
                </Modal>
                <Sidebar 
                    activeNote={activeNote}
                    activePrivateNote={activePrivateNote}
                    notes={notes || []}
                    changeActiveNote={this.changeActiveNote}
                    deleteNote={this.openDeleteModal}
                    createNote={() => this.setState({ createModalOpen: true })}
                />
                <NotesContent 
                    note={(notes || [])?.find(n => n?.id === activeNote || '')}
                    numberOfNotes={(notes || [])?.length}
                    activeNote={activeNote}
                    updateTitle={this.updateTitle}
                    updateContent={this.updateContent}
                    updateUpdating={this.updateUpdating}
                    users={this.props?.users}
                    userId={this.props?.userId}
                    activePrivateNote={activePrivateNote}
                />
                <Modal show={deleteModalOpen} overlayClicked={(e: any) => this.openDeleteModal(null, e)}>
                    <DeleteNotes
                        {...this.props}
                        handleClose={(e: any) => this.openDeleteModal(null, e)}
                        handleSubmit={(e: any) => { 
                            this.deleteNote(this.state.deleteId, e)
                        }}
                    />
                </Modal>
            </NotesWrapper>
        )
    }
}

export default NotesSync
