// Modules
import React, { Fragment } from "react";
import Reflux from 'reflux'

// Icons
import { FiFolderPlus } from "@react-icons/all-files/fi/FiFolderPlus";
import { FiFilePlus } from "@react-icons/all-files/fi/FiFilePlus";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";
import { BsArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft";
import { RiFolderTransferLine as MoveFileIcon } from "@react-icons/all-files/ri/RiFolderTransferLine";
import { FiMenu as ListIcon } from "@react-icons/all-files/fi/FiMenu"
import { FiGrid as GridIcon } from "@react-icons/all-files/fi/FiGrid"
import { FaFolder as FolderIcon } from '@react-icons/all-files/fa/FaFolder'
import { SiSlideshare } from 'react-icons/si'

// Components
import Directory, { BackButton, Wrapper } from "./Directory";
import Folders from "./Folders";
import FileList from "./FileList";
import ItemActions from "./ItemActions";
import ModalNewFolder from "./ModalNewFolder";
import ModalFolderList from "./ModalFolderList";
import ModalFolderRename from "./ModalFolderRename";
import ModalFileRename from "./ModalFileRename";
import ModalRemove from "./ModalRemove";
import File from "./File";
import QuickAccess from "./QuickAccess"
import GoogleDrive from './GoogleDrive'
import GoogleDriveFile from './GoogleDriveFile'
import FileHeader from './FileHeader'
import SyncModal from '../_Shared/SyncModal'
import Urls from './Urls'

// Styled Components
import {
  ContainerFileDrop,
  FileWrapper,
  Ico,
  NameHeader,
  QuickAccessWrapper,
  TopControls,
  UploadIndicator,
  SearchContainer,
  SearchInput,
  Menus,
  SubmenusWrapper,
  SubmenuLabel,
  QuickAccessItemsWrapper,
  Present,
  Content,
  Main,
  SubContent,
  Heading,
  MainList
} from "./StyledComponents";

// Types
import { IWidgetProps, AllWidgets } from "../types";
import {
  IFile,
  IFilesMap,
  IFilesWidgetState,
  IFolder,
  KeyboardKeys,
} from "./types";

// Styles
import "./style.css";

// Helpers
import { convertArrayToObject, getLinks } from "./helpers";
import LoopApi from "../../helpers/LoopApi";

// Stores
import { ThemingStore } from '../../stores/ThemingStore'
import { MainStore, WidgetActions } from '../../stores/MainStore'
const { GlobalState } = require('reflux')

// Icons
import IconPDF from "../_Shared/file-icons/pdf.svg"
import IconImages from "../_Shared/file-icons/images.svg"
import IconDocs from "../_Shared/file-icons/docs.svg"
import IconMusic from "../_Shared/file-icons/music.svg"
import IconZip from "../_Shared/file-icons/zip.svg"
import WidgetHeader from "../_Shared/WidgetHeader";
import OtherSyncs from "./OtherSyncs";
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai'
import GDrive from '../GoogleDrive/icon.svg'

export type FilesWidgetProps = IWidgetProps<AllWidgets.Files>;

const initialState: IFilesWidgetState = {
  modals: {
    renameFile: false,
    renameFolder: false,
    fileList: false,
    createFolder: false,
    delete: false
  },
  selectedItems: [],
  isUploading: false,
  keyboardKeys: {
    [KeyboardKeys.ALT]: false,
    [KeyboardKeys.CTRL]: false,
    [KeyboardKeys.META]: false,
    [KeyboardKeys.SHIFT]: false,
  },
  rightClickedItem: null,
  isFromRightClick: false,
  quickAccessFilter: null,
  viewType: "list",
  currentFileId: null,
  currentFile: null,
  currentFolderId: null,
  searchFilter: '',
  drive: 'default',
  externalFile: null,
  color_scheme: GlobalState.theming.color_scheme,
  presentation: {
    files: null
  },
  lastView: {
    files: null
  },
  modal: {
    show: false,
    type: ''
  }
};

export default class Files extends Reflux.Component<typeof ThemingStore | typeof MainStore, FilesWidgetProps, IFilesWidgetState> {
  fileInputRef: any;
  folderdivRef: any;
  filedivRef: any;
  mainListRef: any;

  constructor(props: FilesWidgetProps) {
    super(props);

    // binds
    this.closeModals = this.closeModals.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.deleteItems = this.deleteItems.bind(this);

    // refs
    this.fileInputRef = React.createRef();
    this.folderdivRef = React.createRef();
    this.filedivRef = React.createRef();
    this.mainListRef = React.createRef();

    // state
    this.state = { ...initialState };

    this.stores = [ThemingStore, MainStore]
		this.storeKeys = [
			'color_scheme',
			'presentation',
			'lastView'
		]
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDownHandler);
    document.addEventListener("keyup", this.onKeyUpHandler);
    document.addEventListener("click", this.handleOutsideClick);
    this.restoreLastView()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDownHandler);
    document.removeEventListener("keyup", this.onKeyUpHandler);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  componentDidUpdate() {
  }

  onKeyDownHandler = (event: KeyboardEvent) => {
    if (this.state.drive === 'default') {
      if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
        this.setState({
          keyboardKeys: {
            [KeyboardKeys.ALT]: event.altKey,
            [KeyboardKeys.CTRL]: event.ctrlKey,
            [KeyboardKeys.META]: event.metaKey,
            [KeyboardKeys.SHIFT]: event.shiftKey,
          },
        });
      }
    }
  };

  onKeyUpHandler = (event: KeyboardEvent) => {
    if (this.state.drive === 'default') {
      const keyboardMap: any = {
        Alt: "altKey",
        Control: "ctrlKey",
        Meta: "metaKey",
        Shift: "shiftKey",
      };

      this.setState((prevState) => ({
        ...prevState,
        keyboardKeys: {
          ...prevState.keyboardKeys,
          [keyboardMap[event.key]]: false,
        },
      }));
    }
  };

  changeCurrentFile = (file: any) => {
    this.setState({ currentFile: file })
  }

  handleOutsideClick = (event: MouseEvent) => {
    if (this.state.drive === 'default') {
      if (!!!this.state.currentFileId &&
        (this.folderdivRef && this.folderdivRef?.contains && this.folderdivRef?.contains(event.target)) ||
        (this.filedivRef && this.filedivRef?.contains && this.filedivRef?.contains(event.target))) {
        return;
      }

      console.log("OUTSIDE CLICK: ", this.state.modals.fileList);
      if (!this.state.modals.fileList && !this.state.modals.delete) {
        this.removeSelectedItems();
      }
    }
  };

  removeSelectedItems = () => {
    console.log("REMOVING SELECTED ITEMS");
    this.setState((prevState) => ({
      ...prevState,
      selectedItems: [],
    }));
  };

  async deleteFile(fileId: string) {
    const { UpdateSelf } = this.props.actions;
    try {
      const res = await LoopApi(null, "DeleteFileFromMeeting", {}, [
        ["name", this.props.meetingName],
        ["file_id", fileId],
      ]);

      const filesObj = await this.getFiles()
      UpdateSelf({ files: filesObj });

    } catch (err) {
      console.error(err);
    }
  }

  debug(data: any) {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }

  closeModals() {
    this.setState({ modals: { ...initialState.modals } });
  }

  async handleUpload(e: any) {
    const { currentFolderId, childFiles, folders } = this.props.data;
    const { UpdateSelf } = this.props.actions;

    this.uploadFiles(Array.from(e.target.files))
  }

  async uploadFiles(_files: any[]) {
    const { childFiles, folders } = this.props.data;
    const { currentFolderId } = this.state
    const { UpdateSelf } = this.props.actions;

    try {
      const files = Array.from(_files);

      const upload = (file: any) => {
        const data = new FormData();
        data.append("file", file);
        data.append('targetWidget', 'files')
        data.append('size', `${file.size}`)
        return LoopApi(null, "UploadFile", {}, undefined, data);
      };

      if (files.length < 1) {
        return;
      }

      // Start Upload
      this.setState({ isUploading: true });

      const response = []
      for (const file of files) {
        let r = await upload(file)
        response.push(r)
      }

      const filesObj = await this.getFiles()

      // Move to current folder
      if (currentFolderId) {
        const newFileIds = response.map((file: any) => file._id);
        const updatedChildFiles = { ...childFiles };

        response.forEach((file: any) => {
          updatedChildFiles[file._id] = currentFolderId;
        });

        UpdateSelf({
          childFiles: {
            ...updatedChildFiles,
          },
          folders: {
            ...folders,
            [currentFolderId]: {
              ...folders[currentFolderId],
              files: [...folders[currentFolderId].files, ...newFileIds],
            },
          },
          files: filesObj
        });
      } else {
        UpdateSelf({ files: filesObj });
      }

    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isUploading: false });
    }
  }

  _filesArrayToObjects = (filesArray: IFile[]) => {
    return filesArray.reduce((acc, file) => {
      return {
        ...acc,
        [file._id]: file
      }
    }, {})
  }

  _filesObjectsToArrays = (fillesObjects: IFilesMap) => {
    return Object.values(fillesObjects)
  }

  getFiles = async() => {
    const meetingFiles = await LoopApi(null, "GetAllFileFromMeeting", {}, []);
    return meetingFiles?.length ? this._filesArrayToObjects(meetingFiles.files) :
                    this.props.files?.length ? this._filesArrayToObjects(this.props.files) : {}

  }

  deleteItems() {
    const { isFromRightClick, rightClickedItem, selectedItems } = this.state;
    const deleteMany = () => {
      const cb = () => {
        const foldersToDelete: IFolder[] = [];
        const filesToDelete: IFile[] = [];

        this.state.selectedItems.forEach((item) => {
          if ("_id" in item) {
            filesToDelete.push(item);
          } else {
            foldersToDelete.push(item);
          }
        });

        // delete folders
        const updatedFolders = { ...this.props.data.folders };

        foldersToDelete.forEach((folder) => {
          delete updatedFolders[folder.uuid];
        });

        this.props.actions.UpdateSelf({ folders: updatedFolders });

        // delete files
        const promises = filesToDelete.map((item) => {
          this.deleteFile(item._id);
        });

        Promise.all(promises);
      };
      this.setState(
        (prevState) => ({
          ...prevState,
          isFromRightClick: false,
        }),
        cb
      );
    };

    if (isFromRightClick) {
      // is right clicked file part of selected ?
      if (!rightClickedItem) {
        return;
      }

      let isPartOfSelected = false;

      if ("_id" in rightClickedItem) {
        isPartOfSelected = selectedItems
          .map((item: IFile) => item._id)
          .includes(rightClickedItem._id);
      } else {
        isPartOfSelected = selectedItems
          .map((item: IFolder) => item.uuid)
          .includes(rightClickedItem.uuid);
      }

      if (!isPartOfSelected) {
        if ("_id" in rightClickedItem) {
          // delete one file
          console.log("DELETING ONE FILE");
          this.deleteFile(rightClickedItem._id);
        } else {
          // delete one folder
          console.log("DELETING ONE FOLDER");
          const updatedFolders = { ...this.props.data.folders };
          delete updatedFolders[rightClickedItem.uuid];

          this.props.actions.UpdateSelf({ folders: updatedFolders });
        }
      } else {
        console.log("DELETING MANY");
        deleteMany();
      }
    } else {
      console.log("DELETING MANY");
      deleteMany();
    }
  }

  // Time Travel Initial Patch
  checkDataFiles = () => {
    let filesObj = null
    if(
      (!this.props.data.files || (this.props.data.files && !Object.keys(this.props.data.files).length)) &&
      this.props.files?.length
    ) {
      filesObj = this._filesArrayToObjects(this.props.files)
    }
    return filesObj
  }

  checkStatusPresenter = () => {
		const presenter = this.props.data.userId &&
			this.props.users?.find(user => user.id === this.props.data.userId && user.status === 'online')
		return !!presenter
	}

	onPresent = () => {
    if(this.state.currentFileId) {
        let f = this.state.currentFile
        let currentFile = f ? f : null;

        if(currentFile) {
            this.props.actions.UpdateSelf({
                file: currentFile,
                userId: this.props.userId,
            })
            this.changePresentationState('presenting')
        } else {
            const files = this._filesObjectsToArrays(this.props.files as any)
            let f = files.find((f: IFile) => f._id === this.state.currentFileId);
            let currentFile = f ? f : null;
            this.props.actions.UpdateSelf({
                file: currentFile,
                userId: this.props.userId,
            })
            this.changePresentationState('presenting')
        }
    }
	}

  stopPresent = async () => {
		if(this.checkStatusPresenter() && this.props.data.file) {

			this.props.actions.UpdateSelf({
				file: null,
				userId: null,
			})

			this.changePresentationState(null)
		}
	}

  changePresentationState = (val: any) => {
		WidgetActions.SetPresentation({
			sync: 'files',
			val
		})
	}

  changeIndexState = (state: any, callback?: Function) => {
		this.setState(state, () => callback ? callback() : {})
  }

	startViewing = () => {
		this.changePresentationState('viewing')
	}

	stopViewing = () => {
		this.changePresentationState(null)
	}

  setModalType = () => {
		let actionModal = () => {}
		let closeModal = () => this.setState({modal: {show: false, type: ''}})
		let typeModal = this.state.modal.type
		switch(this.state.modal.type) {
			case 'FilesStopPresentation':
				actionModal = () => {this.stopPresent()}
			break
			case 'FilesStopViewingPresentation':
				actionModal = () => {this.stopViewing()}
			break
			default:
				typeModal = ''
		}

		return !!typeModal ? <SyncModal
			actionModal={actionModal}
			closeModal={closeModal}
			type={typeModal}
		/> : null
	}

  setLastView = (type: string) => {
    const { currentFolderId, currentFileId } = this.state
    const { files } = this.props.data

    if(type === 'folder')
    WidgetActions.SetLastView({
      sync: 'files',
      data: {
        view: 'folder',
        state: { currentFolderId }
      }
    })
    else if(type === 'file' && currentFileId && files?.[currentFileId]) {
      WidgetActions.SetLastView({
        sync: 'files',
        data: {
          view: 'file',
          state: { currentFolderId, currentFileId }
        }
      })
    }
  }

  restoreLastView = () => {
		if(this.state.lastView?.files?.view) {
			const { view, state: { currentFileId, currentFolderId } } = this.state.lastView.files
			switch(view) {
				case 'folder':
          const { folders } = this.props.data
          if((currentFolderId && folders?.[currentFolderId]) || currentFolderId === null)
          this.setState({ currentFolderId })
					break
				case 'file':
					this.setState({ currentFolderId, currentFileId})
					break
				default:
					break
			}
		}
	}

  handleback = (hierarchy: any, currentFile: any) => {
    if(hierarchy?.length > 1 && !currentFile) {
      const folder = hierarchy?.[hierarchy?.length - 2]
      this.setState({ currentFolderId: folder.uuid })
      return
    }

    if(currentFile) {
      this.setState(prevState => ({ ...prevState, currentFolderId: null, currentFileId: null }))
      return
    }

    this.setState({ currentFolderId: null })
    return
  }

  scrollToTop() {
    let scrollElement = this.mainListRef.current
    console.log('scrollElement :>> ', scrollElement);
    let scrollOptions = {
        top: '0px',
        behavior: 'smooth'
    }
    try {
      scrollElement.scroll(scrollOptions);
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    // console.log(" PROPS1: ", this.props)
    // console.log(" PROPS: ", this.props.data)
    // console.log('this.state :>> ', this.state);
    const {
      data: { folders, childFiles },
      files: _files,
      meetingName,
      actions: { UpdateSelf },
    } = this.props;
    const { currentFolderId, currentFileId } = this.state

    const {
      modals,
      selectedItems,
      isUploading,
      keyboardKeys,
      rightClickedItem,
      isFromRightClick,
      quickAccessFilter,
      drive,
      externalFile
    } = this.state;

    const isDefaultDrive = drive === 'default'
    let files = _files || [];
    if(this.props.travelling && this.props.data.files) {
      files = this._filesObjectsToArrays(this.props.data.files)
    }

    const currentFolder = currentFolderId ? folders[currentFolderId] : null;
    let currentFile: IFile | null = null;

    let folderArray = Object.entries(folders) as [string, IFolder][];

    if (this.state.searchFilter) {
      if (folderArray.length) {
        folderArray = folderArray.filter(fa => {
          return fa[1].name.toLowerCase().includes(this.state.searchFilter.toLowerCase())
        })
      }
      if (files.length) {
        files = files.filter(file => {
          return file.filename.toLowerCase().includes(this.state.searchFilter.toLowerCase())
        })
      }
    }

    if (currentFileId) {
      let f = files.find((f) => f._id === currentFileId);
      currentFile = f ? f : null;
    }

    const filesObject: IFilesMap = convertArrayToObject(files, "_id");

    // console.log({ props: this.props });

    let quickAccessFiles: IFile[] = [];
    if (quickAccessFilter) {
      if (quickAccessFilter !== "document") {
        if(quickAccessFilter === "media") {
          quickAccessFiles = files.filter((file) =>
          file.filetype.includes('audio') || file.filetype.includes('video'))
        } else {
          quickAccessFiles = files.filter((file) =>
          file.filetype.includes(quickAccessFilter))
        }
      } else {

        const docExtensions = [
          "txt",
          "doc",
          "docx",
          "ai",
          "psd",
          "htm",
          "html",
          "ppt",
          "pptx",
          "xls",
          "xlsx",
          "",
        ];
        quickAccessFiles = files.filter((file) => {

          return docExtensions.includes(
            file.filename.substr(file.filename.indexOf(".") + 1)
          );
        });
      }
    }

    let hierarchy = getLinks(currentFolder, folders);

    if(hierarchy.length && hierarchy.length > 2) {
      hierarchy = hierarchy.slice(hierarchy.length - 2)
    }

    // Externals
    let urls = []
    if(this.props.data.externals) {
      urls = Object.entries(this.props.data.externals).reduce((acc: any[], [key, value]: [key: string, value: Object]) => {
        Object.values(value || {}).map(v => {
          acc.push({
            ...v,
            external: key
          })
        })
        return acc
      }, [])
    }

    //Presentation
		const isPresenterOnline = !!this.props.data.file && this.checkStatusPresenter()
		const isPresenter = isPresenterOnline && this.props.data.userId === this.props.userId
		const viewPresenting = isPresenterOnline && !isPresenter && this.state.presentation?.files === 'viewing'
    const isPresentingOrViewing = viewPresenting || isPresenter

    if(!viewPresenting) {
      if(this.state.presentation?.files === 'viewing') {
        this.stopViewing()
      }
    }

    if ((currentFileId && isDefaultDrive) || isPresentingOrViewing) {
      return (
        <FileWrapper>
          <FileHeader
            startViewing={this.startViewing}
            onPresent={this.onPresent}
            colorScheme={this.state.color_scheme}
            isPresenterOnline={isPresenterOnline}
            isPresenter={isPresenter}
            viewPresenting={viewPresenting}
            changeIndexState={this.changeIndexState}
            hasPresentingFile={!!this.props.data.file}
            file={isPresentingOrViewing ? this.props.data.file : null}
            travelling={this.props.travelling}
            currentFile={this.state.currentFile}
            parent={{ props: this.props, state: this.state }}
          >
            <Directory
              folders={folders}
              currentFolder={currentFolder}
              currentFile={this.state.currentFile as any}
              onFolderClick={(folder) => {
                this.setState({ currentFolderId: folder.uuid, currentFileId: null }, () => this.setLastView('folder'))
              }}
              onRootFolderClick={() => {
                this.setState({ currentFolderId: null, currentFileId: null }, () => this.setLastView('folder'))
              }}
              onBackFromFileClick={() => {
                this.setState({currentFolderId: null, currentFileId: null }, () => this.setLastView('folder'))
              }}
              backVisible={true}
            />
          </FileHeader>
          <File changeCurrentFile={this.changeCurrentFile} file={isPresentingOrViewing ? this.props.data.file : currentFile as any} UpdateSelf={UpdateSelf} updateState={this.setState.bind(this)} parent={{ props: this.props, state: this.state }} />
          {this.state.modal.show && this.setModalType()}
        </FileWrapper>
      );
    }

    if (drive === 'googleDrive' && !!externalFile) {
      return (
        <GoogleDriveFile
          file={externalFile}
          back={() => {
            this.setState({ externalFile: null })
          }}
        />
      )
    }

    const folderlist = folderArray.filter((entry) => {
      const [uuid, data] = entry
      if (currentFolder) {
        return currentFolder.folders.includes(entry[0])
      } else {
        return !data.parentFolderUuid
      }
    })

    return (
      <ContainerFileDrop
        onDrop={(e: any) => {
          if (isDefaultDrive) {
            console.log("files widget", e);
            this.uploadFiles(Array.from(e))
          }
        }}
      >
        <div className="show-while-dragging">Drag Files Here</div>
        <div className="show-while-dragging-over">Drop Files</div>
        <WidgetHeader
          className="container content padding-bottom-20"
          name={
            !this.props.travelling && <NameHeader>
              <Ico>
                <img src={require("./icon.svg")} />
              </Ico>
              <span>Files</span>
            </NameHeader>
          }
          end={

            <TopControls>
              {!viewPresenting && !isPresenter && isPresenterOnline &&
                <Present className="button primary" onClick={() => this.startViewing()}>
                    <SiSlideshare size={22}/>
                    <span>View Presentation</span>
                </Present>
              }
              <SearchContainer
                className="border-light topbar"
              >
                <label htmlFor="search_file">
                  <SearchIcon />
                </label>
                <SearchInput
                  id="search_file"
                  type="search"
                  placeholder="Search file"
                  className="dropbox-search-input"
                  onChange={(e) => { this.setState({ searchFilter: e.target.value }) }}
                  value={this.state.searchFilter}
                />
              </SearchContainer>
              {!this.props.travelling && isDefaultDrive ? <ItemActions
                onClick={this.removeSelectedItems}
                actions={[
                  {
                    label: "New Folder",
                    onClick: () => {
                      this.setState((prevState) => {
                        return {
                          ...prevState,
                          modals: {
                            ...prevState.modals,
                            createFolder: true,
                          },
                        };
                      });
                    },
                    icon: <FiFolderPlus size={19} />,
                  },
                  {
                    label: "Upload Files",
                    onClick: () => this.fileInputRef.click(),
                    icon: <FiFilePlus size={19} />,
                  },
                  {
                    label: "Move",
                    onClick: () => {
                      this.setState((prevState) => ({
                        ...prevState,
                        isFromRightClick: false,
                        modals: {
                          ...prevState.modals,
                          fileList: true,
                        },
                      }));
                    },
                    icon: <MoveFileIcon size={19} />,
                    disabled: selectedItems.length === 0,
                  },
                  {
                    label: "Remove",
                    onClick: () => {
                      this.setState((prevState) => ({
                        ...prevState,
                        modals: {
                          ...prevState.modals,
                          delete: true,
                        },
                      }));
                      //this.deleteItems();
                    },
                    icon: <FiTrash size={19} />,
                    disabled: selectedItems.length === 0,
                  },
                ]}
              /> : ''}
            </TopControls>
          }
        />

        <Main>
          <Menus>
            <SubmenusWrapper>
              <SubmenuLabel
                className="topbar"
                onClick={() => this.setState({
                  drive: 'default',
                  currentFolderId: null,
                  selectedItems: [],
                  quickAccessFilter: null,
                  searchFilter: ''
                })}
              >
                <FolderIcon /> &nbsp;All Files
              </SubmenuLabel>
            </SubmenusWrapper>
            <QuickAccessWrapper>
              {!quickAccessFilter && isDefaultDrive && (
                <div>
                  <h1 style={{ textAlign: 'center' }}>Quick Access</h1>
                  <QuickAccessItemsWrapper>
                    <QuickAccess
                      onClick={() => {
                        this.setState({drive: 'googleDrive'}, () => this.scrollToTop())
                      }}
                      iconSrc={GDrive}
                      outline
                    >
                      Google Files
                    </QuickAccess>
                    <QuickAccess
                      onClick={() => {
                        this.setState({ quickAccessFilter: "pdf" }, () => this.scrollToTop())
                      }}
                      iconSrc={IconPDF}
                    >
                      PDF Files
                    </QuickAccess>

                    <QuickAccess
                      onClick={() => {
                        this.setState({ quickAccessFilter: "image" }, () => this.scrollToTop())
                      }}
                      iconSrc={IconImages}
                    >
                      Images
                    </QuickAccess>

                    <QuickAccess
                      onClick={() => {
                        this.setState({ quickAccessFilter: "document" }, () => this.scrollToTop())
                      }}
                      iconSrc={IconDocs}
                    >
                      Documents
                    </QuickAccess>

                    <QuickAccess
                      onClick={() => {
                        this.setState({ quickAccessFilter: "media" }, () => this.scrollToTop())
                      }}
                      iconSrc={IconMusic}
                    >
                      Media
                    </QuickAccess>

                    <QuickAccess
                      onClick={() => {
                        this.setState({ quickAccessFilter: "zip" }, () => this.scrollToTop())
                      }}
                      iconSrc={IconZip}
                    >
                      Zip Files
                    </QuickAccess>
                  </QuickAccessItemsWrapper>
                </div>
              )}
            </QuickAccessWrapper>
          </Menus>

          <Content>
            {isDefaultDrive ? <TopControls>
              {quickAccessFilter ? (
                <Wrapper className="file-breadcrumb" style={{ alignItems: 'center' }}>
                  {/* <BackButton className="button default" onClick={() => this.setState({ quickAccessFilter: null })}>
                    Back
                  </BackButton> */}
                  {
                    quickAccessFilter === 'pdf' ? <h1>PDF Files</h1> :
                      quickAccessFilter === 'image' ? <h1>Images</h1> :
                        quickAccessFilter === 'document' ? <h1>Documents</h1> :
                          quickAccessFilter === 'media' ? <h1>Media</h1> :
                            quickAccessFilter === 'zip' ? <h1>Zip Files</h1> :
                              <h1>Quick Access</h1>
                  }
                </Wrapper>
              ) : (
                <Directory
                  currentFolder={currentFolder}
                  folders={folders}
                  currentFile={currentFile}
                  onFolderClick={(folder) => {
                    this.setState({ currentFolderId: folder.uuid }, () => this.setLastView('folder'))
                  }}
                  onRootFolderClick={() => {
                    this.setState({ currentFolderId: null }, () => this.setLastView('folder'))
                  }}
                  onBackFromFileClick={() => {
                    this.setState({currentFolderId: null, currentFileId: null}, () => this.setLastView('folder'))
                  }}
                  backVisible={false}
                />
              )}
              <ItemActions
                onClick={this.removeSelectedItems}
                actions={[
                  {
                    label: this.state.viewType === "grid" ? "List View" : "Grid View",
                    onClick: () => {
                      this.setState(prevState => ({
                        ...prevState,
                        viewType: prevState.viewType === "grid" ? "list" : "grid"
                      }))
                    },
                    icon: this.state.viewType === "grid" ? <ListIcon size={19} /> : <GridIcon size={19} />
                  }
                ]}
              />
              {
                quickAccessFilter ?
                <BackButton className="button default" onClick={() => this.setState({ quickAccessFilter: null })}>
                  Back
                </BackButton> :
                (hierarchy?.length > 0 || currentFile && !hierarchy.length) ?
                <div>
                  <BackButton className="button default" onClick={() => this.handleback(hierarchy, currentFile)}>
                    Back
                  </BackButton>
                </div> :
                ''
              }
            </TopControls> : ''}

            {isDefaultDrive ? (
            <MainList ref={this.mainListRef}>
              {!quickAccessFilter && folderlist?.length > 0 && (
                <>
                  <Heading
                    className="subheader"
                    onClick={() => this.removeSelectedItems()}
                  >
                    Folders
                  </Heading>
                  <SubContent
                    ref={(ref) => (this.folderdivRef = ref)}
                    viewType={this.state.viewType}
                    className={`${this.state.viewType !== 'grid' ? 'topbar rounded inner' : ''}`}
                  >
                    <Folders
                      folderArray={folderArray}
                      currentFolder={currentFolder}
                      UpdateSelf={UpdateSelf}
                      updateState={this.setState.bind(this)}
                      filesWidgetState={this.state}
                      deleteItems={this.deleteItems}
                      isTravelling={!!this.props.travelling}
                      setLastView={this.setLastView}
                      users={this.props.users}
                      files={this.props.data.files}
                    />
                    <div style={{ marginBottom: '30px' }} />
                  </SubContent>
                </>
              )}
              <>
                <Heading
                  className="subheader"
                  onClick={() => this.removeSelectedItems()}>
                  Files
                </Heading>
                <SubContent
                ref={(ref) => (this.filedivRef = ref)}
                viewType={this.state.viewType}
                className={`${this.state.viewType !== 'grid' ? 'topbar rounded inner' : ''}`}
                >
                  <FileList
                    userId={this.props.userId}
                    files={quickAccessFilter ? quickAccessFiles : files}
                    isQuickAccess={!!quickAccessFilter}
                    currentFolder={currentFolder}
                    UpdateSelf={UpdateSelf}
                    updateState={this.setState.bind(this)}
                    filesWidgetState={this.state}
                    childFiles={childFiles}
                    deleteItems={this.deleteItems}
                    isTravelling={!!this.props.travelling}
                    onPresent={this.onPresent}
                    isPresenterOnline={isPresenterOnline}
                    changeIndexState={this.changeIndexState}
                    setLastView={this.setLastView}
                    users={this.props.users}
                  />
                </SubContent>
              </>
              {!quickAccessFilter && this.state.currentFolderId === null && !!urls.length && (
                <>
                  <Heading
                    className="subheader"
                  >
                    URLs
                  </Heading>
                  <SubContent
                    viewType={this.state.viewType}
                    className={`${this.state.viewType !== 'grid' ? 'topbar rounded inner' : ''}`}
                  >
                    <Urls
                      externals={this.props.data.externals ? this.props.data.externals : null}
                      updateSelf={this.props.actions.UpdateSelf}
                      isTravelling={!!this.props.travelling}
                      urls={urls}
                      viewType={this.state.viewType}
                    />
                    <div style={{ marginBottom: '30px' }} />
                  </SubContent>
                </>
              )}
            </MainList>
            ) : ''}

            {drive === 'googleDrive' && !externalFile &&
              <GoogleDrive
                externals={this.props.data.externals ? this.props.data.externals : null}
                updateSelf={this.props.actions.UpdateSelf}
                // setExternalFile={(file: any) => {
                //   this.setState({ externalFile: file })
                // }}
                searchFilter={this.state.searchFilter}
                back={() => this.setState({drive: 'default'})}
                isTravelling={!!this.props.travelling}
              />
            }
          </Content>
        </Main>

        {isDefaultDrive ?
          <Fragment>
            <input
              type="file"
              hidden
              multiple
              onChange={this.handleUpload}
              ref={(ref) => (this.fileInputRef = ref)}
            />

            {modals.createFolder && (
              <ModalNewFolder
                closeModal={this.closeModals}
                meetingName={meetingName}
                UpdateSelf={this.props.actions.UpdateSelf}
                folders={folders}
                currentFolderId={currentFolderId}
                checkDataFiles={this.checkDataFiles}
                userId={this.props.userId}
              />
            )}

            {modals.fileList && (
              <ModalFolderList
                folderArray={folderArray}
                currentFolder={currentFolder}
                updateState={this.setState.bind(this)}
                deleteItems={this.deleteItems}
                filesObject={filesObject}
                closeModal={this.closeModals}
                meetingName={meetingName}
                UpdateSelf={this.props.actions.UpdateSelf}
                folders={folders}
                childFiles={childFiles}
                rightClickedItem={rightClickedItem}
                selectedItems={selectedItems}
                isFromRightClick={isFromRightClick}
                // resetMoveFileState={this.resetMoveFileState}

                filesWidgetProps={this.props}
                filesWidgetState={this.state}
                checkDataFiles={this.checkDataFiles}
              />
            )}

            {modals.renameFolder && (
              <ModalFolderRename
                folder={rightClickedItem}
                folders={folders}
                closeModal={this.closeModals}
                meetingName={meetingName}
                UpdateSelf={UpdateSelf}
                checkDataFiles={this.checkDataFiles}
              />
            )}

            {modals.renameFile && (
              <ModalFileRename
                file={rightClickedItem}
                closeModal={this.closeModals}
                meetingName={meetingName}
                getFiles={() => this.getFiles()}
                UpdateSelf={this.props.actions.UpdateSelf}
              />
            )}

            {modals.delete && (
              <ModalRemove
                closeModal={this.closeModals}
                deleteItems={this.deleteItems}
              />
            )}

            {isUploading && (
              <UploadIndicator
                size={20}
              />
            )}
          </Fragment> : ''
        }

      </ContainerFileDrop>
    );
  }
}
