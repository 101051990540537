import React from 'react';
import styled from 'styled-components';
import Table from '../../_Shared/Table';
import moment from 'moment';
import Api, { Actions, Endpoints } from '../../_Shared/Api';

const Deals = (props: any) => {
    const { results } = props.data.deals;
    const { UpdateSelf } = props.actions;
    const { getDeals } = props;

    const handleClick = (data: any) => {
        UpdateSelf({ screen: 'deal', deal_id: data.id });
    };

    const deleteDeal = async (id: string, e: any) => {
        await Api(Endpoints['Hubspot'], Actions['RemoveDeal'], props.external_token, {}, [['dealId', id]]);
        getDeals();
    };

    

    const titles = (props.data.stages.stages || []).map((s: any) => ({ name: s.label, key: s.stageId, order: s.displayOrder })).sort((a: any, b: any) => a.order - b.order);

    return (
        <>
            <SpaceWrapper>
                <Button
                    className='button default'
                    onClick={() => UpdateSelf({ screen: 'createDeal' })}
                    ref={props.useRef}
                >
                    Create Deal
                </Button>
            </SpaceWrapper>
            <Header className='text-primary'>Deals</Header>
            <SubHeader className='text-secondary'>{props.data.deals?.length ?? 0} records</SubHeader>

            <DealsTableWrapper>
                <DealsTableContainer className="border-lightimp" theme={props.color_scheme}>
                    
                    {titles.map((t:any) => {
                        let total = 0;
                        return (
                            <div className='columns' key={t.key}>
                                <div className='title'>{t.name}</div>
                                <div className='body'>
                                    <div> 
                                        {props.data.deals?.map((deal: any) => {
                                            if(!deal?.properties) return
                                            const { properties } = deal;
                                            if (t.key !== properties.dealstage) return;
                                            total += parseFloat(properties.amount);
                                            return (
                                                <div className='content' key={deal.id} onClick={() => handleClick(deal)}>
                                                    <div className='active'>
                                                        INACTIVE FOR{' '}
                                                        {Math.abs(
                                                            moment(properties.createdate).diff(
                                                                moment(new Date()),
                                                                'days'
                                                            )
                                                        )}{' '}
                                                        DAYS
                                                    </div>
                                                    <div className='deal-title'>{properties.dealname}</div>
                                                    <div className='text'>Amount: ${properties.amount}</div>
                                                    <div className='text'>
                                                      {properties.closedate ? `Close Date: ${moment(properties.closedate).format('MM/DD/YYYY')}` : ''}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className='total'>Total: ${total || 0}</div>
                                </div>
                            </div>
                        );
                    })}
                </DealsTableContainer>
            </DealsTableWrapper>
        </>
    );
};

export default Deals;

const DealsTableWrapper = styled.div`
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
`

const DealsTableContainer = styled.div`
    display: flex;
    /* background: #e5e5e5; */
    border: 1px solid rgb(223, 227, 235);;
    height: 100%;
    width: fit-content;
    font-weight: 400;

    .columns {
        min-width: 190px;
        display: flex;
        flex-direction: column;

        &:first-child {
            /* border-top-left-radius: 10px; */

            & > .title {
                /* border-top-left-radius: 10px; */
            }
        }

        &:last-child {
            /* border-top-right-radius: 10px; */
            & > .title {
                /* border-top-right-radius: 10px; */
            }
        }

        /* display: flex;
        flex-direction: column;
        justify-content: space-between; */
        .body {
            display: flex;
            flex: 1;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            border-left: 1px solid ${props => props.theme !== 'Light' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.02)'};
            border-right: 1px solid ${props => props.theme !== 'Light' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.02)'};
            /* border-top-color: ${props => props.theme !== 'Light' ? 'rgb(65, 64, 82)' : 'unset'}; */

            > div {
                /* overflow: auto; */
                max-height: 400px;
                border-top: 1px solid ${props => props.theme !== 'Light' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.02)'};
            }

            .total {
                /* border-top: 1px solid rgb(223, 227, 235); */
                padding: 10px;
                text-align: center;
                /* background: #fff; */
            }
        }
    }

    .title {
        /* border-bottom: 1px solid rgb(223, 227, 235); */
        padding: 10px !important;
        /* background: #e5e5e5; */
        white-space: nowrap;
        /* text-align: center; */
        border-radius: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: bolder;
        font-size: 0.7rem;
        font-weight: bold;
        color: white;
        background: ${props => props.theme !== 'Light' ? 'rgb(65, 64, 82)' : 'rgb(54, 59, 69)'};
        border-color: ${props => props.theme !== 'Light' ? 'rgb(65, 64, 82)' : ''};
    }

    .content {
        margin: 4px;
        background: ${props => props.theme !== 'Light' ? '#262732' : '#fff'};
        padding: 10px 10px 20px 10px;
        border-radius: 5px;
        cursor: pointer;
        line-height: 16px;
        border: 1px solid rgb(223, 227, 235);;

        .active {
            font-size: 0.5rem;
        }

        .text {
            font-size: 0.7rem;
        }

        :hover {
            /* border: 1px solid #ff9900; */
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        }
    }

    .deal-title {
        font-weight: bold;
        color: #0091AE;
        font-size: 0.9rem;
    }
`;

const SpaceWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    left: 0;
`;

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    margin: 0 2px;
    display: none;

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }
`;

const Header = styled.div`
    font-weight: bold;
    font-size: 20px;
`;

const SubHeader = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 15px;
`;
