import React from 'react'
import { contextMenu } from 'react-contexify';
import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

interface Props {
    handleClick?: any
}

function SlackMenuInvite({handleClick}: Props ) {
    return (
        <Menu id={'slack-member'}>
            <Item id="slack-invite" onClick={(e) => {
                handleClick(e); 
                contextMenu.hideAll()
            }}>Invite</Item>
            {/* { id === 'slack-not-member' && <Item onClick={joinChannel}>Join</Item>} */}
            {/* <Item>Item 2</Item>
            <Separator />
            <Item disabled>Disabled</Item>
            <Separator />
            <Submenu label="Submenu">
                <Item>Sub Item 1</Item>
                <Item>Sub Item 2</Item>
            </Submenu> */}
        </Menu>
    )
}

function SlackMenuJoin({handleClick}: Props ) {
    return (
        <Menu id={'slack-not-member'}>

            <Item id="slack-join" onClick={handleClick}>Join</Item>
            {/* { id === 'slack-member' && <Item onClick={pokeNow}>Invite</Item> }
            { id === 'slack-not-member' && <Item onClick={joinChannel}>Join</Item>} */}
            {/* <Item>Item 2</Item>
            <Separator />
            <Item disabled>Disabled</Item>
            <Separator />
            <Submenu label="Submenu">
                <Item>Sub Item 1</Item>
                <Item>Sub Item 2</Item>
            </Submenu> */}
        </Menu>
    )
}

export { SlackMenuJoin, SlackMenuInvite }
