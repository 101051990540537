import React from 'react';
import ViewContent from './content';
import ViewHeader from './header';
import styled from 'styled-components';

const ViewBody = (props: any) => {
    const [tab, setTab] = React.useState('notes');

    const changeTab = (tab: string) => {
        setTab(tab);
    };

    return (
        <Container>
            <div>
                <ViewHeader changeTab={changeTab} tab={tab} {...props} />
            </div>
            <div>
                <ViewContent tab={tab} {...props} />
            </div>
        </Container>
    );
};

export default ViewBody;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
