import React, { useMemo } from 'react'
import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'

import { MenuWrapper, NotePreview, NotesContentWrapper } from '../styledcomponents'
import useNotesContext from '../useNotesContext'
import Editor from './Editor'
import Header from './Header'
import Title from './Title'
import LoadingComponent from '../../_Shared/LoadingComponent'

function Content() {
    const { activeNote, sidebarNotes, toggleCollapseSidebar, collapsedSidebar, initialized, initializedTitle } = useNotesContext()
    const sidebarKeys = Object.keys(sidebarNotes) || []
    const isEmpty = sidebarKeys.length === 0

    return useMemo(() => {
        if(isEmpty) {
            return <NotesContentWrapper className="topbar rounded inner" collapsed={collapsedSidebar}>
                <MenuWrapper collapsed={collapsedSidebar}>
                    <SideMenuIcon onClick={toggleCollapseSidebar} />
                </MenuWrapper>
                <NotePreview className="whole-div no-note">
                    <div className="lighter">Your notes is empty. <br />Try to create one by clicking the New Note button.<br /></div>
                </NotePreview>
            </NotesContentWrapper>
        }
        return <NotesContentWrapper className="topbar rounded inner notes-contentwrapper" collapsed={collapsedSidebar}>
            {!!!initialized && !!!initializedTitle && <LoadingComponent info={'Preparing notes content'} width="calc(100% - 80px)" height='calc(100% - 40px)' className="topbar" />}
            <MenuWrapper collapsed={collapsedSidebar}>
                <SideMenuIcon onClick={toggleCollapseSidebar} />
            </MenuWrapper>
            <Header />
            <Title />
            <Editor />
        </NotesContentWrapper>
    }, [activeNote, isEmpty, collapsedSidebar, initialized, initializedTitle])
}

export default Content