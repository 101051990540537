import React from 'react'
import styled from 'styled-components'
import Workspace from '../../../../../../assets/Persistent-Workspace.png'

function MobileView() {
  return (
    <MobileViewWrapper className="rounded inner container">
      <div className="title color-primary">Let's Grapl</div>
      <img src={Workspace} alt="Let's Grapl" />
    </MobileViewWrapper>
  )
}

const MobileViewWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: #efefef; */

  img {
    width: 100%;
  }

  .title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 15px;
  }
`

export default MobileView
