import React, { PureComponent, Component } from "react";
import styled from "styled-components";
import ScreenTrack from "../../NewCall/CallSlide/CallTiles/ScreenTrack";
import CallItemControls, { IconButton } from "./CallItemControls";
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from "./types";

interface IProps {
  screenTrack: any;
  withBg?: boolean;
  onClick?: () => void;
}

interface State {}

export default class PresentationUITwilio extends Component<IProps, State> {
  screenPlayer: any;

  constructor(props: IProps) {
    super(props);

    // this.BindStream = this.BindStream.bind(this);
  }

  componentDidMount() {
    // this.BindStream(this.props.screenTrack);
    this.handleResizePresentation();
    window.addEventListener("resize", this.handleResizePresentation);
  }

  componentWillUnmount() {
    const CallWrapperDisplay = document.getElementById("CallWrapperDisplay");
    if (CallWrapperDisplay) CallWrapperDisplay.classList.remove("upsidedown");
    window.removeEventListener("resize", this.handleResizePresentation);
  }

  handleResizePresentation = () => {
    const rightCallWrapper = document.getElementById("rightCallWrapper");
    const CallWrapperDisplay = document.getElementById("CallWrapperDisplay");
    if (rightCallWrapper && rightCallWrapper.offsetWidth < 1060) {
      if (
        CallWrapperDisplay &&
        !CallWrapperDisplay.classList.contains("upsidedown")
      )
        CallWrapperDisplay.classList.add("upsidedown");
    } else {
      if (CallWrapperDisplay) CallWrapperDisplay.classList.remove("upsidedown");
    }
  };

  // UNSAFE_componentWillReceiveProps(nextProps: IProps) {
  //   if (
  //     JSON.stringify(this.props.screenTrack) !==
  //     JSON.stringify(nextProps.screenTrack)
  //   ) {
  //     setTimeout(() => {
  //       this.BindStream(nextProps.screenTrack);
  //     }, 0);
  //   }
  // }

  shouldComponentUpdate(nextProps: IProps) {
    return (
      JSON.stringify(this.props.screenTrack) !==
      JSON.stringify(nextProps.screenTrack)
    );
  }

  // BindStream(screenTrack: IProps["screenTrack"]) {
  //   if (this.screenPlayer) {
  //     const stream = new MediaStream();
  //     screenTrack &&
  //       normalizedCallHelpers.addTrackToStream(stream, screenTrack);
  //     this.screenPlayer.srcObject = stream;
  //   }
  // }

  render() {
    return (
      <Item className="presentation-ui rounded inner" onClick={this.props.onClick}>
        <ScreenTrack key={`${Math.random()}-videotrack`} track={this.props.screenTrack.mediaStreamTrack}/>
      </Item>
    );
  }
}
const Vid = styled.video`
  height: 100%;
  width: 100%;
  border-radius: 6px;
`;

const AspectRatioEnforcer = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover {
    ${IconButton} {
      opacity: 1;
    }
  }
`;

export const IconButtonMute = styled.div<{ muted?: boolean }>`
  color: white;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  font-size: 14px;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  opacity: 0;
  position: relative;
  ${(props) => (props.muted ? "opacity: 1!important;" : "")}
  cursor: pointer;
`;
