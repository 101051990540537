import React from 'react'
import styled from 'styled-components'
import {ImMobile as MobileIcon} from '@react-icons/all-files/im/ImMobile'
import {ImTablet as TabletIcon} from '@react-icons/all-files/im/ImTablet'
import {IoDesktopOutline as DesktopIcon} from '@react-icons/all-files/io5/IoDesktopOutline'

import bg from './screens/bg.png'
import IE from './assets/icons/browsers/ie.svg'
import Chrome from './assets/icons/browsers/chrome.svg'
import Safari from './assets/icons/browsers/safari-ios.svg'
import Firefox from './assets/icons/browsers/firefox.svg'
import Opera from './assets/icons/browsers/opera.svg'
import Header from './screens/Header'

function NotSupported() {
    return (
        <NotSupportedContainer>
            <Header withButtons={false} />
            <Container className="topbar rounded">
                <Title> You seem to be using an unsupported browser </Title>
                <Subtitle className="subheader">To get the most out of Grapl, please access the app with a supported browser</Subtitle>
                <LogoContainer>
                    {/* <div className="notsupported">
                        <img src={IE} alt="Internet Explorer (Not supported)" />
                        <span>IE</span>
                    </div> */}
                    <div>
                        <img src={Chrome} alt="Chrome (Supported)" />
                        <span>Chrome</span>
                        <DevicesWrapper><MobileIcon title="Mobile (ios/android)"/><TabletIcon className="notsupported" /><DesktopIcon title="Desktop"/></DevicesWrapper>
                    </div>
                    <div className="notsupported">
                        <img src={Firefox} alt="Firefox (Not supported)" />
                        <span>Firefox</span>
                        <DevicesWrapper><MobileIcon /><TabletIcon /><DesktopIcon /></DevicesWrapper>
                    </div>
                    <div>
                        <img src={Safari} alt="Safari (Supported)" />
                        <span>Safari</span>
                        <DevicesWrapper><MobileIcon title="Mobile (ios/android)"/><TabletIcon title="Tablet" /><DesktopIcon title="Desktop" className="notsupported"/></DevicesWrapper>
                    </div>
                    <div className="notsupported">
                        <img src={Opera} alt="Opera (Not supported)" />
                        <span>Opera</span>
                        <DevicesWrapper><MobileIcon /><TabletIcon /><DesktopIcon /></DevicesWrapper>
                    </div>
                </LogoContainer>
            </Container>
        </NotSupportedContainer>
    )
}

export default NotSupported

const DevicesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    > * {
        margin: 2px 2.5px;

        &.notsupported {
            filter: gray; /* IE6-9 */
            -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
            filter: grayscale(1);
            opacity: 0.3;
        }
    }
`

const NotSupportedContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: url(${bg}) no-repeat center 70% fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-size: 14px;
`

const Container = styled.div`
    background: white;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    min-width: 350px;
    max-width: 500px;

    @media (max-width: 350px) {
        min-width: 320px;
        max-width: 350px;
    }
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Subtitle = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	text-align: center;
    line-height: 16px;
`

const LogoContainer = styled.div`
    display: grid;
    grid-gap: 35px; // 15 for 5 columns
    grid-template-columns: repeat(4, minmax(35px, 1fr) );

    > div {
        text-align: center;
        font-weight: 400;

        img {
            margin-bottom: 10px;
        }

        span {
            font-size: 13px;
        }

        &.notsupported {
            filter: gray; /* IE6-9 */
            -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
            filter: grayscale(1);
            opacity: 0.3;
        }
    }
`