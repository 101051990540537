import React from 'react'
import styled from 'styled-components'
import ChatContextProvider from './ChatContext'
import ChatFooter from './ChatFooter'
import ChatHeader from './ChatHeader'
import Conversations from './Conversations/Conversations'

interface Props {
    updateSelf: (obj: any) => void,
    sendNotification: (obj: any) => void,
    updateSync: (obj: any) => void,
}

function NewChat({ updateSelf, sendNotification, updateSync }: Props) {
    return (

        <MainCont>
            <ChatContextProvider sendNotification={sendNotification} updateSelf={updateSelf}>
                <ChatHeader updateSync={() => updateSync('')} />
                <Conversations />
                <ChatFooter />
            </ChatContextProvider>
        </MainCont>
    )
}


export default NewChat

const MainCont = styled.div`
    max-height: 500px;
    min-height: 500px;
    display: flex;
    flex-direction: column;

    .no-note {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
        height: 500px;
        font-weight: 400;
    }

    @media (max-height: 740px) {
        min-height: 333px;
        max-height: 333px;

        .no-note {
            height: 333px;
        }
    }

    @media (max-width: 480px) {
        min-height: 277px;
        max-height: 277px;

        .no-note {
            height: 280px;
        }
    }
`

