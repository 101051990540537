import React, { useState } from 'react'
import styled from 'styled-components'
import Checkbox from '../../../src/components/Checkbox'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import ReactTooltip from 'react-tooltip'
import {AiOutlineInfoCircle as ToolTip} from 'react-icons/ai'

interface Props {
    publicPermission: string
    setPermission: Function
    onPresent: Function
    changeIndexState: Function
    hasMtype: boolean
    saveLink: Function
    file: any
    GDExternals: any
}

const ShareMenu = (props: Props) => {
    const { publicPermission, setPermission, onPresent, changeIndexState, hasMtype, GDExternals, file } = props

    const [saveLink, setSaveLink] = useState(false)
    return (
        <Container>
            <div className="share-radio">
                <RadioWrapper checked={publicPermission === ''}>
                    <input type="radio" id="private" name="private-radio"
                    checked={publicPermission === ''}
                    onChange={(e)=>{setPermission('', false)}} />
                    <label htmlFor="private">Private</label>
                </RadioWrapper>
            </div>
            <div className="share-radio">
                <RadioWrapper checked={publicPermission === 'reader' || publicPermission === 'writer'}>
                    <input type="radio" id="public" name="public-radio"
                    checked={publicPermission === 'reader' || publicPermission === 'writer'}
                    onChange={()=>{setPermission('reader', false)}} />
                    <label htmlFor="public">Public</label>
                </RadioWrapper>
            </div>
            {(publicPermission === 'reader' || publicPermission === 'writer') && hasMtype ?
            <CheckboxWrapper>
                <div className="name" style={{paddingRight: '10px'}}>Can Edit</div>
                <Checkbox
                    name="edit"
                    checked={publicPermission === 'writer'}
                    onChange={(e) => {e.target.checked ? setPermission('writer', false) : setPermission('reader', false)}}
                />
            </CheckboxWrapper>
            : null}
            <CheckboxWrapper>
                <ReactTooltip id="save-link" place="left">
                    <span>Save to file syncs</span>
                </ReactTooltip>
                <div className="name">Save Link</div>
                <ToolTip className="tooltip" data-tip data-for="save-link" />
                <Checkbox
                    name="save"
                    checked={saveLink}
                    onChange={(e) => {e.target.checked ? setSaveLink(true) : setSaveLink(false)}}
                />
            </CheckboxWrapper>
            <div>
                <Button
                    className="button default primary"
                    onClick={() => {
                        if(publicPermission !== '') {
                            onPresent(true)
                            if(saveLink) {
                                if(!!GDExternals && !!GDExternals[file.id]) {
                                    changeIndexState({
                                        modal: {
                                            show: true,
                                            type: 'FSGDduplicateLink'
                                        },
                                        toSaveLink: file
                                    })
                                } else {
                                    props.saveLink()
                                }
                            }
                        } else {
                            changeIndexState({
                                modal: {
                                    show: true,
                                    type: 'GDSetToPublic',
                                },
                                ...(saveLink && !!GDExternals && !!GDExternals[file.id] ? {toSaveLink: file} : {})
                            })

                            if(saveLink && (!!!GDExternals || !!!GDExternals[file.id]))
                            props.saveLink()
                        }
                    }}
                >   <HiOutlinePresentationChartLine size={16}/>
                    Present Now
                </Button>
            </div>
        </Container>
    )
}

const Container = styled.div`
    padding: 10px;
    .share-radio{
        padding: 7px 5px;
    }
    > div:nth-child(3) {
        margin-top: 15px;
    }
`

const RadioWrapper = styled.div<{checked: boolean}>`
    cursor: default;

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    label {
        position: relative;
        line-height: 20px;
        display: inline-block;
        cursor: pointer;
    }

    label:before {
        content: '';
        position: absolute;
        left: 132px;
        top: -1px;
        width: 18px;
        height: 18px;
        border: 1px solid;
        border-radius: 100%;
        border-color: #FF9900 !important;
    }

    label:after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        top: 3px;
        left: 136px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        background: #FF9900;
        z-index: 1;

        ${({checked}) => checked ?
        `
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        `
        :
        `
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        `
        }
    }
`

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .name {
        font-size: 14px;
    }

    .tooltip {
        margin-right: 10px;
        padding: 0 0px 5px 2px;
    }
`

const Button = styled.div`
    text-align: center;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        margin-right: 10px;
    }
`

export default ShareMenu