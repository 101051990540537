import React, { Component } from 'react'
import styled from 'styled-components'
import { AllWidgets } from '..'
import { IWidgetProps } from '../types'
import { Todos } from './types'

type Props = {
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    groupIdtoEdit: string
    taskIdToDelete: string
    selectedGroup: string
    todos: Todos | null
    changeIndexState: Function
}

type state = {}
// import _ from 'lodash'

export class DeleteConfirmation extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {}
    }

    handleDelete = () => {
        if(this.props.groupIdtoEdit) {
            let firstGroupKey
            const todos = {...this.props.todos}

            if(todos[this.props.groupIdtoEdit])
            delete todos[this.props.groupIdtoEdit]

            this.props.actions.UpdateSelf({todo: Object.keys(todos).length ? todos : null})

            if(this.props.selectedGroup === this.props.groupIdtoEdit && Object.keys(todos).length){
                firstGroupKey = Object.keys(todos)[0]
            }

            this.props.changeIndexState({
                showDeleteModal: false,
                groupIdtoEdit: '',
                ...(firstGroupKey ? {selectedGroup: firstGroupKey} : {})
            })
        }

        if(this.props.taskIdToDelete) {
            const todos = {...this.props.todos}

            if(todos[this.props.selectedGroup] && todos[this.props.selectedGroup].tasks?.[this.props.taskIdToDelete]) {
                delete todos[this.props.selectedGroup].tasks?.[this.props.taskIdToDelete]
                const tasks = todos[this.props.selectedGroup].tasks
                if(tasks && !Object.keys(tasks).length) {
                    todos[this.props.selectedGroup].tasks = null
                }
            }

            this.props.actions.UpdateSelf({todo: todos})

            this.props.changeIndexState({
                showDeleteModal: false,
                taskIdToDelete: ''
            })
        }
  }

    render() {
        return (
            <MainCont className="topbar rounded inner">
                <div className="warning-wrapper">
                    <WarningHeader>
                        Remove {this.props.groupIdtoEdit === ''
                            ? ' Task'
                            : ' Group'}
                    </WarningHeader>
                    <WarningMessage>
                        Are you sure you want to remove this
                        {this.props.groupIdtoEdit === ''
                            ? ' task?'
                            : ' group?'}
                    </WarningMessage>
                </div>

                <ButtonCont>
                    <CancelBtn
                        className="button default"
                        onClick={() => {this.props.changeIndexState({
                            showDeleteModal: false,
                            taskIdToDelete: '',
                            groupIdtoEdit: ''
                        })}}
                    >
                        Cancel
                    </CancelBtn>
                    <DeleteBtn
                        className="button danger"
                        onClick={() => this.handleDelete()}
                    >
                        Remove
                    </DeleteBtn>
                </ButtonCont>
            </MainCont>
        )
    }
}

const MainCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    .warning-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const WarningHeader = styled.div`
    font-weight: 500;
    font-size: 16px;
`

const WarningMessage = styled.span`
    font-weight: 400;
    font-size: 13px;
    padding-top: 10px;
`
const ButtonCont = styled.div` 
    display: flex;
    width: 70%;
`
const DeleteBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 14px;
    margin: 4px;
`
const CancelBtn = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 4px;
`

export default DeleteConfirmation
