import React, { Component } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import { v4 as getUuidV4 } from "uuid"
import { IWidgetProps } from '../types'
import { AllWidgets } from '..'

import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose'
import { FiPlus as PlusIcon } from '@react-icons/all-files/fi/FiPlus'
import { toast } from 'react-toastify'
import { Todos, Todo } from './types'

type Props = {
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    todos: Todos | null
    changeIndexState: (s: any) => void
    groupIdtoEdit: string
}
interface Swatch {
    id: string;
    color: string;
}

type state = {
    brushColor: string
    groupName: string
    groupDesc: string
    errors: required | null
    swatchColors: Swatch[]
    selectedSwatch: string | null
    selectedColor: string
}

function createSwatch(swatchColor: string) {
    return { id: getUuidV4(), color: swatchColor }
}

const COLOR_SWATCHES = [
    createSwatch("#fd9b3e"), // Orange
    createSwatch("#f382ef"), // Pink
    createSwatch("#9081fc"), // Purple
    createSwatch("#36c5f0"), // Teal
    createSwatch("#bf312e"), // Red
    createSwatch("#624f9e"), // Dark Purple
    createSwatch("#307ffa"), // Blue
]

type required = {
    [key: string]: any
}

type requiredCheckTypes = {
    name: string,
    value: any
}

const REQUIRED: required = {
    groupName: 'Group Name'
}

export class CreateGroupForm extends Component<Props, state> {
    constructor(props: Props) {
        super(props)

        this.state = {
            brushColor: '#F89809',
            groupName: '',
            groupDesc: '',
            errors: null,
            selectedSwatch: null,
            selectedColor: '#F89809',
            swatchColors: COLOR_SWATCHES
        }
        this.handleBrushColor = this.handleBrushColor.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.createGroup = this.createGroup.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    colorPicker: any

    timer: any
    inputRef: any

    addSwatchColor = (evt: any) => {
        const newSwatch = createSwatch(evt.target.value)

        this.setState(prevState => ({
            ...prevState,
            selectedSwatch: newSwatch.id,
            selectedColor: newSwatch.color,
        }))
    }

    changeSwatchColor = (evt: any) => {
        const color = evt.target.value
        this.setState(prevState => ({
            ...prevState,
            selectedColor: color,
            swatchColors: prevState.swatchColors.map(swatchColor => {
                if (swatchColor.id === prevState.selectedSwatch) {
                    return { ...swatchColor, color }
                }
                return swatchColor
            })
        }))
    }

    selectSwatch = (swatchId: string) => () => {
        this.setState(prevState => {
            const swatch = prevState.swatchColors.find(swatch => swatch.id === swatchId)

            return {
                ...prevState,
                selectedSwatch: swatchId,
                selectedColor: swatch?.color || prevState.selectedColor
            }
        })
    }

    handleBrushColor(e: any) {
        const color = e.target.value

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            this.setState({ brushColor: color })
        }, 50)
    }

    handleChange(e: any) {
        const { name, value } = e.target

        this.setState({ [name]: value } as Pick<state, keyof state>)
    }

    handleGroupNameChange = (evt: any) => {
        const { name, value } = evt.target

        this.requiredCheck([{ name, value }])

        if (!(value.length > 60)) {
            this.setState({
                [name]: value,
                errors: this.resetErrors(name),
            } as Pick<state, keyof state>)
        } else {
            this.setState({
                errors: {
                    [name]: `${REQUIRED[name]} can't be greater than 60 characters`
                }
            })
        }
    }

    onBlurRequired = (evt: any) => {
        const { name, value } = evt.target
        this.requiredCheck([{ name, value: value.trim() }])
        this.setState({
            groupName: this.state.groupName.trim()
        })
    }

    requiredCheck = (requireds: requiredCheckTypes[]) => {
        let errors = {}
        let valueUpdates = {}

        requireds.map(({ name, value }) => {
            if (REQUIRED[name] && (!value || (Array.isArray(value) && !value.length))) {
                valueUpdates = {
                    ...valueUpdates,
                    [name]: value
                }
                errors = {
                    ...errors,
                    [name]: `${REQUIRED[name]} is required`
                }
            }
        })

        if (Object.keys(errors).length) {
            this.setState({
                ...valueUpdates,
                ...(this.state.errors ? {
                    errors: {
                        ...this.state.errors,
                        ...errors
                    }
                } : { errors })
            })
        }
    }

    resetErrors = (name: string) => {
        let delVal = null
        if (this.state.errors) {
            if (this.state.errors[name]) {
                const { [name]: todel, ...errors } = this.state.errors
                if (!Object.keys(errors).length)
                    return null
                else
                    return errors
            }
            return this.state.errors
        }
        return delVal
    }

    componentDidMount() {
        if (this.props.groupIdtoEdit && this.props.todos?.[this.props.groupIdtoEdit]) {
            const m = this.props.todos[this.props.groupIdtoEdit]
            this.setState({
                groupName: m.groupName,
                groupDesc: m.desc,
                brushColor: m.groupColor,
                selectedColor: m.groupColor
            }, () => { this.onMountRequiredCheck() })
        } else {
            this.onMountRequiredCheck()
        }

        setTimeout(() => {
            document.getElementById('group-name-field')?.focus()
        }, 10);
    }

    onMountRequiredCheck = () => {
        const requireds = Object.keys(REQUIRED).map((r: keyof state) => {
            return { name: r, value: this.state[r] }
        })
        this.requiredCheck(requireds)
    }


    createGroup() {
        if (this.props.groupIdtoEdit === '') {
            const id = new Date().getTime()
            const key = id.toString()
            const newTodo = {
                [key]: {
                    id,
                    groupName: this.state.groupName,
                    desc: this.state.groupDesc,
                    groupColor: this.state.selectedColor,
                    tasks: null
                }
            }
            const todos = {
                ...(this.props.todos ? this.props.todos : {}),
                ...newTodo
            }

            this.props.actions.UpdateSelf({ todo: todos })
            this.props.changeIndexState({ selectedGroup: key, showCreateGroup: false })
        } else {
            if (this.props.todos?.[this.props.groupIdtoEdit]) {
                const todos = {
                    ...this.props.todos,
                    [this.props.groupIdtoEdit]: {
                        ...this.props.todos[this.props.groupIdtoEdit],
                        groupName: this.state.groupName,
                        desc: this.state.groupDesc,
                        groupColor: this.state.selectedColor,
                        selected: true
                    }
                }
                this.props.actions.UpdateSelf({ todo: todos })
                this.props.changeIndexState({ groupIdtoEdit: '', showCreateGroup: false })
            }
        }
    }

    closeForm() {
        this.props.changeIndexState({ showCreateGroup: false, groupIdtoEdit: '' })
    }

    render() {
        const { groupIdtoEdit } = this.props

        return (
            <MainCont className="topbar">
                <Title>
                    {groupIdtoEdit !== '' ? 'Edit Group' : 'Create Group'}
                </Title>
                <Label>Group Name</Label>
                <GNInput
                    id='group-name-field'
                    autoComplete="off"
                    name="groupName"
                    value={this.state.groupName}
                    className="textarea hover-container task-title border-light"
                    placeholder="New Group"
                    onChange={this.handleGroupNameChange}
                    onBlur={this.onBlurRequired}
                />
                {this.state.errors?.groupName && (
                    <ErrorMessage>{this.state.errors.groupName}</ErrorMessage>
                )}
                <Label>Description</Label>
                {/* <DescInput className="container" /> */}
                <TextArea
                    name="groupDesc"
                    value={this.state.groupDesc}
                    className="textarea hover-container task-title border-light"
                    placeholder="Group description..."
                    onResize={(e) => { }}
                    onChange={(e: any) => this.handleChange(e)}
                    rows={6}
                    maxRows={6}
                    onBlur={() => this.setState({ groupDesc: this.state.groupDesc.trim() })}
                />
                <Label>Select Color</Label>

                {/*<PickerCont
                    bg={this.state.brushColor}
                    onClick={() => this.colorPicker.click()}
                >
                    <ColorPicker
                        value={this.state.brushColor}
                        ref={(ref: any) => (this.colorPicker = ref)}
                        type="color"
                        onChange={this.handleBrushColor}
                    />
                </PickerCont>*/}

                <SwatchContainer>
                    {this.state.swatchColors.map((swatch, i) => (
                        <Swatch
                            bg={swatch.color}
                            key={i}
                            onClick={this.selectSwatch(swatch.id)}
                            selected={this.state.selectedSwatch === swatch.id}
                        />
                    ))}

                    <Swatch bg={`${this.state.selectedColor || '#363b45'}`} htmlFor="color_picker">
                        <PlusIcon size={24} stroke="#fff" />

                        <ColorPicker
                            id="color_picker"
                            name="color_picker"
                            value="#363b45"
                            type="color"
                            onChange={this.changeSwatchColor}
                            onClick={this.addSwatchColor}
                        />
                    </Swatch>
                </SwatchContainer>

                <CreateButton
                    className="button primary"
                    disabled={!!this.state.errors}
                    onClick={() =>
                        this.state.groupName && !this.state.errors
                            ? this.createGroup()
                            : toast.error(
                                'Please supply all the information needed',
                                {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                }
                            )
                    }
                >
                    {groupIdtoEdit !== '' ? 'Update Group' : 'Create Group'}
                </CreateButton>

                <UnderlinedText onClick={() => this.closeForm()}>
                    <span>Cancel</span>
                </UnderlinedText>
            </MainCont>
        )
    }
}

const UnderlinedText = styled.p`
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;

    span {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 2px solid;
    }
`

const TextArea = styled(TextareaAutosize)`
    border-radius: 8px !important;
`

const MainCont = styled.div`
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 10px;
    border-radius: 10px;
    position: relative;
    
    input, textarea {
        &::placeholder {
            opacity: 0.6;
        }
    }
`

const CloseBtn = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
`

const Title = styled.span`
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
`

const Label = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    filter: brightness(0.8);
`

const GNInput = styled.input`
    border-radius: 99px !important;
    padding: 10px;
    outline: none;
    width: 15rem;
`

const ColorPicker = styled.input`
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
`

const CreateButton = styled.button`
    margin-bottom: 17px;
    cursor: pointer;
    border: none;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`

const ErrorMessage = styled.p`
    color: red;
    margin: 8px 0;
    max-width: 262px;
    font-size: 0.8em;
`

const SwatchContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 15rem;
    margin-bottom: 24px;
`

const Swatch = styled.label<{ bg: string, selected?: boolean }>`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${(p) => p.bg};
    margin: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.selected ? props.theme.shadows.neumorphiclight : null};
`

export default CreateGroupForm
