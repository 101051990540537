import React, {useEffect, useState, memo} from 'react'
import hark from 'hark'

// Styled Components
import {WaveWrapper, Wave, WaveContainer} from './styledComponents'
import {LocalAudioTrack, RemoteAudioTrack} from 'twilio-video'
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack'
import useIsTrackEnabled from '../../../hooks/useIsTrackEnabled'
import useLocalTracks from '../../../../../../../contexts/useLocalTracks'

interface Props {
  audioTrack: LocalAudioTrack
}

const SpeakingDetector = ({audioTrack}: Props) => {
  const { localTracks } = useLocalTracks()
  const [decibels, setDecibels] = useState(0)
  const audioTrck = localTracks.find(
    (track) => track.kind === 'audio',
  ) as LocalAudioTrack

  const audio = audioTrck || audioTrack

  const isTrackEnabled = useIsTrackEnabled(
    audio as LocalAudioTrack | RemoteAudioTrack,
  )
  
  useEffect(() => {
    if (audio && isTrackEnabled) {
      const mediaStreamTrack = audio?.mediaStreamTrack
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()])

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () =>
        newMediaStream.getTracks().forEach((track) => track.stop())
      audio.on('stopped', stopAllMediaStreamTracks)

      const speechEvents = hark(newMediaStream)

      speechEvents.on('volume_change', (decibels: any) => {
        setDecibels(decibels)
      })

      return () => {
        speechEvents.stop()
        stopAllMediaStreamTracks()
        audio.off('stopped', stopAllMediaStreamTracks)
      }
    }
  }, [isTrackEnabled, JSON.stringify(audio?.mediaStreamTrack?.id)])

  const scale1 = decibels && decibels > -50 ? (50 + decibels) * 1 + 20 : 20
  const scale2 = decibels && decibels > -50 ? (50 + decibels) * 2 + 45 : 20
  const scale3 = decibels && decibels > -50 ? (50 + decibels) * 3 + 75 : 20

  return (
    <WaveWrapper>
      <WaveContainer>
        <Wave className="box" scale={Math.min(Math.max(scale1, 20), 30)}></Wave>
        <Wave className="box" scale={Math.min(Math.max(scale2, 20), 55)}></Wave>
        <Wave className="box" scale={Math.min(Math.max(scale3, 20), 75)}></Wave>
        <Wave className="box" scale={Math.min(Math.max(scale2, 20), 55)}></Wave>
        <Wave className="box" scale={Math.min(Math.max(scale1, 20), 30)}></Wave>
      </WaveContainer>
    </WaveWrapper>
  )
}

// function areEqual(prevProps: Props, nextProps: Props) {
//   return JSON.stringify(prevProps.audioTrack) === JSON.stringify(nextProps.audioTrack)
// }

export default memo(SpeakingDetector)
