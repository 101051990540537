import React from 'react';
import Api, { Actions, Endpoints } from '../../../../../../_Shared/Api';
import styled from 'styled-components';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const NotesContent = (props: any) => {
    const [notes, setNotes]: any = React.useState(null);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    const fetch = async () => {
        const deal = await Api(Endpoints['Hubspot'], Actions['GetAssociationsDealsToNotes'], props.external_token, {}, [
            ['dealId', props.data.deal_id],
        ]);
        const engagements = deal.results.map(async (d: any) => {
            const engagement = await Api(Endpoints['Hubspot'], Actions['GetEngagements'], props.external_token, {}, [
                ['associationId', d.id],
            ]);
            return engagement;
        });

        Promise.all(engagements).then((arr: any) => {
                const sorted = arr.sort(function(x:any, y:any){
                    return x.engagement.timestamp - y.engagement.timestamp;
                })
                setNotes(sorted.reverse())
        });

    };

    // const getOwner = async (id: string) => {
    //     try {
    //         const owner = await Api(Endpoints['Hubspot'], Actions['GetOwner'], props.external_token, {}, [
    //             ['ownerId', id],
    //         ]);
    //         return owner;
    //     } catch (error) {
    //         return {};
    //     }
    // };

    React.useEffect(() => {
        fetch();
    }, []);

    const editorChange = (value: any) => {
        setEditorState(value);
    };

    const saveNote = async () => {
        const rawContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const ownerId = props.data.account.user_id
        const data = {
            engagement: {
                active: true,
                // ownerId: ownerId,
                type: 'NOTE',
                timestamp: new Date().getTime(),
            },
            associations: {
                contactIds: [],
                companyIds: [],
                dealIds: [props.data.deal_id],
                ownerIds: [],
                ticketIds: [],
            },
            attachments: [],
            metadata: {
                body: rawContentState,
            },
        };

        await Api(Endpoints['Hubspot'], Actions['CreateEngagement'], props.external_token, { body: data });
        setEditorState(EditorState.createEmpty())
        fetch();
    }

    // if (notes === null) return <>Preparing notes for you...</>;
    // if (notes.length === 0) return <>There is nothing here</>;

    return (
        <>
            <Container>
                <ParentNoteContainer>
                    <NoteContainer>
                        <CustomEditor
                            editorState={editorState}
                            toolbarClassName='toolbarClassName'
                            wrapperClassName='wrapperClassName'
                            editorClassName='editorClassName'
                            onEditorStateChange={editorChange}
                        />
                        <Button className="button default" onClick={saveNote}>Save</Button>
                    </NoteContainer>
                </ParentNoteContainer>
                {notes === null ? (
                    <>Preparing notes for you...</>
                ) : notes.length === 0 ? (
                    <>There is nothing here</>
                ) : (
                    notes.map((n: any, i: number) => {
                        const { engagement } = n;
                        return (
                            <ParentNoteContainer key={i}>
                                <DateContainer>{moment(engagement.timestamp).format('MMMM YYYY')}</DateContainer>
                                <NoteContainer>
                                    <NoteHeader>
                                        <NoteTitle>
                                            Note
                                        </NoteTitle>
                                        <NoteDate>{moment(engagement.timestamp).format('llll')}</NoteDate>
                                    </NoteHeader>
                                    <NoteDescription dangerouslySetInnerHTML={{ __html: n.metadata.body }} />
                                </NoteContainer>
                            </ParentNoteContainer>
                        );
                    })
                )}
            </Container>
        </>
    );
};


const Button = styled.button`
    text-align: center;
    cursor: pointer;
    font-weight: ${(props) => props.theme.textBold};
    min-width: 80px;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 10px !important;
    border: none;
    color: white;


    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${(props) => props.theme.shadows.newlight};
    }
`;
const CustomEditor = styled(Editor)``;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const DateContainer = styled.div`
    /* width: 100%; */
`;

const NoteDate = styled.div`
    /* width: 100%; */
    margin-left: auto;
    font-size: 12px;
    color: #81818d;
`;

const NoteTitle = styled.div`
    /* width: 100%; */
    font-weight: 500;
`;

const NoteHeader = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const NoteDescription = styled.div`
    /* width: 100%; */
`;

const NoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #f2f4f6;
    border: 1px solid #f2f4f6;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
`;

const ParentNoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
`;

export default NotesContent;
