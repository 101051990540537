import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'

import LoopApi from '../../helpers/LoopApi'
import Recaptcha from '../../components/Recaptcha'

import { ErrorMessage, FormGroup, Input, InputLabel, LoginForm, LogoIcon, LogoWrapper, MainFormWrapper, OtherInfo, RecaptchaWrapper, SubmitButton, SubTitle, Title } from './styled'
import ExpiredToken from './ExpiredToken'
import ForgotPasswordIcon from './forgotpassword.svg'
import { useLocation, useParams } from 'react-router'

function Form() {
  const { search } = useLocation();
  const { token_code } = useParams() as any
  const urlParams = new URLSearchParams(search);
  const id = urlParams.get('_k');

  let recaptchaInstance: any = React.createRef()
  const [values, setValues] = useState({
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
  })
  const [visibility, setVisibility] = useState({
      password: false,
      confirmPassword: false
  })
  const [recaptcha, setRecaptcha] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [finished, setFinished] = useState(false)
  const [failed, setFailed] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [failedMsg, setFailedMsg] = useState('')
  const [showRecaptcha, setShowRecaptcha] = useState(false)

  useEffect(() => {
    const fetchData = async() => {
        try {
            const resp = await LoopApi(null, 'VerifyResetPassword', {},
                [['token_code', token_code]])
            if (resp.error) {
                setFailedMsg(resp.msg)
                setFailed(true)
            } else {
                setShowRecaptcha(true)
            }
        } catch (err) {
            console.error(err)
            setFailed(true)
        }
    }

    fetchData()
    
  }, [])

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setErrorMessage('')

    setValues({
        ...values,
        [e.target.name]: value
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(!_isInputGood) return

    handleReset()
  }

  const handleReset = async() => {
    setSubmitting(true)
    setErrorMessage('')

    try {
        const resp = await LoopApi(null, 'ResetPassword', {
            confirm_password: values.confirmPassword,
            password: values.password,
            captcha_response: recaptcha
        }, [['token_code', token_code], ['id', id]])
        if (resp.error) {
            setErrorMessage(resp.msg)
        } else {
            setFinished(true)
            setErrorMessage('')
            setSuccessMsg(resp.msg)
        }
        setSubmitting(false)
    } catch (err) {
        console.error(err)
        this.setState({ submitting: false })
    }
  }

  const passwordValidation = () => {
    const { password, confirmPassword } = values;
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if(password === '') return
    if (password.length < 8) return 'Password must at least 8 characters.';
    if (password.length > 16) return 'Password must at maximum of 16 characters.';
    if (!/[a-z]/.test(password)) return 'At least one lowercase character.';
    if (!/[A-Z]/.test(password)) return 'At least one uppercase character.';
    if (!/\d/.test(password)) return 'At least one numeric character.';
    if (!format.test(password)) return 'At least one special character.';
    if (password !== confirmPassword ) return 'Please make your password match.';
  }

  const _isInputGood = () => {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return !values.password ||
    !values.confirmPassword ||
    values.password !== values.confirmPassword ||
    !recaptcha || 
    values.password.length < 8 ||
    values.password.length > 16 ||
    !/[a-z]/.test(values.password) || 
    !/[A-Z]/.test(values.password) || 
    !/\d/.test(values.password) ||
    !format.test(values.password)
  }


  if(finished || failed) {
    return <ExpiredToken error={failed} msg={failed ? failedMsg : successMsg} />
  }
  
  return (
    <LoginForm onSubmit={handleSubmit}>
        <LogoWrapper>
            <LogoIcon src={ForgotPasswordIcon} alt={'Forgot Password'} />
        </LogoWrapper>
        <Title>Create New Password</Title>
        <SubTitle>What would you like your new password to be?</SubTitle>
        <FormGroup className={`form-group focused`}>
            <InputLabel htmlFor="loginPassword">New Password</InputLabel>
            <Input 
                className='form-password'
                id="loginPassword" 
                type={visibility.password ? "text" : "password"}
                name="password"
                placeholder='Password'
                value={values.password}
                onChange={handleValueChange}
                // onFocus={() => setFocused({ ...focused, password: true })} 
                // onBlur={() => setFocused({ ...focused, password: false })}
                autoComplete="off"
            />
            <span className="password-view" onClick={() => setVisibility({ ...visibility, password: !visibility.password })}>{visibility.password ? <EyeIcon /> : <EyeSlashIcon />}</span>
        </FormGroup>
        <FormGroup className={`form-group focused`}>
            <InputLabel htmlFor="loginConfirmPassword">Confirm Password</InputLabel>
            <Input 
                className='form-password'
                id="loginConfirmPassword" 
                type={visibility.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder='Confirm Password'
                value={values.confirmPassword}
                onChange={handleValueChange}
                // onFocus={() => setFocused({ ...focused, confirmPassword: true })} 
                // onBlur={() => setFocused({ ...focused, confirmPassword: false })}
                autoComplete="off"
            />
            <span className="password-view" onClick={() => setVisibility({ ...visibility, confirmPassword: !visibility.confirmPassword })}>{visibility.confirmPassword ? <EyeIcon /> : <EyeSlashIcon />}</span>
        </FormGroup>
        {
            passwordValidation() && 
            <OtherInfo>
                <ErrorMessage>{passwordValidation()}</ErrorMessage>
            </OtherInfo>
        }
        {
            showRecaptcha && 
            <RecaptchaWrapper>
                <Recaptcha
                    verifyCallback={(ev: any) => setRecaptcha(ev)}
                    ref={e => (recaptchaInstance = e)}
                    innerRef={(e: any) => recaptchaInstance = e}
                    sitekey={process.env.RECAPTCHA_KEY || '6Lf6hU8UAAAAAGNxud_6MqSz3W5kq9c_0hIyHSuE'}
                />
            </RecaptchaWrapper>
        }
        {
            errorMessage &&
            <OtherInfo>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </OtherInfo>
        }

        <SubmitButton
            className="button primary"
            disabled={submitting || _isInputGood()}
            value={"Submit"}
            type="submit"
        />
    </LoginForm>
  )
}

export default Form