import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { IoCaretDown } from '@react-icons/all-files/io5/IoCaretDown'
import { IoCaretForward } from '@react-icons/all-files/io5/IoCaretForward'

import { Table as StyledTable } from '../../components/Elements'
import { defaultColors, ThemingStore } from '../../stores/ThemingStore'

interface Props {
    headers: any[],
    data: any,
    onClick?: (g: any) => void
    expandingRowClick?: (g: any) => void
    emptyCellString?: string
    expandingData?: any,
    parentKey?: string
}
interface State {
    color_scheme: string,
    openedParent: null | string | number
}

export default class Table extends Reflux.Component<typeof ThemingStore, Props, State> {
    constructor(props: Props) {
        super(props)

        this.store = ThemingStore
        this.storeKeys = ['color_scheme']
        this.state = {
            color_scheme: 'Light',
            openedParent: null
        }
    }

    handleExpandRow(id: string | number, e: any) {
        e.stopPropagation();
        this.setState((prevState) => ({
            ...prevState,
            openedParent: prevState.openedParent === id ? null : id
        }))
    }

    render() {
        const { headers, data, emptyCellString = '', onClick = () => { }, expandingRowClick = () => { }, expandingData, parentKey } = this.props
        const color_scheme = defaultColors[this.state.color_scheme]
        return (
            <TableWrapper>
                <CStyledTable {...color_scheme} color_scheme={this.state.color_scheme}> 
                        <tr className="topbar-secondary">
                            {
                                headers.map((p, key) => {
                                    return <th className="topbar-secondary" key={key}><div>{p.label}</div></th>
                                })
                            }
                        </tr> 
                    <tbody>
                        {
                            data.map((instance: any, index: number) => {
                                const child = expandingData && parentKey ? expandingData[instance[parentKey as any]] : null
                                return (
                                    <React.Fragment key={index}>
                                        {/* <tr className="separator"></tr> */}
                                        <tr key={index} className="container" onClick={() => onClick(instance)}>
                                            {
                                                headers.map((p, key) => {
                                                    if (!!!key && child) {
                                                        return <td key={key} style={{ width: p.width || 'auto' }} title={!!!p.row ? instance[p.value] : emptyCellString}>
                                                            {
                                                                p.row ?
                                                                    <div className="flex-container">
                                                                        {instance[parentKey as any] === this.state.openedParent ?
                                                                            <IoCaretDown className="toggle-btn" onClick={(e: any) => this.handleExpandRow(instance[parentKey as any], e)} /> :
                                                                            <IoCaretForward className="toggle-btn" onClick={(e: any) => this.handleExpandRow(instance[parentKey as any], e)} />}
                                                                        <span className="td-title">{p.label ? `${p.label}: ` : ''}</span>{p.row(instance)}
                                                                    </div>
                                                                    : <div>
                                                                        {instance[parentKey as any] === this.state.openedParent ?
                                                                            <IoCaretDown className="toggle-btn" onClick={(e: any) => this.handleExpandRow(instance[parentKey as any], e)} /> :
                                                                            <IoCaretForward className="toggle-btn" onClick={(e: any) => this.handleExpandRow(instance[parentKey as any], e)} />}
                                                                        <span className="td-title">{p.label}:</span>{instance[p.value] || emptyCellString}
                                                                    </div>
                                                            }
                                                        </td>
                                                    }
                                                    return (
                                                        <td key={key} style={{ width: p.width || 'auto' }} title={!!!p.row ? instance[p.value] : emptyCellString}>
                                                            {
                                                                p.row ?
                                                                    <div className="flex-container">
                                                                        <span className="td-title">{p.label ? `${p.label}: ` : ''}</span>{p.row(instance)}
                                                                    </div>
                                                                    : <div>
                                                                        <span className="td-title">{p.label}:</span>{instance[p.value] || emptyCellString}
                                                                    </div>
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {
                                            (child || []).map((c: any, index: number) => {
                                                return <tr key={index} className={instance[parentKey as any] === this.state.openedParent ? `expanded` : 'collapsed'} onClick={() => expandingRowClick(c)}>
                                                    {
                                                        headers.map((p, key) => {
                                                            return (
                                                                <td key={key} style={{ width: p.width || 'auto' }} title={!!!p.row ? c[p.value] : emptyCellString}>
                                                                    {
                                                                        p.row ?
                                                                            <div className="flex-container">
                                                                                <span className="td-title">{p.label ? `${p.label}: ` : ''}</span>{p.row(c)}
                                                                            </div>
                                                                            : <div>
                                                                                <span className="td-title">{p.label}:</span>{c[p.value] || emptyCellString}
                                                                            </div>
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            })
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </CStyledTable>
                {data?.length === 0 ? <NoDataContainer>No Records Found</NoDataContainer> : null}
            </TableWrapper>
        )
    }
}

const TableWrapper = styled.div`    
    margin-top: 50px;
    max-height: 100%;
    overflow: auto;

    .expanded {
    }

    .collapsed {
        display: none;
    }

    th {
        align-self: center;
        text-align: center;
    }
    
    tr {
        border: 1px solid #C4C4C4;
    }
`

const NoDataContainer = styled.div`
    align-self: center;
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
`
const CStyledTable = styled(StyledTable)`
`