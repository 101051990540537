import React, { Component } from 'react'
import styled from 'styled-components'
import { FaCaretDown as CaretDown } from '@react-icons/all-files/fa/FaCaretDown'

import LoopApi from '../../helpers/LoopApi'
import { Avatar, AButton } from '../Slack/SharedStyles'
import { Dropdown, ActionButton, WidgetContainer, WidgetContent, WidgetContentItem, WidgetContentItemName, WidgetRightContainer } from '../_Shared/Elements'

import Logo from '../../assets/logo.svg'
import { useContextMenu } from 'react-contexify'

interface Props {
    token: string
    membersList: IMember[]
    dms: any[]
    users: any
    handleUpdates: (obj: object) => void
    pokeNow: (obj: object, e: any) => void
    isHost: boolean | undefined
    toggleVisibility: () => void
    selected: string | null
}

interface IMember {
    name: string,
    id: string
    avatar_url: string
    displayName?: string
}

interface State {
    collapsed: boolean
}

export default class DMList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            collapsed: false
        }

        this.bindSlackMemberToUser = this.bindSlackMemberToUser.bind(this)
        this.unbindSlackMemberToUser = this.unbindSlackMemberToUser.bind(this)
    }

    bindSlackMemberToUser(member: IMember, e: any) {
        const id = e.target.value
        const user = this.props.users.find((u: any) => u.id === id)
        LoopApi('main', 'UpdateUser', {
            id,
            changes: {
                name: member.name,
                slack_id: member.id,
                avatar_url: member.avatar_url,
            },
        })
    }

    unbindSlackMemberToUser(member: IMember, id: string, e: any) {
        e.stopPropagation()

        LoopApi('main', 'UpdateUser', {
            id,
            changes: {
                name: member.name,
                slack_id: null,
                avatar_url: member.avatar_url,
            },
        })
    }

    handleClick(data: IMember, e: any) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()

        this.props.isHost && this.props.handleUpdates({ selected: data, messages: [] })

    }

    toggleCollapsible = () => {
        this.setState((prevState) => ({
            collapsed: !this.state.collapsed
        }))
    }


    render() {
        if (!!!this.props.membersList.length) {
            return null
        }

        const { show: showMember } = useContextMenu({
            id: 'slack-member'
        });

        const displayMenu = (e: any) => {
            const { c_data, c_id, is_member } = e?.currentTarget?.dataset
    
            if(is_member === 'true') {
                showMember(e, { props: { data: c_data, id: c_id } })
            } 
        }

        const members = this.props.dms.map(d => {
            const filteredUser = this.props.membersList.filter(m => m.id === d.user)
            const user = filteredUser && filteredUser.length ? filteredUser[0] : null

            if (!user) {
                return
            }
            const bindedUser = this.props.users.filter((u: any) => u.slack_id === d.id)
            const isSelected = !!this.props.selected && this.props.selected === d.id ? ' topbar slack-text' : '';

            return (
                <CWidgetContentItem
                    small
                    key={d.id}
                    onClick={(e) => { this.handleClick(d, e); this.props.toggleVisibility()}}
                    className={this.props.isHost ? `cursor-pointer${isSelected}` : `cursor-default${isSelected}`}
                    style={{padding: '7px 25px 7px 32px', marginLeft: '0px', }}
                    data-is_member={"true"}
                    data-c_data={JSON.stringify(d)}
                    data-c_id={d.id}
                    onContextMenu={displayMenu}
                >
                    <Avatar src={user.avatar_url} style={{marginRight: '10px'}}/>
                    <WidgetContentItemName title={user?.displayName || user?.name || 'User'} style={{wordBreak: 'break-word', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: '5px'}}>
                        {user?.displayName || user.name}
                        {bindedUser && bindedUser.length > 0 && <div className="binded-info"><img src={Logo} alt="Blokctalk" width="13" /> {bindedUser[0].name}</div>}
                    </WidgetContentItemName>
                    {/* <WidgetRightContainer>
                        {
                            this.props.isHost && <React.Fragment>
                                <AButton className="button primary" onClick={e => this.props.pokeNow(d, e)}>
                                    INVITE
                                </AButton> */}
                                {/* {
                                    bindedUser && bindedUser.length > 0 ?
                                        <ActionButton onClick={e => this.unbindSlackMemberToUser(d, bindedUser[0].id, e)}>
                                            Unbind user
                                        </ActionButton> :
                                        <Dropdown
                                            defaultValue=""
                                            onClick={e => e.stopPropagation()}
                                            onChange={e => this.bindSlackMemberToUser(d, e)}
                                        >
                                            <option value="" disabled hidden>
                                                Bind To User
                                            </option>
                                            {this.props.users.filter((u: any) => !u.slack_id).length === 0 && <option value="hi" disabled>No more users</option>}
                                            {this.props.users.filter((u: any) => !u.slack_id).map((u: IMember, ii: number) => (
                                                <option key={`bindtouser-${u.name}-${ii}`} value={u.id}>
                                                    {u.name}
                                                </option>
                                            ))}
                                        </Dropdown>
                                } */}
                            {/* </React.Fragment>
                        }
                    </WidgetRightContainer> */}
                </CWidgetContentItem>
            )
        })

        return (
            <WidgetContainer>
                <WidgetContent padding="0 0 14px">
                    <Title onClick={this.toggleCollapsible} collapsed={this.state.collapsed}>
                        <CaretDown className="arrow-collapse" /> &nbsp;Members
                    </Title>
                    <ChannelsWrapper collapsed={this.state.collapsed}>{members}</ChannelsWrapper>
                </WidgetContent>
            </WidgetContainer>
        )
    }
}

const Title = styled.h4<{ collapsed: boolean }>`
	margin: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .arrow-collapse {
        transition: transform 0.3s ease-out;
        ${props => props.collapsed && `
            transform: rotate(-90deg);
        `}
    }
`

const Name = styled.div`
	flex: 1;
    display: flex;
    align-items: center;

    .binded-info {
        margin-left: 20px;
        font-size: 12px;
    }
`

const CWidgetContentItem = styled(WidgetContentItem)`
    /* padding: 7px 0px 7px 10px;
    margin-left: 20px; */
    border-radius: 0px;
`


const ChannelsWrapper = styled.div<{ collapsed: boolean }>`
    max-height: auto;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    ${props => props.collapsed && `
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    `}

`
