import Color from "color";
import styled from "styled-components";

import bg from '../../assets/backgrounds/LandingBG.png'

export const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    background: url(${bg}) no-repeat center 50% fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`

export const LoginForm = styled.form`
    background-color: white;
    padding: 30px 40px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    max-width: 400px;
    width: 100%;
    min-height: 240px;
    position: relative;
`

export const Title = styled.div<{center?: boolean}>`
    color: #00205b;
    font-weight: 600;
    font-size: 20px;

    ${props => props.center && 'text-align: center;'}
`

export const SubTitle = styled.div`
    margin: 15px 0;
    text-align: center;
    opacity: 0.7;
`

export const InputLabel = styled.label`
    /* position: absolute;
    left: 15px;
    top: 11px; */
    color: #999;
    z-index: 10;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
`

export const FormGroup = styled.div`
    position:relative;  
    margin-top: 30px;

    & + .form-group {
        margin-top: 10px;
    }

    &.focused ${InputLabel} {
        transform:  translate(-15%,-235%);
        font-size: .9em;
        margin-bottom: 10px;
    }

    .password-view {
        position: absolute;
        bottom: 15px;
        right: 10px;
        cursor: pointer;
    }
`

export const Input = styled.input`
    position: relative;
    padding: 12px 0px 5px 0;
    color: #363B45;
    width: calc(100% - 30px);
    outline: 0;
    border-radius: 30px;
    padding: 10px 15px;
    transition: box-shadow 150ms ease-out;
    border: 1px solid #E9ECEF; 
    margin-top: 7px;
    margin-bottom: 5px;

    &::placeholder {
        color: ${() => Color('#363B45').alpha(0.5).toString()};
    }

    &.form-password {
        padding-right: 40px;
        width: calc(100% - 55px);
    }
`

export const SubmitButton = styled.input`
    margin-top: 20px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
    border-radius: 999px;
    border: none;
    font-size: 14px;
    color: white;
    padding: 12px;
    cursor: pointer;
    font-weight: 400;
    width: 100%;

    &:disabled {
        opacity: 0.5;
        cursor: initial;
        pointer-events: none;
    }

    &:hover {
        opacity: 0.9;
    }
`

export const MagicLinkButton = styled.button`
    margin-bottom: 10px;
    background: #008BFF;
    border-radius: 999px;
    border: none;
    font-size: 14px;
    color: white;
    padding: 12px;
    cursor: pointer;
    font-weight: 400;
    width: 100%;

    &:disabled {
        opacity: 0.5;
        cursor: initial;
        pointer-events: none;
    }

    &:hover {
        opacity: 0.9;
    }
`

export const LinkButton = styled.div`
    color: #008BFF;
    margin: 20px auto 0;
    font-weight: 400;
    width: max-content;
    width: fit-content;
    text-align: center;
    cursor: pointer;
`

export const Content = styled.div`
    line-height: 18px;
    margin-top: 20px;
    font-weight: 400;

    .emphasis {
        color: #008BFF;
        font-weight: 600;
    }
`

export const BackButton = styled.button`
    border: none;
    background: none;
    outline: none;
    padding: 0;
    font-size: 24px;
    vertical-align: middle;
    margin-right: 10px;
`

export const OtherInfo = styled.div`
    margin-top: 20px;
    text-align: center;
    color: #FF3C3C;    
`

export const ErrorMessage = styled.div``

export const LogoIcon = styled.img`
    height: 105px;
`

export const LogoWrapper = styled.div`
    text-align: center;
    margin-bottom: 20px;
`