import React, { Fragment } from 'react'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import styled from 'styled-components'

interface Props {
  isGrid: boolean
  id: any
  children: any
  options?: any[]
  isLight: boolean
}

const Context = (props: Props) => {
  const { isGrid, id, options, isLight } = props

  const handleContextMenuClick = (e: any, data: any) => {
    data.onItemClick()
    e.stopPropagation()
  }

  return (
    <Fragment>
      {!isGrid && options?.length ?
        <Fragment>
          <ContextMenuTrigger id={id || ""}>
            {props.children}
          </ContextMenuTrigger>
          <StyledContextMenu id={id || ""} isLight={isLight}>
            {options.map((option) => {
            return (
              <StyledMenuItem
                data={option}
                onClick={handleContextMenuClick}
              >
                {/* <option.icon className="todo-dropmenu-svg" /> {option.text} */}
                <div>
                  <div className="first-row-gd"><option.icon /> <span className="text">{option.text}</span></div>
                  {!!option.subtext ? <div className="subtext">{option.subtext}</div> : ''}
                </div>
              </StyledMenuItem>
            )
          })}
          </StyledContextMenu>
        </Fragment> :
        <Fragment>{props.children}</Fragment>
      }
    </Fragment>
  )
}

export default Context

const StyledMenuItem = styled(MenuItem)`
  padding: 10px;
  display: flex;
  align-items: center;
  transition: background 0.3s;

  & svg {
    margin-right: 8px;
  }

  &:hover {
    background: #e7e7e7;
  }

  .text {
    font-size: 16px;
  }

  .subtext {
    font-size: 7px;
    margin-top: 3px;
    color: #929292;
    margin-left: 28px;
  }

	.first-row-gd {
		display: flex;
    align-items: center;
	}
`

const StyledContextMenu = styled(ContextMenu)<{ isLight: boolean}>`
  min-width: 130px;
  background-color: ${({isLight}) => isLight ? '#fff' : '#181820'};
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 0;
  padding-bottom: 0;


  ${StyledMenuItem} {
    ${({isLight}) => isLight ?
      `
        color: #000;
        &:hover {
          background: #e7e7e7;
        }
        & svg {
          fill: #000;
        }
      `
      :
      `
        color: #fff;
        &:hover {
          background: #2b2b39;
        }
        & svg {
          fill: #fff;
        }
      `
    }
  }
`

