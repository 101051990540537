import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from '../../components/Modalv2'
import TextareaAutosize from 'react-autosize-textarea'

interface Props {
  closeModal: () => void
  actionModal: (a?: any) => void
  editItem?: any
}

interface Errors {
  URL?: string []
  description?: string []
}

// https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

const LinkAdd = (props: Props) => {
  const {closeModal, actionModal, editItem} = props
  const [URL, setURL] = useState('')
  const [is_valid, setIs_Valid] = useState(true)
  const [errors, setErrors] = useState<Errors>({})

  const checkErrors = (e: any) => {
    const {name, value} = e.target

    switch(name) {
      case 'URL':
        if(!!!value) {
          setErrors({
            ...errors,
            URL: ['URL is required', ...errors.URL ? errors.URL : []]
          })
        } else {
          if(errors.URL) {
            const {URL, ...otherErrors} = errors
            setErrors({
              ...otherErrors
            })
          }
        }
      break
    }
  }

  const handleChange = (e: any) => {
    const url = e.target.value
    const is_valid = url.length === 0 || urlRegex.test(url)

    setURL(url)
    setIs_Valid(is_valid)
    checkErrors(e)
  }

  return (
    <Modal {...props}>
      <Container className="topbar rounded inner">
        <Title>Add Link</Title>
        <Field>
          <Label>URL</Label>
          <Input
            className="textarea hover-container border-light"
            value={URL || ''}
            name="URL"
            placeholder="Link URL"
            onChange={(e: any) => {
              handleChange(e)
            }}
          />
          {errors?.URL && errors.URL.map((err) => {
            return <ErrorMessage>{err}</ErrorMessage>
          })}
        </Field>
        <ButtonCont>
            <CloseBtn 
              className="button default"
              onClick={(e) => {
                e.preventDefault()
                closeModal()
              }}
            >
              Cancel
            </CloseBtn>
            <ActionBtn 
              className="button primary"
              onClick={(e) => {
                e.preventDefault()
                actionModal({url: URL, is_valid})
                closeModal()
              }}
              disabled={!!Object.values(errors).length || !!!is_valid}
            > Save
            </ActionBtn>
        </ButtonCont>
      </Container>
    </Modal>
  )
}

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;
`

const ButtonCont = styled.div` 
  display: flex;
  width: 100%;
  padding: 0 12px;
  align-items: center;
  margin-top: 10px;
`
const ActionBtn = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 14px;
  margin: 4px;
  white-space: nowrap;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
const CloseBtn = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 4px;
  white-space: nowrap;
`

const Label = styled.div`
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
`

const Input = styled.input`
  outline: none;
  width: 15rem;
  font-size: 12px !important;
  border-radius: 99px !important;
`

const TextArea = styled(TextareaAutosize)`
  border-radius: 8px !important;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px !important;
`

const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`

const ErrorMessage = styled.p`
    color: red;
    margin: 8px 0 8px 8px;
    font-size: 0.8em;
`

export default LinkAdd;