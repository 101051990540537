import React, { useRef, useEffect, memo } from "react";
import styled from 'styled-components'

interface Props {
  isLocalParticipant?: boolean
  track: any
}

const VideoTrack = (props: Props) => {
  const { track, isLocalParticipant } = props
  const trackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      trackRef &&
      trackRef.current &&
      track &&
      track.attach
    ) {
      const child = track.attach();
      trackRef.current.classList.add(track.kind);
      trackRef.current.appendChild(child);
    }

    return () => {
      if (!isLocalParticipant && track.stop) {
        track.stop()
      }
      if (track.detach) {
        track.detach()
      }
    }
  }, []);

  return (
    <Vid className="video_track_container">
      <div ref={trackRef}></div>
    </Vid>
    
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
}

export default memo(VideoTrack, areEqual);

const Vid = styled.div`
  height: 100%;
  width: 100%;

  .video {
    height: 100%;
    width: 100%;

    video {
      height: 100%;
      width: 100%;
      border-radius: 6px;
      transform: scaleX(-1);
      object-fit: contain;
    }
  }
`;