import React, { useMemo } from 'react'
import {Participant, Track, TrackPublication} from 'twilio-video'
import Publication from './Publication'
import usePublications from '../../hooks/usePublications'
import { DailyParticipant, DailyTrackState } from '@daily-co/daily-js'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { CallingProviderName } from '../../../../../../contexts/types'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'

interface ParticipantTracksProps {
  participant: Participant | DailyParticipant
  videoOnly?: boolean
  enableScreenShare?: boolean
  videoPriority?: Track.Priority | null
  isLocalParticipant?: boolean
  notScreenshareTile?: boolean
  filterScreenshare?: boolean
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
  filterScreenshare
}: ParticipantTracksProps) {
  const publications = usePublications(participant)
  const { callProvider, screenshareId } = useMeetingContext()
  const screenShareParticipant = useScreenShareParticipant()

  let filteredPublications = publications

  if (
    enableScreenShare &&
    publications.some((p) => ((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen'))
    // publications.some((p) => ((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen'))
  ) {
    // When displaying a screenshare track is allowed, and a screen share track exists,
    // remove all video tracks without the name 'screen'.
    filteredPublications = ((publications || []) as any).filter(
      (p: TrackPublication | MediaStreamTrack) => ((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen') || p?.kind !== 'video')

  } else {
    // Else, remove all screenshare tracks
    filteredPublications = (publications as any).filter(
      (p: TrackPublication | MediaStreamTrack) => !((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen'),
    )
  }

  const isDaily = callProvider === CallingProviderName.DailyCo
  // DAILY CHECKING
  if(isDaily && filterScreenshare && screenshareId) {
    filteredPublications = (filteredPublications as any).filter(
      (p: MediaStreamTrack) => p?.id !== screenshareId
    )
  }
  if(
    isDaily && enableScreenShare && screenshareId && 
    filteredPublications?.some((p) => ((p as MediaStreamTrack)?.id === screenshareId))
  ) {
    filteredPublications = (filteredPublications as any).filter((p: MediaStreamTrack) => p?.id === screenshareId || p.kind !== 'video')
  }
  if(isDaily && !!!enableScreenShare && !!!filterScreenshare && (screenShareParticipant as DailyParticipant)?.local) {
    filteredPublications = (filteredPublications as any).filter((p: MediaStreamTrack) => p?.id !== screenshareId)
  }

    
  return useMemo(() => (
    <>
      {filteredPublications.map((publication: any) => {
        if(!publication) return null
        return <Publication
          key={publication?.trackName || `${(participant as DailyParticipant)?.user_name || (participant as DailyParticipant)?.user_id}:${publication?.kind}:${publication?.id}:${publication?.label}`}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      })}
    </>
  ), [filteredPublications, videoOnly, videoPriority])
}
