import React, {useState, useEffect, useRef} from 'react'
import { useResizeDetector } from 'react-resize-detector';
import Draggable from 'react-draggable'

const DraggableBoundary = ({children}: any) => {
  const boundary = useRef(null)
  const draggableItem = useRef(null)
  const [isControlled, setIsControlled] = useState(false)
  const [position, setPosition] = useState({x: 0, y: 0})
  const [hasInitialized, setHasInitialized] = useState(false)
  const { width: boundaryWidth, height: boundaryHeight } = useResizeDetector({ targetRef: boundary });

  useEffect(() => {
    if (boundary.current) {
      setTimeout(() => {
        const BOUNDARY_WIDTH: any = (boundary?.current as any)?.clientWidth || 0
        const BOUNDARY_HEIGHT: any =
          (boundary?.current as any)?.clientHeight || 0
        const ITEM_WIDTH: any =
          (draggableItem?.current as any)?.clientWidth || 0
        const ITEM_HEIGHT: any =
          (draggableItem?.current as any)?.clientHeight || 0

        const newX = BOUNDARY_WIDTH - ITEM_WIDTH - 10
        const newY = BOUNDARY_HEIGHT - ITEM_HEIGHT - 10

        setPosition({x: newX, y: newY})
        setHasInitialized(true)
      }, 500)
    }
  }, [])

  useEffect(() => {
    function handleResize() {
      const BOUNDARY_WIDTH: any = (boundary?.current as any)?.clientWidth || 0
      const BOUNDARY_HEIGHT: any = (boundary?.current as any)?.clientHeight || 0
      const ITEM_WIDTH: any = (draggableItem?.current as any)?.clientWidth || 0
      const ITEM_HEIGHT: any =
        (draggableItem?.current as any)?.clientHeight || 0

      const newX = BOUNDARY_WIDTH - ITEM_WIDTH - 10
      const newY = BOUNDARY_HEIGHT - ITEM_HEIGHT - 10

      setPosition({x: newX, y: newY})
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // handleResize();
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleStart = () => {
    setIsControlled(false)
  }

  const handleDrag = (e: any, data: any) => {
    setPosition({x: data.x, y: data.y})
  }

  const handleStop = () => {
    setIsControlled(true)

    const BOUNDARY_WIDTH: any = (boundary?.current as any)?.clientWidth || 0
    const BOUNDARY_HEIGHT: any = (boundary?.current as any)?.clientHeight || 0
    const ITEM_WIDTH: any = (draggableItem?.current as any)?.clientWidth || 0
    const ITEM_HEIGHT: any = (draggableItem?.current as any)?.clientHeight || 0

    if (boundary.current) {
      const newX =
        position.x + ITEM_WIDTH * 0.5 > BOUNDARY_WIDTH * 0.5
          ? BOUNDARY_WIDTH - ITEM_WIDTH - 10
          : 10
      const newY =
        position.y + ITEM_HEIGHT * 0.5 > BOUNDARY_HEIGHT * 0.5
          ? BOUNDARY_HEIGHT - ITEM_HEIGHT - 10
          : 10

      // Check where to SNAP
      setPosition({x: newX, y: newY})
    } else {
      setPosition({x: 10, y: 10})
    }
  }

  useEffect(() => {
    const BOUNDARY_WIDTH: any = (boundary?.current as any)?.clientWidth || 0
    const BOUNDARY_HEIGHT: any = (boundary?.current as any)?.clientHeight || 0
    const ITEM_WIDTH: any = (draggableItem?.current as any)?.clientWidth || 0
    const ITEM_HEIGHT: any = (draggableItem?.current as any)?.clientHeight || 0
    
    const newX = position.x > BOUNDARY_WIDTH ? position.x - ITEM_WIDTH - 10 : position.x < (BOUNDARY_WIDTH / 2) ? position.x : BOUNDARY_WIDTH - ITEM_WIDTH - 10
    const newY = position.y > BOUNDARY_HEIGHT ? position.y - ITEM_HEIGHT - 10 : position.y < (BOUNDARY_HEIGHT / 2) ? position.y : BOUNDARY_HEIGHT - ITEM_HEIGHT - 10

    setPosition({x: newX, y: newY})

  }, [boundaryWidth, boundaryHeight])


  return (
    <div id="draggable-boundary" ref={boundary}>
      <Draggable
        bounds="parent"
        position={position}
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
      >
        <div
          id="draggable-participant"
          ref={draggableItem}
          style={{
            transition: isControlled ? `transform 0.3s` : `none`,
          }}
        >
          {children}
        </div>
      </Draggable>
    </div>
  )
}

export default DraggableBoundary
