import React, { Component } from 'react'
import Reflux, { GlobalState } from 'reflux'
import styled from 'styled-components'

import LoopApi from '/helpers/LoopApi'
import Modal from '/components/Modalv2'
import { ModalStore } from '../../../stores/ModalStore'
import { WidgetActions, MainStore } from '../../../stores/MainStore'
import useToastify from '../../../helpers/useToastify'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'

export default class LeaveMeeting extends Reflux.Component {
	constructor(props) {
		super(props)

		this.stores = [ModalStore, MainStore]
		this.storeKeys = ['meetingId', 'meeting_name', 'meetingName', 'cb', 'presentation', 'widgets']

		this.Cancel = this.Cancel.bind(this)
		this.Leave = this.Leave.bind(this)
	}

	Cancel() {
		this.props.closeModal()
	}

	Leave() {
		const uid = GlobalState.auth.jwt.data._id
		const guest = GlobalState.auth.jwt.data.type === 'guest'

		const mainLeaveFunc = async() => {
			const resp = await LoopApi(null, 'LeaveMeeting', {
				user_id: uid,
				guest,
				meetingId: this.state.meetingId
			})
	
			if (!resp.error) {
				useToastify({
					message: () => SuccessErrorToast({ message: `Room has been sucessfully ${(GlobalState.modal.modallabel || 'remove').toLowerCase()}d`, type: 'success'  }),
					position: "top-right",
					autoClose: 2000,
					closeButton: false,
					hideProgressBar: true,
					className: GlobalState.theming.color_scheme === 'Light' ? 'toastL' : 'toastD',
					bodyClassName: "grow-font-size",
				})
				if(this.state.meetingId === this.state.meetingName) {
					this.props.history.push('/create-or-join')
					window.location.reload()
				} else if(this.state.cb) {
					this.state.cb()
				}
			}
	
			this.props.closeModal()
		}

		let tokens = {}
		const session = sessionStorage.getItem('external_tokens')

		if(session)
		tokens = JSON.parse(session)

		if(
			this.state.presentation?.googledrive === 'presenting' &&
			!!this.state.widgets?.googledrive?.file &&
			session &&
			!!tokens?.googledrive &&
			this.state.meetingId === this.state.meetingName
		) {
			WidgetActions.GoogleCheckPresenting(tokens.googledrive, mainLeaveFunc)
		} else {
			mainLeaveFunc()
		}
	}

	render() {
        const user = GlobalState.auth.jwt.data;
        const meeting = GlobalState.main.db_meeting;

		console.log(meeting.creator_user_id, user._id, GlobalState?.modal?.modallabel)
		return (
			<Modal closeModal={this.props.closeModal}>
				<Container >
					<Contents>
						<Title className="header">{GlobalState?.modal?.modallabel ? GlobalState?.modal?.modallabel : meeting.creator_user_id === user._id ? 'Archive' : 'Remove'} Room</Title>
						<Details className="subheader">Are you sure you want to {GlobalState?.modal?.modallabel ? (GlobalState?.modal?.modallabel || '')?.toLowerCase() : meeting.creator_user_id === user._id ? 'archive' : 'remove'} this room <br /><span className="color-primary" style={{ fontWeight: 500 }}>{`${this.state.meeting_name} - Room ID: ${this.state.meetingId}`}</span>?<br /> If yes, you will also leave this room. </Details>
					</Contents>

					<Options>
						<Button className="button default" onClick={this.Cancel}>No</Button>
						<Button className="button primary" onClick={this.Leave}>Yes</Button>
					</Options>
				</Container>
			</Modal>
		)
	}
}

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 420px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
