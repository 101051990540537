import styled from "styled-components"
import React, {Fragment} from 'react'
import { RiRecordCircleLine as RecordingIcon } from '@react-icons/all-files/ri/RiRecordCircleLine'
import { FiMicOff as MicOff } from '@react-icons/all-files/fi/FiMicOff'

function ToggleRecording ({ isHost, user, onoff }: { isHost?: boolean , user?: any, onoff?: boolean}) {
    return  <Fragment>
        <HostPermission>
            <div className="icon color-primary bg-primary-opaque"><RecordingIcon /></div>
            <HostPermissionContent>
                <HostPermissionMessage>
                    <div>
                        {
                            isHost ? 
                            <React.Fragment>
                                <span className="name color-primary">You</span> { onoff ? 'started' : 'stopped' } the recording.
                            </React.Fragment> :
                            <React.Fragment>
                                <span className="name color-primary">{user?.name || user?.username}</span> { onoff ? 'started' : 'stopped' } the recording.
                            </React.Fragment>
                        }
                    </div>
                </HostPermissionMessage>
            </HostPermissionContent>
        </HostPermission>
    </Fragment>
}

export default ToggleRecording;

export const HostButton = styled.div`
	padding: 10px 15px;
    background: red;
    border-radius: 30px;
    color: white;
	flex: 1;
	text-align: center;
    /* flex: 1;
    margin: 0 7.5px 0 15px; */
`
export const HostPermissionContent = styled.div`
	display: flex;    
	justify-content: center;
	flex-direction: column;
    flex: 1;
`

export const HostPermissionMessage = styled.div`
	height: 30px;
    display: flex;
    align-items: center;
`

export const HostPermissionButtons = styled.div`
	display: flex;
	justify-content: space-between;
    width: 100%;
	margin-top: 5px;

	.left {
		margin-right: 5px;
	}

	.right {
		margin-left: 5px;
	}

	.name {
		font-weight: 500;
	}
`

export const HostPermission = styled.div`
	display: flex;
	font-weight: 400;

	.icon {
		width: 30px;
		height: 30px;
		padding: 5px;
		border-radius: 30px;
		margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
	}

`