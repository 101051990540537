import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { FiSettings as SettingsIcon } from '@react-icons/all-files/fi/FiSettings'
import { FiBell as NotificationIcon } from '@react-icons/all-files/fi/FiBell'
import ReactToolTip from "react-tooltip"
// import { GiTimeTrap as TimeTravelIcon } from '@react-icons/all-files/gi/GiTimeTrap'

import DropdownWrapper from '/screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import NotificationsList from './NotificationsList'
import { ModalActions } from '../../../../stores/ModalStore'
// import UserDropdown from './UserDropdown'

import { NotificationActions, NotificationStore } from '../../../../stores/NotificationStore'
import { MainStore } from '../../../../stores/MainStore'
import { AuthStore } from '../../../../stores/AuthStore'
import CustomTip from '../../../../helpers/CustomTip'
import LoopApi from '../../../../helpers/LoopApi'

// Calender
import { FiCalendar as CalendarIcon } from '@react-icons/all-files/fi/FiCalendar'
import CalendarCanvass from '../../../Calendar/CalendarCanvass'


class KeynoteSidebarDropdowns extends Reflux.Component {

	constructor(props) {
		super(props)
		this.stores = [NotificationStore, MainStore, AuthStore]
		this.storeKeys = ['notifications', 'users', 'jwt', 'db_meeting', 'nextNotification']

        this.state = {
            limits: {},

						// Calendar
						calendarOpen: false
        }
	}

	onClickNotification = () => {
		if (this.props.userBadges.count > 0) {
			NotificationActions.BadgeClick()
		}
	}

	onGetMoreNotifications = () => NotificationActions.GetMoreNotifications()

	componentDidUpdate() {
		ReactToolTip.rebuild()
	}

    componentDidMount() {
        this.getRoomOwnerLimits()
    }

    getRoomOwnerLimits = async () => {
		try {
			if(!!!localStorage.getItem('token')) {
			  throw new Error('invalid token')
			}
			const limits = await LoopApi(null, 'StripeGetRoomOwnerLimits')
			this.setState({ limits })
		} catch(err) {
			//console.log("error: ", err)
			if(err === 'invalid token') {
				localStorage.removeItem('token')
				if(localStorage.getItem('audio_input_device_id')) {
					localStorage.removeItem('audio_input_device_id')
				}
				if(localStorage.getItem('video_input_device_id')) {
					localStorage.removeItem('video_input_device_id')
				}
				if(localStorage.getItem('audio_output_device_id')) {
					localStorage.removeItem('audio_output_device_id')
				}

				this.props.history.push(`/login`)
			}
		}
    }

	render() {
		const { isMobile, isOpen } = this.props
        const isTimeTravelDisabled = this.state.limits.timetravel === 0 ? true : false
		return (
            <Container isOpen={isOpen}>
                <div
                    className='time-travel'
                    style={{ opacity: isTimeTravelDisabled ? 0.3 : 1, cursor: isTimeTravelDisabled ? 'not-allowed' : 'pointer' }}
                    onClick={() => {
                        if(isTimeTravelDisabled) return
                        window.open(`/time-travel/${this.props.meetingName}`, '_blank')
                    }
                    }
                >
                    <Plus className='button default time-travel-icon' data-tip data-for='time-travel'>
                        <TimeTravelIcon className='background-button fill-white' size={22} />
                    </Plus>
                </div>
                <Dropdown
                    className='notifications'
                    width='320px'
                    DropdownItems={NotificationsList}
                    noright
                    noRightMargin={this.state.db_meeting.creator_user_id === this.state.jwt.data._id}
                    data={this.state}
                    onClickEvent={this.onClickNotification}
                    getMoreNotifications={this.onGetMoreNotifications}
                >
                    <Plus className='button default' data-tip data-for='notif'>
                        <NotificationIcon className='background-button' size={18} />
                    </Plus>
                    <CustomTip
                        top={-40}
                        left={-25}
                        class='top-right'
                        place='left'
                        tip='Notifications'
                        dataFor='notif'
                    />

                    {(this.props.userBadges && parseInt(this.props.userBadges.count) > 0 && (
                        <BadgeWrapper>
                            <Badge>{this.props.userBadges.count}</Badge>
                        </BadgeWrapper>
                    )) ||
                        null}
                </Dropdown>
                {this.state.db_meeting.creator_user_id === this.state.jwt.data._id && (
                    <div className='ws-settings' onClick={() => ModalActions.SetModal('ChangeSettings')}>
                        <Plus className='button default' data-tip data-for='workspace-settings'>
                            <SettingsIcon className='background-button' size={18} />
                        </Plus>
                    </div>
                )}
                {/* <Dropdown width="240px" DropdownItems={WorkspaceDropdown} noright >
				</Dropdown> */}
                {/* <Dropdown className="button" DropdownItems={PresentationDropdown} left>
					Presentation <DownCaret />
				</Dropdown> */}
                {!isMobile && (
                    <React.Fragment>
                        <ReactToolTip id='user-count' place='bottom'>
                            <span>Users: +{this.props.users.length >= 100 ? 100 : this.props.users.length}</span>
                        </ReactToolTip>

                        <CustomTip
                            top={-40}
                            left={-25}
                            class='top-right'
                            place='left'
                            tip='Room Settings'
                            dataFor='workspace-settings'
                        />

                        <CustomTip
                            top={-40}
                            left={-25}
                            class='top-right'
                            place='left'
                            tip='Time Travel'
                            dataFor='time-travel'
                        />


												{/* <div className='calendar' onClick={() => this.setState({ calendarOpen: true }) }>
													<Plus className='button default' data-tip data-for='calendar'>
														<CalendarIcon className='background-button' size={18} />
													</Plus>
													<CustomTip
														top={-40}
														left={-25}
														class='top-right'
														place='left'
														tip='My Calendar'
														dataFor='calendar'
													/>
												</div>
												<CalendarDrawer open={this.state.calendarOpen}>
													<CalendarCanvass
															openCalendar={this.state.calendarOpen}
															handleClose={() => this.setState({ calendarOpen: false })}
														/>
												</CalendarDrawer> */}

                    </React.Fragment>
                )}
            </Container>
        );
	}
}

const TimeTravelIcon = ({ className, size }) => {
	return <svg className={className} width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
		<g>
			<path d="M242.4,189.1l-26.9,17.6c-0.6,0.4-1,1-1.1,1.5l-3.7,31.8c0,0.6,0.2,1.2,0.6,1.6l14.8,12c1.7,1.3,5.5,1.5,7.4,0.1l9.3-6.2
				c0.6-0.4,1-1,1.1-1.5l6.6-55.2C250.9,188.4,245.3,187.2,242.4,189.1z"/>
			<path d="M147.6,191.4c0.8,1,2,1.6,3.3,1.6h53.4c1.3,0,2.4-0.5,3.1-1.4l26.2-29.7c2.3-2.5,0.4-6.5-3.1-6.5H128.9
				c-3.3,0-5.3,3.5-3.3,6.2L147.6,191.4z"/>
			<path d="M125.5,355.7l3.7-31.4c0.1-0.6-0.2-1.2-0.9-1.6l-14.9-11.9c-1.7-1.3-5.3-1.5-7.3-0.1l-9.2,6c-0.6,0.4-1,1-1.1,1.5l-6.3,55
				c-0.3,2.4,5.2,3.7,8.1,1.8l26.6-17.8C125,356.7,125.4,356.2,125.5,355.7z"/>
			<path d="M137.8,264.6l-13.3,15.1c-1.2,1.4-1.3,3.3-0.2,4.8l10.8,14.7c0.8,1.1,2,1.7,3.3,1.7h61c1.3,0,2.4-0.5,3.1-1.4l13.4-15.1
				c1.3-1.4,1.3-3.4,0.2-4.8L204,263.4l-63.2-0.1C139.8,263.3,138.6,263.7,137.8,264.6z"/>
			<path d="M193.1,372.7c-0.8-1-2-1.6-3.3-1.6h-53.4c-1.3,0-2.4,0.5-3.1,1.4L107,402.2c-2.3,2.5-0.4,6.5,3.1,6.5h101.7
				c3.3,0,5.3-3.5,3.3-6.2L193.1,372.7z"/>
			<path d="M239.4,316.5l-7.1-5.6c-1.8-1.3-5.5-1.5-7.4-0.1l-18.2,12c-0.6,0.4-1,1-1.1,1.5l-3.7,31.4c-0.1,0.6,0.2,1.2,0.9,1.6
				l22.7,17.8c2.5,1.9,8,1.1,8.3-1.3l6.4-55.6C240.4,317.5,240.1,316.9,239.4,316.5z"/>
			<path d="M107.6,253.6c1.7,1.3,5.5,1.5,7.4,0.1l18.5-12.2c0.6-0.4,1-1,1.1-1.5l3.7-31.4c0.1-0.6-0.2-1.2-0.9-1.6l-22.7-17.9
				c-2.5-1.9-8-1.1-8.3,1.3l-6.7,55.6c-0.1,0.6,0.2,1.1,0.7,1.6L107.6,253.6z"/>
			<path d="M411.2,316.5l-7.1-5.6c-1.8-1.3-5.5-1.5-7.4-0.1l-18.2,12c-0.6,0.4-1,1-1.1,1.5l-3.7,31.4c-0.1,0.6,0.2,1.2,0.9,1.6
				l22.7,17.8c2.5,1.9,8,1.1,8.3-1.3l6.4-55.6C412.2,317.5,411.9,316.9,411.2,316.5z"/>
			<path d="M279.5,253.6c1.7,1.3,5.5,1.5,7.4,0.1l18.4-12.2c0.6-0.4,1-1,1.1-1.5l3.7-31.4c0.1-0.6-0.2-1.2-0.9-1.6l-22.7-17.9
				c-2.5-1.9-8-1.1-8.3,1.3l-6.7,55.5c-0.1,0.6,0.2,1.1,0.9,1.6L279.5,253.6z"/>
			<path d="M297.4,355.7l3.7-31.4c0-0.6-0.3-1.2-0.9-1.6l-14.9-11.9c-1.7-1.3-5.3-1.5-7.3-0.1l-9.2,6c-0.6,0.4-1,1-1.1,1.5l-6.3,55
				c-0.3,2.4,5.2,3.7,8.1,1.8l26.6-17.8C297,356.7,297.3,356.2,297.4,355.7z"/>
			<path d="M319.4,191.4c0.8,1,2,1.6,3.3,1.6h53.4c1.3,0,2.4-0.5,3.1-1.4l26.2-29.7c2.2-2.5,0.3-6.5-3.1-6.5H300.8
				c-3.3,0-5.3,3.5-3.3,6.2L319.4,191.4z"/>
			<path d="M365,372.7c-0.8-1-2-1.6-3.3-1.6h-53.4c-1.3,0-2.4,0.5-3.1,1.4l-26.2,29.7c-2.3,2.5-0.4,6.5,3.1,6.5h101.7
				c3.3,0,5.3-3.5,3.3-6.2L365,372.7z"/>
			<path d="M414.4,189.1l-26.9,17.6c-0.6,0.4-1,1-1.1,1.5l-3.7,31.8c-0.1,0.6,0.2,1.2,0.6,1.6l14.8,12c1.7,1.3,5.5,1.5,7.4,0.1
				l9.3-6.2c0.6-0.4,1-1,1.1-1.5l6.6-55.2C422.8,188.4,417.2,187.2,414.4,189.1z"/>
			<path d="M309.7,264.7l-13.3,15.2c-1.2,1.4-1.3,3.3-0.2,4.8l10.8,14.5c0.8,1.1,2,1.7,3.3,1.7h61c1.3,0,2.4-0.5,3.1-1.4l13.4-15.1
				c1.3-1.4,1.3-3.4,0.2-4.8L376,263.4h-63.2C311.6,263.3,310.5,263.8,309.7,264.7z"/>
		</g>
		<g>
			<path d="M255.7,494.8L255.7,494.8c0-7.9,6.2-14.4,14-14.9c116.5-7.3,209-104.4,209-222.7c0-123-100.1-223.1-223.1-223.1
				c-58.1,0-113.9,22.8-155.4,63c-5.7,5.5-14.6,5.7-20.4,0.4l0,0c-6.4-5.8-6.6-15.7-0.4-21.7c21-20.5,45.3-37,72.2-49.2
				c32.8-14.9,67.8-22.4,104.1-22.4c67.6,0,131.1,26.3,178.9,74.1s74.1,111.3,74.1,178.9s-26.3,131.1-74,178.9
				c-44,44-101.5,69.8-163.1,73.6C263,510.3,255.7,503.4,255.7,494.8z"/>
			<path d="M134.3,123.3H67.5c-8.3,0-14.9-6.7-14.9-14.9V47c0-8.3,6.7-14.9,14.9-14.9l0,0c8.3,0,14.9,6.7,14.9,14.9v31.5
				c0,8.3,6.7,14.9,14.9,14.9h36.9c8.3,0,14.9,6.7,14.9,14.9l0,0C149.2,116.5,142.5,123.3,134.3,123.3z"/>
		</g>
	</svg>

}

const UserWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
`

const UserImage = styled.div`
	width: 30px;
	height: 30px;
	margin: 5px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
`

const Plus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px !important;

	&.time-travel-icon {
		padding: 8px !important;
	}

	&.fixed {
		width: 38px;
		height: 38px;
	}

	.user-count {
		font-weight: 400;
	}

	svg.fill-white {
		path {
			fill: white
		}
	}
`

const Container = styled.div`
	margin-left: 5px;
	display: flex;
	align-items: center;
	* {
		user-select: none;
	}

	.time-travel {
		cursor: pointer;
	}

	.ws-settings {
		cursor: pointer;
		margin: 0 5px;
	}

	.calendar {
		cursor: pointer;
		margin-right: 5px;
	}

	@media (max-width: 767px) {
		flex-direction: column;
		margin-left: initial;

		> div {
			visibility: hidden;
			opacity: 0;
			margin: 0 !important;
			transition: all 0.3s ease-out;
		}

		> div:nth-child(1) {
			transform: translateY(-86px);
		}

		> div:nth-child(2) {
			transform: translateY(-123px);
		}

		> div:nth-child(3) {
			transform: translateY(-160px);
		}

		${({ isOpen }) => isOpen ? `
			> div.time-travel,
			> div.notifications,
			> div.ws-settings
			 {
				visibility: visible !important;
				opacity: 1;
				margin: 5px 0 0 0 !important;
				transform: none;
			}
		`: null}
	}
`

const Dropdown = styled(DropdownWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	margin: 4px 5px;
	font-size: 14px;
	position: relative;

	${props => props.noRightMargin ? 'margin-right: 0px;' : ''}
	/* &:hover {
		background-color: rgba(255, 255, 255, 0.12);
	} */
`

const Badge = styled.div`
	position: relative;
	top: -8px;
	right: -15px;
	height: 18px;
	width: 18px;
	border-radius: 10px;
	line-height: 20px;
	background-color: #ff5555;
	color: #fff;
	text-align: center;
	display: block;
	font-size: 10px;
`

const BadgeWrapper = styled.div`
	position: absolute;
`
// Calendar
const CalendarDrawer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: ${(props) => (props.open ? 100 : 0)}%;
	z-index: 101;
	width: 100%
	-webkit-transition: width 0.3s ease-out;
	transition: width 0.3s ease-out;
	max-width: 100vw;
	max-height: 100vh
	overflow: hidden;
	border-radius: 0;
`

export default withRouter(KeynoteSidebarDropdowns)
