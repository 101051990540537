import styled from "styled-components"
import React, {Fragment} from 'react'
import { FiCheck as SuccessIcon } from '@react-icons/all-files/fi/FiCheck'
import { FiX as ErrorIcon } from '@react-icons/all-files/fi/FiX'

function SuccessErrorToast ({ message, type = 'success' }: any) {
    return  <Fragment>
        <HostPermission>
            <div className={`icon color-${type} bg-${type}-opaque`}>{type === 'success' ? <SuccessIcon /> : <ErrorIcon />}</div>
            <HostPermissionContent>
                <HostPermissionMessage>
                    <div>
                        {message}
                    </div>
                </HostPermissionMessage>
            </HostPermissionContent>
        </HostPermission>
    </Fragment>
}

export default SuccessErrorToast;

export const HostButton = styled.div`
	padding: 10px 15px;
    background: red;
    border-radius: 30px;
    color: white;
	flex: 1;
	text-align: center;
    /* flex: 1;
    margin: 0 7.5px 0 15px; */
`
export const HostPermissionContent = styled.div`
	display: flex;    
	justify-content: center;
	flex-direction: column;
    flex: 1;
`

export const HostPermissionMessage = styled.div`
	height: 30px;
    display: flex;
    align-items: center;
`

export const HostPermissionButtons = styled.div`
	display: flex;
	justify-content: space-between;
    width: 100%;
	margin-top: 5px;

	.left {
		margin-right: 5px;
	}

	.right {
		margin-left: 5px;
	}

	.name {
		font-weight: 500;
	}
`

export const HostPermission = styled.div`
	display: flex;
	font-weight: 400;

	.icon {
		width: 30px;
		height: 30px;
		padding: 5px;
		border-radius: 30px;
		margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
	}

`