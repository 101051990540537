import React from 'react'
import Reflux from 'reflux'
import Sharedb from 'sharedb/lib/client';
import richText from 'rich-text';

import { MainStore } from '../../stores/MainStore';
import { IWidgetProps } from '../types';
import { AllWidgets } from '../index';

import Sidebar from './Sidebar';
import { NotesWrapper, Overlay } from './styledcomponents';
import { NotesContextProvider } from './NotesContext';
import { Doc } from 'sharedb';
import Content from './Content';

import ReconnectingWebSocket from 'reconnecting-websocket';
import LoadingComponent from '../_Shared/LoadingComponent';
const Connection = Sharedb.Connection
const shareDbsocket = new ReconnectingWebSocket(process.env.REACT_APP_SHAREDB_SOCKET_URL || 'wss://localhost:8080')
const connection = new Connection(shareDbsocket as any)

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

type Props = IWidgetProps<AllWidgets.SharedbNotes>

interface State {
    collapsedSidebar: boolean,
    notes: any[]
    sidebarNotes: {}
    active: null | string
    meetingName?: any
    db_meeting?: any
    rDoc: Doc | null
    initialized: boolean
}
class Notes extends Reflux.Component<typeof MainStore, Props, State> {
    rooms: Doc | null
    constructor(props: Props) {
        super(props)

        this.store = MainStore
        this.storeKeys = ['meetingName', 'db_meeting']

        this.rooms = null

        this.state = {
            notes: [],
            sidebarNotes: {},
            active: null,
            collapsedSidebar: false,
            rDoc: null,
            initialized: false
        }
    }

    componentDidMount() {
        const roomId = this.state.meetingName
        const nameAlias = this.state.db_meeting?.name_alias || ''
        if(!roomId) return console.error("Room ID is not defined")
        try {
            
            //@ts-ignore
            this.rooms = connection.get('sharedrooms', roomId)
            this.setState({ rDoc: this.rooms })
            if(!this.rooms) return
            // Mark: Room subscription to sharedb (notes)
            this.rooms.subscribe((error: any) => {
                if (error) return console.error("Error in subscribing room: ", error)
                if(!this.rooms) return
                
                // If doc.type is undefined, the document has not been created, so let's create it
                if (!this.rooms.type) {
                    this.rooms.create({ _id: roomId, roomName: nameAlias, notes: [], sidebarNotes: {}  }, (error: any) => {
                        if (error) console.error(error)
                        this.setState({ initialized: true })
                    });
                } else {
                    if(this.rooms?.data) {
                        const { notes, sidebarNotes } = this.rooms?.data
                        const sidebarNotesKey = Object.keys(sidebarNotes || [])
                        const ownedbyyou = sidebarNotesKey.filter((note: any) => {
                            const currentNote = sidebarNotes?.[note]
                            return (currentNote?.isPrivate && currentNote?.created_by === this.props.userId) || !!!currentNote?.isPrivate 
                        }).reverse()
                        const newSidebarNotes = ownedbyyou.reduce((o, key) => ({ ...o, [key]: sidebarNotes[key]}), {})
                        const active = ownedbyyou?.[0] || null
                        this.setState({
                            notes,
                            sidebarNotes: newSidebarNotes,
                            active,
                            initialized: true
                        })
                    }
                }

                this.rooms.on('op', (op: any) => {
                    //console.log("op: ", op, this.rooms)
                    if(this.rooms?.data) {
                        const { notes, sidebarNotes } = this.rooms?.data
                        const sidebarNotesKey = Object.keys(sidebarNotes || [])
                        const ownedbyyou = sidebarNotesKey.filter((note: any) => {
                            const currentNote = sidebarNotes?.[note]
                            return (currentNote?.isPrivate && currentNote?.created_by === this.props.userId) || !!!currentNote?.isPrivate 
                        }).reverse()
                        const newSidebarNotes = ownedbyyou.reduce((o, key) => ({ ...o, [key]: sidebarNotes[key]}), {})
                        this.setState({
                            notes,
                            sidebarNotes: newSidebarNotes,
                            initialized: true
                        })
                    }
                })
            });
        } catch(error) {
            console.error("ERROR in subscribing room: ", error)
        }
    }

    componentWillUnmount() {
        if(this.rooms) {
            this.rooms.unsubscribe((error: any) => {
                if (error) return console.error(error)

                this.rooms = null
            })
        }
    }

    toggleCollapseSidebar = () => {
        this.setState((prevState: State) => ({ ...prevState, collapsedSidebar: !!!prevState.collapsedSidebar }))
    }
    

    render() {
        if(!this.state.initialized) {
            return <LoadingComponent info="Fetching notes" />
        }
        return (
            <NotesContextProvider
                roomId={this.state.meetingName}
                roomDoc={this.rooms || this.state.rDoc}
                userId={this.props.userId}
                users={this.props.users}
                sidebarNotes={this.state.sidebarNotes}
                active={this.state.active}
                collapsedSidebar={this.state.collapsedSidebar}
                toggleCollapseSidebar={this.toggleCollapseSidebar}
                connection={connection}
            >
                <NotesWrapper collapsedSidebar={this.state.collapsedSidebar}>
                    <Overlay collapsed={this.state.collapsedSidebar} onClick={this.toggleCollapseSidebar} />
                    <Sidebar />
                    <Content />
                </NotesWrapper>
            </NotesContextProvider>
        )
    }
}

export default Notes
