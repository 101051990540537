import React, {ReactElement} from "react"
import styled from "styled-components"
import FilesNotFound from "./NoFiles.svg"
import FoldersNotFound from './NoFolder.svg'

interface Props {
	children: string | ReactElement
	folder?: boolean
}

function EmptyDropboxContents(props: Props): ReactElement {
	const {children, folder = true} = props
	return (
		<Container>
			<Wrapper>
				<img src={folder ? FoldersNotFound : FilesNotFound} alt={folder ? 'Folders not found' : 'File not found'} />
				<Heading>{children}</Heading>
			</Wrapper>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 0;
	flex: 1;
    justify-content: center;

	img {
		width: 100px;
		height: 100px;
	}
`

const Heading = styled.div`
	margin-top: 8px;
	font-weight: 400;
	font-size: 13px;
`

export default EmptyDropboxContents
