import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Color from 'color'

const formatNum = timestamp => new moment(timestamp).format('h:mm:ssa')

const TranscriptionItem = (props) => {
    const [dot, setDots] = React.useState([]);

    React.useEffect(() => {
        for (let i = 0, len = props.rec.deltaCount; i < len; i++) {
            setDots([
                ...dot,
                <Dot key={`${props.rec.timestamp}-dot-${i}`} is_active={props.active_delta_index === i} />,
            ]);
        }
    }, [props.rec]);

    return (
        <>
            <Container ref={(r) => props.is_active && props.setRef(r)}>
                <Name>{props.rec.user.fullname ?? props.rec.user.username}</Name>
                <TextEntry is_active={props.is_active} stared={props.rec.stared}>
                    {props.rec.text}
                </TextEntry>
                <Timestamp>{formatNum(props.rec.timestamp)}</Timestamp>
                <DeltaCount>{dot}</DeltaCount>
            </Container>
        </>
    );
};

export default TranscriptionItem;

const Dot = styled.div`
	width: 10px;
	height: 10px;
	border: 2px solid ${props => props.theme.color.string()};
	background-color: ${props =>
		props.is_active ? '#FF9900' : 'white'};
	border-radius: 50%;
	margin-top: 2px;
	margin-bottom: 2px;
	z-index: 2;
	box-sizing: content-box;
`

const Timestamp = styled.div`
	font-variant: tabular-nums;
	font-family: monospace;
	padding-left: 4px;
	opacity: 0.6;
`

const Name = styled.div`
	font-weight: 500;
	width: 86px;
	margin-right: 4px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	flex: 1;
	padding-left: 4px;
	overflow: hidden;
	position: relative;

	> * {
		padding-top: 4px;
		padding-bottom: 4px;
	}
`

const DeltaCount = styled.div`
	padding: 4px;
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin-right: 4px;
	width: 20px;
	&:before {
		content: '';
		width: 2px;
		position: absolute;
		top: 8px;
		bottom: 8px;
		background-color: rgba(0, 0, 0, 0.3);
	}
`

const TextEntry = styled.div`
    padding: 4px;
    flex: 1;
    border-radius: 4px;
    background-color: ${(props) => (props.stared ? '#ffee00' : '')};
    ${(props) =>
        props.is_active &&
        `
	background-color: ${Color('#FF9900').fade(0.2).string()};
	color: white;
`};
`;
