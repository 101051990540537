import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from '../../components/Elements'

interface Props {
	startLogin?: (key?: string) => void
	loginKey: string
	logo: any
	name: string
	syncedInfo?: string[]
}

export default class LoginTemplate extends Component<Props> {
	startLogin(key: string) {
		window.open(
			(process.env.REACT_APP_API_URL || 'http://localhost:8000') +
			`/oauth/auth/${key}`
		)
	}

	render() {
		let loginAction = () => { }

		if (this.props.startLogin) {
			loginAction = this.props.startLogin
		} else if (this.props.loginKey) {
			loginAction = () => this.startLogin(this.props.loginKey)
		}
		return (
			<Container>
				<InfoContain>
					<TitleContainer>
						<LogoContainer className="topbar"><Logo src={this.props.logo} alt="img" /></LogoContainer>
						<Title>{this.props.name}</Title>
					</TitleContainer>
					<div className="button default" onClick={loginAction}>
						Link Account
					</div>
				</InfoContain>

				{this.props.syncedInfo && (
					<Bullets>
						<p>Only the following data will be seen by collaborators:</p>
						<ul>
							{this.props.syncedInfo.map((info, ii) => (
								<li key={ii}>{info}</li>
							))}
						</ul>
					</Bullets>
				)}

				<Disclaimer>
					<strong>None</strong> of your credentials will be stored on our
					servers
				</Disclaimer>
			</Container>
		)
	}
}

const InfoContain = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px;

	> .button {
		font-weight: 400;
		cursor: pointer;
	}
`

const Disclaimer = styled.p`
	font-weight: 500;
	position: absolute;
	bottom: 6px;
	opacity: 0.7;

	@media (max-width: 500px) {
		text-align: center;
		bottom: 60px;
		padding: 0 10px;
	}
`

const Bullets = styled.div`
	margin: 10px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	p {
		font-weight: 500;
		font-size: 14px;
	}
	li {
		margin-top: 8px;
		margin-left: 8px;
		list-style-type: initial;
		list-style-position: inside;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
	height: 100%;
`

const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`


const Logo = styled.img`
	height: 60%;
	width: 60%;
`

const LogoContainer = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: ${props => props.theme.shadows.newlight};
`

const Title = styled.h1`
	margin: 15px 0;
	color: ${props => props.theme.textPrimary};
	font-weight: ${props => props.theme.textBold};
	font-size: 16px;
`
