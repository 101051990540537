import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { CgMenuRightAlt as HamburgerIcon } from '@react-icons/all-files/cg/CgMenuRightAlt'
import { CgClose as CloseIcon } from '@react-icons/all-files/cg/CgClose'
import { FiLogOut as LogoutIcon } from '@react-icons/all-files/fi/FiLogOut'
import logoText from '../../assets/logo.svg'
import logowhite from '/assets/logowhite.svg'

import { ModalActions } from '/stores/ModalStore'
import MeetingTitle from '../Meeting2.0/KeynoteSidebar/MeetingTitle'
import WorkspaceSelector from '../Meeting2.0/WorkspaceSelector'
import { colorSchemeMapper, defaultStyles, ThemingStore } from '../../stores/ThemingStore'
import { VariableCallingStore } from '../../stores/VariableCallingStore'
import { MainStore } from '../../stores/MainStore'
import { CallingInstanceState } from '../../calling/types'

export default class Hamburger extends Reflux.PureComponent {
	constructor(props) {
		super(props)
		this.stores = [ThemingStore, VariableCallingStore, MainStore, VariableCallingStore]
		this.storeKeys = ['color_scheme', 'status', 'meetingName', 'status']
		this.state = {
			open: false || props.meetingName === 'me' || !props.meetingName,
			color_scheme: 'Light',
			status: '',
			meetingName: null
		}
		this._toggleSidebar = this._toggleSidebar.bind(this)
		this._newMeeting = this._newMeeting.bind(this)
		this._signout = this._signout.bind(this)
		this._logout = this._logout.bind(this)
	}

	_toggleSidebar() {
		this.setState({ open: !this.state.open })
	}

	_newMeeting() {
		window.location.hash = '#create'
		ModalActions.SetModal('create-or-join')
		this.setState({ open: false })
	}

	_signout() {
		localStorage.removeItem('token')
		sessionStorage.removeItem('roomsAllowed')
		localStorage.removeItem('recentRooms')
        if(localStorage.getItem('audio_input_device_id')) {
			localStorage.removeItem('audio_input_device_id')
		}
		if(localStorage.getItem('video_input_device_id')) {
			localStorage.removeItem('video_input_device_id')
		}
		if(localStorage.getItem('audio_output_device_id')) {
			localStorage.removeItem('audio_output_device_id')
		}

		this.props.history.push('/login')
	}

	_logout() {
		const isInCall = this.state.status === CallingInstanceState.Connected
		if(isInCall) {
			ModalActions.SetModal('ConfirmLogout')
		} else {
			localStorage.removeItem('token')
			sessionStorage.removeItem('roomsAllowed')
			localStorage.removeItem('recentRooms')
			if(localStorage.getItem('audio_input_device_id')) {
				localStorage.removeItem('audio_input_device_id')
			}
			if(localStorage.getItem('video_input_device_id')) {
				localStorage.removeItem('video_input_device_id')
			}
			if(localStorage.getItem('audio_output_device_id')) {
				localStorage.removeItem('audio_output_device_id')
			}

			this.props.history.push('/login')
		}
	}

	componentDidMount() {
		if(this.props.meetingName === 'create-or-join') {
			this.setState({ open: true })
		}
	}

	render() {
		const isOpen = this.state.open
		const theme = this.state.meetingName && this.state.color_scheme ? this.state.color_scheme : 'Light'
		const color_scheme = colorSchemeMapper[theme]
		return (
			<React.Fragment>
				<HamburgerButton onClick={this._toggleSidebar} color_scheme={color_scheme} id='hamburger-housing'>
					<HamburgerIcon className="button hamburger-icon" size={26} />
				</HamburgerButton>
				{isOpen && <Underlay onClick={this._toggleSidebar} />}
				<SidebarWrapper open={isOpen} color_scheme={color_scheme}>
					<Sidebar className={`topbar`}>
						<LogoContain>
							<CloseButton onClick={this._toggleSidebar} className="button"><CloseIcon /></CloseButton>
							<img src={theme === 'Dark' ? logowhite : logoText} alt="Grapl" height="30px" className="border-light" />
							<LogoutButton onClick={this._logout} className='error'><LogoutIcon /></LogoutButton>
						</LogoContain>
						{isOpen && (
							<WorkspaceSelector
								skip_reload={this.props.meetingName === 'me'}
								isOpen={this.state.open}
								callStatus={this.state.status}
							/>
						)}
						{/* <Bottom>
							<Button className="button primary" onClick={this._newMeeting}>
								New Room
							</Button>
						</Bottom> */}
					</Sidebar>
					{/* <Sidebar open={isOpen} className="container" color_scheme={color_scheme}>
					<div className="topbar">
						<LogoContain>
							<img src="http://useloop.io/wp-content/uploads/2021/05/GRAPL@4x.png" alt="Grapl" height="50px" />
						</LogoContain>
						<MeetingTitle mobile />
						<NewMeeting onClick={this._newMeeting}>
							<PlusIcon size={18} /> New Room
						</NewMeeting>
					</div>
				</Sidebar> */}
				</SidebarWrapper>
			</React.Fragment>
		)
	}
}

const Bottom = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;
`

const CloseButton = styled.div`
	cursor: pointer;
	font-size: 20px;
    padding: 12px 12px 12px 0px !important;
	margin-right: 15px;
`

const LogoutButton = styled.div`
	cursor: pointer;
	font-size: 20px;
    padding: 12px 0 12px 12px !important;
	margin-left: 15px;
`

const Sidebar = styled.div`
	height: 100%;
	padding: 15px 15px 30px 15px;
	display: flex;
	flex-direction: column;

	.header-title {
		font-weight: 500;
		font-size: 16px;
		margin: 15px 0 0 10px;
	}
`

const SidebarWrapper = styled.div`
	${Object.entries(defaultStyles || {})
		.map(([key, val]) => `${key} {${val}}`)
		.join('\n')}

	${({ color_scheme }) => {
		const styleobj = Object.entries(color_scheme || {})
			.map(([key, val]) => `${key} {${val}}`)
			.join('\n')
		return styleobj
	}}

	width: ${(props) => (props.open ? 300 : 0)}px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 101;
	width: ${(props) => (props.open ? 300 : 0)}px;
	-webkit-transition: width 0.3s ease-out;
	transition: width 0.3s ease-out;
	max-width: 100vw;
	overflow: hidden;
	border-radius: 0px 15px 15px 0;
`

const SignoutButton = styled.div`
	&:hover {
		opacity: 0.85;
	}
	cursor: pointer;
	background-color: rgba(255, 0, 0, 0.65);
	padding: 5px 8px;
	border-radius: 2px;
	position: absolute;
	top: 20px;
	right: 20px;
	color: white;
	font-size: 12px;
	display: flex;
	align-items: center;
`

const Button = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};
	width: 80%;

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`


const LogoContain = styled.div`
	padding: 5px;
	padding-bottom: 15px;
	font-weight: 500;
	font-size: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.2px;

	img {
		padding: 0 30px;
		flex: 1;
		border-left: 1px solid;
	}
`

const Logo = styled.img`
	width: 38px;
	height: 38px;
	border: none;
	box-shadow: none;
	margin-right: 8px;
`

const HamburgerButton = styled.div`
	position: fixed;
	left: 18px;
	top: 22px;
	cursor: pointer;
	&:hover {
		opacity: 0.85;
	}
	z-index: 100;

	${Object.entries(defaultStyles || {})
		.map(([key, val]) => `${key} {${val}}`)
		.join('\n')}

	${({ color_scheme }) => {
		const styleobj = Object.entries(color_scheme || {})
			.map(([key, val]) => `${key} {${val}}`)
			.join('\n')
		return styleobj
	}}

	.hamburger-icon {
		padding: 0px !important;
	}
`

const Underlay = styled.div`
	z-index: 100;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	@keyframes fadeInUnderlay {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	animation: fadeInUnderlay 0.5s forwards;
`

// const Sidebar = styled.div`
// 	* {
// 		user-select: none;
// 	}
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	height: 100%;
// 	z-index: 5;
// 	width: ${(props) => (props.open ? 300 : 0)}px;
// 	-webkit-transition: width 0.3s ease-out;
// 	transition: width 0.3s ease-out;
// 	max-width: 100vw;
// 	overflow: hidden;
// 	overflow-y: auto;
// 	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.4);
// 	/* background: #261c5c; */
// 	${Object.entries(defaultStyles || {})
// 		.map(([key, val]) => `${key} {${val}}`)
// 		.join('\n')}

// 	${({ color_scheme }) => {
// 		const styleobj = Object.entries(color_scheme || {})
// 			.map(([key, val]) => `${key} {${val}}`)
// 			.join('\n')
// 		return styleobj
// 	}}
// `

const NewMeeting = styled.div`
	border: 2px solid rgba(255, 255, 255, 0.8) !important;
	background-color: transparent;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	align-self: stretch;
	width: auto;
	margin: 8px;
	background-color: rgba(255, 255, 255, 0.12);
	cursor: pointer;
	min-width: 284px;
	color: white;
	&:hover {
		opacity: 0.85;
	}
	> * {
		margin-right: 6px;
		margin-left: 6px;
	}
`
