import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'

import LoopApi from '../../../helpers/LoopApi'
import Modal from '../../../components/Modalv2'
import { ModalActions, ModalStore } from '../../../stores/ModalStore'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import useToastify from '../../../helpers/useToastify'
import { CallingInstanceState } from '../../../calling/types'

const { GlobalState } = require('reflux')

function SubscriptionSwitchSuccess(props: any) {
    const onOK = () => {
        props.closeModal && props.closeModal()
        ModalActions.SetParams({ current: null, switch: null, planname: '' })
        ModalActions.SetModal('Subscription')
	}

	return (
		<Modal closeModal={() => {}}>
			<Container className="topbar-modal">
					<Contents>
						<Title className="header">{`You successfully switched your plan to ${GlobalState?.modal?.planname}`}</Title>
						<Details className="subheader">Please be informed that the adjustment in your billing will be reflected on the billing period. </Details>
					</Contents>

					<Options>
						<Button className="button primary" onClick={onOK}>OK</Button>
					</Options>
				</Container>
		</Modal>
	)
}

export default SubscriptionSwitchSuccess

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
    background-color: white;
    color: #363B45;
    max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div<{ disabled?: boolean }>`
    ${props => props.disabled ? 'opacity: 0.3; pointer-events: none;' : ''}
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
    font-size: 14px;

    &.button.primary {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%); 
        color: white;
    }

	&.button.default {
        color: white; 
        background-color: #363B45;
    } 
`
