import React, { Fragment } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { MainActions, MainStore } from '../../../stores/MainStore'
import LoopApi from '../../../helpers/LoopApi'
import { ModalStore } from '../../../stores/ModalStore'
import Modal from '../../../components/Modalv2'
import useToastify from '../../../helpers/useToastify'
import SuccessErrorToast from '../../../components/Notifications/SuccessErrorToast'
import { ThemingStore } from '../../../stores/ThemingStore'

const {GlobalState} = require('reflux')

class RenameMeeting extends Reflux.Component<typeof ModalStore | typeof ThemingStore | typeof MainStore> {
    constructor(props: any) {
        super(props) 

        this.stores = [ModalStore, ThemingStore, MainStore]
        this.storeKeys = ['cb', 'color_scheme', 'db_meeting']
        this.state = {
            meeting_name: '',
            meetingId: '',
            updating: false
        }
    }

    componentDidMount() {
        this.setState({
            meeting_name: GlobalState.modal?.meeting_name || "",
            meetingId: GlobalState.modal?.meetingId
        })
    }

    updateValue = (e: any) => {
        if(e.target.value.length > 24) {
            this.setState({
                error: `Room name cannot be greater than 24 characters`
            })
        } else {
            this.setState({
                meeting_name: e.target.value,
                error: ''
            })
        }
    }

    onSubmit = async(e: any) => {
        e.preventDefault()

        this.setState({ updating: true })

        await LoopApi(null, 'ChangeMeetingName', { title: `${this.state.meeting_name}`.trim(), id: this.state.meetingId })
        .then((response) => {
            //console.log(response)
            if(response?.success) {
                useToastify({
                    message: () => SuccessErrorToast({ message: 'Successfully renamed room', type: 'success' }),
                    position: "top-right",
                    autoClose: 1500,
                    closeButton: false,
                    hideProgressBar: true,
                    className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
                    bodyClassName: "grow-font-size",
                })
                if(this.state.meetingId === this.state.db_meeting?.name) {
                    MainActions.RenameRoom(this.state.meeting_name)
                }
            }
            this.setState({ updating: false })
            if(this.state.cb) {
                this.state.cb()
            }
            
            this.props.closeModal()
        })
        .catch((err) => {
            this.setState({ updating: false })
        })

    }

    render() {
        return (
            <Modal closeModal={this.props.closeModal}>
                <Container className="topbar-modal">
                    <Title className="header">Rename Room</Title>
                    {this.state.create && 
                        <Subtitle className="subheader">
                            <Fragment>
                                Declare a name that describes the room <br /> activity or content
                            </Fragment>
                        </Subtitle>
                    }
                    <NameForm onSubmit={this.onSubmit}>
                        <Input
                            className="border-light topbar"
                            type="text"
                            value={this.state.meeting_name}
                            onChange={this.updateValue}
                            placeholder={'Room name'}
                            autoFocus
                        />
                        {this.state.error ? (
                        <ErrorMessage className="error">{this.state.error}</ErrorMessage>
                        ) : null}
                        <SubmitButton
                            className="button primary"
                            type="submit"
                            value={'Save'}
                            disabled={`${this.state.meeting_name}`?.trim()?.length === 0 || this.state.updating}
                        />
                    </NameForm>
                    
                </Container>
            </Modal>
        )
    }
}
export default RenameMeeting

const ErrorMessage = styled.p`
    margin: 8px 0;
    max-width: 262px;
`

const Subtitle = styled.div`
	margin-bottom: 30px;
	text-align: center;
`

const SubmitButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const NameForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 20px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 36px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 100%;
	z-index: 3;

	@media (min-width: 423px) {
		width: 352px;
	}
`

const Input = styled.input`
	padding: 8px !important;
	border-radius: 32px !important;
	border: 1px solid;
	font-size: 16px !important;
	background: none;
`

