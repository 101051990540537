import {LocalVideoTrack, Room} from 'twilio-video'
import {useCallback, useState} from 'react'
import useMeetingContext from '../../../../../contexts/useMeetingContext'
import { DailyCall } from '@daily-co/daily-js'
import { CallingProviderName } from '../../../../../contexts/types'
import useStreamStates from './useStreamStates'

export default function useLocalVideoToggle(onboarding?: boolean) {
  const {
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
    callProvider
  } = useMeetingContext()

  const localParticipant = (room as Room)?.localParticipant || (room as DailyCall)?.participants().local
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack
  const { isCameraMuted } = useStreamStates()
  const [isPublishing, setIspublishing] = useState(false)

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if(isTwilio || onboarding) {
        if (videoTrack) {
          const localTrackPublication =
            localParticipant?.unpublishTrack(videoTrack)
  
          localParticipant?.emit('trackUnpublished', localTrackPublication)
          removeLocalVideoTrack()
        } else {
          setIspublishing(true)
          getLocalVideoTrack()
            .then((track: LocalVideoTrack) =>
              localParticipant?.publishTrack(track, {priority: 'low'}),
            )
            .catch(onError)
            .finally(() => {
              setIspublishing(false)
            })
        }
      } else if(callProvider === CallingProviderName.DailyCo) {
        (room as DailyCall)?.setLocalVideo(!!isCameraMuted || false)
      }
    }
  }, [
    videoTrack,
    localParticipant,
    getLocalVideoTrack,
    isPublishing,
    onError,
    removeLocalVideoTrack,
  ])

  return [ (isTwilio || onboarding) ?!!videoTrack : !!!isCameraMuted, toggleVideoEnabled] as const
}
