import React, {useCallback, useRef, useEffect} from 'react'
import {RiRecordCircleLine as RecordIcon} from '@react-icons/all-files/ri/RiRecordCircleLine'
import {RiRecordCircleFill as StopRecordIcon} from '@react-icons/all-files/ri/RiRecordCircleFill'

import useVideoContext from '../../hooks/useVideoContext'
import useIsRecording from '../../hooks/useIsRecording'
import CustomTip from '../../../../../../helpers/CustomTip'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { CallingProviderName } from '../../../../../../contexts/types'
import { ModalActions } from '../../../../../../stores/ModalStore'

export default function ToggleRecordingButton(props: {
  disabled?: boolean
  className?: string
  h?: string
  isMobile?: boolean
}) {
  const {disabled, h} = props
  const { callProvider, meeting } = useMeetingContext()
  const {recordMeeting, users, shouldRecord} = useVideoContext()
  // const isRecording = useIsRecording()

  const lastClickTimeRef = useRef(0)

  const toggleRecording = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      if(callProvider === CallingProviderName.DailyCo) {
        ModalActions.SetModalWithParams('ConfirmRecording', { cb: () => {
          recordMeeting(!shouldRecord, users, h)
        } })
      } else {
        if(!shouldRecord) {
          ModalActions.SetModalWithParams('ConfirmRecording', { cb: () => {
            recordMeeting(!shouldRecord, users, h)
          }, isTwilio: true })
        } else {
          recordMeeting(!shouldRecord, users, h)
        }
      }
    }
  }, [shouldRecord])

  return (
    <>
      <button
        className="button default controlbutton"
        onClick={toggleRecording}
        disabled={disabled || !meeting?.settings?.record || (callProvider === CallingProviderName.DailyCo && shouldRecord)}
      >
        <span
          data-tip
          data-for="allow-rec"
          style={{ display: 'flex' }}
        >
          {shouldRecord ? <StopRecordIcon color="#FF3C3C" /> : <RecordIcon />}
        </span>
      </button>

      {!!!props.isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip={`${
            !meeting?.settings?.record
              ? 'Allow Recording Setting is turned OFF'
              : disabled && shouldRecord 
              ? 'Meeting is being recorded'
              : disabled && !shouldRecord
              ? 'Meeting is not being recorded'
              : shouldRecord && callProvider === CallingProviderName.DailyCo
              ? 'Recording will stop when meeting ended'
              : shouldRecord
              ? 'Stop Recording'
              : 'Record Meeting'
          }`}
          dataFor="allow-rec"
        />
      )}
    </>
  )
}
