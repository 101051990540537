import styled from 'styled-components'
import React from 'react'
import TokenExpiredIcon from './sessionexpired.svg'
import bg from '../assets/backgrounds/LandingBG.png'

const { GlobalState } = require('reflux')

function TokenExpired() {
  const theme = GlobalState?.theming?.color_scheme || 'Light'

  const goToLogin = () => {
    if(localStorage.getItem('token')) {
        localStorage.removeItem('token')
    }
    if(localStorage.getItem('audio_input_device_id')) {
      localStorage.removeItem('audio_input_device_id')
    }
    if(localStorage.getItem('video_input_device_id')) {
      localStorage.removeItem('video_input_device_id')
    }
    if(localStorage.getItem('audio_output_device_id')) {
      localStorage.removeItem('audio_output_device_id')
    }
    if(localStorage.getItem('recentRooms')) {
        localStorage.removeItem('recentRooms')
    }
    window.location.href = '/login'
  }

  return (
    <Wrapper isLight={theme === 'Light'} bg={bg}>
        <Content className='topbar-modal'>
            <img src={TokenExpiredIcon} alt="Session Timeout" />
            <Title>Your session has expired</Title>
            <Description>Please login again</Description>

            <Button onClick={goToLogin}>Login</Button>
        </Content>

    </Wrapper>
  )
}

const Button = styled.div`
    padding: 12px;
    width: 100%;
    border-radius: 30px;
    cursor: pointer;
`

const Wrapper = styled.div<{ isLight?: boolean, bg?: any }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    font-weight: 400;
    background-image: url(${({bg}) => bg});
    background-repeat: no-repeat;
    background-size: cover;

    .topbar-modal {
        background: white;
        color: #363B45;
    }

    ${Button} {
        background: linear-gradient(90deg, #FF9900 0%, #FF8200 100%);
        color: white;
        margin-top: 20px;
    }
`

const Content = styled.div`
    max-width: Calc(100% - 67px);
	max-height: 80%;
    min-width: 400px;
    text-align: center;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 20%);

    > img {
        width: 200px;
        margin-bottom: 20px;
    }
`

const Title = styled.div`
    font-weight: 500;
    font-size: 18px; 
    margin-bottom: 10px;
`
const Description = styled.div`
    font-weight: 400;
    font-size: 14px;
`

export default TokenExpired