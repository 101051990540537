import { DailyCall, DailyParticipant } from '@daily-co/daily-js'
import {useEffect, useState} from 'react'
import {Participant} from 'twilio-video'
import { CallingProviderName } from '../../../../../contexts/types'
import useMeetingContext from '../../../../../contexts/useMeetingContext'

export default function useParticipantIsReconnecting(participant: Participant | DailyParticipant) {
  const [isReconnecting, setIsReconnecting] = useState(false)
  const { callProvider } = useMeetingContext()

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  useEffect(() => {
    const handleReconnecting = () => setIsReconnecting(true)
    const handleReconnected = () => setIsReconnecting(false)

    if(isTwilio) {
      handleReconnected();// Reset state when there is a new participant
  
      (participant as Participant).on('reconnecting', handleReconnecting);
      (participant as Participant).on('reconnected', handleReconnected);
      return () => {
        (participant as Participant).off('reconnecting', handleReconnecting);
        (participant as Participant).off('reconnected', handleReconnected);
      }
    }

  }, [participant])

  return isReconnecting
}
