import Alert from 'react-s-alert'
import jwtDecode from 'jwt-decode'
import { GlobalState } from 'reflux'
import { EventEmitter } from 'events'

// import notifierSound from '../../assets/ChatNotification.mp3'
import Encryption from '/helpers/Encryption'
import LoopApi from '/helpers/LoopApi'
// import SFU from '/helpers/SFU'
import { MainActions, WidgetActions } from '/stores/MainStore'
import { AuthActions } from '/stores/AuthStore'
import { NotificationActions } from '/stores/NotificationStore'
import Widgets from '../loop-widgets'
import MainWorker from './MainWorker.usedtobeaworker.js'
import { VariableCallingActions } from '../stores/VariableCallingStore'
import { isJSON } from './'

class ClientHousing {
	constructor(props) {
		this.SFU_client = null
		this.firefox_mode =
			navigator.userAgent.toLowerCase().indexOf('firefox') > -1

		// this.callback_registry = {}
		this.sockets = []
		this.workerMessageMap = {}

		// this._onWorkerMessage = this._onWorkerMessage.bind(this)
		// this.worker = new Worker(
		// 	new URL("./MainWorker.worker.js", import.meta.url),
		// 	{ type: "module" }
		// )
		this.worker = new MainWorker()
		if (this.worker) {
			// console.log(this.worker)
			// this.worker.removeListener('message', this._onWorkerMessage)
			// this.worker.addListener('message', this._onWorkerMessage)
		}

		this.onFocusEvents = this.onFocusEvents.bind(this)
		window.addEventListener('focus', this.onFocusEvents)
	}

	_postMessage(message) {
		if (this.firefox_mode) {
			this.worker.handleInboundMessage(JSON.parse(JSON.stringify(message)))
		} else {
			this.worker.handleInboundMessage(message)
		}
	}

	ConnectWithListeners(connection_type, path, listeners) {
		if (this.sockets.includes(connection_type)) {
			console.warn('connection already exists')
			return this._postMessage({
				action: 'EnsureConnected',
				connection_type,
			})
		}
		// this.workerMessageMap[connection_type] = listeners
		this.sockets.push(connection_type)
		this._postMessage({
			action: 'ConnectWithListeners',
			connection_type,
			path,
			listeners,
		})
	}

	EnsureConnected(connection_type) {
		this._postMessage({ action: 'EnsureConnected', connection_type })
	}

	Emit(connection_type, event, data, cb = null) {
		const message = { action: 'Emit', connection_type, event, data }
		if (cb) {
			message.callback = cb
			// message.callback_id = `${Date.now()}-${Math.random()}`
			// this.callback_registry[message.callback_id] = cb
		}
		this._postMessage(message)
	}

	// _onWorkerMessage(e) {
	// 	console.log('message', e)
	// 	if (e.data.callback_id) {
	// 		if (!this.callback_registry[e.data.callback_id]) {
	// 			return console.error(
	// 				'callback from websocket that wasnt registered',
	// 				e,
	// 				this
	// 			)
	// 		}
	// 		this.callback_registry[e.data.callback_id](e.data.resp)
	// 		delete this.callback_registry[e.data.callback_id]
	// 		return
	// 	}

	// 	if (
	// 		!e.data ||
	// 		!e.data.event ||
	// 		!e.data.connection_type ||
	// 		!this.workerMessageMap[e.data.connection_type] ||
	// 		!this.workerMessageMap[e.data.connection_type][e.data.event]
	// 	) {
	// 		return console.error('got webworker event with no event data', e)
	// 	}

	// 	this.workerMessageMap[e.data.connection_type][e.data.event](e.data.data)
	// }

	onFocusEvents() {
		// Clients.EnsureConnected('sfu')
		Clients.EnsureConnected('main')
	}

	setSFUClient(client) {
		this.SFU_client = client
	}
	ClearSockets() {
		this.sockets = this.sockets.filter((s) => s !== 'sfu' && s !== 'main')
		this._postMessage({ action: 'CloseSocket', connection_type: 'main' })
		this._postMessage({ action: 'CloseSocket', connection_type: 'sfu' })
		this.SFU_client && this.SFU_client.leave()
		this.SFU_client = null
	}
}
const Clients = new ClientHousing()

const subscribe_now = async (r) => {
	try {
		const payload = await r.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: applicationServerKey,
		})
		return JSON.stringify(payload)
	} catch (e) {
		console.warn(e)
		return false
	}
}

setTimeout(() => {
	if (window.notification_worker) {
		window.notification_worker.addEventListener(
			'notification_worker',
			async (e) => {
				subscribe_now(window.notification_worker)
			}
		)
	}
}, 1000)

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i)
	}
	return outputArray
}

const applicationServerKey = urlBase64ToUint8Array(
	process.env.REACT_APP_VAPID_PUBLIC_KEY ||
	'BO1VblRTLtzfk7sLeQ7n5c5xHHAFoky7uAUf5uJzgExr9rJkFAuP6st5yeqE_mg7m7MC9mkkO8iIcTVRXlpylIk'
)

function pokeInSlack(slack_id) {
	LoopApi(null, 'PokeInSlack', {
		slack_id,
	})
}

function emitWidgetScroll(percent) {
	LoopApi('main', 'WidgetScrollPercent', {
		percent,
	})
}

async function changeSetting(key, value) {
	try {
		if (!!!localStorage.getItem('token')) throw new Error('invalid token')
		await LoopApi(null, 'ChangeMeetingSetting', {
			key,
			value,
		})
	} catch (err) {
		//console.log("error: ", err)
		if (err === 'invalid token') {
			localStorage.removeItem('token')

			if (localStorage.getItem('audio_input_device_id')) {
				localStorage.removeItem('audio_input_device_id')
			}
			if (localStorage.getItem('video_input_device_id')) {
				localStorage.removeItem('video_input_device_id')
			}
			if (localStorage.getItem('audio_output_device_id')) {
				localStorage.removeItem('audio_output_device_id')
			}
			if (this && this.props && this.props.history) {
				this.props.history.push(`/login`)
			} else {
				window.location.href = '/login'
			}
		}
	}
}

function addWidgets(widgetNames, extra) {
	addWidget(widgetNames, extra)
}

async function addWidget(w, d) {
	const raw_widget_obj = {}
	const new_widgets = Array.isArray(w) ? w : [w]
	let new_extra_data = d || {}
	if (typeof w === 'string' && d) {
		new_extra_data = { [w]: d }
	}

	new_widgets.forEach((wn) => {
		const widgetName = wn.match(/[a-z]+/)[0]
		const extraData = new_extra_data[widgetName] || {}
		const widgetComp = Widgets[widgetName] || Widgets[extraData._component]
		if (!widgetComp) {
			return console.warn('Sync not found', widgetName, extraData)
		}

		if (widgetComp.widgetConfig.requiredSetup) {
			extraData._requiresSetup = true
		}
		raw_widget_obj[widgetName] = Object.assign(
			{},
			widgetComp.widgetConfig.defaultProps &&
			widgetComp.widgetConfig.defaultProps.data,
			extraData
		)
	})

	let widget_obj = {}
	if (GlobalState.main.db_meeting.ghost_mode) {
		Object.keys(raw_widget_obj).forEach(
			(k) =>
			(widget_obj[k] = {
				...Encryption.encrypt(raw_widget_obj[k]),
				name: raw_widget_obj[k].name,
			})
		)
	} else {
		widget_obj = raw_widget_obj
	}

	LoopApi('main', 'AddWidget', { widgets: widget_obj })
}

function purgeWidget(name, raw_delta) {
	const delta = GlobalState.main.db_meeting.ghost_mode
		? Encryption.encrypt(raw_delta)
		: raw_delta

	LoopApi('main', 'PurgeWidget', { name, delta })
}

async function updateWidget(name, raw_data = {}) {
	const data = GlobalState.main.db_meeting.ghost_mode
		? { ...raw_data, delta: Encryption.encrypt(raw_data.delta) }
		: raw_data

	LoopApi('main', 'UpdateWidget', { name, data })
}

async function deleteWidget(widgetName) {
	LoopApi('main', 'DeleteWidget', {
		name: widgetName,
	})
}

async function clearWidget(widgetName) {
	LoopApi('main', 'ClearWidget')
}

async function kickUser(id) {
	LoopApi('main', 'KickUser', {
		id,
	})
}

async function sendBufferedKeyUpdate(data) {
	LoopApi('main', 'BufferedKeyUpdate', data)
}

const websocketActionMapper = {
	MeetingData: 'SetMeeting',
	UserData: 'SetUsers',
	WidgetData: 'SetWidgets',
	AddWidget: 'SetWidgets',
	DeleteWidget: 'DeleteWidget',
	ClearWidget: 'ClearWidget',
	UpdateWidget: 'TryUpdateWidget',
	FetchMeetings: 'GetMeetings',
	BufferedKeyUpdate: 'BufferedKeyUpdate',
	UserWidgetScroll: 'UserWidgetScroll',
	RequestPurgeWidget: 'RequestPurgeWidget',
	TranscriptionResult: 'TranscriptionResult',
	SetCurrentWidget: 'SetCurrentWidget',
}

function websocketConnected(query) {
	// Alert.success('Connected to meeting')
	MainActions.MeetingConnection('Connected')
	Clients.Emit('main', 'MeetingRequest', query, WidgetActions.SetMeeting)
}

async function websocketMessage(msg) {
	// //console.log('got msg')
	// When we recieve a websocket message we check if the action is explicitly defined in our store actions.
	// If it is then we run the action in our stores (hop over to stores/MainStore.js)
	if (!WidgetActions[websocketActionMapper[msg.action]]) {
		console.error('Action not found!', msg, WidgetActions)
	}

	await WidgetActions[websocketActionMapper[msg.action]](msg.data)
}

function websocketDisconnect(_reason) {
	const reason = Object.values(_reason).join('')

	if (reason.includes('io server disconnect') || _reason === 'io server disconnect') {
		// Alert.warning('Reconnecting 🔌')
		MainActions.MeetingConnection('Kicked')
	} else if (reason.includes('io client disconnect') || _reason === 'io client disconnect') {
		MainActions.MeetingConnection('Disconnected')
	} else {
		// Alert.warning('Disconnected from meeting')
		MainActions.MeetingConnection('Reconnecting')
	}
}

function websocketReconnectFailed() {
	MainActions.MeetingConnection('Unreachable')
}

async function messageBubble(msg) {
	if (
		msg.origin === (process.env.REACT_APP_API_URL || 'http://localhost:8000')
	) {
		const data = JSON.parse(msg.data)
		if (!data.strategy) {
			return
		}
		const widgetConfig =
			Widgets[data.strategy] && Widgets[data.strategy].widgetConfig
		if (!widgetConfig) {
			return
		}
		if (widgetConfig.oauthType === 'private') {
			AuthActions.SetExternalToken(
				data.strategy,
				data.token,
				data.refresh_token
			)
		} else {
			WidgetActions.UpdateWidget({ name: data.strategy, token: data.token })
		}
	}
}

function meetingLogoutProcess() {
	Clients.ClearSockets()
	MainActions.WipeMeeting()
}

function initBotMeeting(meetingName, passed_token) {
	return new Promise(async (done, err) => {
		const resp = await LoopApi(null, 'GetMeeting', {}, [['name', meetingName]])
		MainActions.DbMeeting(resp.dbMeeting)
		meetingLoginProcess(meetingName, passed_token, true)
		done()
	})
}

function initMeeting(
	meetingName,
	changeRoute = () => { },
	flow = null,
	externalToken = null
) {
	return new Promise(async (done, err) => {
		try {
			let resp = null // Meeting info
			let token = null // Token Info

			const allowedToJoin = sessionStorage.getItem('roomsAllowed')
			let parsedAllowedToJoin = isJSON(allowedToJoin) ? JSON.parse(allowedToJoin) : {}

			if (flow) {
				const { token: returnedToken, user, dbMeeting } = await LoopApi(
					null,
					'ExternalFlowCheckWorkspaceAndToken',
					{
						meetingName,
						externalToken,
						flow,
					}
				)
				resp = { dbMeeting }
				localStorage.token = returnedToken
				token = jwtDecode(returnedToken)
				AuthActions.SetJwt()
			} else {
				// If the user doesn't have a token they're PROBABLY a guest
				if (!localStorage.token) {
					AuthActions.IntendedJoin(meetingName)
					const public_data = await LoopApi(
						null,
						'CheckIfMeetingIsPublic',
						{},
						[['name', meetingName]]
					)

					// If the meeting doesn't have public data we don't want them to join as a guest
					// if (public_data && public_data.exists && !public_data.is_public) {
					// 	Alert.error('Meeting is not open to the public')
					// }

					AuthActions.FinishedCheckMeeting()
					if (!public_data || !public_data.exists) {
						changeRoute(`/login/${meetingName}`)
					} else if (public_data.expired) {
						changeRoute(`/expired/${meetingName}`)
					}
					else {
						changeRoute(`/choose-login/${meetingName}`)
					}
					return err(new Error({ handled: true }))
				}

				// Give us the token info!
				token = jwtDecode(localStorage.token)

				if (token.exp - Math.round(Date.now() / 1000) < 0) {
					// If the token is expired send them to login (and then rejoin meeting)
					AuthActions.IntendedJoin(meetingName)
					changeRoute('/login')
					Alert.warning('Login again to continue')
					return err(new Error({ handled: true }))
				}

				// //console.log('ELECTRON: fetching meeting')
				// If the user is logged in with a valid token, try to get the meeting info

				if (!!!token) throw new Error('invalid token in init meeting')
				try {
					resp = await LoopApi(null, 'GetMeeting', { bypass: parsedAllowedToJoin[meetingName] || false }, [['name', meetingName]])
				} catch (error) {
					throw error
				}
			}

			let expiry = resp && resp.dbMeeting && resp.dbMeeting.expiry ? resp.dbMeeting.expiry : ''
			let DateTimeNow = new Date().getTime()

			// If they aren't authorized to join the meeting give 'em a chance to knock
			if (resp.error && resp.allow_knocking && !!!parsedAllowedToJoin[meetingName]) {
				return done('knock')
			} else if (resp.error) {
				return done('unauthorized')
			} else if (new Date(expiry).getTime() < DateTimeNow) {
				// console.log('Meeting Expired')
				return done('expired')
			} else if(resp?.dbMeeting?.password && GlobalState.auth.jwt.data._id !== resp?.dbMeeting?.creator_user_id && !!!parsedAllowedToJoin[meetingName]) {
				return done('password')
			}



			if (
				!!resp.dbMeeting.password &&
				!resp.dbMeeting.user_ids.find((u) => u === token.data._id)
			) {
				if (resp.dbMeeting.ghost_mode) {
					return done('password-join-ghost')
				}
				return done('password')
			}


			if (
				resp.dbMeeting.ghost_mode &&
				(!sessionStorage.ghost_hash ||
					sessionStorage.ghost_meeting !== meetingName)
			) {
				return done('password-ghost')
			}

			if (resp.dbMeeting.locked) {
				return done('locked')
			}

			let dbMeeting = resp.dbMeeting
			const widgetsOrder = resp.widgetsOrder

			if (dbMeeting && dbMeeting.settings && dbMeeting.settings.color_scheme) {
				dbMeeting = {
					...dbMeeting,
					settings: {
						...dbMeeting.settings,
						color_scheme: localStorage.getItem('color_scheme') || dbMeeting.settings.color_scheme
					}
				}
			}

			// Join meeting normally
			MainActions.DbMeeting(dbMeeting)
			WidgetActions.ReorderWidgets(widgetsOrder)

			// startSync(meetingName)

			meetingLoginProcess(meetingName, localStorage.token)
			done('awaiting-meeting')
		} catch (e) {
			if (err.errorCode === 422) return done('archived')
			if (err.errorCode === 404) return done('notfound')
			if (err === 'invalid token in init meeting') {
				localStorage.removeItem('token')

				if (localStorage.getItem('audio_input_device_id')) {
					localStorage.removeItem('audio_input_device_id')
				}
				if (localStorage.getItem('video_input_device_id')) {
					localStorage.removeItem('video_input_device_id')
				}
				if (localStorage.getItem('audio_output_device_id')) {
					localStorage.removeItem('audio_output_device_id')
				}
				this.props.history.push(`/login`)
			}
			return err(e)
		}
	})
}

// function connectToSFUSocket(token) {
// 	Clients.setSFUClient(new SFU())

// 	Clients.ConnectWithListeners('sfu', 'https://sfu.blocktalk.com', {
// 		SFUData: (m) =>
// 			m.connected &&
// 			CallingActions.SetParticipants(m.connected, m.recording, m.bot_connected),
// 		error: (e) => console.error(e),
// 		SFU: (d) => Clients.SFU_client.handle_message(d),
// 		connect: () => Clients.Emit('sfu', 'authenticate', { token }),
// 		reconnecting: () => //console.log('reconnecting!'),
// 	})
// }

const socketUrl = process.env.REACT_APP_WS_URL || 'wss://localhost:8000'

const listenedEvents = ['WebRTCSignal', 'DenyJoinRoom', 'ToggleRecording']
class BaseWsSpecificActionEmitter extends EventEmitter {
	constructor() {
		super()
	}
	EmitWsMsgToListeners(actionName, ...params) {
		this.emit(actionName, ...params)
	}
}
export const WsSpecificActionEmitter = new BaseWsSpecificActionEmitter()

let connection_phrase = null
function meetingLoginProcess(name, password = null, skip_tokens = false) {
	MainActions.MeetingConnection('Connecting')
	if (password) {
		connection_phrase = password
	}

	Clients.ConnectWithListeners('main', `${socketUrl}/${name}`, {
		error: (e) => {
			websocketReconnectFailed()
			console.error(e)
		},
		connect: () =>
			websocketConnected({
				token: localStorage.token,
				password: connection_phrase,
			}),
		message: websocketMessage,
		disconnect: websocketDisconnect,
		reconnect_failed: websocketReconnectFailed,
		connect_error: (e) => console.error('failed', e),
		Transcription: transcriptionMessage,
		DbUpdate: (d) => MainActions.DbMeeting(d, true),
		NewFile: MainActions.AddMeetingFile,
		RemoveFile: MainActions.RemoveMeetingFile,
		UpdateFile: MainActions.UpdateMeetingFile,
		WidgetDataCreated: MainActions.WidgetDataCreated,
		knock: MainActions.UserKnocking,
		Notification: notificationMessage,
		Presentation: MainActions.Presentation,
		PresentedWidget: NotificationActions.PresentedWidget,
		VD_Bitmap: NotificationActions.VD_Bitmap,
		WebRTCParticipantChange: VariableCallingActions.SetConnectedUserIds,
		transferMeetingHost: VariableCallingActions.SetCallStarter,
		askHostAccess: VariableCallingActions.RequestHostAccess,
		notifyMeetingRecording: VariableCallingActions.MeetingRecording,
		toggleLockMeeting: VariableCallingActions.ToggleLockMeeting,
		NotificationUpdate: NotificationActions.NotificationUpdate,
		notifyRemovedUser: NotificationActions.NotifyRemovedUser,
		notifyStartMeeting: VariableCallingActions.NotifyStartMeeting,
		notifyJoinMeeting: VariableCallingActions.NotifyJoinMeeting,
		notifyLeftMeeting: VariableCallingActions.NotifyLeftMeeting,
		saveMeetingSession: VariableCallingActions.SaveMeetingSession,
		setHost: VariableCallingActions.SetHost,
		setLock: VariableCallingActions.SetLock,
		endRoom: VariableCallingActions.EndRoom,
		RemoveKnockingToaster: MainActions.RemoveKnockingToaster,
		saveMeetingPariticipants: VariableCallingActions.SaveMeetingPariticipants,
		muteUser: VariableCallingActions.MuteUser,
		toggleRecording: VariableCallingActions.ToggleRecording,
		starTranscription: MainActions.SaveStaredTranscription,
		unstarTranscription: MainActions.DeleteStarTranscription,
		denyHostAccess: VariableCallingActions.DenyHostRequest,
		...listenedEvents.reduce(
			(prev, curr) => ({
				...prev,
				[curr]: (...msg) => {
					WsSpecificActionEmitter.EmitWsMsgToListeners(curr, ...msg)
				},
			}),
			{}
		),
		SetUpdateUser: MainActions.SetUpdateUser
	})

	NotificationActions.SetMeetingCall(name, false)
	NotificationActions.SetMeetingBadgeCount(name, 0)
	NotificationActions.SetNotifications()

	if (!skip_tokens) {
		getAccessTokens()
	}
}

const getAccessTokens = async () => {
	try {
		const { tokens } = await LoopApi(null, 'AccessTokens')
		tokens &&
			Object.entries(tokens).forEach(([key, val]) => !val && delete tokens[key])
		AuthActions.SetTokens(tokens)
	} catch (e) {
		console.error(e)
	}
}

function notificationMessage(payload) {
	try {
		// new Audio(notifierSound).play()
	} catch (_) { }

	if (!document.hasFocus() || payload.meeting !== localStorage.lastMeeting) {
		const title = `${payload.widget || 'Activity'} in ${payload.meeting}`
		const opts = {
			icon: '/512x512.png',
			badge: '/192x192.png',
		}
		if (payload.body) {
			opts.body = payload.body
		}
		if ('Notification' in window) {
			new Notification(title, opts)
		}
	}

	if (payload.meeting && payload.meeting !== localStorage.lastMeeting) {
		// NotificationActions.SetMeetingCall(payload.meeting)
		NotificationActions.SetMeetingBadgeCount(payload.meeting)
	}
}

async function transcriptionMessage(msg) {
	const text = GlobalState.main.db_meeting.ghost_mode
		? Encryption.decrypt({ text: msg.text }).text
		: msg.text

	MainActions.AddTranscription({ ...msg, text })
}

function getSFUClient() {
	return Clients.SFU_client
}

export default {
	Clients,
	subscribe_now,
	emitWidgetScroll,
	changeSetting,
	initBotMeeting,
	initMeeting,
	websocketReconnectFailed,
	meetingLogoutProcess,
	meetingLoginProcess,
	addWidget,
	addWidgets,
	updateWidget,
	purgeWidget,
	deleteWidget,
	clearWidget,
	kickUser,
	websocketMessage,
	messageBubble,
	pokeInSlack,
	getSFUClient,
	sendBufferedKeyUpdate,
	// connectToSFUSocket,
	WsSpecificActionEmitter,
}
