import styled from 'styled-components'

export const ParticipantsTileWrapper = styled.div`
    position: relative;
    vertical-align: middle;
    align-self: center;
    overflow: hidden;
    display: inline-block;
    background: #fff;
    animation: show 0.4s ease;

    &.presentationParticipant {
        height: 100%;
        width: 100%;
    }

    &:hover {
        .call-control, .call-more-controls-container .call-control {
            opacity: 1;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
            transform: scale(0.4) translateY(20px);
        }
        100% {
            opacity: 1;
            transform: scale(1) translateY(0);
        }
    }
`

export const CallContainer = styled.div`
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;

    @media (max-width: 970px) {
        top: 160px;
    }
`