import styled from "styled-components";

export const AspectRatioEnforcer = styled.div`
/* padding-top: 100%; */
height: 100%;
width: 100%;
background-color: black;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`;

export const Background = styled.div<{ avatar_url: string }>`
background-image: url("${(props) => props.avatar_url}");
background-size: cover;
background-position: center;
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
display: block;
`;

export const MutedWrapper = styled.div`
position: absolute;
top: 10px;
right: 10px;
text-align: center;
font-size: 12px;
overflow: hidden;
text-overflow: ellipsis;
z-index: 1;
`;

export const IconButtonMute = styled.div<{ muted?: boolean }>`
color: white;
padding: 5px !important;
display: flex;
align-items: center;
justify-content: center;
margin: 0 4px;
border-radius: 2px;
font-size: 16px;
transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
opacity: 0;
position: relative;
${(props) => (props.muted ? "opacity: 1!important;" : "")}
cursor: pointer;

-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
`;

export const XLine = styled.div`
position: absolute;
height: 1px;
width: 16px;
background-color: #fff;
transform: rotate(135deg);
pointer-events: none;
`;
