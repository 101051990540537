import {Room} from 'twilio-video'
import {useEffect} from 'react'

import {Callback} from './types'
import { CallingProviderName } from '../../../../../contexts/types'
import { DailyCall } from '@daily-co/daily-js'

export default function useHandleTrackPublicationFailed(
  room: Room | DailyCall | null,
  onError: Callback,
  callProvider?: CallingProviderName
) {
  useEffect(() => {
    if (room) {
      if(
        callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard
      ) {
        (room as Room).localParticipant.on('trackPublicationFailed', onError)
        return () => {
          (room as Room).localParticipant.off('trackPublicationFailed', onError)
        }
      }
    }
  }, [room, onError, callProvider])
}
