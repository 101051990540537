import React from 'react';
import styled from 'styled-components';
import FormField from '../../_Shared/FormField';
import TextareaAutosize from 'react-autosize-textarea';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import Select from '../../_Shared/Select';

const CreateContact = (props: any) => {
  const [data, setData] = React.useState({ company: null });
  const [error, setError] = React.useState('');
  const { UpdateSelf } = props.actions;
  const { getContacts } = props;
  const companies = props.data.companies.results;

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const createContact = async () => {
    const new_contact = await Api(Endpoints['Hubspot'], Actions['CreateContact'], props.external_token, { body: { properties: data } });
    //console.log(new_contact);
    if (new_contact.status === 'error') return setError('There was an error creating your account.');
    await getContacts();
    UpdateSelf({ screen: 'contacts' });
  };

  return (
    <CreateContactContainer>
      <Button className='button default' onClick={createContact} ref={props.useRef}>
        Create
      </Button>
      <CustomFormField
        label='Company'
        field={
          <div>
            <Select
              hoverEffect
              fitContent
              name='company'
              placeholder={'Select a company'}
              defaultValue={data.company || ''}
              onChange={handleChange}
              optionsList={companies.map((c: any) => {
                const { name, hs_object_id } = c.properties;
                return {
                  value: hs_object_id,
                  label: (name || "").toUpperCase(),
                };
              })}
            />
          </div>
        }
      />
      <CustomFormField
        label='First Name'
        field={
          <div>
            <TextareaAutosize
              name='firstname'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter First Name'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Last Name'
        field={
          <div>
            <TextareaAutosize
              name='lastname'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Last Name'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Email'
        field={
          <div>
            <TextareaAutosize
              name='email'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Email'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Phone'
        field={
          <div>
            <TextareaAutosize
              name='phone'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Phone'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <CustomFormField
        label='Website'
        field={
          <div>
            <TextareaAutosize
              name='website'
              //value={}
              className='textarea hover-container task-title'
              placeholder='Enter Website'
              onResize={(e) => {}}
              onChange={handleChange}
            />
          </div>
        }
      />
      <Error className="error">{error}</Error>
    </CreateContactContainer>
  );
};

export default CreateContact;

const CreateContactContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const CustomFormField = styled(FormField)`
  align-self: stretch;
`;

const Button = styled.div`
  align-self: flex-start;
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;

const Error = styled.p`
`;
