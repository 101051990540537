import React from "react"
import styled from "styled-components"
import Downshift from "downshift"
import { FiChevronDown as ChevronDown } from "@react-icons/all-files/fi/FiChevronDown"
import { DropdownListItem } from "../loop-widgets/Dropbox/DropdownOptions"

interface Item {
	name: string;
	value: any;
	disabled: boolean;
}

interface Props {
	name: string;
	items: Item[];
	value: any;
	inputWidth?: number;
	rounded?: boolean
	onChange: (item: Item, name: string) => void;
	customIcon?: React.ReactElement
	disabled?: boolean
	dropdownIcon?: React.ReactNode
}

function Dropdown(props: Props) {
	const {name, items, value, inputWidth, onChange, rounded = true, customIcon, disabled=false, dropdownIcon = null} = props

	const selectedItem = items.find(item => item.value === value)

	return (
		<Downshift
			itemToString={item => item ? item.name : ""}
			onChange={(item: Item) => onChange(item, name)}
			initialSelectedItem={value}
			stateReducer={(state, changes) => {
				// if opening the menu, then ensure highlighted index is set to the selected index,
				// if closing the menu, then ensure highlighted index is set to null:
				if (changes.hasOwnProperty('isOpen')) {
				  return {
					...changes,
					highlightedIndex: changes.isOpen ? items.map((item) => item?.value || item).indexOf((state?.selectedItem?.value || state?.selectedItem || '').toString()) : null
				  };
				}
				return changes;
			  }}
		>
			{props => (
				<Container {...props.getRootProps()}>
					<InputContainer {...props.getToggleButtonProps()} inputWidth={inputWidth} rounded={rounded} disabled={disabled} title={selectedItem?.name || ''} dropdownIcon={dropdownIcon}>
						{dropdownIcon}
						<input
							className="border-light topbar hover-container"
							name={name}
							readOnly={true}
							value={selectedItem?.name || ""}
							disabled={disabled}
						/>
						{customIcon || <ChevronDown />}
					</InputContainer>
					{
						!!!disabled && 
						<List {...props.getMenuProps()} className="topbar">
							{props.isOpen ? (
								items.map((item, index) => (
									<ListItem
										{...props.getItemProps({
											key: item.value,
											index,
											item,
											className: `${
												selectedItem?.value === item.value
												? 'container'
												: 'topbar hover-container border-light'
											}`
										})}
										// className="topbar hover-container border-light"
										disabled={item.disabled}
									>
										{item.name}
									</ListItem>
								))
							) : null}
						</List>
					}

				</Container>
			)}
		</Downshift>
	)
}

const Container = styled.div`
	position: relative;
`

const InputContainer = styled.div<{ inputWidth: number, rounded: boolean, disabled: boolean, dropdownIcon: any }>`
	position: relative;
	cursor: pointer;

	${props => props.dropdownIcon ? `
		& > svg:first-child {
			position: absolute;
			left: 14px;
			top: 10px;
		}
	` : ''}

	& > input + svg {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	& > input {
		padding: ${props => props.dropdownIcon ? '8px 24px 8px 44px' : '8px 24px 8px 16px'};
		border: 1px solid;
		border-radius: ${props => props.rounded ? '32px' : '5px'};
		cursor: pointer;
		color: #000;
		width: ${props => props.inputWidth ? `${props.inputWidth}px` : props.dropdownIcon ? 'calc(100% - 68px)' : 'calc(100% - 40px)' };
	}

	${props => props.disabled && `
		cursor: not-allowed;
		opacity: 0.5;

		& > input {
			cursor: not-allowed;
		}
	`}
`

const List = styled.ul`
	position: absolute;
	width: 100%;
	z-index: 2;
	box-shadow: ${props => props.theme.shadows.newheavy};
	text-align: left;
	max-height: 250px;
	overflow: overlay;
`

const ListItem = styled.li<{ disabled: boolean }>`
	padding: 10px 8px;
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
	pointer-events: ${props => props.disabled ? 'none' : null};
	/* border-bottom: 1px solid; */

	&.hover-container:focus {
		background: transparent;
	}
`

export default Dropdown
