import React, { Component } from 'react'
import styled from 'styled-components'

import WidgetActionBar from '../../components/WidgetActionBar'
import { WidgetRightContainer } from './Elements'

interface Props {
    pre?: React.ReactNode
    name?: string | React.ReactNode
    icon: string
    end?: React.ReactNode
    divider?: string | React.ReactNode
    size?: [number | string, number | string]
    onClick?: () => void
    alignEndCenter?: boolean
}

export default class WidgetHeader extends Component<Props> {
    render() {
        const { pre, name, icon, end, divider, size, onClick, alignEndCenter = false} = this.props

        return (
            <WidgetActionBar icon={icon} size={size} onClick={onClick}>
                {pre}

                {divider !== '' ? <WidgetActionBarDivider>{divider}</WidgetActionBarDivider> : null}

                <WidgetActionBarName>{name}</WidgetActionBarName>

                <WidgetRightContainer alignEndCenter={alignEndCenter}>{end}</WidgetRightContainer>
            </WidgetActionBar>
        )
    }
}

const WidgetActionBarName = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};
`

const WidgetActionBarDivider = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.textMD};
    font-weight: ${(props) => props.theme.textRegular};
    margin-right: 15px;
`
