import React, { useState } from 'react'
import styled from 'styled-components'

import LoopApi from '../../../helpers/LoopApi'
import Modal from '../../../components/Modalv2'

const { GlobalState } = require('reflux')

function CreateSpace(props: any) {
	const [ spaceName, setSpaceName ] = useState('')
	const [ errorMsg, setErrorMsg ] = useState<string | null>('')
	const [ creating, setCreating ] = useState<boolean>(false)

	const updateValue = (e: any) => {
		let sName = e.target.value
		if (sName.length > 20) return setErrorMsg('Space name should not exceed more than 20 characters')

		setSpaceName(sName)
	}

	const onSubmit = async (e: any) => {
		e.preventDefault()
		setErrorMsg(null)
		setCreating(true)

		try {
			const response = await LoopApi(null, 'CreateSpace', {
				name: `${spaceName}`.trim(),
			})
			setCreating(false)
			GlobalState.modal.onSubmit && GlobalState.modal.onSubmit(response)
			props.closeModal && props.closeModal()
		} catch (e) {
			//console.log(e)
			setErrorMsg('Uh oh... Something went wrong...')
			setCreating(false)
		}
	}

	return (
		<Modal closeModal={() => {
			GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
			props.closeModal()
		}} dismissible={!!!creating} >
			<Container className="topbar-modal">
				<Title className="header">Create a New Space</Title>
				<Subtitle className="subheader">Organize your rooms by creating spaces</Subtitle>
				<NameForm onSubmit={onSubmit}>
					<Input
						className="border-light topbar"
						type="text"
						value={spaceName}
						onChange={updateValue}
						placeholder={`Enter space name`}
						autoFocus
					/>
					<SubmitButton
						className="button primary"
						type="submit"
						value={creating ? 'Creating' : 'Create'}
						disabled={spaceName?.trim()?.length === 0 || creating}
					/>
					{errorMsg && (
						<ErrorText className="error">{errorMsg}</ErrorText>
					)}
					{/* <SwitchLink onClick={this.toggleCreate}>{switchText}</SwitchLink> */}
				</NameForm>
			</Container>
		</Modal>
	)
}

export default CreateSpace

const ErrorText = styled.div`
	margin-top: 15px;
	text-align: center;
`
const Subtitle = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	text-align: center;
`

const SwitchLink = styled.div`
	text-decoration: underline;
	text-align: center;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;
`

const SubmitButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const NameForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 30px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 352px;
	z-index: 3;
`

const Input = styled.input`
	padding: 8px !important;
	border-radius: 32px !important;
	border: 1px solid;
	font-size: 16px !important;
	background: none;
`
