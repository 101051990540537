import React from 'react'
import styled from 'styled-components'

export const Initials = styled.div<{avatar_url: string}>`
  background-image: url('${(props) => props.avatar_url}');
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
`

const MutedWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
`

export const IconButtonMute = styled.div<{muted?: boolean}>`
  color: white;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 2px;
  font-size: 16px;
  transition: opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  opacity: 0;
  position: relative;
  ${(props) => (props.muted ? 'opacity: 1!important;' : '')}
  cursor: pointer;
`

const XLine = styled.div`
  position: absolute;
  height: 1px;
  width: 16px;
  background-color: #fff;
  transform: rotate(135deg);
  pointer-events: none;
`

export const MutedIcon = () => (
  <MutedWrapper>
    <IconButtonMute className="button" muted={true}>
      {/* <img src={VolumeIcon} width={11} /> */}
      <VolumeIcon />
      <XLine className="bg-primary" />
    </IconButtonMute>
  </MutedWrapper>
)

const VolumeIcon = () => {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-fill-primary"
    >
      <g filter="url(#filter0_d)">
        <path d="M4 5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2C7.79565 2 8.55871 2.31607 9.12132 2.87868C9.68393 3.44129 10 4.20435 10 5V10C10 10.7956 9.68393 11.5587 9.12132 12.1213C8.55871 12.6839 7.79565 13 7 13C6.20435 13 5.44129 12.6839 4.87868 12.1213C4.31607 11.5587 4 10.7956 4 10V5Z" />
      </g>
      <g filter="url(#filter1_d)">
        <path d="M2.5 8.5C2.63261 8.5 2.75979 8.55268 2.85355 8.64645C2.94732 8.74021 3 8.86739 3 9V10C3 11.0609 3.42143 12.0783 4.17157 12.8284C4.92172 13.5786 5.93913 14 7 14C8.06087 14 9.07828 13.5786 9.82843 12.8284C10.5786 12.0783 11 11.0609 11 10V9C11 8.86739 11.0527 8.74021 11.1464 8.64645C11.2402 8.55268 11.3674 8.5 11.5 8.5C11.6326 8.5 11.7598 8.55268 11.8536 8.64645C11.9473 8.74021 12 8.86739 12 9V10C12 11.2395 11.5396 12.4349 10.7081 13.3541C9.87663 14.2734 8.73332 14.851 7.5 14.975V17H10.5C10.6326 17 10.7598 17.0527 10.8536 17.1464C10.9473 17.2402 11 17.3674 11 17.5C11 17.6326 10.9473 17.7598 10.8536 17.8536C10.7598 17.9473 10.6326 18 10.5 18H3.5C3.36739 18 3.24021 17.9473 3.14645 17.8536C3.05268 17.7598 3 17.6326 3 17.5C3 17.3674 3.05268 17.2402 3.14645 17.1464C3.24021 17.0527 3.36739 17 3.5 17H6.5V14.975C5.26668 14.851 4.12337 14.2734 3.29188 13.3541C2.46038 12.4349 1.99998 11.2395 2 10V9C2 8.86739 2.05268 8.74021 2.14645 8.64645C2.24021 8.55268 2.36739 8.5 2.5 8.5Z" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="2"
          y="0"
          width="10"
          height="15"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="0"
          y="6.5"
          width="14"
          height="13.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export const ParticipantContainer = styled.div<{
  avatar: string
  isScreenSharing: boolean
}>`
  background-size: cover;
  background-position: center;
  /* background-image: ${(props) =>
    props.avatar ? `url("${props.avatar || ''}")` : ''}; */
  cursor: ${(props) => (props.isScreenSharing ? `pointer` : `unset`)};
  background-repeat: no-repeat;

  animation: show 0.4s ease;

  &.presentationParticipant {
    height: 100%;
    width: 100%;
  }

  .button {
    cursor: pointer;
  }

  .button.disabled {
    opacity: 0 !important;
    cursor: not-allowed !important;
  }

  &:hover {
    .call-control,
    .call-more-controls-container .call-control {
      opacity: 1;
    }

    .button.disabled {
      opacity: 0.5 !important;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.4) translateY(20px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
`
