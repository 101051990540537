import React, { ReactNode } from 'react'
import styled from "styled-components";
import _props from '../../styles/Theme'

import { TButtonVariant, TButtonColor } from '../../props/Types'

import {
    Button
} from '@material-ui/core';

export function StyledButton({ variant = "outlined", color = "primary", type = "button", disabled = false, onClick = () => {}, children = <></>, isrounded = 'false', fullWidth = false, style = {} }: {
    variant?: TButtonVariant
    color?: TButtonColor
    disabled?: boolean
    type?: any
    onClick?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
    children?: ReactNode | string
    isrounded?: boolean | string
    fullWidth?: boolean
    style?: any
}) {
  return (
    <CustomButton variant={variant} type={type} disabled={disabled} color={color} onClick={onClick} style={style}>
        {children}
    </CustomButton>
  )
}

const CustomButton = styled(Button) <{ isrounded?: string, alwaysactive?: string, linkcolor?: string }>`
    height: 38px;
    padding: 4px 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* PRIMARY BUTTONS */
    &.ant-btn-primary:not(:disabled):not(.secondary):not(.default) {
        background: ${_props => _props.theme.colorPrimaryGradient};

        &:hover, &:focus {
            background: ${_props => _props.theme.colorPrimaryGradientLight};
        }
    }

    /* PRIMARY - DEFAULT COLOR BUTTONS */
    &.ant-btn-primary.default:not(:disabled):not(.secondary) {
        background: ${_props => _props.theme.colorText};

        &:hover, &:focus {
            background: ${_props => _props.theme.colorTextLight};
        }
    }

    &.ant-btn-primary:not(.ant-btn-circle) {
        border-radius: ${({ isrounded }) => isrounded === 'true' ? '30px' : '2px'};
        border: none;
    }

    /* DEFAULT PRIMARY BUTTONS */
    &.ant-btn-default:not(:disabled), &.ant-btn-default:not(.secondary) {
        ${({ alwaysactive, theme }) => alwaysactive === 'true' && `
            border-color: ${theme.colorPrimary};
        `}

        &:hover, &:focus {
            color: ${_props => _props.theme.colorPrimary};
            border-color: ${_props => _props.theme.colorPrimary};
        }
    }

    &.ant-btn-default:not(.ant-btn-circle) {
        border-radius: ${({ isrounded }) => isrounded === 'true' ? '30px' : '2px'};
    }



    /* SECONDARY BUTTONS */
    &.ant-btn-primary.secondary:not(:disabled) {
        background: ${_props => _props.theme.colorSecondary};

        &:hover, &:focus {
            background: ${_props => _props.theme.colorSecondaryLight};
        }
    }

    &.ant-btn-link.colorText {
        ${({ linkcolor }) => linkcolor && `color: ${linkcolor};`}

        &:hover, &:focus {
            color: ${_props => _props.theme.colorPrimary};
        }
    }
`
