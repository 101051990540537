import React, {Fragment} from 'react'
import styled from 'styled-components'
import { MdCancelPresentation, MdOutlineStopScreenShare } from 'react-icons/md'
import { SiSlideshare } from 'react-icons/si'
import { BsChevronDown } from 'react-icons/bs'
import DropdownWrapper from '../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import ShareMenu from './ShareMenu'

import {
  IFile,
} from "./types";

interface Props {
  children: any
  onPresent: Function
  changeIndexState: Function
  isPresenter: boolean
  viewPresenting: boolean
  isPresenterOnline: boolean
  hasPresentingFile: boolean
  colorScheme: string
  startViewing: Function
  file: IFile
  travelling: boolean
  currentFile: any
  parent: any
}

const FileHeader = (props: Props) => {

  const {
    onPresent,
    isPresenter,
    viewPresenting,
    changeIndexState,
    isPresenterOnline,
    hasPresentingFile,
    startViewing,
    file,
    currentFile,
    travelling,
    parent
  } = props

  return (
    <Container>
      {!!!file ? props.children : (
        <LeftControls>
          <span className="fs-filename">{currentFile?.filename || parent.props.data.file.filename || ''}</span>
        </LeftControls>
      )}
      {!travelling && (
        <RightControls
          isLight={props.colorScheme === 'Light'}
        >
          <Fragment>
              {
                  (!hasPresentingFile || !isPresenterOnline) &&
                  <Dropdown
                      width="200px"
                      DropdownItems={ShareMenu}
                      noright
                      title="Share"
                      onPresent={onPresent}
                  >
                      <Share>
                          <div>Share</div>
                          <div><BsChevronDown size={15} /></div>
                      </Share>
                  </Dropdown>
              }

              {isPresenter &&
                  <Present className="button default" onClick={() =>
                      changeIndexState({
                          modal: {
                              show: true,
                              type: 'FilesStopPresentation'
                          }
                      })}
                  >
                      <MdOutlineStopScreenShare size={22}/>
                      <span>Stop Presentation</span>
                  </Present>
              }

              {!viewPresenting && !isPresenter && isPresenterOnline &&
                  <Present className="button primary" onClick={() => startViewing()}>
                      <SiSlideshare size={22}/>
                      <span>View Presentation</span>
                  </Present>
              }

              {viewPresenting &&
                  <React.Fragment>
                      <Present className="button default" onClick={() =>
                          changeIndexState({
                              modal: {
                                  show: true,
                                  type: 'FilesStopViewingPresentation'
                              }
                          })}
                      >
                          <MdCancelPresentation size={22}/>
                          <span>Stop Viewing</span>
                      </Present>
                  </React.Fragment>
              }
          </Fragment>
        </RightControls>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`

const Present = styled.div` 
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 8px 12px !important;

  span {
      margin-left: 5px;
      white-space: nowrap;
  }
`

const RightControls = styled.div<{isLight: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px){
      flex-direction: row;
      align-items: center;
  }

  ${Present} {
      ${({isLight}) => isLight ? 'background: transparent;' : 'background: #414052;'}
  }
`

const LeftControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px){
      flex-direction: row;
      align-items: center;
      width: 100%;
  }

  .fs-filename {
    font-size: 16px;
    color: inherit;
    font-weight: bold;
  }
`


const Dropdown = styled(DropdownWrapper as any)`
  display: flex;
  justify-content: center;
  align-items: center;
	cursor: pointer;
	margin: 4px 5px;
  margin-right: 25px;
	font-size: 14px;
	position: relative;
`

const Share = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:nth-child(1) {
      margin-right: 20px;
  }
`

export default FileHeader