import moment from 'moment'
import React, { Dispatch } from 'react'
import styled from 'styled-components'
import { Marker, MarkerInnerWrapper, MarkerWrapper } from './index'

interface Props {
    day: string
    selectedDay: string
    selectedDate: string
    currentMY: string
    currentWeek: string
    changeSelectedDate: Dispatch<any>
    setSelectedData: Dispatch<any>
    transcriptions: any
    recordings: any
    syncs: any
}

function SingleDate(props: Props) {
    const { day, selectedDay, currentMY, changeSelectedDate, currentWeek, transcriptions, recordings, syncs, setSelectedData  } = props

    const today = moment().isSame(moment(day), 'day')
    const formattedDay = moment(day).format("D")
    const partOfMonth = moment(day).isSame(moment(currentWeek), 'month')
    const yourDate = moment(day).format("MMMM D, YYYY")

    const curRecordings = (recordings?.[yourDate] || []).filter((_: any, idx: number) => !!_.isRecording)

    return (
        <Container 
            className={`rounded inner singledate hover-container ${formattedDay === selectedDay && currentMY === moment(day).format("MMMM, YYYY") ? 'border-primary selected' : 'border-light'} ${!partOfMonth && 'disabled'}`}
            onClick={() => {
                changeSelectedDate(day)
                setSelectedData({
                    transcriptions: transcriptions?.[yourDate] || [],
                    recordings: recordings?.[yourDate] || [],
                    syncs: syncs?.[yourDate] || {}
                })
            }} 
        >
            <Day>{formattedDay}</Day>
            <LocaleDay>{moment(day).format("ddd")}</LocaleDay>
            <Today>{ today && 'Today'}</Today>
            <DayHover className="bg">{moment(day).format('D')}</DayHover>
            <MarkerWrapper>
                <MarkerInnerWrapper title={`Transcription (${transcriptions?.[yourDate]?.length || 0} records)`} >
                    {
                        (transcriptions?.[yourDate] || []).filter((_: any, idx: number) => idx < 5).map(() => {
                            return <Marker key={transcriptions._id} whichOne="Transcription" title={`Transcription (${transcriptions?.[yourDate]?.length} record${(transcriptions?.[yourDate]?.length || 0) > 1 ? 's' : ''})`} />
                        })
                    }
                </MarkerInnerWrapper>
                <MarkerInnerWrapper title={`Recording (${curRecordings?.length || 0} records)`}>
                    {
                        curRecordings.filter((_: any, idx: number) => idx < 5).map(() => {
                            return <Marker key={transcriptions._id} whichOne="Recording" title={`Recording (${curRecordings?.length} record${(curRecordings?.length || 0) > 1 ? 's' : ''})`} />
                        })
                    }
                </MarkerInnerWrapper>
                <MarkerInnerWrapper title={`Syncs (${Object.keys((syncs?.[yourDate] || {}))?.length || 0} records)`}>
                    {
                        (Object.keys((syncs?.[yourDate] || {}))).filter((_: any, idx: number) => idx < 5).map(() => {
                            return <Marker key={transcriptions._id} whichOne="Syncs" title={`Syncs (${Object.keys((syncs?.[yourDate] || {}))?.length} sync${(Object.keys((syncs?.[yourDate] || {}))?.length || 0) > 1 ? 's' : ''})`} />
                        })
                    }
                </MarkerInnerWrapper>
            </MarkerWrapper>
        </Container>
    )
}

const Container = styled.div`
    padding: 15px;
    border: 1px solid;
    position: relative;
    cursor: pointer;
    transition: 0.15s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &:hover .bg, &.selected .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
    }

    &.disabled {
        div:not(.bg) {
            opacity: 0.5;
        }
    }
`

const Day = styled.div`
    font-weight: 500;
    font-size: 25px;
`

const DayHover = styled.div`
    font-weight: 700;
    line-height: 1;
    opacity: 0;
    font-size: 8em;
    position: absolute;
    bottom: -0.2em;
    right: -0.05em;
    transition: 0.15s ease-out;
    letter-spacing: -0.07em;
`

const LocaleDay = styled.div`
    padding-top: 7px;
    font-size: 13px;
`

const Today = styled.div`
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    right: 15px;
    top: 17px;
`

export default SingleDate
