import React from 'react'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'

export type Thumbnail = 'none' | 'blur' | 'image'

interface BackgroundThumbnailProps {
  thumbnail: Thumbnail
  imagePath?: string
  name?: string
  icon?: any
  index?: number
  label?: string
}

export default function BackgroundThumbnail({
  thumbnail,
  imagePath,
  name,
  icon,
  label,
  index,
}: BackgroundThumbnailProps) {
  const {backgroundSettings, setBackgroundSettings} = useMeetingContext()
  const isImage = thumbnail === 'image'

  return (
    <div
      className="thumb-container"
      onClick={() =>
        setBackgroundSettings({
          type: thumbnail,
          index: index,
        })
      }
    >
      {isImage ? (
        <img
          src={imagePath}
          alt={name}
          className={`${
            backgroundSettings?.type === thumbnail &&
            index === backgroundSettings?.index
              ? 'border-primary'
              : 'border-light'
          }`}
        />
      ) : (
        <h1
          className={`rounded inner ${
            backgroundSettings?.type === thumbnail
              ? 'border-primary'
              : 'border-light'
          }`}
        >
          {icon || name}
        </h1>
      )}

      {label ? <div className="bg-option-label">{label}</div> : ''}
    </div>
  )
}
