import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../../../calling/_constants'
import {useCallback, useState} from 'react'
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
} from 'twilio-video'
import {
  getDeviceInfo,
  isPermissionDenied,
} from '../../../../../calling/_helpers'
import useToastify from '../../../../../helpers/useToastify'
import SuccessErrorToast from '../../../../../components/Notifications/SuccessErrorToast'
import useLocalStorage from '../hooks/useLocalStorage'

const {GlobalState} = require('reflux')

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>()
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false)

  const [audioInputId, setAudioInputId] = useLocalStorage(
    SELECTED_AUDIO_INPUT_KEY,
    '',
  )

  const getLocalAudioTrack = useCallback(() => {
    const options: CreateLocalTrackOptions = {}

    const deviceId = audioInputId

    if (deviceId) {
      options.deviceId = {exact: deviceId}
    }

    return Video.createLocalAudioTrack(options)
      .then((newTrack) => {
        if (audioTrack) {
          audioTrack.stop()
        }
        setAudioTrack(newTrack)
        return newTrack
      })
      .catch((err) => {
        //console.log('Failed to create local audio tracks: ', err)
        useToastify({
          message: () =>
            SuccessErrorToast({
              message: `Failed to create local audio tracks: ${err}`,
            }),
          position: 'top-right',
          autoClose: 1500,
          closeButton: false,
          hideProgressBar: true,
          className:
            GlobalState?.theming?.color_scheme === 'Light'
              ? 'toastL'
              : 'toastD',
          bodyClassName: 'grow-font-size',
        })
      })
  }, [])

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY,
    )

    const {videoInputDevices} = await getDeviceInfo()

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device: any) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    )

    const options: CreateLocalTrackOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && {
        deviceId: {exact: selectedVideoDeviceId!},
      }),
    }

    return Video.createLocalVideoTrack(options)
      .then((newTrack) => {
        //console.log('**twil', 'createLocalVideoTrack', {newTrack})
        if (videoTrack) {
          videoTrack.stop()
        }
        setVideoTrack(newTrack)
        return newTrack
      })
      .catch((err) => {
        //console.log('Failed to create local video tracks: ', err)
        useToastify({
          message: () =>
            SuccessErrorToast({
              message: `Failed to create local video tracks: ${err}`,
            }),
          position: 'top-right',
          autoClose: 1500,
          closeButton: false,
          hideProgressBar: true,
          className:
            GlobalState?.theming?.color_scheme === 'Light'
              ? 'toastL'
              : 'toastD',
          bodyClassName: 'grow-font-size',
        })
      })
  }, [])

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      //console.log('**twil', 'STOPPING LOCAL AUDIO TRACK')
      if (!audioTrack.isStopped) audioTrack.stop()
      setAudioTrack(undefined)
    }
  }, [audioTrack])

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      //console.log('**twil', 'STOPPING LOCAL VIDEO TRACK', {videoTrack})
      if (!videoTrack.isStopped) videoTrack.stop()
      setVideoTrack(undefined)
    }
  }, [videoTrack])

  const getAudioAndVideoTracks = useCallback(async () => {
    const {
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    } = await getDeviceInfo()

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve()
    if (isAcquiringLocalTracks || audioTrack || videoTrack)
      return Promise.resolve()

    setIsAcquiringLocalTracks(true)

    const selectedAudioDeviceId = audioInputId
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY,
    )

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device: any) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
    )
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device: any) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    )

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.

    // @ts-ignore
    const isCameraPermissionDenied = await isPermissionDenied('camera')
    // @ts-ignore
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied
    const shouldAcquireAudio =
      hasAudioInputDevices && !isMicrophonePermissionDenied

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: {exact: selectedVideoDeviceId!},
        }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice
          ? {deviceId: {exact: selectedAudioDeviceId!}}
          : hasAudioInputDevices),
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find(
          (track) => track.kind === 'video',
        ) as LocalVideoTrack
        const newAudioTrack = tracks.find(
          (track) => track.kind === 'audio',
        ) as LocalAudioTrack
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack)
          // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          window.localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? '',
          )
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack)
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error()
          error.name = 'NotAllowedError'
          throw error
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied')
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied')
        }
      })
      .catch((err) => {
        //console.log('Failed to create local audio and video tracks: ', err)
        useToastify({
          message: () =>
            SuccessErrorToast({
              message: `Failed to create local audio and video tracks: ${err}`,
            }),
          position: 'top-right',
          autoClose: 1500,
          closeButton: false,
          hideProgressBar: true,
          className:
            GlobalState?.theming?.color_scheme === 'Light'
              ? 'toastL'
              : 'toastD',
          bodyClassName: 'grow-font-size',
        })
      })
      .finally(() => setIsAcquiringLocalTracks(false))
  }, [audioTrack, videoTrack, isAcquiringLocalTracks])

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined,
  ) as (LocalAudioTrack | LocalVideoTrack)[]

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  }
}
