import React from 'react'
import styled from 'styled-components'
import { MainActions } from '../stores/MainStore'

interface Props {
    label?: string
    checked: boolean
    onChange: (e: any) => void
}

function Toggle(props: Props) {
    const { label, checked, onChange = () => {} } = props
    return (
        <ToggleWrapper>
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={onChange} />
                <span className="slider slider200"></span>
            </label> { label }
        </ToggleWrapper>
    )
}

const ToggleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .switch {
        display: inline-block;
        height: 20px;
        width: 40px;
        margin: 4px;
        position: relative;

        input {
            display: none;

            &:checked + .slider:before {
                transform: translateX(20px); /*translateX(w-h)*/
                /* background-color: limeGreen; */
            }

            &:checked + .slider {
                /* box-shadow: 0 0 0 2px limeGreen, 0 0 8px limeGreen; */
            }

        }

        .slider {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 30px;
            box-shadow: 0 0 0 2px #777, 0 0 4px #777;
            cursor: pointer;
            border: 3px solid transparent;
            overflow: hidden;
            transition: 0.2s;

            &:before {
                position: absolute;
                content: "";
                height: 100%;
                background-color: #777;
                border-radius: 30px;
                transition: 0.2s;
                width: 200%;
                transform: translateX(-54px);
            }
        }
    }
`

export default Toggle
