import React, {ChangeEvent, Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {BiChevronRight} from "@react-icons/all-files/bi/BiChevronRight"
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai'
import {FolderStackItem} from "../types"
import WidgetHeader from '../_Shared/Header'
import DropdownWrapper from '../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import ShareMenu from './ShareMenu'
import { BsChevronDown } from 'react-icons/bs'
import { MdCancelPresentation, MdOutlineStopScreenShare } from 'react-icons/md'
import { SiSlideshare } from 'react-icons/si'

interface Props {
	folderStack: FolderStackItem[]
	searchFile: (fileName: string) => void
	searchFilter: string
	back: Function
	startViewing: Function
	onPresent: Function
	colorScheme: string
	file: any
	isPresenter: boolean
	viewPresenting: boolean
	isPresenterOnline: boolean
	hasPresentingFile: boolean
	unauthenticatedViewer: boolean
	isPresentingOrViewing: boolean
	changeIndexState: Function
	changeFolder: Function
}

function DropboxHeader(props: Props) {
	const {
		folderStack,
		searchFile,
		searchFilter,
		back,
		file,
		startViewing,
		onPresent,
		colorScheme,
		isPresenter,
		viewPresenting,
		isPresenterOnline,
		hasPresentingFile,
		unauthenticatedViewer,
		changeIndexState,
		isPresentingOrViewing,
		changeFolder
	} = props

	const searchFileHandler = (evt: ChangeEvent<HTMLInputElement>) => {
		searchFile(evt.target.value)
	}

	const [fStack, setFStack] = useState<FolderStackItem[]>([]);

	useEffect(() => {
        let folders: FolderStackItem[];
        const fs = (limit: number) => folderStack.length > limit ? folderStack.slice(folderStack.length - limit) : folderStack
		folders = fs(2)
        folders && setFStack(folders);
    }, [props])

	let crumbs = null
	if(file && (isPresenter || viewPresenting || unauthenticatedViewer)) {
		crumbs = (
			<Fragment>
				<BreadCrumb
					type="button"
					className="breadcrumb-button filename"
				>
					{file?.file.name}
				</BreadCrumb>
			</Fragment>
		)
	} else {
		crumbs = fStack && fStack.map((folder, index, arr) => (
			<Fragment>
				<BreadCrumb
					type="button"
					key={folder.path}
					onClick={() => changeFolder(folder.path)}
					className="breadcrumb-button"
				>
					{folder.name}
				</BreadCrumb>

				{index === arr.length - 1 ? null: <ChevronRight />}
			</Fragment>
		))

		if(file) {
			crumbs.push(
				<Fragment>
						<BreadCrumb
								type="button"
								className="breadcrumb-button filename"
						>
								{file?.file.name}
						</BreadCrumb>
				</Fragment>
			)
		}
	}

	return (
		<DropboxHeaderContainer className="container" hasFile={!!file}>
			<HeaderWrapper className="container">
			<LeftControls>
				<WidgetHeader
					icon={require("./icon.svg")}
					size={['35px', '35px']}
					divider={''}
				/>
				<BreadCrumbContainer>{crumbs}</BreadCrumbContainer>
			</LeftControls>
			<RightControls
        isLight={props.colorScheme === 'Light'}
      >
				{file && (
					<Fragment>
							{
								(!hasPresentingFile || !isPresenterOnline) &&
								<Dropdown
										width="200px"
										DropdownItems={ShareMenu}
										noright
										title="Share"
										onPresent={onPresent}
										changeIndexState={changeIndexState}
										file={file}
										// GDExternals={GDExternals}
										// saveLink={() => saveLink(file)}
								>
										<Share>
												<div>Share</div>
												<div><BsChevronDown size={15} /></div>
										</Share>
								</Dropdown>
							}

							{isPresenter &&
								<Present className="button default" onClick={() =>
										changeIndexState({
												modal: {
														show: true,
														type: 'DBStopPresentation'
												}
										})}
								>
										<MdOutlineStopScreenShare size={22}/>
										<span>Stop Presentation</span>
								</Present>
							}

							{!viewPresenting && !isPresenter && !unauthenticatedViewer && isPresenterOnline &&
								<Present className="button primary" onClick={() => startViewing()}>
										<SiSlideshare size={22}/>
										<span>View Presentation</span>
								</Present>
							}

							{viewPresenting && !unauthenticatedViewer &&
								<React.Fragment>
										<Present className="button default" onClick={() =>
												changeIndexState({
														modal: {
																show: true,
																type: 'DBStopViewingPresentation'
														}
												})}
										>
												<MdCancelPresentation size={22}/>
												<span>Stop Viewing</span>
										</Present>
								</React.Fragment>
							}

							{!isPresentingOrViewing &&
								<div className="button default" style={{cursor: 'pointer', marginLeft: '10px'}}
										onClick={() => back()}
								>
										Back
								</div>
							}
					</Fragment>
				)}
				{!file && (
					<React.Fragment>
						{!viewPresenting && !isPresenter && isPresenterOnline &&
								<Present className="button primary" onClick={() => startViewing()}>
										<SiSlideshare size={22}/>
										<span>View Presentation</span>
								</Present>
						}
						<SearchContainer
							className="border-light topbar"
						>
							<label htmlFor="search_file">
								<SearchIcon />
							</label>
							<SearchInput
								id="search_file"
								type="search"
								placeholder="Search file"
								className="dropbox-search-input"
								onChange={searchFileHandler}
								value={searchFilter}
							/>
						</SearchContainer>
					</React.Fragment>
				)}
			</RightControls>
			</HeaderWrapper>
		</DropboxHeaderContainer>
	)
}


const DropboxHeaderContainer = styled.div<{hasFile: boolean}>`
	position: ${({hasFile}) => hasFile ? 'unset' : 'sticky'};
	top: 0;
	left: 0;
	padding: 16px;
	background-color: #f2f4f6;
	z-index: 1;
	border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`

const BreadCrumbContainer = styled.div`
	font-size: 16px;
	display: flex;
	align-items: center;
`

const BreadCrumb = styled.button`
	padding: 4px 12px;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 16px;
	border-radius: 24px;
	max-width: 124px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	transition: all 0.5s;

	&:hover:not(.filename) {
		background-color: rgba(0, 0, 0, 0.3);
	}

	&:last-child:not(:first-child) {
		color: #fff;
		background-color: #33333d;
	}

	&.filename {
		cursor: default;
		text-overflow: unset;
		max-width: unset;
		color: #fff;
		background-color: #33333d;
		margin-left: 10px;
	}

`

const ChevronRight = styled(BiChevronRight)`
	margin-right: 8px;
`

const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	border: 1px solid;
	border-radius: 32px;
	margin-top: 20px;
	margin-left: 20px !important;

	@media (min-width: 570px){
		margin-top: 0;
		margin-left: auto;
    }
`

const SearchInput = styled.input`
	background: none;
	border: none;
	padding-left: 8px;
`

const LeftControls = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content:space-between;
`

const Present = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	padding: 8px 12px !important;

	span {
			margin-left: 5px;
			white-space: nowrap;
	}
`

const RightControls = styled.div<{isLight: boolean}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (min-width: 768px){
			flex-direction: row;
			align-items: center;
	}

	${Present} {
			${({isLight}) => isLight ? 'background: transparent;' : 'background: #414052;'}
	}
`

const Dropdown = styled(DropdownWrapper)`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 4px 5px;
  margin-right: 25px;
	font-size: 14px;
	position: relative;

	${props => props.noRightMargin ? 'margin-right: 0px;' : ''}
`

const Share = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > div:nth-child(1) {
        margin-right: 20px;
    }
`

export default DropboxHeader
