import React from "react"
import styled from "styled-components"
import { MdFolder } from '@react-icons/all-files/md/MdFolder'
import { DropboxFile } from "../types"

interface Props {
	file: DropboxFile
	openFolder: (folderId: string, folderName: string) => void
	colorScheme: string
}

function FolderItem(props: Props) {
	const {file, openFolder, colorScheme} = props

	return (
		<Container
			onClick={() => openFolder(file.path_lower, file.name)}
			className="topbar"
			isLight={colorScheme === 'Light'}
		>
			<FolderIcon size={24} />
			<Header>{file.name}</Header>
		</Container>
	)
}

const Container = styled.div<{isLight: boolean}>`
	display: flex;
	flex-direction: column;
	background-color: ${({isLight}) => isLight ? '#fff' : '#1b1b25'};
	color: ${({isLight}) => isLight ? 'rgb(54, 59, 69)' : '#fff'};
	border-radius: 8px;
	padding: 16px 35px;
	cursor: pointer;
`

const FolderIcon = styled(MdFolder)`
	fill: #2483f3;
`

const Header = styled.h1`
	margin: 8px 0;
	font-size: 14px;
	font-weight: 100;
	word-break: break-all;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export default FolderItem
