import React, { Component } from 'react'
import styled, { keyframes, css } from 'styled-components'
import urlRegex from 'url-regex'
import axios from 'axios'
// import { FaArrowDown as ArrowDownIcon } from '@react-icons/all-files/fa/FaArrowDown'
import { FiArrowDown as ArrowDownIcon } from '@react-icons/all-files/fi/FiArrowDown'
import { slideInUp, slideOutDown } from 'react-animations';
import { v4 as getUuidV4 } from "uuid"

import ShowNotification from '../../helpers/Notification'
import ChatInput from './ChatInput'
import ChatMapper from './ChatMapper'

import { IWidgetProps, AllWidgets } from '../types'
import LoopApi from '../../helpers/LoopApi'
import Compressor from '../../helpers/Compressor'

import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'

type Props = IWidgetProps<AllWidgets.Chat>
interface State {
    photos: any[]
    isScrollDownBtnVisible: boolean
    searched: any[]
    widgetData: any
    hasLoadedInitialData: boolean
    cursor: string
    hasNext: boolean
    isFetchingChats: boolean
}

let timeout = null as any
export default class Chat extends Component<Props, State> {
    messagesEnd: any
    typing: boolean
    fileInput: any
    scrollRef: any
    timerId: any

    constructor(props: Props) {
        super(props)

        this.messagesEnd = null
        this.typing = false
        this.timerId = null

        this.fileInput = React.createRef();
        this.scrollRef = React.createRef();

        this.submitChat = this.submitChat.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.timeoutFunction = this.timeoutFunction.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
        this.onPaste = this.onPaste.bind(this)
        this.removePhoto = this.removePhoto.bind(this)
        this.removeAllPhotos = this.removeAllPhotos.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.scrollHandler = this.scrollHandler.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.getUnreadMessagesCount = this.getUnreadMessagesCount.bind(this)
        this.handleFloatingButtonClick = this.handleFloatingButtonClick.bind(this)
        this.updateLastSeenMessage = this.updateLastSeenMessage.bind(this)
        this.isFocusedAndNotBackreading = this.isFocusedAndNotBackreading.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleWidgetData = this.handleWidgetData.bind(this)

        this.state = {
            photos: [],
            isScrollDownBtnVisible: false,
            searched: [],
            widgetData: {
                chats: []
            },
            hasLoadedInitialData: false,
            cursor: '',
            hasNext: false,
            isFetchingChats: false,
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            this.scrollToBottom()
        }, 100)

        this.setState({ isFetchingChats: true })
        let res = await LoopApi(null, 'GetWidgetData', {
            where: JSON.stringify({
                widgetName: 'chat',
                propName: 'chats',
                // 'data.text': { $regex: '.*' + 'hey' + '.*' }
            }),
            limit: 10,
            isCursorPaginated: true
        })

        // //console.log({ res })

        // let res = await LoopApi(null, 'GetWidgetData', {
        //     where: JSON.stringify({
        //         widgetName: 'chat',
        //         propName: 'chats',
        //         // 'data.text': { $regex: '.*' + 'hey' + '.*' }
        //     }),
        //     limit: 100,
        //     page: 1,
        //     // sort: JSON.stringify({ 'data.text': 1 })
        // })

        this.setState(prevState => {
            let newState = { ...prevState }
            newState.widgetData.chats = res.results.map((data: any) => data.data).reverse()
            newState.hasLoadedInitialData = true
            newState.cursor = res.next
            newState.hasNext = res.hasNext
            newState.isFetchingChats = false
            return { ...newState }
        })
        // //console.log('get widget data', res.map(d => d.data.text))

        // window.deleteWidget = (id: any) => LoopApi(null, "DeleteWidgetData", {}, [
        //     ["name", this.props.meetingName],
        //     ["id", id],
        // ])

        // window.updateWidget = (id: any, body: any) => LoopApi(
        //   null,
        //   'UpdateWidgetData',
        //   body  ,

        //   [["name", this.props.meetingName],
        //   ["id", id]]
        // )

        // EVENT HANDLER
        if (window) {
            // //console.log('EVENT LISTENER CHAT')
            window.addEventListener('WIDGET-chat', this.handleWidgetData)
        }
    }

    handleWidgetData(e: any) {
        const { detail } = e
        const { type, data } = detail
        //console.log('WIDGET DATA:', detail)

        switch (type) {
            case 'create':
                if (data.propName === 'chats') {
                    this.setState(prevState => {
                        const newState = { ...prevState }
                        newState.widgetData.chats.push(data.data)

                        return { ...newState }
                    }, () => {
                        this.scrollToBottom()
                    })
                }
                break;

            case 'update':
                if (data.propName === 'chats') {
                    this.setState(prevState => {
                        const newState = { ...prevState }
                        newState.widgetData.chats = newState.widgetData.chats.map((c: any) => {
                            if (c.id === data.data.id) {
                                return data.data
                            } else {
                                return c
                            }
                        })

                        return { ...newState }
                    })
                }
                break;

            case 'delete':
                if (data.propName === 'chats') {
                    this.setState(prevState => {
                        const newState = { ...prevState }
                        newState.widgetData.chats = newState.widgetData.chats.filter((c: any) => c.id !== data.data.id)

                        return { ...newState }
                    })
                }
                break;

        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
            
        const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
        const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight

        if (prevProps.data.chats.length < this.props.data.chats.length) {
            const lastChat: any = this.props.data.chats[this.props.data.chats.length - 1]
            if (lastChat && lastChat.userId === this.props.userId) {
                this.scrollToBottom(true)
            } else {
                if (PIXELS_FROM_BOTTOM > clientHeight) {
                    if (PIXELS_FROM_BOTTOM > THRESHOLD_HEIGHT) {
                        this.setState({
                            isScrollDownBtnVisible: true
                        })
                    } else {
                        this.setState({
                            isScrollDownBtnVisible: false
                        })
                    }
                } else {
                    this.scrollToBottom(true)
                }
            }

            // notify if mentioned
            if (lastChat && lastChat.userId !== this.props.userId) {
                // MENTIONS

                //console.log({ lastChat})
                let matches = lastChat.text.match(/\@\[(.*?)\]\((.*?)\)/ig);
                matches?.forEach((match: any) => {
                    let userIdMatch = match.match(/\((.*?)\)/ig)
                    if (Array.isArray(userIdMatch) && userIdMatch[0]) {
                        let userID = userIdMatch[0].replace('(', '').replace(')', '')
                        // SEND NOTIFICATION HERE
                        if (userID === this.props.userId) {
                            if (Array.isArray(this.props.users)) {
                                let user = this.props.users.find((user: any) => {
                                    return user.id === lastChat.userId
                                })

                                if (user) {
                                    //console.log('YOU WERE MENTIONED', this.props)
                                    // this.props.actions.SendNotification(`${user.name} mentioned you`, this.props.userId)
                                    ShowNotification({
                                        message: `${user.name} mentioned you`
                                    })
                                }
                            }
                        }
                    }
                })
            }
        }

        // Adjust scroll of CHAT LIST when a the *photos to upload list* comes up
        if (prevState.photos.length === 0 && this.state.photos.length > 0) {
            let scrollElement = this.scrollRef.current
            let scrollOptions = { 
                top: scrollElement.scrollTop + 110, // 110 is height of photos to upload div
            }
            scrollElement.scroll(scrollOptions);
        }

        // Update last seen message
        const textInput = document.getElementById('CHAT_WIDGET_TEXT_INPUT')
        if (document.activeElement === textInput) {
            if (PIXELS_FROM_BOTTOM <= THRESHOLD_HEIGHT ) {
                this.updateLastSeenMessage()
            }
        }
    }

    isFocusedAndNotBackreading() {
        if (!this.scrollRef.current) {
            return false
        }
        const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
        const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
        const PIXELS_FROM_BOTTOM = scrollHeight - scrollTop - clientHeight

        const textInput = document.getElementById('CHAT_WIDGET_TEXT_INPUT')
        return document.activeElement === textInput && PIXELS_FROM_BOTTOM <= THRESHOLD_HEIGHT
    }

    async scrollHandler() {
        const throttle = (fn: Function, delay: number) => {
            if (this.timerId) {
                return
            }
    
            this.timerId = setTimeout(() => {
                fn()
                this.timerId = undefined;
            }, delay)
        }

        throttle(async () => {

            let { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
            
            const THRESHOLD_HEIGHT = 30 // pixels away from bottom of screen
            if (scrollHeight - scrollTop - clientHeight > THRESHOLD_HEIGHT) {
                this.setState({
                    isScrollDownBtnVisible: true
                })
            } else {
                this.setState({
                    isScrollDownBtnVisible: false
                })
            }

            // handle pagination
            if (scrollTop < 100) {
                //console.log(' SHOULD PAGINATE NOW ')
                const oldScrollHeight = scrollHeight
                if (this.state.isFetchingChats || !this.state.hasNext) {
                    return
                }
                this.setState({ isFetchingChats: true })
                let res = await LoopApi(null, 'GetWidgetData', {
                    where: JSON.stringify({
                        widgetName: 'chat',
                        propName: 'chats',
                        // 'data.text': { $regex: '.*' + 'hey' + '.*' }
                    }),
                    limit: 10,
                    isCursorPaginated: true,
                    next: this.state.cursor,
                })

                const newChats = res.results.map((data: any) => data.data).reverse()

                this.setState(prevState => {
                    let newState = { ...prevState }
                    newState.widgetData.chats = [...newChats, ...newState.widgetData.chats]
                    newState.hasLoadedInitialData = true
                    newState.cursor = res.next
                    newState.hasNext = res.hasNext
                    return { ...newState }
                }, () => {
                    let { scrollHeight } = this.scrollRef.current;
                    const diff = scrollHeight - oldScrollHeight
                    
                    let scrollElement = this.scrollRef.current
                    let scrollOptions = { 
                        top: diff + scrollTop
                    }
                    scrollElement.scroll(scrollOptions);
                })
                setTimeout(() => {
                    this.setState({ isFetchingChats: false })
                }, 500)
                // //console.log('PAGINATION', res)
            }

        }, 300)
    }

    async submitChat(val: string) {
        const text = val.trim()
        let photos = this.state.photos
            // .filter(photo => photo.file.type.indexOf('image') === 0)
            .map(photo => photo.file)


        // PROCESS TEXT
        if (text) {
            const newChat = {
                text,
                userId: this.props.userId,
                timeStamp: Date.now(),
                id: getUuidV4()
            }
    
            const myUser = this.props.users && this.props.users.find((u: any) => u.id === this.props.userId)
    
            // Send Notifications
            myUser &&
                this.props.actions.SendNotification(`${myUser.name}: ${text.slice(0, 30)}`)
    
            this.props.actions.UpdateSelf({
                chats: [...this.props.data.chats, newChat],
            })
    
            //get urls from string
            const urls = text.match(urlRegex()) || []
            if (urls.length > 0) this.props.actions.AddLink(urls)

            let response = await LoopApi(null, 'CreateWidgetData', {
                widgetName: 'chat',
                propName: 'chats',
                data: newChat,
                userId: this.props.userId
            })

            // //console.log(response)
        }

        return

        // PROCESS IMAGES
        if (photos.length > 0) {
            // Remove Photos
            this.removeAllPhotos()

            // Get Signed URLs
            const promises = photos.map((file: any) => LoopApi(null, 'S3PresignedURL', {
                type: file.type,
                fileExtension: file.name.substr(file.name.lastIndexOf('.') + 1)
            }, undefined))

			let signedUrls = await Promise.all(promises)
            photos = photos.map((photo: any, index: number) => ({
                file: photo,
                signedUrl: signedUrls[index]
            }))

            // Upload the File
            const uploadPromises = photos.map(async (photo: any, index: number) => {
				let type = photo.file.type
				try {
                    let fileToUpload = photo.file
                    if (type.indexOf('image') === 0) {
                        // Compress when uploading an image
                        fileToUpload = await Compressor(fileToUpload, .9, 1500)
                    }

                    const { data } = await axios.put(photo.signedUrl.url, fileToUpload, {
						headers: {
							'Content-Type': type
						}
					})
					return data
				} catch (err) {
					return null
				}
			})

			const res = await Promise.all(uploadPromises)
			//console.log({ res })

            // Send the text and Notify
            const files = [ ...photos ]

            // For PHOTOS
            const images = files.filter(file => file.file.type.indexOf('image') === 0)

            const imageURLs = images.map(file => file.signedUrl.imgUrl)

            if (imageURLs.length > 0) {
                const newChat = {
                    text: '',
                    photos: imageURLs,
                    userId: this.props.userId,
                    timeStamp: Date.now(),
                    id: getUuidV4(),
                }
        
                const myUser = this.props.users && this.props.users.find((u: any) => u.id === this.props.userId)

                // Send Notifications
                if (myUser) {
                    if (imageURLs.length === 1) {
                        this.props.actions.SendNotification(`${myUser.name}: Sent a photo`)
                    } else {
                        this.props.actions.SendNotification(`${myUser.name}: Sent ${imageURLs.length} photos`)
                    }
                }
        
                this.props.actions.UpdateSelf({
                    chats: [...this.props.data.chats, newChat],
                })
            }

            // For FILES
            const otherFiles = files.filter(file => file.file.type.indexOf('image') !== 0)

            otherFiles.forEach((file: any) => {
                const newChat = {
                    text: '',
                    file: {
                        type: file.file.type,
                        name: file.file.name,
                        url: file.signedUrl.imgUrl,
                        size: file.file.size
                    },
                    userId: this.props.userId,
                    timeStamp: Date.now(),
                    id: getUuidV4(),
                }
        
                const myUser = this.props.users && this.props.users.find((u: any) => u.id === this.props.userId)
        
                // Send Notifications
                if (myUser) {
                    this.props.actions.SendNotification(`${myUser.name}: Sent an attachment`)
                }
        
                this.props.actions.UpdateSelf({
                    chats: [...this.props.data.chats, newChat],
                })
            })
        }

        // ShowNotification({ message: 'yes', type: 'default'})

    }

    scrollToBottom(isSmooth = false) {
        let scrollElement = this.scrollRef.current
        let scrollOptions = { 
            top: scrollElement.scrollHeight,
            behavior: isSmooth ? 'smooth' : undefined
        }
        scrollElement.scroll(scrollOptions);
    }

    handleFloatingButtonClick() {
        this.scrollToBottom(true)
        this.updateLastSeenMessage()
    }

    timeoutFunction() {
        this.typing = false;
        const typing = this.props.data.typing.filter(u => u !== this.props.userId)

        this.props.actions.UpdateSelf({ typing })
    }

    onKeyUp(e: any) {
        if (e.keyCode !== 13) {
            if (this.typing == false) {
                this.typing = true
                const users = this.props.data.typing.filter(u => u === this.props.userId)
                if (!!!users.length) {
                    this.props.actions.UpdateSelf({ typing: [...this.props.data.typing, this.props.userId] })
                }
                timeout = setTimeout(this.timeoutFunction, 5000);
            } else {
                clearTimeout(timeout);
                timeout = setTimeout(this.timeoutFunction, 5000);
            }
        }
    }

    componentWillUnmount() {
        this.timeoutFunction()

        if (window) {
            window.removeEventListener('chat', this.handleWidgetData)
        }
    }

    onPaste(event: any) {
        // use event.originalEvent.clipboard for newer chrome versions
        var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
        // will give you the mime types
        // //console.log(JSON.stringify(items)); 

        // find pasted image among pasted items
        let blob: any = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
                blob = items[i].getAsFile();
            }
        }

        // load image if there is a pasted image
        if (blob !== null) {
            var reader = new FileReader();
            reader.onload = event => {
                // //console.log(event.target.result); // data url!
                // document.getElementById("pastedImage").src = event.target.result;
                this.setState(prevState => {
                    let newState = { ...prevState }
                    newState.photos.push({
                        file: blob,
                        src: event.target ? event.target.result : null
                    })

                    return { ...newState }
                })
            };
            reader.readAsDataURL(blob);
        }
    }

    removePhoto(index: number) {
        this.setState(prevState => {
            let newState = { ...prevState }
            newState.photos.splice(index, 1)
            return { ...newState }
        })
    }

    removeAllPhotos() {
        this.setState({ photos: [] })
    }

    async handleOnChange(e: any) {
        let files = Array.from(e.target.files)

        if (files.length === 0) {
            return
        }
        // Validate size
        

        const getSrc = (file: any) => {
            return new Promise((resolve) => {
                if (file.type.indexOf("image") !== 0) {
                    resolve('')
                }
                var reader = new FileReader();
                reader.onload = event => {
                    if (event.target) {
                        resolve(event.target.result)
                    } else {
                        resolve('')
                    }
                };
                reader.readAsDataURL(file)  
            })
        }

        const promises = files.map(file => getSrc(file))
        const sources = await Promise.all(promises)

        files = files.map((file: any, index: number) => {
            return {
                file,
                src: sources[index]
            }
        })

        this.setState(prevState => {
            const newState = { ...prevState }
            newState.photos = [ ...newState.photos, ...files ]
            return { ...newState }
        })
    }

    async onFocus() {
        this.updateLastSeenMessage()
    }

    updateLastSeenMessage() {
        // send here
        const lastChat: any = this.props.data.chats[this.props.data.chats.length - 1]
        
        const lastSeenMessageMap = { ...this.props.data.lastSeenMessageMap }
        const userId: string = this.props.userId
        lastSeenMessageMap[userId] = lastChat?.id || '' 

        this.props.actions.UpdateSelf({
            lastSeenMessageMap: { ...lastSeenMessageMap }
        })
    }

    getUnreadMessagesCount() {
        const chatWidgetState = { ...this.props.data }
        const { userId } = this.props

        const lastSeenMessageId = chatWidgetState.lastSeenMessageMap[userId]
        let unreadMessagesCount = chatWidgetState.chats.length

        if (lastSeenMessageId) {
            let chatsCopy = [ ...chatWidgetState.chats ]
            let lastMessageIndex = chatsCopy.findIndex((chat: any) => chat.id === lastSeenMessageId)

            chatsCopy = chatsCopy.slice(lastMessageIndex + 1)
            // get messages that are not yours
            unreadMessagesCount = chatsCopy.filter((chat: any) => chat.userId !== userId).length
        }

        return unreadMessagesCount
    }

    
    handleSearch(e: string) {
        const searched = (this.props.data.chats || []).filter((f: any) => f.text.includes(e))
        this.setState({ searched })
        // //console.log(this.props)
    }

    render() {
        const { isScrollDownBtnVisible, searched } = this.state

        const chats = ChatMapper(
            searched.length > 0 ? searched : this.state.widgetData.chats,
            this.props.users,
            this.props.userId
        )

        const usersName = (this.props.data.typing || []).filter(t => t !== this.props.userId).map(t => {
            const myUser = this.props.users && this.props.users.find((u: any) => u.id === t)
            return myUser?.name || ''
        })

        const finalName = usersName.pop() || '';
        const list = usersName.length
            ? usersName.join(', ') + ' & ' + finalName
            : finalName;
        const isAre = usersName.length ? 'are' : finalName ? 'is' : ''

        const badge = this.getUnreadMessagesCount()

        return (
            <Wrapper className="topbar rounded inner">
                <SearchDiv>
                    <AiOutlineSearch size="22px" />
                    <SearchInput onChange={(e) => this.handleSearch(e.target.value)} className="topbar" type='text' placeholder='Search' />
                </SearchDiv>
                <Chats 
                    ref={this.scrollRef}
                    onScroll={this.scrollHandler}
                    id="CHAT_WIDGET_SCROLL"
                >
                    {chats}
                </Chats>

                <ChatInput
                    submitChat={this.submitChat}
                    onKeyUp={this.onKeyUp}
                    onPaste={this.onPaste}
                    onFocus={this.onFocus}
                    photos={this.state.photos}
                    removePhoto={this.removePhoto}
                    fileInputRef={this.fileInput}
                    users={(this.props.users || []).filter((u: any) => u.id !== this.props.userId)}
                />
                {/* {
                    isScrollDownBtnVisible ? <h1>YES</h1> : <h2>NO</h2>
                } */}
                <input
                    type="file"
                    ref={this.fileInput} 
                    hidden
                    multiple
                    onChange={this.handleOnChange}
                    // accept="image/*"
                />

                <FloatingButton
                    onClick={this.handleFloatingButtonClick} 
                    isScrollDownBtnVisible={isScrollDownBtnVisible}
                >
                    <ArrowDownIcon></ArrowDownIcon>
                    {
                        (badge > 0 && (
                            <span>{ badge }</span>
                        ))
                    }
                </FloatingButton>

            </Wrapper>
        )
    }
}

const TypingLoader = () => {
    return (
        <div className="loader">
            <div className="loader-inner ball-pulse-sync">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

const Pulse = keyframes`
    33% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
    66% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

    .typing {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 12px;
        display: flex;
    }

    .loader {
        margin-right: 10px;
    }

    .ball-pulse-sync > div:nth-child(1) {
        -webkit-animation: ${Pulse} 0.8s -0.18s infinite ease-in-out;
        animation: ${Pulse} 0.8s -0.18s infinite ease-in-out;
    }

    .ball-pulse-sync > div:nth-child(2) {
        -webkit-animation: ${Pulse} 0.8s -0.09s infinite ease-in-out;
        animation: ${Pulse} 0.8s -0.09s infinite ease-in-out;
    }

    .ball-pulse-sync > div:nth-child(3) {
        -webkit-animation: ${Pulse} 0.8s 0s infinite ease-in-out;
        animation: ${Pulse} 0.8s 0s infinite ease-in-out;
    }

    .ball-pulse-sync > div {
        background-color: ${props => props.theme.textPrimary};
        width: 3px;
        height: 3px;
        border-radius: 100%;
        margin: 1px;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: inline-block;
    }
`

const Wrapper = styled.div`
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: relative;
    margin: 15px;

    display: flex;
    flex-direction: column;
`

const Chats = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`
const slideInUpAnimation = keyframes`${slideInUp}`;
const slideOutAnimation = keyframes`${slideOutDown}`;

const FloatingButton = styled.button<{ isScrollDownBtnVisible: boolean }>`
    position: absolute;
    bottom: 120px;
    right: 23px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #fcfcfc;
    animation: ${props => props.isScrollDownBtnVisible ? css`.4s ${slideInUpAnimation}` : css`.2s ${slideOutAnimation} forwards`};


	span {
		position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        background: #FF3C3C;
        color: white;
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
` 

const SearchDiv = styled.div`
      align-items: center;
      border-radius: 10px;
      border: 2px solid;
      margin-left: auto;
      display: flex;
      padding: 2px;
      margin-right: 5px;
      margin-top: 10px;
`

const SearchInput = styled.input`
      background-color: transparent;
      outline: none;
      border: none;
`