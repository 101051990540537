import React, { Component } from 'react'
import styled from 'styled-components'

interface Props {
    items: React.ReactNode
    left?: boolean
    right?: boolean
    center?: boolean
    width?: string | number
    addTop?: string | number
    scrollable?: boolean
    containerClass?: string
    callbackFunction?: () => void
    overlayIndex?: number
    containerIndex?: number
    componentProps?: any
}

interface State {
    open: boolean
}

export default class Dropdown extends Component<Props, State> {
    curPosition: any
    constructor(props: Props) {
        super(props)

        this.state = {
            open: false,
        }

        this.curPosition = null

    }

    close() { this.setState({ open: false }) }


    render() {
        const DropdownItems = this.props.items as any
        const { right = true, addTop, scrollable = false, center, containerClass = '', callbackFunction = () => { }, overlayIndex = 3, containerIndex = 4, componentProps = {} } = this.props

        const curPosition = this.curPosition ? this.curPosition.getBoundingClientRect() : {}
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    !this.state.open && this.setState({ open: true })
                }}
            >
                <div {...{ ref: (el: any) => (this.curPosition = el) } as any}>{this.props.children}</div>
                {this.state.open && (
                    <ClosingUnderlay onClick={() => this.setState({ open: false }, () => callbackFunction())} overlayIndex={overlayIndex} />
                )}
                {this.state.open && (
                    <Container className={`topbar ${containerClass}`} left={this.props.left} right={right} width={this.props.width} curPosition={curPosition} addTop={addTop} scrollable={scrollable} center={center} containerIndex={containerIndex}>
                        <DropdownItems
                            {...this.props}
                            componentProps={componentProps}
                            onClose={() => {
                                this.setState({ open: false })
                            }}
                        />
                    </Container>
                )}
            </div>
        )
    }
}

const ClosingUnderlay = styled.div<{overlayIndex: number}>`
	position: fixed;
	z-index: ${props => props.overlayIndex};
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
    cursor: default;
`

const Container = styled.div<{ left?: boolean, right?: boolean, center?: boolean, width?: string | number, curPosition?: any, addTop?: number | string, scrollable?: boolean, containerIndex: number }>`
	${(props) => (props.left ? 'transform: translateX(-10%);' : '')};
	${(props) => (props.right && !!!props.left ? 'transform: translateX(-80%);' : '')};
    ${(props) => (!!!props.right && !!!props.left ? 'transform: translateX(-80%);' : '')};
    ${(props) => props.center ? 'transform: translateX(calc(-50% + 20px));' : ''};
	padding: 6px;
	border-radius: 4px;
	width: ${(props) => (props.width || '180px')};
	box-shadow: ${(props) => props.theme.shadows.newheavy};
	position: fixed;
	z-index: ${props => props.containerIndex};
    top: ${props => `${props.curPosition?.top + 20}px`};
    top: ${props => `${props.curPosition?.top + 20 + props.addTop}px`};

    ${props => props.scrollable ? 'max-height: 250px; overflow-y: auto;' : ''}
`
