import React, { FormEvent, memo } from 'react'

import TitleEditor from './Editor/TitleEditor'
import NotesEditor from './Editor/NotesEditor'
import NotesHeader from './NotesHeader'
import { NotePreview, NotesContentWrapper } from './styledcomponents'
import { CallingInstanceState } from '../../calling/types'

const { GlobalState } = require('reflux')

interface Props {
    note: INote
    activeNote: string | null
    activePrivateNote: INote
    users: any
    userId: string
    numberOfNotes: number
    updateTitle: (e: FormEvent<HTMLTextAreaElement>, isPrivate?: boolean, fromBlur?: boolean) => void
    updateContent: (content: string, pureText: string, isPrivate?: boolean, fromBlur?: boolean) => void
    updateUpdating: () => void
}

interface INote {
    title: string
    pureText: string
    content: string
    updating: null | string
    id: string
    private?: boolean
    isPrivate?: boolean
    date_updated: any
    date_created: any
    created_by: string
    updated_by: string
}

function NotesContent(props: Props) {
    const { note, activePrivateNote, updateTitle, updateContent, updateUpdating, activeNote, users, userId, numberOfNotes } = props
    let owner = (users || []).find((user: any) => user?.id === note?.created_by)
    let modifiedBy = (users || []).find((user: any) => user?.id === note?.updated_by)

    if(!!!activeNote || !!!numberOfNotes) {
        return <NotesContentWrapper className="topbar rounded inner">
            <NotePreview className="whole-div no-note">
                <div className="lighter">Your notes is empty. <br />Try to create one by clicking the New Note button.<br /></div>
                {/* <div className="button hover-default" onClick={() => this.setState({ modalOpen: true })}>New Note</div> */}
            </NotePreview>
        </NotesContentWrapper>
    }

    return (
        <NotesContentWrapper className="topbar rounded inner notes-contentwrapper" isInCall={GlobalState.calling?.status === CallingInstanceState.Connected}>
            <NotesHeader dateUpdated={note?.date_updated} dateCreated={note?.date_created} owner={owner} modifiedBy={modifiedBy} />
            <TitleEditor title={activePrivateNote?.title || note?.title} updateTitle={updateTitle} isPrivate={activePrivateNote?.isPrivate || note?.isPrivate || activePrivateNote?.private || note?.private} />
            <NotesEditor 
                pureText={activePrivateNote?.pureText || note?.pureText} 
                content={activePrivateNote?.content || note?.content} 
                isPrivate={activePrivateNote?.isPrivate || note?.isPrivate || activePrivateNote?.private || note?.private}
                activeNote={activeNote}
                updating={note?.updating} 
                updateContent={updateContent} 
                updateUpdating={updateUpdating} 
            />
        </NotesContentWrapper>
    )
}

function areEqual(prevProps: Props, nextProps: Props) {
    return JSON.stringify(prevProps.note) === JSON.stringify(nextProps.note) && 
    JSON.stringify(prevProps.users) === JSON.stringify(nextProps.users) &&
    JSON.stringify(prevProps.activePrivateNote) === JSON.stringify(nextProps.activePrivateNote) &&
    prevProps.userId === nextProps.userId
}


export default memo(NotesContent, areEqual)
