import { useEffect, useRef, useState } from 'react'

export const useWindowUnloadEffect = (handler: Function, callOnCleanup?: boolean) => {
    const cb: any = useRef()
    
    cb.current = handler
    
    useEffect(() => {
      const handler = () => cb.current()
    
      window.addEventListener('beforeunload', handler)
      
      return () => {
        if(callOnCleanup) handler()
      
        window.removeEventListener('beforeunload', handler)
      }
    }, [callOnCleanup])
  }
  