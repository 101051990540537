import React, { memo } from 'react';
import styled from 'styled-components';

import Modal from '/components/Modalv2';
const { GlobalState } = require('reflux');
import check from '../../../../src/assets/icons/check.svg';
import checkLight from '../../../../src/assets/icons/check-light.svg';
import LoopApi from '../../../helpers/LoopApi';
import exclamationIcon from '../../../assets/icons/exclamation.svg'
import moment from 'moment'
import { ModalActions } from '/stores/ModalStore';

const CancelSubscription = memo((props) => {
    const [rooms, setRooms] = React.useState([])
    const [room, setRoom] = React.useState(null)

    React.useEffect(() => {
        getMeetings()
    },[])

    const getMeetings = async () => {
        const { meetings } = await LoopApi(null, 'GetMeetings')
        setRooms(meetings)
    }

    const handleChange = e => {
        setRoom(e.target.value)
    }

    const handleNext = () => {
        ModalActions.SetModalWithParams('SubscriptionCancelFeedback', { room })
    }

    const { name } = GlobalState.modal.billings.product;
    const { interval } = GlobalState.modal.billings.subscription.plan.interval;
    const auth = GlobalState.auth.jwt.data
    const effectiveDate = moment.unix(GlobalState.modal.billings.subscription.current_period_end).format('LL')

    return (
        <Modal closeModal={props.closeModal} dismissible={true}>
            <Container className='topbar'>
                <div className='head-1'>We're sorry to see you go!</div>
                <div className='head-2'>
                    Your {name} with {interval} subscription is paid until {effectiveDate}. If you
                    would like to proceed with canceling your subscription, please select "Cancel Subscription" below.
                    Your account will go back to <b>FREE</b> plan.
                </div>
                <div className='description'>
                    <img src={exclamationIcon} /><pre>    </pre>Are you sure? After {effectiveDate} you will not have the ability to start a meeting 
                </div>
                <div className='description'>
                    <img src={exclamationIcon} /><pre>    </pre>Not ready to lose your meeting recording? Download them within your account before {effectiveDate}
                </div>
                <div className='description'>
                    <img src={exclamationIcon} /><pre>    </pre>Not ready to lose your files? Download them within your account before {effectiveDate}
                </div>
                <div className='description'>
                    <img src={exclamationIcon} /><pre>    </pre>You have a total of {rooms?.map(r => r.archived ? null : r).filter(r => r!=null).length} rooms, you can only retain 1 active room. Other rooms will be archived and will be permanetly deleted after 30 days
                </div>

                <div className='select'>
                    Select room
                    <select onChange={handleChange} className="border-topbar">
                        <option value="" selected disabled hidden>Choose here</option>
                        {rooms?.map(r => {
                            if(r.archived) return
                            if(r.creator_user_id !== auth._id) return
                            return <option value={r._id}>{r.name_alias}</option>
                        })}
                        
                    </select>
                </div>

                <div className='center-btn'>
                    <button className='big-btn' onClick={props.closeModal}>Nevermind</button>
                    <div className='link-btn border-default' onClick={handleNext}>No thanks, I want to cancel my subscription</div>
                </div>
            </Container>
        </Modal>
    );
});

export default CancelSubscription;

const Container = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 600px;

    .head-1 {
        font-size: 1.6em;
        margin-bottom: 10px;
    }

    .head-2 {
        margin-bottom: 20px;
        line-height: 1.2em;
    }

    .description {
        display: flex;
        align-items: center;
        line-height: 2em;
    }

    .select {
        select {
            border-radius: 15px;
            width: 200px;
            height: 35px;
            border: 1px solid;
            margin-left: 20px;
        }
    }

    .center-btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .big-btn {
            width: 255.71px;
            height: 50px;
            background: #ED3030;
            border-radius: 999px;
            color: white;
            border: 0;
            margin-top: 20px;
            margin-bottom: 10px;
            cursor: pointer;
        }

        .link-btn {
            cursor: pointer;
            margin-top: 10px;
            border: none;
            border-bottom: 1px solid;
            padding-bottom: 2px;
        }
    }
`;
