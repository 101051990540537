import React from 'react'
import styled from 'styled-components'
import {FaCrown as HostIcon} from '@react-icons/all-files/fa/FaCrown'

const NameContainer = styled.div<{isCurrentUser?: boolean}>`
  position: absolute;
  bottom: 0px;
  left: 4px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  .host-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    overflow: hidden;
    -webkit-animation: filckering 2s infinite;
    animation: filckering 2s infinite;
    display: inline-block;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    font-size: 13px;

    svg {
      width: 18px;
    }
  }

  .tile-username {
    > .ellipsis-tile-username {
      max-width: 80px;
      text-shadow: 0 1px 2px rgb(0 0 0 / 60%), 0 0 2px rgb(0 0 0 / 30%);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  @keyframes filckering {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
`
const CrownIcon = ({size = 13}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-fill-primary"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M24.625 21.5H8.375C8.03125 21.5 7.75 21.7812 7.75 22.125V23.375C7.75 23.7188 8.03125 24 8.375 24H24.625C24.9688 24 25.25 23.7188 25.25 23.375V22.125C25.25 21.7812 24.9688 21.5 24.625 21.5ZM27.125 9C26.0898 9 25.25 9.83984 25.25 10.875C25.25 11.1523 25.3125 11.4102 25.4219 11.6484L22.5938 13.3437C21.9922 13.7031 21.2148 13.5 20.8672 12.8906L17.6836 7.32031C18.1016 6.97656 18.375 6.46094 18.375 5.875C18.375 4.83984 17.5352 4 16.5 4C15.4648 4 14.625 4.83984 14.625 5.875C14.625 6.46094 14.8984 6.97656 15.3164 7.32031L12.1328 12.8906C11.7852 13.5 11.0039 13.7031 10.4062 13.3437L7.58203 11.6484C7.6875 11.4141 7.75391 11.1523 7.75391 10.875C7.75391 9.83984 6.91406 9 5.87891 9C4.84375 9 4 9.83984 4 10.875C4 11.9102 4.83984 12.75 5.875 12.75C5.97656 12.75 6.07813 12.7344 6.17578 12.7187L9 20.25H24L26.8242 12.7187C26.9219 12.7344 27.0234 12.75 27.125 12.75C28.1602 12.75 29 11.9102 29 10.875C29 9.83984 28.1602 9 27.125 9Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="33"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default function Name({
  isHost,
  isLocalParticipant,
  name,
}: {
  isHost?: boolean
  isLocalParticipant?: boolean
  name?: string
}) {
  return (
    <NameContainer
      className={`${isLocalParticipant ? 'curname' : 'othername'}`}
      isCurrentUser={isLocalParticipant}
      style={{zIndex: 1}}
    >
      {isHost && (
        <div className="host-indicator color-primary">
          <HostIcon />
        </div>
      )}
      <div className="tile-username">
        <div className="ellipsis ellipsis-tile-username">
          {name} {isLocalParticipant ? '(You)' : null}
        </div>
      </div>
    </NameContainer>
  )
}
