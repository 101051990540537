import React, {useEffect, useState} from 'react'
import {AiOutlineExpand as ExpandIcon} from '@react-icons/all-files/ai/AiOutlineExpand'
import {AiOutlineCompress as CollapseIcon} from '@react-icons/all-files/ai/AiOutlineCompress'

// Components
import WidgetItem from '../../../WidgetItem'
import MainParticipant from './MainParticipant'
import LocalParticipant from './LocalParticipant'
import MeetingControls from './MeetingControls'
import ParticipantList from './ParticipantList'
import Participant from './Participant'
import ModalDeviceSelection from './ModalDeviceSelection'
import ModalBackgroundSelection from './ModalBackgroundSelection'
import DraggableBoundary from './DraggableBoundary'

// hooks
import {isMobile} from '../../../../../../calling/_helpers'
import useView, {MeetingLayouts, MeetingViews} from '../../hooks/useView'
import useRoomState from '../../hooks/useRoomState'
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle'
import useDevices from '../../hooks/useDevices'
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle'
import useVideoContext from '../../hooks/useVideoContext'
import useParticipants from '../../hooks/useParticipants'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant'
import usePublications from '../../hooks/usePublications'
import useMainParticipant from '../../hooks/useMainParticipant'
import ModalLayoutSelection from './ModalLayoutSelection'
import CallDuration from './CallDuration'
import MainParticipantNoScreenshare from './MainParticipantNoScreenshare'
import useDominantSpeaker from '../../hooks/useDominantSpeaker'
import CustomTip from '../../../../../../helpers/CustomTip'
import ReactToolTip from 'react-tooltip'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { DailyCall } from '@daily-co/daily-js'
import * as twilio from 'twilio-video'

import { MainDivGrid } from '../../StyledComponents'
import ModalMeetingParticipants from './ModalMeetingParticipants'

type RoomProps = {
  currentWidgetData: any
  couldRenderWidget: boolean
  mobileCollapse: boolean
}

const Room = (props: RoomProps) => {
  const roomState = useRoomState()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const {view, currentWidgetName, layout, setLayout, setView, participantsHidden, setParticipantsHidden} = useView()
  const {localTracks, setActiveWidget} = useVideoContext()
  const {room, session} = useMeetingContext()
  const [_isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const [_isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const localParticipant = (room as twilio.Room)!.localParticipant || (room as DailyCall)?.participants()?.local
  const dominantSpeaker = useDominantSpeaker()

  const mainParticipant = useMainParticipant()
  const screenShareParticipant = useScreenShareParticipant()

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isBackgroundModalOpen, setIsBackgroundModalOpen] = useState(false)
  const [isLayoutModalOpen, setIsLayoutModalOpen] = useState(false)
  const [isMeetingParticipantsModalOpen, setIsMeetingParticipantsModalOpen] = useState(false)

  const remoteParticipants = useParticipants()
  useEffect(() => {
    const init = async () => {
      // @ts-ignore
      //console.log('**twil', 'CONSTRAINTS', {c: window.constraints, localTracks})

      // await getAudioAndVideoTracks()

      // // @ts-ignore
      // if (window.constraints.audio) {
      //   toggleAudioEnabled()
      // }

      // // @ts-ignore
      // if (window.constraints.video) {
      //   toggleVideoEnabled()
      // }
    }
    init()
  }, [])

  useEffect(() => {
    ReactToolTip.rebuild()
  }, [isFullScreen])

  const renderFloating = () => {
    if (layout !== MeetingLayouts.Floating) return null

    if (currentWidgetName) {
      return (
        <DraggableBoundary>
          <MainParticipant />
        </DraggableBoundary>
      )
    } else {
      if (screenShareParticipant) {
        if (remoteParticipants.length > 0) {
          return (
            <DraggableBoundary>
              <MainParticipantNoScreenshare hideScreenshareLabel={true} />
            </DraggableBoundary>
          )
        } else {
          return (
            <DraggableBoundary>
              <LocalParticipant hideScreenshareLabel={true} />
            </DraggableBoundary>
          )
        }
      } else {
        if (remoteParticipants.length > 0) {
          return (
            <DraggableBoundary>
              <LocalParticipant />
            </DraggableBoundary>
          )
        }
      }
    }

    return null
  }

  const isRemoteParticipantScreenSharing =
    mainParticipant === screenShareParticipant &&
    mainParticipant !== localParticipant
  const isLocalparticipantScreenSharing =
  //@ts-ignore
    (screenShareParticipant?.identity || screenShareParticipant?.user_name) === (localParticipant?.identity || localParticipant?.user_name)
  const someoneIsScreensharing =
    isRemoteParticipantScreenSharing || isLocalparticipantScreenSharing

  const tilesCount =
    remoteParticipants.length + 1 + (someoneIsScreensharing ? 1 : 0)

  return (
    <React.Fragment>
      <MainDivGrid
        id="main-grid"
        className={`topbar meeting-${
          tilesCount === 1 && !!!currentWidgetName ? 'full-main' : view
        } ${isFullScreen && 'full-screen'}`}
        { ...{mobileCollapse: props.mobileCollapse} }
      >
        <main id="main-content">
          <div
            className={`main-content-inner ${
              isLocalparticipantScreenSharing ? 'screensharing' : ''
            }`}
          >
            {currentWidgetName && props.couldRenderWidget ? (
              <WidgetItem {...props.currentWidgetData} />
            ) : (
              <MainParticipant />
            )}
          </div>

          {renderFloating()}
          {
            //@ts-ignore
            (screenShareParticipant?.identity || screenShareParticipant?.user_name) === (localParticipant?.identity || localParticipant?.user_name) && (
            <div className="local-presenter-label">
              <div className="bg-primary rounded inner local-presenter-label-bg" />
              <div style={{position: 'absolute'}}>
                You are currently presenting
              </div>
            </div>
          )}
        </main>
        <aside id="participants-sidebar">
          <div className={`participants-wrapper p-${tilesCount}`}>
            {/* <MainParticipant /> */}
            {((layout === MeetingLayouts.Default && currentWidgetName) ||
              (MeetingLayouts.Sidebar && currentWidgetName)) &&
              someoneIsScreensharing && <MainParticipant />}
            <ParticipantList
              currentWidgetName={currentWidgetName}
              layout={layout}
            />
          </div>
        </aside>
        <footer id="meeting-footer" className="rounded inner">
          <div className="left-footer">
            <button
              className="button default controlbutton"
              onClick={() => setIsFullScreen((isFullScreen) => !isFullScreen)}
            >
              {isFullScreen ? (
                <CollapseIcon data-tip data-for="exit-fs" />
              ) : (
                <ExpandIcon data-tip data-for="enter-fs" />
              )}
              {!!!isMobile && (
                <React.Fragment>
                  <CustomTip
                    top={-20}
                    left={0}
                    class="top-left"
                    place="right"
                    tip="Enter Fullscreen"
                    dataFor="enter-fs"
                  />
                  <CustomTip
                    top={-20}
                    left={0}
                    class="top-left"
                    place="right"
                    tip="Exit Fullscreen"
                    dataFor="exit-fs"
                  />
                </React.Fragment>
              )}
            </button>
            <CallDuration startTime={session?.created_at || ''} />
          </div>
          <MeetingControls
            openSettingsModal={() => {
              setIsSettingsModalOpen(true)
            }}
            openBackgroundModal={() => {
              setIsBackgroundModalOpen(true)
            }}
            openLayoutModal={() => {
              setIsLayoutModalOpen(true)
            }}
            openMeetingParticipantsModal={() => {
              setIsMeetingParticipantsModalOpen(true)
            }}
            layout={layout}
            setLayout={setLayout}
            isFullScreen={isFullScreen}
            toggleFullScreen={() =>
              setIsFullScreen((isFullScreen) => !isFullScreen)
            }
          />
        </footer>
      </MainDivGrid>

      {isSettingsModalOpen && (
        <ModalDeviceSelection
          closeModal={() => {
            setIsSettingsModalOpen(false)
          }}
        />
      )}

      {isBackgroundModalOpen && (
        <ModalBackgroundSelection
          closeModal={() => {
            setIsBackgroundModalOpen(false)
          }}
        />
      )}

      {isLayoutModalOpen && (
        <ModalLayoutSelection
          layout={layout}
          setLayout={setLayout}
          closeModal={() => {
            setIsLayoutModalOpen(false)
          }}
        />
      )}

      {isMeetingParticipantsModalOpen && (
        <ModalMeetingParticipants 
          setView={setView}
          setActiveWidget={setActiveWidget}
          layout={layout}
          setParticipantsHidden={setParticipantsHidden}
          participantsHidden={participantsHidden}
          currentWidgetName={currentWidgetName}
          screenShareParticipant={screenShareParticipant}
          closeModal={() => {
            setIsMeetingParticipantsModalOpen(false)
          }}
        />
      )}


    </React.Fragment>
  )
}

export default Room
