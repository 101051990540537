import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'


export class CustomTip extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {


        return (
            <div>
                <ReactTooltip

                    offset={{ top: this.props.top || 0, left: this.props.left || 0 }}
                    id={this.props.dataFor}
                    effect={this.props.effect || 'solid'}
                    place={this.props.place}
                    className={`${this.props.class} custom-tooltip container main`}  >
                    {this.props.tip}
                </ReactTooltip>
            </div>
        )
    }
}

export default CustomTip
