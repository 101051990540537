import React, {useState, useEffect} from 'react'
import useVideoContext from './useVideoContext'
import useScreenShareParticipant from './useScreenShareParticipant'

export enum MeetingLayouts {
  Default = 'grid',
  Floating = 'floating',
  Sidebar = 'default',
}

export enum MeetingViews {
  Complete = 'main-with-sidebar',
  SidebarOnly = 'full-sidebar',
  MainOnly = 'full-main',
}

export default function useView() {
  const {currentWidgetName} = useVideoContext()
  const [view, setView] = useState(MeetingViews.Complete)
  const [layout, setLayout] = useState(MeetingLayouts.Default)
  const [participantsHidden, setParticipantsHidden] = useState(false)
  const screenShareParticipant = useScreenShareParticipant()

  useEffect(() => {
    if (!currentWidgetName) {
      // ON GOING CALL
      switch (layout) {
        case MeetingLayouts.Sidebar:
          if(screenShareParticipant && participantsHidden) {
            return setView(MeetingViews.MainOnly)
          } 
          setView(MeetingViews.Complete)
          break

        case MeetingLayouts.Default:
          if(screenShareParticipant && participantsHidden) {
            return setView(MeetingViews.MainOnly)
          } 
          if (screenShareParticipant) setView(MeetingViews.Complete)
          else setView(MeetingViews.SidebarOnly)
          break

        case MeetingLayouts.Floating:
          setView(MeetingViews.MainOnly)
          if(participantsHidden) setParticipantsHidden(false)
          break
      }
    } else {
      // WIDGET MODE
      switch (layout) {
        case MeetingLayouts.Sidebar:
          if(participantsHidden) {
            setView(MeetingViews.MainOnly)
          } else {
            setView(MeetingViews.Complete)
          }
          break

        case MeetingLayouts.Default:
          if(participantsHidden) {
            setView(MeetingViews.MainOnly)
          } else {
            setView(MeetingViews.Complete)
          }
          // setLayout(MeetingLayouts.Default)
          break

        case MeetingLayouts.Floating:
          setView(MeetingViews.MainOnly)
          if(participantsHidden) setParticipantsHidden(false)
          break
      }
    }
  }, [currentWidgetName, screenShareParticipant, layout, participantsHidden])

  return {
    view,
    setView,
    currentWidgetName,
    layout,
    setLayout,
    participantsHidden,
    setParticipantsHidden
  }
}

/*
  ON GOING CALL
    - DEFAULT
      - Main Speaker + Sidebar = (main-with-sidebar)
    - GRID
      - Full Sidebar = (full-sidebar)
    - FLOATING
      - Main Speaker + Floating = (full-main)

  WIDGET MODE
    - DEFAULT
      - Widget + Sidebar Mode = (main-with-sidebar)
    - FLOATING
      - Widget + floating = (full-main)
*/
