import moment from 'moment'
import React, { Dispatch, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaPlayCircle as PlayIcon } from '@react-icons/all-files/fa/FaPlayCircle' 

import EmptyFileContents from './EmptyFileContents'
import { ISelectedData } from '../types'
import Timeline from './Timeline'
import AllWidgets from '../../../loop-widgets'

interface Props {
    selectedData: ISelectedData
    setSItem: Dispatch<any>
}

function LeftDisplay(props: Props) {
    const { selectedData, setSItem } = props
    const [ transcriptions, setTranscriptions ] = useState([])
    const [ recordings, setRecordings ] = useState([])
    const [ syncs, setSyncs ] = useState({})
    const [ selectedItem, setSelectedItem ] = useState<any>({})

    useEffect(() => {
        setTranscriptions(selectedData?.transcriptions || [])
        const t: any[] = (selectedData?.transcriptions || [])
        setSelectedItem((t)?.length ? (t)?.[0] : {})
        setSItem((t)?.length ? (t)?.[0] : {})
    }, [selectedData])

    useEffect(() => {
        setRecordings(selectedData?.recordings || [])
        const r: any[] = (selectedData?.recordings || [])
        if(selectedData?.transcriptions?.length === 0) {
            setSelectedItem((r)?.length ? (r)?.[0] : {})
            setSItem((r)?.length ? (r)?.[0] : {})
        }
    }, [selectedData])

    useEffect(() => {
        setSyncs(selectedData?.syncs || {})
        if(selectedData?.transcriptions?.length === 0 && selectedData?.recordings?.length === 0) {
            const s: any[] = Object.keys(selectedData?.syncs)
            const selected: any = s?.[0]
            const selectedd: any = selectedData?.syncs
            const data: any = selectedd?.[selected]
            const d = {
                checkpoint: {},
				all_changes: data,
				name: selected,
			}
            setSelectedItem((s)?.length ? { data: d } : {})
            setSItem((s)?.length ? { data: d } : {})
        }
    }, [selectedData])
    
    const formatDuration = (seconds: any) => new Date(seconds * 1000).toISOString().substr(11, 8);

    const renderFile = (recording: any) => {
    
        const isTwilio = recording.provider === 'TWILIO'

        const duration = recording.duration ? recording.duration : 
        (recording.compositionDuration ? parseInt(recording.compositionDuration) : 0)
    
        let [hours, minutes, seconds]: any = formatDuration(duration).split(':')
        // remove padding 0
        hours = parseInt(hours)
        minutes = parseInt(minutes)
        seconds = parseInt(seconds)

        const timestring = `${hours ? hours + 'hr ' : ''}${ minutes ? minutes + 'min ' : ''}${seconds ? seconds + 'sec' : ''}`

        const source = recording.uri ? recording.uri.split('/done/')?.[1] : (
            recording.compositionSid && recording.compositionAvailable ?
                `${process.env.REACT_APP_BACKEND_URL}/api/webhooks/twilio/records/${recording.compositionSid}` : ''
        )

        if (isTwilio) {
            if (!recording.isRecording || (recording.isRecording && !recording.compositionSid) ) {
                return <React.Fragment>
                    <div className="duration">Meeting not recorded</div>
                </React.Fragment>
            }

            if (recording.compositionSid) {
                if (recording.compositionAvailable) {
                    return <React.Fragment>
                        <div className="filename"><a href={source} download target="_blank" style={{ fontSize: 12 }}>Download Video</a></div>
                        <div className="duration">{timestring}</div>
                    </React.Fragment>
                }
            }

            return <React.Fragment>
                <div className="duration">Video still processing...</div>
            </React.Fragment>
        } else {
            if ((!recording.isRecording && !recording.compositionSid)) {
                return <React.Fragment>
                    <div className="duration">Meeting not recorded</div>
                </React.Fragment>
            }
            if (recording.isRecording && recording.uri) {
                if (recording.compositionAvailable) {
                    return <React.Fragment>
                        <div className="filename"><a href={recording.uri} download target="_blank" style={{ fontSize: 12 }}>Download Video</a></div>
                        <div className="duration">{timestring}</div>
                    </React.Fragment>
                }
            }
            return <React.Fragment>
                <div className="duration">Video still processing...</div>
            </React.Fragment>
        }
    }

    return (
        <LeftDisplayWrapper className="container rounded inner">
            <LeftInnerWrapper className="topbar rounded inner">

                { !!!transcriptions?.length && !!!recordings?.length && !!!(Object.keys(syncs)?.length) && <EmptyFileContents>No List Found</EmptyFileContents>}
                {
                    transcriptions?.length > 0 && 
                    <React.Fragment>
                        <LeftHeader type="transcriptions">
                            Transcriptions
                        </LeftHeader>
                        <Timeline data={
                            transcriptions?.map((t: any) => ({
                                title: moment(t.created_at).format('hh:mm A'),
                                content: t.title || moment(t.created_at).format('lll'),
                                _id: t._id,
                                itemOnClick: () => {
                                    setSelectedItem(t)
                                    setSItem(t)
                                }
                            }))}
                            active={selectedItem}
                        />
                    </React.Fragment>
                }
                
                {
                    recordings?.length > 0 && 
                    <React.Fragment>
                        <LeftHeader type="recordings">
                            Recordings
                        </LeftHeader>
                        <Timeline data={
                            recordings?.map((r: any) => {

                                return {
                                    _id: r._id,
                                    title: moment(r.created_at).format('hh:mm A'),
                                    content: <RecordingsWrapper>
                                        <RecordingsIcon>
                                            <PlayIcon />
                                        </RecordingsIcon>
                                        <RecordsContent>
                                            { renderFile(r) }
                                        </RecordsContent>
                                    </RecordingsWrapper>,
                                    itemOnClick: () => {
                                        setSelectedItem(r)
                                        setSItem(r)
                                    }
                                }
                            })}
                            active={selectedItem}
                        />
                    </React.Fragment>
                }
                {
                    Object.keys(syncs)?.length > 0 && 
                    <React.Fragment>
                        <LeftHeader type="syncs">
                            Syncs
                        </LeftHeader>
                        <Timeline data={[{
                            content: <SyncsWrapper>
                                {
                                    Object.keys(syncs)?.map((sync: any) => {
                                        const name = sync.replace(/\d/g, '')
                                        const widget_render: any = AllWidgets[name]
                                        //console.log("widget_render", widget_render, AllWidgets, name)
                                        return <SyncsIcon className={`hover-container ${selectedItem?.data?.name === sync ? 'container active-sync' : ''}`} onClick={() => {
                                            const s: any = syncs
                                            const data: any = s?.[sync]
                                            const d = {
                                                checkpoint: {},
                                                all_changes: data,
                                                name: sync,
                                            }
                                            setSelectedItem({ data: d })
                                            setSItem({ data: d })

                                        }}>
                                            <img src={widget_render?.widgetConfig?.icon} alt={name} title={name} />
                                        </SyncsIcon>
                                    })
                                }
                            </SyncsWrapper>
                            }]}
                            active={selectedItem}
                            hoverEffect={false}
                        />
                    </React.Fragment>
                }
            </LeftInnerWrapper>
        </LeftDisplayWrapper>
    )
}

const SyncsWrapper = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat( auto-fit, minmax(40px, 1fr) );
`
const SyncsIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 5px;
    width: 40px;
    height: 40px;

    &.active-sync {
        border: 1px solid #008BFF;
        box-shadow: ${props => props.theme.shadows.newlight};
    }

    img {
        height: 24px;
    }
`

const RecordingsWrapper = styled.div`
    display: flex;
    align-items: center;
`

const RecordingsIcon = styled.div`
    color: #FF7575;
    margin-right: 10px;
    font-size: 16px;
`

const RecordsContent = styled.div`
    .filename {
        color: #008BFF;
        font-weight: 400;
    }

    .duration {
        opacity: 0.6;
        font-size: 13px;
        line-height: 16px;
    }
`

const LeftDisplayWrapper = styled.div`
    min-width: 350px;
    max-width: 350px;
    padding: 15px;
    font-weight: 400;
    display: flex;
`

const LeftInnerWrapper = styled.div`
    padding: 15px;
    width: 100%;
    height: calc(100vh - 297px);
    overflow: auto;
`

const LeftHeader = styled.div<{type: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background: ${
        props => 
            props.type === 'transcriptions' ? 'linear-gradient(114deg,#adadad 0%,#21262b 74%);' : 
            props.type === 'recordings' ? 'linear-gradient(114deg, #FF9900 0%, #FF8200 100%)' :
            props.type === 'syncs' ? 'linear-gradient(90deg, #213465 0%, #008BFF 100%)' :
            'transparent'
    };
    color: #fff;
`

export default LeftDisplay
