import styled from 'styled-components'

export const CalendarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: "Roboto",Arial,Helvetica,sans-serif;
  border: none;
  position: relative;
`

export const CalendarCell = styled.div`
  height: 120px;
  font-size: 1.5em;
  box-shadow: 0 0px 1px #393939;
  border-radius: 10px;
  margin: 5px 0.5%;

  &.big {
    width: 13.2857%;

    &:hover {
      background-color: #F9F9F9
    }
  }

  &.small {
    width: 14.2857%;
    border: none;
    height: auto;
    text-align: center;
    box-shadow: none;
    font-size: 13px;
    margin: 0;
    border-radius: 50%;
  }
`

export const DateCell = styled.div`
  margin: 8px 15px 10px;

  &.current {
    color: #FF9900;
  }

  &.small, &.big {
    &.current {
      background: none;
    }

    &.other {
      color: #bfbfbf;
    }
  }

  &.small {
    width: 38px;
    height: 38px;
    display: block;
    text-align: center;
    padding-top: 10px;
    margin: 3px 3px 3px 8px;

    &.current {
      border-radius: 50%;
      background: #FF9900;
      color: white;
    }
  }
`

export const NumberDate = styled.div`
  font-family: "Roboto",Arial,Helvetica,sans-serif;

  &.big {
    line-height: 30px;
    font-size: 30px;
    font-weight: 500;
  }

  &.small {
    font-weight: 100;
  }
`
export const ShortDay = styled.div`
  &.big {
    font-size: 13px;
  }
`

export const CalendarNavigation = styled.div`
  width: 100%;
  padding: 10px 8px 14px 8px;
  display: flex;
  flex-direction: row;

  div {
    display: inline-block;
  }
`
export const NavMonthYear = styled.div`
  font-family: "Poppins",Arial,Helvetica,sans-serif;
  font-weight: 500;
  font-size: 20px;
  min-width: 100px;
  margin-right: 15px;
`
export const NavNextPrev = styled.div`
  min-width: 20px;
  cursor: pointer;
  font-size: 0.9em;
  color: grey;
  padding-top: 7px;

  &.prev {
    text-align: left;
  }

  &.next {
    text-align: right;
  }
`

export const DayLabel = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  background: #E9ECEF;

  &.cell {
    width: 14.2857%;
    height: auto;
    border: none;
    box-shadow: none;
    font-size: 13px;
    margin: 0;
    border-radius: 0 !important;
  }
`

export const CalendarEvent = styled.div`
  padding: 1px 5px 1px 15px;
  font-size: 8px; /* 0.4em; */
  line-height: 8px;
  cursor: pointer;

  svg {
    color: #007AD8;
  }

  &.holiday svg {
    color: #E01E5A;
  }

  &.busy svg {
    color: #E01E5A;
  }
`