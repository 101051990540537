import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { MainStore } from '../../stores/MainStore'

import { IWidgetProps, AllWidgets } from '../types'

import { ClickupContextProvider } from './ClickupContext'
import ClickupContent from './Content.tsx/ClickupContent'

type Props = IWidgetProps<AllWidgets.Clickup>

class Clickup extends Reflux.Component<typeof MainStore, Props> {

  constructor(props: Props) {
    super(props)

    this.store = MainStore
    this.storeKeys = ['lastView']

    this.state = {
        lastView: {
            clickup: null
        }
    }

  }

  render() {
      console.log("LAST VIEW: ", this.state.lastView?.['clickup'])
    return (
        <ClickupContextProvider
            userId={this.props.userId}
            users={this.props.users}
            external_token={this.props.external_token}
            TryRefreshToken={this.props.actions.TryRefreshToken}
            travelling={this.props.travelling}
            lastView={this.state.lastView?.['clickup'] || null}
        >
            <ClickupContainer>
                <ClickupContent clickupData={this.state.lastView?.['clickup'] || null} />
            </ClickupContainer>
        </ClickupContextProvider>
    )
  }
}


const ClickupContainer = styled.div`
    @media (max-width: 555px) {
        .host-button {
            margin: 0px !important;
        }
    }

`

export const LoadingComponentWrapper = styled.div`
    display: flex;
    flex: 1;

    > div {
        position: unset;
    }
`

export default Clickup
