import React, { Component } from 'react'
import styled from 'styled-components'
import { FaSpinner as LoadingIcon } from '@react-icons/all-files/fa/FaSpinner'

interface Props {
    triggerFileInputClick: () => void
}


class NoImage extends Component<Props> {

    render() {
        return (
            <DropZoneInner>
                <CenterDiv>
                    <UploadPhotoBtn
                        className="button primary"
                        onClick={() => this.props.triggerFileInputClick()}
                    >
                        {
                            false ? (
                                <div><LoadingIcon></LoadingIcon></div>
                            ) : (
                                'Upload Photos'
                            )
                        }
                    </UploadPhotoBtn>
                </CenterDiv>
            </DropZoneInner >
        )
    }
}

const DropZoneInner = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
	/* border: 2px dashed rgb(50, 50, 50); */
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c4c4c4' stroke-width='4' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	display: flex;
	justify-content: center;
	align-items: center;
    
    overflow: auto;
    max-height: 420px;
    min-height: 420px;


    @media (max-height: 740px) {
        min-height: 260px;
        max-height: 260px;

      
    }

    @media (max-width: 480px) {
        min-height: 260px;
        max-height: 260px;

    
    }

`


const CenterDiv = styled.div`
	text-align: center;

	p {
		font-size: 14px;
		margin-bottom: 10px;
	}
`

const UploadPhotoBtn = styled.button`
	color: white;
	height: 40px;
	width: 140px;
	outline: 0;
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 20px;
    cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		background-color: rgba(62, 131, 247, 0.8);
	}

	div {
		animation: spin 2s linear infinite;
		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	}
	
`

export default NoImage
