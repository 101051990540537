import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { FaEye as EyeIcon } from '@react-icons/all-files/fa/FaEye'
import { FaEyeSlash as EyeSlashIcon } from '@react-icons/all-files/fa/FaEyeSlash'
import { useHistory, useParams } from 'react-router'

import LoopApi from '../../helpers/LoopApi'
import useToastify from '../../helpers/useToastify'
import ResendVerification from './ResendVerification'
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast'

import { ErrorMessage, FormGroup, Input, InputLabel, LinkButton, LoginForm, LogoIcon, LogoWrapper, MagicLinkButton, OtherInfo, SubmitButton, SubTitle, Title } from './styled'
import MagicLinkIcon from './magiclink.svg'
import ArchivedAccount from './ArchivedAccount'

function Form() {
  let history = useHistory()
  let { meetingName } = useParams() as any

  const [values, setValues] = useState({
      email: '',
      password: '',
      magicLinkEmail: ''
  })
  const [visibility, setVisibility] = useState(false)
  const [isMagicLink, setIsMagicLink] = useState(false)
  const [archived, setArchived] = useState(false)
  const [verified, setVerified] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [loggingIn, setLoggingIn] = useState(false)
  const [isSendingMagicLink, setIsSendingMagicLink] = useState(false)
  const [isSendingMagicLinkSuccessful, setIsSendingMagicLinkSuccessful] = useState(false)
  const [username, setUsername] = useState('')

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setErrorMessage('')

    setValues({
        ...values,
        [e.target.name]: value
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isMagicLink) {
        return handleLoginWithMagicLink()
    }

    if(!values.email && !values.password) {
        return setErrorMessage('Please enter your email address and password')
    }
    if (!values.email) {
        return setErrorMessage('Please enter your email address')
    }
    if (!values.password) {
        return setErrorMessage('Please enter your password')
    }

    handleLoginWithEmailAndPassword()
  }

  const handleLoginWithEmailAndPassword = async () => {
    setLoggingIn(true)
    setErrorMessage('')
    try {
        const resp = await LoopApi(null, 'Login', {
            email: values.email,
            password: values.password,
        })
        if (resp.error) {
            setLoggingIn(false)
            if(resp.msg.includes('archived')) {
                setArchived(true)
                setUsername(resp.username)
                return setErrorMessage('')
            }
            if(resp.msg.includes('verify')) {
                setVerified(false)
                setUsername(resp.username)
                return setErrorMessage('')
            }
            setErrorMessage(resp.msg)
        } else {
            signinSuccess(resp.token)
            localStorage.removeItem('cached-rooms');
        }
    } catch (err) {
        if(err.message === 'Too many requests, please try again later.') {
            setErrorMessage(err.message)
        }
        setLoggingIn(false)
    }
  }

  const handleLoginWithMagicLink = async () => {
    if (!values.magicLinkEmail) {
        return setErrorMessage('Please enter your email address')
    }

    setIsSendingMagicLink(true)
    setErrorMessage('')
    try {
        const resp = await LoopApi(null, 'MagicLink', {
            email: values.magicLinkEmail,
        })
        if (resp.error) {
            setIsSendingMagicLink(false)
            return setErrorMessage(resp.msg)
        } 

        setIsSendingMagicLink(false)
        useToastify({
            message: () => SuccessErrorToast({ message: 'Successfully sent the magic link. Please check your email.' }),
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
            hideProgressBar: true,
            className: 'toastL',
            bodyClassName: "grow-font-size",
        })
        setIsSendingMagicLinkSuccessful(true)
    } catch (err) {
        if(err.message) {
            setErrorMessage(err.message)
        }
        console.error(err)
        setIsSendingMagicLink(false)
    }
  }

  const signinSuccess = async (token: string) => {
    localStorage.setItem('token', token)

    if (meetingName) return history.push(`/${meetingName}`)

    const meetingResp = await LoopApi(null, 'GetMeetings')
    if (!meetingResp.meetings || meetingResp.meetings.length === 0) {
        return history.push(`/create-or-join`)
    } else if (
        localStorage.lastMeeting &&
        meetingResp.meetings.find((m: any) => m.name === localStorage.lastMeeting)
    ) {
        return history.push(`/${localStorage.lastMeeting}`)
    }

    history.push(`/create-or-join`)
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    if(localStorage.getItem('token')) {
        if(history) {
            history.push(`/create-or-join`)
        } else {
            window.location.href = '/create-or-join'
        }
    }
    return () => {
        window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleMessage = (msg: any) => {
    if (msg.origin === (process.env.REACT_APP_API_URL || 'http://localhost:8000')) {
        const data = JSON.parse(msg.data)
        if (data.token && data.token.token) {
            signinSuccess(data.token.token)
        }
    }
  }

  if(archived) {
      return <ArchivedAccount
        onOk={() => {
            setArchived(false);
            setVerified(true); 
            setVisibility(false)
            setUsername('');
            setValues({
                email: '',
                password: '',
                magicLinkEmail: ''
            })
        }}
        username={username} 
      />
  }

  if(!!!verified) {
    return <ResendVerification 
        email={values.email} 
        username={username} 
        onBack={() => {
            setArchived(false);
            setVerified(true); 
            setVisibility(false)
            setUsername('');
            setValues({
                email: '',
                password: '',
                magicLinkEmail: ''
            })
        }} />
  }

  
  return (
    <LoginForm onSubmit={handleSubmit} autoComplete="off">
        {
            isMagicLink && <LogoWrapper>
                <LogoIcon src={MagicLinkIcon} alt={'Magic Link'} />
            </LogoWrapper>
        }
        <Title center={isMagicLink}>{
            isMagicLink ? 'Tired of typing passwords?' :
            'Sign in to your account'
        }</Title>
        {
            isMagicLink && <SubTitle>
                Get a magic link to be sent to your email so you can login instantly.
            </SubTitle>
        }
        {/* ${(isMagicLink && (focused.magicLinkEmail || values.magicLinkEmail)) || (!isMagicLink && (focused.email || values.email))  ? */}
        <FormGroup className={`form-group focused`}>
            <InputLabel htmlFor="loginEmail">Email Address</InputLabel>
            <Input 
                id="loginEmail" 
                type="email" 
                placeholder='Email Address'
                name={isMagicLink ? 'magicLinkEmail' : 'email'}
                value={isMagicLink ? values.magicLinkEmail : values.email}
                onChange={handleValueChange}
                // onFocus={() => setFocused({ ...focused, email: true, magicLinkEmail: true })} 
                // onBlur={() => setFocused({ ...focused, email: false, magicLinkEmail: false })}
                autoComplete="off"
            />
        </FormGroup>
        {/* ${focused.password || values.password ? */}
        {
            !isMagicLink &&
            <FormGroup className={`form-group focused`}>
                <InputLabel htmlFor="loginPassword">Password</InputLabel>
                <Input 
                    className='form-password'
                    id="loginPassword" 
                    type={visibility ? "text" : "password"} 
                    name="password"
                    value={values.password}
                    onChange={handleValueChange}
                    placeholder="Password"
                    // onFocus={() => setFocused({ ...focused, password: true })} 
                    // onBlur={() => setFocused({ ...focused, password: false })}
                    autoComplete="off"
                />
                <span className="password-view" onClick={() => setVisibility(!visibility)}>{visibility ? <EyeIcon /> : <EyeSlashIcon />}</span>
            </FormGroup>
        }
        {
            errorMessage &&
            <OtherInfo>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </OtherInfo>
        }
        <SubmitButton
            className="button primary"
            disabled={isMagicLink ? (isSendingMagicLink || isSendingMagicLinkSuccessful) : loggingIn}
            value={isMagicLink ? "Send Magic Link" : "Log In"}
            type="submit"
        />
        {
            !isMagicLink && 
            <MagicLinkButton
                disabled={loggingIn}
                onClick={() => {
                    setIsMagicLink(!isMagicLink);
                    setIsSendingMagicLinkSuccessful(false);
                    setErrorMessage('')
                    setValues({
                        email: '',
                        password: '',
                        magicLinkEmail: ''
                    })
                    setVisibility(false)
                }}
                type="button"
            >
                {isMagicLink ? "Back" : "Sign In With Magic Link"}
            </MagicLinkButton>
        }
        <LinkButton onClick={isMagicLink ? () => {
            setIsMagicLink(!isMagicLink);
            setIsSendingMagicLinkSuccessful(false);
            setErrorMessage('')
            setValues({
                email: '',
                password: '',
                magicLinkEmail: ''
            })
        } : () => history.push('/forgot-password')}>
            { isMagicLink ? 'Use password instead' : 'Forgot Password' }
        </LinkButton>
    </LoginForm>
  )
}

export default Form