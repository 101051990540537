import React, { Fragment, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars';
import { Container } from './_Shared'
import moment from 'moment'
import { SetMessage } from './NotificationMessages'
import LoadingComponent from '../../../../loop-widgets/_Shared/LoadingComponent'

interface Props {
    data: {
        notifications: Array<any>
        users: Array<any>
        nextNotification: boolean
    }
    onClose: Function
    getMoreNotifications: Function
}

function NotificationsList(props: Props) {

    const { data: { users, notifications }, onClose } = props;
    let notifs = []

    const loadingRef = useRef<any>()
    let observer: IntersectionObserver

    useEffect(() => {
        const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.95
		}

        observer = new IntersectionObserver(
            handleObserver,
            options,
        )
        if(loadingRef.current)
        observer.observe(loadingRef.current);
    }, [loadingRef])

    const handleObserver = (entities: any) => {
        if(props.data.nextNotification) {
            if (entities[0].isIntersecting) {
                props.getMoreNotifications()
            }
        }
	}

    const filteredNotifs = notifications.filter((n: any) => {
        return !!users.find((u: any) => u.id === n.author)
    })

    if (filteredNotifs.length) {
        notifs = filteredNotifs.length > 1 ?
            filteredNotifs.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) :
            filteredNotifs
    }

    return (
        <Wrapper className="topbar">
            {notifs.length &&
                <Fragment>
                    <Title>Notifications</Title>
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                    >
                        {notifs.map((notification: any, indx: number) => {
                            const user = users.find((u: any) => u.id == notification.author)
                            const { msg, onClickAction } = SetMessage({ notification, user })
                            return (
                                <Item className="rounded inner hover-container" key={indx} onClick={() => { onClickAction(), onClose() }}>
                                    <ItemPhotoWrapper>
                                        <ItemPhoto className="border-topbar">
                                            <img src={user.avatar_url} alt={user.name.split(' ')[0]} />
                                        </ItemPhoto>
                                    </ItemPhotoWrapper>
                                    <ItemBody>
                                        <Message>
                                            {msg}
                                        </Message>
                                        <Time>
                                            {moment(notification.created_at).fromNow()}
                                        </Time>
                                    </ItemBody>
                                </Item>
                            )
                        })}
                        <div ref={loadingRef}>
							<LoadingWrapper loading={props.data.nextNotification}>
								<LoadingComponent />
							</LoadingWrapper>
						</div>
                    </Scrollbars>
                </Fragment> || <EmptyMessage><div>No Notifications Available</div></EmptyMessage>
            }
        </Wrapper>
    )
}

const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
`

const ItemPhotoWrapper = styled.div``

const ItemPhoto = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
`

const Item = styled.div`
    display: flex;
    padding: 6px;

    &:hover {
        ${ItemPhoto} {
        }
    }
`

const ItemBody = styled.div`
    padding: 5px 10px;
    font-weight: 400;
    flex: 1;
`

const Message = styled.div`
    .actor {
        font-weight: 500;
        line-height: 24px;
    }

    .link {
        text-decoration: underline;
    }
`

const Time = styled.div`
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
`

const Wrapper = styled(Container)`
    overflow: scroll;
    height: 340px;
    ::-webkit-scrollbar-corner {background-color: transparent;}
`

const EmptyMessage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LoadingWrapper = styled.div<{loading?: boolean}>`
	position: relative;
	height: 100px;
	${({loading}) => loading === false ? 'display: none;' : loading === true ? 'display: block;' : ''}
`

export default NotificationsList
