import React, { Component, Fragment } from 'react';
import Reflux from 'reflux'
import styled from 'styled-components';

import Login from '../_Shared/Login';
import ChannelList from './ChannelList';
import Message from './Message';
import DMList from './DMList';
import HostSlack from './HostSlack';
import Modal from '../_Shared/Modal'

import Api, { Actions, Endpoints } from '../_Shared/Api';
import LoopApi from '../../helpers/LoopApi';
import { IWidgetProps, AllWidgets } from '../types';
import ShowNotification from '../../helpers/Notification';

import { WidgetContainer } from '../_Shared/Elements';
import WidgetHeader from '../_Shared/Header';
import { Button } from '../../components/Elements';
import Logo from './icon.svg';
import { FaAngleLeft as CollapseIcon } from '@react-icons/all-files/fa/FaAngleLeft'

// import ChangeHostModal from './ChangeHostModal';
import Scrollbars from 'react-custom-scrollbars';
import LinkOtherAccountModal from './LinkOtherAccountModal';
import SearchField from './SearchField';
import { ThemingStore } from '../../stores/ThemingStore';
import useToastify from '../../helpers/useToastify';
import SuccessErrorToast from '../../components/Notifications/SuccessErrorToast';

type Props = IWidgetProps<AllWidgets.Slack>;
interface State {
  isHost: boolean | undefined;
  isLeftVisible: boolean;
  // hostChangeModal: boolean
  isAuthenticated: boolean
  team: ITeam | null,
  dms: any
  messages: any
  membersList: any
  channelList: any
  selected: any
  linkOtherModal: boolean
  searchValue: string
  refreshMessages: boolean
  color_scheme: string
}

interface ITeam {
  name: string
}

export default class Slack extends Reflux.Component<typeof ThemingStore, Props, State> {
  last_auth_attempt: any
  searchingTimeout: any

  constructor(props: Props) {
    super(props);
    this.searchingTimeout = null;

    this.store = ThemingStore
    this.storeKeys = ['color_scheme']

    this.state = {
      isHost: false,
      isLeftVisible: true,
      // hostChangeModal: false,
      isAuthenticated: true,
      team: null,
      dms: [],
      messages: [],
      channelList: [],
      membersList: [],
      selected: null,
      linkOtherModal: false,
      searchValue: '',
      refreshMessages: false,
      color_scheme: 'Light'
    };

    this.listChannels = this.listChannels.bind(this);
    this.handleUpdates = this.handleUpdates.bind(this);
    this.pokeMemberInSlack = this.pokeMemberInSlack.bind(this);
    this.listUsers = this.listUsers.bind(this);
    this.initSlack = this.initSlack.bind(this);
    // this.changeHost = this.changeHost.bind(this);
    this.joinChannel = this.joinChannel.bind(this);
    this.getTeamInfo = this.getTeamInfo.bind(this);
    this.last_auth_attempt = null
  }

  componentDidMount() {
    if (this.props.external_token) {
      this.initSlack();
      // setTimeout(()=>{
      //   this.handleHostChange()
      // }, 200)
    }
  }

  componentDidUpdate(prevProps: Props) {
    // if (this.props.data.hostId !== prevProps.data.hostId) {
    // }
    // if (!!this.props.data.hostRequest &&
    //   this.props.data.hostRequest !== prevProps.data.hostRequest) {
    //   this.handleHostChange()
    // }
    if (
      this.props.external_token &&
      this.props.external_token !== prevProps.external_token
    ) {
      this.initSlack();
      this.setState({ isAuthenticated: true, linkOtherModal: false })
    }
  }

  async initSlack() {
    // if (this.checkIfHost(this.props.data.hostId)) {
    await this.listChannels();
    await this.getTeamInfo();
    await this.listUsers();
    // }
  }

  async getTeamInfo() {
    await Api(Endpoints['Slack'], Actions['GetTeamInfo'], this.props.external_token, {
      noHeader: true,
      headerSettings: {
        contentType: false,
        headerConfig: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    })
      .then((response) => {
        if (response.error && !response.ok) {
          this.authFailed()
          return
        }
        const { team }: { team: ITeam } = response
        this.setState({ team })

      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  async listChannels() {
    const url = `?types=public_channel,private_channel,im`;

    await Api(Endpoints['Slack'], Actions['ListChannels'], this.props.external_token, {
      urlAppend: url,
      noHeader: true,
      headerSettings: {
        contentType: false,
        headerConfig: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    })
      .then((response) => {
        if (response.error && !response.ok) {
          this.authFailed()
          return
        }
        const channelList = response.channels.filter((c: any) => !c.is_im);
        const dms = response.channels.filter((c: any) => c.is_im);
        let selected = this.state.selected;
        if (selected) {
          const filtered = response.channels.filter((c: any) => c.id === selected.id);
        }
        selected = response.channels && response.channels.length > 0 ? response.channels[0] : selected;
        this.setState({ channelList, dms, selected });
      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  async listUsers() {
    const url = ``;

    await Api(Endpoints['Slack'], Actions['ListUsers'], this.props.external_token, {
      urlAppend: url,
      noHeader: true,
      headerSettings: {
        contentType: false,
        headerConfig: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    })
      .then((response) => {
        const membersList = response.members
          .filter((m: any) => !m.is_bot && m.name !== 'slackbot' && !m.deleted)
          .map((m: any) => {
            return {
              username: m.name,
              name: m.profile.real_name_normalized,
              displayName: m.profile.display_name_normalized,
              avatar_url: m.profile.image_512,
              id: m.id,
            };
          });
        this.setState({ membersList })
        // this.props.actions.UpdateSelf({ membersList });
      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  checkIfHost(hostId: string | null | undefined) {
    const myself = (this.props.users || []).find((u: any) => u.id === this.props.userId);
    const isHost = (myself && myself.member_id === hostId) || (myself && myself.id === hostId);

    this.setState({ isHost });

    return isHost;
  }

  // changeHost(request?: boolean) {
  //   const myself = (this.props.users || []).find((u) => u.id === this.props.userId);
  //   const isHostOnline = this.props.data.hostId && (this.props.users || []).find((u) => u.id === this.props.data.hostId)?.status === 'online';

  //   if(request && this.props.data.hostId !== null && isHostOnline) {
  //     this.props.actions.UpdateSelf({
  //       hostRequest: {
  //         requestor: myself?.member_id || myself?.id,
  //         status: 'requesting'
  //       }
  //     });
  //   } else {
  //     this.props.actions.UpdateSelf({
  //       hostId: myself?.member_id || myself?.id,
  //       selected: null,
  //       messages: [],
  //       hostRequest: null
  //     });
  //   }

  // }

  handleUpdates(obj: object) {
    this.setState({ ...obj });
  }

  async pokeMemberInSlack(member: any, e: any) {
    e.stopPropagation();

    const userRegex = /^(U)/gm;
    let isUser = false;
    let user = null as any;

    if (new RegExp(userRegex).test(member.user)) {
      isUser = true;
      const filteredMember = this.state.membersList.filter((m: any) => m.id === member.user);
      user = filteredMember && filteredMember.length > 0 ? filteredMember[0] : null;
    }

    user = user ? `, ${user.displayName || user.name}` : '';

    const message = `*Hi${isUser ? user : ', everyone'}!* :wave: ↵You've been poked to join ${this.props.meetingName
      }! Go to ${window.location.origin}/${this.props.meetingName}`;
    const blocks = [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: `Hi${isUser ? user : ', everyone'}! :wave:`,
          emoji: true,
        },
      },
      {
        type: 'section',
        text: {
          type: 'plain_text',
          text: `You've been invited to join ${this.props.meetingName} on Grapl! Go to ${window.location.origin}/${this.props.meetingName} or click the button below to join.`,
          emoji: true,
        },
      },
      {
        type: 'actions',
        elements: [
          {
            type: 'button',
            text: {
              type: 'plain_text',
              emoji: true,
              text: 'Join the meeting',
            },
            style: 'primary',
            value: 'join',
            url: `${window.location.origin}/${this.props.meetingName}`,
          },
        ],
      },
    ];

    await LoopApi(null, 'SendSlackMessage', {
      channel: member.id,
      message,
      blocks: JSON.stringify(blocks),
      meeting_name: this.props.meetingName,
    })
      .then((response) => {
        if (response.ok) {
          useToastify({
            message: () => SuccessErrorToast({ message: 'Successfully sent an invitation.' }),
            position: "top-right",
            autoClose: 1500,
            closeButton: false,
            hideProgressBar: true,
            className: this.state.color_scheme === 'Light' ? 'toastL' : 'toastD',
            bodyClassName: "grow-font-size",

          })
          if (this.state.selected?.id === member.id) {
            this.setState({ refreshMessages: true })
          }
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  async joinChannel(id: string, e: any) {
    // debugger
    e.stopPropagation();

    const selected_id = id || this.state.selected?.id;
    const url = `?channel=${selected_id}`;

    await Api(Endpoints['Slack'], Actions['JoinChannel'], this.props.external_token, {
      urlAppend: url,
      noHeader: true,
      headerSettings: {
        headerConfig: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    })
      .then((response) => {
        if (response.ok) {
          this.listChannels();
          ShowNotification({ message: 'Successfully joined the channel' });
        }
      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  toggleVisibility = () => {
    this.setState({ isLeftVisible: !this.state.isLeftVisible })
  }

  // toggleHostChangeModal = (val: boolean) => {
  //   this.setState({hostChangeModal: val})
  // }

  // isAccepted = (val: boolean) => {
  //   if(val) {
  //     this.props.actions.UpdateSelf({
  //       hostRequest: {
  //         ...this.props.data.hostRequest,
  //         status: 'accepted'
  //       }
  //     });
  //   } else {
  //     this.props.actions.UpdateSelf({
  //       hostRequest: null
  //     });
  //   }

  // }

  // handleHostChange = () => {
  //   if(this.props.data.hostRequest) {
  //     if(this.props.data.hostRequest.status === 'requesting' && this.state.isHost && !this.state.hostChangeModal) {
  //       this.toggleHostChangeModal(true)
  //     } else if (this.props.data.hostRequest.status === 'accepted' && 
  //       this.props.data.hostRequest.requestor === this.props.userId) {
  //       this.changeHost()
  //     }
  //   }
  // }

  authFailed = () => {
    this.setState({ isAuthenticated: false })
    if (
      this.props.external_token &&
      this.last_auth_attempt < Date.now() - 1000
    ) {
      if (this.last_auth_attempt === -1) {
        this.last_auth_attempt = 0
      } else {
        this.last_auth_attempt = Date.now()
      }
      this.props.actions.TryRefreshToken('slack')
    }
  }

  handleSearch = async () => {
    const url = `?query=${this.state.searchValue}`;
    await Api(Endpoints['Slack'], Actions['SearchAll'], this.props.external_token, {
      urlAppend: url,
      noHeader: true,
      headerSettings: {
        headerConfig: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    })
      .then((response) => {
        if (response.error && !response.ok) {
          this.authFailed()
          return
        }
        if (response.ok) {
          //console.log("Response: ", response)
        }
      })
      .catch((error) => {
        //console.log('Error: ', error);
      });
  }

  handleSearchInputChange = (e: any) => {
    if(!!e.target.value) {
      clearTimeout(this.searchingTimeout);

      // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
      this.searchingTimeout = setTimeout(this.handleSearch, 500);
  
      // Setting value of the search box to a state.
      this.setState({ [e.target.name]: e.target.value } as Pick<State, keyof State>);
    }
  }

  render() {
    if (!this.props.external_token || !this.state.isAuthenticated) {
      return <Login logo={Logo} name='Slack' loginKey='slack' />;
    }

    const main = (
      <>
        <Scroller
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {this.state.channelList && (
            <ChannelList
              token={this.props.external_token}
              channelList={this.state.channelList}
              users={this.props.users}
              handleUpdates={this.handleUpdates}
              isHost={this.state.isHost}
              pokeNow={this.pokeMemberInSlack}
              joinChannel={this.joinChannel}
              toggleVisibility={this.toggleVisibility}
              selected={this.state.selected?.id}
            />
          )}
          {this.state.dms && (
            <DMList
              token={this.props.external_token}
              membersList={this.state.membersList}
              users={this.props.users}
              handleUpdates={this.handleUpdates}
              dms={this.state.dms}
              isHost={true}
              pokeNow={this.pokeMemberInSlack}
              toggleVisibility={this.toggleVisibility}
              selected={this.state.selected?.id}
            />
          )}
        </Scroller>
      </>
    );

    return (

      <>
        <HeaderWrapper>
          {/* {this.props.data.hostId && ( */}
          <Modal show={this.state.linkOtherModal} overlayClicked={() => this.setState({ linkOtherModal: false })}>
            <LinkOtherAccountModal refresh={this.props.actions.TryRefreshToken} cancel={() => this.setState({ linkOtherModal: false })} />
          </Modal>
          <WidgetHeader
            icon={require('./icon.svg')}
            size={['35px', '35px']}
            name={<div style={{ fontSize: '16px', fontWeight: 500 }}>{this.state.team?.name || 'Team'}</div>}
            end={
              <React.Fragment>
                <Button className="button" onClick={() => { this.setState({ linkOtherModal: true }) }}>Link other workspace</Button>
                <SearchField handleChange={this.handleSearchInputChange} />
              </React.Fragment>
            }
            alignEndCenter
          // end={this.state.isHost && 
          //   <HButton className="button default custom-padding disabled">You are the host</HButton>
          // }
          // pre={
          //   <React.Fragment>
          //     {/* {!!this.props.data.selected && (
          //       <Button onClick={() => this.handleUpdates({ selected: null, messages: [] })}>Back</Button>
          //     )} */}
          //     {!!!this.state.isHost && (
          //       <React.Fragment>
          //         <Button {...{ type: 'primary-nobg' }} onClick={() => this.changeHost(true)}>
          //           {' '}
          //           Be the host{' '}
          //         </Button>
          //       </React.Fragment>
          //     )}
          //   </React.Fragment>
          // }
          />
          {/* )} */}

        </HeaderWrapper>
        <Container>
          <IWidgetContainer
            isLeftVisible={this.state.isLeftVisible}
            hasSelected={!!this.state.selected}
            hasHostID={true}
          >
            {/* {!this.props.data.hostId ? <HostSlack {...this.props} /> : main} */}
            {main}
          </IWidgetContainer>
          {this.state.selected ? (
            <Fragment>
              {/* <div className="button primary" onClick={() => props.setExpanded(!props.expanded) }><CollapseIcon size={16} title="Collapse" /></div> */}
              <BackButton
                isLeftVisible={this.state.isLeftVisible}
                className="button primary"
                onClick={() => this.toggleVisibility()}
              >
                <CollapseIcon size={16} title="Collapse" />
              </BackButton>
              <Message
                messages={this.state.messages}
                token={this.props.external_token}
                selected={this.state.selected}
                handleUpdates={this.handleUpdates}
                membersList={this.state.membersList}
                channelList={this.state.channelList}
                isHost={true}
                meetingName={this.props.meetingName}
                listChannels={this.listChannels}
                isLeftVisible={this.state.isLeftVisible}
                refreshMessages={this.state.refreshMessages}
              />
            </Fragment>
          ) : null}

          {/* {this.state.hostChangeModal && <ChangeHostModal 
              toggleModal={this.toggleHostChangeModal}
              isAccepted={this.isAccepted}
          />} */}
        </Container>
      </>
    );
  }
}

const HeaderWrapper = styled.div`
  padding: 20px;
`

const NewButton = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const Scroller = styled(Scrollbars)`
  /* display: flex;
	flex-direction: column;
	flex: 1; */
  /* overflow-y: auto;
  margin-top: 20px; */
`;

const HButton = styled.div`
    text-align: center;
    cursor: pointer;
    font-weight: ${props => props.theme.textBold};

    &.custom-padding {
        padding: 10px 15px !important;
        font-size: 12px;
        margin: 0 10px;
    }

    transition: box-shadow 0.3s ease-out;
    &:hover {
        box-shadow: ${props => props.theme.shadows.neumorphiclight};
    }
`

const IWidgetContainer = styled(WidgetContainer) <{ isLeftVisible: boolean, hasSelected: boolean, hasHostID: boolean }>`
  padding: 0px 0px 5px 0px;
  display: ${({ isLeftVisible }) => isLeftVisible ? 'flex' : 'none'};

  @media (min-width: 768px){
    display: flex;
    min-width: 340px;
    ${({ hasSelected, hasHostID }) => hasSelected || !hasHostID ? null : 'max-width: 340px'}
  }
`

const BackButton = styled.div<{ isLeftVisible: boolean }>`
  height: 27px;
  width: 27px;
  position: relative;
  left: 36px;
  top: 9px;
  margin-left: -27px;
  cursor: pointer;
  padding: 5px !important;
  display: ${({ isLeftVisible }) => isLeftVisible ? 'none' : 'block'};

  @media (min-width: 768px){
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  height: calc(100% - 80px);
`
