import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IUser } from './types'
const { GlobalState } = require('reflux')

interface Props {
    id: String
    hidden?: boolean
    small?: boolean
    src?: string
}

function ChatAvatar(props: Props) {
    const users = GlobalState.main.users

    const [user, setuser] = useState<IUser>()

    useEffect(() => {
        const myInfo = GlobalState.main.users.find((user: IUser) => user.id === props.id)
        setuser(myInfo)
    }, [users])

    return (
        <User title={user?.name} hide={props.hidden || false} small={props.small || false}>
            {
                <img
                    src={
                        user?.avatar_url || props.src ||
                        `https://ui-avatars.com/api/?name=${user?.name}&format=svg`
                    }
                    alt={user?.name}
                />
            }
            {user?.status === 'online' && <Indicator small={props.small || false} />}
        </User>

    )
}
const Indicator = styled.div<{ small: boolean }>`
   
    width: ${({ small }) => small ? '7px' : '10px'};
    height: ${({ small }) => small ? '7px' : '10px'};
    background-color: green;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border : 1px solid white;
`
const User = styled.div<{ hide: boolean, small: boolean }>`
    opacity: ${({ hide }) => hide ? '0' : '1'};
    position: relative;
    min-width: ${({ small }) => small ? '30px' : '40px'};
    min-height: ${({ small }) => small ? '30px' : '40px'};
    width: ${({ small }) => small ? '30px' : '40px'};
    height: ${({ small }) => small ? '30px' : '40px'};
    object-fit: cover;
    border: 1px solid white;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
        font-size: 12px;
    }
`

export default ChatAvatar