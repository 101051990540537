import React from 'react'
import styled from 'styled-components'
import { IChat } from '../types'
import moment from 'moment'

interface Props {
    chat: IChat
}

function Info({ chat }: Props) {
    return (
        <InfoCont>
            <Time>
                {moment(chat.timeStamp).format('h:mm A')}
            </Time>
            <Message>{chat.message}</Message>
        </InfoCont>
    )
}

export default Info

const InfoCont = styled.div`
    padding: 25px 80px;
    text-align: center;
`

const Time = styled.div`
    margin-bottom: 15px;
    font-size: 10px;
`

const Message = styled.div`
    opacity: .8;
    font-size: 12px;
    display: flex;
    justify-content: center;
    line-height: 20px;
`