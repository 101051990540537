import { createActions, Store } from 'reflux'
import jwtDecode from 'jwt-decode'
// import Raven from 'raven-js'
import LoopApi from '/helpers/LoopApi'
import * as Sentry from '@sentry/browser';
import { MainActions } from './MainStore'
import Api, { Actions, Endpoints } from '../loop-widgets/_Shared/Api'

export var AuthActions = createActions([
	'SetTokens',
	'SetJwt',
	'ClearJwt',
	'IntendedJoin',
	'SetExternalToken',
	'RemoveExternalToken',
	'TryRefreshToken',
	'FinishedCheckMeeting'
])

export class AuthStore extends Store {
	constructor() {
		super()
		this.state = {
			jwt: null,
			intended_join: null,
			external_tokens: JSON.parse(sessionStorage.external_tokens || '{}'),
			finishedCheckMeeting: false,
		}
		this.listenables = AuthActions
	}

	onFinishedCheckMeeting() {
		this.setState({ finishedCheckMeeting: true })
	}

	onSetTokens(external_tokens = {}) {
		this.setState({
			external_tokens: {
				...this.state.external_tokens,
				...external_tokens,
			},
		})
	}

	onSetExternalToken(strategy, access_token, refresh_token = null) {
		const external_tokens = { ...this.state.external_tokens }
		external_tokens[strategy] = access_token
		this.setState({ external_tokens })

		LoopApi(null, 'SetToken', { strategy, refresh_token, access_token })

		sessionStorage.external_tokens = JSON.stringify(external_tokens)
	}

	async onRemoveExternalToken(strategy, skip_request = false) {
		!skip_request && LoopApi(null, 'RemoveToken', { strategy })
		const external_tokens = { ...this.state.external_tokens }
		const token = external_tokens[strategy]
		delete external_tokens[strategy]
		this.setState({ external_tokens })
		sessionStorage.external_tokens = JSON.stringify(external_tokens)

		const action = 'RevokeToken'
		let resp
		switch(strategy) {
			case 'googledrive':
				resp = await Api(Endpoints['GoogleDriveOauth'], Actions[action], token, {
					urlAppend: `?token=${token}`
				})
			break
			case 'dropbox':
				resp = await Api(Endpoints['Dropbox'], Actions[action], token, {body: null})
			break
		}
		return resp
	}

	async onTryRefreshToken(strategy) {
		try {
			const result = await LoopApi(null, 'RefreshToken', { strategy })
			if (result.error) {
				return console.error(result)
			}
			if (!result) {
				this.onRemoveExternalToken(strategy, true)
			}
			const external_tokens = {
				...this.state.external_tokens,
				[strategy]: result.token,
			}
			this.setState({ external_tokens })
			sessionStorage.external_tokens = JSON.stringify(external_tokens)
		} catch (e) {
			console.error(e)
		}
	}

	onIntendedJoin(meeting_name) {
		this.setState({
			intended_join: meeting_name,
		})
	}

	onSetJwt(passedJwt = null) {
		let jwt = passedJwt || localStorage.getItem('token')
		if (!jwt) {
			return
		}

		try {
			const decoded = jwtDecode(jwt)
			if (decoded.exp && Math.floor(Date.now() / 1000) > decoded.exp) {
				localStorage.removeItem('token')
                sessionStorage.removeItem('roomsAllowed')
				if(localStorage.getItem('audio_input_device_id')) {
					localStorage.removeItem('audio_input_device_id')
				}
				if(localStorage.getItem('video_input_device_id')) {
					localStorage.removeItem('video_input_device_id')
				}
				if(localStorage.getItem('audio_output_device_id')) {
					localStorage.removeItem('audio_output_device_id')
				}

				this.setState({ jwt: null })
				return location.reload()
			}
			this.setState({ jwt: decoded })

			const raven_data = {
				username: decoded.data.username,
				email: decoded.data.email,
				created_at: decoded.data.created_at,
				type: decoded.data.type,
				verified: decoded.data.verified,
				id: decoded.data._id,
			}
			MainActions.SetUpdateUser({id: decoded.data._id, data: decoded.data})
			Sentry.setUser(raven_data)
		} catch (err) {
			localStorage.removeItem('token')
			sessionStorage.removeItem('roomsAllowed')
			localStorage.removeItem('recentRooms')
			if(localStorage.getItem('audio_input_device_id')) {
				localStorage.removeItem('audio_input_device_id')
			}
			if(localStorage.getItem('video_input_device_id')) {
				localStorage.removeItem('video_input_device_id')
			}
			if(localStorage.getItem('audio_output_device_id')) {
				localStorage.removeItem('audio_output_device_id')
			}

			this.setState({ jwt: null })
			console.error(err)
			location.reload()
		}
	}

	onClearJwt() {
		localStorage.removeItem('token')
		sessionStorage.removeItem('roomsAllowed')
		localStorage.removeItem('external_tokens')
		localStorage.removeItem('recentRooms')
        if(localStorage.getItem('audio_input_device_id')) {
			localStorage.removeItem('audio_input_device_id')
		}
		if(localStorage.getItem('video_input_device_id')) {
			localStorage.removeItem('video_input_device_id')
		}
		if(localStorage.getItem('audio_output_device_id')) {
			localStorage.removeItem('audio_output_device_id')
		}

		this.setState({ jwt: null })
	}
}

AuthStore.id = 'auth'
