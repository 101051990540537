import React, { useEffect, useRef } from 'react'

import { AspectRatioEnforcer, Video } from './styles'
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from '../../../Sidebar/UsersInCall/types';

interface Props {
	track?: MediaStreamTrack | IFakeMediasoupOuterTrack
}

function ScreenTrack(props: Props) {
    const trackRef = useRef<HTMLVideoElement>(null);
    const { track } = props

    useEffect(() => {
        if (trackRef?.current) {
            const stream = new MediaStream()
			track && normalizedCallHelpers.addTrackToStream(stream, track)

            trackRef.current.srcObject = stream;
        }
    }, [track]);

    return (
        <AspectRatioEnforcer>
            <Video className="video_track_container">
                <video {...{ ref: trackRef } as any} playsInline autoPlay className="screenvideo"/>
            </Video>
        </AspectRatioEnforcer>
    )
}

function areEqual(prevProps: Props, nextProps: Props) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track)
}

export default React.memo(ScreenTrack, areEqual)
