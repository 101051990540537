import React, { useEffect } from 'react'

import Login from '../../_Shared/Login'

import useClickupContext from '../useClickupContext'

import Logo from '../icon.svg'
import TeamsList from '../TeamsList'
import SpaceList from '../SpaceList'
import Tasks from '../Tasks'
import Task from '../Task'
import HostClickup from '../HostClickup'

function ClickupContent({ clickupData }: any) {
    const { external_token, isAuthenticated, travelling, currentView, setClickupData } = useClickupContext()

    useEffect(() => {
        console.log({ clickupData })
        setClickupData(clickupData)
    }, [JSON.stringify(clickupData)])
    return (
        <React.Fragment>
            {
                (!external_token || !isAuthenticated) && !travelling ? (
                    <Login logo={Logo} name="Clickup" loginKey="clickup" />
                ) : !!!currentView ? (
                    <HostClickup />
                ) : currentView === 'teamlist' ? (
                    <TeamsList />
                ) : currentView === 'spaces' ? (
                    <SpaceList />
                ) : currentView === 'taskslist'  ? (
                    <Tasks />
                ) : currentView === 'task' ? (
                    <Task />
                ) : null
            }
        </React.Fragment>
    )
}

export default ClickupContent