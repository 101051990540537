import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { TiMicrophone as VolumeIcon } from '@react-icons/all-files/ti/TiMicrophone'
import { MdVideocam as VideoIcon } from '@react-icons/all-files/md/MdVideocam'
import { MdCallEnd as EndCallIcon } from '@react-icons/all-files/md/MdCallEnd'
import { BsThreeDotsVertical as MoreOptionIcon } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import { FaCrown as HostIcon } from '@react-icons/all-files/fa/FaCrown'

import LoopApi from '../../../../../helpers/LoopApi'
import DropdownWrapper from '../../../../../screens/Meeting2.0/Sidebar/TopBar/DropdownWrapper'
import { IFakeMediasoupOuterTrack, normalizedCallHelpers } from '../../../Sidebar/UsersInCall/types'
import { CallControlsContainer, IconButton, Item, XLine } from './styles'
import { VariableCallingActions, VariableCallingStore } from '../../../../../stores/VariableCallingStore'
import { MainStore } from '../../../../../stores/MainStore'
import useToastify from '../../../../../helpers/useToastify'

interface IProps {
	videoTrack?: IFakeMediasoupOuterTrack | MediaStreamTrack
	audioTrack?: IFakeMediasoupOuterTrack | MediaStreamTrack
	screenTrack?: IFakeMediasoupOuterTrack | MediaStreamTrack
	participant?: any
}

interface State {
	callObject: any
	meetingName: string
	call_starter: string
	host: string
}

export class CallControls extends Reflux.Component<typeof VariableCallingStore | typeof MainStore,IProps, State> {
    constructor(props: never) {
        super(props)
        this.stores = [VariableCallingStore, MainStore]
        this.storeKeys = ['callObject', 'meetingName', 'call_starter', 'host']

    }
    state: State = { callObject: {}, meetingName: '', call_starter: '', host: '' }

    getStreamStates = () => {
		const { callObject } = this.state
		let isCameraMuted = false
		let isMicMuted = false
		let isSharingScreen = false
        let othersIsSharingScreen = false
		if (
		  callObject &&
		  callObject.participants &&
		  callObject.participants() &&
		  callObject.participants().local
		) {
			const otherParticipantsKey = Object.keys(callObject?.participants())?.filter(p => p !== 'local')
			const shareArray = otherParticipantsKey.map((p: any) => callObject?.participants()?.[p]?.screen)
			const localParticipant = callObject.participants().local
			isCameraMuted = !localParticipant.video
			isMicMuted = !localParticipant.audio
			isSharingScreen = localParticipant.screen
			othersIsSharingScreen = (shareArray || [])?.includes(true)
		}
		return { isCameraMuted, isMicMuted, isSharingScreen, othersIsSharingScreen }
	}

    startCapture = () => {
		const streamStates = this.getStreamStates()
		VariableCallingActions.Screenshare(!streamStates.isSharingScreen)
	}

	askHostAccess = async () => {
		try {
			const resp = await LoopApi(null, 'AskHostAccess', {host_id: this.state.host }, [
				['name', this.state.meetingName],
			])

			if (resp.status === 'failed') {
				useToastify({
					type:'error',
					message: 'Denied access'
				})
			}
		} catch (e) {
			console.error(e)
		}
	}

    render() {
		const { callObject, call_starter, host } = this.state
		const { participant } = this.props
		const streamStates = this.getStreamStates()

		const isAudioDisabled = this.props.audioTrack && normalizedCallHelpers.isTrackPaused(this.props.audioTrack) || streamStates.isMicMuted
		const isCameraDisabled = this.props.videoTrack && (normalizedCallHelpers.isTrackPaused(this.props.videoTrack) || normalizedCallHelpers.isTrackEnded(this.props.videoTrack)) || streamStates.isCameraMuted

        const MoreOptions = (props: any) => {
			return <React.Fragment>
				<Item
					className={`hover-container ${this.getStreamStates()?.isSharingScreen ? 'color-primaryimp' : ''}`} 
					onClick={() => {
						if(!!!this.getStreamStates()?.othersIsSharingScreen) {
							this.startCapture()
							props.onClose()
						}
					}}
					style={{
						color: !!!this.getStreamStates()?.othersIsSharingScreen ? 'unset' : 'rgba(0,0,0,0.5)',
						cursor: !!!this.getStreamStates()?.othersIsSharingScreen ? 'pointer' : 'not-allowed'
					}}
				>
					<ScreenshareIconSvg />
					{this.getStreamStates()?.isSharingScreen ? 'Stop Screenshare' : 'Screenshare'}
				</Item>
				{host !== participant?.id && <Item className="hover-container" onClick={() => {
					this.askHostAccess()
					props.onClose()
				}}>
					<HostIcon size={16} />
					Ask for host access
				</Item>}
			</React.Fragment>
		}

        return (
            <CallControlsContainer className="call-control-container">
                <IconButton
                    className="button default-opaque call-control"
                    muted={isAudioDisabled}
                    onClick={callObject && callObject.setLocalAudio ? () => callObject.setLocalAudio(streamStates.isMicMuted?.toString() === 'true' ? localStorage.getItem('audio_input_device_id') : streamStates.isMicMuted ) : () => {}}
                >
                    <VolumeIcon />
                    {(isAudioDisabled) ? <XLine big/> : null}
                </IconButton>

                <IconButton
                    muted={isCameraDisabled}
                    className="button default-opaque call-control"
                    onClick={callObject && callObject.setLocalVideo ? () => callObject.setLocalVideo(this.getStreamStates()?.isCameraMuted?.toString() === 'true' ? localStorage.getItem('video_input_device_id') : this.getStreamStates()?.isCameraMuted) : () => {}}
                >
                    <VideoIcon />
                    {(isCameraDisabled) ? <XLine big/> : null}
                </IconButton>

                <Dropdown DropdownItems={MoreOptions}>
                    <IconButton className={`button default-opaque call-control`}>
                    <MoreOptionIcon />
                    </IconButton>
                </Dropdown>

                <IconButton
                    className="button danger-opaque call-control"
                    onClick={() => {
                        VariableCallingActions.Leave();
                    }}
                >
                    <EndCallIcon />
                </IconButton>
            </CallControlsContainer>
        )
    }
}

const Dropdown = styled(DropdownWrapper)<{screenSharing?: boolean}>`
	.dropdown-content {
		position: fixed; 
		bottom: unset;
		transform: translate(-100px, -100px);
	}

	@media (max-width: 970px) {
		.dropdown-content {
			position: fixed;
			bottom: unset;
			transform: translate(-100px, -100px);
		}
	}
`

const ScreenshareIconSvg = ({ size = 16 }) => {
	return <svg width={size} height={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19 9V12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M5 17H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M7 13V17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M13 13V17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M15 1H19V5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M14 6L19 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
}


export default CallControls
