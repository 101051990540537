const { GlobalState } = require('reflux')
import React from 'react'
import { createActions, Store } from 'reflux'
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Sagas from '../helpers/Sagas'
import * as CallTranscriber from '../helpers/calling/CallTranscriber'

import LoopApi from '../helpers/LoopApi'
import {
	BaseProviderInstance,
	CallingInstanceState,
	callingProviderLoadableClasses,
	CallingProviderName,
} from '../calling/types'
import { Slide, toast } from 'react-toastify'
import { colorLog } from '../calling/_utils'
import useToastify from '../helpers/useToastify'
import RequestHostAccess from '../components/HostAccess'
import HostTransferred from '../components/HostTransferred'
import LockMeeting from '../components/Notifications/LockMeeting'
import { sortDate } from '../helpers';
import StartMeeting from '../components/Notifications/StartMeeting';

import { MainActions, WidgetActions } from './MainStore'
import MuteParticipant from '../components/Notifications/MuteParticipant';
import ToggleRecording from '../components/Notifications/ToggleRecording';
import RecordingNotif from '../components/RecordingNotif';

const CONSOLE_TAG = 'Calling'

const loading_states = [
	CallingInstanceState.Joining,
	CallingInstanceState.Initializing,
]
const failed_states = [CallingInstanceState.JoiningFailed]
const in_call_states = [
	CallingInstanceState.Connected,
	CallingInstanceState.Disconnected,
]

export const VariableCallingActions = createActions([
	'Join',
	'Leave',
	'SetConnectedUserIds',

	'Screenshare',
	'SetActivePresenter',

	// Producer toggling
	'MuteMic',
	'ToggleVideo',
	'ToggleScreenshare',

	'c',

	'LocalTrackSpoke', // Used to determine backend transcription

	// Decibal levels
	'PeerVolumeChange',
	'SetActiveSpeaker',

	'SaveMeetingSession',
	'SetHost',
	'SetLock',

	// host access
	'SetCallStarter',
	'RequestHostAccess',
	'MeetingRecording',
	'DenyHostAccess',
	'DenyHostRequest',
	'ToggleLockMeeting',
	'NotifyStartMeeting',
	'NotifyJoinMeeting',
	'NotifyLeftMeeting',

	// twilio
	'AddParticipant',
	'RemoveParticipant',
	'SetRoom',
	'RemoveAllParticipants',
	'SetLocalScreenshareTrack',
	'SetParticipantScreenshareTrack',
	'StopLocalShareScreen',
	'SetLocalVideoTrack',
	'SetLocalAudioTrack',
	'StopLocalVideoTrack',
	'StopLocalAudioTrack',
	'StopTranscriptionTrack',
	'SetTranscriptionTrack',
	'EndRoom',
	'SaveMeetingPariticipants',
	'MuteUser',
	'ToggleRecording',

	'CallStatusChange'
])

export interface IVariableCallingStoreState {
	connectedUserIds: string[]
	participants: InstanceType<typeof BaseProviderInstance>['participants']
	status: CallingInstanceState

	sfu_token: string
	peerDecibals: { [id: string]: number }
	startTime: number | string
	recording: boolean
	userSpokenInTimeslice: boolean
	current_speaker: null | 'local' | string
	current_presenter: null | 'local' | string
	session: typeof BaseProviderInstance | null
	callObject: any,
	voiceAudio: any,
	transcrptionAudio: any,
	call_starter: any
	host: any
	usersAsking: any[]
	meetingLocked: boolean
	participantTracks: any[]
	room: any
	localScreenshareTrack: any
	remoteScreenshareTrack: any
	hostManuallyTransferred: boolean
	session_id: null | string
	localVideoTrack: any
	localAudioTrack: any
	transcriptionTrack: any
	currentParticipants: any
}

export const extractLocalDecibals = (
	decibalsObj: IVariableCallingStoreState['peerDecibals']
) => {
	const localId = GlobalState.auth.jwt.data._id
	return decibalsObj[localId] || 0
}

export class VariableCallingStore extends Store {
	state: IVariableCallingStoreState
	dailyco: any
	constructor() {
		super()
		this.checkUnload = this.checkUnload.bind(this)
		this.state = {
			connectedUserIds: [],
			status: CallingInstanceState.UninitializedWithoutToken,
			participants: [],
			session: null,
			sfu_token: '',
			peerDecibals: {},
			startTime: Date.now(),
			recording: false,
			current_speaker: null,
			callObject: null,
			current_presenter: null,
			userSpokenInTimeslice: false, // Used for backend transcription,
			call_starter: null,
			usersAsking: [],
			meetingLocked: false, 
			voiceAudio: null,
			transcrptionAudio: null,
			participantTracks: [],
			localScreenshareTrack: null,
			remoteScreenshareTrack: null,
            room: null,
			host: null,
			hostManuallyTransferred: false,
			session_id: null,
			localVideoTrack: null,
			localAudioTrack: null,
			transcriptionTrack: null,
			currentParticipants: [],
		}
		this.listenables = [VariableCallingActions]
		this.dailyco = window.DailyIframe;

		window.addEventListener('beforeunload', this.checkUnload)
		window.addEventListener('unload', () => {
			this.state.room?.disconnect()
		})
	}

	instance: BaseProviderInstance | null = null

	access_token: string | null = null

	checkUnload(ev: BeforeUnloadEvent) {
		ev.preventDefault()

		if (
			this.state.status === CallingInstanceState.Connected &&
			!GlobalState.main.bot_mode
		) {
			return (ev.returnValue = `You're in an ongoing call 📞`)
		}
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.checkUnload)
	}

	/**
	 * API sends us a key unique to the meeting. Key is meant to be unguessable
	 */
	private async getAccessToken() {

		if (this.provider.includes('TWILIO')) {
			// serverless function

			// @ts-ignore
			const identity = `${GlobalState.main.db_meeting.name}::${GlobalState.auth.jwt.data._id}::${window.socket.id}`

			const response = await axios.get(
				`${process.env.REACT_APP_TWILIO_ACCESS_TOKEN_ENDPOINT || 'https://grapl-twilio-2351-dev.twil.io'}/token-service?identity=${identity}`
			);
		
			const data = response.data;
		
			if (data.accessToken) {
				this.access_token = data.accessToken
			}

		} else {
			const { provider_token } = await LoopApi(
				null,
				'GenerateCallingProviderToken',
				{
					provider: this.provider,
				}
			)
			this.access_token = provider_token
		}
		
	}

	/////////////////////////////////////////////////////
	/* Calling Actions: Used by other parts of the app */
	/////////////////////////////////////////////////////
	private _log(message: string, payload?: any) {
		colorLog(message, CONSOLE_TAG, {
			payload,
			instance: {
				state: this.state,
				provider: this.provider,
			},
		})
	}

	get provider() {
		return (GlobalState.main?.db_meeting?.settings?.calling_provider ||
			'webrtc') as CallingProviderName
	}

	get meeting_name() {
		return GlobalState.main?.db_meeting?.name as string
	}

	private async initialize() {
		try {
			this.setState({ status: CallingInstanceState.Initializing })
			await this.getAccessToken()
			this.setState({ status: CallingInstanceState.ReadyToJoin })
		} catch (e) {
			toast(`Error: ${e.message}`)
			this.setState({ status: CallingInstanceState.UninitializedWithoutToken })
		}
	}

	private async reset() {
		this.setState({ status: CallingInstanceState.UninitializedWithoutToken })
		try {
			await this.instance?.teardown()
		} catch (e) {
			console.log(e)
		}
		this.instance = null
	}

    public async onEndRoom() {
        try {
            // auto saving transcriptions
            const { transcription, db_meeting } = GlobalState.main;
            // const { creator_user_id } = db_meeting;
            // const { _id } = GlobalState.auth.jwt.data;

            
            // if (_id === creator_user_id) {
            //     //console.log('saving transcriptions....', this.state)
            await LoopApi(null, 'SaveTranscriptions', { transcriptions: transcription, session_id: this.state.session_id })
            const { getTranscriptions } = GlobalState.main.widgets?.transcription;
            MainActions.TranscriptionSaved()
            if(getTranscriptions) {
                getTranscriptions();
            }
                
            // }

        } catch (error) {
            console.error(error.message)
        }
    }

	public async onLeave() {
		CallTranscriber.stopRecognition()

		if (in_call_states.includes(this.state.status)) {
			// //console.log("connectedUserIds: ",this.state.connectedUserIds)

			this._log('Received leave call status')
			// //console.log(GlobalState)
			return this.reset()
		}
	}

	public async onJoin(
		constraints: MediaStreamConstraints
	): Promise<boolean | null> {
		try {
			if (
				loading_states.includes(this.state.status) ||
				in_call_states.includes(this.state.status)
			) {
				this._log('Returning from join attempt')
				return null
			}

			if(GlobalState.main?.db_meeting?.settings?.calling_provider === CallingProviderName.DailyCo) {
				if(!this.dailyco?.supportedBrowser()?.supported) {
					this._log("Browser is not supported")
					useToastify({
						type: 'error',
						message: 'Your browser does not support daily.co. Please refresh the page to retry.'
					})
					
					return null
				}
			}

			this._log(`Starting Join`)

			if (failed_states.includes(this.state.status)) {
				this._log('In failed state, resetting before joining')
				this.reset()
			}

			if (
				!this.access_token ||
				this.state.status === CallingInstanceState.UninitializedWithoutToken
			) {
				this._log('Initializing')
				await this.initialize()
			}

			if (this.state.status !== CallingInstanceState.ReadyToJoin) {
				this._log('Not in ready to join state - not good... returning')
				return null
			}

			this.setState({ status: CallingInstanceState.Joining })
			this._log('Creating instance for ' + this.provider)

			if (this.provider.includes('TWILIO')) {
				const [provider, soundQuality] = this.provider.split('::')
				this.instance = await callingProviderLoadableClasses[this.provider](
					this.access_token!,
					GlobalState.auth.jwt.data._id,
					soundQuality,
				)
			} else {
				this.instance = await callingProviderLoadableClasses[this.provider](
					this.access_token!,
					GlobalState.auth.jwt.data._id
				)
			}

			this.instance!.on('participantChange', () => {
				let otherProps: any = {}
				if (this.provider === 'DAILYCO') {
					const sortedParticipant = (this.instance?.sortedParticipants || []).slice().sort((a: any, b: any) => sortDate(a.joined_at, b.joined_at))
					const hostInMeeting = sortedParticipant.filter((p: any) => (p?.local ? GlobalState.auth.jwt.data._id : p?.user_name) === this.state.host)
					if(this.state.hostManuallyTransferred && !!!hostInMeeting.length) {
						otherProps.hostManuallyTransferred = false
						otherProps.host = sortedParticipant?.[0]?.user_name || GlobalState.auth.jwt.data._id
						otherProps.meetingLocked = this.state.meetingLocked
					} else if(this.state.hostManuallyTransferred && hostInMeeting.length) {
						otherProps.host = this.state.host
						otherProps.meetingLocked = this.state.meetingLocked
					} else {
						otherProps.host = sortedParticipant?.[0]?.user_name || GlobalState.auth.jwt.data._id
						otherProps.meetingLocked = this.state.meetingLocked
					}
					

				}

				this.setState({ participants: this.instance?.participants || [], ...otherProps })
			})

			await this.instance!.connect(this.meeting_name, constraints)
			this.setState({ 
				status: CallingInstanceState.Connected, 
				callObject: this.instance?.callObj || [],
				startTime: this.instance?.startTime 
			})

			const usersInCallKey = this.state.connectedUserIds.length === undefined ? (Object.keys(this.state.connectedUserIds) || []).filter((key) => !isNaN(parseInt(key))) : this.state.connectedUserIds
			// const usersInCall = usersInCallKey.map((u: any) => this.state.connectedUserIds?.[u])

            
            
			if(usersInCallKey?.length === 0) {
				try {

                    if(this.provider === 'DAILYCO') {

                        const { meetingSession } = await this.instance.callObj.getMeetingSession()
                    
                        const resp = await LoopApi(null, 'NotifyStartMeeting', { userId: GlobalState.auth.jwt.data._id, meetingSession: meetingSession.id }, [
                            ['name', GlobalState?.main?.meetingName]
                        ])

                        if (resp.status === 'failed') {
                            console.error("failed showing start meeting notification")
                        }
                    }
		

				} catch (e) {
					console.error(e)
				}
			} else {
				try {

                    if(this.provider === 'DAILYCO') {

                        const resp = await LoopApi(null, 'NotifyJoinMeeting', { userId: GlobalState.auth.jwt.data._id }, [
                            ['name', GlobalState?.main?.meetingName]
                        ])

                        if (resp.status === 'failed') {
                            console.error("failed showing join meeting notification")
                        }
                    }
		

				} catch (e) {
					console.error(e)
				}
			}

			return true
		} catch (e) {
			toast(`Error: ${e.statusText || e.msg || e.message}`)
			this.setState({ status: CallingInstanceState.JoiningFailed })
			this.instance?.teardown()
			return false
		}
	}

	onSetConnectedUserIds(ids: string[], ...rest: any) {
		// debugger
		if (!this.state.call_starter || (this.state.call_starter && !(Object.values(ids) || [])?.includes(this.state.call_starter))) {
			// debugger
			this.setState({ call_starter: ids[0] })
		}
		this.setState({ connectedUserIds: ids })
	}

	onNotifyStartMeeting({ id  }: { id: string }) {
		const user = GlobalState?.main?.users.find((u: any) => u.id === id)
		const { username, avatar_url, color, name } = user
		useToastify({
			message: () => StartMeeting({ username: name || username, avatar_url, color }),
			position: "top-right",
			autoClose: 1500,
			closeButton: false,
			hideProgressBar: true,
			transition: Slide,
			toastId: 'startMeetingNotif',
			className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
			bodyClassName: "grow-font-size",
			progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'

		})
	}

	onNotifyJoinMeeting({ id, isDaily }: { id: string, isDaily?: boolean }) {
		const user = GlobalState?.main?.users.find((u: any) => u.id === id)
		if(!user) return 
		const { avatar_url, color } = user
		if(!isDaily) {
			toast.dismiss('joinMeetingNotif');
		}
		useToastify({
			message: () => StartMeeting({ username: user?.name || user?.username, avatar_url, color, action: 'joined' }),
			position: "top-right",
			autoClose: 1500,
			closeButton: false,
			hideProgressBar: true,
			transition: Slide,
			toastId: 'joinMeetingNotif',
			className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
			bodyClassName: "grow-font-size",
			progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'

		})
	}


	onNotifyLeftMeeting({ id, isDaily }: { id: string, isDaily?: boolean }) {
		const user = GlobalState?.main?.users.find((u: any) => u.id === id)
		if(!user) return 
		const { avatar_url, color } = user
		if(!isDaily) {
			toast.dismiss('leftMeetingNotif');
		}
		useToastify({
			message: () => StartMeeting({ username: user?.name || user?.username, avatar_url, color, action: 'left' }),
			position: "top-right",
			autoClose: 1500,
			closeButton: false,
			hideProgressBar: true,
			transition: Slide,
			toastId: 'leftMeetingNotif',
			className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
			bodyClassName: "grow-font-size",
			progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'

		})
	}

	// transfer host access
	async onSetCallStarter({ user, _id: prevHost }: any) {
		const { id, _id } = user
		this.setState({ 
			call_starter: id || _id, 
			host: id || _id, 
			usersAsking: [], 
			hostManuallyTransferred: true 
		})
		await LoopApi(null, 'UpdateRecordingHost', { 
			session_id: this.state.session_id,  
			host: id || _id,
		}, [])

		toast.dismiss('transferHostNotif')
		useToastify({
			message: () => HostTransferred({ user, isPrevHost: prevHost ===  GlobalState.auth.jwt.data._id, isCurrentHost: (id || _id) === GlobalState.auth.jwt.data._id }),
			position: "top-right",
			autoClose: 1500,
			closeButton: false,
			toastId: 'transferHostNotif',
			className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
			bodyClassName: "grow-font-size",
			progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'

		})
	}

	// deny host access
	onDenyHostAccess(id: string, denied?: boolean) {
		const ind = this.state.usersAsking.findIndex(u => u._id === id)
		if (ind === -1) {
			return
		}

		const usersAsking = this.state.usersAsking.filter(u => u._id !== id)
		if(denied) {
			Sagas.Clients.Emit('main', 'message', { action: 'DenyHostRequest', identity: id, meetingName: GlobalState?.main?.meetingName })
		}
		this.setState({ usersAsking })
	}

	// request host access
	onRequestHostAccess({ user, meetingName }: any) {
		if (!this.state.usersAsking.find(u => u._id === user._id)) {
			useToastify({
				message: () => RequestHostAccess({ user, meetingName }),
				position: "top-center",
				autoClose: 10000,
				closeButton: false,
				onClose: () => VariableCallingActions.DenyHostAccess(user._id),
				toastId: `${user?.id}askAccessNotif`,
				className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
				progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
	
			})
			this.setState({
				usersAsking: [...this.state.usersAsking, user],
			})
		}
	}

	// toggle meeting restriction
	onToggleLockMeeting({ _id, username }: any) {
		if(_id !== (this.state.host || this.state.call_starter)) return

		if(GlobalState.auth.jwt.data._id !== _id) {
			toast.dismiss('lockMeetingNotif')
			useToastify({
				message: () => LockMeeting({ username, locked: !this.state.meetingLocked }),
				position: "top-right",
				autoClose: 1500,
				closeButton: false,
				hideProgressBar: true,
				transition: Slide,
				toastId: 'lockMeetingNotif',
				className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
				progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
	
			})
		}

		this.setState({ meetingLocked: !this.state.meetingLocked })
	}

	// toggle meeting restriction
	onMeetingRecording({ _id }: any) {
		if(GlobalState.auth.jwt.data._id !== _id && this.state.status === CallingInstanceState.Connected) {
			toast.dismiss('meetingRecordingNotif')
			useToastify({
				message: () => RecordingNotif(),
				position: "top-center",
				autoClose: false,
				closeButton: false,
				hideProgressBar: true,
				transition: Slide,
				toastId: 'meetingRecordingNotif',
				className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
				progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
			})
		}
	}

	onDenyHostRequest({ _id }: any) {
		if(GlobalState.auth.jwt.data._id === _id && this.state.status === CallingInstanceState.Connected) {
			toast.dismiss('denyHostRequestNotif')
			useToastify({
				message: () => HostTransferred({ denied: true }),
				position: "top-center",
				autoClose: 1500,
				closeButton: false,
				hideProgressBar: true,
				transition: Slide,
				toastId: 'denyHostRequestNotif',
				className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
				progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
			})
		}
	}

	//new screenshare
	async onScreenshare(open: boolean) {
		try {
			await this.instance!.toggleScreenshare(open)
		} catch (e) {
			toast(`Error: ${e.statusText || e.msg || e.message}`)
			return false
		}
	}

	onSetActivePresenter(current_presenter: string) {
		this.setState({
			current_presenter,
		})
	}

	// Producer toggling
	onMuteMic(should_mute: boolean) {
		Sagas.getSFUClient().toggleMuteMic(should_mute)
	}

	onToggleVideo(should_toggle_video: boolean) {
		Sagas.getSFUClient().toggleWebcam(should_toggle_video)
	}

	onToggleScreenshare(constraints: MediaStreamConstraints) {
		Sagas.getSFUClient().toggleScreenshare(constraints)
	}

	// Device selectors
	onSelectAudioInput(target: never) {
		// //console.log(`Didn't handle SelectAudioInput for ${target}`)
	}

	onSelectVideoInput(target: never) {
		// //console.log(`Didn't handle SelectVideoInput for ${target}`)
	}

	/////////////////////////////////////////////////////
	// SFU Actions: Used exclusively by our SFU system //
	/////////////////////////////////////////////////////
	// Decibal changes
	onPeerVolumeChange(peername: string, decibals: number) {
		const peerDecibals = { ...this.state.peerDecibals, [peername]: decibals }

		this.setState({
			peerDecibals,
		})
	}
	onSetActiveSpeaker(current_speaker: string) {
		this.setState({
			current_speaker,
		})
	}

	// SESSION
	onSaveMeetingSession({ session_id }: { session_id: string}) {
		this.setState({ session_id })
	}

	onSetHost({ host }: {host: string}) {
		this.setState({ host })
	}

	onSetLock({ meetingLocked }: {meetingLocked: boolean}) {
		this.setState({ meetingLocked })
	}

	// participants twilio
	onAddParticipant(participant: any) {

		let participantTracks = [...this.state.participantTracks]
		let foundIndex = participantTracks.findIndex((p: any) => p.identity === participant.identity)

		if (foundIndex >= 0) {
			return
		}
		participantTracks.push(participant)
		
		this.setState({ participantTracks })
	}

	onRemoveParticipant(participant: any) {

		let participantTracks = [...this.state.participantTracks]
		participantTracks = participantTracks.filter((p: any) => p.identity !== participant.identity)

		this.setState({ participantTracks })

		// check if screensharing
		const userId = participant.identity.split('::')[1]
		if (userId === this.state.current_presenter) {
			this.setState({ current_presenter: null, remoteScreenshareTrack: null })
		}
	}

	onSaveMeetingPariticipants({ currentParticipants }: any) {
		this.setState({ currentParticipants })
	}

	onRemoveAllParticipants(participant: any) {
		this.setState({ participantTracks: [] })
	}

	onSetRoom(room: any) {
		this.setState({ room })
	}

	onSetLocalScreenshareTrack(track: any) {
		this.setState({ localScreenshareTrack: track })
	}

	onSetParticipantScreenshareTrack(track: any) {
		this.setState({ remoteScreenshareTrack: track })
	}

	onSetLocalVideoTrack(localVideoTrack: any) {
		this.setState({ localVideoTrack })
	}

	onSetLocalAudioTrack(localAudioTrack: any) {
		this.setState({ localAudioTrack })
	}

	onSetTranscriptionTrack(transcriptionTrack: any) {
		this.setState({ transcriptionTrack })
	}

	onStopLocalVideoTrack() {
		if (this.state.localVideoTrack) {
			this.state.localVideoTrack.stop()
		}
	}

	onStopLocalAudioTrack() {
		if (this.state.localAudioTrack) {
			this.state.localAudioTrack.stop()
		}
	}

	onStopTranscriptionTrack() {
		if (this.state.transcriptionTrack) {
			this.state.transcriptionTrack.audioTrack.stop()
			this.state.transcriptionTrack.mediaStreamTrack.stop()
		}
	}

	onStopLocalShareScreen() {
		this.state.localScreenshareTrack.stop()
		this.setState({ localScreenshareTrack: null })
		this.instance!.toggleScreenshare(false)

		// //console.log('onStopLocalShareScreen', this.state.localScreenshareTrack)
	}

	onMuteUser(data: any) {
		if(data?.sender?.id === GlobalState.auth.jwt.data._id) {
			toast.dismiss('mutedUserParticipant')
			useToastify({
			  message: () => MuteParticipant({ isHost: true, user: data.user }),
			  position: "top-right",
			  autoClose: 1500,
			  closeButton: false,
			  hideProgressBar: true,
			  transition: Slide,
			  toastId: 'mutedUserParticipant',
			  className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
			  bodyClassName: "grow-font-size",
			  progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
		
			})
		}
		const event = new CustomEvent(`MUTE_USER`, { detail: { identity: data.identity } })
		window.dispatchEvent(event)
	}

	onToggleRecording(data: any) {
		const getUser = (id: string) => (data?.users || []).find((u: any) => u.id === id)
		const user = getUser(this.state.host)
		const isInCall = this.state.status === CallingInstanceState.Connected
		if((user?.id || data?.user?.id) !== GlobalState.auth.jwt.data._id) {
			const event = new CustomEvent(`TOGGLE_RECORDING`, { detail: { onoff: data.onoff } })
			window.dispatchEvent(event)
		}

		if(!isInCall) return 

		toast.dismiss('toggleRecording')
		useToastify({
			message: () => ToggleRecording({ isHost: (user?.id || data?.user?.id) === GlobalState.auth.jwt.data._id, user: user || data?.user, onoff: data.onoff, }),
			position: "top-right",
			autoClose: 1500,
			closeButton: false,
			hideProgressBar: true,
			transition: Slide,
			toastId: 'toggleRecording',
			className: GlobalState?.theming?.color_scheme === 'Light' ? 'toastL' : 'toastD',
			bodyClassName: "grow-font-size",
			progressClassName: GlobalState?.theming?.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'
	
		})

		
	}

	onCallStatusChange(status: CallingInstanceState) {
		this.setState({ status })
	}

}

const OtherJoinSound = require('../assets/OtherJoin.mp3')
const OtherLeaveSound = require('../assets/OtherLeave.mp3')

const playSound = (name: string) => {
	let s
	switch (name) {
		case 'OtherJoin':
			s = OtherJoinSound
			break
		case 'OtherLeave':
			s = OtherLeaveSound
			break
		default:
			return
	}
	try {
		new Audio(s).play()
	} catch (_) { }
}

	; (VariableCallingStore as any).id = 'calling'
