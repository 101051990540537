import React, {useCallback, useRef, useEffect} from 'react'
import useDevices from '../../hooks/useDevices'
import {FiMicOff as MicOff} from '@react-icons/all-files/fi/FiMicOff'
import {FiMic as MicOn} from '@react-icons/all-files/fi/FiMic'

import useLocalAudioToggle from '../../hooks/useLocalAudioToggle'
import CustomTip from '../../../../../../helpers/CustomTip'
import Tip from 'react-tooltip'
import useToastify from '../../../../../../helpers/useToastify'
import {Slide, toast} from 'react-toastify'
import MuteParticipant from '../../../../../../components/Notifications/MuteParticipant'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { Room } from 'twilio-video'
const {GlobalState} = require('reflux')

export default function ToggleAudioButton(props: {
  disabled?: boolean
  className?: string
  isMobile?: boolean
}) {
  const {room} = useMeetingContext()

  const [
    isAudioEnabled,
    toggleAudioEnabled,
  ] = useLocalAudioToggle()
  const lastClickTimeRef = useRef(0)

  const {hasAudioInputDevices} = useDevices()

  const handleMuteUser = useCallback(
    (e: any) => {
      const {detail} = e

      if (room !== null) {
        if ((room as Room).localParticipant.identity === detail.identity) {
          toggleAudioEnabled()
          toast.dismiss('muteUserParticipant')
          useToastify({
            message: () => MuteParticipant({}),
            position: 'top-right',
            autoClose: 1500,
            closeButton: false,
            hideProgressBar: true,
            transition: Slide,
            toastId: 'muteUserParticipant',
            className:
              GlobalState?.theming?.color_scheme === 'Light'
                ? 'toastL'
                : 'toastD',
            bodyClassName: 'grow-font-size',
            progressClassName:
              GlobalState?.theming?.color_scheme === 'Light'
                ? 'toastProgressL'
                : 'toastProgressD',
          })
        }
      }
    },
    [isAudioEnabled],
  )

  useEffect(() => {
    Tip.rebuild()
  }, [isAudioEnabled])

  useEffect(() => {
    window.addEventListener('MUTE_USER', handleMuteUser)

    return () => {
      window.removeEventListener('MUTE_USER', handleMuteUser)
    }
  }, [isAudioEnabled])

  const toggleAudio = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleAudioEnabled()
    }
  }, [toggleAudioEnabled])

  return (
    <>
      <button
        className={`button ${isAudioEnabled ? 'default' : 'danger'} controlbutton`}
        onClick={toggleAudio}
        disabled={!hasAudioInputDevices || props.disabled}
        data-tip
        data-for="mic"
      >

        {!hasAudioInputDevices ? <MicOff /> : isAudioEnabled ? <MicOn /> : <MicOff />}
      </button>
      {!!!props.isMobile && (
        <CustomTip
          top={-20}
          left={10}
          class="top-left"
          place="right"
          tip={`${isAudioEnabled ? 'Mute' : 'Unmute'}`}
          dataFor="mic"
        />
      )}
    </>
  )
}
