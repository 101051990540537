import styled from "styled-components"
import React, {Fragment} from 'react'
import { FiBell } from '@react-icons/all-files/fi/FiBell'
import { toast } from "react-toastify";

import { MainActions } from "../../stores/MainStore";
import LoopApi from '../../helpers/LoopApi'

const { GlobalState } = require('reflux')

function UserKnocking ({ user, meetingName }: any) {
    //console.log("USER: ", user)
    const _Allow = () => {
		MainActions.AllowKnockingUser(user._id)
		LoopApi(
			null,
			'AddUserToMeeting',
			{ user_id: user._id },
			[['name', meetingName]]
		);
	    toast.dismiss(`${user._id}knocking`);
	}

    const _Deny = () => {
        MainActions.IgnoreKnockingUser(user._id)
	    toast.dismiss(`${user._id}knocking`);
    }

    return  <Fragment>
        <HostPermission>
            <div className="icon color-primary bg-primary-opaque"><NotificationWrapper className="color-primary"><FiBell /></NotificationWrapper></div>
            <HostPermissionContent>
                <HostPermissionMessage>
                    <div>
                        <span className="name color-primary">{user?.username || user?.name}&nbsp;</span> is knocking
                    </div>
                </HostPermissionMessage>
                <HostPermissionButtons>
                    <HostButton className="left bg-default" onClick={() => _Deny()}>Deny</HostButton>
                    <HostButton className="right bg-primary" onClick={() => _Allow()}>Accept</HostButton>
                </HostPermissionButtons>
            </HostPermissionContent>
        </HostPermission>
    </Fragment>
}

export default UserKnocking;


const NotificationWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0px !important;
    cursor: pointer;

    transform: rotate(-45deg);
    animation: shake 1.5s infinite ease-in-out;
    @keyframes shake {
        0% { transform:rotate(-0deg) scale(1) skew(1deg); }
        10% { transform:rotate(-15deg) scale(1) skew(1deg); }
        20% { transform:rotate(15deg) scale(1) skew(1deg); }
        30% { transform:rotate(-15deg) scale(1) skew(1deg); }
        40% { transform:rotate(15deg) scale(1) skew(1deg); }
        50% { transform:rotate(-0deg) scale(1) skew(1deg); }
        100% { transform:rotate(-0deg) scale(1) skew(1deg); }
    }
`

export const HostButton = styled.div`
	padding: 10px 15px;
    background: red;
    border-radius: 30px;
    color: white;
	flex: 1;
	text-align: center;
    /* flex: 1;
    margin: 0 7.5px 0 15px; */
`
export const HostPermissionContent = styled.div`
	display: flex;    
	justify-content: center;
	flex-direction: column;
    flex: 1;
`

export const HostPermissionMessage = styled.div`
	min-height: 30px;
    display: flex;
    align-items: center;

    .name {
        word-break: break-all;
    }
`

export const HostPermissionButtons = styled.div`
	display: flex;
	justify-content: space-between;
    width: 100%;
	margin-top: 5px;

	.left {
		margin-right: 5px;
	}

	.right {
		margin-left: 5px;
	}

	.name {
		font-weight: 500;
	}
`

export const HostPermission = styled.div`
	display: flex;
	font-weight: 400;

	.icon {
		width: 30px;
		height: 30px;
		padding: 5px;
		border-radius: 30px;
		margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
	}

`