import React, { Component } from 'react'
import styled from 'styled-components'
import TranscriptionItem from './TranscriptionItem'
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'
import { BiMenuAltLeft as SideMenuIcon } from '@react-icons/all-files/bi/BiMenuAltLeft'
import { BsThreeDotsVertical as MoreOptionIcon } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import { IWidgetProps, AllWidgets } from '../types'
import moment from 'moment'
import {
      Button, ContentWrapper, DropdownOption, MenuWrapper, MicrophoneWrapper, NotePreview, NotesWrapper, NoteToolbar, NoteToolbarBlock,
      Scroll, Scrollable, Select, SideMenuWrapper, ToolbarButton, Overlay
  } from './StyledComponents'
import { FaDownload } from '@react-icons/all-files/fa/FaDownload';
import { WidgetContentItem } from '../_Shared/Elements'
import Dropdown from '../_Shared/DropdownWrapper'
import LoopApi from '../../helpers/LoopApi'
import { MainActions, WidgetActions } from '../../stores/MainStore'
import { ModalActions } from '../../stores/ModalStore'
import { FaEye } from '@react-icons/all-files/fa/FaEye'
import { ImPencil } from 'react-icons/im'
import { BsTrash } from 'react-icons/bs'


const { GlobalState } = require('reflux')

declare const window: any;

type Props = IWidgetProps<AllWidgets.Transcription>

type state = {
      transcriptions: any,
      collapsed: boolean,
      color_scheme: string
      live_transcript?: any
      live_speaker?: any
      saved: any
      transcribe: boolean
      scrolling: boolean
      data: {
        view: any
        transcriptions: any[]
        isHighlightsShown: boolean
        stashed_view: any
        searched: any
      }
}

export default class TranscriptionWidget extends Component<Props, state> {
      messagesEnd: any
      transcriptions: any
      width: any
      interval: any

      constructor(props: Props) {
            super(props)

            this.messagesEnd = null
            this.scrollToBottom = this.scrollToBottom.bind(this)
            transcription: []
            this.handleSearch = this.handleSearch.bind(this);
            this.toggleCollapseSidebar = this.toggleCollapseSidebar.bind(this)
            this.downloadTranscription = this.downloadTranscription.bind(this)
            this.saveTranscriptions = this.saveTranscriptions.bind(this)
            this.showTranscription = this.showTranscription.bind(this)
            this.getTranscriptions = this.getTranscriptions.bind(this)
            this.removeTranscription = this.removeTranscription.bind(this)
            this.showHighlights = this.showHighlights.bind(this)
            this.hideHighlights = this.hideHighlights.bind(this)
            this.highlight = this.highlight.bind(this)
            this.removeHighlight = this.removeHighlight.bind(this)

            this.width = window.innerWidth;
            this.state = {
                  transcriptions: [],
                  collapsed: false,
                  color_scheme: 'Light',
                  live_transcript: null,
                  live_speaker: null,
                  transcribe: false,
                  saved: [],
                  scrolling: false,
                  data: {
                        searched: null,
                        view: null,
                        transcriptions: [],
                        isHighlightsShown: false,
                        stashed_view: null,
                    }
            }
      }


      handleSearch(e: string) {
            const transcriptions = (this.state.saved || []).filter((f: any) => (f.title?.toLowerCase() || moment(f.created_at).format('lll')).toLowerCase().includes(e.toLowerCase()))
            // this.props.actions.UpdateSelf({ searched: transcriptions})
            this.setState({
                data: {
                    ...this.state.data,
                    searched: !!e ? transcriptions : null
                }
            })
      }

      scrollToBottom() {
        window.gScrollHeight = this.messagesEnd
            this.messagesEnd &&
                  (this.messagesEnd.scrollTo
                        ? this.messagesEnd.scrollTo(0, this.messagesEnd.scrollHeight)
                        : (this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight))
      }

       componentDidMount() {
            const { transcribe } = GlobalState.main.db_meeting.settings;
            this.setState({ transcribe })

            document.addEventListener('transcribe', ({ detail }:any) => {
                this.setState({ live_transcript: detail.transcript, live_speaker: detail.speaker })
                if(!this.state.scrolling) {
                    this.scrollToBottom()
                }
            })  

            document.addEventListener('change-settings', ({ detail }: any) => {
                const { transcribe } = detail.state.settings
                this.setState({ transcribe })
            })
            
            if (this.width <= 1100) {
                this.setState({ collapsed: false })
                
            }
            
            this.scrollToBottom()
            this.getTranscriptions()
      }

      componentDidUpdate() {
        if(!this.state.scrolling) {
            this.scrollToBottom()
        }
      }

      UNSAFE_componentWillReceiveProps(newProps: any) {
          console.log(newProps)
            this.setState({ transcriptions: newProps.transcription })
            
      }

      handleWindowResize() {
            this.width = window.innerWidth
            if (!this.state.collapsed) this.setState({ collapsed: true })
        }

      getDate() {
            let date = new Date();
            let month = date.toLocaleString('default', { month: 'long' });
            let day = date.getDate();
            let year = date.getFullYear();

            return `${month} ${day}, ${year}`;
      }

      toggleCollapseSidebar() {
            this.setState((prevState: state) => ({ ...prevState, collapsed: !prevState.collapsed }))
      }

      toggleOnMobile() {
            if(this.width <= 1100)
            this.toggleCollapseSidebar()
      }

      downloadTranscription() {
            const transcriptions = this.state.data.view?.transcriptions ?? this.props.data.transcriptions;
            let raw_transcriptions = "";
            try {
                  transcriptions.forEach((t:any) => {
                        raw_transcriptions += `[${moment(t?.timestamp).format('hh:mm:ss')}]${((this.props.users || []).find((u: any) => u.id === t.speaker))?.name}: ${t.text}\n`;
                  });
            } catch (e) {}


            const element = document.createElement("a");
            const file = new Blob([raw_transcriptions], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = `Transcription_${this.props.meetingName}_${moment().format('MMDDYYYY_HHMMSS')}.txt`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
      }

      async saveTranscriptions() {
            const { transcriptions } = this.props.data;
            await LoopApi(null, 'SaveTranscriptions', { transcriptions })
            this.getTranscriptions()
      }

      showTranscription(view: any) {
            const { UpdateSelf } = this.props.actions;
            UpdateSelf({ view: [] })
            this.setState({
                data: {
                    ...this.state.data,
                    isHighlightsShown: false,
                    stashed_view: null,
                    view
                }
            })
      }

      async getTranscriptions() {
            const saved = await LoopApi(null, 'GetTranscriptions')
            this.props.actions.UpdateSelf({ saved: [] })
            this.setState({ saved })
            if(this.state.data.view?._id) {
                const newView = saved.filter((s:any) => s._id === this.state.data.view._id)
                if(this.state.data.view) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            view: newView[0]
                        }
                    })
                }
            }
      }

    async removeTranscription(view: any) {
        ModalActions.SetModalWithParams('RemoveTranscription', { view: this.state.data.view, getTranscriptions: this.getTranscriptions })
    }

    showHighlights() {
        const stashed_view = this.state.data.view
        const transcriptions = this.state.data.view.transcriptions.map((t:any) => t.stared === true ? t : null).filter((t:any) => t !== null)
        this.setState({
            data: {
                ...this.state.data,
                isHighlightsShown: true,
                view: {
                    ...this.state.data.view,
                    transcriptions
                },
                stashed_view
            }
        })
    }

    hideHighlights() {
        this.setState({
            data: {
                ...this.state.data,
                view: this.state.data.stashed_view,
                stashed_view: null,
                isHighlightsShown: false,
            }
         })
    }

    highlight = async (props:any) => {
        LoopApi('main', 'AudioTranscription', [])
        await LoopApi(null, 'HighlightTranscription', { transcription: props.rec });
        MainActions.StaredTranscription({ record: props.rec }) 

        this.setState({ data: {
            ...this.state.data,
            view: {...this.state.data.view, transcriptions: this.state.data.view.transcriptions.map((t:any) => props.rec.t_id === t.t_id ? ({ ...t, stared: true}): t)}
        }})
    };

    removeHighlight = async (props:any) => {
        await LoopApi(null, 'RemoveHighlightTranscription', { transcription: props.rec });
        MainActions.RemoveStarTranscription({record: props.rec})
        if(this.state.data.view) this.getTranscriptions()
    };

      render() {
            const { collapsed, color_scheme = 'Light' } = this.state;
            const color = color_scheme === 'Light' ? '#F89809' : '#008BFF'
            const { view } = this.state.data;

            const { transcribe } = GlobalState.main.db_meeting.settings;

            const DropdownContent = (props: any) => {
                  return (
                      <React.Fragment>

                      {!this.state.data.view ?
                        <WidgetContentItem padding={10} onClick={(e: any) => this.saveTranscriptions()}>
                                <DropdownOption>
                                        <FaDownload size={12} /><span className="icon-label">Save</span>
                                </DropdownOption>
                        </WidgetContentItem> : null}

                        {!this.state.data.isHighlightsShown ?
                            <WidgetContentItem padding={10} onClick={(e: any) => this.showHighlights()}>
                                <DropdownOption>
                                        <FaEye size={12} /><span className="icon-label">Show Highlights Only</span>
                                </DropdownOption>
                            </WidgetContentItem> :
                            <WidgetContentItem padding={10} onClick={(e: any) => this.hideHighlights()}>
                                <DropdownOption>
                                        <FaEye size={12} /><span className="icon-label">Show All Transcriptions</span>
                                </DropdownOption>
                            </WidgetContentItem>
                        }

                        <WidgetContentItem padding={10} onClick={(e: any) => this.downloadTranscription()}>
                              <DropdownOption>
                                    <FaDownload size={12} /><span className="icon-label">Download</span>
                              </DropdownOption>
                        </WidgetContentItem>

                      <WidgetContentItem padding={10} onClick={(e: any) => ModalActions.SetModalWithParams('RenameTranscription', { getTranscriptions: this.getTranscriptions, view: this.state.data.view })}>
                              <DropdownOption>
                                <ImPencil size={12} /><span className="icon-label">Rename</span>
                            </DropdownOption>
                      </WidgetContentItem>
                      <WidgetContentItem padding={10} onClick={(e: any) => this.removeTranscription(this.state.data.view)}>
                              <DropdownOption>
                              <BsTrash size={12} /> <span className="icon-label">Remove</span>
                            </DropdownOption>
                      </WidgetContentItem>
                       {/* </>: null} */}
                      </React.Fragment>
                  )
              }

            const mapTrans = (this.state.data.view?.transcriptions ?? this.state.transcriptions ?? [])
                  .map((t: any, ii: number) => (
                        <TranscriptionItem
                            {...this.props}
                              key={ii}
                              rec={{
                                    owner: (this.props.userId === t.speaker ? true : false),
                                    user: (this.props.users || []).find((u: any) => u.id === t.speaker),
                                    timestamp: t.timestamp,
                                    text: t.text,
                                    stared: t.stared,
                                    id: this.state.data.view?._id,
                                    t_id: t.t_id
                              }}
                              getTranscriptions={this.getTranscriptions}
                              updateSelf={this.props.actions.UpdateSelf}
                              highlight={this.highlight}
                              removeHighlight={this.removeHighlight}
                        />
                  ))
                  .sort((a: any, b: any) => a.timestamp - b.timestamp)

            return (
                <>
                    <NotesWrapper>
                        <Overlay collapsed={collapsed} onClick={this.toggleCollapseSidebar} />
                        <SideMenuWrapper className='rounded container content' collapsed={collapsed}>
                            <Title>All Transcriptions</Title>
                            <SearchDiv className='border-light topbar hover-container'>
                                <AiOutlineSearch size='18px' />
                                <SearchInput
                                    onChange={(e) => this.handleSearch(e.target.value)}
                                    type='text'
                                    placeholder='Search'
                                    className='color-textimp'
                                />
                            </SearchDiv>
                            <Scrollable>
                                <NotePreview
                                    color={color}
                                    style={{ background: transcribe ? 'rgba(248,152,9,0.2)' : '', display: this.state.transcribe ? 'block' : 'none' }}
                                    onClick={() => {
                                        this.toggleOnMobile();
                                        this.showTranscription(null);
                                    }}
                                >
                                    {/* // className={(localActiveId || active) === note.id ? 'topbar' : localActiveId && localActiveId !== active && active === note.id ?  'public-active' : ''} onClick={() => this.handleChangeActive(note)} key={`note-preview-${idx}`}> */}
                                    <div className='preview-header'>
                                        <div className='preview-title'>
                                            {this.state.transcribe ? 'Current Transcriptions' : 'Transcription Disabled'}
                                        </div>
                                        {/* <div className="preview-date">
                                            {new Date().toDateString()}
                                        </div> */}
                                    </div>
                                    {/* <div className="preview-content">No additional content</div> */}
                                </NotePreview>
                                {(this.state.data.searched ?? this.state.saved).map((data: any, index: any) => {
                                    return (
                                        <NotePreview
                                            key={index}
                                            color={color}
                                            className={this.state.data.view?._id === data._id ? 'topbar' : ''}
                                            onClick={() => {
                                                this.toggleOnMobile();
                                                this.showTranscription(data);
                                            }}
                                        >
                                            {/* // className={(localActiveId || active) === note.id ? 'topbar' : localActiveId && localActiveId !== active && active === note.id ?  'public-active' : ''} onClick={() => this.handleChangeActive(note)} key={`note-preview-${idx}`}> */}
                                            <div className='preview-header'>
                                                <div className='preview-title'>
                                                    {data.title || moment(data.created_at).format('lll')}
                                                </div>
                                                <div className='preview-date'>{moment(data.created_at).fromNow()}</div>
                                            </div>
                                            <div className='preview-content'>
                                                {data.description ?? 'No additional content'}
                                            </div>
                                        </NotePreview>
                                    );
                                })}
                            </Scrollable>
                        </SideMenuWrapper>
                        <ContentWrapper className='topbar rounded inner' collapsed={collapsed} color={color}>
                            <MenuWrapper>
                                <SideMenuIcon onClick={this.toggleCollapseSidebar} />
                            </MenuWrapper>
                            <div className='note-header'>
                                <div className='note-header-left'>
                                    <DateCont>{view?.title || moment(view?.created_at).format('lll')}</DateCont>
                                    <TransSpan>{view ? view?.description ?? "No additional content" : "On-going Meeting"}</TransSpan>
                                </div>
                                {/* <SearchDiv>
                                          <AiOutlineSearch size="22px" />
                                          <SearchInput onChange={(e) => this.handleSearch(e.target.value)} className="topbar" type='text' placeholder='Search' />
                                    </SearchDiv> */}
                                {this.state.data.view ? (
                                    <div className='note-options'>
                                        <Dropdown items={DropdownContent} addTop={10}>
                                            <MoreOptionIcon />
                                        </Dropdown>
                                    </div>
                                ) : null}
                            </div>

                            <TransContainer {...({ ref: (r: any) => (this.messagesEnd = r) } as any)} onScroll={() => {
                                clearTimeout(this.interval)
                                this.interval = setTimeout(() => {
                                    this.setState({ scrolling: false })
                                }, 10000);
                                if(!this.state.scrolling) {
                                    this.setState({ scrolling: true })
                                }
                                }
                                }>
                                {/* {should_backend_transcribe && (
                                    <Alert>
                                        Transcription is not locally supported on your machine, so we're doing it on our
                                        end. Results will be a bit slower, and may not finish in order.
                                    </Alert>
                                )} */}

                                {mapTrans}

                                {/* Disabling this for now */}
                                {this.state.live_transcript && this.state.live_transcript !== "..." ?
                            <TranscriptionItem
                            {...this.props}
                              key='live'
                              rec={{
                                    owner: false,
                                    user: { name: "" },
                                    timestamp: new Date(),
                                    text: this.state.live_transcript || "...",
                              }}
                              getTranscriptions={this.getTranscriptions}
                              updateSelf={this.props.actions.UpdateSelf}
                              highlight={this.highlight}
                              removeHighlight={this.removeHighlight}
                              live
                        /> : null}
                            </TransContainer>
                        </ContentWrapper>
                    </NotesWrapper>
                </>
            );
      }
}

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    padding: 0px 20px 10px;
`

const Alert = styled.div`
	padding: 10px;
	font-size: 12px;
	font-weight: 500;
    border-radius: 5px;
    margin: 10px 0;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    line-height: 14px;
`

const TransContainer = styled.div`
	width: 100%;
	overflow-y: scroll;
      height: 100%;

       ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: darkgray;
    }

`

const DateCont = styled.div`
      margin-left: 2px;
      filter: brightness(.8);
`

const TransSpan = styled.div`
      margin-top: 5px;
      font-weight: bold;
`
const SearchDiv = styled.div`
      align-items: center;
      border: 1px solid;
      margin: 5px 5px 10px;
      display: flex;
      margin-right: 5px;
      padding: 10px;
      border-radius: 30px;
`

const SearchInput = styled.input`
      background-color: transparent !important;
      outline: none;
      border: none;
      margin-left: 5px;
      width: 100%;
      margin-right: 5px;
`
