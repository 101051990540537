import React from 'react'
import styled from 'styled-components'

import { ParticipantsTileWrapper } from '../styles'
import ScreenTrack from './ScreenTrack'

interface Props {
    screenTrack?: any
}

function PresentationTileTwilio(props: Props) {
    const { screenTrack } = props
    return (
        <PresentationContainer id="presentationContainer">
            <ParticipantsTileWrapper className="presentationParticipant container rounded inner">
                <ScreenTrack key={`${Math.random()}-screentrack`} track={screenTrack.mediaStreamTrack}/>
            </ParticipantsTileWrapper>
        </PresentationContainer>
    )
}

const PresentationContainer = styled.div`
    width: 100%;
    height: 100%;

    justify-content: center;
    overflow: auto;
    height: 100%;
    display: flex;

    & + #callContainer {
        width: 220px;
        left: auto;
        bottom: auto;
        min-height: 100%;
        align-content: baseline;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        > .callParticipants {
            min-width: 200px;
            max-width: 200px;
            height: 160px;
            max-height: 160px;
            min-height: 160px;

            .host-indicator {
                width: 14px;
                
                svg {
                    width: 14px;
                    margin-right: 5px;
                }
            }


            .call-control {
                padding: 12px !important;
                font-size: 14px;
                margin: 2px !important;
            }
        }
    }

    @media (max-width: 970px) {

        width: 100%;
        min-width: 100%;
        height: 100%;

    }

    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        height: 100%;
        margin-top: 80px;

        & + #callContainer {
            width: 100%;
            top: auto;
            bottom: 0;
            min-height: 160px;
            max-height: 160px;
            align-content: center;
            left: 0;
            overflow: auto;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: baseline;
            > .callParticipants {
                max-width: 150px;
                min-width: 150px;
                max-height: 130px;
                min-height: 130px; 
                
                .call-control {
                    padding: 10px !important;
                    font-size: 14px;
                    margin: 2px !important;
                }
            }
        }
    }
`

function areEqual(prevProps: Props, nextProps: Props) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    return JSON.stringify(prevProps.screenTrack) === JSON.stringify(nextProps.screenTrack)
}

export default React.memo(PresentationTileTwilio, areEqual)
