import React from 'react'
import Reflux from 'reflux'
import moment from 'moment'
import { FaAngleRight as ExpandIcon } from '@react-icons/all-files/fa/FaAngleRight'
import { FaAngleLeft as CollapseIcon } from '@react-icons/all-files/fa/FaAngleLeft'

import KeynoteMain from './KeynoteMain'
import KeynoteSidebar from './KeynoteSidebar'
import FloatingWidgets from '../FloatingWidgets'

import { AuthStore } from '../../../../stores/AuthStore'
import { MainStore, WidgetActions } from '../../../../stores/MainStore'
import { VariableCallingStore } from '../../../../stores/VariableCallingStore'
import {
  NotificationStore,
  NotificationActions,
} from '../../../../stores/NotificationStore'
import { ThemingStore } from '../../../../stores/ThemingStore'

import { Container, JoinedUserUI, LeftUserUI, ToggleList } from './StyledComponents'
import JoinedRoom from '../../../../assets/JoinMeeting.mp3'
import { CallingInstanceState } from '../../../../calling/types'
import { updateLayout, updateLayoutPresentation } from '../../../../helpers'
import CustomTip from '../../../../helpers/CustomTip'
import deepCopy from 'deep-copy'

const { GlobalState } = require('reflux')

interface Props {
  watchMode: any
}

interface State {
  expanded: boolean
  db_meeting: any
  currentWidget: string
  localCurrentWidget: string
  widgets: any
  meetingName: string
  files: any
  users: any
  transcription: any
  meetingConnection: string
  jwt: any
  external_tokens: any
  usersWhoLeft: any[]
  usersWhoJoined: any
  locallyHiddenSync: {}
  status: CallingInstanceState
  meetingLocked: boolean
  call_starter: any
  showChat: Boolean
  host: any
  mobileCollapse: Boolean
  is_mobile: boolean
  useCurrentWidget: boolean
  localWidgetOrder: {}
  color_scheme: string
}

const is_mobile = window.matchMedia('(max-width: 767px)')
export class KeynoteWidgets extends Reflux.PureComponent<
  | typeof VariableCallingStore
  | typeof MainStore
  | typeof AuthStore
  | typeof NotificationStore
  | typeof ThemingStore,
  Props,
  State
> {
  widget_refs: any

  constructor(props: Props) {
    super(props)

    this.stores = [
      MainStore,
      VariableCallingStore,
      AuthStore,
      NotificationStore,
      ThemingStore
    ]
    this.storeKeys = [
      'db_meeting',
      'currentWidget',
      'localCurrentWidget',
      'widgets',
      'meetingName',
      'files',
      'users',
      'transcription',
      'meetingConnection',
      'jwt',
      'external_tokens',
      'locallyHiddenSync',
      'status',
      'meetingLocked',
      'call_starter',
      'host',
      'showChat',
      'localWidgetOrder',
      'color_scheme'
    ]

    this.widget_refs = {}

    this.state = {
      expanded: true,
      db_meeting: {
        settings: {
          widget_display_method: 'Keynote',
          widgetOrder: [],
          expandedWidgets: [],
        },
      },
      currentWidget: '',
      localCurrentWidget: '',
      widgets: {},
      meetingName: '',
      files: [],
      users: [],
      transcription: [],
      meetingConnection: '',
      jwt: { data: {} },
      external_tokens: {},

      // widget state
      status: CallingInstanceState.UninitializedWithoutToken,
      locallyHiddenSync: {},
      meetingLocked: false,
      call_starter: '',
      host: '',
      localWidgetOrder: {},

      // defined
      usersWhoJoined: null,
      usersWhoLeft: [],

      showChat: false,

      mobileCollapse: false,
      is_mobile: is_mobile.matches,
      useCurrentWidget: !!!is_mobile.matches,
      color_scheme: 'Light'
    }
  }

  componentDidMount() {
    is_mobile.addListener(this.mediaChange)
  }

  componentWillUnmount() {
    is_mobile.removeListener(this.mediaChange)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState?.currentWidget !== this.state?.currentWidget) {
      this.scrollWidgetIntoView(this.state.currentWidget || 'splash')
    }

    const curOnlineUsers = this.state.users.filter(
      (u: any) => u.status === 'online',
    )
    const prevOnlineUsers = prevState.users.filter(
      (u: any) => u.status === 'online',
    )

    // show the users who joined the room
    if (prevOnlineUsers.length < curOnlineUsers.length) {
      const usersWhoJoined = curOnlineUsers
        .filter(
          ({ id: curId }: any) =>
            !prevOnlineUsers.some(({ id: prevId }: any) => curId === prevId),
        )
        .filter(
          ({ id: curId, last_seen }: any) =>
            curId !== this.state.jwt?.data?._id &&
            moment().diff(moment.unix(last_seen / 1000), 'seconds') <= 5,
        )
      // show first user
      this.setState({
        usersWhoJoined: usersWhoJoined?.length ? usersWhoJoined[0] : null,
      })
      // exclude first user
      const nextAvailable =
        usersWhoJoined?.length > 1 ? usersWhoJoined.slice(1) : []
      // iterate through the remaining usersWhoJoined and show every 3 seconds
      if (nextAvailable.length) {
        let interval = setInterval(
          (gen) => {
            const { value, done } = gen.next()

            if (done) {
              this.setState({ usersWhoJoined: null })
              clearInterval(interval)
            } else {
              this.setState({ usersWhoJoined: value })
            }
          },
          2000,
          usersWhoJoined[Symbol.iterator](),
        )
      } else {
        let interval = setInterval(() => {
          this.setState({ usersWhoJoined: null })
          clearInterval(interval)
        }, 3000)
      }

      // new Audio(JoinedRoom).play()
    }

    // shows the users who left the room
    if (curOnlineUsers.length < prevOnlineUsers.length) {
      const usersWhoLeft = prevOnlineUsers.filter(
        ({ id: prevId }: any) =>
          !curOnlineUsers.some(({ id: curId }: any) => curId === prevId),
      )
      this.setState({ usersWhoLeft }, () => {
        setTimeout(() => {
          this.setState({ usersWhoLeft: [] })
        }, 2000)
      })
    }
  }

  mediaChange = ({ matches }: { matches: any }) => {
    this.setState({ is_mobile: matches, useCurrentWidget: !!!matches })
  }

  setExpanded = () => {
    this.setState(
      (prevState) => ({ ...prevState, expanded: !prevState.expanded }),
      () => {
        updateLayout()
        updateLayoutPresentation()
      },
    )
  }

  scrollWidgetIntoView = (widget_name: string) => {
    const { db_meeting } = this.state

    const scrollType =
      this.props.watchMode &&
        (!db_meeting?.bot_settings?.smoothScroll ||
          db_meeting?.bot_settings?.presentationStyle === 'fullscreen')
        ? 'instant'
        : 'smooth'
    this.widget_refs[widget_name] &&
      this.widget_refs[widget_name].scrollIntoView({
        behavior: scrollType,
        block: 'center',
      })
  }

  toggleMobileCollapse = () => {
    this.setState({ mobileCollapse: !this.state.mobileCollapse })
  }

  _transformChat = () => {
    let chats = this.state.widgets?.chat?.chats
      ? deepCopy(this.state.widgets.chat.chats)
      : []

    if (!Array.isArray(chats)) {
      return Object.values(chats).sort(
        (a: any, b: any) => a.timeStamp - b.timeStamp,
      )
    }

    return chats
  }

  timer: any

  getUnreadMessagesCount = () => {
    // clearTimeout(this.timer)
    // this.timer = setTimeout(() => {
    //   console.log('getUnread', this.state.db_meeting.name);
    //   return 2
    // }, 3000);
  }

  _getUnreadMessagesCount = () => {
    const { chat = {} } = this.state.widgets
    const chatWidgetState = { ...chat }

    // console.log('widgets',this.state.widgets);


    const chats = this._transformChat()
    chatWidgetState.chats = chats

    // if (chatWidgetState.userList === undefined) return
    const myUserData =
      this.state.users.find(
        (u: any) => u.id === GlobalState.auth.jwt.data._id,
      ) || {}

    // const userList = chatWidgetState.userList

    // if (!userList.includes(myUserData.id)) {
    //   const {chat = {}} = this.state.widgets

    //   const lastChat = chats && chats[chats.length - 1]

    //   const lastSeenMessageMap = {...chat.lastSeenMessageMap}
    //   const userId = myUserData.id
    //   lastSeenMessageMap[userId] = lastChat && lastChat.id ? lastChat.id : ''

    //   WidgetActions.UpdateWidget({
    //     name: 'chat',
    //     lastSeenMessageMap: {...lastSeenMessageMap},
    //     userList: [...chat.userList, myUserData.id],
    //   })

    //   return 0
    // }

    const lastSeenMessageId =
      chatWidgetState.lastSeenMessageMap &&
        chatWidgetState.lastSeenMessageMap[myUserData.id]
        ? chatWidgetState.lastSeenMessageMap[myUserData.id]
        : ''

    let unreadMessagesCount =
      chatWidgetState.chats && chatWidgetState.chats.length
        ? chatWidgetState.chats.length
        : 0

    if (lastSeenMessageId) {
      let chatsCopy = [...chatWidgetState.chats]
      let lastMessageIndex = chatsCopy.findIndex(
        (chat) => chat.id === lastSeenMessageId,
      )
      chatsCopy = chatsCopy.slice(lastMessageIndex + 1)
      // get messages that are not yours
      unreadMessagesCount = chatsCopy.filter(
        (chat) => chat.userId !== myUserData.id,
      ).length
    }

    return unreadMessagesCount
  }

  render() {
    let {
      db_meeting,
      currentWidget,
      localCurrentWidget,
      widgets,
      meetingName,
      files,
      users,
      transcription,
      expanded,
      status,
      meetingConnection,
      jwt,
      external_tokens,
      usersWhoLeft,
      usersWhoJoined,
      locallyHiddenSync,
      meetingLocked,
      call_starter,
      host,
      localWidgetOrder,
    } = this.state
    const {
      settings: { widget_display_method },
    } = db_meeting

    // LOCAL STATE WIDGET NAVIGATOR
    currentWidget = localCurrentWidget?.[meetingName as any]

    if (status === CallingInstanceState.Connected && meetingLocked) {
      currentWidget = this.state.currentWidget
    }

    const mainstore = {
      db_meeting,
      currentWidget,
      localCurrentWidget,
      widgets,
      meetingName,
      files,
      users,
      transcription,
      locallyHiddenSync,
      localWidgetOrder,
    }
    const callingstore = {
      status,
      meetingConnection,
      meetingLocked,
      call_starter,
      host,
    }
    const authstore = { jwt, external_tokens }

    return (
      <Container
        className="container"
        {...{ mobileCollapse: this.state.mobileCollapse }}
      >
        {widget_display_method === 'Keynote' && (
          <KeynoteSidebar
            setExpanded={this.setExpanded}
            expanded={expanded}
            mainstore={mainstore}
            callingstore={callingstore}
            authstore={authstore}
            widget_refs={this.widget_refs}
            scrollWidgetIntoView={this.scrollWidgetIntoView}
            mobileCollapse={this.state.mobileCollapse}
            toggleMobileCollapse={this.toggleMobileCollapse}
          />
        )}
        {widget_display_method === 'Keynote' && (
          <ToggleList
            data-tip
            data-for="ex-col"
            onClick={this.setExpanded}
            {...{ expanded }}
          >
            <div className={`button ${expanded ? 'default' : 'primary'}`}>
              {expanded ? <CollapseIcon size={16} /> : <ExpandIcon size={16} />}
            </div>
          </ToggleList>
        )}
        <CustomTip
          top={-25}
          left={10}
          class="top-left"
          place="right"
          tip={expanded ? 'Collapse' : 'Expand'}
          dataFor="ex-col"
        />
        {!usersWhoJoined ? (
          <React.Fragment />
        ) : (
          <JoinedUserUI className="topbar rounded inner">
            {usersWhoJoined ? (
              <React.Fragment>
                <div className="image-wrapper">
                  <img
                    src={
                      usersWhoJoined?.avatar_url ||
                      `https://ui-avatars.com/api/?name=${usersWhoJoined?.name
                      }&format=svg&background=${usersWhoJoined?.color || 'random'
                      }&color=fefefe`
                    }
                    alt={usersWhoJoined?.name}
                  />
                </div>
                <div className="joined-message">
                  <span className="name">{usersWhoJoined?.name}</span> joined the
                  room
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
          </JoinedUserUI>
        )}

        <KeynoteMain
          setExpanded={this.setExpanded}
          expanded={expanded}
          mainstore={mainstore}
          scrollWidgetIntoView={this.scrollWidgetIntoView}
          callingstore={callingstore}
          authstore={authstore}
          usersWhoJoined={usersWhoJoined}
          useCurrentWidget={this.state.useCurrentWidget}
          mobileCollapse={this.state.mobileCollapse}
          color_scheme={this.state.color_scheme}
        />
        {(usersWhoLeft || []).map((user) => {
          if (user.last_seen && !moment(user.last_seen, 'x').isBefore(moment()))
            return <React.Fragment></React.Fragment>
          return (
            <LeftUserUI className="button default rounded inner">
              <div className="joined-message">
                <span className="name">
                  {user?.name || user?.username || ''}
                </span>{' '}
                left the room
              </div>
            </LeftUserUI>
          )
        })}
        <FloatingWidgets
          showChat={this.state.showChat}
          onShowChat={NotificationActions.ShowChat}
          mobileCollapse={this.state.mobileCollapse}
          getUnreadMessagesCount={this.getUnreadMessagesCount}
        />
      </Container>
    )
  }
}

export default KeynoteWidgets
