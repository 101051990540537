import React from 'react';
import styled from 'styled-components';
import Table from '../../_Shared/Table';
import moment from 'moment';
import { WidgetContentItem } from '../../_Shared/Elements';
import { FaTrash as Trash } from '@react-icons/all-files/fa/FaTrash';
import Dropdown from '../../_Shared/DropdownWrapper';
import { IoEllipsisHorizontal } from '@react-icons/all-files/io5/IoEllipsisHorizontal';
import Api, { Actions, Endpoints } from '../../_Shared/Api';
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'
import Tablev2 from '../../_Shared/Tablev2';


const Tasks = (props: any) => {
  const [search, setSearch] = React.useState('')
  const { UpdateSelf } = props.actions;
  const { getTasks } = props;

  React.useEffect(() => {
      //console.log(props)
  })

//   const handleClick = (data: any) => {
//     UpdateSelf({ screen: 'task', contact_id: data.id });
//   };

  const deleteTask = async (id: string, e: any) => {
    await Api(Endpoints['Hubspot'], Actions['RemoveCalendarTask'], props.external_token, {}, [['taskId', id]]);
    getTasks();
  };

  return (
    <>
      <SpaceWrapper>
        <Button className='button default' onClick={() => UpdateSelf({ screen: 'createTask' })} ref={props.useRef}>
          Create Task
        </Button>
      </SpaceWrapper>
      <Header className="text-primary">Marketing Tasks</Header>
      <SubHeader className="text-secondary">{props.data.tasks?.length ?? 0} records</SubHeader>
      <Tablev2 
        defaultPageSize={props.data.tasks.length || 0}
        columns={[{
            Header: 'State',
            accessor: '',
            Cell: ({ original }) => `${original?.state}`
          }, {
            Header: 'Name',
            accessor: '',
            Cell: ({ original }) => `${original?.name ?? '-'}`
          }, {
            Header: 'Description',
            accessor: '',
            Cell: ({ original }) => `${original?.description ?? '-'}`
          },
          {
            Header: 'Category',
            accessor: '',
            Cell: ({ original }) => `${original?.category ?? '-'}`
          }, 
          {
            Header: 'Due Date',
            accessor: '',
            Cell: ({ original }) => `${moment(original?.eventDate).format('ll') ?? '-'}`
          }, 
          {
            Header: '',
            accessor: '',
            width: 60,
            Cell: ({ original }) => {
              const DropdownContent = (props: any) => {
                return (
                  <React.Fragment>
                    <WidgetContentItem
                      padding={10}
                      onClick={(e: any) => {
                        deleteTask(original.id, e);
                        props.onClose();
                      }}
                    >
                      <Trash width={16} className='error' />
                      Remove Task
                    </WidgetContentItem>
                  </React.Fragment>
                );
              };
              return (
                <Dropdown items={DropdownContent}>
                  <IoEllipsisHorizontal width={16} style={{ fontSize: '16px' }} />
                </Dropdown>
              );
            }
          }
        ]}
        data={props.data.tasks.status === 'error' ? [] : props.data.tasks}
        showPagination={false}
        sortable={false}
        //onRowClick={(row: any) => handleClick(row)}
      />
    </>
  );
};

export default Tasks;

const SpaceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: 0;
`;

const Button = styled.div`
  text-align: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.textBold};
  margin: 0 2px;
  display: none;

  &.custom-padding {
    padding: 10px 15px !important;
    font-size: 12px;
    margin: 0 10px;
  }

  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
  }
`;


const Assignees = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > div:not(:last-child) {
    margin-left: -10px;
  }

  & > div {
    transition: 0.3s transform ease-out;
    border: 1px solid white;
    &:hover {
      z-index: 2;
      border: 1px solid white;
      transform: scale(1.1);
    }
  }
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`

const SubHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 15px;
`

const SearchInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 294px;  
  background-color: transparent !important;
  outline: none;
  border: none;
`

const SearchDiv = styled.div`
  align-items: center;
  border-radius: 999px;
  border: 1px solid #6F6868; 
  display: flex;
  padding: 2px;
  margin-right: 5px;
  margin-top: 30px;
  margin-bottom: -30px;
  width: 294px;  
  padding-left: 10px;
`

const CTable = styled(Table)`
    display: block;
    overflow-x: auto;
    white-space: nowrap;
`