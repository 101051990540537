import React from 'react';
import styled from 'styled-components';

const ViewHeader = (props: any) => {
    const changeTab = (e: any) => {
        //console.log(e.target.id);
        props.changeTab(e.target.id);
    };

    return (
        <Navigation>
            <Tab tab={props.tab === 'notes' ? true : false} onClick={changeTab} id='notes'>
                Notes
            </Tab>
            <Tab tab={props.tab === 'emails' ? true : false} onClick={() => {}} id='emails' disabled>
                Emails
            </Tab>
            <Tab tab={props.tab === 'calls' ? true : false} onClick={() => {}} id='calls' disabled>
                Calls
            </Tab>
            <Tab tab={props.tab === 'task' ? true : false} onClick={changeTab} id='task'>
                Task
            </Tab>
            <Tab tab={props.tab === 'meetings' ? true : false} onClick={() => {}} id='meetings' disabled>
                Meetings
            </Tab>
        </Navigation>
    );
};

const Navigation = styled.div`
    display: flex;
    flex-direction: row;
    /* .selected {
        border-bottom: 3px solid #F69018;
    } */
`;

const Tab = styled.div<{ tab?: any; name?: any; disabled?: boolean }>`
    margin: 20px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    padding-bottom: 5px;
    border-bottom: ${(props) => (props.tab ? '3px solid #F69018' : 'none')};
    color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.1);' : '#000')};
`;

export default ViewHeader;
