import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'

import HamburgerHousing from './HamburgerHousing'
import KeynoteWidgets from '../Meeting2.0/Widgets/KeynoteWidgets'
import KeynoteSidebar from '../Meeting2.0/KeynoteSidebar'
import Setup from '../Meeting2.0/Setup'
import { MainActions, MainStore } from '/stores/MainStore'
import Sagas from '/helpers/Sagas'
import {
      colorSchemeMapper,
      defaultStyles,
      ThemingStore,
} from '../../stores/ThemingStore'
import AudioBackgroundPlayer from '../Meeting2.0/NewCall/AudioBackgroundPlayer'
import { VariableCallingActions } from '../../stores/VariableCallingStore'

import LoopApi from 'helpers/LoopApi'
import { getMediaDevices } from '/calling/_helpers.tsx'
import { AuthActions, AuthStore } from '../../stores/AuthStore'
import {MeetingProvider} from '../../contexts/MeetingProvider'

const { GlobalState } = require('reflux')


export default class Presentation extends Reflux.Component {
      constructor(props) {
            super(props)
            this.stores = [MainStore, ThemingStore, AuthStore]
            this.storeKeys = ['setupPhase', 'color_scheme', 'widgets', 'db_meeting', 'jwt']
            this.initMeeting = this.initMeeting.bind(this)
      }

      componentDidMount() {
          const { url, params } = this.props.match;
          if(url[url.length-1] === '/' && params.meetingName) return window.location.href = `/${params.meetingName}`
          this.initMeeting()
      }

      componentWillUnmount() {
            VariableCallingActions.Leave()
            super.componentWillUnmount()
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
            if (
                  nextProps.match.params.meetingName !==
                  this.props.match.params.meetingName
            ) {
                  this.initMeeting(nextProps.match.params.meetingName)
            }
      }


       recentMeetingManager = () => {
        let meetings = JSON.parse(localStorage.getItem('recentRooms')) || []
        const roomName = this.props.match.params.meetingName || null
        if(Array.isArray(meetings) && roomName) {
            meetings.push({ lastVisit: new Date(), room: roomName })
            meetings = meetings.reverse().filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.room === value.room,
                ),
            )
            localStorage.setItem('recentRooms', JSON.stringify(meetings))
        }

      }

      async initMeeting(passed_name = null) {
            if(this.state.setupPhase === 'completed') return 
            const meeting_name =
                  passed_name || this.props.match.params.meetingName
                  //console.log("Meeting name: ", meeting_name)
            if (meeting_name === 'me' || !meeting_name) {
                  if(!!!localStorage.token) {
                        if(this && this.props && this.props.history) {
                              return this.props.history.push(`/login`)
                        } else {
                              return window.location.href = '/login'
                        }
                  } else {
                        if(this && this.props && this.props.history) {
                              return this.props.history.push(`/create-or-join`)
                        } else {
                              return window.location.href = '/create-or-join'
                        }
                  }
            } else if (meeting_name === 'create-or-join') {
                  return MainActions.SetupPhase('create-or-join')
            }

            try {
                  let resp = {}
                  let me = {}

                  if(this.state.jwt) {

                        if(!!!localStorage.getItem('token')) throw new Error('invalid token')
                        resp = await LoopApi(null, 'GetMeeting', {}, [
                              ['name', meeting_name],
                        ])
                        me = await LoopApi(null, 'Me')
                  }


                  if (!(resp && resp.dbMeeting && resp.dbMeeting.user_ids.includes(me._id))) {
                        const { hasMaxParticipants } = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
                              ['name', meeting_name],
                        ]);

                        AuthActions.FinishedCheckMeeting()


                        if (hasMaxParticipants) return window.location.href = "/create-or-join"
                  }

                  let expiry = resp && resp.dbMeeting && resp.dbMeeting.expiry ? resp.dbMeeting.expiry : ''
                  let DateTimeNow = new Date().getTime()
                  //   var result = new Date(expiry).getTime();

                  console.log('EXPIRY',expiry,);
                  if (new Date(expiry).getTime() < DateTimeNow) {
                        console.log('Meeting Expired')
                        return MainActions.SetupPhase('expired')
                  }

                  // RECENT ROOM MANAGER
                  //console.log('RECENT ROOM MANAGES HERE....')
                  this.recentMeetingManager()

                  // devices
                  const setDevices = async () => {
                        let devices = await getMediaDevices()

                        const localSinkId = localStorage.getItem('audio_output_device_id')
                        if (devices.audioOutput.length > 0 && !devices.audioOutput.map(({ deviceId }) => deviceId).includes(localSinkId)) {
                              localStorage.setItem('audio_output_device_id', devices.audioOutput[0].deviceId)
                              MainActions.SetActiveSinkId(devices.audioOutput[0].deviceId)
                        }

                        const selectedAudioInputId = localStorage.getItem('audio_input_device_id')
                        if (devices.audio.length > 0 && !devices.audio.map(({ deviceId }) => deviceId).includes(selectedAudioInputId)) {
                              // localStorage.setItem('audio_input_device_id', devices.audio[0].deviceId)
                        }

                        MainActions.SetDevices({
                              videoDevices: devices.video,
                              audioDevices: {
                                    input: devices.audio,
                                    output: devices.audioOutput,
                              },
                        })
                  }

                  setDevices()

                  navigator.mediaDevices.addEventListener('devicechange', setDevices)

                  //console.log(new Date(expiry).getTime(), DateTimeNow)
            } catch (err) {
                  console.error(err)
            }

            if(this.state.jwt) {
                  Sagas.changeSetting('minimizedWidgets', [])
                  .then(() =>{})
                  .catch((e) => {
                        console.error("CHANGE SETTING ERROR: ", e)
                  })
            }
            MainActions.SetupPhase('loading')
            localStorage.lastMeeting = meeting_name

            await Sagas.initMeeting(meeting_name, this.props.history.push)
                  .then((setupPhase) => MainActions.SetupPhase(setupPhase))
                  .catch((e = {}) => {
                        if (e.handled) {
                              return
                        } else if (e.errorCode === 404) {
                              return MainActions.SetupPhase('notfound')
                        } else if (e.errorCode === 403) {
                            return MainActions.SetupPhase('unauthorized')
                        } else if (e.errorCode === 422) {
                              return MainActions.SetupPhase('archived')
                        } else if(e.errorCode === 500) {
                              return MainActions.SetupPhase('error')
                        }
                  })
      }

      render() {
            const color_scheme = colorSchemeMapper[this.state.color_scheme]
            const meetingName = this.props.match.params.meetingName

            return (
                  <MeetingProvider
                        meeting={this.state.db_meeting}
                  >
                        <HamburgerHousing
                              meetingName={meetingName}
                              history={this.props.history}
                        >
                              <React.Fragment>
                                    <Container
                                          id="container-72px-padding"
                                          color_scheme={color_scheme}
                                          loaded={
                                                this.state.setupPhase === 'completed'
                                          }
                                    >
                                          <KeynoteSidebar meetingName={meetingName} />{' '}
                                          <KeynoteWidgets />
                                    </Container>{' '}
                                    {this.state.setupPhase !== 'completed' && meetingName && meetingName !== 'me' && (
                                          <Setup JoinedMeeting={this.initMeeting} />
                                    )}
                                    {/* { !(this.state.db_meeting.settings.calling_provider || '').includes('TWILIO') && <AudioBackgroundPlayer /> } */}

                              </React.Fragment>
                        </HamburgerHousing>
                  </MeetingProvider>
            )
      }
}

const Container = styled.div`
	height: 100%;
	padding-top: 72px;
	background-color: white;
	box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.5);
	visibility: ${props => props.loaded ? 'visible' : 'hidden'};
	opacity: ${props => props.loaded ? 1 : 0};
	transition-duration: 0.3s;
      // width: 100vw;

	${Object.entries(defaultStyles || {})
            .map(([key, val]) => `${key} {${val}}`)
            .join('\n')}

	${({ color_scheme }) => {
            const styleobj = Object.entries(color_scheme || {})
                  .map(([key, val]) => `${key} {${val}}`)
                  .join('\n')
            return styleobj
      }}
`
