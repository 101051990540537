import React, { Component } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import RDatepicker from '../_Shared/Datepicker'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

import { TiDelete } from '@react-icons/all-files/ti/TiDelete'

import FormField from '../_Shared/FormField'
import Dropdown from '../_Shared/DropdownWrapper'

import { FaUser } from '@react-icons/all-files/fa/FaUser'
import { FiPaperclip } from '@react-icons/all-files/fi/FiPaperclip'
import { IoIosAddCircle } from '@react-icons/all-files/io/IoIosAddCircle'

import { IWidgetProps, AllWidgets } from '../types'
import moment from 'moment'

import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose'
import { toast } from 'react-toastify'

import LoopApi from '../../helpers/LoopApi'

import urlRegex from 'url-regex'

import { WidgetActions } from '../../stores/MainStore'

import { Todos, Todo } from './types'
import { EditorComponent } from './Editor'

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import CustomTip from '../../helpers/CustomTip'

type Props = {
    actions: IWidgetProps<AllWidgets.Todo>['actions']
    users: any
    userId: any
    addLink: (s: string) => void
    closeModal: () => void
    todos: Todos
    selected: Todo
    selectedTask: string
    selectedGroup: string
}


type state = {
    dueDate: string
    members: any[]
    taskName: string
    notes: any
    errors: required | null
    reminder: string,
    attachments: any[]
    isLoading: boolean,
    assignee: any,
    createdBy: string | null
    dateCreated: Date
    done: boolean
}

type required = {
    [key: string]: any
}

type requiredCheckTypes = {
    name: string,
    value: any
}

const REQUIRED: required = {
    taskName: 'Task Name',
    assignee: 'Assignment'
}

export class CreateTaskForm extends Component<Props, state> {
    fileRef: any
    notes = React.createRef<HTMLTextAreaElement>()

    constructor(props: Props) {
        super(props)

        this.state = {
            dueDate: '',
            members: this.props.users,
            taskName: '',
            notes: '',
            errors: null,
            reminder: '',
            attachments: [],
            isLoading: false,
            assignee: this.props.users?.map((u: any) => u.id === this.props.userId ? u : null).filter((u: any) => u !== null),
            createdBy: '',
            dateCreated: new Date(),
            done: false
        }

        this.fileRef = React.createRef()

        this.addAssignee = this.addAssignee.bind(this)
        this.removeAssignee = this.removeAssignee.bind(this)
        this.addTask = this.addTask.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.handleChangeAttachments = this.handleChangeAttachments.bind(this)
        this.removeAttachment = this.removeAttachment.bind(this)
    }

    timer: any

    componentDidMount() {
        if (this.props.selectedTask && this.props.selected.tasks?.[this.props.selectedTask]) {


            const t = this.props.selected.tasks[this.props.selectedTask]

            const blocksFromHTML = convertFromHTML(t.notes);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            this.setState({
                taskName: t.name,
                notes: EditorState.createWithContent(state),
                reminder: t.reminder,
                attachments: t.attachments,
                assignee: t.assignees,
                createdBy: t.createdBy,
                dateCreated: t.dateCreated || moment().format(),
                dueDate: t.dueDate,
                done: t.done
            }, () => { this.onMountRequiredCheck() })
        } else {
            this.onMountRequiredCheck()
            this.setState({ notes: EditorState.createEmpty() })
        }

        document.addEventListener('paste', this.handlePaste);
        setTimeout(() => {
            document.getElementById('task-name-field')?.focus()
        }, 10);

    }

    onMountRequiredCheck = () => {
        const requireds = Object.keys(REQUIRED).map((r: keyof state) => {
            return { name: r, value: this.state[r] }
        })
        this.requiredCheck(requireds)
    }

    componentWillUnmount() {
        window.removeEventListener('paste', this.handlePaste);
    }

    handlePaste = (e: any) => {
        if (document.activeElement === this.notes.current && e && e.clipboardData && e.clipboardData.files[0] === undefined) {
            const textData = e.clipboardData.getData('Text')
            const urls = textData && textData.match(urlRegex()) || []
            urls && urls.length > 0 && this.props.addLink(urls)
        }
    }

    addAssignee(assignee: any, e: any) {
        e.stopPropagation()

        var check = false
        this.state.assignee.map((m: any) => {
            if (m.id === assignee.id) {
                return (check = true)
            }
        })

        if (!check) {
            const assignees = [...this.state.assignee, { ...assignee }]
            this.setState({ assignee: assignees, errors: this.resetErrors('assignee') })
        } else {
            this.removeAssignee(assignee, e)
        }
    }

    removeAssignee(assignee: any, e: any) {
        e.stopPropagation()
        const assignees = this.state.assignee.filter(
            (a: any) => a.id !== assignee.id
        )

        if (!assignees.length) {
            this.requiredCheck([{ name: 'assignee', value: assignees }])
        } else {
            this.setState({ assignee: assignees })
        }
    }

    _filterAssignees = (assignees: Array<any>) => {
        const filAssignees = assignees.filter(x => x.id !== this.props.userId);

        if (filAssignees.length) {
            return filAssignees.map(x => x.id);
        }

        return filAssignees
    }

    async addTask() {

        const attachments = []

        try {
            if (this.state.attachments?.length > 0) {
                const upload = (file: any) => {
                    const data = new FormData();
                    data.append("file", file);
                    return LoopApi(null, "UploadFile", {}, undefined, data);
                };

                for (const file of this.state.attachments) {
                    let r = file;
                    try {
                        r = await upload(file)
                    } catch (error) {
                        //console.log(error)
                    }
                    attachments.push(r)
                }
            }
        } catch (error) {
            //console.log(error)
        }

        const assignees = Array.from(new Set([...this.state.assignee].map((a: any) => a.id)))
            .map(id => {
                return [...this.state.assignee].find((a: any) => a.id === id)
            })

        const id = new Date().getTime()
        const todayDate = moment().format()

        const newTask = {
            name: this.state.taskName,
            notes: draftToHtml(convertToRaw(this.state.notes.getCurrentContent())),
            assignees,
            dueDate: this.state.dueDate,
            ...(this.props.selectedTask && this.props.selected.tasks?.[this.props.selectedTask] ?
                { id: this.props.selected.tasks[this.props.selectedTask].id } : { id }),
            done: this.state.done,
            reminder: this.state.reminder,
            attachments: attachments.length > 0 ? attachments : this.state.attachments,
            updatedBy: this.props.userId || null,
            createdBy: this.state?.createdBy || this.props.userId || null,
            dateCreated: this.state?.dateCreated || todayDate,
            dateUpdated: todayDate
        }

        let assigneeCheckPassed = false
        let toNotify: Array<any> = []
        const fNewAssignees = this._filterAssignees(newTask.assignees)
        const todos = { ...this.props.todos }

        if (this.props.selectedTask && todos[this.props.selectedGroup].tasks?.[this.props.selectedTask]) {
            // For task notification
            if (fNewAssignees.length) {
                const prevAssignees = todos[this.props.selectedGroup].tasks?.[this.props.selectedTask].assignees
                const fPrevAssignees = prevAssignees && this._filterAssignees(prevAssignees)
                const newAssignees = fPrevAssignees && fNewAssignees.filter(na => !fPrevAssignees.includes(na))

                if (newAssignees && newAssignees.length) {
                    toNotify = newAssignees
                    assigneeCheckPassed = true
                }
            }

            todos[this.props.selectedGroup].tasks = {
                ...todos[this.props.selectedGroup].tasks,
                [this.props.selectedTask]: newTask
            }
        } else {
            todos[this.props.selectedGroup].tasks = {
                ...todos[this.props.selectedGroup].tasks,
                [id]: newTask
            }

            // For task notification
            if (fNewAssignees.length) {
                toNotify = fNewAssignees
                assigneeCheckPassed = true
            }
        }

        this.props.actions.UpdateSelf({ todo: todos })

        if (assigneeCheckPassed && toNotify.length) {
            WidgetActions.SendNotification({
                body: {
                    type: 'newtask',
                    todoId: this.props.selectedGroup,
                    taskId: newTask.id
                },
                target_user_ids: toNotify,
                widget: 'todo',
                endpoint: 'NotifyUsers',
            })
            WidgetActions.NotifyUsersTaskWithEmail({
                target_user_ids: toNotify,
                task: newTask
            })
        }

        // For email notification
        if (newTask.dueDate && newTask.reminder)
            await LoopApi(null, 'CreateNotification', { data: newTask, type: 'task' });

        this.setState({ isLoading: false })
        this.props.closeModal()
    }

    closeForm() {
        this.props.closeModal()
    }

    handleChange(val: any) {
        this.setState({ notes: val } as Pick<state, keyof state>)
    }

    handleTaskNameChange = (evt: any) => {
        const { name, value } = evt.target

        this.requiredCheck([{ name, value }])

        if (value.length) {
            if (!(name === "taskName" && value.length > 60)) {
                this.setState({ [name]: value, errors: this.resetErrors(name) } as Pick<
                    state,
                    keyof state
                >)
            } else {
                this.setState({
                    errors: {
                        [name]: `${REQUIRED[name]} can't be greater than 60 characters`
                    }
                })
            }
        }
    }

    handleReminderChange = (evt: any) => {
        const { name, value } = evt.target
        const val = parseInt(value)

        //console.log(val)
        if (val < 1) return;
        this.setState({ [name]: Number.isSafeInteger(val) ? val : 0 } as Pick<
            state,
            keyof state
        >)
    }

    handleChangeAttachments(e: any) {
        const { name, value, files } = e.target

        const attachments = Array.from(files);

        this.setState({ attachments: [...this.state.attachments, ...attachments] })
    }

    removeAttachment(attachment: any) {
        //console.log(this.state.attachments)
        if (!this.state.attachments[0]._id) {
            this.setState({ attachments: this.state.attachments.filter(a => a.lastModified !== attachment.lastModified) ?? [] })
            this.fileRef.value = ""
        } else {
            this.setState({ attachments: this.state.attachments.filter(a => a._id !== attachment._id) ?? [] })
        }
    }

    displayOthers = (assignees: []) => {
        const toDisplay = assignees.slice(3).map((x: any) => x.name)
        return toDisplay.length === 1 ? toDisplay[0] :
            toDisplay.length === 2 ? `${toDisplay[0]} and ${toDisplay[1]}` :
                toDisplay.map((a: any, indx: number, td: any) => {
                    if (td.length - 2 == indx) {
                        return `${a} and `
                    } else if (td.length - 1 == indx) {
                        return `${a}`
                    }
                    return `${a}, `
                }).join('')
    }

    onBlurRequired = (evt: any) => {
        const { name, value } = evt.target
        this.requiredCheck([{ name, value: value.trim() }])
    }

    requiredCheck = (requireds: requiredCheckTypes[]) => {
        let errors = {}
        let valueUpdates = {}

        requireds.map(({ name, value }) => {
            if (REQUIRED[name] && (!value || (Array.isArray(value) && !value.length))) {
                valueUpdates = {
                    ...valueUpdates,
                    [name]: value
                }
                errors = {
                    ...errors,
                    [name]: `${REQUIRED[name]} is required`
                }
            }
        })

        if (Object.keys(errors).length) {
            this.setState({
                ...valueUpdates,
                ...(this.state.errors ? {
                    errors: {
                        ...this.state.errors,
                        ...errors
                    }
                } : { errors })
            })
        }
    }

    resetErrors = (name: string) => {
        let delVal = null
        if (this.state.errors) {
            if (this.state.errors[name]) {
                const { [name]: todel, ...errors } = this.state.errors
                if (!Object.keys(errors).length)
                    return null
                else
                    return errors
            }
            return this.state.errors
        }
        return delVal
    }

    render() {
        const AddAssignee = () => {
            return (this.state.members || []).filter((user) => user.type !== 'guest').map((user, indx) => {
                const bordered =
                    (this.state.assignee || []).filter(
                        (a: any) => a.id === user.id
                    ).length > 0
                return (
                    <UserWrapper
                        className="hover-container"
                        style={{ padding: '2px 10px' }}
                        onClick={(e) => this.addAssignee(user, e)}
                        key={indx}
                    >
                        <ImageWrapper

                            style={{ marginRight: '5px' }}
                            // bordered={bordered}
                            className="border-light"
                        >
                            {bordered ? (
                                <TiDelete
                                    className="remove-assignee bottom"
                                    onClick={(e) =>
                                        this.removeAssignee(user, e)
                                    }
                                />
                            ) : (
                                ''
                            )}
                            {user.avatar_url ? (
                                <img
                                    src={user.avatar_url}
                                    alt={user.name || user.email}
                                />
                            ) : (
                                <span className="assignee-name">
                                    {user.initials}
                                </span>
                            )}
                        </ImageWrapper>
                        {user.name || user.email}
                    </UserWrapper>
                )
            })
        }

        let firstThree = [...this.state.assignee]

        firstThree = Array.from(new Set([...this.state.assignee].map((a: any) => a.id)))
            .map(id => {
                return [...this.state.assignee].find((a: any) => a.id === id)
            })

        if (firstThree.length > 3) {
            firstThree.length = 3
        }

        const task = this.props.selectedTask && this.props.selected.tasks?.[this.props.selectedTask]

        const updatedBy = task && this.props.users.find((user: any) => user.id === task && task.updatedBy)
        const createdBy = task && this.props.users.find((user: any) => user.id === task.createdBy)

        return (
            <MainCont className="topbar">
                <CustomTip top={-20} left={-25} class='top-right' place='left' tip='Assign' dataFor='taskAssign' />

                <Header>
                    <Title>
                        {this.props.selectedTask
                            ? 'Edit Task'
                            : 'Create Task'}
                    </Title>
                    <div style={{ display: 'flex' }}>
                        {this.state.assignee &&
                            this.state.assignee.length > 0 ? (
                            <Assignees>
                                <Dropdown
                                    items={AddAssignee}
                                    left
                                    addTop={15}
                                    width="250px"
                                    scrollable
                                >
                                    <ImageWrapper
                                        className="placeholder transparent-border"
                                        data-tip
                                        data-for='taskAssign'
                                        color="transparent"
                                    >


                                        <IoIosAddCircle className="add-assignee" />
                                        <span className="add-assignee-logo">
                                            <FaUser />
                                        </span>
                                    </ImageWrapper>
                                </Dropdown>
                                {firstThree.length === 3 &&
                                    this.state.assignee.length > 3 ? (
                                    <ImageWrapper
                                        className="container border-light"
                                        title={this.displayOthers(this.state.assignee)}
                                    // color="rgba(0, 0,0,0.4)"
                                    >
                                        +{this.state.assignee.length - 3}
                                    </ImageWrapper>
                                ) : (
                                    ''
                                )}
                                {[...firstThree].reverse().map((assignee: any, i: number) => (
                                    <ImageWrapper data-tip data-for={assignee?.name || ''} className="border-light" key={i}>
                                        <CustomTip top={-20} left={-25} class='top-right' place='left' tip={assignee?.name || ''} dataFor={assignee?.name || ''} />

                                        <TiDelete
                                            className="remove-assignee"
                                            onClick={(e) =>
                                                this.removeAssignee(assignee, e)
                                            }
                                        />
                                        {assignee?.avatar_url ? (
                                            <img
                                                src={assignee?.avatar_url}
                                                alt={assignee?.username}
                                            />
                                        ) : (
                                            <span className="assignee-name">
                                                asdsad
                                                {assignee?.initials}
                                            </span>
                                        )}
                                    </ImageWrapper>
                                ))}
                            </Assignees>
                        ) : (
                            <Assignees>
                                <Dropdown
                                    items={AddAssignee}
                                    left
                                    addTop={15}
                                    width="250px"
                                    scrollable
                                >
                                    <ImageWrapper
                                        className="placeholder transparent-border"
                                        data-tip
                                        data-for='taskAssign'
                                        color="transparent"
                                        borderColored={!!!this.state.assignee.length}
                                    >
                                        <IoIosAddCircle className="add-assignee" />
                                        <span className="add-assignee-logo">
                                            <FaUser />
                                        </span>
                                    </ImageWrapper>
                                </Dropdown>
                            </Assignees>
                        )}
                    </div>
                </Header>

                <SubInfo>
                    <div className="created-by">
                        {this.props.selectedTask && createdBy?.name ?
                            <React.Fragment>
                                {`Created by ${createdBy?.name || ''}`} <span>•</span>
                            </React.Fragment>
                            : ''} {this.props.selectedTask && this.state.dateCreated ? `${moment(this.state.dateCreated).format("MMMM DD, YYYY")} at ${moment(this.state.dateCreated).format("hh:mm A")}` : ''}
                    </div>
                    <div className="updated-by">
                        {this.props.selectedTask && updatedBy?.name ? <React.Fragment>
                            {`Last updated by ${updatedBy?.name || ''}`} <span>•</span>
                        </React.Fragment> : ''} {task && task?.dateUpdated ? `${moment(task?.dateUpdated).format("MMMM DD, YYYY")} at ${moment(task?.dateUpdated).format("hh:mm A")}` : ''}
                    </div>
                </SubInfo>
                <Body edit={!!this.props.selectedTask}>
                    <Label>Task Name</Label>
                    <Input
                        id='task-name-field'
                        autoComplete="off"
                        name="taskName"
                        value={this.state.taskName}
                        className="textarea hover-container task-title border-light"
                        placeholder="New task"
                        onChange={this.handleTaskNameChange}
                        onBlur={(e) => {
                            this.onBlurRequired(e)
                        }}
                    />

                    {this.state.errors && this.state.errors.taskName ? (
                        <ErrorMessage>{this.state.errors.taskName}</ErrorMessage>
                    ) : null}

                    <Label>Due Date</Label>

                    {/* <Input
                        className="textarea hover-container task-title border-light"
                        type="date"
                        onChange={(e) =>
                            this.props.actions.UpdateSelf({
                                dueDate: e.target.value,
                            })
                        }
                        value={this.props.data.dueDate}
                    /> */}


                    <TaskDatePicker
                        name="dueDate"
                        wrapperClassName="datepicker"
                        placeholderText="No due date"
                        onChangeRaw={(e: any) => e.preventDefault()}
                        onSelect={(date) =>
                            this.setState({
                                dueDate: moment(date).format('YYYY-MM-DD'),
                            })
                        }
                        selected={
                            this.state.dueDate
                                ? moment(this.state.dueDate).toDate()
                                : null
                        }
                        onChange={() => { }}
                        readOnly
                        containerClassName="border-lightimp task-datepicker"
                        dateFormat="MMMM dd, yyyy"
                    />

                    {!!this.state.dueDate &&
                        <React.Fragment>
                            <Label>Reminder (day/s)</Label>

                            <Input
                                disabled={!!!this.state.dueDate}
                                autoComplete="off"
                                name="reminder"
                                value={this.state.reminder}
                                className="textarea hover-container task-title border-light"
                                placeholder="e.g 1"
                                onChange={this.handleReminderChange}
                                type="number"
                            />
                        </React.Fragment>
                    }

                    <Label>Notes</Label>
                    {/* <DescInput className="container" /> */}
                    {/* <TextArea
                        name="notes"
                        value={this.state.notes}
                        className="textarea hover-container task-title border-light"
                        placeholder="Task notes..."
                        onResize={(e) => { }}
                        onChange={(e: any) => this.handleChange(e)}
                        rows={6}
                        maxRows={6}
                        style={{ minHeight: '92px' }}
                        ref={this.notes}
                        onBlur={() => this.setState({ notes: this.state.notes.trim() })}
                    /> */}
                    <div style={{ maxWidth: '350px' }}>

                        <EditorComponent onChange={(e: any) => this.handleChange(e)} state={this.state.notes} />
                    </div>


                    <Label>
                        Attachments
                        <AddAttachmentButton className="button primary" onClick={() => {
                            if (this.fileRef.click) {
                                this.fileRef.click()
                            }
                        }}>
                            Add
                        </AddAttachmentButton>
                    </Label>
                    {/* <DescInput className="container" /> */}
                    {this.state.attachments[0] && Object.keys(this.state.attachments[0]).length === 0 && this.state.attachments[0].constructor === Object ?
                        <></> :
                        this.state.attachments?.map((attachment, i) => {
                            return <AttachmentItem key={i}>
                                <FiPaperclip />
                                <a href={attachment.uri} target="_blank" className="color-primary">{attachment.filename || attachment.name || ''}</a><a href="#" onClick={() => this.removeAttachment(attachment)}><small>x</small></a>
                            </AttachmentItem>
                        })}

                    <input
                        ref={el => this.fileRef = el}
                        name="attachments"
                        type="file"
                        onChange={(e: any) => this.handleChangeAttachments(e)}
                        multiple
                        hidden={true}
                    />
                </Body>

                <CreateButton
                    className="button primary"
                    disabled={!!this.state.errors || this.state.isLoading}
                    onClick={() =>
                        this.state.taskName &&
                            this.state.assignee.length > 0 &&
                            !this.state.errors
                            ? (() => { this.setState({ isLoading: true }); this.addTask() })()
                            : toast.error(
                                'Please supply all the information needed',
                                {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                }
                            )
                    }
                >
                    {this.props.selectedTask ? 'Update Task' : 'Create Task'}
                </CreateButton>

                <UnderlinedText onClick={() => this.closeForm()}>
                    <span>Cancel</span>
                </UnderlinedText>

            </MainCont>
        )
    }
}

const Body = styled.div<{ edit: boolean }>`
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: ${props => props.edit ? '290px' : '420px'};
    margin: 10px 0;
    padding-right: 5px;

    @media (max-height: 670px) {
        max-height: ${props => props.edit ? '190px' : '220px'};
    }
`

const SubInfo = styled.div`
    .created-by, .updated-by {
        font-weight: 400;
        font-size: 11px;
        font-style: italic;
        opacity: 0.5;

        span {
            font-size: 18px;
            margin: 0 1px;
        }
    }
`

const AddAttachmentButton = styled.div`
    font-weight: 500;
    padding: 5px 8px !important;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;

    span {
        border-bottom: 2px solid;
        padding-bottom: 2px;
    }
`

const AttachmentItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
        margin-right: 8px;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        padding: 5px;
        color: red
    }
`

const UnderlinedText = styled.p`
    cursor: pointer;
    text-align: center;
    margin-bottom: 16px;

    span {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 2px solid;
    }
`

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const MainCont = styled.div`
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 10px;
    border-radius: 10px;
    position: relative;
    min-width: 440px;

    input, textarea {
        &::placeholder {
            opacity: 0.6;
        }
    }

    .task-datepicker {
        border-radius: 99px !important;
        .react-datepicker__input-container input {
            font-weight: 400;
        }
    }

    p {
        margin-bottom: 5px;
        font-size: 0.8em;
    }
`

const Title = styled.span`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
`

const Label = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    /* filter: brightness(0.8); */
`

const TaskDatePicker = styled(RDatepicker)`
    border-radius: 99px !important;
`

const Input = styled.input`
    border-radius: 99px !important;
    padding: 10px;
    outline: none;
`

const TextArea = styled(TextareaAutosize)`
    border-radius: 8px !important;
`

const ImageWrapper = styled.div<{ color?: string, bordered?: boolean, borderColored?: boolean }>`
    width: 30px;
    height: 30px;
    object-fit: cover;
    background: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    border: 1px solid;
    ${(props) => (props.bordered ? 'border: 2px solid #2D81FF;' : '')}
    font-weight: ${(props) => props.theme.textRegular};

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed;
    }

    .assignee-name {
        color: white;
    }

    .remove-assignee {
        font-size: 20px;
        position: absolute;
        right: -4px;
        display: none;
        cursor: pointer;

        &.bottom {
            bottom: -4px;
            right: -5px;
        }

        &:not(.bottom) {
            top: -4px;
        }
    }

    .add-assignee {
        font-size: 20px;
        position: absolute;
        bottom: -5px;
        right: -4px;
        cursor: pointer;
    }

    /* &.transparent-border {
        border-color: transparent !important;
    } */

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 3px dashed ${({ borderColored }) => borderColored ? 'red' : 'rgba(0,0,0,0.4)'};
        color: ${({ borderColored }) => borderColored ? 'red' : 'unset'};
    }

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const Assignees = styled.div`
    display: flex;
    flex-direction: row-reverse;

    & > div:not(:last-child) {
        margin-left: -10px;
    }

    & > ${ImageWrapper} {
        transition: 0.3s transform ease-out;
        &:hover {
            z-index: 2;
            /* border: 1px solid white; */
            transform: scale(1.1);
        }
    }
`

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .remove-assignee {
            display: block;
        }
    }
`

const CreateButton = styled.button`
    margin-top: 15px;
    margin-bottom: 17px;
    cursor: pointer;
    border: none;

    &:hover {
        box-shadow: ${(props) => props.theme.shadows.neumorphiclight};
    }

    &:disabled {
        opacity: 0.3;
    }
`

const ErrorMessage = styled.p`
    color: red;
    margin: 8px 0;
    max-width: 262px;
`

export default CreateTaskForm
