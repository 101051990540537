import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useHistory } from 'react-router'

import LoopApi from '../../helpers/LoopApi'
import SuccessfulEmail from './SuccessfulEmail'

import { ErrorMessage, FormGroup, Input, InputLabel, LinkButton, LoginForm, LogoIcon, LogoWrapper, OtherInfo, SubmitButton, SubTitle, Title } from './styled'
import ForgotPasswordIcon from './forgotpassword.svg'

function Form() {
  let history = useHistory()

  const [focused, setFocused] = useState(true)
  const [email, setEmail] = useState('')
  const [successful, setSuccessful] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [sendingEmail, setSendingEmail] = useState(false)

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setErrorMessage('')

    setEmail(value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!email) {
        return setErrorMessage('Please enter your email address')
    }

    handleForgotPassword()
  }

  const handleForgotPassword = async () => {
    setSendingEmail(true)
    setErrorMessage('')
    try {
        const resp = await LoopApi(null, 'ForgotPassword', { email })
        if (resp.error) {
            setSendingEmail(false)
            return setErrorMessage(resp.msg)
        } else {
            setSuccessful(true)
            setSendingEmail(false)
        }
    } catch (err) {
        if(err.message === 'Too many requests, please try again later.') {
            setErrorMessage(err.message)
        }
        setSendingEmail(false)
    }
  }
  
  if(successful) {
    return <SuccessfulEmail />
  }
  
  return (
    <LoginForm onSubmit={handleSubmit}>
        <LogoWrapper>
            <LogoIcon src={ForgotPasswordIcon} alt={'Forgot Password'} />
        </LogoWrapper>
        <Title>Forgot Password</Title>
        <SubTitle>Enter the email address associated with your account and  we'll send  you a link  to reset password </SubTitle>
        <FormGroup className={`form-group ${focused || email ? 'focused' : ''}`}>
            <InputLabel htmlFor="forgotEmail">Email address</InputLabel>
            <Input 
                id="forgotEmail" 
                type="email" 
                name="email"
                value={email}
                placeholder="Email Address"
                onChange={handleValueChange}
                // onFocus={() => setFocused(true)} 
                // onBlur={() => setFocused(false)}
                autoComplete="off"
            />
        </FormGroup>
        {
            errorMessage &&
            <OtherInfo>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </OtherInfo>
        }
        <SubmitButton
            className="button primary"
            disabled={sendingEmail}
            value={'Submit'}
            type="submit"
        />
        <LinkButton onClick={() => history.push('/login')}>
            Cancel
        </LinkButton>
    </LoginForm>
  )
}

export default Form