import React from 'react'
import styled from 'styled-components'

import Modal from '../../../components/Modalv2'

const { GlobalState } = require('reflux')

function DeleteSpace(props: any) {

    const onCancel = () => {
		GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
        props.closeModal && props.closeModal()
	}

	const onLeave = async () => {
		GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
		GlobalState.modal.onSubmit()
        props.closeModal && props.closeModal()
	}

	return (
		<Modal closeModal={() => {
			GlobalState.modal.onModalClose && GlobalState.modal.onModalClose()
			props.closeModal()
		}}>
			<Container className="topbar-modal">
					<Contents>
						<Title className="header">Remove Space</Title>
						<Details className="subheader">Are you sure you want to remove this space? </Details>
						{GlobalState?.modal?.shownote && <Details className="error">Note: This space may contain rooms in your personal space or some of your organizations.</Details>}
					</Contents>

					<Options>
						<Button className="button default" onClick={onCancel}>No</Button>
						<Button className="button primary" onClick={onLeave}>Yes</Button>
					</Options>
				</Container>
		</Modal>
	)
}

export default DeleteSpace

const Container = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 400px;
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`

const Title = styled.div`
	font-weight: ${props => props.theme.textBold};
	font-size: ${props => props.theme.textLG};
`

const Details = styled.div`
	font-weight: ${props => props.theme.textRegular};
	font-size: ${props => props.theme.textMD};
	padding-top: 10px;
`

const Options = styled.div`
	display: flex;
	width: 70%;
`

const Button = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 14px;
	margin: 4px;
`
