import React, {useCallback, useMemo, useRef} from 'react'
import {Img} from 'react-image'
import VisibilitySensor from 'react-visibility-sensor'
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Room,
  TrackPublication,
} from 'twilio-video'

import LoopApi from '../../../../../../helpers/LoopApi'

import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff'
import usePublications from '../../hooks/usePublications'
import useTrack from '../../hooks/useTrack'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting'
import {Initials, MutedIcon, ParticipantContainer} from './styledComponents'
import TrackWaveMeter from './TrackWaveMeter'
import Name from './Name'
import useVideoContext from '../../hooks/useVideoContext'
import Sagas from '../../../../../../helpers/Sagas'
import TransferHostOverlay from './TransferHostOverlay'
import ParticipantOverlay from './ParticipantOverlay'
import useDominantSpeaker from '../../hooks/useDominantSpeaker'

import Grapl from '../../../../../../assets/Icon-SVG.svg'
import Loader from '../../../../../../components/Loader'
import useMeetingContext from '../../../../../../contexts/useMeetingContext'
import { DailyCall } from '@daily-co/daily-js'
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled'
import { CallingProviderName } from '../../../../../../contexts/types'
interface ParticipantInfoProps {
  participant: Participant
  children: React.ReactNode
  // onClick?: () => void;
  isSelected?: boolean
  isLocalParticipant?: boolean
  hideParticipant?: boolean
  avatar?: string
  name?: string
  user: any
  dominantSpeakerId?: string | null
}

function ParticipantInfo({
  participant,
  // onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  avatar,
  name,
  user,
}: ParticipantInfoProps) {
  const publications = usePublications(participant)
  const lastClickTimeRef = useRef(0)

  const audioPublication = (publications as any).find((p: any) => p?.kind === 'audio')
  const videoPublication = (publications as any).find(
    (p: any) => !(((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen')) && p?.kind === 'video',
  )

  const isVideoEnabled = Boolean(videoPublication)
  const isScreenShareEnabled = (publications as any).find((p: any) =>
  ((p as TrackPublication)?.trackName || (p as MediaStreamTrack)?.label)?.includes('screen'),
  )

  const videoTrack = useTrack(videoPublication)
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  )

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined
  const isAudioTrackEnabled = useIsTrackEnabled(audioTrack)
  const isParticipantReconnecting = useParticipantIsReconnecting(participant)

  const {host, users} = useVideoContext()
  const {room, callProvider} = useMeetingContext()
  const localParticipant = (room as Room)!.localParticipant || (room as DailyCall)?.participants()?.local

  const isTwilio = callProvider === CallingProviderName.Twilio ||
        callProvider === CallingProviderName.TwilioPro || 
        callProvider === CallingProviderName.TwilioStandard

  const isDaily = callProvider === CallingProviderName.DailyCo

  const getUser = (id: string) => users.find((u) => u.id === id)
  const [meetingName, currentUserId] = (localParticipant?.identity || localParticipant?.user_name || '')?.split('::')
  const me = getUser(currentUserId)
  //console.log({ currentUserId, me, users })

  // if the CURRENT LOGGED IN USER is host
  const isHost = me?.id === host

  // if the PARTICIPANT (of this tile) is host
  const participantIsHost = user?.id === host

  const participantUser = getUser((participant?.identity || participant?.user_name || '')?.split('::')?.[1])

  const dominantSpeaker = useDominantSpeaker()
  const dominantSpeakerId = dominantSpeaker?.identity?.split('::')?.[1]
  const isDominantSpeaker = participantUser?.id === dominantSpeakerId

  const mute = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()

      let [meetingName] = participant.identity.split('::')
      Sagas.Clients.Emit('main', 'message', {
        action: 'MuteParticipant',
        meetingName,
        identity: participant.identity,
        user: participantUser,
        currentUser: me,
      })
    }
  }, [])

  const transferHostAccess = async () => {
    try {
      await LoopApi(null, 'TransferHost', {participant: participantUser}, [
        ['name', meetingName],
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const IconSvg = <img src={Grapl} alt="Grapl" />

  return useMemo(() => (
    // @ts-ignore
    <ParticipantContainer
      avatar={user?.avatar_url || ''}
      className={`participant remote-participant container contentimp ${
        isDominantSpeaker && audioTrack && !!!isScreenShareEnabled
          ? 'border-primary'
          : 'border-topbarimp'
      }`}
      style={{
        display: hideParticipant ? 'none' : 'block',
        border: '2px solid',
        transition: 'border 0.3s ease-in-out',
      }}
    >
      {children}
      <div className="avatar-img-wrapper rounded inner">
        <Img
          key={Math.random()}
          src={[
            user?.avatar_url || '',
            `https://ui-avatars.com/api/?name=${
              user?.username || user?.name || ''
            }&format=svg&background=${user?.color || 'random'}&color=fefefe`,
            'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg',
          ]}
          className="avatar-img rounded inner"
          loader={<Loader />}
          unloader={IconSvg}
        />
      </div>
      {(audioTrack && isAudioTrackEnabled && isTwilio) || (isDaily && audioTrack) ? <TrackWaveMeter track={audioTrack} /> : <MutedIcon />}

      <Name
        isHost={participantIsHost}
        isLocalParticipant={isLocalParticipant}
        name={user?.name || user?.username || ''}
      />

      {/* {
        !isLocalParticipant && audioTrack && isHost && <button onClick={() => {
          mute()
        }} style={{
          top: 0,
          left: 0,
          position: 'absolute'
        }}>Mute</button>
      } */}

      {isHost && !!!participantIsHost && (
        <ParticipantOverlay
          transferHostAccess={transferHostAccess}
          transferHostAccessDisabled={!isHost}
          muteParticipant={mute}
          muteParticipantDisabled={
            !(!isLocalParticipant && ((audioTrack && isAudioTrackEnabled && isTwilio) || (isDaily && audioTrack)) && isHost)
          }
        />
      )}
    </ParticipantContainer>
  ), [isDominantSpeaker, audioTrack, isAudioTrackEnabled, isScreenShareEnabled, isLocalParticipant, user, isHost, audioTrack, participantIsHost, hideParticipant])
}

export default ParticipantInfo
