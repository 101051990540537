import React, { ReactNode } from 'react'
import styled from 'styled-components'
import _props from '../../styles/Theme'

import {
    FormControl,
    InputLabel,
    Select,
} from '@material-ui/core'

type IOption = {
    value: any
    label: ReactNode
}

function StyledSelect({ className = '', label = '', name = '', options = [], value = null, width = '100%', onChange = () => {} }: {
    className?: any
    label: string
    name: string,
    options: IOption[],
    value?: string | null
    width?: string | number
    onChange?: ((e: any) => void) | undefined
}) {
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
            <Select
                native
                name={name}
                value={value}
                onChange={onChange}
            >
            {
                options?.map((option: IOption, idx: number) => (
                     <option key={idx} value={option.value}>{option.label}</option>
                ))
            }
            </Select>
        </FormControl>
    )
}

export default StyledSelect