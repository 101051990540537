import React, { Component } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea';

import { Input } from '../../components/Elements'
import { FiSend as SendIcon } from '@react-icons/all-files/fi/FiSend'
import { StyledAce } from '../_Shared/Elements'

interface Props {
    submitChat(val: string): void
    isHost: boolean | undefined
    is_channel: boolean
    currentName: any
}

interface State {
    value: string
}

export default class ChatInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            value: ''
        }

        this.setValue = this.setValue.bind(this)
        this.submitChat = this.submitChat.bind(this)
    }

    submitChat(e: any) {
        e.preventDefault && e.preventDefault()
        this.props.submitChat(this.state.value)
        this.setState({ value: '' })
    }

    setValue(e: any) {
        this.setState({ value: e.target.value })
    }


    render() {
        const { is_channel = false, currentName = '' } = this.props
        return (
            <MsgInputContainer onSubmit={this.submitChat}>
                <IcoWrapper>
                    <AirplaneIco 
                        size="18"
                        disabled={!this.props.isHost}
                        onClick={this.submitChat}
                    />
                </IcoWrapper>
                <MsgInput
                    rows={2}
                    maxRows={4}
                    onResize={(e) => { }}
                    placeholder={`Message ${is_channel ? '#' : ''}${currentName}`}
                    onChange={this.setValue}
                    value={this.state.value}
                    disabled={!this.props.isHost}
                    className="input-desc textarea border-lightslack"
                />
                
            </MsgInputContainer>
        )
    }
}

const MsgInputContainer = styled.form`
	display: flex;
	flex-shrink: 0;
	padding: 8px 14px;
    flex-direction: column;
    position: relative;
`

const IcoWrapper = styled.div`
    /* margin-left: auto;
    visibility: hidden; */
    position: absolute;
    bottom: 15px;
    right: 30px;
`

const AirplaneIco = styled(SendIcon)<{disabled: boolean}>`
    /* position: relative;
    top: 42px;
    right: 18px; */
    /* visibility: visible; */
    /* background: linear-gradient(90deg,#ff9900 0%,#fdb468 100%); */
    /* color: white; */
    /* border-radius: 999px; */
    padding: 5px;
    width: 27px;
    height: 18px;
    cursor: pointer;
    ${({disabled}) => disabled ? 'pointer-events:none;' : null}
`

const MsgInput = styled(TextareaAutosize)`
	/* padding: 4px 20px; */
    border: 1px solid;
    /* border-radius: 20px; */
    padding-right: 60px !important;


    ::placeholder,
    ::-webkit-input-placeholder {
        /* font-size: 15px; */
        /* color: #999aa4; */
    }

    /* &:hover {
        border-color: #000 !important;
    } */
`
