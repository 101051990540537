import React, { useRef, useEffect, useState } from 'react'
import { StyledAce } from '../_Shared/Elements'

interface Props {
    changeSelection: (s: string) => void
    changeSelectionCheck: (sc: boolean) => void
    syntax: string
    content: any
    file: any
    updateSelf: Function
}

const AceComp = (props: Props) => {
    const editor = useRef<any>()
    const [content, setContent] = useState(props.content)

      useEffect(()=> {
        if(props.content !== content)
        setContent(props.content)
      }, [props.content])

    const onSelectionChange = (s: any) => {

        const selectionCheck = s.anchor.column == s.lead.column && s.anchor.row == s.lead.row
        const selectedText = editor.current.editor.getSelectedText()

        if(selectedText) {
            const {start, end} = editor.current.editor.selection.getRange()
            const contentSelection = `// ${props.file.path}\n// ${start.row}:${start.column} - ${end.row}:${end.column}\n${selectedText}\n`
            props.changeSelection(contentSelection)
        }

        props.changeSelectionCheck(selectionCheck)
    }

    const onChange = (newValue: any) => {
        setContent(newValue)
        props.updateSelf({content: newValue})
      }

    return (
        <StyledAce
            height='100%'
            width='100%'
            mode={props.syntax || 'javascript'}
            theme='monokai'
            value={content}
            name='GITHUB_FILE_VIEWER'
            readOnly={false}
            onSelectionChange={(s: any) => onSelectionChange(s)}
            style={editorStyle}
            ref={editor}
            onChange={(newValue: any) => onChange(newValue)}
        />
    )
}

var editorStyle = {
    zIndex: 0,
}

export default AceComp